import React, { useState } from "react";
import { Grid, IconButton, Tooltip, Fade } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import YAML from "yaml";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";
import { connect } from "react-redux";
import AceEditorDialog from "./AceEditorDialog";
import _ from "lodash";

function CodePreview(props) {
  const [isCopied, setIsCopied] = useState(false);
  const [yamlEditorText, setYamlEditorText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);
  React.useEffect(() => {
    if (props.currentResourceQuota) {
      //Replace addition comma in application namespace to display in yaml
      let updatedResourceQuota = JSON.parse(
        JSON.stringify(props?.currentResourceQuota)
      );
      updatedResourceQuota?.spec?.resourceQuota.spec?.clusterQuota?.forEach(
        (cluster) => {
          if (cluster["resources"]) {
            if (cluster["resources"]["userdata"]) {
              delete cluster["resources"]["userdata"];
            }
          }
          return cluster?.namespaceQuota?.forEach((item) => {
            if (item["resources"]["userdata"]) {
              return delete item["resources"]["userdata"];
            }
            return delete item["resources"]["userdata"];
          });
        }
      );

      updatedResourceQuota?.spec?.resourceQuota.spec?.clusterQuota?.forEach(
        (cluster) => {
          if (_.has("resources", cluster)) {
            if (cluster["resources"]["userdata"]) {
              return delete cluster["resources"]["userdata"];
            }
          }
          return cluster;
        }
      );

      delete updatedResourceQuota?.spec?.resourceQuota?.spec?.sliceQuota
        .resources.userdata;
      const doc = new YAML.Document();
      doc.contents = updatedResourceQuota;
      setYamlEditorText(doc.toString());
    }
    props.setClose(true);
  }, []);

  const copyToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(yamlEditorText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = yamlEditorText;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      });
    }
  };

  const closeEditor = () => {
    props.setClose(false);
    props.setCurrHeight("60px");
  };
  const onClickHandler = () => {
    /*if (props.currHeight === '66px') {
      props.setCurrHeight('79vh')
    }
    else {
      props.setCurrHeight('66px')
    }*/
    setOpenDialog(true);
  };

  const onExpandClick = () => {
    setExpanded((oldState) => {
      props.setCurrHeight(oldState ? "60px" : "40vh");
      return !oldState;
    });
  };

  return (
    <Grid
      item
      xs
      container
      backgroundColor="#ffffff"
      borderRadius="14px"
      flexDirection="column"
      direction="column"
      justifyContent="space-between"
    >
      {props?.close && (
        <Grid
          item
          sx={{
            backgroundColor: "#272822",
            padding: "24px",
          }}
        >
          <span
            style={{
              margin: "30px 0 8px",
              fontSize: "20px",
              fontWeight: "700",
              color: "white",
            }}
          >
            Code Preview
            <IconButton
              onClick={closeEditor}
              sx={{ float: "right" }}
              aria-label="copy yaml code"
              component="span"
            >
              <CloseIcon color="white" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={onClickHandler}
              sx={{ float: "right" }}
              aria-label="copy yaml code"
              component="span"
            >
              {props.currHeight === "60px" ? (
                <OpenInFullIcon color="white" fontSize="small" />
              ) : (
                <CloseFullscreenIcon color="white" fontSize="small" />
              )}
            </IconButton>
            {props.withMinimize && (
              <IconButton
                sx={{ float: "right" }}
                aria-label="minimize yaml code"
                onClick={onExpandClick}
                component="span"
              >
                {expanded ? (
                  <MinimizeIcon color="white" fontSize="small" />
                ) : (
                  <MaximizeIcon color="white" fontSize="small" />
                )}
              </IconButton>
            )}
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={isCopied}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
            >
              <IconButton
                onClick={copyToClipboard}
                sx={{ float: "right" }}
                aria-label="copy yaml code"
                component="span"
              >
                <ContentCopyIcon color="white" fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <AceEditor
            mode="sass"
            theme="monokai"
            name="Slice YAML"
            height={props.currHeight}
            width="100%"
            onChange={(value) => setYamlEditorText(value)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={yamlEditorText}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </Grid>
      )}
      <AceEditorDialog
        editorValue={yamlEditorText}
        setEditorValue={(value) => setYamlEditorText(value)}
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
      />
    </Grid>
  );
}

export default CodePreview;
