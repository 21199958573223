import {
  CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST,
  CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST,
  CLEAR_CURRENT_SLICE_ROLES_LIST,
  CLEAR_SELECTED_ROLE,
  SELECT_IMPORTK8ROLE_LIST,
  SELECT_ROLE_LIST,
  SET_CURRENT_SLICE_ROLES_LIST,
  SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST,
  SET_ROLES,
  SET_SELECTED_ROLE,
  SET_SLICE_ROLES_LIST,
} from "../actions/types";

const initialState = {
  sliceRoleList: [],
  currentSliceRoleList: {},
  importk8Roles: [],
  selectRoleList: [
    {
      user_or_group: "",
      name: "",
      roles: [],
      name_error: true,
      roles_error: true,
      user_or_group_error: true,
      touched: false,
    },
  ],
  originalSliceRoleList: [],
  roles: [],
  selectedRole: [],
};

export default function RbacReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SLICE_ROLES_LIST:
      return {
        ...state,
        sliceRoleList: action.payload,
      };
    case SET_CURRENT_SLICE_ROLES_LIST:
      return {
        ...state,
        currentSliceRoleList: action.payload,
      };
    case SELECT_ROLE_LIST:
      return {
        ...state,
        selectRoleList: action.payload,
      };
    case SELECT_IMPORTK8ROLE_LIST:
      return {
        ...state,
        importk8Roles: action.payload,
      };
    case SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST:
      return {
        ...state,
        originalSliceRoleList: action.payload,
      };
    case CLEAR_CURRENT_SLICE_ROLES_LIST:
      return {
        ...state,
        currentSliceRoleList: {},
      };
    case CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST:
      return {
        ...state,
        selectRoleList: [
          {
            user_or_group: "",
            name: "",
            roles: [],
            name_error: true,
            roles_error: true,
            user_or_group_error: true,
            touched: false,
          },
        ],
      };
    case CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST:
      return {
        ...state,
        importk8Roles: [],
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: action.payload,
      };
    case CLEAR_SELECTED_ROLE:
      return {
        ...state,
        selectedRole: [],
      };
    default:
      return state;
  }
}
