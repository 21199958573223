import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import React, { useState } from "react";
import AceEditor from "react-ace";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { addRole } from "../../actions/RbacActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddRole({ addRoleDialog, setAddRoleDialog, rules }) {
  const [roleName, setRoleName] = useState("");
  const roles = useSelector((state) => state.RbacReducer.roles);

  const [errors, setErrors] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [rule, setRule] = useState(() => {
    if (rules) {
      return JSON.stringify(rules, null, 4);
    }
    return "";
  });
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    setRoleName("");
    setDescription("");
    // setRule("");
    setConfirmDialog(false);
    setAddRoleDialog(false);
  };

  let schema = yup.object().shape({
    rules: yup
      .mixed()
      .test(
        "is_valid",
        (d) => `${d.path} is not valid`,
        (rules) => {
          try {
            JSON.parse(rules);
            return true;
          } catch (e) {
            return false;
          }
        }
      )
      .test(
        "has_rules",
        (d) => `${d.path} should not be empty array`,
        (value) => {
          try {
            const rules = JSON.parse(value);
            return rules.length > 0;
          } catch (e) {
            return false;
          }
        }
      ),
    name: yup
      .string()
      .required()
      .notOneOf(
        roles.map((role) => role.name),
        "Role name is already taken"
      )
      .test(
        "is_valid",
        (d) => `${d.path} is not valid`,
        (value) => {
          const regex_name = /^(?!-)[a-z0-9-]{1,63}$/g;
          return value.match(regex_name);
        }
      )
      .max(253, "Must not be longer than 253")
      .label("Role name"),
  });

  const saveRole = async () => {
    schema
      .validate(
        { name: roleName, rules: rule },
        {
          abortEarly: false,
        }
      )
      .then(async () => {
        const roleDetails = {
          name: roleName,
          description,
          rules: JSON.parse(rule),
        };
        const result = await dispatch(addRole(roleDetails));
        setErrors(null);
        if (result) {
          handleClose();
        } else {
          throw new Error("Could not add template");
        }
      })
      .catch((error) => {
        const { inner } = error;
        if (inner) {
          let local_errors = {};
          inner.forEach((error) => {
            local_errors[error.path] = error.message;
          });
          setErrors(local_errors);
        } else {
          setErrors({
            rules: error.message,
          });
        }
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "RoleName") {
      setRoleName(value);
      if (value === "") {
        setErrors({
          name: "Enter Role Name",
        });
      } else {
        setErrors(null);
        setRoleName(value);
      }
    }
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={addRoleDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <label style={{ fontWeight: "bold" }}>Add Roles</label>
          <IconButton
            aria-label="close"
            onClick={() => setConfirmDialog(true)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <FormControl error={error}> */}
          <TextField
            id="role-name"
            label="Role Name"
            name="RoleName"
            value={roleName}
            fullWidth
            autoFocus
            error={errors?.name}
            margin="dense"
            // onChange={(e) => setRoleName(e.target.value)}
            onChange={(e) => handleChange(e)}
            helperText={errors?.name}
          />
          <TextField
            id="description"
            label="Description"
            value={description}
            fullWidth
            autoFocus
            margin="dense"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Typography
            sx={{ fontSize: "17px", color: "black" }}
            mt={2}
            gutterBottom
          >
            Rules :
          </Typography>
          {errors?.rules && (
            <Typography
              sx={{
                pb: 1,
                px: 0,
                color: "error.main",
              }}
            >
              {errors.rules}
            </Typography>
          )}

          <AceEditor
            mode="json"
            theme="monokai"
            name="Rule"
            height="400px"
            width="100%"
            onChange={(value) => setRule(value)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={rule}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
          {/* <FormHelperText>{error}</FormHelperText> */}
          {/* </FormControl> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#2868F3", color: "white" }}
            fullWidth
            autoFocus
            onClick={saveRole}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="confirm"
        aria-describedby="confirm to close"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to leave without saving the changes?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>No</Button>
          <Button onClick={handleClose} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
