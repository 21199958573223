import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import { compose } from 'redux';
import { RootContainer } from './AppStyles';
import NodeAffinitySliceList, { AssignNodes } from './Components/AssignNodes';
import { BodyRoot } from './Components/Body/BodyStyles';
import Popups from './Components/Body/Popups';
import ConnectClusterSliceList from './Components/ConnectClusters/SliceList';
import StepLayoutConnectCluster from './Components/ConnectClusters/Steps/StepLayout';
import Dashboard from './Components/Dashboard/Dashboard';
import IsolateNamespaceList from './Components/IsolateNamespaces/LandingPage';
import IsolateNamespaceSteps from './Components/IsolateNamespaces/Steps';
import LicensingBanner from './Components/Licensing/Licensing';
import Login from './Components/Login/Login';
import ManageRbac from './Components/ManageRbac/LandingPage';
import ManageRbacSteps from './Components/ManageRbac/Steps';
import ManageResource from './Components/ManageResource';
import ManageResourceSteps from './Components/ManageResource/Steps';
import ManageRoles from './Components/ManageRoles';
import NamespaceList from './Components/Namespaces/LandingPage';
import NamespaceSteps from './Components/Namespaces/Steps';
import { NavBar } from './Components/NavBar';
import NotAvailable from './Components/NotAvailable';
import SlicePolicyList from './Components/SlicePolicy/SlicePolicyList';
import SliceBody from './Components/Slices/SliceBody';
import SliceList from './Components/Slices/SliceList';
import ErrorBoundary from './ErrorBoundary';
import { MuiTheme } from './Theme';
import { MuiTheme as MaterialTheme } from './Theme/MaterialTheme';
import {
  updateSnackbar,
  updateSnackbarSuccess,
} from './actions/SnackbarActions';
import { logoutUser } from './actions/logoutAction';
import { closedDrawerWidth, openDrawerWidth } from './constants/styleConstants';
import CurrentProject from './Components/CurrentProject';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const fillAvailable = () => {
  var isFirefox = typeof InstallTrigger !== 'undefined';
  return isFirefox ? '-moz-available' : 'fill-available';
};

const RootDiv = styled('div')(({ theme, open }) => ({
  margin: open ? `0 0 0 ${openDrawerWidth}` : `0 0 0 ${closedDrawerWidth}`,
  width: fillAvailable,
  transition: open
    ? theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      })
    : theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
}));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      menuOpen: true,
      logoutPopUp: false,
    };
  }
  handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (this.props.snackSeverity == 'error')
      this.props.updateSnackbar(false, null);
    else this.props.updateSnackbarSuccess(false, null);
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  };

  render() {
    let Content = <Login />;

    if (this.props.loginStatus) {
      const { menuOpen } = this.state;
      Content = (
        <>
          <ErrorBoundary>
            <Popups />
            <div
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <NavBar
                menuOpen={menuOpen}
                handleDrawerToggle={this.handleDrawerToggle}
              />
              <RootDiv open={menuOpen}>
                {/* <CurrentProject /> */}
                <LicensingBanner />
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route exact path="/slice">
                    <SliceBody />
                  </Route>
                  <Route path="/slices">
                    <SliceList />
                  </Route>
                  <Route exact path="/manage-namespaces">
                    <NamespaceList />
                  </Route>
                  <Route exact path="/manage-namespaces/add/:stepNo">
                    <NamespaceSteps />
                  </Route>
                  <Route exact path="/manage-namespaces/isolate-namespaces">
                    <IsolateNamespaceList />
                  </Route>
                  <Route
                    exact
                    path="/manage-namespaces/isolate-namespaces/add/:stepNo"
                  >
                    <IsolateNamespaceSteps />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  {/* <Route path="/register-cluster/add/:stepNo">
                    <StepLayout />
                  </Route>
                  <Route path="/register-cluster">
                    <LandingPage />
                  </Route> */}
                  <Route path="/assign-nodes/:stepNo" component={AssignNodes} />
                  <Route
                    path="/assign-nodes"
                    component={NodeAffinitySliceList}
                  />
                  <Route path="/manage-resource">
                    <ManageResource />
                  </Route>
                  <Route exact path="/manage-resources/add/:stepNo">
                    <ManageResourceSteps />
                  </Route>
                  {/* <Route path="/connect-cluster/slice/:stepNo">
                    <StepLayoutConnectCluster />
                  </Route> */}
                  {/* <Route path="/connect-cluster">
                    <ConnectClusterSliceList />
                  </Route> */}
                  <Route path="/manage-rbac">
                    <ManageRbac />
                  </Route>
                  <Route path="/manage-rbacs/add/:stepNo">
                    <ManageRbacSteps />
                  </Route>
                  <Route path="/manage-roles">
                    <ManageRoles />
                  </Route>
                  <Route path="/slicePolicy">
                    <SlicePolicyList />
                  </Route>
                  <Route path="/workInProgress">
                    <NotAvailable />
                  </Route>
                  <Route>
                    <BodyRoot>
                      <h3>Invalid Route!</h3>
                    </BodyRoot>
                  </Route>
                </Switch>
              </RootDiv>
            </div>
          </ErrorBoundary>
        </>
      );
    }
    return (
      <MuiTheme>
        <MaterialTheme>
          <LoadingOverlay
            active={Boolean(this.props.spinner)}
            spinner={
              <div
                style={{
                  background: '#ffffff',
                  padding: '3rem',
                  borderRadius: '2rem',
                  opacity: '0.7',
                }}
              >
                <CircleLoader color={'#00048D'} />
              </div>
            }
            styles={{
              overlay: (base) => ({
                ...base,
                background: 'rgb(0, 0, 0,0.5)',
                zIndex: '10000',
                dispay: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }),
              wrapper: (base) => ({
                ...base,
                dispay: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }),
              content: (base) => ({
                ...base,
                height: '100%',
                paddingTop: window.pageYOffset,
                marginTop: '45vh',
              }),
            }}
          >
            <Snackbar
              style={{ zIndex: 100000 }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={this.props.snackShow}
              autoHideDuration={6000}
              onClose={this.handleSnackClose}
            >
              <div>
                <Alert
                  onClose={this.handleSnackClose}
                  severity={this.props.snackSeverity || 'error'}
                >
                  {this.props.snackMessage}
                </Alert>
              </div>
            </Snackbar>
            <Dialog
              open={this.state.logoutPopUp}
              onClose={() => this.setState({ logoutPopUp: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Session Expired</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your session has expired. Please login again.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ logoutPopUp: false });
                  }}
                >
                  Login
                </Button>
              </DialogActions>
            </Dialog>
            <HashRouter>
              <RootContainer>{Content}</RootContainer>
            </HashRouter>
          </LoadingOverlay>
        </MaterialTheme>
      </MuiTheme>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    loginStatus: store.SessionReducer.login,
    spinner: store.SpinnerReducer.spinner,
    snackShow: store.ErrorSnackReducer.show,
    snackMessage: store.ErrorSnackReducer.message,
    snackSeverity: store.ErrorSnackReducer.severity,
    loading: store.EnvironmentReducer.loading,
  };
};

export default compose(
  connect(mapStoreToProps, {
    updateSnackbarSuccess,
    updateSnackbar,
    logoutUser,
  })
)(App);
