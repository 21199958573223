import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { classNames } from '../../../../../utils/functions';

const DetailBlock = ({
  limit,
  container_limit,
  request,
  container_request,
  userdata,
}) => {
  const renderLimit = () => {
    if ((!userdata?.limit == undefined || userdata?.limit === null) && !limit) {
      return (
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          NA
        </Typography>
      );
    }

    const shouldLimitBeRed = limit
      ? userdata?.limit > limit
        ? true
        : false
      : false;

    return (
      <>
        <Typography
          className={classNames(shouldLimitBeRed ? 'text-red' : '')}
          sx={{
            fontSize: '14px',
          }}
          data-testid="limit-usage"
        >
          {userdata?.limit ? userdata.limit : 'NA'}
        </Typography>
        <Typography
          data-testid="limit-value"
          sx={{
            ml: '4px',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {limit ? ` / ${limit}` : ''}
        </Typography>
      </>
    );
  };

  const shouldRequestBeRed = request
    ? userdata?.request > request
      ? true
      : false
    : false;

  const renderRequest = () => {
    if (
      (!userdata?.request === undefined) | (userdata?.request === null) &&
      !request
    ) {
      return (
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          NA
        </Typography>
      );
    }

    return (
      <>
        <Typography
          className={classNames(shouldRequestBeRed ? 'text-red' : '')}
          data-testid="request-usage"
          sx={{
            fontSize: '14px',
          }}
        >
          {userdata?.request ? userdata.request : 'NA'}
        </Typography>
        <Typography
          data-testid="request-value"
          sx={{
            ml: '4px',
            fontSize: '14px',
          }}
        >
          {request ? ` / ${request}` : ''}
        </Typography>
      </>
    );
  };

  return (
    <Stack>
      <Box
        sx={{
          height: '28px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 84,
            fontSize: '14px',
          }}
        >
          Limit -
        </Typography>
        {renderLimit()}
      </Box>
      <Box
        sx={{
          height: '28px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 84,
            fontSize: '14px',
          }}
        >
          Limit -
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          {container_limit ? container_limit : ''}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            ml: 1,
          }}
        >
          / container
        </Typography>
      </Box>
      <Box
        sx={{
          height: '28px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 84,
            fontSize: '14px',
          }}
        >
          Request -
        </Typography>
        {renderRequest()}
      </Box>
      <Box
        sx={{
          height: '28px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 84,
            fontSize: '14px',
          }}
        >
          Request -
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
          }}
        >
          {container_request ? container_request : ''}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            ml: 1,
          }}
        >
          / container
        </Typography>
      </Box>
    </Stack>
  );
};

export default DetailBlock;
