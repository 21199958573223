import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const ConfirmEnforceModal = ({
  handleClose: continueMonitoring,
  handleCancel,
  title,
  open = false,
  content,
}) => {
  return (
    <Dialog
      sx={{
        maxWidth: '528px',
        margin: '0px auto',
        minHeight: '252px',
      }}
      open={open}
      onClose={handleCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle className='modal-title' id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className='modal-desc' id='alert-dialog-description'>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-btn' onClick={() => continueMonitoring(true)}>
          Yes
        </Button>
        <Button
          className='submit-btn'
          onClick={() => continueMonitoring(false)}
          autoFocus
        >
          No
        </Button>
        <Button className='submit-btn' onClick={handleCancel} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmEnforceModal;
