import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLicensingDetails } from '../../actions/licensing.action';
import LicensingBanner from '../LicensingBanner/LicensingBanner';
import LicenseExpiration from './Modals';

const Licensing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLicensingDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LicensingBanner />
      <LicenseExpiration />
    </>
  );
};

export default Licensing;
