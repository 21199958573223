import * as React from 'react';
import KindOnCloud from '../../assets/images/cloud-kind.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import Kind from '../../assets/images/kind.svg';

function KindCloud(props) {
  return <CommonCloud {...props} img={Kind} />;
}

export const KindTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={KindOnCloud} img={Kind} />;
};

export default KindCloud;
