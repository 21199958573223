import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { object } from 'underscore';
import './index.css';

export default function NamespacesTable({
  selectedNamespace,
  setSelectedNamespace,
  searchText,
  search,
  searchBy,
  setIsChanged,
  setSearchText,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const selectedRole = useSelector((state) => state.RbacReducer.selectedRole);
  const [rowCount, setRowCount] = useState(0);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  // console.log(selectedNamespace, 'selectedNamespace');
  useEffect(() => {
    setSearchText('');
    let itemArr = [];
    selectedRole?.namespaces?.map((data) => {
      let obj = { namespace: data?.namespace, cluster: data?.cluster };
      itemArr.push(obj);
    });
    setSelectedNamespace(itemArr);
  }, []);

  // console.log(list);
  const formatList = (list) => {
    const results = [];
    list?.forEach((item) => {
      // console.log(item);
      const foundIndex =
        results?.length > 0
          ? results.findIndex((resultItem) => {
              // console.log('resultItem', resultItem);
              return resultItem.namespace === item.namespace;
            })
          : -1;

      if (results?.length > 0 && foundIndex >= 0) {
        results[foundIndex]['cluster'].push(item?.cluster);
      } else {
        // console.log(64);
        results.push({
          namespace: item.namespace,
          sameness: item.sameness,
          cluster: [item.cluster],
        });
      }
    });
    return results;
  };

  useEffect(() => {
    let itemArr = [];
    // console.log(itemArr);
    let clusters = [];
    const { clusters: slice_clusters, namespaceIsolationProfile } =
      currentSlice.spec;

    // slice_clusters?.map((cluster) => {
    //   const namespaces = namespaceIsolationProfile?.applicationNamespaces
    //     .filter((namespace) => {
    //       return (
    //         namespace.clusters.includes(cluster) ||
    //         namespace.clusters.includes('*')
    //       );
    //     })
    //     .map((namespace) => namespace.namespace);
    //   clusters[cluster] = namespaces;
    //   // console.log(cluster);
    //   return cluster;
    // });
    // console.log(clusters);
    // const result = Object.entries(clusters).reduce((acc, [worker, items]) => {
    //   items.forEach((item) => {
    //     if (item === 'pizza') {
    //       acc[item] = [...(acc[item] || []), worker];
    //     }
    //   });
    //   return acc;
    // }, {});

    // console.log(result);
    currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces?.map(
      (data) => {
        // console.log('data', data);
        data.clusters.map((cluster) => {
          let objclusters = {};

          slice_clusters?.map((cluster) => {
            const namespaces = namespaceIsolationProfile?.applicationNamespaces
              .filter((namespace) => {
                return (
                  namespace.clusters.includes(cluster) ||
                  namespace.clusters.includes('*')
                );
              })
              .map((namespace) => namespace.namespace);
            objclusters[cluster] = namespaces;
            return cluster;
          });
          // console.log(objclusters);

          const result = Object.entries(objclusters).reduce(
            (acc, [worker, items]) => {
              items.forEach((item) => {
                if (item === data.namespace) {
                  acc[item] = [...(acc[item] || []), worker];
                }
              });
              return acc;
            },
            {}
          );

          // console.log(_.values(result));

          // [result].map((_sem) => {
          //   console.log(_sem);
          // });

          // console.log(cluster);
          [objclusters].map((_data) => {
            _.values(result).map((_ddd) => {
              _ddd.map((_sem) => {
                // console.log(_sem);
                let obj = {
                  namespace: data.namespace,
                  cluster: _sem,
                  sameness: cluster === '*' ? _sem : false,
                };
                itemArr.push(obj);
              });
            });
          });
        });
      }
    );
    let uniqueArray = Array.from(
      new Set(itemArr.map(JSON.stringify)), // this removes the duplicate values of the object
      JSON.parse
    );
    itemArr = uniqueArray;
    itemArr?.sort((a, b) => (a.cluster == '*' ? -1 : 1));
    if (search && searchText !== '') {
      itemArr = itemArr.filter((item) => {
        if (searchBy === 'namespace') {
          return searchBy === 'namespace'
            ? item?.namespace?.toUpperCase().includes(searchText.toUpperCase())
            : item?.sameness ||
                item?.cluster?.toUpperCase().includes(searchText.toUpperCase());
        } else if (searchBy === 'cluster') {
          return searchBy === 'cluster'
            ? item?.cluster?.toUpperCase().includes(searchText.toUpperCase())
            : item?.sameness ||
                item?.cluster?.toUpperCase().includes(searchText.toUpperCase());
        }
      });
    }

    setRowCount(itemArr.length);
    itemArr = itemArr.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // const formattedArr = formatList(itemArr);
    // console.log('formattedArr', formattedArr);
    setList(itemArr);
  }, [currentSlice, search, searchText, searchBy, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    const { clusters: slice_clusters, namespaceIsolationProfile } =
      currentSlice.spec;
    setIsChanged(true);
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      let itemArr = [];
      currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces?.map(
        (data) => {
          // console.log('data', data);
          data.clusters.map((cluster) => {
            let objclusters = {};

            slice_clusters?.map((cluster) => {
              const namespaces =
                namespaceIsolationProfile?.applicationNamespaces
                  .filter((namespace) => {
                    return (
                      namespace.clusters.includes(cluster) ||
                      namespace.clusters.includes('*')
                    );
                  })
                  .map((namespace) => namespace.namespace);
              objclusters[cluster] = namespaces;
              return cluster;
            });
            // console.log(objclusters);

            const result = Object.entries(objclusters).reduce(
              (acc, [worker, items]) => {
                items.forEach((item) => {
                  if (item === data.namespace) {
                    acc[item] = [...(acc[item] || []), worker];
                  }
                });
                return acc;
              },
              {}
            );
            [objclusters].map((_data) => {
              // console.log(_data);
              _.values(result).map((_ddd) => {
                _ddd.map((_sem) => {
                  let obj = {
                    namespace: data.namespace,
                    cluster: _sem,
                  };
                  itemArr.push(obj);
                });
              });
            });
          });
        }
      );
      setSelectedNamespace(itemArr);
    } else {
      setSelectedNamespace([]);
    }
  };

  const selectRow = (event, namespace, cluster) => {
    setIsChanged(true);
    const isItemSelected = event.target.checked;
    const results = selectedNamespace ? [...selectedNamespace] : [];
    const foundIndex =
      results?.length > 0
        ? results.findIndex((selected) => {
            return (
              selected.namespace === namespace && selected.cluster === cluster
            );
          })
        : -1;
    if (isItemSelected) {
      if (results?.length > 0 && foundIndex >= 0) {
        if (Array.isArray(cluster)) {
          results[foundIndex]['cluster'] = [`*`];
        } else {
          results.push({
            namespace: namespace,
            cluster: Array.isArray(cluster) ? [`*`] : cluster,
          });
        }
      } else {
        results.push({
          namespace: namespace,
          cluster: Array.isArray(cluster) ? [`*`] : cluster,
        });
      }
    } else {
      if (results[foundIndex]['cluster']) {
        //const clusterIndex = results[foundIndex]['cluster'].indexOf(cluster);
        //results[foundIndex]['cluster'].splice(clusterIndex, 1);
        results.splice(foundIndex, 1);
      } /*else {
        results.splice(foundIndex, 1);
      }*/
    }
    isFormChanged === false && setIsFormChanged(true);
    // console.log(results, 'selectednamespace before');
    setSelectedNamespace(results);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderBottom: '1px solid lightgrey',
      }}
    >
      <Prompt
        message={(location, action) => {
          if (isFormChanged) {
            if (
              location.pathname === '/manage-rbacs/add/2' &&
              action === 'PUSH'
            ) {
              return true;
            } else {
              return 'You have unsaved changes. Do you want to discard them?';
            }
          }
          return true;
        }}
      />
      <div
        style={{
          height: '45vh',
          overflow: 'auto',
          borderBottom: '1px solid lightgrey',
        }}
      >
        <TableContainer className="table-container">
          <Table
            sx={{ minWidth: 300, borderRight: '1px solid lightgrey' }}
            aria-labelledby="Namespace Table"
            size="small"
          >
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  style={{
                    padding: '0px 20px',
                    width: '10%',
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={checkAll}
                    onChange={(e) => {
                      handleSelectAll(e);
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    padding: '15px 20px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '60%',
                  }}
                >
                  CHOOSE NAMESPACES
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                  align="right"
                >
                  <Button
                    onClick={() => {
                      setSelectedNamespace([]);
                      setCheckAll(false);
                      setIsChanged(true);
                    }}
                    style={{
                      fontFamily: 'Bai Jamjuree',
                      fontSize: '14px',
                      lineHeight: '17.5px',
                      textTransform: 'none',
                    }}
                  >
                    Clear All
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {list?.length > 0 ? (
                  list.map((item, index) => {
                    // console.log(item.namespace, 'itemnamespace');
                    // console.log(item.cluster, 'itemnamespace');
                    if (item?.sameness == true) {
                      const isItemSelected =
                        selectedNamespace?.length > 0
                          ? selectedNamespace.some((value) => {
                              // console.log(
                              //   value['clusters'][0],
                              //   "['clusters'][0]"
                              // );
                              // console.log(
                              //   value['namespace'] === item['namespace'] &&
                              //     value['clusters'][0] === item['clusters'][0]
                              // );
                              // console.log(value['cluster'] === item['cluster']);
                              return (
                                value['namespace'] === item['namespace'] &&
                                value['cluster'] === item['cluster']
                              );
                            })
                          : false;
                      return (
                        <TableRow
                          key={`${index}`}
                          selected={isItemSelected}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          hover
                        >
                          <TableCell
                            style={{
                              padding: '0px 20px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#12153E',
                            }}
                          >
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                selectRow(
                                  event,
                                  item['namespace'],
                                  item['cluster']
                                )
                              }
                              checked={isItemSelected}
                            />
                          </TableCell>

                          <TableCell
                            style={{
                              padding: '12px 0px 12px 20px',
                              /*lineHeight: '20px',*/
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                color: '#12153E',
                                lineHeight: '20px',
                              }}
                            >
                              {item['namespace']}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              padding: '20px 20px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#12153E',
                            }}
                          >
                            <div
                              style={{
                                border: '1px solid green',
                                borderRadius: '30px',
                                textAlign: 'center',
                                background: '#E9FAF6',
                              }}
                            >
                              <label
                                style={{ color: 'green', fontSize: '14px' }}
                              >
                                {`Namespace sameness`}
                              </label>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      // console.log('selectedNamespace', selectedNamespace);
                      const isItemSelected =
                        selectedNamespace?.length > 0
                          ? selectedNamespace.some((value) => {
                              // console.log(value, 'value');
                              // console.log('value', value, 'item', item);
                              // console.log(
                              //   value['clusters'][0],
                              //   "['clusters'][0]"
                              // );
                              // console.log(
                              //   value['namespace'] === item['namespace'] &&
                              //     value['clusters'][0] === item['clusters'][0]
                              // );
                              return (
                                value['namespace'] == item['namespace'] &&
                                value['cluster'] == item['cluster']
                              );
                            })
                          : false;

                      return (
                        <TableRow
                          key={`${item?.cluster}-${index}`}
                          selected={isItemSelected}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          hover
                        >
                          <TableCell
                            style={{
                              padding: '0px 20px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#12153E',
                            }}
                          >
                            <Checkbox
                              color="primary"
                              onChange={(event) =>
                                selectRow(
                                  event,
                                  item['namespace'],
                                  item['cluster']
                                )
                              }
                              checked={isItemSelected}
                            />
                          </TableCell>
                          <TableCell
                            style={{
                              padding: '12px 0px 12px 20px',
                              /*lineHeight: '20px',*/
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                color: '#12153E',
                                lineHeight: '20px',
                              }}
                            >
                              {item['namespace']}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '14px',
                                color: '#5A607C',
                                lineHeight: '17.5px',
                              }}
                            >
                              {item['cluster']}
                            </Typography>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      );
                    }
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>No Namespace to display</TableCell>
                  </TableRow>
                )}
              </TableBody>
            }
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
