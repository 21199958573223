import {
  SET_SLICES,
  ACTION_LOGOUT_INITIATED,
  SET_SLICE_GATEWAYS,
  CLEAR_SLICE_GATEWAYS,
  SET_SLICE_CLUSTERS,
  SET_CLUSTER_GATEWAYS,
  SET_SLICE_SERVICE_EXPORTS,
  SET_SLICE_SERVICE_IMPORTS,
} from "../actions/types";

const initialState = {
  sliceList: [],
  sliceGateways: [],
  sliceResourceQuota: [],
  sliceClusters: [],
  clusterGateways: {},
  serviceImports: {},
  serviceExports: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SLICES:
      return {
        ...state,
        sliceList:
          action.payload.slices === undefined ? [] : action.payload.slices,
      };
    case SET_SLICE_GATEWAYS:
      return {
        ...state,
        sliceGateways: action.payload,
      };
    case SET_SLICE_CLUSTERS:
      return {
        ...state,
        sliceClusters: action.payload,
      };
    case CLEAR_SLICE_GATEWAYS:
      return {
        ...state,
        sliceGateways: [],
      };
    case SET_CLUSTER_GATEWAYS:
      return {
        ...state,
        clusterGateways: { ...state.clusterGateways, ...action.payload },
      };
    case SET_SLICE_SERVICE_EXPORTS:
      return {
        ...state,
        serviceExports: action.payload,
      };
    case SET_SLICE_SERVICE_IMPORTS:
      return {
        ...state,
        serviceImports: action.payload,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
