import { START_SPINNER, STOP_SPINNER } from "./types";

export const startSpinner = () => {
    // console.log('Spinning!');
    return (dispatch, getState) => {
        dispatch({ type: START_SPINNER });
    };
}

export const stopSpinner = () => {
    // console.log('Spinning Stop!');
    return (dispatch, getState) => {
        dispatch({ type: STOP_SPINNER });
    };
}