import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { CustomPaginator } from '../../../Namespaces/Steps/InitialStep/NamespaceTable';
import { ReactComponent as Checked } from '../../../../assets/icons/Checked.svg';

export default function NamespaceTable({
  selectedNamespace,
  setSelectedNamespace,
  searchText,
  search,
  searchBy,
  updateFormChangeStat,
  isolationFlag,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const clusterList = useSelector(
    (state) => state.RegisterCluster.clusterList?.items
  );
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const [rowCount, setRowCount] = useState(0);
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);

  useEffect(() => {
    if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
      setDisableOffboardCluster(true);
    } else {
      setDisableOffboardCluster(false);
    }
  });

  const formatList = (list) => {
    const results = [];
    list?.forEach((item) => {
      const foundIndex =
        results?.length > 0
          ? results.findIndex((resultItem) => {
            return resultItem.namespace === item.namespace;
          })
          : -1;

      if (results?.length > 0 && foundIndex >= 0) {
        results[foundIndex]['clusters'].push(item?.cluster);
      } else {
        results.push({
          namespace: item.namespace,
          clusters: [item.cluster],
        });
      }
    });
    return results;
  };
  useEffect(() => {
    let itemArr = [];
    if (clusterList) {
      const clusterForSlice = clusterList.filter((cluster) => {
        return currentSlice?.spec?.clusters.includes(cluster?.metadata?.name);
      });

      clusterForSlice.map((cluster) => {
        cluster?.status?.namespaces &&
          cluster?.status?.namespaces.map((namespace) => {
            // Creating custom object
            const itemObj = {
              namespace: namespace?.name,
              cluster: cluster?.metadata?.name,
            };

            // Checking on whether the cluster and namespace includes inside the onboarded namespace list
            const proceed =
              currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces.every(
                (ns) => {
                  return !(
                    ns.namespace === namespace?.name &&
                    ns.clusters.includes(cluster?.metadata?.name)
                  );
                }
              );
            proceed && itemArr.push(itemObj);
          });
      });

      if (search && searchText !== '') {
        itemArr = itemArr.filter((item) => {
          return searchBy === 'namespace'
            ? item.namespace === searchText
            : item.cluster === searchText;
        });
      }

      setRowCount(itemArr.length);
      itemArr = itemArr.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      const formattedArr = formatList(itemArr);
      setList(formattedArr);
    }
  }, [
    clusterList,
    currentSlice,
    search,
    searchText,
    searchBy,
    page,
    rowsPerPage,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const selectRow = (event, namespace, cluster) => {
    const isItemSelected = event.target.checked;
    const results = selectedNamespace ? [...selectedNamespace] : [];
    const foundIndex =
      results?.length > 0
        ? results.findIndex((selected) => {
          return selected.namespace === namespace;
        })
        : -1;

    if (isItemSelected) {
      if (results?.length > 0 && foundIndex >= 0) {
        if (Array.isArray(cluster)) {
          results[foundIndex]['clusters'] = cluster;
        } else {
          results[foundIndex]['clusters'].push(cluster);
        }
      } else {
        results.push({
          namespace: namespace,
          clusters: Array.isArray(cluster) ? [...cluster] : [cluster],
        });
      }
    } else {
      if (
        results[foundIndex]['clusters']?.length > 1 &&
        !Array.isArray(cluster)
      ) {
        const clusterIndex = results[foundIndex]['clusters'].indexOf(cluster);
        results[foundIndex]['clusters'].splice(clusterIndex, 1);
      } else {
        results.splice(foundIndex, 1);
      }
    }
    // Updating the state change flag
    updateFormChangeStat();

    setSelectedNamespace(results);
  };

  const selectAllRows = () => {
    const allChecked = list?.every((item) =>
      selectedNamespace.some(
        (namespace) => namespace.namespace === item.namespace
      )
    );
    if (allChecked) {
      const filteredList = selectedNamespace.filter(
        (namespace) =>
          !list?.some((item) => item.namespace === namespace.namespace)
      );
      setSelectedNamespace(filteredList);
    } else {
      setSelectedNamespace([...selectedNamespace, ...list]);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRight: '1px solid #D9DFEE',
        flex: 6,
      }}
    >
      <TableContainer>
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow style={{ height: '45px' }}>
              <TableCell
                sx={{
                  // padding: '24px 15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                  borderBottom: 0,
                  textAlign: 'center',
                  padding: 0,
                }}
              >
                <Checkbox
                  color="primary"
                  onChange={() => selectAllRows()}
                  disabled={isolationFlag}
                  checked={list?.every((item) =>
                    selectedNamespace?.some(
                      (namespace) => namespace.namespace === item.namespace
                    )
                  )}
                  checkedIcon={<Checked />}
                  disabled={!isolationFlag || DisableOffboardCluster}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: '15px 24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                  borderBottom: 0,
                }}
              >
                NAMESPACE
              </TableCell>
              <TableCell
                sx={{
                  padding: '15px 24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                  borderBottom: 0,
                }}
              >
                CLUSTER NAME
              </TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {list?.length > 0 ? (
                list.map((item, index) => {
                  return item?.clusters?.map((cluster) => {
                    const isItemSelected =
                      selectedNamespace?.length > 0
                        ? selectedNamespace.some((value) => {
                          return (
                            value['clusters'].includes(cluster) &&
                            value['namespace'] === item['namespace']
                          );
                        })
                        : false;

                    return (
                      <TableRow
                        disabled={DisableOffboardCluster}
                        key={`${cluster}-${index}`}
                        selected={isItemSelected}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        hover
                        style={{
                          borderTop: 0,
                        }}
                      >
                        <TableCell
                          sx={{
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#12153E',
                            textAlign: 'center',
                            paddingY: '5px',
                          }}
                        >
                          <Checkbox
                            disabled={DisableOffboardCluster}
                            color="primary"
                            onChange={(event) =>
                              selectRow(event, item['namespace'], cluster)
                            }
                            checked={isItemSelected}
                            disabled={!isolationFlag}
                            checkedIcon={<Checked />}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: '16px 0px 16px 24px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#12153E',
                          }}
                        >
                          {item['namespace']}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: '16px 0px 16px 24px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#12153E',
                          }}
                        >
                          {cluster}
                        </TableCell>
                      </TableRow>
                    );
                  });
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No Namespace to display</TableCell>
                </TableRow>
              )}
            </TableBody>
          }
        </Table>
      </TableContainer>
      <CustomPaginator
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
