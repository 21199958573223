import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { kiToMi } from '../../../../utils/functions';
import { ValueWithUsage } from '../ValueWithUsage';
import { canRemove } from './utils';
import { schema } from './validation-schema';

const AddResource = ({
  sliceName,
  resourceData,
  handleResourceData,
  resetResourceQuotaData,
  step,
  currentResourceQuota,
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [editVal, setEditVal] = useState('');
  const [editData, setEditData] = useState(null);
  const isEditable = step === '1';
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  // validate on mount

  const can_remove_default_cpu_request = canRemove(currentResourceQuota, 'cpu');

  const can_remove_default_memory_request = canRemove(
    currentResourceQuota,
    'memory'
  );

  const can_remove_default_storage_request = canRemove(
    currentResourceQuota,
    'ephemeralStorage'
  );

  useEffect(() => {
    const values = resourceData.reduce((obj, item) => {
      let value = item.total;

      if (item.total === 0 || item.total === '0') {
        value = '';
      }

      return {
        ...obj,
        [item.name]: value,
      };
    }, {});

    schema
      .validate(
        {
          ...values,
          can_remove_default_cpu_request,
          can_remove_default_memory_request,
          can_remove_default_storage_request,
        },
        {
          abortEarly: false,
        }
      )
      .then(() => {
        setErrors({});
      })
      .catch((error) => {
        const { inner } = error;
        if (inner) {
          let local_errors = {};
          inner.forEach((error) => {
            local_errors[error.path] = error.message;
          });
          setErrors(local_errors);
        }
        // this is risky
        handleResourceData(resourceData, true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCellClick = (data, i) => {
    setEditIndex(i);
    if (data.total === 0) {
      setEditVal('');
    } else {
      setEditVal(data.total);
    }
    setEditData(data);
  };

  const handleClear = () => {
    resetResourceQuotaData();
  };

  const getValue = (Name) => {
    return resourceData.find(({ name }) => name === Name)?.total ?? '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updated_data = resourceData.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          total: value !== '' ? Number.parseInt(value) : '',
          value: value !== '' ? Number.parseInt(value) : '',
          isEdited: true,
        };
      }
      return item;
    });

    const values = updated_data.reduce((obj, item) => {
      let value = item.total;

      if (item.total === 0 || item.total === '0') {
        value = '';
      }
      return {
        ...obj,
        [item.name]: value,
      };
    }, {});

    schema
      .validate(
        {
          ...values,
          can_remove_default_cpu_request,
          can_remove_default_memory_request,
          can_remove_default_storage_request,
        },
        {
          abortEarly: false,
        }
      )
      .then(() => {
        setErrors({});
        handleResourceData(updated_data, false);
      })
      .catch((error) => {
        const { inner } = error;
        if (inner) {
          let local_errors = {};
          inner.forEach((error) => {
            local_errors[error.path] = error.message;
          });
          setErrors(local_errors);
        }
        handleResourceData(updated_data, true);
      });
  };
  const handleBlur = (event) => {
    if (event && event.currentTarget) {
      setTouched((oldTouched) => ({
        ...oldTouched,
        [event.currentTarget.name]: true,
      }));
    }
    setEditIndex(null);
  };

  // used to display values
  const pods_count = getValue('Pods Count');
  const cpu_limit = getValue('CPU Limit');
  const cpu_request = getValue('CPU Request');
  const container_cpu_request = getValue('Container CPU Request');
  const memory_limit = getValue('Memory Limit');
  const memory_request = getValue('Memory Request');
  const container_memory_request = getValue('Container Memory Request');
  const container_storage_request = getValue('Container Storage Request');
  const storage_limit = getValue('Ephemeral Storage Limit');
  const storage_request = getValue('Ephemeral Storage Request');

  const slice_userdata =
    currentResourceQuota.spec?.resourceQuota?.spec?.sliceQuota?.resources
      ?.userdata;

  // memory and storage comes in ki, we need to convert to Mi
  const userdata = {
    ...slice_userdata,
    memory: kiToMi(slice_userdata.memory),
    memoryRequest: kiToMi(slice_userdata.memoryRequest),
    ephemeralStorage: kiToMi(slice_userdata.ephemeralStorage),
    ephemeralStorageRequest: kiToMi(slice_userdata.ephemeralStorageRequest),
  };

  return (
    <Grid item className="add-resource-container">
      <Paper
        elevation={0}
        className="add-resource-paper"
        sx={{
          border: '1px solid #B6B8D6',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '2px 24px 24px 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography>Slice : </Typography>{' '}
            <Typography style={{ display: 'inline-block', fontWeight: '600' }}>
              {sliceName}
            </Typography>
          </Box>
          <Button
            onClick={handleClear}
            className="btn-outlined"
            variant="outlined"
          >
            Clear all values
          </Button>
        </Box>
        <TableContainer
          className="table-container"
          data-testid="slice_detail_table"
        >
          <Table aria-label="collapsible table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{ width: '14%', padding: '15px 4px 15px 24px' }}
                  className="txt-bold table-header-cell"
                >
                  NO.OF PODS
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '15px 4px 15px 24px' }}
                  className="txt-bold table-header-cell"
                >
                  CPU LIMIT (m)
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '15px 4px 15px 24px' }}
                  className="txt-bold table-header-cell">
                  CPU REQUEST (m)
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '15px 4px 15px 24px' }}
                  className="txt-bold table-header-cell"
                >
                  MEMORY LIMIT (Mi)
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '15px 4px 15px 24px', }}
                  className="txt-bold table-header-cell">
                  MEMORY REQUEST (Mi)
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '7.5px 4px 7.5px 24px', lineHeight: '30px' }}
                  className="txt-bold table-header-cell"
                >
                  EPHEMERAL STORAGE LIMIT (Mi)
                </TableCell>
                <TableCell
                  sx={{ width: '14%', padding: '7.5px 4px 7.5px 24px', lineHeight: '30px' }}
                  className="txt-bold table-header-cell">
                  EMPHEMERAL STORAGE REQUEST (Mi)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEditable ? (
                <TableRow sx={{ fontWeight: 500 }} data-testid="editable-row">
                  <TableCell
                    sx={{
                      verticalAlign: 'middle',
                      padding: '16px',
                      minWidth: '80px',
                    }}
                    data-testid="pod_count"
                  >
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Pods Count'] && touched['Pods Count']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Pods Count']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Pods Count'] && touched['Pods Count']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                        }}
                        variant="outlined"
                        name="Pods Count"
                        data-testid="pod_count_input"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {`${userdata?.podCount || 'NA'} /`}
                            </Box>
                          </InputAdornment>
                        }
                        value={getValue('Pods Count')}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                      minWidth: '80px',
                    }}
                  >
                    <>
                      <Tooltip
                        classes={{
                          popper: 'custom-error-tooltip',
                          arrow: 'custom-error-tooltip',
                        }}
                        arrow
                        open={!!errors['CPU Limit'] && touched['CPU Limit']}
                        title={
                          <>
                            <InfoIcon
                              sx={{
                                color: '#fff',
                                marginRight: '4px',
                                fontSize: '12px',
                              }}
                            />
                            {errors['CPU Limit']}
                          </>
                        }
                      >
                        <OutlinedInput
                          error={!!errors['CPU Limit'] && touched['CPU Limit']}
                          type="number"
                          sx={{
                            padding: '4px 4px 4px 12px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#2868F3',
                            },
                            '& .MuiOutlinedInput-input': {
                              padding: '0px',
                            },
                          }}
                          className="add-resource-custom-input"
                          variant="outlined"
                          name="CPU Limit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{
                            'data-testid': 'cpu_limit_input',
                          }}
                          startAdornment={
                            <InputAdornment
                              sx={{ marginRight: 0 }}
                              position="start"
                            >
                              <Box
                                sx={{
                                  fontSize: '12px',
                                }}
                              >
                                {`${userdata?.cpu || 'NA'} /`}
                              </Box>
                            </InputAdornment>
                          }
                          value={getValue('CPU Limit')}
                        />
                      </Tooltip>
                    </>
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px' }}>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['CPU Request'] && touched['CPU Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['CPU Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['CPU Request'] && touched['CPU Request']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        name="CPU Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'cpu_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box fontSize={'12px'}>{`${userdata?.cpuRequest || 'NA'
                              } /`}</Box>
                          </InputAdornment>
                        }
                        value={getValue('CPU Request')}
                      />
                    </Tooltip>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Container CPU Request'] && touched['Container CPU Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Container CPU Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Container CPU Request'] && touched['Container CPU Request']}
                        type="number"
                        sx={{
                          mt: 1,
                          px: 1,
                          py: 0,
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        className="container-input"
                        name="Container CPU Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'container_cpu_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          ></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              mr: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              / container
                            </Typography>
                          </InputAdornment>
                        }
                        value={getValue('Container CPU Request')}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                      minWidth: '80px',
                    }}
                  >
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Memory Limit'] && touched['Memory Limit']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Memory Limit']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Memory Limit'] && touched['Memory Limit']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                        }}
                        className="add-resource-custom-input"
                        variant="outlined"
                        name="Memory Limit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'memory_limit_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              {`${userdata?.memory || 'NA'} /`}
                            </Box>
                          </InputAdornment>
                        }
                        value={memory_limit}
                      />
                    </Tooltip>
                    {/*
                      <Tooltip
                        classes={{
                          popper: "custom-error-tooltip",
                          arrow: "custom-error-tooltip",
                        }}
                        arrow
                        open={error}
                        title={
                          <>
                            <InfoIcon
                              sx={{
                                color: "#fff",
                                marginRight: "4px",
                                fontSize: "12px",
                              }}
                            />
                            Enter a greater value
                          </>
                        }
                      >
                        <OutlinedInput
                          error={error}
                          type="number"
                          sx={{ mt: 1, width: "80%", px: 1, py: 0 }}
                          variant="outlined"
                          name="Container Memory Limit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          endAdornment={
                            <InputAdornment sx={{ marginRight: 0}}
                              position="end"
                              sx={{
                                mr: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                / container
                              </Typography>
                            </InputAdornment>
                          }
                          value={getValue("Container Memory Limit")}
                        />
                      </Tooltip>
                      */}
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px' }}>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Memory Request'] && touched['Memory Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Memory Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Memory Request'] && touched['Memory Request']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        name="Memory Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'memory_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box fontSize={'12px'}>
                              {`${userdata?.memoryRequest || 'NA'} /`}
                            </Box>
                          </InputAdornment>
                        }
                        value={getValue('Memory Request')}
                      />
                    </Tooltip>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Container Memory Request'] && touched['Container Memory Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Container Memory Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Container Memory Request'] && touched['Container Memory Request']}
                        type="number"
                        className="container-input"
                        sx={{
                          mt: 1,
                          px: 1,
                          py: 0,
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        name="Container Memory Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'container_memory_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          ></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              mr: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              / container
                            </Typography>
                          </InputAdornment>
                        }
                        value={getValue('Container Memory Request')}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                      minWidth: '80px',
                    }}
                  >
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Ephemeral Storage Limit'] && touched['Ephemeral Storage Limit']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Ephemeral Storage Limit']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Ephemeral Storage Limit'] && touched['Ephemeral Storage Limit']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                        }}
                        className="add-resource-custom-input"
                        variant="outlined"
                        name="Ephemeral Storage Limit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'storage_limit_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {`${userdata?.ephemeralStorage || 'NA'} /`}
                            </Box>
                          </InputAdornment>
                        }
                        value={getValue('Ephemeral Storage Limit')}
                      />
                    </Tooltip>
                    {/*
                      <Tooltip
                        classes={{
                          popper: "custom-error-tooltip",
                          arrow: "custom-error-tooltip",
                        }}
                        arrow
                        open={error}
                        title={
                          <>
                            <InfoIcon
                              sx={{
                                color: "#fff",
                                marginRight: "4px",
                                fontSize: "12px",
                              }}
                            />
                            Enter a greater value
                          </>
                        }
                      >
                        <OutlinedInput
                          error={error}
                          type="number"
                          sx={{ mt: 1, width: "80%", px: 1, py: 0 }}
                          variant="outlined"
                          name="Container Storage Limit"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          endAdornment={
                            <InputAdornment sx={{ marginRight: 0}}
                              position="end"
                              sx={{
                                mr: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                / container
                              </Typography>
                            </InputAdornment>
                          }
                          value={getValue("Container Storage Limit")}
                        />
                      </Tooltip>
                      */}
                  </TableCell>
                  <TableCell sx={{ padding: '16px 8px' }}>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Ephemeral Storage Request'] && touched['Ephemeral Storage Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Ephemeral Storage Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Ephemeral Storage Request'] && touched['Ephemeral Storage Request']}
                        type="number"
                        sx={{
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        name="Ephemeral Storage Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'storage_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          >
                            <Box
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {`${userdata?.ephemeralStorageRequest || 'NA'
                                } / `}
                            </Box>
                          </InputAdornment>
                        }
                        value={getValue('Ephemeral Storage Request')}
                      />
                    </Tooltip>
                    <Tooltip
                      classes={{
                        popper: 'custom-error-tooltip',
                        arrow: 'custom-error-tooltip',
                      }}
                      arrow
                      open={!!errors['Container Storage Request'] && touched['Container Storage Request']}
                      title={
                        <>
                          <InfoIcon
                            sx={{
                              color: '#fff',
                              marginRight: '4px',
                              fontSize: '12px',
                            }}
                          />
                          {errors['Container Storage Request']}
                        </>
                      }
                    >
                      <OutlinedInput
                        error={!!errors['Container Storage Request'] && touched['Container Storage Request']}
                        type="number"
                        className="container-input"
                        sx={{
                          mt: 1,
                          px: 1,
                          py: 0,
                          padding: '4px 4px 4px 12px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#2868F3',
                          },
                          '& .MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                          width: '150px',
                        }}
                        variant="outlined"
                        name="Container Storage Request"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          'data-testid': 'container_storage_request_input',
                        }}
                        startAdornment={
                          <InputAdornment
                            sx={{ marginRight: 0 }}
                            position="start"
                          ></InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{
                              mr: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              / container
                            </Typography>
                          </InputAdornment>
                        }
                        value={getValue('Container Storage Request')}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow sx={{ fontWeight: 500 }}>
                  <TableCell
                    sx={{
                      border: 0,
                      padding: '16px 0px 16px 24px',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Box
                      sx={{
                        height: '28px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ValueWithUsage
                        usage={userdata.podCount}
                        value={pods_count}
                        data-testid="pod_count"
                        valueError={step === '1' && errors['Pods Count']}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <Box
                      sx={{
                        height: '28px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ValueWithUsage
                        usage={userdata.cpu}
                        value={cpu_limit}
                        data-testid="cpu_limit"
                        valueError={step === '1' && errors['CPU Limit']}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <Box>
                      <ValueWithUsage
                        usage={userdata.cpuRequest}
                        value={cpu_request}
                        data-testid="cpu_request"
                        valueError={step === '1' && errors['CPU Request']}
                      />

                      <Box>
                        <ValueWithUsage
                          value={container_cpu_request}
                          valueError={
                            step === '1' && errors['Container CPU Request']
                          }
                          data-testid="default_cpu_request"
                          isContainer={true}
                        />
                      </Box>

                      {/* <Typography
                        color={
                          step === '1' && errors['Container CPU Request']
                            ? 'red'
                            : ''
                        }
                        data-testid='default_cpu_request'
                      >
                        {container_cpu_request === ''
                          ? 'NA'
                          : container_cpu_request}
                        <Box
                          sx={{
                            ml: 1,
                          }}
                          component='span'
                        >
                          /container
                        </Box>
                      </Typography> */}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <ValueWithUsage
                      usage={userdata.memory}
                      value={memory_limit}
                      valueError={step === '1' && errors['Memory Limit']}
                      data-testid="memory_limit"
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <Box>
                      <ValueWithUsage
                        usage={userdata.memoryRequest}
                        value={memory_request}
                        valueError={step === '1' && errors['Memory Request']}
                        data-testid="memory_request"
                      />

                      <Box>
                        <ValueWithUsage
                          isContainer={true}
                          value={container_memory_request}
                          valueError={
                            step === '1' && errors['Container Memory Request']
                          }
                          data-testid="default_memory_request"
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <Box>
                      <ValueWithUsage
                        usage={userdata.ephemeralStorage}
                        value={storage_limit}
                        valueError={
                          step === '1' && errors['Ephemeral Storage Limit']
                        }
                        data-testid="storage_limit"
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 0,
                      verticalAlign: 'middle',
                      padding: '16px 8px',
                    }}
                  >
                    <Box>
                      <ValueWithUsage
                        usage={userdata.ephemeralStorageRequest}
                        value={storage_request}
                        valueError={
                          step === '1' && errors['Ephemeral Storage Request']
                        }
                        data-testid="storage_request"
                      />
                      <Box>
                        <ValueWithUsage
                          value={container_storage_request}
                          valueError={
                            step === '1' && errors['Container Storage Request']
                          }
                          data-testid="default_storage_request"
                          isContainer
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default AddResource;
