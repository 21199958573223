import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { FooterBtns } from '../FooterBtns';
import NamespaceTable from '../Generic/NamespaceTable';
import SliceTable from '../Generic/SliceTable';
import CodePreview from './CodePreview';

export const ApplyYaml = () => {
  const history = useHistory();
  const [close, setClose] = useState(true);
  const [applied, setApplied] = useState(false);
  const [currHeight, setCurrHeight] = useState('0vh');
  const [openCodePreview, setOpenCodePreview] = useState(false);

  const handleNext = () => {
    history.push(`/assign-nodes/4`);
  };

  const handlePrev = () => {
    history.push(`/assign-nodes/2`);
  };

  const toggleOpen = () => {
    setOpenCodePreview((prev) => !prev);
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (
            location.pathname.includes('/assign-nodes/4') ||
            location.pathname.includes('/assign-nodes/2')
          ) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      <Box sx={{ margin: '10px 40px', overflow: 'auto', flex: '1 1 0%' }}>
        <Grid className="grid-item-main step-grid-item" item xs={12}>
          <SliceTable stepNo={3} />
        </Grid>
        <Grid
          className="grid-item-main step-grid-item"
          style={{ height: 'auto' }}
          item
          xs={12}
        >
          <NamespaceTable stepNo={3} />
        </Grid>
      </Box>
      <Box>
        <Grid
          item
          style={{
            position: openCodePreview && close ? 'fixed' : '',
            top: '0',
            bottom: '48px',
            width: openCodePreview ? 'calc(100% - 220px)' : '100%',
            height: openCodePreview ? 'calc(100% - 64px)' : '',
            right: '10px',
          }}
        >
          <CodePreview
            setClose={setClose}
            close={close}
            setCurrHeight={setCurrHeight}
            currHeight={currHeight}
            isOpen={openCodePreview}
            toggleOpen={toggleOpen}
          />
        </Grid>
      </Box>
      <Grid
      // className="bottom-btn-containe"
      // style={{
      //   position: "fixed",
      //   bottom: "-20px",
      //   width: "calc(100% - 220px)",
      //   height: "10vh",
      //   right: ".10px",
      // }}
      >
        <FooterBtns
          handleNext={handleNext}
          handlePrev={handlePrev}
          nextDisabled={!applied}
        />
      </Grid>
    </>
  );
};
