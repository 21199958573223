import React, { Component } from "react";
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Create from "@mui/icons-material/Create";
import Close from "@mui/icons-material/Close";
import styled from "styled-components";
const Table = styled(MuiTable)`
  background: white;
`;

const TableDataRow = styled(TableRow)`
  &:hover {
    box-shadow: 0 0 2px 1px grey;
    cursor: pointer;
  }
`;
const MultiContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: left;
  align-self: center;
  justify-content: left;
`;
export default class Tables extends Component {
  getValue = (obj, str) => {
    const arr = str.split(".");
    let value = "";
    if (!obj && isNaN(obj)) return value;
    else value = obj;
    for (var i = 0; i < arr.length; i++) {
      if (value[arr[i]] || value[arr[i]] == 0) value = value[arr[i]];
      else return "";
    }
    return value;
  };
  render() {
    const { size, config, data, editable, editListener, deleteEnable, deleteListener, rowListener } = this.props;
    const { columns } = config;
    return (
      <TableContainer style={{maxHeight: '50vh'}}>
        <Table size={size || "small"} stickyHeader>
          <TableHead>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{
                  color: "black",
                  fontWeight: "600",
                  background: "#DEE8FF",
                }}
                align="left"
              >
                {column.name}
              </TableCell>
            ))}
            {(editable || deleteEnable) && (
              <TableCell
                key={columns.length}
                style={{
                  color: "black",
                  fontWeight: "600",
                  background: "#DEE8FF",
                }}
                align="left"
              ></TableCell>
            )}
          </TableHead>
            <TableBody>
              {data &&
                data.map((row, index) => (
                  <TableDataRow
                    key={index}
                    onClick={() => {
                      if (rowListener) rowListener(row, index);
                    }}
                  >
                    {columns.map((column, index) => (
                      <TableCell key={index} style={{ color: "black", textAlign: "left", padding: "2px 13px" }} align="left">
                        <MultiContent>
                          {column.icon && column.iconPath}
                          {this.getValue(row, column.id) || column.default}
                        </MultiContent>
                      </TableCell>
                    ))}
                    {(editable || deleteEnable) && (
                      <TableCell key={index} style={{ color: "black" }} align="left">
                        <MultiContent>
                          {deleteEnable && <Close color="primary" style={{ flex: 1, margin: "3px" }} onClick={editListener} />}
                          {editable && <Create color="primary" style={{ flex: 1, margin: "3px" }} onClick={deleteListener} />}
                        </MultiContent>
                      </TableCell>
                    )}
                  </TableDataRow>
                ))}
            </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
