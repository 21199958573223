import * as React from 'react';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(
    props,
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress sx={{
                borderRadius: '50%',
                boxShadow: 'inset 0 0 1px 3px #d9dae6'
            }} value={props.progress} color="success" variant="determinate" />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    style={{
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: 18,
                        color: '#12153E'
                    }}
                >{props.value}</Typography>
            </Box>
        </Box>
    );
}

export default function CircularStatic({ value, total }) {
    let progress = (value / total) * 100
    if(value == total && value == 0) progress = 0;
    if(total == 0) progress = 0;
    return <CircularProgressWithLabel value={value} progress={progress} />;
}
