import React, { Component } from "react";
import { FormControlLabel, FormGroup } from "@mui/material";
import { FilterCategory } from "../../SliceStyles";
import CheckBox from "@mui/material/Checkbox";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { keyMap } from "../../../../utils/keyMap";
const camelToText = (camel) => {
  let text = camel[0]?.toUpperCase();
  for (var i = 1; i < camel.length; i++) {
    if (camel.charCodeAt(i) >= 65 && camel.charCodeAt(i) <= 90) {
      text += " " + camel[i];
    } else {
      text += camel[i];
    }
  }
  return text;
};
export default class Filter extends Component {
  render() {
    const {
      filterBy,
      filterCategory,
      changeFilterBy,
      changeFilterCategory,
    } = this.props;
    const categories = Object.keys(filterBy);
    const options = filterCategory
      ? Object.keys(filterBy[filterCategory].values)
      : null;
    console.table({ options });
    return (
      <div>
        <div>
          {filterCategory ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <KeyboardArrowLeftIcon
                  style={{ marginTop: "3px" }}
                  onClick={() => changeFilterCategory(null)}
                />
                <p style={{ fontWeight: "bold" }}>{camelToText(filterCategory)}</p>
              </div>
              <FormGroup style={{ paddingLeft: "1rem" }}>
                {options.map((option) => {
                  return (
                    <FormControlLabel
                      control={
                        <CheckBox
                          checked={filterBy[filterCategory].values[option]}
                          onChange={() => {
                            changeFilterBy(option);
                          }}
                          name={option}
                        />
                      }
                      label={option}
                    />
                  );
                })}
              </FormGroup>
            </>
          ) : (
            <div>
              <h5
                style={{
                  marginLeft: "1rem",
                  marginTop: "16px",
                  marginBottom: "8px",
                }}
              >
                Filter By
              </h5>
              {categories.map((cat) => (
                <FilterCategory onClick={() => changeFilterCategory(cat)}>
                  {filterBy[cat].label}
                </FilterCategory>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
