import { SdkBackend } from '../backend/MeshBackend';
import {
  CLEAR_CURRENT_RESOURCE_QUOTA,
  SET_CURRENT_RESOURCE_QUOTA,
  SET_DELETE_RQ,
  SET_ORIGINAL_CURRENT_RESOURCE_QUOTA,
  SET_RESOURCE_QUOTA_LIST,
  SET_UPDATED_CLUSTER_QUOTA,
} from './types';
const sdkBackend = new SdkBackend();

export const getResourceQuotaList = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getResourceQuotaList(getState(), dispatch);
    if (response) {
      const promises = response.items.map((item) => {
        return sdkBackend.getResourceQuotaPromCountsForSlice(
          getState(),
          dispatch,
          item.metadata.name
        );
      });
      const promData = await Promise.all(promises);
      response.items.forEach(async (resourceItem, index) => {
        if (resourceItem?.spec?.resourceQuota) {
          if (!resourceItem.spec.resourceQuota.spec?.sliceQuota) {
            resourceItem.spec.resourceQuota.spec.sliceQuota = {};
            resourceItem.isNew = true;
          }
          resourceItem.spec.resourceQuota.spec.sliceQuota.resources = {
            ...resourceItem.spec.resourceQuota.spec.sliceQuota.resources,
            userdata: {
              cpu: 0,
              ephemeralStorage: 0,
              podCount: 0,
              memory: 0,
            },
          };
          promData[index].forEach((sliceItem) => {
            resourceItem.spec.resourceQuota.spec.sliceQuota.resources[
              'userdata'
            ][sliceItem.metric.labels.resource_type] = sliceItem.value.value;
          });
        }
      });
      dispatch({ type: SET_RESOURCE_QUOTA_LIST, payload: response });
    }
  };
};

export const clearCurrentResourceQuota = () => {
  return {
    type: CLEAR_CURRENT_RESOURCE_QUOTA,
  };
};

export const setDeleteRQ = (payload) => {
  return {
    type: SET_DELETE_RQ,
    payload,
  };
};

export const setCurrentResourceQuota = (payload) => {
  return {
    type: SET_CURRENT_RESOURCE_QUOTA,
    payload,
  };
};

export const setUpdatedClusterQuota = (payload) => {
  return {
    type: SET_UPDATED_CLUSTER_QUOTA,
    payload,
  };
};

export const setOriginalResourceQuota = (payload) => {
  return {
    type: SET_ORIGINAL_CURRENT_RESOURCE_QUOTA,
    payload,
  };
};

export const updateCustomResourceQuotaObject = (resourceQuotaDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.updateCustomResourceQuotaObject(
      getState(),
      dispatch,
      resourceQuotaDetail
    );
    setTimeout(() => dispatch(getResourceQuotaList()), 1000);
    if (response) return response;
  };
};

export const addCustomResourceQuotaObject = (resourceQuotaDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.registerCustomResourceQuotaObject(
      getState(),
      dispatch,
      resourceQuotaDetail
    );
    setTimeout(() => dispatch(getResourceQuotaList()), 1000);
    if (response) return response;
  };
};

export const deleteCustomResourceQuotaObject = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.deleteCustomResourceQuotaObject(
      getState(),
      dispatch,
      sliceName
    );
    setTimeout(() => dispatch(getResourceQuotaList()), 1000);
    if (response) return response;
  };
};

export const getResourceQuotaPromCountsForNamespace = (
  sliceName,
  clusterName,
  namespace
) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getResourceQuotaPromCountsForNamespace(
      getState(),
      dispatch,
      sliceName,
      clusterName,
      namespace
    );

    // if (response) {
    const currentResourceQuota = {
      ...getState().resourceQuota.currentResourceQuota,
    };
    currentResourceQuota?.spec?.resourceQuota?.spec?.clusterQuota?.forEach(
      (cluster) => {
        if (cluster.clusterName === clusterName) {
          cluster.namespaceQuota.forEach((namespaceQuota) => {
            if (namespaceQuota.namespace === namespace) {
              namespaceQuota.resources = {
                ...namespaceQuota.resources,
                userdata: {
                  cpu: 0,
                  ephemeralStorage: 0,
                  podCount: 0,
                  memory: 0,
                  cpuRequest: 0,
                  ephemeralStorageRequest: 0,
                  memoryRequest: 0,
                },
              };
              response?.forEach((namespaceItem) => {
                namespaceQuota.resources['userdata'][
                  namespaceItem.metric.labels.resource_type
                ] = namespaceItem.value.value;
              });
            }
          });
        }
      }
    );
    dispatch({
      type: SET_CURRENT_RESOURCE_QUOTA,
      payload: currentResourceQuota,
    });
    // dispatch({ type: SET_RESOURCE_QUOTA_LIST, payload: currentResourceQuota });
    //}
  };
};
