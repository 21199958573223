import { SdkBackend } from '../backend/MeshBackend';
import { getMeshBackend } from '../backend/MeshBackendProxy';
import { SET_REGISTER_CLUSTER_LIST } from './types';

const meshBackend = getMeshBackend();

const sdkBackend = new SdkBackend();

export const getRegisteredClusters = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getRegisteredClusters(getState(), dispatch);

    if (response)
      dispatch({ type: SET_REGISTER_CLUSTER_LIST, payload: response });
  };
};

export const createCustomClusterObject = (clusterDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.createCustomClusterObject(
      getState(),
      dispatch,
      clusterDetail
    );
    if (response) {
      setTimeout(() => dispatch(getRegisteredClusters()), 1000);
      return response;
    }
  };
};
export const updateCustomClusterObject = (clusterDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.updateCustomClusterObject(
      getState(),
      dispatch,
      clusterDetail
    );
    setTimeout(() => dispatch(getRegisteredClusters()), 1000);
    if (response) return response;
  };
};
export const deleteCustomClusterObject = (clusterName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.deleteCustomClusterObject(
      getState(),
      dispatch,
      clusterName
    );
    if (response) {
      setTimeout(() => dispatch(getRegisteredClusters()), 1000);
    }
  };
};

export const getClusterSecret = (clusterName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getClusterSecret(
      getState(),
      dispatch,
      clusterName
    );
    if (response) return response;
  };
};
