import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { IconButton, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import React from 'react';
import './index.css';

const Charts = ({ activeChartTypeMetics, data, activeTimeMetrics }) => {
  const [fullScreen, setFullScreen] = React.useState(false);
  const [fullScreenIndex, setFullScreenIndex] = React.useState();

  const getXAxisData = (xdata, type) => {
    if (xdata === null || Object.keys(xdata).length === 0) {
      return [];
    } else {
      if (type === 'pod') {
        if (Array.isArray(xdata[type]?.actualCount)) {
          let time = xdata[type]?.actualCount?.map((el) => el.time);
          return time;
        } else {
          return [];
        }
      }
      if (Array.isArray(xdata[type]?.ActualUtilization)) {
        let time = xdata[type]?.ActualUtilization?.map((el) => el.time);

        return time;
      } else return [];
    }
  };

  const getYAxisData = (yData, type, legendType) => {
    if (yData === null || Object.keys(yData).length === 0) {
      return [];
    } else {
      if (yData?.[type]?.[legendType]) {
        if (
          Object.keys(yData).length > 0 &&
          Array.isArray(yData?.[type]?.[legendType])
        ) {
          if (type === 'memory' || type === 'ephemeral') {
            let vals = yData[type][legendType]?.map((el) =>
              el.value !== '' ? el.value / 1024 : ''
            );

            return vals;
          } else {
            let vals = yData[type][legendType]?.map((el) => el.value);

            return vals;
          }
        } else {
          return [];
        }
      } else return [];
    }
  };

  const showOrHideLegends = (data, type, legendType) => {
    if (Object.keys(data).length > 0 && type && legendType) {
      if (data?.[type]?.legendType !== null)
      {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const cpuObj = {
    title: 'CPU',
    data: ['Limit', 'Usage', 'Request', 'Request Usage'],
    xAxis: 'Time',
    yAxis: 'm (millicores)',
    xData: getXAxisData(data, 'cpu'),
    ActualUtilization: getYAxisData(data, 'cpu', 'ActualUtilization'),
    ActualRequestUtilization: getYAxisData(
      data,
      'cpu',
      'ActualRequestUtilization'
    ),
    UtilizationLimit: getYAxisData(data, 'cpu', 'UtilizationLimit'),
    RequestLimit: getYAxisData(data, 'cpu', 'RequestLimit'),
    showLegend: showOrHideLegends(data, 'cpu', 'RequestLimit'),
    unit: 'm',
  };
  const memObj = {
    title: 'Memory',
    data: ['Limit', 'Usage', 'Request', 'Request Usage'],
    xAxis: 'Time',
    yAxis: 'Mi',
    xData: getXAxisData(data, 'memory'),
    ActualUtilization: getYAxisData(data, 'memory', 'ActualUtilization'),
    ActualRequestUtilization: getYAxisData(
      data,
      'memory',
      'ActualRequestUtilization'
    ),
    UtilizationLimit: getYAxisData(data, 'memory', 'UtilizationLimit'),
    RequestLimit: getYAxisData(data, 'memory', 'RequestLimit'),
    showLegend: showOrHideLegends(data, 'memory', 'RequestLimit'),
    unit: 'Mi',
  };
  const EphemeralObj = {
    title: 'Ephemeral Storage',
    data: ['Limit', 'Usage', 'Request', 'Request Usage'],
    xAxis: 'Time',
    yAxis: 'Mi',
    xData: getXAxisData(data, 'ephemeral'),
    ActualUtilization: getYAxisData(data, 'ephemeral', 'ActualUtilization'),
    ActualRequestUtilization: getYAxisData(
      data,
      'ephemeral',
      'ActualRequestUtilization'
    ),
    UtilizationLimit: getYAxisData(data, 'ephemeral', 'UtilizationLimit'),
    RequestLimit: getYAxisData(data, 'ephemeral', 'RequestLimit'),
    showLegend: showOrHideLegends(data, 'ephemeral', 'RequestLimit'),
    unit: 'Mi',
  };

  const podObj = {
    title: 'Pod Count',
    data: ['Limit', 'Usage'],
    xAxis: 'Time',
    yAxis: 'Number',
    xData: getXAxisData(data, 'pod'),
    ActualCount: getYAxisData(data, 'pod', 'actualCount'),
    UtilizationCount: getYAxisData(data, 'pod', 'utilizationCount'),
    showLegend: showOrHideLegends(data, 'pod', 'utilizationCount'),
    unit: '',
  };

  const formatTitle = (data, type) => {
    if (type !== 'pod' && data?.[type]?.['ActualUtilization']?.length > 0) {
      return {
        show: false,
      };
    } else if (type === 'pod' && data?.[type]?.['actualCount']?.length > 0) {
      return {
        show: false,
      };
    } else {
      return {
        text: 'No Graph Data',
        textStyle: {
          color: '#B2B4CA',
          fontWeight: '500',
          fontFamily: 'Bai Jamjuree',
          fontSize: 20,
          lineHeight: 14,
        },
        backgroundColor: '#ffffff',
        left: 'center',
        top: 'center',
      };
    }
  };

  const option = (type) => {
    let obj =
      type == 'cpu'
        ? cpuObj
        : type === 'memory'
        ? memObj
        : type === 'ephemeral'
        ? EphemeralObj
        : podObj;

    return {
      title: formatTitle(data, type),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          lineStyle: { type: 'solid', width: '2', color: '#000000' },
          label: {
            backgroundColor: '#6a7985',
            show: false,
            formatter: function (params) {
              if (params.axisDimension === 'x') {
                return moment(params.value).format(
                  'dddd, MMMM Do YYYY, h:mm:ss a'
                );
              }
            },
          },
        },
        formatter: function (params) {
          let formattedData = [];
          for (let i = 0; i < params.length; i++) {
            formattedData.push(`
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-height: 1.7rem">
                                <div style="margin-right: 1.7rem;">
                                    <span style="display: inline-block; margin-right: 4px; border-radius: 2px; width: 10px; height: 10px; background-color: ${
                                      params[i].color
                                    }"></span>
                                    ${params[i].seriesName}: 
                                </div>
                                <p style="margin-left: auto; font-weight: bold; color: '#fff',">
                                    ${
                                      params[i].value !== ''
                                        ? params[i].value + obj.unit
                                        : ''
                                    }
                                <p>
                            </div>
                        `);
          }
          return `
                <div style="display: flex; flex-direction: column">
                ${moment(params[0]?.axisValue).format(
                  'dddd, MMMM Do YYYY, h:mm:ss a'
                )}
                    ${formattedData.join('')}
                </div>
        `;
        },
      },
      legend: {
        data: obj.data,
        bottom: fullScreen ? '10%' : '4%',
        show: obj.showLegend,
        itemGap: 15,
        textStyle: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontFamily: 'Bai Jamjuree',
          color: '#5A607C',
          fontSize: 10,
          lineHeight: 14,
          padding: [0, 0, 0, 0],
          align: 'left',
        },
        icon: 'square',
      },
      grid: fullScreen
        ? {
            left: '5%',
            right: '5%',
            bottom: '18%',
            containLabel: true,
          }
        : {
            left: '6%',
            right: '6%',
            containLabel: true,
          },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: obj.xData,
        name: obj.xAxis,
        nameLocation: 'middle',
        axisLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            opacity: 0.3,
            width: 1,
          },
        },
        axisLabel: {
          show: true,
          margin: 10,
          formatter: function (value, index) {
            if ([3, 4, 5].includes(activeTimeMetrics)) {
              return moment(value).format('MMM DD, HH:mm');
            } else {
              return moment(value).format('HH:mm');
            }
          },
        },
        nameTextStyle: {
          color: 'rgba(18, 21, 62, 1)',
          fontStyle: 'normal',
          fontWeight: 'bolder',
          lineHeight: 14,
          padding: [10, 0, 40, 0],
          fontSize: 12,
          fontFamily: 'Bai Jamjuree',
          align: 'center',
          verticalAlign: 'top',
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            opacity: 0.3,
          },
        },
        axisLabel: {
          show: true,
          margin: 16,
        },
        name: obj.yAxis,
        nameLocation: 'end',
        nameTextStyle: {
          color: 'rgba(18, 21, 62, 1)',
          fontStyle: 'normal',
          fontWeight: 'bolder',
          lineHeight: 14,
          padding: [0, 0, 0, 0],
          fontSize: 12,
          fontFamily: 'Bai Jamjuree',
          align: 'center',
          verticalAlign: 'bottom',
        },
      },
      series:
        activeChartTypeMetics.label === 'Area' && type !== 'pod'
          ? [
              {
                name: 'Limit',
                type: 'line',
                //step: 'start',
                data: obj.UtilizationLimit,
                color: '#CE7CEB',
                areaStyle: {},
                showSymbol: false,
              },
              {
                name: 'Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj.ActualUtilization,
                color: '#C32121',
                areaStyle: {},
                showSymbol: false,
              },
              {
                name: 'Request',
                type: 'line',
                //step: 'start',
                data: obj.RequestLimit,
                color: '#38D489',
                areaStyle: {},
                showSymbol: false,
              },
              {
                name: 'Request Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj.ActualRequestUtilization,
                color: '#5145BD',
                areaStyle: {},
                showSymbol: false,
              },
            ]
          : activeChartTypeMetics.label === 'Area' && type === 'pod'
          ? [
              {
                name: 'Limit',
                type: 'line',
                //step: 'start',
                data: obj?.UtilizationCount,
                color: '#CE7CEB',
                areaStyle: {},
                showSymbol: false,
              },
              {
                name: 'Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj?.ActualCount,
                color: '#C32121',
                areaStyle: {},
                showSymbol: false,
              },
            ]
          : activeChartTypeMetics.label === 'Line' && type !== 'pod'
          ? [
              {
                name: 'Limit',
                type: 'line',
                //step: 'start',
                data: obj.UtilizationLimit,
                color: '#CE7CEB',
                showSymbol: false,
              },
              {
                name: 'Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj.ActualUtilization,
                color: '#C32121',
                showSymbol: false,
              },
              {
                name: 'Request',
                type: 'line',
                //step: 'start',
                data: obj.RequestLimit,
                color: '#38D489',
                showSymbol: false,
              },
              {
                name: 'Request Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj.ActualRequestUtilization,
                color: '#5145BD',
                showSymbol: false,
              },
            ]
          : [
              {
                name: 'Limit',
                type: 'line',
                //step: 'start',
                data: obj?.UtilizationCount,
                color: '#CE7CEB',
                showSymbol: false,
              },
              {
                name: 'Usage',
                type: 'line',
                lineStyle: {
                  type: 'dashed',
                },
                data: obj?.ActualCount,
                color: '#C32121',
                showSymbol: false,
              },
            ],
      dataZoom: [
        {
          show: fullScreen,
          bottom: '3%',
        },
      ],
    };
  };
  const handleClick = () => {
    setFullScreen(false);
    setFullScreenIndex(1);
  };

  return (
    <>
      {!fullScreen && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '16px',
              columnGap: '16px',
            }}
          >
            <div
              style={{
                width: '50%',
                border: '1px solid #B6B8D6',
                borderRadius: '4px',
                height: '300px',
                position: 'relative',
              }}
            >
              <IconButton
                className="closeIco"
                onClick={() => {
                  setFullScreen(true);
                  setFullScreenIndex(1);
                }}
              >
                <OpenInFullIcon color="#5A607C" fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '2%',
                  left: '2%',
                  color: '#12153E',
                  fontWeight: '600',
                  fontFamily: 'Bai Jamjuree',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                Pod Count
              </Typography>
              <ReactECharts
                option={option('pod')}
                style={{ width: '100%', height: '100%', paddingLeft: '4px' }}
                notMerge={true}
              />
            </div>
            <div
              style={{
                width: '50%',
                border: '1px solid #B6B8D6',
                borderRadius: '4px',
                height: '300px',
                position: 'relative',
              }}
            >
              <IconButton
                aria-label="add"
                //  size="small"
                //  color="primary"
                className="closeIco"
                onClick={() => {
                  setFullScreen(true);
                  setFullScreenIndex(2);
                }}
              >
                <OpenInFullIcon color="#5A607C" fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '2%',
                  left: '2%',
                  color: '#12153E',
                  fontWeight: '600',
                  fontFamily: 'Bai Jamjuree',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                {' '}
                CPU
              </Typography>
              <ReactECharts
                option={option('cpu')}
                style={{ width: '100%', height: '100%', paddingLeft: '4px' }}
                notMerge={true}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '16px',
              columnGap: '16px',
            }}
          >
            <div
              style={{
                width: '50%',
                border: '1px solid #B6B8D6',
                borderRadius: '4px',
                height: '300px',
                position: 'relative',
              }}
            >
              <IconButton
                className="closeIco"
                onClick={() => {
                  setFullScreen(true);
                  setFullScreenIndex(3);
                }}
              >
                <OpenInFullIcon color="#5A607C" fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '2%',
                  left: '2%',
                  color: '#12153E',
                  fontWeight: '600',
                  fontFamily: 'Bai Jamjuree',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                {' '}
                Memory
              </Typography>
              <ReactECharts
                option={option('memory')}
                style={{ width: '100%', height: '100%' }}
                notMerge={true}
              />
            </div>
            <div
              style={{
                width: '50%',
                border: '1px solid #B6B8D6',
                borderRadius: '4px',
                height: '300px',
                position: 'relative',
              }}
            >
              <IconButton
                className="closeIco"
                onClick={() => {
                  setFullScreen(true);
                  setFullScreenIndex(4);
                }}
              >
                <OpenInFullIcon color="#5A607C" fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '2%',
                  left: '2%',
                  color: '#12153E',
                  fontWeight: '600',
                  fontFamily: 'Bai Jamjuree',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                Ephemeral Storage
              </Typography>
              <ReactECharts
                option={option('ephemeral')}
                style={{ width: '100%', height: '100%' }}
                notMerge={true}
              />
            </div>
          </div>
        </>
      )}
      {fullScreen && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '16px',
            height: '600px',
          }}
        >
          <div
            style={{
              width: '100%',
              border: '1px solid #B6B8D6',
              borderRadius: '4px',
              height: '100%',
              position: 'relative',
            }}
          >
            {fullScreenIndex === 1 && (
              <>
                <IconButton
                  aria-label="add"
                  //  size="small"
                  //  color="primary"
                  className="closeIco"
                  onClick={handleClick}
                >
                  <CloseFullscreenIcon color="#5A607C" fontSize="small" />
                </IconButton>
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '1%',
                    left: '1%',
                    color: '#12153E',
                    fontWeight: '600',
                    fontFamily: 'Bai Jamjuree',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  Pod Count
                </Typography>
                <ReactECharts
                  option={option('pod')}
                  style={{ width: '100%', height: '100%' }}
                  notMerge={true}
                />
              </>
            )}
            {fullScreenIndex === 2 && (
              <>
                <IconButton
                  aria-label="add"
                  //  size="small"
                  //  color="primary"
                  className="closeIco"
                  onClick={handleClick}
                >
                  <CloseFullscreenIcon color="#5A607C" fontSize="small" />
                </IconButton>
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '1%',
                    left: '1%',
                    color: '#12153E',
                    fontWeight: '600',
                    fontFamily: 'Bai Jamjuree',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  {' '}
                  CPU
                </Typography>
                <ReactECharts
                  option={option('cpu')}
                  style={{ width: '100%', height: '100%' }}
                  notMerge={true}
                />
              </>
            )}
            {fullScreenIndex === 3 && (
              <>
                <IconButton
                  aria-label="add"
                  //  size="small"
                  //  color="primary"
                  className="closeIco"
                  onClick={handleClick}
                >
                  <CloseFullscreenIcon color="#5A607C" fontSize="small" />
                </IconButton>
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '1%',
                    left: '1%',
                    color: '#12153E',
                    fontWeight: '600',
                    fontFamily: 'Bai Jamjuree',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  Memory
                </Typography>
                <ReactECharts
                  option={option('memory')}
                  style={{ width: '100%', height: '100%' }}
                  notMerge={true}
                />
              </>
            )}
            {fullScreenIndex === 4 && (
              <>
                <IconButton
                  aria-label="add"
                  //  size="small"
                  //  color="primary"
                  className="closeIco"
                  onClick={handleClick}
                >
                  <CloseFullscreenIcon color="#5A607C" fontSize="small" />
                </IconButton>
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '1%',
                    left: '1%',
                    color: '#12153E',
                    fontWeight: '600',
                    fontFamily: 'Bai Jamjuree',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  Ephemeral Storage
                </Typography>
                <ReactECharts
                  option={option('ephemeral')}
                  style={{ width: '100%', height: '100%' }}
                  notMerge={true}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Charts;
