import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import _ from 'underscore';
import {
  selectAffinityProfiles,
  selectSliceNamepaces,
} from '../../../selectors/NodeAffinity.selector';
import './index.css';

export default function NodeTable({
  selectedNamespace,
  selectNamespace,
  search,
  searchBy,
}) {
  const clusterNamespaces = useSelector((state) => selectSliceNamepaces(state));

  const affinity_profiles = useSelector((state) =>
    selectAffinityProfiles(state)
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);

  const isNamespaceEmpty = Object.keys(clusterNamespaces).length === 0;

  const count = Object.entries(clusterNamespaces)
    .map(([_, namespaces]) => namespaces)
    .reduce((total, current) => {
      return total + current.length;
    }, 0);

  const selectRow = (namespace) => {
    const shouldSelect =
      !_.isEqual(selectedNamespace, namespace) || !selectedNamespace;

    selectNamespace(shouldSelect ? namespace : null);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRight: '1px solid lightgray',
      }}
    >
      {/* <Prompt
        message={(location, action) => {
          if (
            location.pathname === '/assign-nodes/3' ||
            ('/assign-nodes/2' && action === 'PUSH')
          ) {
            return true; */}
      <Prompt
        message={(location, action) => {
          if (
            location.pathname.includes('/assign-nodes/3') ||
            location.pathname.includes('/assign-nodes/1')
          ) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      <TableContainer sx={{ flex: 1 }}>
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Node Table"
          size="small"
          data-testid="AssigntoNames2"
        >
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                style={{
                  padding: '24px 15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                CHOOSE NAMESPACE
              </TableCell>
              <TableCell
                style={{
                  padding: '24px 15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="state"
              >
                STATE
              </TableCell>
            </TableRow>
          </TableHead>
          {
            <TableBody>
              {!isNamespaceEmpty ? (
                Object.entries(clusterNamespaces).map(
                  ([cluster, namespaces]) => {
                    return namespaces
                      .filter((namespace) => {
                        if (searchBy === 'cluster') {
                          return cluster.includes(search);
                        } else if (searchBy === 'namespace') {
                          return namespace.includes(search);
                        }
                        return namespace;
                      })
                      .map((namespace, idx) => {
                        const isItemSelected =
                          selectedNamespace?.namespace === namespace &&
                          selectedNamespace?.cluster === cluster;

                        const hasNodeLabels = affinity_profiles.some(
                          (profile) => {
                            if (profile.cluster === cluster) {
                              return profile.nodeAffinityRules.some((rule) => {
                                if (rule.namespace === namespace) {
                                  return rule.nodeSelectorLabels?.length > 0;
                                }
                                return false;
                              });
                            }
                            return false;
                          }
                        );

                        return (
                          <TableRow
                            data-testid={`${cluster}-${namespace}`}
                            key={idx}
                            selected={isItemSelected}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            hover
                            onClick={() => selectRow({ cluster, namespace })}
                            // onClick={() => { setSelected(item) }}
                          >
                            <TableCell
                              style={{
                                padding: '18px 16px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#12153E',
                              }}
                            >
                              <Stack>
                                <span>{namespace}</span>
                                <Box
                                  sx={{
                                    fontSize: '14px',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: '400',
                                    }}
                                    component="span"
                                  >
                                    {cluster}
                                  </Box>
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '18px 16px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                //   fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#12153E',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: hasNodeLabels ? 700 : 500,
                                  color: hasNodeLabels ? 'green' : '#81839A',
                                }}
                              >
                                {hasNodeLabels
                                  ? 'Nodes Added'
                                  : 'No Nodes Added'}
                              </Typography>
                              {/* <Button
                          style={{
                            color: "#2868F3",
                            textTransform: "none",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setParentModalOpen(true);
                            setCurrentNode(item);
                          }}
                        >
                          View
                        </Button> */}
                            </TableCell>
                          </TableRow>
                        );
                      });
                  }
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>No Node to display</TableCell>
                </TableRow>
              )}
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ borderTop: '1px solid lightgray' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
