import {
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentSliceRoleList,
  setSelectedRole,
} from '../../../../actions/RbacActions';
import { ReactComponent as Error } from '../../LandingPage/Error.svg';
import Fallback from './Fallback';
import './index.css';

export default function ChooseRoles({
  search,
  searchText,
  searchBy,
  setOpenViewModal,
  modalBox,
  setRoleCount,
}) {
  //props details
  //search,searchText,SearchBy - for search logic
  //setSelectedRole - to set the role that user clicks
  //selectedRole - selected role
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const selectedRole = useSelector((state) => state.RbacReducer.selectedRole);
  const dispatch = useDispatch();

  useEffect(() => {
    var newArr = [];
    if (
      currentSlice?.metadata?.bindings &&
      (!currentSlice?.metadata?.data ||
        currentSlice?.metadata?.data?.length == 0)
    ) {
      currentSlice?.metadata?.bindings?.map((row) => {
        if (
          row?.roleRef?.kind === 'SliceRoleTemplate' &&
          row?.roleRef?.apiVersion === 'controller.kubeslice.io/v1alpha1'
        ) {
          row?.applyTo?.map((data) => {
            data?.subjects?.map((x) => {
              if (newArr?.length == 0) {
                let obj = {
                  roleName: [row?.roleRef?.name],
                  apiKind: row?.roleRef?.kind,
                  apiversion: row?.roleRef?.apiVersion,
                  name: x?.name,
                  kind: x?.kind,
                  namespaces: data?.namespace
                    ? [{ namespace: data?.namespace, cluster: data?.cluster }]
                    : [],
                };
                newArr.push(obj);
              } else {
                let index = newArr.findIndex((item) => {
                  return item?.name === x?.name && item?.kind === x?.kind;
                });
                if (index >= 0) {
                  if (
                    data?.namespace &&
                    //!newArr[index]?.namespaces?.includes({namespace:data?.namespace,cluster:data?.cluster})
                    !newArr[index]?.namespaces.some(function (element) {
                      return (
                        JSON.stringify(element) ===
                        JSON.stringify({
                          namespace: data?.namespace,
                          cluster: data?.cluster,
                        })
                      );
                    })
                  ) {
                    newArr[index]?.namespaces.push({
                      namespace: data?.namespace,
                      cluster: data?.cluster,
                    });
                  }
                  if (!newArr[index]?.roleName.includes(row?.roleRef?.name)) {
                    newArr[index]?.roleName.push(row?.roleRef?.name);
                  }
                } else {
                  let obj = {
                    roleName: [row?.roleRef?.name],
                    apiKind: row?.roleRef?.kind,
                    apiversion: row?.roleRef?.apiVersion,
                    name: x?.name,
                    kind: x?.kind,
                    namespaces: data?.namespace
                      ? [{ namespace: data?.namespace, cluster: data?.cluster }]
                      : [],
                  };
                  newArr.push(obj);
                }
              }
            });
          });
        }
      });
      if (currentSlice?.metadata?.status?.roleRefConditions) {
        currentSlice?.metadata?.status?.roleRefConditions?.map((error) => {
          const getAllIndexes = newArr
            ?.map((item, idx) =>
              item?.roleName?.includes(error?.roleRef?.name) &&
              item?.apiKind === error?.roleRef?.kind &&
              item?.apiversion === error?.roleRef?.apiVersion
                ? idx
                : ''
            )
            .filter(String);
          if (getAllIndexes) {
            getAllIndexes?.map((index) => {
              if (index >= 0) {
                if (newArr[index]?.errObj) {
                  newArr[index]?.errObj?.message.push(error?.message);
                } else {
                  let errObj = {
                    error: true,
                    message: [error?.message],
                  };
                  let updatedObj = { ...newArr[index], errObj };
                  newArr[index] = updatedObj;
                }
              }
            });
          }
        });
      }
      let updatedCurrentSlice = { ...currentSlice };
      updatedCurrentSlice.metadata.data = newArr;
      dispatch(setCurrentSliceRoleList(updatedCurrentSlice));
    }
  }, []);

  useEffect(() => {
    let newArr = currentSlice?.metadata?.data;

    if (search && searchText !== '') {
      newArr = newArr.filter((item) => {
        return searchBy === 'roles'
          ? item?.roleName
              ?.join(', ')
              .toUpperCase()
              ?.includes(searchText.toUpperCase())
          : searchBy === 'userGroup'
          ? item?.kind === 'Group' &&
            item?.name?.toUpperCase().includes(searchText.toUpperCase())
          : searchBy === 'user'
          ? item?.kind === 'User' &&
            item?.name?.toUpperCase().includes(searchText.toUpperCase())
          : '';
      });
    }
    setRowCount(newArr?.length);
    newArr = newArr?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setRowData(newArr);
    setRoleCount && setRoleCount(newArr?.length);
  }, [page, rowsPerPage, search, searchText, searchBy, currentSlice]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const setSelected = (item) => {
    dispatch(setSelectedRole(item));
  };

  const isSelected = (item) => {
    return (
      item?.kind === selectedRole?.kind && item?.name === selectedRole?.name
    );
  };

  const getErrorMessage = (messages) => {
    if (messages) {
      return (
        <List
          sx={{
            '& ul': { padding: 0 },
            listStyleType: 'disc',
          }}
          subheader={<li />}
        >
          {messages.map((message, idx) => (
            <ListItem key={idx}>
              <li>{message}</li>
            </ListItem>
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <div className="rbac-role-table">
      <div>
        <TableContainer className="rbac-table-container-step2">
          <Table
            aria-labelledby="Namespace Table"
            size="large"
            style={{ width: '100%' }}
          >
            <TableHead className="table-head">
              <TableRow
                style={{
                  padding: '15px 0px 15px 24px',
                }}
              >
                {modalBox === true && (
                  <TableCell
                    style={{
                      padding: '15px 0px 15px 24px',
                      fontFamily: 'Bai Jamjuree',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#12153E',
                      width: '10%',
                    }}
                  >
                    NO
                  </TableCell>
                )}
                <TableCell
                  style={{
                    padding: '15px 0px 15px 24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '50%',
                  }}
                >
                  {modalBox === true ? 'ROLES' : 'CHOOSE ROLES'}
                </TableCell>
                {modalBox !== true && (
                  <TableCell
                    style={{
                      padding: '15px 0px 15px 24px',
                      fontFamily: 'Bai Jamjuree',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#12153E',
                      width: '50%',
                    }}
                  >
                    ROLES WITH NAMESPACES ASSIGNED
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {
              <TableBody>
                {rowData?.length > 0 ? (
                  rowData.map((item, index) => {
                    return (
                      <TableRow
                        key={`${index}`}
                        selected={isSelected(item)}
                        hover
                        onClick={() => {
                          setSelected(item);
                        }}
                        sx={{"&.Mui-selected":{backgroundColor: '#F4F8FF'}, backgroundColor:'white'}}
                      >
                        {modalBox === true && (
                          <TableCell
                            style={{
                              padding: '12px 0px 12px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#12153E',
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                color: '#12153E',
                                lineHeight: '20px',
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell
                          style={{
                            padding: '12px 0px 12px 24px',
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              color: '#12153E',
                              lineHeight: '20px',
                            }}
                          >
                            {item?.roleName.join(', ')}
                            {item?.errObj?.error && (
                              <Tooltip
                                title={getErrorMessage(item?.errObj?.message)}
                              >
                                <span
                                  style={{
                                    height: '8px',
                                    width: '8px',
                                    margin: '12px 10px',
                                  }}
                                >
                                  <Error />
                                </span>
                              </Tooltip>
                            )}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              color: '#5A607C',
                              lineHeight: '17.5px',
                            }}
                          >
                            {item.kind == 'User' ? 'User' : 'User Group'} :{' '}
                            {item.name}
                          </Typography>
                        </TableCell>
                        {modalBox !== true && (
                          <TableCell
                            style={{
                              padding: '12px 0px 12px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#12153E',
                            }}
                          >
                            {item?.namespaces?.length > 0 ? (
                              <Link
                                underline="hover"
                                style={{ color: '#2868F3', cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenViewModal(true);
                                }}
                              >
                                View all
                              </Link>
                            ) : (
                              <Typography
                                style={{
                                  fontFamily: 'Bai Jamjuree',
                                  fontStyle: 'italic',
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  color: '#81839A',
                                  lineHeight: '17.5px',
                                }}
                              >
                                No Namespaces Assigned
                              </Typography>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} style={{ border: 'none' }}>
                      <Fallback />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            }
          </Table>
        </TableContainer>
        {rowData?.length > 0 && (
          <div className="pagination">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
