import * as React from 'react';
import aws from '../../assets/images/aws.png';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import awsLogo from '../../assets/images/awsLogo.svg';

export const AWSTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={awsLogo} img={aws} />;
};

function AWS(props) {
  return <CommonCloud {...props} img={aws} />;
}

export default AWS;
