import { ContentCopy } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLicenseDetails } from '../../../selectors/licensing.selector';

const LicenseId = () => {
  const licenseDetails = useSelector(selectLicenseDetails);
  const { 'license-id': licenseId } = licenseDetails;
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(licenseId);
    setCopied(true);
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timer);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#EEF3FF',
        padding: '8px 12px 8px 28px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          component="p"
          sx={{
            color: '#828490',
          }}
        >
          License ID:{' '}
          <Box
            sx={{
              color: '#5A607C',
              fontWeight: '500',
            }}
            component="span"
          >
            {licenseId}
          </Box>
        </Box>
        <Button
          type="button"
          sx={{
            padding: '6px',
            minWidth: 'unset',
            borderRadius: '4px',
            backgroundColor: copied ? '#2868F3' : '',
            '&:hover': {
              backgroundColor: copied ? '#2868F3' : '',
            },
          }}
          onClick={copyToClipboard}
        >
          <ContentCopy
            sx={{
              height: '28px',
              width: '28px',
              color: copied ? '#fff' : '#828490',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default LicenseId;
