import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteModal = ({ handleClose, open = false, isNamespace = false }) => {

    return (
        <Dialog
            sx={{
                maxWidth: '528px',
                margin:'0px auto',
                minHeight:'252px'
            }}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='modal-title' id="alert-dialog-title">
                {isNamespace ? <>Do you want to toggle off the enforcement ?</> : <>Do you want to clear all Values ?</>}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className='modal-desc' id="alert-dialog-description">
                   {isNamespace ? <>Turning the toggle off on this namespace will remove the resource quotas that were previously applied.</> 
                   : <>if you clear all values at the slice level, all the enforced namespaces values will also be cleared </>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className='cancel-btn' onClick={() => handleClose(false)}>No</Button>
                <Button className='submit-btn' onClick={() => handleClose(true)} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteModal;