import { Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

export default function SearchBar({
  currentData = [],
  placeholder = 'Search by name',
  setRowsData,
  rows = [],
  source = '',
}) {
  const [searched, setSearched] = React.useState('');
  const requestSearch = (e) => {
    if (e?.target?.value) {
      const filteredRows = currentData?.filter((row) => {
        if (source === 'manage_resources') {
          return row.metadata.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return row.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setSearched(e.target.value);
      setRowsData(filteredRows);
    } else {
      setSearched('');
      setRowsData(currentData);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '40px',
          overflow: 'hidden',
          border: '1px solid #B6B8D6',
          margin: '24.5px 0px 24.5px 16px',
          borderRadius: '4px',
          width: '308px',
          padding: '1px 0px 0px 16px;',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          onChange={requestSearch}
          value={searched}
          inputProps={{
            'aria-label': 'weight',
            style: {
              padding: 3,
            },
          }}
        />
        <IconButton className="search-icon" type="button">
          <Search />
        </IconButton>
      </div>
    </>
  );
}
