import {
  ACTION_LOGOUT_INITIATED,
  SET_REGISTER_CLUSTER_LIST,
} from "../actions/types";

const initialState = {
  clusterList: {},
};

export default function RegisterClusterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REGISTER_CLUSTER_LIST:
      return {
        ...state,
        clusterList: action.payload,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
