import _ from "lodash";

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const LightenDarkenColor = (colorCode, amount) => {
  var usePound = false;

  if (colorCode[0] == '#') {
    colorCode = colorCode.slice(1);
    usePound = true;
  }

  var num = parseInt(colorCode, 16);

  var r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  var b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  var g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const createSearchRegex = (query) => {
  let _query = '';
  // const specialCharacters = ["\\", "^", "$", ".", "|", "?", "*", "+", "(", ")"];
  const specialCharactersMap = {
    '\\': 1,
    '^': 1,
    $: 1,
    '.': 1,
    '|': 1,
    '?': 1,
    '*': 1,
    '+': 1,
    '(': 1,
    ')': 1,
  };
  for (let i in query) {
    let char = query[i];
    if (specialCharactersMap[char] == 1) {
      _query += '\\' + char;
    } else {
      _query += char;
    }
  }
  return _query;
};

export const verifyNamespaceFormat = (namespace) => {
  return typeof namespace == 'string' && namespace.split(':').length == 2;
};

export const verifyHierarchyNamespaceFormat = (namespace) => {
  return typeof namespace == 'string' && namespace.split(':').length <= 3;
};

export const orderByEnforced = (clusters) => {
  let itemArr = [];
  clusters.forEach((cluster) => {
    cluster.namespaceQuota.forEach((item) => {
      if (item.enforceQuota) {
        itemArr.unshift(cluster);
      } else {
        itemArr.push(cluster);
      }
    });
  });
  const dupItemArr = _.uniqWith(itemArr, _.isEqual);
  return dupItemArr;
};

export const handleConversion = (field, value) => {
  if (field === 'cpu') {
    return value
      ? value != 0 /*&& value != 'NaNm'*/ && Number(value) % 1 !== 0
        ? value.slice(-1) === 'u'
          ? value.slice(0, -1) / 1000
          : value.slice(-1) === 'k'
          ? value.slice(0, -1) * 1000000
          : value.slice(0, -1)
        : Number(value) % 1 === 0
        ? value * 1000
        : 0
      : '';
  } else if (field == 'memory' || field === 'ephemeralStorage') {
    return value
      ? value != 0 /*&& value != 'NaNMi'*/
        ? value.slice(-2) === 'Gi'
          ? value.slice(0, -2) * 1024
          : value.slice(-2) === 'Ki'
          ? value.slice(0, -2) / 1024
          : value.slice(-2) === 'Mi'
          ? value.slice(0, -2)
          : value.slice(-1) === 'm'
          ? value.slice(0, -1) / (1024 * 1024 * 1000)
          : value
        : 0
      : '';
  } else return value;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const cpuParser = (input) => {
  const milliMatch = input.match(/^([0-9]+)m$/);
  if (milliMatch) {
    return milliMatch[1] / 1000;
  }

  return parseFloat(input);
};

const memoryMultipliers = {
  k: 1000,
  M: 1000 ** 2,
  G: 1000 ** 3,
  T: 1000 ** 4,
  P: 1000 ** 5,
  E: 1000 ** 6,
  Ki: 1024,
  Mi: 1024 ** 2,
  Gi: 1024 ** 3,
  Ti: 1024 ** 4,
  Pi: 1024 ** 5,
  Ei: 1024 ** 6,
};

export const memoryParser = (input) => {
  if (!input) return '';
  const unitMatch = input.match(/^([0-9]+)([A-Za-z]{1,2})$/);
  if (unitMatch) {
    return parseInt(unitMatch[1], 10) * memoryMultipliers[unitMatch[2]];
  }

  return parseInt(input, 10);
};

// this does the same thing
export const storageParser = (input) => {
  return memoryParser(input);
};

export const kiToMi = (value) => {
  if (value || value === 0) {
    return Math.round((value / 1024) * 100) / 100;
  }
};
