import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSliceClusters,
  selectSliceLabels,
} from '../../../selectors/NodeAffinity.selector';
import './index.css';
import LabelsTable from './LabelsTable';
import SelectedNode from './SelectedNode';
import { search_labels_by, stringifyLabel } from './utils';

export default function InitialStep() {
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState(search_labels_by.label);
  const clusters = useSelector((state) => selectSliceClusters(state));
  const [selectedCluster, setSelectedCluster] = useState();

  const labels = useSelector((state) =>
    selectSliceLabels(state, selectedCluster)
  );

  const searchLabels = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangeSearchBy = (e) => {
    const { value } = e.target;
    if (value === search_labels_by.cluster && !selectedCluster) {
      setSelectedCluster(clusters[0]);
    } else {
      setSelectedCluster(null);
    }
    setSearchBy(e.target.value);
  };

  const handleClusterChange = (e) => {
    setSelectedCluster(e.target.value);
  };

  return (
    <div className="step-content">
      <Box sx={{ padding: '24px 24px 13px 24px' }} className="step-content-header">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              height: '40px',
              overflow: 'hidden',
              border: '1px solid #B6B8D6',
              marginRight: '24px',
              borderRadius: '9px',
            }}
          >
            <FormControl
              className="filter-dropdown"
              sx={{ backgroundColor: '#E6E8F3', top: '0px', height: '44px' }}
            >
              <InputLabel id="search-by-label"></InputLabel>
              <Select
                data-testid="SelectLabelsorClusters"
                labelId="search-by-label"
                id="search-by"
                value={searchBy}
                onChange={handleChangeSearchBy}
                label="Search By"
                style={{ height: '40px' }}
                renderValue={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              >
                <MenuItem data-testid="MenuItem1" value="label">
                  Search by Label
                </MenuItem>
                <MenuItem data-testid="MenuItem2" value="cluster">
                  Search by Clusters
                </MenuItem>
              </Select>
            </FormControl>
            {searchBy === search_labels_by.cluster ? (
              <FormControl
                className="filter-dropdown"
                sx={{
                  width: 250,
                  backgroundColor: '#E6E8F3',
                  top: '0px',
                  height: '44px',
                }}
              >
                <InputLabel id="search-by-label"></InputLabel>
                <Select
                  data-testid="selectclusters1"
                  labelId="search-by-label"
                  id="search-by"
                  value={selectedCluster}
                  onChange={handleClusterChange}
                  label="Select Cluster"
                  style={{ height: '40px' }}
                >
                  {clusters.map((cluster) => (
                    <MenuItem
                      data-testid="selectclusteropt"
                      value={cluster}
                      key={cluster}
                    >
                      {cluster}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Paper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 250,
                  borderRadius: '0 5px 5px 0',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Labels"
                  onChange={searchLabels}
                  inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton
                  className="search-icon"
                  sx={{ p: '10px' }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}
          </div>
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <LabelsTable
          searchText={searchText}
          searchBy={searchBy}
          labels={
            searchBy === search_labels_by.label
              ? labels.filter((label) => {
                  const stringified_label = stringifyLabel(label);
                  return stringified_label.includes(searchText);
                })
              : labels
          }
          cluster={selectedCluster}
        />
        <SelectedNode />
      </Box>
    </div>
  );
}
