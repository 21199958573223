import { DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const DeleteModal = ({ handleClose, subtitle, onAccept, open = false, title }) => {
  return (
    <Dialog
      sx={{
        maxWidth: '528px',
        margin: '0px auto',
        minHeight: '252px',
      }}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="modal-title" id="alert-dialog-title">
        {title ?? 'Do you want to delete selected labels?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="modal-desc" id="alert-dialog-description">
          {subtitle ?? ''}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="cancel-btn" onClick={handleClose}>
          No
        </Button>
        <Button className="submit-btn" onClick={onAccept}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
