import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './index.css';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { deleteRoleBinding } from '../../../../actions/RbacActions';
import DeleteModal from './DeleteModal';
import K8Roles from './K8Roles.jsx';
import SelectRoles from './SelectRoles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const history = useHistory();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
      <div className="bottom-btn-container">
        <div>
          <Button
            onClick={() => history.push('/manage-rbacs/add/2')}
            style={{ width: '100px', marginRight: '10px', top: '8%' }}
            className="btn"
            variant="contained"
            disabled={props.isNextDisabled}
          >
            Next <ArrowForwardIos style={{ fontSize: '12px' }} />
          </Button>
        </div>
      </div>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function InitialStep({ setBreadcrumb }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSelectRolesValid, setIsSelectRolesValid] = useState(true);

  // this should be current_slice_name
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList.metadata.name
  );

  if (currentSlice) {
    setBreadcrumb(currentSlice);
  }
  // current slice
  const cSlice = useSelector((state) => state.RbacReducer.currentSliceRoleList);

  /*const nextBtnDisabled =
      currentSlice.importk8Roles.length < 1 &&
      currentSlice.metadata?.bindings?.length === 0;*/
  ///below useEffect is for next button Functionality and needs to be re-verified
  useEffect(() => {
    if (cSlice.metadata?.bindings?.length > 0) {
      let count = 0;
      if (!isSelectRolesValid) {
        count++;
      }
      cSlice?.metadata?.bindings.forEach((row) => {
        row?.applyTo?.forEach((data) => {
          data?.subjects?.forEach((x) => {
            if (x?.kind === '' || x?.name === '' || row?.roleRef?.name === '') {
              count++;
            }
          });
        });
      });
      if (count > 0) {
        setIsNextDisabled(true);
      } else if (cSlice.importk8Roles.length > 0) {
        cSlice?.importk8Roles.forEach((row) => {
          row?.applyTo?.forEach((data) => {
            data?.subjects?.forEach((x) => {
              if (
                x?.kind === '' ||
                x?.name === '' ||
                row?.roleRef?.name === '' ||
                row?.roleRef?.apiVersion === '' ||
                row?.roleRef?.apiVersion.includes('/') === false ||
                row?.roleRef?.kind === ''
              ) {
                count++;
              }
            });
          });
        });
        if (count > 0) {
          setIsNextDisabled(true);
        } else {
          setIsNextDisabled(false);
        }
      } else {
        setIsNextDisabled(false);
      }
    } else if (cSlice.importk8Roles.length > 0) {
      let count = 0;
      cSlice?.importk8Roles.forEach((row) => {
        row?.applyTo?.forEach((data) => {
          data?.subjects?.forEach((x) => {
            if (
              x?.kind === '' ||
              x?.name === '' ||
              row?.roleRef?.name === '' ||
              row?.roleRef?.apiVersion === '' ||
              row?.roleRef?.apiVersion.includes('/') === false ||
              row?.roleRef?.kind === ''
            ) {
              count++;
            }
          });
        });
      });
      if (count > 0) {
        setIsNextDisabled(true);
      } else {
        setIsNextDisabled(false);
      }
    } else {
      setIsNextDisabled(true);
    }
  }, [cSlice]);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isNextDisabled, setIsNextDisabled] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalClose = (del) => {
    if (del) {
      dispatch(deleteRoleBinding({ sliceName: currentSlice }));
      setOpenDeleteModal(false);
      history.push('/manage-rbac');
    } else {
      setOpenDeleteModal(false);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  // useEffect(() => {
  //   if (cSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = cSlice?.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);

  return (
    <div className="step-content">
      <div className="step-main-content">
        <Box sx={{ width: '100%' }}>
          <Box>
            <DeleteModal
              open={openDeleteModal}
              handleClose={handleModalClose}
            ></DeleteModal>
            <Button
              disabled={DisableOffboardCluster}
              style={{
                fontFamily: 'Bai Jamjuree',
                fontSize: '14px',
                lineHeight: '17.5px',
                textTransform: 'none',
                float: 'right',
              }}
              variant="text"
              onClick={() => setOpenDeleteModal(true)}
              sx={{ positon: 'right' }}
            >
              Clear All
            </Button>
            <Tabs sx={{ "& .MuiTabs-indicator": { backgroundColor: '#2868F3' } }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={{ "&.Mui-selected": { color: '#12153E' }, color: '#81839A', textTransform: 'none', fontSize: '16px', fontWeight: '600', lineHeight: '20px', padding: '24px 0px 12px 0px', margin: '0px 12px 0px 24px' }} label="Select roles" {...a11yProps(0)} />
              <Tab sx={{ "&.Mui-selected": { color: '#12153E' }, color: '#81839A', textTransform: 'none', fontSize: '16px', fontWeight: '600', lineHeight: '20px', padding: '24px 0px 12px 0px', margin: '0px 12px 0px 12px' }} label="Import k8s Roles" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} isNextDisabled={isNextDisabled}>
            <SelectRoles setIsSelectRolesValid={setIsSelectRolesValid} />
          </TabPanel>
          <TabPanel value={value} index={1} isNextDisabled={isNextDisabled}>
            <K8Roles />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
