import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Link,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Radio,
  Dialog,
  DialogContent,
  DialogTitle,
  InputBase,
  Typography,
  Paper,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './index.css';
import { setCurrentSlice } from '../../../actions/namespaceActions';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutline from '@mui/icons-material/ErrorOutlineOutlined';
import WatchLater from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import componentName from '../../../constants/componentName.json';

export default function Row(props) {
  const { slice, radioDisable = false } = props;
  const [open, setOpen] = useState(false);
  const [namespaceModal, setNamespaceModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const [clusterDetails, setClusterDetails] = useState({});
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(false);

  const namespaceList =
    location.pathname === '/manage-namespaces'
      ? slice?.spec?.namespaceIsolationProfile?.applicationNamespaces
      : currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces;

  const totalNamespaceCount =
    namespaceList &&
    namespaceList.reduce((total, item) => {
      return total + item.clusters.length;
    }, 0);

  const isChecked = useMemo(() => {
    return currentSlice?.metadata?.name === slice?.metadata?.name;
  }, [slice, currentSlice]);

  let filterKSEvents = (kubesliceEvents, type) =>
    kubesliceEvents.filter((data) => {
      return data.type === type;
    });

  let tooltipStatus = (ksEvents) => (
    <div>
      {ksEvents.map((data) => (
        <div key={data.reason}>
          {data.reason && <p>Reason: {data.reason}</p>}
          {data.components && (
            <>
              Components:{' '}
              <ul>
                {data.components.map((component) => (
                  <li key={component}>
                    {componentName[component.split('/')[0]]}
                  </li>
                ))}
              </ul>
            </>
          )}
          {data.action && <p>Actions: {data.action}</p>}
        </div>
      ))}
    </div>
  );

  let status = null;
  if (slice?.status?.kubesliceEvents?.length > 0) {
    if (
      slice.status.kubesliceEvents.some((data) => data.type === 'InProgress')
    ) {
      status = 'InProgress';
    } else {
      status = 'Error';
    }
  }

  const renderIcon = () => {
    let ksEvents = slice?.status?.kubesliceEvents;
    switch (status) {
      case 'InProgress':
        let inProgressText = tooltipStatus(
          filterKSEvents(ksEvents, 'InProgress')
        );
        return (
          <span style={{ position: 'relative', right: '3px', top: '3px' }}>
            <Tooltip title={inProgressText} arrow>
              <WatchLater fontSize="small" color="warning" />
            </Tooltip>
          </span>
        );
      case 'Error':
        let errorText = tooltipStatus(filterKSEvents(ksEvents, 'Error'));
        return (
          <span style={{ position: 'relative', right: '3px', top: '3px' }}>
            <Tooltip title={errorText} arrow>
              <ErrorIcon fontSize="small" color="error" />
            </Tooltip>
          </span>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((clusterName, key) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == clusterName
        );
        list[clusterName] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion,
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });

      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ padding: '0px', textAlign: 'center' }}>
          <IconButton
            aria-label="expand row"
            size="large"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ width: '40px', height: '40px' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ width: '40px', height: '40px' }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{ paddingLeft: '0px' }}
          className="txt-bold"
          component="th"
          scope="row"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '24px',
            }}
          >
            {!radioDisable && (
              <Radio
                checked={isChecked}
                onChange={() => {
                  dispatch(setCurrentSlice(slice));
                }}
                name="slice-row"
                inputProps={{ 'aria-label': 'selected slice row' }}
                style={{ marginLeft: '-11px' }}
              />
            )}
            <Typography style={{ marginRight: '8px', fontSize: '16px' }}>
              {slice.metadata?.name}
            </Typography>
            {renderIcon()}
          </div>
        </TableCell>

        <TableCell className="txt-bold" component="th" scope="row">
          {namespaceList?.length > 0 ? (
            <Link
              underline="hover"
              style={{
                color: '#2868F3',
                cursor: 'pointer',
                marginLeft: '11px',
                fontSize: '16px',
              }}
              onClick={() => setNamespaceModal(true)}
            >
              View all
            </Link>
          ) : (
            <Typography
              sx={{
                fontStyle: 'italic',
                fontSize: '16px',
                fontFamily: 'Bai Jamjuree',
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#81839A',
                marginLeft: '11px',
              }}
            >
              No Namespaces added
            </Typography>
          )}
        </TableCell>
        <TableCell
          className="txt-bold"
          component="th"
          scope="row"
          style={{ marginLeft: '11px', fontSize: '16px' }}
        >
          {slice.metadata?.annotations?.desc}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex', justifyContent: 'center' }}>
              <Table
                size="small"
                style={{ border: '1px solid lightgray', margin: '24px 40px' }}
                aria-label="purchases"
              >
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell sx={{ fontSize: '12px' }} className="txt-bold">
                      CLUSTERS
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} className="txt-bold">
                      DEPLOYMENT LOCATION
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }} className="txt-bold">
                      CLOUD TYPE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slice.spec?.clusters &&
                    slice.spec?.clusters.map((cluster, index) => {
                      return (
                        <TableRow key={`${slice.metadata?.name}-${index}`}>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            className="txt-bold"
                          >
                            {cluster}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            className="txt-bold"
                          >
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster]['location']}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            className="txt-bold"
                          >
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster][
                                'cloudType'
                              ]?.toUpperCase()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={namespaceModal}
        onClose={() => setNamespaceModal(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            minHeight: '80%',
            maxHeight: '80%',
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{ padding: '24px 16px 16px 16px' }}
          id="scroll-dialog-title"
        >
          <Typography className="dialogue-title">
            {`Total Namespaces : ${totalNamespaceCount}`}
            <IconButton
              aria-label="close"
              onClick={() => setNamespaceModal(false)}
              sx={{
                float: 'right',
                padding: 0,
              }}
            >
              <CloseIcon htmlColor="black" />
            </IconButton>
          </Typography>
          <Typography sx={{ paddingTop: '4px' }} className="dialogue-subtitle">
            {`Slice name: ${slice.metadata?.name}`}
          </Typography>
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '12px',
              border: 'solid 1px lightgray',
            }}
          >
            <IconButton
              className="search-icon"
              onClick={() => setSearch(true)}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Namespaces"
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearch(false);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  setSearch(true);
                }
              }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Paper>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <Table size="small" aria-label="purchases">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    paddingLeft: '24px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    paddingRight: '0px',
                    lineHeight: '15px',
                    width: '15%',
                  }}
                  className="txt-bold"
                >
                  N.O
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    paddingLeft: '24px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    paddingRight: '0px',
                    lineHeight: '15px',
                    width: '15%',
                  }}
                  className="txt-bold"
                >
                  NAMESPACES
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    paddingLeft: '24px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    paddingRight: '0px',
                    lineHeight: '15px',
                    width: '15%',
                  }}
                  className="txt-bold"
                >
                  CLUSTERS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {namespaceList &&
                namespaceList.map((item, popupRowCount) => {
                  if (
                    (search &&
                      searchText !== '' &&
                      item.namespace === searchText) ||
                    !search
                  ) {
                    return item.clusters.map((cluster, index) => {
                      return (
                        <TableRow
                          key={`${cluster}-${popupRowCount + index + 1}`}
                        >
                          <TableCell
                            sx={{ padding: '16px 24px' }}
                            className="txt-bold"
                          >
                            {`${popupRowCount + index + 1}.`}
                          </TableCell>
                          <TableCell
                            sx={{ padding: '16px 24px' }}
                            className="txt-bold"
                          >
                            {item['namespace']}
                          </TableCell>
                          <TableCell
                            sx={{ padding: '16px 24px' }}
                            className="txt-bold"
                          >
                            {cluster === `*` || cluster === `'*'` ? (
                              <label style={{ color: 'green' }}>
                                {`Namespace sameness`}
                              </label>
                            ) : (
                              cluster
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    });
                  }
                })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
