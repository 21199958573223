import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import { Button, InputBase, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'underscore';
import {
  setAffinityApiAction,
  setAffinityProfiles,
  setCurrentSliceAffinity,
} from '../../../actions/NodeAffinity.actions';
import {
  selectAffinityProfiles,
  selectCurrentSliceAffinity,
} from '../../../selectors/NodeAffinity.selector';
import DeleteModal from '../Generic/DeleteModal';
import Fallback from '../Generic/Fallback';
import { nodeAffinityApiActions } from '../utils';
import './index.css';

export default function SelectedNode({ selectedNode, setSelectedNode }) {
  const [clearAllDialog, setClearAllDialog] = React.useState(false);
  const [clearNsLabel, setClearNsLabel] = React.useState(null);
  const [clearNsAllLabels, setClearNsAllLabel] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const affinity_profiles = useSelector((state) =>
    selectAffinityProfiles(state)
  );
  const currentSliceAffinity = useSelector(selectCurrentSliceAffinity);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleClearAll = () => {
    dispatch(setAffinityApiAction(nodeAffinityApiActions.delete));
    dispatch(setAffinityProfiles([]));
    dispatch(
      setCurrentSliceAffinity({
        ...(currentSliceAffinity ? currentSliceAffinity : {}),
        spec: {
          ...(currentSliceAffinity?.spec ? currentSliceAffinity?.spec : {}),
          nodeAffinityProfiles: [],
        },
      })
    );
    history.push('/assign-nodes/3');
  };

  const openDialog = () => {
    setClearAllDialog(true);
  };

  const closeDialog = () => {
    setClearAllDialog(false);
  };

  const removeSelectedNode = () => {
    const { cluster, namespace, label } = clearNsLabel;
    dispatch(
      setAffinityProfiles(
        affinity_profiles.map((profile) => {
          if (profile.cluster === cluster) {
            return {
              ...profile,
              nodeAffinityRules: profile.nodeAffinityRules.map((rule) => {
                if (rule.namespace === namespace) {
                  return {
                    ...rule,
                    nodeSelectorLabels: rule.nodeSelectorLabels.filter(
                      (r_label) => !_.isEqual(r_label, label)
                    ),
                  };
                }
                return rule;
              }),
            };
          }
          return profile;
        })
      )
    );
    setClearNsLabel(null);
  };

  const clearLabels = () => {
    const { cluster, namespace } = clearNsAllLabels;
    dispatch(
      setAffinityProfiles(
        affinity_profiles.map((profile) => {
          if (profile.cluster === cluster) {
            return {
              ...profile,
              nodeAffinityRules: profile.nodeAffinityRules.map((rule) => {
                if (rule.namespace === namespace) {
                  return {
                    ...rule,
                    nodeSelectorLabels: [],
                  };
                }
                return rule;
              }),
            };
          }
          return profile;
        })
      )
    );
    setClearNsAllLabel(null);
  };

  const shouldFallBack = affinity_profiles.some((profile) =>
    profile.nodeAffinityRules
      .filter((rule) => rule.namespace !== '*')
      .some((rule) => rule.nodeSelectorLabels?.length > 0)
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div className="selected-namespace-header">
          <Box
            style={{
              padding: '0px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              p={0}
              item
              style={{
                padding: '21px 0px 7px 0px',
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#12153E',
              }}
              md={9}
              data-testid="AssignedNodes"
            >
              ASSIGNED NODE LABELS
            </Box>
            <Box style={{ padding: '14px 0px' }}>
              <Button data-testid="clearAll" onClick={openDialog}>
                Clear all
              </Button>
            </Box>
          </Box>
        </div>
        <Stack
          sx={{
            p: 2,
            marginBottom: '52px',
          }}
        >
          {shouldFallBack ? (
            <Stack>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '40px',
                  overflow: 'hidden',
                  width: '308px',
                  mb: 2,
                  mt: '-16px',
                  ml: '-16px',
                  px: '4px',
                  gap: 2,
                }}
              >
                <Search
                  sx={{
                    color: '#0000008a',
                  }}
                />
                <InputBase
                  sx={{ m: 0, flex: 1 }}
                  placeholder="Search namespaces"
                  onChange={handleSearch}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={search}
                />
              </Box>
              <Box>
                {affinity_profiles.map((profile, p_idx) => {
                  return (
                    <Stack key={p_idx}>
                      {profile.nodeAffinityRules
                        .filter((rule) => rule.namespace !== '*')
                        .filter((rule) => rule.namespace.includes(search))
                        .map((rule, idx) => {
                          const shouldLoopOver =
                            rule.nodeSelectorLabels?.length > 0;

                          return (
                            shouldLoopOver && (
                              <Stack key={idx}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Box>{rule.namespace}</Box>
                                  <Box>
                                    <Button
                                      onClick={
                                        () =>
                                          setClearNsAllLabel({
                                            cluster: profile.cluster,
                                            namespace: rule.namespace,
                                          })
                                        // clearLabels(
                                        //   profile.cluster,
                                        //   rule.namespace
                                        // )
                                      }
                                    >
                                      Clear
                                    </Button>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {rule.nodeSelectorLabels.map(
                                    (label, l_idx) => (
                                      <div
                                        key={l_idx}
                                        className="selected-namespace-item"
                                        data-testid={rule.namespace}
                                      >
                                        <Tooltip
                                          title={`${profile.cluster ?? ''}`}
                                          arrow
                                          placement="top"
                                        >
                                          <label>{label.key}</label>
                                        </Tooltip>
                                        <CloseIcon
                                          onClick={
                                            () =>
                                              setClearNsLabel({
                                                cluster: profile.cluster,
                                                namespace: rule.namespace,
                                                label: label,
                                              })

                                            // removeSelectedNode(
                                            //   profile.cluster,
                                            //   rule.namespace,
                                            //   label
                                            // )
                                          }
                                          color="primary"
                                          fontSize="small"
                                          sx={{
                                            fontSize: '13px',
                                            cursor: 'pointer',
                                            margin: '0 5px',
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </Box>
                              </Stack>
                            )
                          );
                        })}
                    </Stack>
                  );
                })}
              </Box>
            </Stack>
          ) : (
            <Fallback text="You have not assigned any labels" />
          )}
        </Stack>
      </Box>

      {clearAllDialog && (
        <DeleteModal
          open={true}
          handleClose={() => closeDialog()}
          onAccept={() => handleClearAll()}
          title="Do you want to clear all the labels from the slice?"
          subtitle="If you clear all the labels, node assignment will be deleted."
        />
      )}

      {clearNsLabel && (
        <DeleteModal
          open={true}
          handleClose={() => setClearNsLabel(null)}
          onAccept={() => removeSelectedNode()}
          title="Do you want to delete the selected label?"
        />
      )}

      {clearNsAllLabels && (
        <DeleteModal
          open={true}
          handleClose={() => setClearNsAllLabel(null)}
          onAccept={() => clearLabels()}
          title="Do you want to clear all the labels from the selected namespace?"
        />
      )}
    </>
  );
}
