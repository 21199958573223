import {
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import "./index.css";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as ErrorEmpty } from "../../LandingPage/Error-Empty.svg";
import { ReactComponent as Error } from "../../LandingPage/Error.svg";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { setCurrentSliceRoleList } from "../../../../actions/RbacActions";
import AlertDialog from "./AlertDialog";

export default function K8SelectRoles() {
  const k8currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const k8Status = useSelector(
    (state) =>
      state?.RbacReducer?.currentSliceRoleList?.metadata?.status
        ?.roleRefConditions
  );
  // console.log(k8Status);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [UGkindErr, setUGkindErr] = useState(false);
  const [UGnameErr, setUGnameErr] = useState("");
  const [apiVersionErr, setapiVersionErr] = useState(false);
  const [kindErr, setkindErr] = useState(false);
  const [nameErr, setnameErr] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [Delindex, setDelIndex] = useState();
  const [delMessage, setDelMessage] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    let updatedslice = { ...k8currentSlice };
    updatedslice.metadata.importedData = [];
    dispatch(setCurrentSliceRoleList(updatedslice));
  }, [openDeleteModal]);

  useEffect(() => {
    var dupk8currentSlice = { ...k8currentSlice };
    if (k8Status !== undefined) {
      k8Status.forEach((data) => {
        k8currentSlice?.importk8Roles.forEach((current, index) => {
          if (
            data.roleRef.name === current.roleRef.name &&
            data.roleRef.kind === current.roleRef.kind &&
            data.roleRef.apiVersion === current.roleRef.apiVersion
          ) {
            if (!dupk8currentSlice.importk8Roles[index].error?.length) {
              dupk8currentSlice.importk8Roles[index].error = [];
            }
            dupk8currentSlice.importk8Roles[index].error.push(data);
          }
        });
      });
    }
    dispatch(setCurrentSliceRoleList(dupk8currentSlice));
  }, []);

  const [rowsData, setRowsData] = useState(() => {
    if (k8currentSlice?.importk8Roles[0] !== undefined) {
      return k8currentSlice.importk8Roles;
    }
    return [
      {
        isTouched,
        applyTo: [
          {
            namespace: "",
            cluster: "",
            subjects: [
              {
                kind: "",
                name: "",
              },
            ],
          },
        ],
        roleRef: {
          apiVersion: "",
          kind: "",
          name: "",
        },
      },
    ];
  });

  const handleChange = (index, evnt) => {
    const { name, value, id } = evnt.target;

    const rowsInput = [...rowsData];

    if (name === "kind1") {
      rowsInput[index].applyTo[0].subjects[0].kind = value;
    } else if (id === "UGname") {
      rowsInput[index].applyTo[0].subjects[0].name = value;
    } else if (name === "apiVersion") {
      rowsInput[index].roleRef.apiVersion = value;
    } else if (name === "kind") {
      rowsInput[index].roleRef.kind = value;
    } else if (name === "name") {
      rowsInput[index].roleRef.name = value;
    }
    isFormChanged === false && setIsFormChanged(true);
    // setIsTouched(true);
    setRowsData(rowsInput);
    // setRowsData(() => {
    //   return rowsInput.map((role, idx) => {
    //     if (idx === index) {
    //       let errors = validate({ ...role, rowsInput: value });

    //       return {
    //         ...role,
    //         rowsInput: value,
    //         // roles_touched: true,
    //         ...errors,
    //       };
    //     }
    //     return rowsInput;
    //   });
    // });/

    dispatch(
      setCurrentSliceRoleList({
        ...k8currentSlice,
        importk8Roles: rowsInput,
      })
    );
  };

  const saveRoles = () => {
    const rowsInput = {
      isFormChanged: false,
      applyTo: [
        {
          namespace: "",
          cluster:"",
          subjects: [
            {
              kind: "",
              name: "",
            },
          ],
        },
      ],
      roleRef: {
        apiVersion: "",
        kind: "",
        name: "",
      },
    };

    setIsFormChanged(false);
    setRowsData([...rowsData, rowsInput]);
  };

  const removeRoles = (i) => {
    const rows = [...rowsData];
    rows.splice(i, 1);

    setRowsData(rows);
    dispatch(
      setCurrentSliceRoleList({
        ...k8currentSlice,
        importk8Roles: rows,
      })
    );
  };

  const setDelete = (i, message) => {
    if (i === 0 && !(i > 0) && rowsData.length <= 1 && message === "") {
      setRowsData([
        {
          isTouched,
          applyTo: [
            {
              namespace: "",
              cluster:"",
              subjects: [
                {
                  kind: "",
                  name: "",
                },
              ],
            },
          ],
          roleRef: {
            apiVersion: "",
            kind: "",
            name: "",
          },
        },
      ]);
    }
    if (message === "" && i >= 0 && rowsData.length > 1) {
      removeRoles(i);
    } else if (i >= 0 && message !== "") {
      setDelIndex(i);
      setDelMessage(message);
      setOpenDeleteModal(true);
    }
  };

  const [openModel, setOpenModel] = React.useState(false);

  const handleModalClose = (del, i) => {
    if (del) {
      if (i === 0 && !(i > 0) && rowsData.length <= 1) {
        removeRoles(i);
        setRowsData([
          {
            isTouched,
            applyTo: [
              {
                namespace: "",
                cluster:"",
                subjects: [
                  {
                    kind: "",
                    name: "",
                  },
                ],
              },
            ],
            roleRef: {
              apiVersion: "",
              kind: "",
              name: "",
            },
          },
        ]);
        setOpenDeleteModal(false);
      }
      // console.log(i);
      else removeRoles(i);
      setOpenDeleteModal(false);
    } else {
      setOpenDeleteModal(false);
      setOpenModel();
    }
  };

  const getErrorMessage = (item) => {
    // ?.error[0].message
    if (item.error?.length) {
      return (
        <List
          sx={{
            '& ul': { padding: 0 },
            listStyleType: 'disc',
          }}
          subheader={<li />}
        >
          {item.error?.map((error, idx) => (
            <ListItem key={idx}>{error.message}</ListItem>
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <AlertDialog
        open={openDeleteModal}
        handleClose={(a) => handleModalClose(a, Delindex)}
        groupName={delMessage}
      ></AlertDialog>
      <TableContainer className="table-container">
        <Prompt
          message={(location, action) => {
            if (isFormChanged) {
              if (
                location.pathname.includes("manage-rbacs") &&
                location.pathname.includes("/manage-rbac")
              ) {
                return true;
              } else {
                return "You have unsaved changes. Do you want to discard them?";
              }
            } else if (
              location.pathname.includes("manage-rbacs") ||
              location.pathname.includes("/manage-rbac")
            ) {
              // console.log(266);
              return true;
            } else {
              // console.log(269);
              // console.log(location.pathname);
              // console.log(isFormChanged);
              return "You have unsaved changes. Do you want to discard them?";
            }
          }}
        />
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow style={{ height: "45px" }}>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                USER / GROUP
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                USER / GROUP’s NAME
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                API VERSION
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                KIND
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                NAME
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsData?.map((listItem, i) => {
              // console.log(listItem?.error[0].message);

              // console.log(listItem?.error);
              return (
                <TableRow style={{ borderStyle: "hidden" }}>
                  <TableCell
                    style={{
                      // ariaRequired:true,
                      padding: "24px",
                      paddingTop: "0px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                      width: '20%',
                      minWidth: '20%',
                    }}
                  >
                    {listItem?.error === undefined ? (
                      <Typography className="errorIco" paddingTop={"18px"}>
                        <ErrorEmpty></ErrorEmpty>
                      </Typography>
                    ) : (
                      <Typography className="errorIco" paddingTop={"18px"}>
                        <Tooltip title={getErrorMessage(listItem)}>
                          <Error></Error>
                        </Tooltip>
                      </Typography>
                    )}

                    <Box
                      sx={{
                        minWidth: 120,
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select User/Group
                        </InputLabel>
                        <Select
                          required={true}
                          error={UGkindErr}
                          name="kind1"
                          labelId="UG_kind2"
                          id="UG_kind"
                          value={listItem.applyTo[0].subjects[0].kind || null}
                          label="Select User/Group"
                          onChange={(evnt) => handleChange(i, evnt)}
                          // onClick={(event) => Validator(i, event)}
                        >
                          <MenuItem value="User">User</MenuItem>
                          <MenuItem value="Group">Group</MenuItem>
                        </Select>
                      </FormControl>

                      {isFormChanged &&
                        listItem?.applyTo[0]?.subjects[0]?.kind === "" && (
                          <Typography color={"red"} fontSize={"12px"}>
                            {" "}
                            Enter User/Group{" "}
                          </Typography>
                        )}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 120,
                      }}
                    >
                      <TextField
                        required={true}
                        error={UGnameErr}
                        helperText={UGnameErr}
                        id="UGname"
                        label="User/Group"
                        name="UGname"
                        variant="outlined"
                        value={listItem?.applyTo[0]?.subjects[0]?.name}
                        // onChange={namehandler}

                        onChange={(evnt) => handleChange(i, evnt)}
                        // onClick={(event) => Validator(i, event)}
                      />
                    </Box>
                    {isFormChanged &&
                      listItem?.applyTo[0]?.subjects[0]?.name === "" && (
                        <Typography color={"red"} fontSize={"12px"}>
                          {" "}
                          Enter Users/Group’s{" "}
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 120,
                      }}
                    >
                      <TextField
                        required={true}
                        error={apiVersionErr}
                        id="outlined-basic"
                        label="API Version"
                        name="apiVersion"
                        variant="outlined"
                        value={listItem?.roleRef?.apiVersion}
                        onChange={(evnt) => handleChange(i, evnt)}
                        // onClick={(event) => Validator(i, event)}
                      />
                    </Box>
                    {isFormChanged && listItem?.roleRef?.apiVersion === "" ? (
                      <Typography color={"red"} fontSize={"12px"}>
                        {" "}
                        Enter API Version{" "}
                      </Typography>
                    ) : (
                      isFormChanged &&
                      listItem?.roleRef?.apiVersion.includes("/") !== true && (
                        <Typography color={"red"} fontSize={"12px"}>
                          {" "}
                          Enter Valid Api Version{" "}
                        </Typography>
                      )
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box sx={{ minWidth: 120 }}>
                      <TextField
                        required={true}
                        error={kindErr}
                        id="outlined-basic"
                        label="Kind"
                        name="kind"
                        variant="outlined"
                        value={listItem?.roleRef?.kind}
                        onChange={(evnt) => handleChange(i, evnt)}
                        // onClick={(event) => Validator(i, event)}
                      />
                    </Box>
                    {isFormChanged && listItem?.roleRef?.kind === "" && (
                      <Typography color={"red"} fontSize={"12px"}>
                        {" "}
                        Enter Kind{" "}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box sx={{ minWidth: 120 }}>
                      <TextField
                        required={true}
                        error={nameErr}
                        id="outlined-basic"
                        label="Enter name"
                        name="name"
                        variant="outlined"
                        value={listItem?.roleRef?.name}
                        onChange={(evnt) => handleChange(i, evnt)}
                        // onClick={(event) => Validator(i, event)}
                      />
                    </Box>
                    {isFormChanged && listItem?.roleRef?.name === "" && (
                      <Typography color={"red"} fontSize={"12px"}>
                        {" "}
                        Enter name{" "}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <div style={{display: 'flex'}}>
                    {listItem?.roleRef?.name !== "" &&
                      listItem?.roleRef?.kind !== "" &&
                      listItem?.roleRef?.apiVersion !== "" &&
                      listItem?.applyTo[0]?.subjects[0]?.name !== "" &&
                      listItem?.applyTo[0]?.subjects[0]?.kind !== "" &&
                      listItem?.roleRef?.apiVersion.includes("/") !== false &&
                      rowsData.length - 1 === i && (
                        // listItem?.roleRef?.apiVersion.includes("/") !== false &&
                        <IconButton
                          aria-label="add"
                          size="small"
                          color="primary"
                          onClick={saveRoles}
                        >
                          <AddCircleIcon className="AddCircleIcon" />
                        </IconButton>
                      )}
                    {rowsData?.length > 0 && (
                      <IconButton
                        name="delete"
                        variant="outlined"
                        aria-label="delete"
                        size="small"
                        // onChange={() => removeRoles(i)}
                        // onChange={handleModalClose(true, i)}
                        onClick={() =>
                          setDelete(
                            i,
                            listItem?.applyTo[0]?.subjects[0]?.name,
                            listItem?.roleRef?.name,
                            listItem?.roleRef?.kind,
                            listItem?.roleRef?.apiVersion,
                            listItem?.applyTo[0]?.subjects[0]?.kind,
                            listItem
                          )
                        }
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
