import Search from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import _ from 'underscore';
import { setAffinityProfiles } from '../../../actions/NodeAffinity.actions';
import { selectAffinityProfiles } from '../../../selectors/NodeAffinity.selector';
import Dialogs from './Dialogs';
import './index.css';
import {
  labelToSelectorLabel,
  search_labels_by,
  stringifyLabel,
} from './utils';
import { ReactComponent as Checked } from '../../../assets/icons/Checked.svg';

export default function NodeTable({ searchBy, labels: rawLabels, cluster }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const [currentLabel, setCurrentLabel] = useState();
  const [subSearchText, setSubSearchText] = useState(''); 
  const dispatch = useDispatch();

  const affinity_profiles = useSelector((state) =>
    selectAffinityProfiles(state)
  );

  const selectRow = (event, label) => {
    const isItemSelected = event.target.checked;
    const [key, value] = Object.entries(label)[0];
    const affinity_rule = {
      key,
      operator: 'In',
      values: [value],
    };

    if (searchBy === search_labels_by.label) {
      const sliceLabels = affinity_profiles.find(
        (profile) => profile.cluster === '*'
      );

      if (sliceLabels) {
        let nodeAffinityRules = [];
        if (isItemSelected) {
          nodeAffinityRules = [
            ...sliceLabels.nodeAffinityRules.map((rule) => ({
              ...rule,
              nodeSelectorLabels: [
                ...(rule.nodeSelectorLabels ?? []),
                affinity_rule,
              ],
            })),
          ];
        } else {
          nodeAffinityRules = sliceLabels.nodeAffinityRules.map((rule) => ({
            ...rule,
            nodeSelectorLabels: rule.nodeSelectorLabels.filter(
              (label) => !_.isEqual(label, affinity_rule)
            ),
          }));
        }

        dispatch(
          setAffinityProfiles(
            affinity_profiles.map((profile) => {
              if (profile.cluster === '*') {
                return {
                  ...profile,
                  nodeAffinityRules: nodeAffinityRules,
                };
              }
              return profile;
            })
          )
        );
      } else {
        dispatch(
          setAffinityProfiles([
            {
              cluster: '*',
              nodeAffinityRules: [
                {
                  namespace: '*',
                  nodeSelectorLabels: [affinity_rule],
                },
              ],
            },
            ...affinity_profiles,
          ])
        );
      }

      // setSelectedNode(results);
    } else if (searchBy === search_labels_by.cluster) {
      const clusterProfile = affinity_profiles.find(
        (profile) => profile.cluster === cluster
      );

      if (clusterProfile) {
        let nodeAffinityRules = [];

        if (isItemSelected) {
          // affinity rules can be empty array
          if (clusterProfile.nodeAffinityRules.length === 0) {
            nodeAffinityRules = [
              {
                namespace: '*',
                nodeSelectorLabels: [affinity_rule],
              },
            ];
          } else {
            nodeAffinityRules = [
              ...clusterProfile.nodeAffinityRules.map((rule) => ({
                ...rule,
                nodeSelectorLabels: [
                  ...(rule.nodeSelectorLabels ?? []),
                  affinity_rule,
                ],
              })),
            ];
          }
        } else {
          nodeAffinityRules = clusterProfile.nodeAffinityRules.map((rule) => ({
            ...rule,
            nodeSelectorLabels: rule.nodeSelectorLabels.filter(
              (label) => !_.isEqual(label, affinity_rule)
            ),
          }));
        }

        dispatch(
          setAffinityProfiles(
            affinity_profiles.map((profile) => {
              if (profile.cluster === cluster) {
                return {
                  ...profile,
                  nodeAffinityRules: nodeAffinityRules,
                };
              }
              return profile;
            })
          )
        );
      } else {
        dispatch(
          setAffinityProfiles([
            ...affinity_profiles,
            {
              cluster: cluster,
              nodeAffinityRules: [
                {
                  namespace: '*',
                  nodeSelectorLabels: [affinity_rule],
                },
              ],
            },
          ])
        );
      }
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labels = rawLabels.filter((label) => {
    // slice level is filtered on parent component
    if (searchBy === search_labels_by.cluster) {
      const stringified_label = stringifyLabel(label);
      return stringified_label.includes(subSearchText);
    }
    return label;
  });

  const labelsPaginated = labels.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const checkSelection = (label) => {
    const selector_label = labelToSelectorLabel(label);

    if (searchBy === search_labels_by.cluster) {
      return affinity_profiles
        .filter((rule) => rule.cluster === cluster)
        .some((profile) => {
          return profile.nodeAffinityRules.some((rule) =>
            rule.nodeSelectorLabels?.some((label) =>
              _.isEqual(label, selector_label)
            )
          );
        });
    } else {
      return affinity_profiles
        .filter((rule) => rule.cluster === '*')
        .some((profile) => {
          return profile.nodeAffinityRules.some((rule) =>
            rule.nodeSelectorLabels?.some((label) =>
              _.isEqual(label, selector_label)
            )
          );
        });
    }
  };

  const allSelected = labelsPaginated?.every((item) => checkSelection(item));

  const selectAllRows = () => {
    const affinity_rule = labelsPaginated.map((label)=>{
      const [key, value] = Object.entries(label)[0];
      return{
        key,
        operator: 'In',
        values: [value],
      };}) 


    if (searchBy === search_labels_by.label) {
      const sliceLabels = affinity_profiles.find(
        (profile) => profile.cluster === '*'
      );

      if (sliceLabels) {
        let nodeAffinityRules = [];
        if (!allSelected) {
          nodeAffinityRules = [
            ...sliceLabels.nodeAffinityRules.map((rule) => ({
              ...rule,
              nodeSelectorLabels: [
                ...(rule.nodeSelectorLabels ?? []),
                ...affinity_rule,
              ],
            })),
          ];
        } else {
          nodeAffinityRules = sliceLabels.nodeAffinityRules.map((rule) => ({
            ...rule,
            nodeSelectorLabels: rule.nodeSelectorLabels.filter(
              (label) => !affinity_rule.some((affinityLabel)=> _.isEqual(label, affinityLabel))
            ),
          }));
        }

        dispatch(
          setAffinityProfiles(
            affinity_profiles.map((profile) => {
              if (profile.cluster === '*') {
                return {
                  ...profile,
                  nodeAffinityRules: nodeAffinityRules,
                };
              }
              return profile;
            })
          )
        );
      } else {
        dispatch(
          setAffinityProfiles([
            {
              cluster: '*',
              nodeAffinityRules: [
                {
                  namespace: '*',
                  nodeSelectorLabels: [...affinity_rule],
                },
              ],
            },
            ...affinity_profiles,
          ])
        );
      }

      // setSelectedNode(results);
    } else if (searchBy === search_labels_by.cluster) {
      const clusterProfile = affinity_profiles.find(
        (profile) => profile.cluster === cluster
      );

      if (clusterProfile) {
        let nodeAffinityRules = [];

        if (!allSelected) {
          // affinity rules can be empty array
          if (clusterProfile.nodeAffinityRules.length === 0) {
            nodeAffinityRules = [
              {
                namespace: '*',
                nodeSelectorLabels: [...affinity_rule],
              },
            ];
          } else {
            nodeAffinityRules = [
              ...clusterProfile.nodeAffinityRules.map((rule) => ({
                ...rule,
                nodeSelectorLabels: [
                  ...(rule.nodeSelectorLabels ?? []),
                  ...affinity_rule,
                ],
              })),
            ];
          }
        } else {
          nodeAffinityRules = clusterProfile.nodeAffinityRules.map((rule) => ({
            ...rule,
            nodeSelectorLabels: rule.nodeSelectorLabels.filter(
              (label) => !affinity_rule.some((affinityLabel)=> _.isEqual(label, affinityLabel))
            ),
          }));
        }

        dispatch(
          setAffinityProfiles(
            affinity_profiles.map((profile) => {
              if (profile.cluster === cluster) {
                return {
                  ...profile,
                  nodeAffinityRules: nodeAffinityRules,
                };
              }
              return profile;
            })
          )
        );
      } else {
        dispatch(
          setAffinityProfiles([
            ...affinity_profiles,
            {
              cluster: cluster,
              nodeAffinityRules: [
                {
                  namespace: '*',
                  nodeSelectorLabels: [...affinity_rule],
                },
              ],
            },
          ])
        );
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Prompt
        message={(location, action) => {
          if (
            location.pathname.includes('/assign-nodes/2') ||
            location.pathname.includes('/assign-nodes/3')
          ) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />

      <TableContainer
        className="table-container"
        sx={{
          overflowY: 'auto',
          maxHeight: 512,
        }}
      >
        <Table
          sx={{
            minWidth: 300,
            borderRight: '1px solid lightgrey',
          }}
          stickyHeader
          aria-labelledby="Node Table"
          size="small"
          data-testid="AssigntoSliceS1"
        >
          <TableHead className="table-head">
            <TableRow
              sx={{
                color: '#12153E',
                background: '#DEE8FF',
              }}
            >
              <TableCell
                sx={{
                  fontWeight: 600,
                  padding: 2,
                  fontSize: '12px',
                  background: '#DEE8FF',
                }}
              >
                <Checkbox
                  color="primary"
                  onChange={() => selectAllRows()}
                  checked={allSelected}
                  checkedIcon={<Checked />}
                />
              </TableCell>
              <TableCell
                sx={{
                  padding: 1,
                  fontWeight: 600,
                  fontSize: '12px',
                  background: '#DEE8FF',
                }}
              >
                LABEL NAME
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  minWidth: '100px',
                  background: '#DEE8FF',
                }}
              >
                VIEW NODES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="AssigntoSliceS1Body">
            {searchBy === search_labels_by.cluster && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                      alignItems: 'center',
                    }}
                  >
                    <Search />
                    <InputBase
                      value={subSearchText}
                      onChange={(e) => setSubSearchText(e.target.value)}
                      sx={{
                        flex: '1 1 0%',
                      }}
                      placeholder="search labels"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {labelsPaginated.map((label, index) => {
              let isItemSelected = checkSelection(label);

              return (
                <TableRow
                  key={index}
                  selected={isItemSelected}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  hover
                  data-testid="LabelTableStep1"
                >
                  <TableCell
                    style={{
                      padding: '18px 16px',
                      fontWeight: 600,
                      color: '#12153E',
                    }}
                  >
                    <Checkbox
                      color="primary"
                      onChange={(event) => selectRow(event, label)}
                      checked={isItemSelected}
                      inputProps={{
                        'data-testid': stringifyLabel(label),
                      }}
                      checkedIcon={<Checked />}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: '18px 16px',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#12153E',
                    }}
                    data-testid="LabelData"
                  >
                    {stringifyLabel(label)}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: '18px 16px',
                      fontWeight: 600,
                      color: '#12153E',
                    }}
                  >
                    <Button
                      style={{
                        color: '#2868F3',
                        textTransform: 'none',
                        fontWeight: '600',
                      }}
                      onClick={() => {
                        setCurrentLabel(label);
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ borderRight: '1px solid #D9DFEE' }}
        data-testid="tabelPagination"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={labels.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {currentLabel && (
        <Dialogs
          parentModalOpen={!!currentLabel}
          onClose={() => setCurrentLabel(null)}
          currentLabel={currentLabel}
        />
      )}
    </div>
  );
}
