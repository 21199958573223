import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentSliceRoleList,
  setSelectedRole,
} from '../../../../actions/RbacActions';
import './index.css';
import NamespacesTable from './NamespacesTable';
const AssignNamespacesModel = ({
  open,
  setOpenModel,
  setOpenAlertModal,
  value,
  isChanged,
  setIsChanged,
}) => {
  //props details
  //open and setOpenModel - to open and close this model
  //value-to define which tab is open in the main screen
  //setopenAlertModal - toopen the view modal

  const [searchBy, setSearchBy] = React.useState('namespace');
  const [search, setSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [selectedNamespace, setSelectedNamespace] = React.useState([]);
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const selectedRole = useSelector((state) => state.RbacReducer.selectedRole);
  const dispatch = useDispatch();
  const searchNamespace = (event) => {
    setSearchText(event.target.value);
    setSearch(true);
  };

  const handleSave = () => {
    setIsChanged(false);
    let itemArr = [];
    let updatedSelectedRole = { ...selectedRole };
    let updatedCurrentSlice = { ...currentSlice };
    selectedNamespace?.map((data) => {
      itemArr.push({ namespace: data?.namespace, cluster: data?.cluster });
    });
    updatedSelectedRole.namespaces = itemArr;

    if (value == '0') {
      let index = updatedCurrentSlice.metadata.data.findIndex((item) => {
        return (
          item.name === updatedSelectedRole.name &&
          item.kind === updatedSelectedRole.kind
        );
      });
      if (index >= 0) {
        updatedCurrentSlice.metadata.data[index].namespaces = itemArr;
      }
    } else if (value == '1') {
      let index = updatedCurrentSlice.metadata.importedData.findIndex(
        (item) => {
          return (
            item.name === updatedSelectedRole.name &&
            item.kind === updatedSelectedRole.kind
          );
        }
      );
      if (index >= 0) {
        updatedCurrentSlice.metadata.importedData[index].namespaces = itemArr;
      }
    }
    dispatch(setSelectedRole(updatedSelectedRole));
    dispatch(setCurrentSliceRoleList(updatedCurrentSlice));
    setOpenModel(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {}}
        sx={{ '& .MuiDialog-paper': { minWidth: '60%', minHeight: 'auto' } }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div>
            <Typography className="dialog-title">
              Assign Namespaces to Roles
              <IconButton
                aria-label="close"
                onClick={() => {
                  if (isChanged) setOpenAlertModal(true);
                  else setOpenModel(false);
                }}
                sx={{
                  float: 'right',
                  padding: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            <Typography className="dialog-subtitle">
              Role:{' '}
              <b className="model-prop">
                {selectedRole?.roleName?.join(', ')}{' '}
              </b>
              | {selectedRole?.kind == 'User' ? 'User:' : 'User Group:'}{' '}
              <b className="model-prop">{selectedRole?.name} </b>
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0, maxHeight: '80%' }}>
          <div
            style={{
              display: 'flex',
              height: '40px',
              overflow: 'hidden',
              border: '1px solid #B6B8D6',
              margin: 'auto',
              borderRadius: '4px',
              marginTop: '16px',
              width: '92%',
            }}
          >
            <FormControl
              className="filter-dropdown"
              sx={{
                backgroundColor: '#E6E8F3',
                top: '0px',
                height: '44px',
                minWidth: '25%',
              }}
            >
              <InputLabel id="search-by-label"></InputLabel>
              <Select
                labelId="search-by-label"
                id="search-by"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                label="Search By"
                style={{ height: '40px' }}
              >
                <MenuItem value="namespace">Namespaces</MenuItem>
                <MenuItem value="cluster">Cluster</MenuItem>
              </Select>
            </FormControl>
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                borderRadius: '0 5px 5px 0',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={searchNamespace}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearch(true);
                  }
                }}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                className="search-icon"
                onClick={() => setSearch(true)}
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div>
            <NamespacesTable
              setSelectedNamespace={setSelectedNamespace}
              selectedNamespace={selectedNamespace}
              search={search}
              searchBy={searchBy}
              searchText={searchText}
              setIsChanged={setIsChanged}
              setSearchText={setSearchText}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2%',
              height: 'auto',
            }}
          >
            <Button
              variant="contained"
              className="btn-save-role"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignNamespacesModel;
