/* eslint-disable eqeqeq */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import {
  getResourceQuotaPromCountsForNamespace,
  setCurrentResourceQuota,
  setDeleteRQ,
} from '../../../../actions/ResourceQuotaActions';
import { handleConversion, kiToMi } from '../../../../utils/functions';
import AddResource from '../AddResource';
import { defaultRQValues, emptyRqValues } from '../AddResource/utils';
import DeleteModal from '../DeleteModal';
import './index.css';
import ManageResourceTable from './ManageResourceTable';
import CircularStatic from './ManageResourceTable/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

export default function InitialStep({ step, setBreadcrumb }) {
  const currentResourceQuota = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );

  const editedClusterQuota = useSelector(
    (state) => state.resourceQuota.edited_cluster_quota
  );

  const {
    spec: {
      resourceQuota: {
        spec: {
          sliceQuota: {
            resources: {
              limit: { podCount, ephemeralStorage, cpu, memory } = {},
              userdata,
              request: {
                ephemeralStorage: ephemeralRequestStorage,
                cpu: cpuRequest,
                memory: memoryRequest,
              } = {},
              defaultRequestPerContainer: {
                ephemeralStorage: containerStorageRequest,
                cpu: containerCpuRequest,
                memory: containerMemoryRequest,
              } = {},
            } = {},
          } = {},
        },
      } = {},
    },
  } = currentResourceQuota;
  if (currentResourceQuota && currentResourceQuota?.metadata) {
    setBreadcrumb(currentResourceQuota?.metadata?.name);
  }

  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const topBarData = [
    {
      name: 'Pods Count',
      value: userdata ? userdata.podCount : '',
      total: podCount ? podCount : '',
      limitKey: '',
      mainKey: 'limit',
      internalKey: 'podCount',
      isEdited: false,
    },
    {
      name: 'CPU Limit',
      value: userdata ? userdata.cpu : '',
      total: handleConversion('cpu', cpu) || '',
      limitKey: 'M',
      mainKey: 'limit',
      internalKey: 'cpu',
      isEdited: false,
    },
    {
      name: 'CPU Request',
      value: userdata.cpuRequest,
      total: handleConversion('cpu', cpuRequest) || '',
      limitKey: 'M',
      mainKey: 'request',
      internalKey: 'cpu',
      isEdited: false,
    },
    {
      name: 'Memory Limit',
      // value: userdata ? userdata.memory / 1024 : '',
      value: kiToMi(userdata.memory),
      total: handleConversion('memory', memory) || '',
      limitKey: 'Mi',
      mainKey: 'limit',
      internalKey: 'memory',
      isEdited: false,
    },
    {
      name: 'Memory Request',
      value: kiToMi(userdata.memoryRequest),
      total: handleConversion('memory', memoryRequest) || '',
      limitKey: 'Mi',
      mainKey: 'request',
      internalKey: 'memory',
      isEdited: false,
    },
    {
      name: 'Ephemeral Storage Limit',
      value: kiToMi(userdata.ephemeralStorage),
      total: handleConversion('ephemeralStorage', ephemeralStorage) || '',
      limitKey: '',
      mainKey: 'limit',
      internalKey: 'ephemeralStorage',
      isEdited: false,
    },
    {
      name: 'Ephemeral Storage Request',
      value: kiToMi(userdata.ephemeralStorageRequest),
      total:
        handleConversion('ephemeralStorage', ephemeralRequestStorage) || '',
      limitKey: '',
      mainKey: 'request',
      internalKey: 'ephemeralStorage',
      isEdited: false,
    },
    // {
    //   name: "Container CPU Limit",
    //   value: handleConversion("cpu", containerCpuLimit),
    //   total: handleConversion("cpu", containerCpuLimit),
    //   limitKey: "",
    //   mainKey: "defaultLimitPerContainer",
    //   internalKey: "cpu",
    //   isEdited: false,
    // },
    {
      name: 'Container CPU Request',
      value: '',
      total: handleConversion('cpu', containerCpuRequest) || '',
      limitKey: '',
      mainKey: 'defaultRequestPerContainer',
      internalKey: 'cpu',
      isEdited: false,
    },
    {
      name: 'Container Memory Request',
      value: '',
      total: handleConversion('memory', containerMemoryRequest) || '',
      limitKey: '',
      mainKey: 'defaultRequestPerContainer',
      internalKey: 'memory',
      isEdited: false,
    },
    {
      name: 'Container Storage Request',
      value: '',
      total:
        handleConversion('ephemeralStorage', containerStorageRequest) || '',
      limitKey: '',
      mainKey: 'defaultRequestPerContainer',
      internalKey: 'ephemeralStorage',
      isEdited: false,
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleModalClose = (del = false) => {
    if (del === true) {
      const cleared_slice = {
        ...currentResourceQuota,
        spec: {
          ...currentResourceQuota.spec,
          resourceQuota: {
            ...currentResourceQuota.spec.resourceQuota,
            spec: {
              ...currentResourceQuota.spec.resourceQuota.spec,
              sliceQuota: {
                ...currentResourceQuota.spec.resourceQuota.spec.sliceQuota,
                resources: {
                  ...currentResourceQuota.spec.resourceQuota.spec.sliceQuota
                    .resources,
                  limit: {
                    podCount: '',
                    ...defaultRQValues,
                  },
                  request: defaultRQValues,
                  defaultRequestPerContainer: defaultRQValues,
                },
              },
              clusterQuota:
                currentResourceQuota.spec.resourceQuota.spec.clusterQuota.map(
                  (quota) => ({
                    ...quota,
                    namespaceQuota: quota.namespaceQuota.map((nsQuota) => ({
                      ...nsQuota,
                      enforceQuota: false,
                      resources: {
                        ...nsQuota.resources,
                        ...emptyRqValues,
                      },
                    })),
                  })
                ),
            },
          },
        },
      };
      dispatch(setCurrentResourceQuota(cleared_slice));
      dispatch(setDeleteRQ(true));
      setOpenDeleteModal(false);
      history.push('/manage-resources/add/3');
    } else {
      setOpenDeleteModal(false);
    }
  };

  const resetResourceQuotaData = () => {
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    if (step == 2) {
      if (_.has(currentResourceQuota.spec, 'resourceQuota')) {
        if (
          _.has(currentResourceQuota.spec.resourceQuota.spec, 'clusterQuota')
        ) {
          currentResourceQuota.spec.resourceQuota.spec.clusterQuota?.forEach(
            (cluster) => {
              cluster.namespaceQuota.forEach((namespace) => {
                dispatch(
                  getResourceQuotaPromCountsForNamespace(
                    currentResourceQuota.metadata.name,
                    cluster.clusterName,
                    namespace.namespace
                  )
                );
              });
            }
          );
        }
      }
    }
  }, [step]);

  const handleResourceData = (data, isNew) => {
    let updatedRQQuota = { ...currentResourceQuota };
    if (/*_.has(updatedRQQuota, 'isNew') &&*/ isNew !== null) {
      updatedRQQuota.isNew = isNew;
      setIsNextDisabled(isNew);
    }
    data.forEach((item) => {
      if (_.has(updatedRQQuota.spec, 'resourceQuota')) {
        if (
          !updatedRQQuota.spec.resourceQuota.spec.sliceQuota.resources[
          item['mainKey']
          ]
        ) {
          updatedRQQuota.spec.resourceQuota.spec.sliceQuota.resources[
            item['mainKey']
          ] = {
            cpu: '',
            memory: '',
            ephemeralStorage: '',
          };
        }

        let value = item.total;

        if (
          item['mainKey'] === 'limit' ||
          item['mainKey'] === 'request' ||
          item['mainKey'] === 'defaultRequestPerContainer' ||
          item['mainKey'] === 'defaultLimitPerContainer'
        ) {
          // defaultRequestPerContainer and defaultLimitPerContainer may not exist

          if (item['internalKey'] === 'cpu') {
            value = value + 'm';
          } else if (
            item['internalKey'] === 'memory' ||
            item['internalKey'] === 'ephemeralStorage'
          ) {
            value = value + 'Mi';
          }
        }
        updatedRQQuota = {
          ...updatedRQQuota,
          spec: {
            ...updatedRQQuota.spec,
            resourceQuota: {
              ...updatedRQQuota.spec.resourceQuota,
              spec: {
                ...updatedRQQuota.spec.resourceQuota.spec,
                sliceQuota: {
                  ...updatedRQQuota.spec.resourceQuota.spec.sliceQuota,
                  resources: {
                    ...updatedRQQuota.spec.resourceQuota.spec.sliceQuota
                      .resources,
                    [item.mainKey]: {
                      ...updatedRQQuota.spec.resourceQuota.spec.sliceQuota
                        .resources[item.mainKey],
                      [item.internalKey]: value,
                    },
                  },
                },
              },
            },
          },
        };
      } else {
        updatedRQQuota = {
          ...updatedRQQuota,
          spec: {
            ...updatedRQQuota.spec,
            resourceQuota: {
              spec: {
                clusterQuota: [],
                sliceQuota: {
                  resources: {
                    userdata: {
                      podCount: 0,
                      cpu: 0,
                      ephemeralStorage: 0,
                      memory: 0,
                    },
                    limit: {
                      podCount: '',
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    request: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                  },
                },
              },
            },
          },
        };
        if (item['mainKey'] === 'limit' && item['internalKey'] === 'cpu') {
          updatedRQQuota.spec.resourceQuota.spec.sliceQuota.resources[
            item['mainKey']
          ][item['internalKey']] = item.total + 'm';
        } else if (
          item['mainKey'] === 'limit' &&
          item['internalKey'] === 'memory'
        ) {
          updatedRQQuota.spec.resourceQuota.spec.sliceQuota.resources[
            item['mainKey']
          ][item['internalKey']] = item.total + 'Mi';
        } else {
          updatedRQQuota.spec.resourceQuota.spec.sliceQuota.resources[
            item['mainKey']
          ][item['internalKey']] = item.total;
        }
      }
    });
    dispatch(setCurrentResourceQuota(updatedRQQuota));
  };

  const handleStepTwoNext = () => {
    const updatedResourceQuota = { ...currentResourceQuota };
    updatedResourceQuota.spec.resourceQuota.spec.clusterQuota =
      editedClusterQuota;
    dispatch(setCurrentResourceQuota(updatedResourceQuota));
    // when clear all values is confirmed, deleteRQ is set to two, which will hit delete route.
    // every time user enters step 3 by clicking next button, we need to set this to false to prevent user hitting delete route
    dispatch(setDeleteRQ(false));
    history.push('/manage-resources/add/3');
  };

  const gotToStepTwo = () => {
    // when clear all values is confirmed, deleteRQ is set to two, which will hit delete route.
    // every time user enters step 3 by clicking next button, we need to set this to false to prevent user hitting delete route
    dispatch(setDeleteRQ(false));
    history.push('/manage-resources/add/2');
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (location.pathname.includes('manage-resources/add')) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      <Stack>
        <Grid item>
          <div className="add-resource-data-container">
            <div className="flex">
              {topBarData
                .filter((data) => !data.mainKey.includes('Container'))
                .map((item, idx) => {
                  return (
                    <div style={{ paddingRight: 8 }} className="flex" key={idx}>
                      <CircularStatic value={item.value} total={item.total} />
                      <div style={{ marginLeft: 8 }}>
                        <Typography className="topbar-title">
                          {item.name}
                        </Typography>
                        {item.value == item.total && item.value == 0 ? (
                          <>
                            <Typography className="topbar-subtitle">
                              Used 0
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography className="topbar-subtitle">
                              Used {item.value}{' '}
                              {item.total == 0 ? (
                                ''
                              ) : (
                                <>/ {Math.round(item.total)}</>
                              )}
                            </Typography>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <AddResource
              step={step}
              resetResourceQuotaData={resetResourceQuotaData}
              handleResourceData={handleResourceData}
              sliceName={currentResourceQuota?.metadata?.name}
              currentResourceQuota={currentResourceQuota}
              // better way to do this is to turn the values into object and pass object and handle accordingly.
              // couldn't refactor since this was setup like this
              resourceData={topBarData}
            />
          </div>
        </Grid>
        {step == 2 && (
          <Grid>
            <ManageResourceTable
              currentResourceQuota={currentResourceQuota}
              checkNextButton={setIsNextDisabled}
            />
          </Grid>
        )}
        <DeleteModal open={openDeleteModal} handleClose={handleModalClose} />
      </Stack>
      <Box
        sx={{
          marginLeft: '-19px',
          marginBottom: '-12px',
          position: 'fixed',
          bottom: '12px',
          left: '240px',
          right: '0',
          zIndex: 10,
          padding: '12px 40px',
          background: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
          boxSizing: 'border-box',
        }}
      >
        {step == 2 && (
          <>
            <Button
              sx={{
                color: '#5A607C',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 600,
              }}
              onClick={() => history.push(`/manage-resources/add/3`)}
            >
              Skip step <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
            </Button>
            <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 2 }}>
              <Button
                onClick={() => history.push('/manage-resources/add/1')}
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  width: '100px',
                  textTransform: 'none',
                  fontWeight: 600,
                }}
                variant="outlined"
              >
                <ArrowBackIosIcon style={{ fontSize: '12px' }} /> Previous{' '}
              </Button>
              <Button
                disabled={isNextDisabled}
                onClick={handleStepTwoNext}
                style={{ width: '100px' }}
                className="btn"
                variant="contained"
                data-testid="next_btn"
              >
                Next <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
              </Button>
            </Box>
          </>
        )}
        {step == 1 && (
          <Button
            // disabled={currentResourceQuota.isNew}
            disabled={isNextDisabled}
            onClick={gotToStepTwo}
            style={{ width: '100px' }}
            className="btn"
            variant="contained"
            data-testid="next_btn"
          >
            Next <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
          </Button>
        )}
      </Box>
    </>
  );
}
