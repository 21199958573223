import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Link,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Radio,
  Dialog,
  DialogContent,
  DialogTitle,
  InputBase,
  Typography,
  Paper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './index.css';
import CloseIcon from '@mui/icons-material/Close';
import { setCurrentSlice } from '../../../actions/namespaceActions'; // Verify
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

export default function Row(props) {
  const { slice, radioDisable = false } = props;
  const [open, setOpen] = useState(false);
  const [namespaceModal, setNamespaceModal] = useState(false);
  const dispatch = useDispatch();
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const [clusterDetails, setClusterDetails] = useState({});
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(false);
  const applicationNamespaceList =
    slice?.spec?.namespaceIsolationProfile?.applicationNamespaces;
  const allowedNamespaceList =
    slice?.spec?.namespaceIsolationProfile?.allowedNamespaces;

  const totalApplicaitonNamespaceCount =
    applicationNamespaceList &&
    applicationNamespaceList.reduce((total, item) => {
      return total + item.clusters.length;
    }, 0);

  const totalAllowedNamespaceCount =
    allowedNamespaceList &&
    allowedNamespaceList.reduce((total, item) => {
      return total + item.clusters.length;
    }, 0);

  const isChecked = useMemo(() => {
    return currentSlice?.metadata?.name === slice?.metadata?.name;
  }, [slice, currentSlice]);

  useEffect(() => {
    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((clusterName, key) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name === clusterName
        );
        list[clusterName] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion,
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });

      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  // ENUMS
  const APPLICATION_NS = 'APPLICATION_NS';
  const ALLOWED_NS = 'ALLOWED_NS';

  const [activeNS, setActiveNS] = useState(APPLICATION_NS);
  const openConditionalModal = (flag, type) => {
    setNamespaceModal(flag);
    setActiveNS(type);
  };
  const renderPopupTable = () => {
    let activeList =
      activeNS === APPLICATION_NS
        ? applicationNamespaceList
        : allowedNamespaceList;
    return (
      activeList &&
      activeList.map((item, indexActiveList) => {
        if (
          (search && searchText !== '' && item.namespace === searchText) ||
          !search
        ) {
          return item.clusters.map((cluster, indexCluster) => {
            return (
              <TableRow
                sx={{ paddingY: '0px' }}
                key={`${cluster}-${indexActiveList}-${indexCluster}`}
              >
                <TableCell
                  sx={{
                    padding: '16px 24px',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 600,
                  }}
                >
                  {indexActiveList + indexCluster + 1}
                </TableCell>
                <TableCell
                  sx={{
                    padding: '16px 24px',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 600,
                  }}
                >
                  {item['namespace']}
                </TableCell>
                <TableCell
                  sx={{
                    padding: '16px 24px',
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: 600,
                  }}
                >
                  {cluster === `*` || cluster === `'*'` ? (
                    <label style={{ color: 'green' }}>
                      {`Namespace sameness`}
                    </label>
                  ) : (
                    cluster
                  )}
                </TableCell>
              </TableRow>
            );
          });
        }
      })
    );
  };
  // useEffect(() => {
  //   const currentSliceData = slice?.metadata?.name;
  //   localStorage.setItem('currentSlice', currentSliceData);

  //   return () => {
  //     localStorage.removeItem('currentSlice');
  //   };
  // }, [slice.metadata?.name]);

  localStorage.setItem('currentSlice', slice.metadata?.name);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ padding: '12px', lineHeight: '40px' }}>
          <IconButton
            sx={{ padding: '0px', lineHeight: '40px' }}
            aria-label="expand row"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon
                sx={{ width: '40px', height: '40px', padding: '0px' }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{ width: '40px', height: '40px', padding: '0px' }}
              />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            width: '20%',
            fontFamily: 'Bai Jamjuree',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '24px 0px 24px 10px',
            fontSize: '16px',
          }}
        >
          {!radioDisable && (
            <Radio
              style={{
                padding: '0px 0px 1px 10px',
                lineHeight: '20px',
                height: '20px',
                marginRight: '18px',
              }}
              checked={isChecked}
              onChange={() => {
                dispatch(setCurrentSlice(slice));
              }}
              name="slice-row"
              inputProps={{ 'aria-label': 'selected slice row' }}
            />
          )}
          {slice.metadata?.name}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            width: '20%',
            padding: '24px 0px 24px 24px',
            lineHeight: '20px',
            fontSize: '16px',
            fontWeight: '600',
          }}
        >
          {applicationNamespaceList?.length > 0 ? (
            <Link
              underline="hover"
              onClick={() => openConditionalModal(true, APPLICATION_NS)}
              href="javascript:void(0)"
              style={{
                fontFamily: 'Bai Jamjuree',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#2868F3',
                fontSize: '16px',
              }}
            >
              View all
            </Link>
          ) : (
            <Typography
              style={{
                fontStyle: 'italic',
                fontFamily: 'Bai Jamjuree',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#81839A',
              }}
            >
              No Namespaces added
            </Typography>
          )}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            padding: '24px 0px 24px 24px',
            lineHeight: '20px',
          }}
        >
          {slice && slice?.spec?.namespaceIsolationProfile?.isolationEnabled ? (
            <Typography
              style={{
                fontFamily: 'Bai Jamjuree',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#12153E',
              }}
            >
              Isolated with Network policy
            </Typography>
          ) : (
            <Typography
              style={{
                fontStyle: 'italic',
                fontFamily: 'Bai Jamjuree',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#81839A',
              }}
            >
              Isolation not applied
            </Typography>
          )}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            padding: '24px 0px 24px 24px',
          }}
        >
          {allowedNamespaceList?.length > 0 ? (
            <Link
              underline="hover"
              onClick={() => openConditionalModal(true, ALLOWED_NS)}
              href="javascript:void(0)"
              style={{
                fontFamily: 'Bai Jamjuree',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#2868F3',
                fontSize: '16px',
              }}
            >
              View all
            </Link>
          ) : (
            <Typography
              style={{
                fontStyle: 'italic',
                fontFamily: 'Bai Jamjuree',
                fontWeight: '600',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#81839A',
              }}
            >
              No external namespaces
            </Typography>
          )}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '24px 0px 24px 24px',
          }}
        >
          {slice.metadata?.annotations?.desc}
        </TableCell>
      </TableRow>

      {/* Collapsable Table */}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: open ? 10 : 0,
            paddingTop: open ? 10 : 0,
            transition: '0.3s ease-in-out',
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex', justifyContent: 'center' }}>
              <Table
                size="small"
                aria-label="purchases"
                sx={{ margin: '24px 40px' }}
              >
                <TableHead
                  className="table-head"
                  sx={{ borderColor: 'transparent' }}
                >
                  <TableRow style={{ border: '1px solid #D9DFEE' }}>
                    <TableCell sx={{ fontSize: '12px' }}>CLUSTERS</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>
                      DEPLOYMENT LOCATION
                    </TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>CLOUD TYPE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slice.spec?.clusters &&
                    slice.spec?.clusters.map((cluster, index) => {
                      return (
                        <TableRow key={`${slice.metadata?.name}-${index}`}>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            style={{ borderLeft: '1px solid #D9DFEE' }}
                            className="txt-bold"
                          >
                            {cluster}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            className="txt-bold"
                          >
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster]['location']}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: '16px' }}
                            style={{ borderRight: '1px solid #D9DFEE' }}
                            className="txt-bold"
                          >
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster][
                                'cloudType'
                              ]?.toUpperCase()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Namespace Modal */}
      <Dialog
        open={namespaceModal}
        onClose={() => {
          setNamespaceModal(false);
          setSearchText('');
          setSearch(false);
        }}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%',
            minHeight: '80%',
            maxHeight: '80%',
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{ padding: '24px 16px 16px 16px' }}
          id="scroll-dialog-title"
        >
          <Typography className="dialogue-title">
            {`Total ${
              activeNS === APPLICATION_NS ? 'Application' : 'Allowed'
            } Namespaces: ${
              activeNS === APPLICATION_NS
                ? totalApplicaitonNamespaceCount
                : totalAllowedNamespaceCount
            }`}
            <IconButton
              aria-label="close"
              onClick={() => setNamespaceModal(false)}
              sx={{
                float: 'right',
                padding: 0,
              }}
            >
              <CloseIcon htmlColor="black" />
            </IconButton>
          </Typography>
          <Typography sx={{ paddingTop: '4px' }} className="dialogue-subtitle">
            {`Slice name: ${slice.metadata?.name}`}
          </Typography>
          <Paper
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '12px',
            }}
          >
            <IconButton
              className="search-icon"
              onClick={() => setSearch(true)}
              type="submit"
              sx={{ p: '4px' }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Namespaces"
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearch(false);
              }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Paper>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <Table size="small" aria-label="purchases">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{
                    padding: '14px 0px 13px 24px',
                    width: '15%',
                    lineHeight: '15px',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  N.O
                </TableCell>
                <TableCell
                  sx={{
                    padding: '14px 0px 13px 24px',
                    width: '40%',
                    lineHeight: '15px',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  NAMESPACES
                </TableCell>
                <TableCell
                  sx={{
                    padding: '14px 0px 13px 24px',
                    width: '40%',
                    lineHeight: '15px',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  CLUSTERS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderPopupTable()}</TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
