export const selectCurrentSlice = (state) => {
  return state.NodeAffinity.currentSlice;
};

export const selectSliceLabels = (state, clusterName) => {
  const local_labels = [];
  state.NodeAffinity.node_labels
    .filter((cluster) => {
      if (clusterName) {
        return cluster.clusterName === clusterName;
      }
      return true;
    })
    .forEach((cluster) => {
      cluster.nodeDetails.forEach((node) => {
        Object.entries(node.labels).forEach(([key, value]) => {
          const label = { [key]: value };
          const exists = local_labels.find((_label) => _label[key] === value);
          if (!exists) {
            local_labels.push(label);
          }
        });
      });
    });
  return local_labels;
};

export const selectNodeLabels = (state) => {
  return state.NodeAffinity.node_labels;
};

export const selectSliceClusters = (state) => {
  return state.NodeAffinity.node_labels.map((cluster) => {
    return cluster.clusterName;
  });
};

export const selectAffinityProfiles = (state, cluster) => {
  if (cluster) {
    return state.NodeAffinity.affinity_profiles.filter(
      (profile) => profile.cluster === cluster
    );
  }
  return state.NodeAffinity.affinity_profiles;
};

export const selectHasAffinity = (state) => {
  const slice = state.NodeAffinity.currentSlice;

  return state.NodeAffinity.node_affinity_list.some(
    (affinity) => affinity.metadata.name === slice?.metadata?.name
  );
};

export const selectAffinityApiAction = (state) => {
  return state.NodeAffinity.api_action;
};

export const selectSliceNamepaces = (state) => {
  const currentSlice = selectCurrentSlice(state);
  let clusters = {};

  const { clusters: slice_clusters, namespaceIsolationProfile } =
    currentSlice.spec;

  slice_clusters?.map((cluster) => {
    const namespaces = namespaceIsolationProfile?.applicationNamespaces
      .filter((namespace) => {
        return (
          namespace.clusters.includes(cluster) ||
          namespace.clusters.includes('*')
        );
      })
      .map((namespace) => namespace.namespace);
    clusters[cluster] = namespaces;
    return cluster;
  });

  return clusters;
};

export const selectCurrentSliceAffinity = (state) => {
  return state.NodeAffinity.currentSliceAffinity;
};
