import { SdkBackend } from '../backend/MeshBackend';
import { getMeshBackend } from '../backend/MeshBackendProxy';

import {
  CLEAR_LEGEND_DATA,
  CLEAR_SYSTEM_EVENT,
  SET_ACTIVE_CLOUD_OPTION,
  SET_ACTIVE_SLICE_OPTION,
  SET_CLUSTER_DETAIL,
  SET_CLUSTER_METADATA,
  SET_ENTITY_COUNT,
  SET_LEGEND_DATA,
  SET_RQ_TRENDS_DATA,
  SET_SLICE_DETAIL,
  START_SPINNER,
  STOP_SPINNER,
} from './types';
const meshBackend = getMeshBackend();
const sdkBackend = new SdkBackend();

export const getCompareClusterStats = (anchoredCluster, slice, start, end) => {
  return async (dispatch, getState) => {
    dispatch({ type: START_SPINNER });
    let response = await sdkBackend.getCompareAllClusterStats(
      getState(),
      dispatch,
      anchoredCluster,
      slice,
      start,
      end
    );
    dispatch({ type: STOP_SPINNER });
    return response;
  };
};

// Experimental :: Has performance issues on querying multiple APIs simultaneously for latency report
export const getTopologyViewDetails = (slice, timestamp) => {
  return async (dispatch, getState) => {
    dispatch({ type: START_SPINNER });
    const response = await sdkBackend.getTopologyViewMetricStats(
      getState(),
      dispatch,
      slice,
      timestamp
    );
    dispatch({ type: STOP_SPINNER });
    return response;
  };
};

export const setActiveSelectedSlice = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_SLICE_OPTION,
      payload: data,
    });
  };
};

export const setActiveSelectedCloud = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_CLOUD_OPTION,
      payload: data,
    });
  };
};

export const setLegendData = (data, filteredData) => {
  return (dispatch) => {
    dispatch({
      type: SET_LEGEND_DATA,
      payload: {
        data,
        filteredData,
      },
    });
  };
};
export const clearLegendData = (data) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LEGEND_DATA,
    });
  };
};

export const getClusterDetail = (start = null, end = null) => {
  return async (dispatch, getState) => {
    // Passing on start and end date for getting range of cluster used capacity
    // Default value for both of these metrics are "null" which provide us the single metrics value for cluster used capacity of current timeframe
    let response = await sdkBackend.getClusterDetail(
      getState(),
      dispatch,
      start,
      end
    );

    if (response) {
      if (start !== null && end !== null) {
        // If we provide the start and end time frame then we simply return the value to plot the latest data
        return response;
      } else {
        // If we dont want range of used capacity value we simply store the returned value to redux store
        dispatch({ type: SET_CLUSTER_DETAIL, payload: response });
      }
    }
  };
};

export const getClusterMetaData = () => {
  return (dispatch, getState) => {
    meshBackend.getClusterMetaData(getState(), dispatch).then((response) => {
      response && dispatch({ type: SET_CLUSTER_METADATA, payload: response });
    });
  };
};

export const getClusterCapacityDetail = () => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getClusterCapacityDetail(getState(),dispatch);
    // if(response) dispatch({type:SET_CLUSTER_CAPACITY_DETAIL,payload:response})
  };
};
export const getEntityCount = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getEntityCount(getState(), dispatch);
    if (response) dispatch({ type: SET_ENTITY_COUNT, payload: response });
  };
};

export const getNodeCapacityDetail = (clusterName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getNodeCapacityDetail(
      getState(),
      dispatch,
      clusterName
    );
    if (response) return response;
  };
};

export const getNamespaceCapacityDetail = (clusterId, nodeName) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getNamespaceCapacityDetail(getState(),dispatch,clusterId, nodeName);
    // if(response) return response.clusterNodeNamespace;
  };
};

export const getAllSliceNamespaces = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllSliceNamespaces(getState(), dispatch);
    if (response) {
      dispatch({ type: SET_SLICE_DETAIL, payload: response });
      dispatch({ type: SET_ACTIVE_SLICE_OPTION, payload: response[0] });
    }
  };
};

export const getAllServiceDependencyRequest = (params) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllServiceDependencyRequest(
      getState(),
      dispatch,
      params
    );
    if (response) return response;
  };
};

export const getSystemEvent = (params) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getSystemEvent(
    //   getState(),
    //   dispatch,
    //   params
    // );
    // if (response)
    // dispatch({ type: SET_SYSTEM_EVENT, payload: response });
  };
};

export const clearSystemEvent = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SYSTEM_EVENT, payload: [] });
  };
};

export const getKubernetesDashboard = (clusterName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getKubernetesDashboard(
      getState(),
      dispatch,
      clusterName
    );
    if (response) return response;
  };
};

export const getRqtrendsData = (slice, cluster, namespace, start, end) => {
  return async (dispatch, getState) => {
    dispatch({ type: START_SPINNER });
    let response = await sdkBackend.getRqtrends(
      getState(),
      dispatch,
      slice,
      cluster,
      namespace,
      start,
      end
    );
    dispatch({ type: STOP_SPINNER });
    dispatch({ type: SET_RQ_TRENDS_DATA, payload: response });
  };
};
export const getCurentProject = () => {
  return async (dispatch, getState) => {
    try {
      const response = await sdkBackend.getCurentProject(getState(), dispatch);
      console.log(response);

      // Store the response in local storage
      localStorage.setItem('currentProjectData', JSON.stringify(response));

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};
