import {
  Button,
  Grid,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React, { useEffect} from "react";
import { BodyRoot } from "../Body/BodyStyles";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSliceCustomObjectList,
  clearCurrentSlice,
  editSlice,
  setResourceVersion,
} from "../../actions/ConnectClusterActions";
import SliceListRow from "./SliceListRow";
import { ReactComponent as NoSliceRobot } from "../../assets/images/NoSliceRobot.svg";
import { ReactComponent as NotificationsIcon } from '../../assets/navbarIcons/notifications.svg';

const StyledTableCell = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "12px",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "24px",
  textAlign: "left",
  backgroundColor: "#DEE8FF",
  lineHeight: "15px"
}));

function SliceList({ clearCurrentSlice, editSlice, setResourceVersion }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const sliceList = useSelector((state) => state.ConnectCluster.sliceList);

  useEffect(() => {
    dispatch(getSliceCustomObjectList());
  }, []);

  const handleRedirectToNewUi = () => {
    window.location.href = `/events`;
  };

  const onClick = () => {
    clearCurrentSlice();
    setResourceVersion("");
    editSlice(false);
    history.push("/connect-cluster/slice/1");
  };

  return (
    <BodyRoot>
      <Grid
        container
        height="100vh"
        width="100%"
        direction="column"
        flexWrap="nowrap"
        overflow="auto"
        px={5}
        py={3}
        bgcolor="#f4f7fe"
        gap={4}
      >
        <Grid item justifyContent={'space-between'} container direction="row" flexWrap="nowrap" gap="2px">
          <div style={{display:'flex', flexDirection:'column'}}>
          <Grid item>
            <Typography fontSize={34} fontWeight={700}>
              Slice
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={14} fontWeight={400} color="#12153E">
              Welcome! You can create virtual clusters or “Application Slices”
              to segment your infrastructure for your applications’ needs.
            </Typography>
          </Grid>
          </div>
          <NotificationsIcon style={{cursor:'pointer'}} onClick={handleRedirectToNewUi} />
        </Grid>
        <Grid
          item
          xs
          overflow="auto"
          container
          direction="column"
          flexWrap="nowrap"
          pt={6}
          bgcolor="#ffffff"
          boxShadow="0px 5px 25px rgba(40, 104, 243, 0.04)"
          borderRadius={4}
          position="relative"
          justifyContent="space-between"
        >
          <TableContainer
            sx={{ height: "100%", borderBottom: "solid #DEE8FF 5px" }}
          >
            <Table
              stickyHeader
              aria-label="Slice Table"
              
              sx={{ fontSize: "12px" }}
            >
              <TableHead sx={{ backgroundColor: "#DEE8FF" }}>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Slice Type</StyledTableCell>
                  <StyledTableCell>Gateway Type</StyledTableCell>
                  <StyledTableCell>CA Type</StyledTableCell>
                  <StyledTableCell>QOS Type</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Clusters Connected</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sliceList?.length > 0 ? (
                  sliceList.map((row, index) => <SliceListRow key={index} slice={row} />)
                ) : (
                  <TableRow colSpan={9}>
                    <TableCell
                      sx={{ border: "none", marginTop: "20px" }}
                      align="left"
                      colSpan={9}
                    >
                      <NoSliceRobot style={{ marginTop: "75px" }} />
                      <Typography fontSize={24} fontWeight={400}>
                        You have no Slices created.
                      </Typography>
                      <Typography fontSize={24} fontWeight={400}>
                        Get started by creating a slice.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={onClick}
            sx={{
              alignSelf: "center",
              textTransform: "none",
              marginTop: "40px",
              marginBottom: "40px",
              backgroundColor: "#2868f3",
              width: "150px",
              height: "50px",
            }}
            variant="contained"
          >
            Add Slice
          </Button>
        </Grid>
      </Grid>
    </BodyRoot>
  );
}

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  clearCurrentSlice,
  editSlice,
  setResourceVersion,
})(SliceList);
