import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Dialog, IconButton, styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import componentName from '../../constants/componentName.json';

import {
  editSlice,
  getSliceCustomObjectList,
  setCurrentSlice,
  setResourceVersion,
} from '../../actions/ConnectClusterActions';
import { getSliceObject } from '../../actions/ConnectClusterActions';
import SliceDeletionConfirmModal from '../Slices/SliceDeletionConfirmationModal';
import './index.css';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutline from '@mui/icons-material/ErrorOutlineOutlined';
import WatchLater from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function SliceListRow({ slice }) {
  const [deleteSliceDialog, setDeleteSliceDialog] = useState(false);
  const [OffboardSliceData, setOffboardSliceData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getSliceObject(slice.metadata?.name)).then((response) => {
      setOffboardSliceData(response);
    });
  }, [dispatch]);
  const onEditHandler = () => {
    const payload = {
      sliceName: slice.metadata?.name,
      maxClusters: slice.spec?.maxClusters,
      sliceDisplayName: slice?.metadata?.annotations?.displayName,
      sliceType: slice.spec?.sliceType,
      sliceGWType: slice.spec?.sliceGatewayProvider?.sliceGatewayType,
      sliceCertAuthType: slice.spec?.sliceGatewayProvider?.sliceCaType,
      sliceQosProfile: slice.spec?.qosProfileDetails && 'Custom',
      sliceQosType: slice.spec?.qosProfileDetails?.queueType,
      sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
      sliceQosPriority: slice.spec?.qosProfileDetails?.priority,
      bandwidthCeilingKbps: slice.spec?.qosProfileDetails?.bandwidthCeilingKbps,
      bandwidthGuaranteedKbps:
        slice.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,
      dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
      sliceSubnet: slice.spec?.sliceSubnet,
      sliceIpamType: slice.spec?.sliceIpamType,
      sliceDesc: slice.metadata?.annotations?.desc,
      clusters: slice?.spec?.clusters,
      isolationEnabled:
        slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
      applicationNamespaces:
        slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
      allowedNamespaces:
        slice?.spec?.namespaceIsolationProfile?.allowedNamespaces,
      kubesliceEvents: OffboardSliceData?.status?.kubesliceEvents,
    };
    dispatch(setCurrentSlice(payload));
    dispatch(setResourceVersion(slice?.metadata?.resourceVersion));
    dispatch(editSlice(true));
    history.push('/connect-cluster/slice/1');
  };

  useEffect(() => {
    dispatch(getSliceCustomObjectList());
  }, [dispatch]);

  const openDialog = () => {
    setDeleteSliceDialog(true);
  };

  const closeDialog = () => {
    setDeleteSliceDialog(false);
  };

  const handleSliceDelitionOk = () => {
    dispatch(getSliceCustomObjectList());
    setDeleteSliceDialog(false);
  };

  let identifier = slice?.status?.kubesliceEvents || [];

  let filterKSEvents = (kubesliceEvents, type) =>
    kubesliceEvents.filter((data) => {
      return data.type === type;
    });

  let tooltipStatus = (ksEvents) => (
    <div>
      {ksEvents.map((data) => (
        <div key={data.reason}>
          {data.reason && <p>Reason: {data.reason}</p>}
          {data.components && (
            <>
              Components:{' '}
              <ul>
                {data.components.map((component) => (
                  <li key={component}>
                    {componentName[component.split('/')[0]]}
                  </li>
                ))}
              </ul>
            </>
          )}
          {data.action && <p>Actions: {data.action}</p>}
        </div>
      ))}
    </div>
  );

  let status = null;
  if (slice?.status?.kubesliceEvents?.length > 0) {
    if (
      slice.status.kubesliceEvents.some((data) => data.type === 'InProgress')
    ) {
      status = 'InProgress';
    } else {
      status = 'Error';
    }
  }

  const renderIcon = () => {
    let ksEvents = slice?.status?.kubesliceEvents;
    switch (status) {
      case 'InProgress':
        let inProgressText = tooltipStatus(
          filterKSEvents(ksEvents, 'InProgress')
        );
        return (
          <span style={{ position: 'relative',}}>
            <Tooltip title={inProgressText} arrow>
              <WatchLater fontSize="small" color="warning" />
            </Tooltip>
          </span>
        );
      case 'Error':
        let errorText = tooltipStatus(filterKSEvents(ksEvents, 'Error'));
        return (
          <span style={{ position: 'relative'}}>
            <Tooltip title={errorText} arrow>
              <ErrorIcon fontSize="small" color="error" />
            </Tooltip>
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableRow className='row'>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {/* {renderIcon()} */}
          <span style={{ display: 'flex', justifyContent: 'left' }}>
            <span
              style={{
                display: 'flex',
                position: 'relative',
                
              }}
            >
              {slice.metadata?.name}
              {renderIcon()}
            </span>
          </span>
        </TableCell>

        <TableCell align="left" sx={{paddingLeft:'24px'}}>{slice.spec?.sliceType}</TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {slice.spec?.sliceGatewayProvider?.sliceGatewayType}
        </TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {slice.spec?.sliceGatewayProvider?.sliceCaType}
        </TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {slice.spec?.qosProfileDetails?.queueType}
        </TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {slice.metadata?.annotations?.desc}
        </TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          {slice?.spec?.clusters &&
            slice?.spec?.clusters.map((cluster, index) => (
              <div key={index}>{cluster}</div>
            ))}
        </TableCell>
        <TableCell align="left" sx={{paddingLeft:'24px'}}>
          <IconButton onClick={onEditHandler}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={openDialog}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {deleteSliceDialog && (
        <SliceDeletionConfirmModal
          sliceName={slice.metadata?.name}
          handleClose={() => setDeleteSliceDialog(false)}
          onOk={handleSliceDelitionOk}
        />
      )}
    </>
  );
}
