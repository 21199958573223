import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Row from "../LandingPage/Row";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearCurrentSlice } from "../../../actions/namespaceActions";
import { getSliceObject } from "../../../actions/ConnectClusterActions";
const FinalButton = styled(Button)({
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  height: "50px",
  padding: "10px 24px",
  lineHeight: 1.5,
  backgroundColor: "#2868f3",
  borderColor: "#0063cc",
  fontFamily: ["Bai Jamjuree"].join(","),
  "&:hover": {
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    boxShadow: "none",
  },
});

const Finalize = ({ currentSlice, clearCurrentSlice, getSliceObject }) => {
  const history = useHistory();
  const [slice, setSlice] = useState({});
  const onDone = () => {
    clearCurrentSlice();
    history.push("/manage-namespaces");
  };

  useEffect(() => {
    if (currentSlice && currentSlice?.metadata?.name) {
      getSliceObject(currentSlice?.metadata?.name)
        .then((res) => {
          setSlice(res);
        })
        .catch((err) => console.log(err));
    } else {
      history.push("/manage-namespaces");
    }
  }, []);

  return (
    <>
      {slice?.metadata?.name && (
        <Grid sx={{ marginTop: "24px" }} item>
          <Paper elevation={0} sx={{ position: "relative", border: "1px solid #B6B8D6" }}>
            <Grid
              style={{ padding: "16px 30px" }}
              container
              spacing={2}
              direction="row"
              alignItems={"center"}
            >
              <Grid item xs={10}>
                <Typography
                  className="step-title-header"
                  gutterBottom
                  component="div"
                >
                  Slice
                </Typography>
              </Grid>
              <Grid textAlign={"end"} item xs={2}>
                <FinalButton onClick={onDone}>Done</FinalButton>
              </Grid>
            </Grid>

            <TableContainer>
              <Table aria-label="collapsible table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell
                      sx={{ color: "#12153E", fontWeight: 600, paddingLeft: '24px', paddingTop: '15px', paddingBottom: '15px', lineHeight: '15px', fontSize: '12px' }}
                      width={"60px"}
                    />
                    <TableCell
                      sx={{ color: "#12153E", fontWeight: 600, paddingLeft: '24px', paddingTop: '15px', paddingBottom: '15px', lineHeight: '15px', fontSize: '12px' }}
                      width={"25%"}
                    >
                      SLICE NAME
                    </TableCell>
                    <TableCell
                      sx={{ color: "#12153E", fontWeight: 600, paddingLeft: '24px', paddingTop: '15px', paddingBottom: '15px', lineHeight: '15px', fontSize: '12px' }}
                      width={"18%"}
                    >
                      NAMESPACES
                    </TableCell>
                    <TableCell sx={{ color: "#12153E", fontWeight: 600, paddingLeft: '24px', paddingTop: '15px', paddingBottom: '15px', lineHeight: '15px', fontSize: '12px' }}>
                      DESCRIPTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Row
                    key={slice?.metadata?.name}
                    slice={slice}
                    radioDisable={true}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentSlice: state.NamespaceOnboard.currentSlice,
  };
};
export default connect(mapStateToProps, {
  clearCurrentSlice,
  getSliceObject,
})(Finalize);
