import {
    Divider,
    Grid,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { deleteSliceCustomObject, clearCurrentSlice } from "../../../../actions/ConnectClusterActions";
  import { RowContainer } from "./RowContainer";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";


const SliceDataRow = (props) => {
    const history = useHistory();
    if (Object.keys(props.value).length === 0) return null;
    const {
      sliceName = "",
      sliceType = "",
      sliceGWType = "",
      sliceCertAuthType = "",
      sliceQosType = "",
      sliceDesc = "",
    } = props.value;

    const onDelete = ()=>{
      if(sliceName)
        {
          if(props.editSlice){
            props.deleteSliceCustomObject(sliceName)
          .then(()=>{
            history.push("/connect-cluster")
          })
          }
          else{
            props.clearCurrentSlice();
            history.push("/connect-cluster")
          }
          
        }
    }
    return (
      <>
        <RowContainer>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {sliceName}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
            {sliceType}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {sliceGWType}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {sliceCertAuthType}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {sliceQosType}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {sliceDesc}
            </Typography>
          </Grid>
        </RowContainer>
        <Divider sx={{ width: "95%", alignSelf: "center" }} color="#DEE8FF" />
      </>
    );
  };

  const mapStateToProps = (state) => {
    return {
      currentSlice: state.ConnectCluster.currentSlice,
      editSlice: state.ConnectCluster.editSlice
    };
  };
  export default connect(mapStateToProps, {
    clearCurrentSlice,
    deleteSliceCustomObject,
  })(SliceDataRow);