import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from "@mui/material";
import AveshaIcon from "./../../assets/icons/AveshaLoginLogo.svg";
import { connect } from "react-redux";
import background from "./../../assets/images/login_mesh_background.png";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import YAML from "yaml";
import CancelIcon from "@mui/icons-material/Cancel";
import { loginUser, changeUserPassword, authLookupProjects, authLookupOidc } from "./../../actions/loginAction";
import { useFormField } from "../Commons/formUtils";
import { removeError } from "../../actions/SnackbarActions";
import CircularProgress from '@mui/material/CircularProgress';
import { AUTH_PROVIDER, AUTH_PROVIDER_PARAMETER } from "../../constants/enums";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const paperStyle = {
  padding: 20,
  minHeight: "75vh",
  width: "40vw",
  margin: "40px 60px",
  borderRadius: "14px",
};
const FileInput = styled(Grid)`
  height: 50px;
  width: 100%;
  border: solid #e3e3e3 1px;
  border-radius: 4px;
`;
const FileInputText = styled(Typography)`
  :hover {
    cursor: pointer;
  }
`;
const mainGridStyle = {
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  overflow: "auto",
};
const headStyle = {
  fontFamily: "Bai Jamjuree",
  color: "#03051E",
  fontSize: "36px",
  lineHeight: "120%",
  fontWeight: "bold",
  fontStyle: "normal",
  margin: "2px auto",
};
const textStyle = {
  fontFamily: "Bai Jamjuree",
  color: "#03051E",
  fontSize: "20px",
  lineHeight: "30px",
  fontWeight: "normal",
  fontStyle: "normal",
  margin: "2px auto",
  opacity: "0.8",
};

const CustomTextField = styled(TextField)({
  color: "#03051E",
  backgroundColor: "#fff",
  fontFamily: "Bai Jamjuree !important",
  fontWeight: "normal",
  "& label.Mui-focused": {
    color: "#797A86",
    fontFamily: "Bai Jamjuree !important",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  "& .MuiInput-underline:after": {
    fontFamily: "Bai Jamjuree !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#797A86",
      fontFamily: "Bai Jamjuree !important",
      border: "1px solid #EBEBEB",
    },
    "&:hover fieldset": {
      borderColor: "silver",
      fontFamily: "Bai Jamjuree !important",
    },
    "&.Mui-focused fieldset": {
      color: "#797A86",
      fontFamily: "Bai Jamjuree !important",
      border: "1px solid #EBEBEB",
    },
  },
});

const LoginScreen = (props) => {
  // const [token, setToken] = useState("");
  const token = useFormField("");
  // const [namespace, setNamespace] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // const [kubeConfigFile, setKubeConfigFile] = useState(null);
  const kubeConfigFile = useFormField(null);
  const [showUsernameInChangePassword, setShowUsernameInChangePassword] =
    useState(false);
  const loginRef = useRef();
  const fileInput = useRef();
  const changePassRef = useRef();

  useEffect(() => {
    if (props.changePassword && !showUsernameInChangePassword && !token) {
      setShowUsernameInChangePassword(true);
    }
  }, [props.changePassword, showUsernameInChangePassword, token]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        let e = new Event("click", { bubbles: true });
        props.changePassword
          ? changePassRef.current.dispatchEvent(e)
          : loginRef.current.dispatchEvent(e);
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  // state details and implementaion details for lookup project api
  const [lookupResponse, setLookupResponse] = useState(null);
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [namespaceError, setNamespaceError] = useState(false);
  const [idpLogin, setLogin] = useState(false);
  const [idpName, setIdpName] = useState("OIDC");
  const [idpAuthUrl, setIdpAuthUrl] = useState("");

  const handleSelectedProjectChange = (event) => {
    setSelectedNamespace(event.target.value);
    setNamespaceError(false);
  };

  const verifyProxyAuth = async () => {
    const host = window.location.href;
    const kubernetesProxy = host.match(/(.*)\/api\/v1\/namespaces\/(.*)\/services\/(.*)\/proxy\/(.*)/i);
    const proxyMatch = kubernetesProxy && kubernetesProxy.length > 0;
    const oidcRedirectUrl = `${window.location.origin}${window.location.pathname}?login=oidc`
    const urlParams = new URLSearchParams(window.location.search);
    const oidcParam = urlParams.get('login') === 'oidc';
    let lr = undefined;
    if (proxyMatch || oidcParam) {
      const projectLookupRequest = {
        authProvider: AUTH_PROVIDER,
        authProviderParameter: oidcParam ? 'kot' : AUTH_PROVIDER_PARAMETER,
      }
      if (proxyMatch) {
        projectLookupRequest.kubeApiServerEndpoint = kubernetesProxy[1]
      }
      lr = await props.authLookupProjects(projectLookupRequest)
      if (lr) {
        setLookupResponse(lr.data);
      } else {
        setLookupResponse({});
      }
    } else {
      setLookupResponse({});
    }
    if (!proxyMatch && !(lr?.data?.projectNamespaces?.length > 0)) {
      const lookupOidcResponse = await props.authLookupOidc(oidcRedirectUrl)
      if(lookupOidcResponse) {
        if (lookupOidcResponse?.authorization_url) {
          setLogin(true)
          setIdpAuthUrl(lookupOidcResponse?.authorization_url)
        }
        setIdpName(lookupOidcResponse?.auth_name || "OIDC")
      }
    }
  }

  const getSelectedNamespace = () => {
    if(selectedNamespace === '' && lookupResponse?.projectNamespaces.length === 1) {
      setSelectedNamespace(lookupResponse.projectNamespaces[0]);
      return lookupResponse.projectNamespaces[0]
    } else {
      return selectedNamespace
    }
  }

  // On every project load verify the authentication
  useEffect(() => {
    verifyProxyAuth();
  }, [])

  const conditionalInputFields = () => {
    if (lookupResponse?.projectNamespaces?.length > 0) {
      return (
        <React.Fragment>
          <Box sx={{ minWidth: 120, marginTop: 5 }}>
            <FormControl fullWidth>
              <InputLabel id="namespace-select-label" error={namespaceError}>Select Project</InputLabel>
              <Select
                labelId="namespace-select-label"
                id="namespace-select"
                value={getSelectedNamespace()}
                label="Select Project"
                onChange={handleSelectedProjectChange}
                error={namespaceError}
              >
                {
                  lookupResponse.projectNamespaces.map((el, index) => (
                    <MenuItem key={index} value={el}>
                      {el}
                    </MenuItem>
                  ))
                }
              </Select>
              {namespaceError && <FormHelperText error style={{ margin: "5px 0 0" }}>Project namespace is required.</FormHelperText>}
            </FormControl>
          </Box>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Box mt={5}>
            <CustomTextField
              label="Enter Service Account Token"
              value={token.value}
              variant="outlined"
              onChange={(e) => {
                token.setValue(e.target.value);
                props.removeError(null);
              }}
              placeholder="Enter Service Account Token"
              fullWidth
              required
              type="password"
              error={props.error}
              helperText={props.error}
            />
          </Box>
          <Box mt={5}>
            {kubeConfigFile?.value && !kubeConfigFile?.errorMsg ? (
              <FileInput
                container
                alignItems="center"
                px={2}
                justifyContent="space-between"
              >
                <Typography>{kubeConfigFile?.value?.name}</Typography>
                <IconButton
                  onClick={() => {
                    kubeConfigFile.setValue(null);
                    kubeConfigFile.setErrorMsg("");
                    token.setValue("");
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </FileInput>
            ) : (
              <Grid container direction="column" justifyContent="space-around">
                <FileInput
                  onDrop={readKubeConfig}
                  onDragOver={(e) => e.preventDefault()}
                  container
                  px={1.5}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <input
                    type="file"
                    onChange={readKubeConfig}
                    ref={fileInput}
                    style={{ display: "none" }}
                  />
                  <Typography>
                    Drop your KubeConfig file in the box or{"  "}
                  </Typography>
                  <FileInputText
                    onClick={(e) => fileInput.current.click()}
                    sx={{ color: "blue", mx: 0.5 }}
                  >
                    Click here
                  </FileInputText>
                  <Typography>to upload</Typography>
                </FileInput>
                {kubeConfigFile.errorMsg && (
                  <Typography color="error">{kubeConfigFile.errorMsg}</Typography>
                )}
              </Grid>
            )}
          </Box>
        </React.Fragment>
      )
    }
  }

  const handleLogin = () => {
    if (lookupResponse !== null && Object.keys(lookupResponse).length > 0) {
      if (selectedNamespace !== '') {
        props.loginUser({
          token: lookupResponse.token,
          kubeApiServerEndpoint: lookupResponse.kubeApiServerEndpoint,
          namespace: selectedNamespace
        });
      } else {
        setNamespaceError(true);
      }
    } else {
      props.loginUser({
        token: token.value,
      });
    }
  };

  const handleOidcRedirect = () => {
    window.location.href = idpAuthUrl
  };

  const readKubeConfig = async (e) => {
    try {
      if (kubeConfigFile.errorMsg) kubeConfigFile.setErrorMsg("");
      if (props.error) props.removeError(null);
      kubeConfigFile.setValue(
        e?.target?.files?.[0] || e?.dataTransfer?.files?.[0]
      );
      e.preventDefault();
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const text = e.target.result;
          const config = YAML.parse(text);
          const _token = config?.users[0].user.token;
          if (!_token) throw "Invalid File";
          token.setValue(_token);
        } catch (e) {
          kubeConfigFile.setErrorMsg("Invalid File");
        }
      };
      reader.readAsText(e?.target?.files?.[0] || e?.dataTransfer?.files?.[0]);
    } catch (e) {
      kubeConfigFile.setErrorMsg("Invalid File");
    }
  };

  const loginForm = (
    <>
      <Box mt={2}>
        <p style={textStyle}>You build apps.</p>
        <p style={textStyle}>We simplify your journey.</p>
      </Box>
      {
        lookupResponse !== null ?
          conditionalInputFields()
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "2rem 0" }}>
            <CircularProgress />
          </Box>
      }
      <Box mt={3}>
        <Button
          ref={loginRef}
          onClick={handleLogin}
          style={{ backgroundColor: "#2868F3", height: "50px" }}
          color="primary"
          variant="contained"
          fullWidth
          disabled={lookupResponse === null}
        >
          Sign In
        </Button>
      </Box>
    </>
  );
  const idpLoginForm = (
    <>
      <Box mt={2}>
        <p style={textStyle}>You build apps.</p>
        <p style={textStyle}>We simplify your journey.</p>
      </Box>
      {
        lookupResponse !== null ?
          conditionalInputFields()
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "2rem 0" }}>
            <CircularProgress />
          </Box>
      }

      {/* <Box mt={4}>
        <CustomTextField
          error={props.error ? true : false}
          helperText={props.error}
          id="password"
          name="password"
          label="Enter Namespace"
          value={namespace}
          onChange={(e) => {
            handleRemoveError();
            setNamespace(e.target.value);
          }}
          placeholder="Enter Namespace"
          variant="outlined"
          InputLabelProps={{ style: { fontFamily: "Bai Jamjuree" } }}
          fullWidth
          required
        />
      </Box> */}
      <Box mt={3}>
        <Button
          ref={loginRef}
          onClick={handleLogin}
          style={{ backgroundColor: "#2868F3", height: "50px" }}
          color="primary"
          data-testid='sign-in-btn'
          variant="contained"
          fullWidth
          disabled={lookupResponse === null}
        >
          Sign In
        </Button>
        <br/>
        <div style={{display:"flex",flexDirection:"row", color:"black", margin:"10px"}}><hr style={{width:"35%",background:"grey"}} /> <b style={{color:"grey"}}>or</b> <hr style={{width:"35%",background:"grey"}} /></div>
        
        <Button
          ref={loginRef}
          onClick={handleOidcRedirect}
          style={{ backgroundColor: "#2868F3", height: "50px" }}
          color="primary"
          variant="contained"
          fullWidth
          disabled={lookupResponse === null}
        >
          Sign In With {idpName}
        </Button>
      </Box>
      {/* <Box mt={4}>
        <Typography style={{ fontFamily: "Bai Jamjuree" }}>
          Not registered yet? Create an account
        </Typography>
      </Box> */}
    </>
  );
  return (
    <>
      <div style={mainGridStyle}>
        <Grid>
          <Box>
            <Paper elevation={9} style={paperStyle}>
              <Box pl={2} pr={2}>
                <Grid>
                  <Box mt={4}>
                    <img alt="Avesha Logo" src={AveshaIcon}></img>
                  </Box>
                  <Box mt={3}>
                    <p style={headStyle}>KubeSlice</p>
                  </Box>
                  {idpLogin?idpLoginForm:loginForm}
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </div>
    </>
  );
};

const mapStoreToProps = (store) => {
  return {
    changePassword: store.SessionReducer.changePassword,
    error: store.ErrorReducer.errorMessage,
  };
};

export default connect(mapStoreToProps, {
  loginUser,
  authLookupProjects,
  authLookupOidc,
  changeUserPassword,
  removeError,
})(LoginScreen);
