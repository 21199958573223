import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const AlertDialog = ({ handleClose, open = false,setIsChanged }) => {

    return (
        <Dialog
            sx={{
                maxWidth: '528px',
                margin:'0px auto',
                minHeight:'252px'
            }}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='modal-title' id="alert-dialog-title">
                { <>You have unsaved changes, are you sure you want to leave? </>}
            </DialogTitle>
            <DialogActions>
                <Button className='cancel-btn' onClick={() => handleClose(false)}>No</Button>
                <Button className='submit-btn' onClick={() => {handleClose(true)
                                                              setIsChanged(false) } } autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;