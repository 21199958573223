import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  clearCurrentSliceRoleList,
  getRoles,
  getSliceRoleList,
  sliceRoleLists,
} from '../../../actions/RbacActions';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import NoSlice from '../../../assets/images/no-slice-namespace.png';
import { ReactComponent as NotificationsIcon } from '../../../assets/navbarIcons/notifications.svg';
import TopbarSpinner from '../../Dashboard/TopbarSpinner';
import Row from './Row';
import './index.css';
import GeneralLayout from '../../Commons/GeneralLayout';

const ManageRbac = () => {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowCount, setRowCount] = React.useState(0);

  const sliceRoleList = useSelector((state) => state.RbacReducer.sliceRoleList);
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );

  // console.log(sliceRoleLists);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const searchSlice = (event) => {
    setSearchText(event.target.value);
    // setSearch(false);
  };

  useEffect(() => {
    dispatch(getSliceRoleList());
    dispatch(getRoles());
    dispatch(clearCurrentSliceRoleList());
    dispatch(getRegisteredClusters());
  }, [dispatch]);

  useEffect(() => {
    let itemArr = [];
    let finalData = [];
    let RoleListData = [];
    if (sliceRoleLists && sliceRoleLists.data) {
      sliceRoleLists.data.map((name) => {
        RoleListData.push(name.name);
      });
    } else {
      return [];
    }
    const RoleListDataStructured = sliceRoleList.filter((item) =>
      RoleListData.includes(item?.metadata?.name)
    );
    const filteredData = sliceRoleList.filter(
      (item) =>
        item?.spec?.clusters &&
        item?.spec?.namespaceIsolationProfile?.applicationNamespaces
    );
    const mergedArr = [...RoleListDataStructured, ...filteredData];

    let uniqueArr = mergedArr.filter(
      //removing duplicate array
      (item, index) =>
        mergedArr.findIndex(
          (el) => JSON.stringify(el) === JSON.stringify(item)
        ) === index
    );
    finalData.push(uniqueArr);
    itemArr = uniqueArr;

    if (search && searchText !== '') {
      const filteredArr = uniqueArr.filter((item) =>
        item?.metadata?.name.includes(searchText)
      );
      itemArr = filteredArr;
    } else {
      itemArr = uniqueArr;
    }

    if (itemArr.length > 0) {
      setRowCount(itemArr.length);
      itemArr = itemArr.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setRows(itemArr);
    }
  }, [page, rowsPerPage, sliceRoleList, search, searchText]);

  const checked = (row) => {
    return row?.metadata?.name === currentSlice?.metadata?.name;
  };

  const handleRedirectToNewUislice = (redirect_uri) => {
    window.location.href = `/${redirect_uri}`;
  };

  return (
    <GeneralLayout description={'RBAC'}>
      <Paper elevation={3} className="rbac-table-paper">
        <div className="rbac-table-header">
          <Paper
            component="form"
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 40,
              borderRadius: '0 5px 5px 0',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                margin: '24.5px 0px 24.5px 16px',
              }}
            >
              Slices
            </Typography>
            <div
              style={{
                display: 'flex',
                height: '40px',
                overflow: 'hidden',
                border: '1px solid #B6B8D6',
                margin: '24.5px 0px 24.5px 16px',
                borderRadius: '4px',
                width: '308px',
                padding: '1px 0px 0px 16px;',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={searchSlice}
                onKeyPress={() => {
                  setSearch(true);
                }}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                className="search-icon"
                onClick={() => setSearch(true)}
                type="button"
              >
                <SearchIcon />
              </IconButton>
            </div>
          </Paper>
          <Button
            disabled={Object.keys(currentSlice).length === 0}
            onClick={() => history.push('/manage-rbacs/add/1')}
            className="btn-rbac"
            variant="contained"
          >
            {currentSlice?.metadata?.bindings ? 'Edit Roles' : 'Assign Roles'}
          </Button>
        </div>
        <TableContainer className="rbac-table-container">
          <Table aria-label="collapsible table">
            <TableHead className="table-head">
              <TableRow>
                {/* <TableCell className="txt-bold ft-12 padding-cell-header " /> */}
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    paddingRight: '0px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                ></TableCell>

                <TableCell
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    paddingRight: '0px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '27%',
                  }}
                >
                  SLICE NAME
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    paddingRight: '0px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '20%',
                  }}
                >
                  TYPE
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    paddingRight: '0px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '25%',
                  }}
                >
                  DESCRIPTION
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    paddingRight: '0px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                    width: '22%',
                  }}
                >
                  ROLES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row) => (
                  <Row
                    key={row?.metadata?.name}
                    step={0}
                    slice={row}
                    checked={checked(row)}
                  />
                ))
              ) : (
                <>
                  <TableRow style={{ textAlign: 'center' }}>
                    <TableCell colSpan={6}>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={NoSlice}
                          width="150"
                          height="150"
                          alt="No slice for namespace"
                        />
                      </div>
                      {/* <Typography style={{ textAlign: 'center' }}>
    {`Create Slices under Slices and `}
    </Typography> */}
                      <Typography
                        className="no-slice-txt-isolated"
                        variant="subtitle2"
                        component="div"
                        style={{ textAlign: 'center' }}
                      >
                        {`Create Slices and onboard Namespaces before defining RBAC`}
                      </Typography>
                      <Box
                        sx={{
                          marginTop: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          style={{ width: 'auto' }}
                          onClick={() => handleRedirectToNewUislice('slices')}
                          className="btn"
                          variant="contained"
                        >
                          Go to Slices
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 0 ? (
          <div className="pagination">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <></>
        )}
      </Paper>
    </GeneralLayout>
  );
};

export default ManageRbac;
