import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  // setCurrentResourceQuota,
  setUpdatedClusterQuota,
} from '../../../../../actions/ResourceQuotaActions';
import { ReactComponent as CleanIcon } from '../../../../../assets/icons/Clean.svg';
import { ReactComponent as ClearActionIcon } from '../../../../../assets/icons/ClearAction.svg';
import {
  handleConversion,
  kiToMi,
  orderByEnforced,
} from '../../../../../utils/functions';
import AlertModal from '../../AlertModal';
import ConfirmClearModal from '../../ConfirmClearModal';
import ConfirmEnforceModal from '../../ConfirmEnforceModal';
import { ValueWithUsage } from '../../ValueWithUsage';
import '../index.css';
import DetailBlock from './DetailBlock';
import {
  DividendInputLimit,
  DivisorInputLimit,
  RegularInputLimit,
} from './components';
import { schema } from './utils';

const defaultRQValues = {
  ephemeralStorage: '',
  cpu: '',
  memory: '',
};

const emptyRqValues = {
  defaultLimitPerContainer: defaultRQValues,
  defaultRequestPerContainer: defaultRQValues,
  limit: {
    ...defaultRQValues,
    podCount: '',
  },
  request: defaultRQValues,
};

const IOSSwitch = styled((props) => (
  <Switch
    size="small"
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#14974C',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

// show alert when limit cross for each

const ManageResourceTable = ({
  currentResourceQuota,
  checkNextButton,
  isFinalize = false,
}) => {
  const {
    spec: {
      resourceQuota: { spec: { clusterQuota, sliceQuota } = {} } = {},
    } = {},
  } = currentResourceQuota;
  const { stepNo } = useParams();
  const dispatch = useDispatch();
  const [searchBy, setSearchBy] = useState('namespace');
  const [search, setSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [touched, setTouched] = useState({});

  const [errors, setErrors] = useState({});

  const [rowData, setRowData] = useState(() =>
    clusterQuota ? orderByEnforced(clusterQuota) : []
  );
  const [orderedClusters, setOrderedClusters] = useState(() =>
    clusterQuota ? orderByEnforced(clusterQuota) : []
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let itemArr = [];
    if (search && searchText !== '') {
      if (searchBy !== 'namespace') {
        orderedClusters.forEach((cluster) => {
          if (cluster.clusterName.includes(searchText)) {
            itemArr.push(cluster);
          }
        });
        setRowData(itemArr);
      } else {
        orderedClusters.forEach((cluster) => {
          cluster.namespaceQuota.forEach((item) => {
            if (item?.namespace.includes(searchText)) {
              const nameobj = {
                clusterName: cluster.clusterName,
                namespaceQuota: [
                  {
                    enforceQuota: item.enforceQuota,
                    namespace: item.namespace,
                    resources: item.resources,
                  },
                ],
              };
              itemArr.push(nameobj);
            }
          });
        });
        setRowData(itemArr);
      }
    }
  }, [orderedClusters, search, searchBy, searchText]);

  const searchNamespace = (event) => {
    const { value } = event.target;
    setSearchText(value);
    // if (!value) {
    //   setRowData(orderedClusters);
    // }
  };

  useEffect(() => {
    if (stepNo == 2) {
      checkResourceCount();
      handleFocusOut();
      // when the user comes to step two, we will take cluste rquota from current resource quota 
      // and populated it inside edited cluster quota otherwise if the use doesn't edit anything edited rq from previous slice will be populated
      dispatch(setUpdatedClusterQuota(clusterQuota))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [openEnforcementModal, setOpenEnforcementModal] = useState(false);
  const [openNSRQClearModal, setOpenNSRQClearModal] = useState(false);
  const [showClearAllModal, setShowClearAllModal] = useState(false);
  const [delRowIndex, setDelRowIndex] = useState(null);
  const [delClusterIndex, setDelClusterIndex] = useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const { resources } = sliceQuota;
  const {
    limit: slice_limit,
    request: slice_request,
    defaultRequestPerContainer: slice_request_per_container,
  } = resources;

  // disabling inputs if not entered in slice level
  // limits
  const cpu_limit_check =
    slice_limit?.cpu !== '' &&
    slice_limit?.cpu !== '0' &&
    slice_limit?.cpu !== 0 &&
    slice_limit?.cpu !== 'm' &&
    slice_limit?.cpu !== '0m';

  const memory_limit_check =
    slice_limit?.memory !== '' &&
    slice_limit?.memory !== 0 &&
    slice_limit?.memory !== '0' &&
    slice_limit?.memory !== 'Mi' &&
    slice_limit?.memory !== '0Mi';

  const storage_limit_check =
    slice_limit?.ephemeralStorage !== '' &&
    slice_limit?.ephemeralStorage !== 0 &&
    slice_limit?.ephemeralStorage !== '0' &&
    slice_limit?.ephemeralStorage !== 'Mi' &&
    slice_limit?.ephemeralStorage !== '0Mi';

  const pod_count_check =
    slice_limit?.podCount &&
    slice_limit?.podCount !== '0' &&
    slice_limit?.podCount !== '' &&
    slice_limit?.podCount !== 0;

  // request
  const cpu_request_check =
    slice_request?.cpu !== '' &&
    slice_request?.cpu !== 0 &&
    slice_request?.cpu !== '0' &&
    slice_request?.cpu !== 'm' &&
    slice_request?.cpu !== '0m';

  const memory_request_check =
    slice_request?.memory !== '' &&
    slice_request?.memory !== 'Mi' &&
    slice_request?.memory !== '0Mi' &&
    slice_request?.memory !== '0' &&
    slice_request?.memory !== 0;

  const storage_request_check =
    slice_request?.ephemeralStorage !== '' &&
    slice_request?.ephemeralStorage !== 'Mi' &&
    slice_request?.ephemeralStorage !== '0Mi' &&
    slice_request?.ephemeralStorage !== '0' &&
    slice_request?.ephemeralStorage !== 0;

  const checkResourceCount = (mapData = rowData) => {
    const values = mapData
      .find((cluster) => cluster.clusterName === editClusterIndex)
      ?.namespaceQuota.find(
        (namespace) => namespace.namespace === namespaceToEdit
      )?.resources;

    if (!values) {
      return;
    }
    const {
      limit: { ephemeralStorage, cpu: cpuLimit, memory: memoryLimit, podCount },
      request: {
        ephemeralStorage: ephemeralStorageRequest,
        cpu: cpuRequest,
        memory: memoryRequest,
      },
      defaultRequestPerContainer: {
        ephemeralStorage: containerEphemeralStorageRequest,
        cpu: containerCpuRequest,
        memory: containerMemoryRequest,
      } = defaultRQValues,
      defaultLimitPerContainer: {
        ephemeralStorage: containerEphemeralStorageLimit,
        cpu: containerCpuLimit,
        memory: containerMemoryLimit,
      } = defaultRQValues,
    } = values;

    const pod_count = parseInt(podCount) || '';
    const cpu_limit = handleConversion('cpu', cpuLimit) || '';
    const slice_container_cpu_request =
      handleConversion('cpu', slice_request_per_container.cpu) || '';
    const slice_container_memory_request =
      handleConversion('memory', slice_request_per_container.memory) || '';

    const slice_container_storage_request =
      handleConversion(
        'ephemeralStorage',
        slice_request_per_container.ephemeralStorage
      ) || '';

    const memory_limit = handleConversion('memory', memoryLimit) || '';
    const storage_limit =
      handleConversion('ephemeralStorage', ephemeralStorage) || '';
    const cpu_request = handleConversion('cpu', cpuRequest) || '';
    const memory_request = handleConversion('memory', memoryRequest) || '';
    const storage_request =
      handleConversion('ephemeralStorage', ephemeralStorageRequest) || '';

    const container_cpu_request =
      handleConversion('cpu', containerCpuRequest) || '';
    const container_memory_request =
      handleConversion('memory', containerMemoryRequest) || '';
    const container_cpu_limit =
      handleConversion('cpu', containerCpuLimit) || '';
    const container_memory_limit =
      handleConversion('memory', containerMemoryLimit) || '';

    const container_storage_limit =
      handleConversion('ephemeralStorage', containerEphemeralStorageLimit) ||
      '';

    const container_storage_request =
      handleConversion('ephemeralStorage', containerEphemeralStorageRequest) ||
      '';

    schema
      .validate(
        {
          slice_container_cpu_request,
          slice_container_memory_request,
          slice_container_storage_request,
          pod_count: pod_count ? pod_count : '',
          cpu_limit,
          container_cpu_limit,
          cpu_request,
          container_cpu_request,
          memory_limit,
          container_memory_limit,
          memory_request,
          container_memory_request,
          storage_limit,
          container_storage_limit,
          storage_request,
          container_storage_request,
        },
        {
          abortEarly: false,
        }
      )
      .then(() => {
        setErrors({});
        checkNextButton(false);
        // check if namespace limits exceeds slice limits
      })
      .catch((error) => {
        const { inner } = error;
        if (inner) {
          let local_errors = {};
          inner.forEach((error) => {
            local_errors[error.path] = error.message;
          });
          setErrors(local_errors);
        }
        checkNextButton(true);
      });

    let count = 0;
    let totalPodCount = 0;
    let totalCpuCount = 0;
    let totalMemoryCount = 0;
    let cpu =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .cpu;
    // let maxCpuCount = handleConversion("cpu", cpu);
    let memory =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .memory;
    let maxMemoryCount = handleConversion('memory', memory);
    let negativeCheck = false;
    // checkNextButton(false);

    if (!negativeCheck) {
      if (
        count > 0 ||
        totalPodCount >
        currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources
          .limit.podCount ||
        totalCpuCount > cpu ||
        totalMemoryCount > Number(maxMemoryCount)
      ) {
        checkNextButton(true);
      } else {
        checkNextButton(false);
      }
    } else {
      checkNextButton(true);
    }
  };

  const handleFocusOut = (event) => {
    if (event && event.currentTarget) {
      setTouched((oldTouched) => ({
        ...oldTouched,
        [event.currentTarget?.name]: true,
      }));
    }
    let totalPodCount = 0;

    let total_cpu_limit = 0;
    let total_cpu_request = 0;
    let total_container_cpu_request = 0;

    let total_memory_limit = 0;
    let total_container_memory_request = 0;

    const maxPodCount =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .podCount ?? 0;

    const storage =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .ephemeralStorage;
    const storageRequest =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.request
        .ephemeralStorage;

    let total_memory_request = 0;
    let total_storage_request = 0;
    let total_container_storage_request = 0;
    let total_storage_limit = 0;

    let cpu =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .cpu;
    let maxCpuCount = handleConversion('cpu', cpu);
    let memory =
      currentResourceQuota.spec.resourceQuota.spec.sliceQuota.resources.limit
        .memory;
    let maxMemoryCount = handleConversion('memory', memory);
    const maxStorageLimit = handleConversion('ephemeralStorage', storage);
    const maxStorageRequest = handleConversion(
      'ephemeralStorage',
      storageRequest
    );

    let { resources } = currentResourceQuota.spec.resourceQuota.spec.sliceQuota;

    const {
      request: slice_request,
      defaultRequestPerContainer: slice_request_per_container,
    } = resources;

    const maxCpuRequest = Number(handleConversion('cpu', slice_request.cpu));
    const maxMemoryRequest = Number(
      handleConversion('memory', slice_request.memory)
    );

    rowData.forEach((cluster) => {
      cluster.namespaceQuota.forEach((item) => {
        let { cpu, memory, podCount } = item.resources.limit;
        let {
          request,
          limit,
          defaultRequestPerContainer = {},
        } = item.resources;

        // if (item.enforceQuota) {
        //   if (cpu) {
        //     totalCpuCount = totalCpuCount + cpu;
        //   }

        //   if (memory) {
        //     totalMemoryCount = totalMemoryCount + memory;
        //   }

        //   if (podCount) {
        //     totalPodCount = totalPodCount + podCount;
        //   }
        // }

        // make sure if enforced is needed
        const cpu_request = Number.parseInt(
          handleConversion('cpu', request.cpu)
        );

        const cpu_limit = Number.parseInt(handleConversion('cpu', limit.cpu));
        const memory_limit = Number.parseInt(
          handleConversion('memory', limit.memory)
        );
        const memory_request = Number.parseInt(
          handleConversion('memory', request.memory)
        );

        const container_memory_request = Number.parseInt(
          handleConversion('memory', defaultRequestPerContainer.memory)
        );

        const container_cpu_request = Number.parseInt(
          handleConversion('cpu', defaultRequestPerContainer.cpu)
        );

        const storage_limit = parseInt(limit.ephemeralStorage);
        const storage_request = parseInt(request.ephemeralStorage);
        const container_storage_request = parseInt(
          defaultRequestPerContainer.ephemeralStorage
        );

        totalPodCount = podCount ? totalPodCount + podCount : totalPodCount;

        total_cpu_request = cpu_request
          ? total_cpu_request + cpu_request
          : total_cpu_request;

        total_cpu_limit = cpu_limit
          ? total_cpu_limit + cpu_limit
          : total_cpu_limit;

        // total_container_cpu_limit = container_cpu_limit
        //   ? total_container_cpu_limit + container_cpu_limit
        //   : total_container_cpu_limit;

        total_container_cpu_request = container_cpu_request
          ? total_container_cpu_request + container_cpu_request
          : total_container_cpu_request;

        // memory
        total_memory_limit = memory_limit
          ? total_memory_limit + memory_limit
          : total_memory_limit;

        total_memory_request = memory_request
          ? total_memory_request + memory_request
          : total_memory_request;

        // total_container_memory_limit = container_memory_limit
        //   ? container_memory_limit + container_memory_limit
        //   : total_container_memory_limit;

        total_container_memory_request = container_memory_request
          ? total_container_memory_request + container_memory_request
          : total_container_memory_request;

        // total_container_cpu_limit = container_cpu_limit
        //   ? total_container_cpu_limit + container_cpu_limit
        //   : total_container_cpu_limit;

        total_storage_limit = storage_limit
          ? total_storage_limit + storage_limit
          : total_storage_limit;

        total_storage_request = storage_request
          ? total_storage_request + storage_request
          : total_storage_request;

        total_container_storage_request = container_storage_request
          ? total_container_storage_request + container_storage_request
          : total_container_storage_request;
      });
    });

    if (
      (pod_count_check && totalPodCount > maxPodCount) ||
      (cpu_limit_check && total_cpu_limit > Number(maxCpuCount)) ||
      (cpu_request_check && total_cpu_request > maxCpuRequest) ||
      (memory_request_check && total_memory_request > maxMemoryRequest) ||
      (memory_limit_check && total_memory_limit > Number(maxMemoryCount)) ||
      (storage_request_check && total_storage_request > maxStorageRequest) ||
      (storage_limit_check && total_storage_limit > maxStorageLimit)
    ) {
      setOpenAlertModal(true);
      checkNextButton(true); // disable next btn
    } else {
      setOpenAlertModal(false);
    }
  };

  const handleSwitch = (cluster, namespaceName) => {
    const nsQuota = cluster.namespaceQuota.find(
      (namespace) => namespace.namespace === namespaceName
    );

    if (!nsQuota?.enforceQuota) {
      const newClusterQuota = rowData.map((quota) => {
        if (_.isEqual(quota, cluster)) {
          const namespaceQuota = cluster?.namespaceQuota?.map((nsQuota) => {
            if (nsQuota.namespace === namespaceName) {
              return { ...nsQuota, enforceQuota: true };
            }
            return nsQuota;
          });
          return { ...cluster, namespaceQuota: namespaceQuota };
        }
        return quota;
      });

      setRowData(newClusterQuota);
      setOrderedClusters(newClusterQuota);
      dispatch(setUpdatedClusterQuota(newClusterQuota));
      checkResourceCount(newClusterQuota);
    } else {
      setDelRowIndex(cluster.clusterName);
      setDelClusterIndex(namespaceName);
      setOpenEnforcementModal(true);
    }
  };

  const clearValues = (clusterName, namespaceName) => {
    setDelRowIndex(clusterName);
    setDelClusterIndex(namespaceName);
    setOpenNSRQClearModal(true);
  };

  const [namespaceToEdit, setNamespaceToEdit] = useState(null);
  const [editClusterIndex, setEditClusterIndex] = useState(null);

  const handleEditCell = (e, clusterName, namespaceName) => {
    if (Object.keys(errors).length > 0) {
      e.stopPropagation();
      return;
    }
    e.stopPropagation();
    setNamespaceToEdit(namespaceName);
    setEditClusterIndex(clusterName);
  };

  const updateValue = (local_data, mainKey, navKey, value) => {
    return local_data.map((cluster) => {
      if (cluster.clusterName === editClusterIndex) {
        return {
          ...cluster,
          namespaceQuota: cluster.namespaceQuota.map((namespace) => {
            if (namespace.namespace === namespaceToEdit) {
              const local_resources = {
                ...namespace.resources,
                [mainKey]: {
                  ...namespace.resources[mainKey],
                  [navKey]: value,
                },
              };
              return {
                ...namespace,
                resources: local_resources,
              };
            }
            return namespace;
          }),
        };
      }
      return cluster;
    });
  };

  const handleChange = (e, mainKey, navKey) => {
    let local_data = [...rowData];
    const value = e.target.valueAsNumber;

    if (value) {
      // sometimes the RQ element may not contain defaultLimitPerContainer, or defaultRequestPerContainer
      // better if we move this logic while setting current RQ in list apge but for now I'm keeping this here(this is
      // wasteful as it's checking on each change)
      const resources = local_data
        .find((cluster) => cluster.clusterName === editClusterIndex)
        ?.namespaceQuota.find(
          (namespace) => namespace.namespace === namespaceToEdit
        )?.resources;

      if (!resources[mainKey]) {
        local_data = updateValue(local_data, mainKey, navKey, 0);
      }

      if (navKey === 'cpu') {
        local_data = updateValue(
          local_data,
          mainKey,
          navKey,
          Number.parseInt(e.target.value) + 'm'
        );
      } else if (navKey === 'memory' || navKey === 'ephemeralStorage') {
        local_data = updateValue(
          local_data,
          mainKey,
          navKey,
          Number.parseInt(e.target.value) + 'Mi'
        );
      } else {
        local_data = updateValue(local_data, mainKey, navKey, value);
      }
    } else {
      local_data = updateValue(local_data, mainKey, navKey, '');
    }
    checkResourceCount(local_data);
    setRowData(local_data);
    dispatch(setUpdatedClusterQuota(local_data));
  };

  const handleEnforceClose = (continueMonitoring = false) => {
    const updatedRowData = rowData.map((cluster) => {
      if (cluster.clusterName === delRowIndex) {
        return {
          ...cluster,
          namespaceQuota: cluster.namespaceQuota.map((nsQuota) => {
            if (nsQuota.namespace === delClusterIndex) {
              if (!continueMonitoring) {
                return {
                  ...nsQuota,
                  enforceQuota: false,
                  resources: emptyRqValues,
                };
              }
              return {
                ...nsQuota,
                enforceQuota: false,
              };
            }
            return nsQuota;
          }),
        };
      }
      return cluster;
    });

    setRowData(updatedRowData);
    setOrderedClusters(updatedRowData);
    dispatch(setUpdatedClusterQuota(updatedRowData));
    setDelRowIndex(null);
    setDelClusterIndex(null);
    setOpenEnforcementModal(false);
    checkResourceCount();
  };

  const handleEnforceCancel = () => {
    setDelRowIndex(null);
    setDelClusterIndex(null);
    setOpenEnforcementModal(false);
  };

  const handleClearModalClose = (del = false) => {
    if (del === true) {
      const updatedRowData = rowData.map((cluster) => {
        if (cluster.clusterName === delRowIndex) {
          return {
            ...cluster,
            namespaceQuota: cluster.namespaceQuota.map((nsQuota) => {
              if (nsQuota.namespace === delClusterIndex) {
                return {
                  ...nsQuota,
                  enforceQuota: false,
                  resources: {
                    ...nsQuota.resources,
                    ...emptyRqValues
                  },
                };
              }
              return nsQuota;
            }),
          };
        }
        return cluster;
      });

      setRowData(updatedRowData);
      setOrderedClusters(updatedRowData);
      dispatch(setUpdatedClusterQuota(updatedRowData));
      setDelRowIndex(null);
      setDelClusterIndex(null);
      setOpenNSRQClearModal(false);
      checkResourceCount();
    } else {
      setDelRowIndex(null);
      setDelClusterIndex(null);
      setOpenNSRQClearModal(false);
    }
  };

  const handleClearAll = (clearAll) => {
    if (clearAll) {
      const updatedRowData = orderedClusters.map((quota) => ({
        ...quota,
        namespaceQuota: quota.namespaceQuota.map((nsQuota) => ({
          ...nsQuota,
          enforceQuota: false,
          resources: {
            ...nsQuota.resources,
            ...emptyRqValues,
          },
        })),
      }));
      setRowData(updatedRowData);
      setOrderedClusters(updatedRowData);
      dispatch(setUpdatedClusterQuota(updatedRowData));
      checkResourceCount();
    }

    setShowClearAllModal(false);
  };

  const handleAlertClose = () => {
    setOpenAlertModal(false);
  };

  const handleClickAway = (e) => {
    if (Object.keys(errors).length > 0) {
      e.stopPropagation();
      return;
    }
    setEditClusterIndex(null);
    setNamespaceToEdit(null);
  };

  const stringifyUsageValue = (value) => {
    return value ? ` ${value} / ` : ' NA / ';
  };

  const rowsFiltered = rowData.filter((cluster) => {
    if (searchBy === 'cluster') {
      return cluster.clusterName.includes(searchText);
    }
    return cluster;
  });

  const rowCount = rowsFiltered.flatMap((cluster) => {
    if (stepNo == '2') {
      return cluster.namespaceQuota;
    }
    return cluster.namespaceQuota.filter((nsQuota) => nsQuota.enforceQuota);
  }).length;

  return (
    <Grid item>
      <Paper
        elevation={0}
        sx={{
          my: 2,
          border: '1px solid #B6B8D6',
          borderRadius: '4px',
        }}
      >
        {openEnforcementModal && (
          <ConfirmEnforceModal
            open
            handleClose={handleEnforceClose}
            title="You are turning off enforcement for this namespace."
            content="Do you want to continue monitoring this namespace?"
            handleCancel={handleEnforceCancel}
          />
        )}

        {openNSRQClearModal && (
          <ConfirmClearModal
            open
            handleClose={handleClearModalClose}
            content="Do you want to delete the quotas for this namespace?"
          />
        )}

        {showClearAllModal && (
          <ConfirmClearModal
            open
            handleClose={handleClearAll}
            content="Do you want to clear the quotas for all the namespaces in this slice?"
          />
        )}
        <AlertModal open={openAlertModal} handleClose={handleAlertClose} />

        <Paper
          elevation={0}
          sx={{
            pt: '20px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 3,
              paddingBottom: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
              }}
            >
              <Typography className="table-title" component="div">
                Manage Resources
              </Typography>
              <div
                style={{
                  display: 'flex',
                  height: '40px',
                  overflow: 'hidden',
                  border: '1px solid #B6B8D6',
                  marginRight: '24px',
                  borderRadius: '9px',
                }}
              >
                <Paper
                  component="div"
                  sx={{
                    backgroundColor: '#E6E8F3',
                    borderWidth: 0,
                    borderColor: 'transparent',
                  }}
                  style={{ borderWidth: 0 }}
                >
                  <Select
                    style={{
                      height: '120%',
                      borderWidth: 0,
                      border: 'none',
                      marginTop: '-4px',
                      marginLeft: '-2px',
                    }}
                    value={searchBy}
                    onChange={(e) => {
                      setSearchBy(e.target.value);
                    }}
                    renderValue={(value) =>
                      value.charAt(0).toUpperCase() + value.slice(1)
                    }
                  >
                    <MenuItem value="namespace" style={{ borderWidth: 0 }}>
                      Search by Namespace
                    </MenuItem>
                    <MenuItem value="cluster" style={{ borderWidth: 0 }}>
                      Search by Cluster name
                    </MenuItem>
                  </Select>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 250,
                    borderRadius: '0 5px 5px 0',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    onChange={searchNamespace}
                    value={searchText}
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                  <IconButton
                    className="search-icon"
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </Box>
            {stepNo == 2 && (
              <Button
                onClick={() => setShowClearAllModal(true)}
                className="btn-outlined"
                variant="outlined"
                sx={{ gap: '4px', padding: '10px 19px' }}
              >
                <CleanIcon />
                Clear All
              </Button>
            )}
          </Box>

          <Divider />
          <TableContainer>
            <Table aria-label="collapsible table" data-testid="namespace-table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      py: '15px',
                      px: 3,
                      pr: '20px',
                      lineHeight: '15px',
                    }}
                  >
                    {isFinalize ? 'ENFORCED NAMESPACES' : 'ENFORCE'}
                  </TableCell>
                  {!isFinalize && (
                    <TableCell
                      className="txt-bold ft-12"
                      sx={{
                        py: '15px',
                        px: 3,
                        pr: '20px',
                        lineHeight: '15px',
                      }}
                    >
                      NAMESPACES
                    </TableCell>
                  )}
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      py: '15px',
                      px: 3,
                      pr: '0px',
                      lineHeight: '15px',
                    }}
                  >
                    NO.OF PODS
                  </TableCell>
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      py: '15px',
                      px: 3,
                      pr: '20px',
                      maxWidth: '200px',
                      lineHeight: '15px',
                    }}
                  >
                    <Box lineHeight='15px'>CPU (Mi)</Box>
                  </TableCell>
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      py: '15px',
                      maxWidth: '200px',
                      px: 3,
                      pr: '20px',
                      lineHeight: '15px',
                    }}
                  >
                    <Box lineHeight='15px'>MEMORY (Mi)</Box>
                  </TableCell>
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      py: '15px',
                      maxWidth: '200px',
                      px: 3,
                      pr: '20px',
                      lineHeight: '15px',
                    }}
                  >
                    <Box lineHeight='15px'>EPHEMERAL STORAGE (Mi)</Box>
                  </TableCell>
                  {stepNo === '2' && (
                    <TableCell
                      // className='txt-bold ft-12'
                      sx={{
                        minWidth: '0px',
                        lineHeight: '15px',
                      }}
                    />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsFiltered.map((cluster) => {
                  // rowIndex should be named clusterIdx because each row is a cluster
                  return cluster.namespaceQuota
                    .filter((namespace) => {
                      if (searchBy === 'namespace') {
                        return namespace.namespace.includes(searchText);
                      }
                      return namespace;
                    })
                    .map((item, namespaceIndex) => {
                      // the clusterIndex should be namespaceQuotaIdx
                      const {
                        enforceQuota,
                        resources: {
                          userdata: ns_userdata,
                          limit: { podCount, ephemeralStorage, cpu, memory },
                          request: {
                            ephemeralStorage: ephemeralStorageRequest,
                            cpu: cpu_request,
                            memory: memory_request,
                          },
                          defaultRequestPerContainer: {
                            ephemeralStorage: containerEphemeralStorageRequest,
                            cpu: copntainer_cpu_request,
                            memory: container_memory_request,
                          } = defaultRQValues,
                          defaultLimitPerContainer: {
                            ephemeralStorage: containerEphemeralLimitStorage,
                            cpu: container_cpu_limit,
                            memory: container_memory_limit,
                          } = defaultRQValues,
                        },
                      } = item;

                      const userdata = {
                        ...ns_userdata,
                        memory: kiToMi(ns_userdata?.memory),
                        memoryRequest: kiToMi(ns_userdata?.memoryRequest),
                        ephemeralStorage: kiToMi(ns_userdata?.ephemeralStorage),
                        ephemeralStorageRequest: kiToMi(
                          ns_userdata?.ephemeralStorageRequest
                        ),
                      };

                      const cpu_limit = handleConversion('cpu', cpu) || '';
                      const memory_limit =
                        handleConversion('memory', memory) || '';
                      const cpuRequest =
                        handleConversion('cpu', cpu_request) || '';
                      const memoryRequest =
                        handleConversion('memory', memory_request) || '';
                      const containerCpuRequest =
                        handleConversion('cpu', copntainer_cpu_request) || '';
                      const containerMemoryRequest =
                        handleConversion('memory', container_memory_request) ||
                        '';
                      const containerCpuLimit =
                        handleConversion('cpu', container_cpu_limit) || '';
                      const containerMemoryLimit =
                        handleConversion('memory', container_memory_limit) ||
                        '';

                      const storage_limit =
                        handleConversion(
                          'ephemeralStorage',
                          ephemeralStorage
                        ) || '';

                      const container_storage_limit =
                        handleConversion(
                          'ephemeralStorage',
                          containerEphemeralLimitStorage
                        ) || '';

                      const storage_request =
                        handleConversion(
                          'ephemeralStorage',
                          ephemeralStorageRequest
                        ) || '';

                      const container_storage_request =
                        handleConversion(
                          'ephemeralStorage',
                          containerEphemeralStorageRequest
                        ) || '';

                      const editable =
                        editClusterIndex === cluster.clusterName &&
                        namespaceToEdit === item.namespace;

                      return !isFinalize ? (
                        editable ? (
                          <ClickAwayListener
                            onClickAway={handleClickAway}
                            key={namespaceIndex}
                          >
                            <TableRow
                              sx={{
                                borderBottom: '1px solid #cbd5e1',
                              }}
                              data-testid="editable-row"
                            >
                              <TableCell
                                style={{ paddingLeft: '20px' }}
                                sx={{
                                  width: '40px',
                                  py: 1.5,
                                  px: 0.5,
                                  border: 'none',
                                }}
                              >
                                <FormControlLabel
                                  className="switch-style"
                                  control={
                                    <IOSSwitch
                                      sx={{ m: 1 }}
                                      onChange={() =>
                                        handleSwitch(cluster, item.namespace)
                                      }
                                      checked={enforceQuota}
                                    />
                                  } // clusterIndex sent here is actually resourceQuotaIndex
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                onClick={(e) =>
                                  handleEditCell(
                                    e,
                                    cluster.clusterName,
                                    item.namespace,
                                    enforceQuota
                                  )
                                }
                                sx={{
                                  py: 2.5,
                                  px: 0.5,
                                  paddingLeft: "24px",
                                  border: 'none',
                                }}
                              >
                                <Typography
                                  style={{ fontSize: '14px' }}
                                  className="txt-bold"
                                >
                                  {item.namespace}
                                </Typography>
                                <Typography className="ft-12">
                                  {cluster.clusterName}
                                </Typography>
                              </TableCell>
                              <TableCell
                                onClick={(e) => {
                                  handleEditCell(
                                    e,
                                    cluster.clusterName,
                                    item.namespace,
                                    enforceQuota
                                  );
                                }}
                                sx={{
                                  width: '132px',
                                  py: 1.5,
                                  px: 0.5,
                                  border: 'none',
                                  // boxSizing: "border-box",
                                }}
                              >
                                <RegularInputLimit
                                  error={
                                    errors.pod_count &&
                                    touched['pod_count'] &&
                                    (errors.pod_count ??
                                      'Please set the quota at the slice level first.')
                                  }
                                  handleChange={(e) =>
                                    handleChange(e, 'limit', 'podCount')
                                  }
                                  handleBlur={handleFocusOut}
                                  dataTestId={'pod_count_input'}
                                  startText={stringifyUsageValue(
                                    userdata?.podCount
                                  )}
                                  value={podCount}
                                  name={'pod_count'}
                                />
                              </TableCell>
                              <TableCell
                                onClick={(e) =>
                                  handleEditCell(
                                    e,
                                    cluster.clusterName,
                                    item.namespace,
                                    enforceQuota
                                  )
                                }
                                sx={{
                                  py: 1.5,
                                  px: 0.5,
                                  border: 'none',
                                  width: '200px',
                                }}
                              >
                                <Stack>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <DivisorInputLimit
                                      gap={'30px'}
                                      error={
                                        errors.cpu_limit &&
                                        touched['cpu_limit'] &&
                                        (errors.cpu_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'cpu_limit_input'}
                                      handleChange={(e) =>
                                        handleChange(e, 'limit', 'cpu')
                                      }
                                      handleBlur={handleFocusOut}
                                      value={cpu_limit}
                                      label={'Limit '}
                                      name={'cpu_limit'}
                                      startText={stringifyUsageValue(
                                        userdata?.cpu
                                      )}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <DividendInputLimit
                                      gap={'24px'}
                                      error={
                                        errors.container_cpu_limit &&
                                        touched['container_cpu_limit'] &&
                                        (errors.container_cpu_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      label={'Limit -'}
                                      endText={'container'}
                                      name={'container_cpu_limit'}
                                      dataTestId={'container_cpu_limit_input'}
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultLimitPerContainer',
                                          'cpu'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={containerCpuLimit}
                                    />
                                  </Box>
                                  <Box>
                                    <DivisorInputLimit
                                      error={
                                        errors.cpu_request &&
                                        touched['cpu_request'] &&
                                        (errors.cpu_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'cpu_request_input'}
                                      handleChange={(e) =>
                                        handleChange(e, 'request', 'cpu')
                                      }
                                      handleBlur={handleFocusOut}
                                      value={cpuRequest}
                                      startText={stringifyUsageValue(
                                        userdata?.cpu_request
                                      )}
                                      name={'cpu_request'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                  <Box>
                                    <DividendInputLimit
                                      error={
                                        errors.container_cpu_request &&
                                        touched['container_cpu_request'] &&
                                        (errors.container_cpu_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'container_cpu_request_input'}
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultRequestPerContainer',
                                          'cpu'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={containerCpuRequest}
                                      endText={' container'}
                                      name={'container_cpu_request'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell
                                // memoryCell
                                onClick={(e) =>
                                  handleEditCell(
                                    e,
                                    cluster.clusterName,
                                    item.namespace,
                                    enforceQuota
                                  )
                                }
                                sx={{
                                  py: 1.5,
                                  px: 0.5,
                                  width: '200px',

                                  border: 'none',
                                }}
                              >
                                <Stack>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <DivisorInputLimit
                                      gap={'30px'}
                                      error={
                                        errors.memory_limit &&
                                        touched['memory_limit_input'] &&
                                        (errors.memory_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'memory_limit_input'}
                                      handleChange={(e) =>
                                        handleChange(e, 'limit', 'memory')
                                      }
                                      handleBlur={handleFocusOut}
                                      value={memory_limit}
                                      startText={stringifyUsageValue(
                                        userdata?.memory
                                      )}
                                      name={'memory_limit'}
                                      label={'Limit -'}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <DividendInputLimit
                                      gap={'24px'}
                                      error={
                                        errors.container_memory_limit &&
                                        touched['container_memory_limit'] &&
                                        (errors.container_memory_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={
                                        'container_memory_limit_input'
                                      }
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultLimitPerContainer',
                                          'memory'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={containerMemoryLimit}
                                      endText={' container'}
                                      name={'container_memory_limit'}
                                      label={'Limit -'}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <DivisorInputLimit
                                      error={
                                        errors.memory_request &&
                                        touched['memory_request'] &&
                                        (errors.memory_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'memory_request_input'}
                                      handleChange={(e) =>
                                        handleChange(e, 'request', 'memory')
                                      }
                                      handleBlur={handleFocusOut}
                                      value={memoryRequest}
                                      startText={stringifyUsageValue(
                                        userdata?.memory_request
                                      )}
                                      name={'memory_request'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <DividendInputLimit
                                      error={
                                        errors.container_memory_request &&
                                        touched[
                                        'container_memory_request_input'
                                        ] &&
                                        (errors.container_memory_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={
                                        'container_memory_request_input'
                                      }
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultRequestPerContainer',
                                          'memory'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={containerMemoryRequest}
                                      endText={' container'}
                                      name={'container_memory_request_input'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell
                                // storageCell
                                onClick={(e) =>
                                  handleEditCell(
                                    e,
                                    cluster.clusterName,
                                    item.namespace,
                                    enforceQuota
                                  )
                                }
                                sx={{
                                  width: '200px',
                                  py: 1.5,
                                  px: 0.5,
                                  border: 'none',
                                }}
                              >
                                <Stack>
                                  <Box>
                                    <DivisorInputLimit
                                      gap={'30px'}
                                      error={
                                        errors.storage_limit &&
                                        touched['storage_limit'] &&
                                        (errors.storage_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'storage_limit_input'}
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'limit',
                                          'ephemeralStorage'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={storage_limit}
                                      startText={stringifyUsageValue(
                                        userdata?.ephemeralStorage
                                      )}
                                      name={'storage_limit'}
                                      label={'Limit -'}
                                    />
                                  </Box>
                                  <Box>
                                    <DividendInputLimit
                                      gap={'24px'}
                                      error={
                                        errors.container_storage_limit &&
                                        touched['container_storage_limit'] &&
                                        (errors.container_storage_limit ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={
                                        'container_storage_limit_input'
                                      }
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultLimitPerContainer',
                                          'ephemeralStorage'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={container_storage_limit}
                                      endText={' container'}
                                      name={'container_storage_limit'}
                                      label={'Limit -'}
                                    />
                                  </Box>
                                  <Box>
                                    <DivisorInputLimit
                                      error={
                                        errors.storage_request &&
                                        touched['storage_request'] &&
                                        (errors.storage_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={'storage_request_input'}
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'request',
                                          'ephemeralStorage'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={storage_request}
                                      startText={stringifyUsageValue(
                                        userdata?.ephemeralStorageRequest
                                      )}
                                      name={'storage_request'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                  <Box>
                                    <DividendInputLimit
                                      error={
                                        errors.container_storage_request &&
                                        touched['container_storage_request'] &&
                                        (errors.container_storage_request ??
                                          'Please set the quota at the slice level first.')
                                      }
                                      dataTestId={
                                        'container_storage_request_input'
                                      }
                                      handleChange={(e) =>
                                        handleChange(
                                          e,
                                          'defaultRequestPerContainer',
                                          'ephemeralStorage'
                                        )
                                      }
                                      handleBlur={handleFocusOut}
                                      value={container_storage_request}
                                      endText={' container'}
                                      name={'container_storage_request'}
                                      label={'Request -'}
                                    />
                                  </Box>
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: '40px',
                                  textAlign: 'center'
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    clearValues(
                                      cluster.clusterName,
                                      item.namespace
                                    )
                                  }
                                  className="btn-outlined"
                                  variant="outlined"
                                  sx={{
                                    mx: 'auto',
                                    minWidth: '30px',
                                    height: '30px',
                                    padding: '5px',
                                    background: '#F3F6FF',
                                    border: '1px solid #B6B8D6',
                                    color: '#A1A4D3',
                                  }}
                                >
                                  <ClearActionIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </ClickAwayListener>
                        ) : (
                          <TableRow
                            sx={{
                              borderBottom: '1px solid #cbd5e1',
                            }}
                            key={namespaceIndex}
                            data-testid={`${cluster?.clusterName ?? ''}-${item?.namespace ?? ''
                              }`}
                          >
                            <TableCell
                              sx={{
                                width: '40px',
                                py: 1.5,
                                // height: '100%',
                                px: 3,
                                // display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                border: 'none',
                                paddingLeft: '20px',
                                textAlign: 'center'
                              }}
                            >
                              <FormControlLabel
                                className="switch-style"
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    onChange={() =>
                                      handleSwitch(cluster, item.namespace)
                                    }
                                    checked={enforceQuota}
                                  />
                                } // clusterIndex sent here is actually resourceQuotaIndex
                                label=""
                              />
                            </TableCell>
                            <TableCell
                              onClick={(e) =>
                                handleEditCell(
                                  e,
                                  cluster.clusterName,
                                  item.namespace,
                                  enforceQuota
                                )
                              }
                              sx={{
                                py: 2.5,
                                px: 0.5,
                                paddingLeft: '24px',
                                border: 'none',
                              }}
                              data-testid="cluster-name"
                            >
                              <Typography
                                style={{ fontSize: '14px' }}
                                className="txt-bold"
                              >
                                {item.namespace}
                              </Typography>
                              <Typography className="ft-12">
                                {cluster.clusterName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              onClick={(e) =>
                                handleEditCell(
                                  e,
                                  cluster.clusterName,
                                  item.namespace,
                                  enforceQuota
                                )
                              }
                              sx={{
                                py: 1.5,
                                px: 3,
                                pr: 0,
                                border: 'none',
                              }}
                              data-testid="ns_pod_count"
                            >
                              <ValueWithUsage
                                usage={userdata?.podCount}
                                value={podCount}
                              />
                            </TableCell>
                            <TableCell
                              // cpuCell
                              onClick={(e) =>
                                handleEditCell(
                                  e,
                                  cluster.clusterName,
                                  item.namespace,
                                  enforceQuota
                                )
                              }
                              sx={{
                                width: '204px',
                                border: 'none',
                                py: 1.5,
                                px: 0.5,
                              }}
                              data-testid="ns_cpu_block"
                            >
                              <DetailBlock
                                userdata={{
                                  limit: userdata?.cpu,
                                  request: userdata?.cpuRequest,
                                }}
                                limit={cpu_limit}
                                container_limit={containerCpuLimit}
                                request={cpuRequest}
                                container_request={containerCpuRequest}
                              />
                            </TableCell>
                            <TableCell
                              // memoryCell
                              data-testid="ns_memory_block"
                              onClick={(e) =>
                                handleEditCell(
                                  e,
                                  cluster.clusterName,
                                  item.namespace,
                                  enforceQuota
                                )
                              }
                              sx={{
                                width: '204px',
                                py: 1.5,
                                px: 0.5,
                                border: 'none',
                              }}
                            >
                              <DetailBlock
                                userdata={{
                                  limit: userdata?.memory,
                                  request: userdata?.memoryRequest,
                                }}
                                limit={memory_limit}
                                container_limit={containerMemoryLimit}
                                request={memoryRequest}
                                container_request={containerMemoryRequest}
                              />
                            </TableCell>
                            <TableCell
                              // storageCell
                              data-testid="ns_storage_block"
                              onClick={(e) =>
                                handleEditCell(
                                  e,
                                  cluster.clusterName,
                                  item.namespace,
                                  enforceQuota
                                )
                              }
                              sx={{
                                width: '204px',
                                py: 1.5,
                                px: 0.5,
                                border: 'none',
                              }}
                            >
                              <DetailBlock
                                userdata={{
                                  limit: userdata?.ephemeralStorage,
                                  request: userdata?.ephemeralStorageRequest,
                                }}
                                limit={storage_limit}
                                container_limit={container_storage_limit}
                                request={storage_request}
                                container_request={container_storage_request}
                              />
                            </TableCell>
                            {stepNo === '2' && (
                              <TableCell
                                sx={{
                                  padding: '0px',
                                  textAlign: 'center'
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    clearValues(
                                      cluster.clusterName,
                                      item.namespace
                                    )
                                  }
                                  className="btn-outlined"
                                  variant="outlined"
                                  sx={{
                                    minWidth: '30px',
                                    height: '30px',
                                    padding: '5px',
                                    background: '#F3F6FF',
                                    border: '1px solid #B6B8D6',
                                  }}
                                >
                                  <ClearActionIcon />
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      ) : enforceQuota ? (
                        <TableRow>
                          <TableCell style={{ paddingLeft: '20px' }}>
                            <Typography
                              style={{ fontSize: '14px' }}
                              className="txt-bold"
                            >
                              {item.namespace}
                            </Typography>
                            <Typography className="ft-12">
                              {cluster.clusterName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 0.5,
                            }}
                          >
                            <ValueWithUsage
                              value={podCount}
                              usage={userdata?.podCount}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '204px',
                              border: 'none',
                              py: 1.5,
                              px: 0.5,
                            }}
                            data-testid="ns_cpu_block"
                          >
                            <DetailBlock
                              userdata={{
                                limit: userdata?.cpu,
                                request: userdata?.cpuRequest,
                              }}
                              limit={cpu_limit}
                              container_limit={containerCpuLimit}
                              request={cpuRequest}
                              container_request={containerCpuRequest}
                            />
                          </TableCell>
                          <TableCell
                            // memoryCell
                            data-testid="ns_memory_block"
                            sx={{
                              width: '204px',
                              py: 1.5,
                              px: 0.5,
                              border: 'none',
                            }}
                          >
                            <DetailBlock
                              userdata={{
                                limit: userdata?.memory,
                                request: userdata?.memoryRequest,
                              }}
                              limit={memory_limit}
                              container_limit={containerMemoryLimit}
                              request={memoryRequest}
                              container_request={containerMemoryRequest}
                            />
                          </TableCell>
                          <TableCell
                            // storageCell
                            data-testid="ns_storage_block"
                            onClick={(e) =>
                              handleEditCell(
                                e,
                                cluster.clusterName,
                                item.namespace,
                                enforceQuota
                              )
                            }
                            sx={{
                              width: '204px',
                              py: 1.5,
                              px: 0.5,
                              border: 'none',
                            }}
                          >
                            <DetailBlock
                              userdata={{
                                limit: userdata?.ephemeralStorage,
                                request: userdata?.ephemeralStorageRequest,
                              }}
                              limit={storage_limit}
                              container_limit={container_storage_limit}
                              request={storage_request}
                              container_request={container_storage_request}
                            />
                          </TableCell>
                        </TableRow>
                      ) : null;
                    });
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Paper>
    </Grid>
  );
};

export default ManageResourceTable;
