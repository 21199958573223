import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.css';

import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  createNodeAffinity,
  deleteNodeAffinity,
  updateNodeAffinity,
} from '../../../actions/NodeAffinity.actions';
import { nodeAffinityApiActions } from '../utils';
import {
  selectAffinityApiAction,
  selectCurrentSlice,
  selectCurrentSliceAffinity,
} from './../../../selectors/NodeAffinity.selector';
import ViewNamespacesModel from './ViewNamespacesModel';

export default function SliceTable() {
  const [open, setOpen] = useState(false);

  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const history = useHistory();
  const currentSlice = useSelector((state) => selectCurrentSlice(state));

  const currentSLiceAffinity = useSelector(selectCurrentSliceAffinity);

  const affinity_api_action = useSelector((state) =>
    selectAffinityApiAction(state)
  );

  const sliceName = currentSlice.metadata.name;

  const dispatch = useDispatch();

  const [DisableOffboardCluster, setDisableOffboardCluster] =
    React.useState(false);

  // useEffect(() => {
  //   if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // }, []);
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);
  /**
   *{
        ...currentSliceAffinity,
        spec: {
          ...currentSliceAffinity.spec,
          nodeAffinityProfiles:
            currentSliceAffinity.spec.nodeAffinityProfiles
           .map(profile => ({
              ...profile, 
              nodeAffinityRules: profile.nodeAffinityRules?.filter(rule => {
                return rule.nodeSelectorLabels?.length > 0
              }) ?? []
            })) .filter(profile => {
              // if all nodeSelectorLabels is empty, skip the cluster itself
              return profile.nodeAffinityRules?.some(rule => rule.nodeSelectorLabels?.length > 0)
            }).map((profile) => {
              const { nodeAffinityRules } = profile;
              const sorted_rules = nodeAffinityRules.sort((prev, next) => {
                if (prev.namespace === '*') return 1;
                return -1;
              });
              return {
                ...profile,
                nodeAffinityRules: sorted_rules,
              };
            }),
        },
      } 
   */

  const applyYml = () => {
    const payload = {
      sliceName,
      nodeAffinityProfiles: currentSLiceAffinity.spec.nodeAffinityProfiles
        .map((profile) => ({
          ...profile,
          nodeAffinityRules:
            profile.nodeAffinityRules?.filter((rule) => {
              return rule.nodeSelectorLabels?.length > 0;
            }) ?? [],
        }))
        .filter((profile) => {
          // if all nodeSelectorLabels is empty, skip the cluster itself
          return profile.nodeAffinityRules?.some(
            (rule) => rule.nodeSelectorLabels?.length > 0
          );
        })
        .map((profile) => {
          const { nodeAffinityRules } = profile;
          const sorted_rules = nodeAffinityRules.sort((prev, next) => {
            if (prev.namespace === '*') return 1;
            return -1;
          });
          return {
            ...profile,
            nodeAffinityRules: sorted_rules,
          };
        }),
    };

    switch (affinity_api_action) {
      case nodeAffinityApiActions.delete:
        dispatch(deleteNodeAffinity(sliceName)).then(() => {
          history.push('/assign-nodes/4');
        });
        break;

      case nodeAffinityApiActions.update:
        dispatch(updateNodeAffinity(payload)).then((res) => {
          history.push('/assign-nodes/4');
        });
        break;
      default:
        dispatch(createNodeAffinity(payload)).then((res) => {
          history.push('/assign-nodes/4');
        });
        break;
    }

    // history.push('/assign-nodes/4');
  };

  const [clusterDetails, setClusterDetails] = useState({});

  useEffect(() => {
    if (currentSlice?.spec?.clusters && clusterList.items) {
      const list = {};
      currentSlice?.spec?.clusters.forEach((item) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == item
        );
        list[item] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion?.toLowerCase(),
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });
      setClusterDetails(list);
    }
  }, [currentSlice, clusterList]);

  const [labels, setLabels] = useState([]);
  const selectedNode_labels = useSelector(
    (state) => state.NodeAffinity.affinity_profiles
  );
  const node_labels = useSelector((state) => state.NodeAffinity.node_labels);

  useEffect(() => {
    let selected_node = [];
    const local_labels = [];
    selectedNode_labels?.forEach((profile) => {
      profile.nodeAffinityRules.forEach((item) => {
        item.nodeSelectorLabels?.forEach((node) => {
          const label = { [node.key]: node.values[0] };
          selected_node = [
            ...selected_node.filter((l_label) => {
              return !_.isEqual(l_label, label);
            }),
            label,
          ];
        });
      });
    });
    node_labels.forEach((cluster) => {
      cluster.nodeDetails.forEach((node) => {
        Object.entries(node.labels).forEach(([key, value]) => {
          const label = { [key]: value };
          const exists = local_labels.find((_label) => _label[key] === value);

          if (!exists) {
            local_labels.push(label);
          }
        });
      });
    });
    setLabels(selected_node);
  }, [selectedNode_labels]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {openViewModal && (
        <ViewNamespacesModel
          open={openViewModal}
          setOpenViewModal={setOpenViewModal}
          sliceName={currentSlice.metadata.name}
          labels={labels}
        />
      )}
      {/* <Typography
        style={{
          paddingLeft: "24px",
          paddingTop: "15px",
          fontFamily: "Bai Jamjuree",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "30px",
          color: "#12153E",
        }}
        className="step-title-header"
        gutterBottom
        component="div"
      >
        <div>Slice</div>
        <div
          style={{
            direction: "rtl",
          }}
        >
          <Button
            onClick={openDialog}
            style={{ width: "130px" }}
            className="btn"
            variant="contained"
          >
            Apply YAML
          </Button>
        </div>
      </Typography> */}

      <Box
        sx={{
          p: 3,
          pb: 0,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box item xs={10}>
          <Typography
            className="step-title-header"
            gutterBottom
            component="div"
            data-testid="Slice"
          >
            Slice
          </Typography>
        </Box>
        {/* <Prompt
        message={(location, action) => {
          if (location.pathname === '/assign-nodes/3' && action === 'PUSH') {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      /> */}
        {window.location.hash.includes('#/assign-nodes/3') ? (
          <Box item xs={2}>
            <Button
              disabled={DisableOffboardCluster}
              onClick={applyYml}
              style={{
                width: '130px',
                backgroundColor: DisableOffboardCluster ? '#CCCCCC' : undefined,
              }}
              className="btn"
              variant="contained"
              data-testid="ApplyYamal"
            >
              Apply YAML
            </Button>
          </Box>
        ) : null}
        {/* <Box item xs={2}>
          <Button
            onClick={applyYml}
            style={{ width: "130px" }}
            className="btn"
            variant="contained"
            data-testid="ApplyYamal"
          >
            Apply YAML
          </Button>
        </Box> */}
      </Box>
      {/* <Typography
        style={{
          paddingLeft: "24px",
          paddingTop: "15px",
          fontFamily: "Bai Jamjuree",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "30px",
          color: "#12153E",
        }}
        className="step-title-header"
        gutterBottom
        component="div"
      >
        <Button
          onClick={openDialog}
          style={{ width: "130px" }}
          className="btn"
          variant="contained"
        >
          Apply YAML
        </Button>
      </Typography> */}
      <TableContainer className="table-container">
        <Table
          data-testid="SliceTabelS4"
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow style={{ height: '45px' }}>
              <TableCell />
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="sliceName"
              >
                SLICE NAME
              </TableCell>
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="sliceType"
              >
                TYPES
              </TableCell>
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="sliceNode"
              >
                NODES LABELS
              </TableCell>
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="sliceDescription"
              >
                DESCRIPTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ borderStyle: 'hidden' }}>
              <TableCell
                sx={{
                  width: 20,
                }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? (
                    <KeyboardArrowUpIcon sx={{ width: 40, height: 40 }} />
                  ) : (
                    <KeyboardArrowDownIcon sx={{ width: 40, height: 40 }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell
                style={{
                  padding: '24px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                {currentSlice?.metadata?.name}
              </TableCell>
              <TableCell
                style={{
                  padding: '24px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                Application
              </TableCell>
              <TableCell
                style={{
                  padding: '24px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                {labels?.length > 0 ? (
                  <Link
                    data-testid="SliceLinkS4"
                    underline="hover"
                    style={{ color: '#2868F3', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenViewModal(true);
                    }}
                  >
                    View all
                  </Link>
                ) : (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: '#5A607C',
                    }}
                  >
                    No Labels Assigned
                  </Typography>
                )}
              </TableCell>
              <TableCell
                style={{
                  padding: '24px',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                {currentSlice?.metadata?.annotations?.desc}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{
                  paddingBottom: open ? 10 : 0,
                  paddingTop: open ? 10 : 0,
                  transition: '0.3s ease-in-out',
                }}
                colSpan={9}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Table size="small" aria-label="purchases">
                      <TableHead
                        className="table-head"
                        sx={{ borderColor: 'transparent' }}
                      >
                        <TableRow
                          style={{
                            border: '1px solid #D9DFEE',
                            fontWeight: '600',
                          }}
                        >
                          <TableCell className="ft-12 txt-bold">
                            CLUSTERS
                          </TableCell>
                          <TableCell className="ft-12 txt-bold">
                            DEPLOYMENT LOCATION
                          </TableCell>
                          <TableCell className="ft-12 txt-bold">
                            CLOUD TYPE
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(clusterDetails)?.map((key, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                className="ft-16"
                                style={{ borderLeft: '1px solid #D9DFEE' }}
                              >
                                {key}
                              </TableCell>
                              <TableCell className="ft-16">
                                {clusterDetails[key]['location']}
                              </TableCell>
                              <TableCell
                                className="ft-16"
                                style={{ borderRight: '1px solid #D9DFEE' }}
                              >
                                {clusterDetails[key]['cloudType']}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
