import * as React from 'react';
import AkamaliOnCloud from '../../assets/images/AkamaliOnCloud.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import Akamai from '../../assets/images/AkamaiCircle.svg';

function AkamaiCloud(props) {
  return <CommonCloud {...props} img={Akamai} />;
}

export const AkamaiTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={AkamaliOnCloud} img={Akamai} />;
};

export default AkamaiCloud;
