import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getSliceCustomObjectList,
  clearCurrentSlice,
} from '../../../actions/ConnectClusterActions';
import {
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import './index.css';
import Row from './Row';
import NoSlice from '../../../assets/images/no-slice-namespace.png';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import GeneralLayout from '../../Commons/GeneralLayout';

export const StyledTableCell = styled(TableCell)((props) => ({
  fontWeight: '600',
  fontSize: '12px',
  padding: '15px 0px 15px 24px',
  backgroundColor: '#DEE8FF',
  alignItems: 'center',
  lineHeight: '15px',
}));

export default function NamespaceList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  useEffect(() => {
    dispatch(clearCurrentSlice());
    dispatch(getSliceCustomObjectList());
    dispatch(getRegisteredClusters());
  }, []);

  const sliceList = useSelector((state) => state.ConnectCluster.sliceList);
  const iterableSliceList = sliceList.filter(
    (el) =>
      el.spec.namespaceIsolationProfile.applicationNamespaces &&
      el.spec.namespaceIsolationProfile.applicationNamespaces.length > 0
  );
  // const currentSlice = useSelector(
  //   (state) => state.NamespaceOnboard.currentSlice
  // );
  const handleRedirectToNewUi = () => {
    window.location.href = `/slices`;
  };
  return (
    <GeneralLayout description={'Isolation'}>
      <Paper
        elevation={0}
        className={` ${
          iterableSliceList?.length > 0
            ? ''
            : 'table-container-isolate-empty table-empty-size'
        }`}
        sx={{
          padding: '24px 0 0 0',
          border: '1px solid #B6B8D6',
        }}
      >
        <div className="table-header table-header-isolate">
          <Typography className="table-header-txt" gutterBottom component="div">
            {`Slice`}
          </Typography>
          {iterableSliceList?.length > 0 && (
            <Button
              disabled={Object.keys(currentSlice).length === 0}
              onClick={() =>
                history.push('/manage-namespaces/isolate-namespaces/add/1')
              }
              className="btn-dif"
              variant="contained"
            >
              Add Allowed Namespaces
            </Button>
          )}
        </div>
        <TableContainer
          className={`table-container-isolate ${
            iterableSliceList?.length > 0
              ? ''
              : 'table-container-isolate-empty flex-1'
          }`}
        >
          <Table
            stickyHeader
            aria-label="collapsible table"
            size="small"
            sx={{
              fontSize: '12px',
              ...(iterableSliceList?.length > 0
                ? {}
                : { display: 'flex', flexDirection: 'column', flex: 1 }),
            }}
            style={{ tableLayout: 'fixed', border: 0 }}
          >
            <TableHead
              sx={{
                backgroundColor: '#DEE8FF',
                borderBottom: '1px solid #D9DFEE',
              }}
            >
              <TableRow>
                <StyledTableCell width={'60px'} />
                <StyledTableCell sx={{ width: '20%' }}>
                  SLICE NAME
                </StyledTableCell>
                <StyledTableCell sx={{ width: '13%' }}>
                  NAMESPACES
                </StyledTableCell>
                <StyledTableCell sx={{ width: '22%' }}>
                  ISOLATION
                </StyledTableCell>
                <StyledTableCell sx={{ width: '20%' }}>
                  ALLOWED NAMESPACES
                </StyledTableCell>
                <StyledTableCell sx={{ width: '17%' }}>
                  DESCRIPTION
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={
                iterableSliceList?.length > 0
                  ? {}
                  : { flex: 1, display: 'flex', flexDirection: 'column' }
              }
            >
              {iterableSliceList?.length > 0 ? (
                iterableSliceList.map((slice) => (
                  // sliceList.map((slice) => (
                  <Row key={slice.metadata?.name} slice={slice} />
                ))
              ) : (
                <TableRow
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TableCell
                    colSpan={6}
                    sx={{
                      height: '50vh',
                      border: 0,
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={NoSlice}
                        width="200"
                        height="200"
                        alt="No slice for namespace"
                      />
                    </div>
                    <Typography
                      className="no-slice-txt-isolated"
                      variant="subtitle2"
                      component="div"
                      sx={{ width: '840px' }}
                    >
                      {`Create Slices and Onboard Namespaces before enabling Isolation.`}
                    </Typography>
                    <Button
                      onClick={() => history.push('/manage-namespaces')}
                      variant="contained"
                      sx={{ alignSelf: 'center', marginTop: '24px' }}
                      className="btn-dif"
                    >
                      {'Go to Namespaces'}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </GeneralLayout>
  );
}
