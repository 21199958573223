import React, { memo, useCallback, useMemo, useState } from 'react';

import ReactFlow from 'react-flow-renderer';

import Paper from '@mui/material/Paper';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  ALL_SLICES_KEY,
  SLICE_COMPARE_KEY,
} from '../../../../src/constants/enums';
import { getTopologyViewDetails } from '../../../actions/dashboardActions';
import CustomEdge from './CustomEdge';
import ColorSelectorNode from './CustomNode';
import { generateEdges, generateNodeCoordinates } from './util';
const nodeTypes = {
  selectorNode: ColorSelectorNode,
  draggable: false,
  isConnectable: false,
};
const edgeTypes = {
  special: CustomEdge,
  draggable: false,
  isConnectable: false,
};

const TopologicalView = ({
  clusters,
  activeSelectedSlice,
  getTopologyViewDetails,
}) => {
  const [elements, setElements] = useState([]);
  const [latency, setLatency] = useState({});
  const [reactflowInstance, setReactflowInstance] = useState(null);
  const activeSliceClusters = useMemo(() => {
    const clusterDetails =
      activeSelectedSlice?.sliceName === ALL_SLICES_KEY
        ? clusters
        : clusters.filter((cluster) =>
            cluster.slices.find(
              (slice) => slice.name === activeSelectedSlice.sliceName
            )
          );
    setLatency({});
    return clusterDetails;
  }, [clusters, activeSelectedSlice]);

  const onLoad = useCallback(
    (rf) => {
      rf.fitView();
      if (!reactflowInstance) {
        setTimeout(setReactflowInstance(rf), 0);
      }
    },
    [reactflowInstance]
  );

  const nodes = useMemo(
    () => generateNodeCoordinates(activeSliceClusters),
    [activeSliceClusters]
  );

  React.useEffect(() => {
    async function fetchData() {
      const data = await getTopologyViewDetails(
        activeSelectedSlice[SLICE_COMPARE_KEY],
        moment().format('X')
      );
      const _latency = data.reduce((acc, currentValue) => {
        const keys = Object.keys(currentValue);
        keys.map((key) => (acc[key] = currentValue[key]));
        return acc;
      }, {});
      setLatency(_latency);
    }
    fetchData();
  }, [clusters, activeSelectedSlice]);

  const edges = useMemo(() => generateEdges(nodes, latency), [nodes, latency]);
  React.useEffect(() => {
    setElements(
      [...nodes, ...edges].map((el) => ({
        ...el,
        draggable: false,
        isConnectable: false,
      }))
    );
    if (reactflowInstance) {
      setTimeout(() => reactflowInstance.fitView(), 0);
    }
  }, [nodes, edges, latency]);

  return (
    <div>
      <Paper
        variant="outlined"
        square
        style={{ marginTop: '0rem', minHeight: 'inherit', padding: '0rem' }}
      >
        <div style={{ height: '75vh' }}>
          <div
            style={{
              position: 'relative',
              width: '107px',
              height: '20px',
              top: '20px',
              left: '10px',
              fontFamily: 'Bai Jamjuree',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            Topology View
          </div>

          {elements.length > 0 ? (
            <ReactFlow
              elements={elements}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              elementsSelectable={false}
              onLoad={onLoad}
              snapToGrid={false}
              snapGrid={[15, 15]}
              paneMoveable={false}
              zoomOnScroll={false}
              draggable={false}
              zoomOnPinch={false}
              zoomOnDoubleClick={false}
              fitView
              defaultPosition={[0, 0]}
            ></ReactFlow>
          ) : (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              No clusters available
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clusters: state.dashboard.clusterDetail,
    edges: state.dashboard.getCompareClusterStats,
    activeSelectedSlice: state.dashboard.activeSelectedSlice,
  };
};

export default connect(mapStateToProps, { getTopologyViewDetails })(
  memo(TopologicalView)
);
