import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSliceObject,
  updateSliceCustomObject,
} from '../../../../actions/ConnectClusterActions';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgressWithLabel from './ProgressBar';
import Checked from './../../../../assets/images/Check.svg';
import Row from '../../LandingPage/Row';
import { useHistory, Prompt } from 'react-router-dom';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '300px',
    height: '120px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '50px',
  padding: '10px 24px',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

const GenerateYaml = ({
  getSliceObject,
  setClose,
  currentSlice,
  updateSliceCustomObject,
}) => {
  const [applyYamlDialog, setApplyYamlDialog] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [slice, setSlice] = useState({});
  const history = useHistory();
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);

  useEffect(() => {
    if (currentSlice && currentSlice?.metadata?.name) {
      getSliceObject(currentSlice?.metadata?.name)
        .then((res) => {
          setSlice(res);
        })
        .catch((err) => console.log(err));
    } else {
      history.push('/manage-namespaces');
    }
  }, []);

  // useEffect(() => {
  //   if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);
  const generatePayload = (slice) => {
    let payload;
    if (slice?.spec?.overlayNetworkDeploymentMode === 'no-network') {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,
        sliceSubnet: slice.spec?.sliceSubnet,
        maxClusters: slice.spec?.maxClusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces,
      };
    } else if (slice?.spec?.standardQosProfileName) {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        maxClusters: slice.spec?.maxClusters,
        externalGatewayConfig: slice?.spec?.externalGatewayConfig,
        sliceGWType: slice.spec?.sliceGatewayProvider?.sliceGatewayType,
        sliceCertAuthType: slice.spec?.sliceGatewayProvider?.sliceCaType,
        standardQosProfileName: slice.spec?.standardQosProfileName,
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,
        // sliceQosProfile: slice.spec?.qosProfileDetails && 'Custom',
        // sliceQosType: slice.spec?.qosProfileDetails?.queueType,
        // sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
        // sliceQosPriority: slice.spec?.qosProfileDetails?.priority,
        // bandwidthCeilingKbps: slice.spec?.qosProfileDetails?.bandwidthCeilingKbps,
        // bandwidthGuaranteedKbps:
        //   slice.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,
        // dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
        sliceSubnet: slice.spec?.sliceSubnet,
        sliceIpamType: slice.spec?.sliceIpamType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces,
      };
    } else if (
      !slice?.spec?.standardQosProfileName &&
      slice.spec?.qosProfileDetails
    ) {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        externalGatewayConfig: slice?.spec?.externalGatewayConfig,
        maxClusters: slice.spec?.maxClusters,
        sliceGWType: slice.spec?.sliceGatewayProvider?.sliceGatewayType,
        sliceCertAuthType: slice.spec?.sliceGatewayProvider?.sliceCaType,
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,

        qosProfileDetails: {
          bandwidthCeilingKbps: parseInt(
            slice.spec?.qosProfileDetails?.bandwidthCeilingKbps
          ),
          dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
        },
        // standardQosProfileName: slice.spec?.standardQosProfileName,
        sliceQosProfile: slice.spec?.qosProfileDetails && 'custom',
        sliceQosType: slice.spec?.qosProfileDetails?.queueType,
        sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
        sliceQosPriority: slice.spec?.qosProfileDetails?.priority,

        bandwidthGuaranteedKbps:
          slice.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,

        sliceSubnet: slice.spec?.sliceSubnet,
        sliceIpamType: slice.spec?.sliceIpamType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces,
      };
    }

    //namespace Isolation data

    updateSliceCustomObject(payload).then((res) => {
      if (res) {
        setSlice(res);
        setApplyYamlDialog(true);
      }
      setTimeout(() => {
        if (res) {
          setTimeout(() => {
            setApplyYamlDialog(false);

            history.push('/manage-namespaces/add/3');
          }, 1000);
        }
      });
    }, 2000);
  };

  const onClickHandler = () => {
    setClose(true);
  };

  const openDialog = () => {
    generatePayload(currentSlice);
  };

  const closeDialog = () => {
    setApplyYamlDialog(false);
  };

  return (
    <>
      {slice?.metadata?.name && (
        <>
          <Prompt
            message={(location, action) => {
              if (location.pathname.includes('manage-namespaces')) {
                return true;
              } else {
                return 'You have unsaved changes. Do you want to discard them?';
              }
            }}
          />
          <Grid item sx={{ marginBottom: '40vh' }}>
            <Paper
              elevation={0}
              sx={{ overflow: 'auto', border: '1px solid #B6B8D6' }}
            >
              <Grid
                style={{ padding: '14px 20px' }}
                container
                spacing={1}
                direction="row"
              >
                <Grid item xs={10}>
                  <Typography
                    className="step-title-header"
                    gutterBottom
                    component="div"
                  >
                    Slice
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FinalButton
                    disabled={DisableOffboardCluster}
                    onClick={openDialog}
                    style={{
                      backgroundColor: DisableOffboardCluster
                        ? '#CCCCCC'
                        : undefined,
                    }}
                  >
                    Apply YAML
                  </FinalButton>
                </Grid>
              </Grid>

              <TableContainer className="table-container">
                <Table aria-label="collapsible table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          lineHeight: '15px',
                          paddingRight: '0px',
                          fontWeight: '600',
                        }}
                        width={'60px'}
                      />
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          lineHeight: '15px',
                          paddingRight: '0px',
                          fontWeight: '600',
                        }}
                        width={'20%'}
                      >
                        SLICE NAME
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          lineHeight: '15px',
                          paddingRight: '0px',
                          fontWeight: '600',
                        }}
                        width={'18%'}
                      >
                        NAMESPACES
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '12px',
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          lineHeight: '15px',
                          paddingRight: '0px',
                          fontWeight: '600',
                        }}
                      >
                        DESCRIPTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody onClick={onClickHandler}>
                    <Row
                      onClick={onClickHandler}
                      key={slice?.metadata?.name}
                      slice={slice}
                      radioDisable={true}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <CustomDialog
            open={applyYamlDialog}
            onClose={closeDialog}
            style={{ padding: '5px' }}
            aria-labelledby="This dialog is for applying yaml"
            aria-describedby="This Dialog is for applying yaml"
          >
            <DialogTitle
              style={{ fontWeight: 'bold' }}
              id="delete-cluster-dialog-title"
            >
              {!loaded ? (
                <>
                  <Box p={1} sx={{ width: '100%' }}>
                    <Typography
                      style={{
                        fontFamily: 'Bai Jamjuree',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '20px',
                        lineHeight: '18px',
                        /* identical to box height, or 90% */
                        color: '#2A2747',
                      }}
                    >
                      Applying YAML
                    </Typography>
                  </Box>

                  <Box p={1} sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={100} />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img alt="discover img" src={Checked} width="25px" />{' '}
                  </Box>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      style={{
                        fontFamily: 'Bai Jamjuree',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '14px',
                        lineHeight: '18px',
                        /* identical to box height, or 90% */
                        color: '#2A2747',
                      }}
                    >
                      YAML applied succesfully
                    </Typography>
                  </Box>
                </>
              )}
            </DialogTitle>
          </CustomDialog>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSlice: state.NamespaceOnboard.currentSlice,
  };
};
export default connect(mapStateToProps, {
  getSliceObject,
  updateSliceCustomObject,
})(GenerateYaml);
