import React, { Component } from "react";
import { Button } from "@mui/material";
import { FormCheck, FormField, FormSelect } from "../Commons/formUtils";
import MuiPaper from "@mui/material/Paper";
import styled from "styled-components";
import JsonEditor from "./JsonEditor";
import MuiPopper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

const Popper = styled(MuiPopper)`
  z-index: 5000;
`;

const Paper = styled(MuiPaper)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`;
const BackgroundBlur = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 6000;
`;

const ruleNameMessage = "Rule Name is required";
const ruleJsonMsg = "Rule Json is required";

export default class EnforcementRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {
        kind: "",
        enabled: true,
      },
      ruleNameError: false,
      ruleJsonError: null,
      ruleEnforcementKindError: false,
    };
  }

  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };

  validate = () => {
    const {
      value: { name, rule, kind },
    } = this.state;
    if (!kind) return false;
    // if (!name || !rule || !kind) return false;
    return true;
  };
  saveEnforcement = () => {
    // const {
    //   value: { name, rule },
    // } = this.state;
    if (!this.validate()) return;
    // if (name && rule) {
    //   this.props.onSave(this.state.value);
    //   return;
    // }

    this.props.onSave(this.state.value);
    // if (!name) {
    //   this.setState({ ruleNameError: true });
    // } else {
    //   this.setState({ ruleNameError: false });
    // }

    // if (!rule || rule === {}) {
    //   this.setState({ ruleJsonError: { reason: ruleJsonMsg, line: 1 } });
    // } else {
    //   this.setState({ ruleJsonError: null });
    // }
  };

  render() {
    const {
      ruleNameError,
      ruleJsonError,
      value: { enabled, kind },
    } = this.state;
    const { options } = this.props;
    // let options;
    // if (policyProfile == "TCP_LB_POLICY") {
    //   options = [{ value: "KIND_TCP_ISTIO_VIRTUAL_SERVICE", label: "TCPIstioVirtualService" }];
    // } else if (policyProfile == "HTTP_LB_POLICY") {
    //   options = [
    //     // KIND_INVALID: "KindInvalid",
    //     { value: "KIND_HTTP_ISTIO_EGRESS_VIRTUAL_SERVICE", label: "HTTPIstioEgressVirtualService" },
    //     { value: "KIND_HTTP_ISTIO_VIRTUAL_SERVICE", label: "HTTPIstioVirtualService" },
    //   ];
    // } else {
    //   options = [];
    // }

    // const enforcementKindOptions = {
    //   // KIND_INVALID: "KindInvalid",
    //   KIND_HTTP_ISTIO_EGRESS_VIRTUAL_SERVICE: "HTTPIstioEgressVirtualService",
    //   KIND_HTTP_ISTIO_VIRTUAL_SERVICE: "HTTPIstioVirtualService",
    //   KIND_TCP_ISTIO_VIRTUAL_SERVICE: "TCPIstioVirtualService",
    // };
    return (
      <BackgroundBlur>
        <Paper>
          <h3 style={{ alignSelf: "flex-start" }}>Enforcement Point Profile</h3>
          {/* <FormField
            variant="standard"
            label="Rule Name"
            onChange={(e) => this.setValue("name", e.nativeEvent.target.value)}
            style={{ width: "40rem" }}
            error={ruleNameError}
            helperText={ruleNameError && ruleNameMessage}
          /> */}
          {/* <FormField
            variant="standard"
            label="Description"
            onChange={(e) => this.setValue("description", e.nativeEvent.target.value)}
            multiline={true}
            style={{ width: "40rem" }}
          /> */}
          <Autocomplete
            options={options}
            // defaultValue={kind}
            filterSelectedOptions
            PopperComponent={Popper}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Enforcement Kind" placeholder="" />}
            style={{ width: "40rem", zIndex: 5000, marginTop: "1rem", marginBottom: "1rem" }}
            onChange={(e, v) => this.setValue("kind", v.value)}
          />
          {/* <FormSelect
            width="40rem"
            label="Enforcement Kind"
            options={enforcementKindOptions}
            onChange={(e) => this.setValue("kind", e.target.value)}
          /> */}
          <FormCheck checked={enabled} onChange={() => this.setValue("enabled", !enabled)} label="Enabled" style={{ alignSelf: "flex-start" }} />
          {/* <JsonEditor label="Rule" onChange={(e) => this.setValue("rule", e.jsObject)} error={ruleJsonError} /> */}
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", margin: "1rem" }}>
            <Button variant="outlined" onClick={() => this.props.onCancel()}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={this.saveEnforcement} style={{ marginLeft: "1rem" }}>
              Save
            </Button>
          </div>
        </Paper>
      </BackgroundBlur>
    );
  }
}
