import React from 'react';
import { connect } from 'react-redux';
import {
  getSliceObject,
  updateSliceCustomObject,
} from '../../../../actions/ConnectClusterActions';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgressWithLabel from './ProgressBar';
import Checked from './../../../../assets/images/Check.svg';
import StepsTableRow from '../StepsTableRow';
import { useHistory, useLocation } from 'react-router-dom';
import { Prompt } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '300px',
    height: '120px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 'bold',
  height: '40px',
  padding: '10px 24px',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

const GenerateYaml = ({
  getSliceObject,
  setClose,
  currentSlice,
  updateSliceCustomObject,
}) => {
  const [applyYamlDialog, setApplyYamlDialog] = React.useState(false);
  const [loaded, setLoaded] = React.useState(true);
  const history = useHistory();
  const location = useLocation();
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);

  // useEffect(() => {
  //   if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);

  const generatePayload = (slice) => {
    let payload;
    if (slice?.spec?.overlayNetworkDeploymentMode === 'no-network') {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,
        sliceSubnet: slice.spec?.sliceSubnet,
        maxClusters: slice.spec?.maxClusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces || [],
      };
    }
    else if (slice?.spec?.standardQosProfileName) {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        maxClusters: slice.spec?.maxClusters,
        sliceGWType: slice.spec?.sliceGatewayProvider?.sliceGatewayType,
        sliceCertAuthType: slice.spec?.sliceGatewayProvider?.sliceCaType,
        standardQosProfileName: slice.spec?.standardQosProfileName,
        sliceQosProfile: slice.spec?.qosProfileDetails && 'Custom',
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,
        externalGatewayConfig: slice?.spec?.externalGatewayConfig,
        // sliceQosType: slice.spec?.qosProfileDetails?.queueType,
        // sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
        // sliceQosPriority: slice.spec?.qosProfileDetails?.priority,
        // bandwidthCeilingKbps:
        //   slice.spec?.qosProfileDetails?.bandwidthCeilingKbps,
        // bandwidthGuaranteedKbps:
        //   slice.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,
        // dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
        sliceSubnet: slice.spec?.sliceSubnet,
        sliceIpamType: slice.spec?.sliceIpamType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces || [],
      };
    } else if (
      !slice?.spec?.standardQosProfileName &&
      slice.spec?.qosProfileDetails
    ) {
      payload = {
        sliceName: slice.metadata?.name,
        sliceDisplayName: slice?.metadata?.annotations?.displayName,
        sliceType: slice.spec?.sliceType,
        overlayNetworkDeploymentMode: slice?.spec.overlayNetworkDeploymentMode,
        sliceGWType: slice.spec?.sliceGatewayProvider?.sliceGatewayType,
        sliceCertAuthType: slice.spec?.sliceGatewayProvider?.sliceCaType,
        externalGatewayConfig: slice?.spec?.externalGatewayConfig,
        // sliceQosProfile: slice.spec?.qosProfileDetails && 'Custom',
        // sliceQosType: slice.spec?.qosProfileDetails?.queueType,
        // sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
        // sliceQosPriority: slice.spec?.qosProfileDetails?.priority,
        // bandwidthCeilingKbps:
        //   slice.spec?.qosProfileDetails?.bandwidthCeilingKbps,
        // bandwidthGuaranteedKbps:
        //   slice.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,
        // dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
        qosProfileDetails: {
          bandwidthCeilingKbps: parseInt(
            slice.spec?.qosProfileDetails?.bandwidthCeilingKbps
          ),
          dscpClass: slice.spec?.qosProfileDetails?.dscpClass,
        },
        // standardQosProfileName: slice.spec?.standardQosProfileName,
        sliceQosProfile: slice.spec?.qosProfileDetails && 'custom',
        sliceQosType: slice.spec?.qosProfileDetails?.queueType,
        sliceQosTcType: slice.spec?.qosProfileDetails?.tcType,
        sliceQosPriority: slice.spec?.qosProfileDetails?.priority,
        sliceSubnet: slice.spec?.sliceSubnet,
        sliceIpamType: slice.spec?.sliceIpamType,
        sliceDesc: slice.metadata?.annotations?.desc,
        clusters: slice?.spec?.clusters,
        isolationEnabled:
          slice?.spec?.namespaceIsolationProfile?.isolationEnabled,
        applicationNamespaces:
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces,
        allowedNamespaces:
          slice?.spec?.namespaceIsolationProfile?.allowedNamespaces || [],
      };
    }

    updateSliceCustomObject(payload).then((res) => {
      setTimeout(() => {
        setApplyYamlDialog(true);
        history.push({
          pathname: '/manage-namespaces/isolate-namespaces/add/3',
          state: {
            legit: true,
          },
        });
      }, 500);
    });
  };

  const onClickHandler = () => {
    setClose(true);
  };

  const openDialog = () => {
    generatePayload(currentSlice);
  };

  const closeDialog = () => {
    setApplyYamlDialog(false);
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (location.pathname.includes('manage-namespaces')) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      {location?.state?.legit ? (
        <>
          {currentSlice?.metadata?.name && (
            <>
              <Grid item>
                <Paper
                  elevation={0}
                  sx={{ margin: '4px 0', border: '1px solid #D9DFEE' }}
                >
                  <Grid
                    sx={{ padding: '29px 25px' }}
                    container
                    direction="row"
                    alignItems={'center'}
                  >
                    <Grid xs={10} sx={{ paddingTop: 0 }}>
                      <Typography
                        className="step-title-header"
                        gutterBottom
                        component="div"
                      >
                        Slice
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ paddingTop: 0 }}
                      xs={2}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <FinalButton
                        disabled={DisableOffboardCluster}
                        onClick={openDialog}
                        style={{
                          backgroundColor: DisableOffboardCluster
                            ? '#CCCCCC'
                            : undefined,
                        }}
                      >
                        Apply YAML
                      </FinalButton>
                    </Grid>
                  </Grid>

                  <TableContainer>
                    <Table aria-label="collapsible table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: '14px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '15px',
                            }}
                          >
                            SLICE NAME
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '14px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '15px',
                            }}
                          >
                            ALLOWED NAMESPACES
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '14px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '15px',
                            }}
                          >
                            DESCRIPTION
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: '14px 24px',
                              fontFamily: 'Bai Jamjuree',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '15px',
                            }}
                          >
                            ISOLATE NAMEPSACES
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody onClick={onClickHandler}>
                        <StepsTableRow
                          onClick={onClickHandler}
                          key={currentSlice?.metadata?.name}
                          slice={currentSlice}
                          radioDisable={true}
                        />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>

              <CustomDialog
                open={applyYamlDialog}
                onClose={closeDialog}
                style={{ padding: '5px', marginTop: '10px' }}
                aria-labelledby="This dialog is for applying yam"
                aria-describedby="This Dialog is for applying yaml"
              >
                <DialogTitle
                  style={{ fontWeight: 'bold' }}
                  id="delete-cluster-dialog-title"
                >
                  {!loaded ? (
                    <>
                      <Box p={1} sx={{ width: '100%' }}>
                        <Typography
                          style={{
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '20px',
                            lineHeight: '18px',
                            /* identical to box height, or 90% */

                            color: '#2A2747',
                          }}
                        >
                          Applying YAML
                        </Typography>
                      </Box>

                      <Box p={1} sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={100} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        p={1}
                        sx={{ width: '100%' }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img alt="discover img" src={Checked} width="25px" />{' '}
                      </Box>

                      <Box
                        p={1}
                        sx={{ width: '100%' }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          style={{
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '18px',
                            /* identical to box height, or 90% */

                            color: '#2A2747',
                          }}
                        >
                          YAML applied succesfully
                        </Typography>
                      </Box>
                    </>
                  )}
                </DialogTitle>
              </CustomDialog>
            </>
          )}
        </>
      ) : (
        <Typography>Please complete the actions over Step 1 first</Typography>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSlice: state.NamespaceOnboard.currentSlice,
  };
};
export default connect(mapStateToProps, {
  getSliceObject,
  updateSliceCustomObject,
})(GenerateYaml);
