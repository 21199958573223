import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ReloadIcon } from '../../assets/icons/Reload.svg';
import { ReactComponent as CheckFill } from '../../assets/icons/CheckFill.svg';
import { ReactComponent as LoadingFill } from '../../assets/icons/LoadingFill.svg';

const drawerWidth = 560;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#FFFFFF',
    boxShadow: '-4px 0px 12px rgba(38, 38, 38, 0.08)',
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    // marginBottom: theme.spacing(2),
    margin: '3%',
    marginBottom: '-1%',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  sliceText: {
    width: '260px',
    height: '30px',
    fontFamily: 'Bai Jamjuree',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#12153E',
  },
  frame: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px',
    gap: '24px',
    width: 'calc(100% - 48px)', // Subtracting twice the padding to account for margin
    height: 'calc(100% - 48px)', // Subtracting twice the padding to account for margin
    margin: '24px',
    border: '1px solid #ACB9FF',
    borderRadius: '4px',
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 1,
  },
  checkFillLineItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'relative',
  },
  checkFillLineConnector: {
    position: 'absolute',
    left: '6.46%',
    right: '92.52%',
    top: '6.11%',
    bottom: '3.33%',
    background: '#F0F0F0',
  },
}));

const CustomDrawer = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.header}>
          <IconButton>
            <ReloadIcon />
          </IconButton>
          <div className={classes.sliceText}>Deleting Slice</div>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.frame}>
          <div style={{ margin: '2px' }}>red-slice-1</div>
          <div>
            {' '}
            <div
              style={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '12px',
                gap: '12px',
                width: '464px',
                height: '42px',
                background: '#F3F6FF',
                border: '1px solid #E1EBFF',
                borderRadius: '4px',
                flex: 'none',
                order: 1,
                alignSelf: 'stretch',
                flexGrow: 0,
              }}
            >
              {'In Progress'}: Deletion of slice "{'Red-slice-1'}"
            </div>
          </div>
          <div style={{ margin: '2px' }}>Steps:</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: '12px',
            }}
          >
            <div className={classes.checkFillContainer}>
              <CheckFill />

              <CheckFill />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default CustomDrawer;
