import { Grid, Typography } from '@mui/material';
import { ReactComponent as KubeSlice } from '../../../assets/images/Kubeslice.svg';

const Fallback = ({ text }) => {
  return (
    <Grid
      item
      xs
      container
      justifyContent="center"
      alignItems="center"
      width="60%"
      style={{ padding: '20px' }}
      flexWrap="nowrap"
      flexDirection="column"
      alignSelf="center"
    >
      <KubeSlice />
      <Typography fontSize="15px" fontWeight="400">
        {text}
      </Typography>
    </Grid>
  );
};

export default Fallback;
