import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { getRandomColor } from "../../../utils/functions";

export default function LineFilter({ open, handleClose, handleSave, lines }) {
  const [newLines, setNewLines] = useState([]);
  useEffect(() => {
    setNewLines(lines);
    return () => {
      setNewLines([]);
    };
  }, [lines]);
  const handleLineClick = (index) => {
    let _lines = [...newLines];
    _lines[index] = { ..._lines[index], stroke: _lines[index].stroke == "lightgrey" ? getRandomColor() : "lightgrey" };
    setNewLines(_lines);
  };
  return (
    <Dialog open={open} onClose={handleClose} style={{zIndex:4001}}>
      <DialogTitle>Filter Lines</DialogTitle>
      <DialogContent>
        {newLines.map(({ dataKey, stroke }, index) => (
          <div style={{ display: "flex", alignItems: "center", color: stroke }}>
            <Checkbox checked={stroke != "lightgrey"} onChange={() => handleLineClick(index)} />
            {dataKey}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSave(newLines)} color="primary" autoFocus>
          Filter
        </Button>
      </DialogActions>
    </Dialog>
  );
}
