import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Paper,
  Typography,
  InputBase,
  IconButton,
  FormControl,
  Button,
  Divider,
  Select,
  InputLabel,
  MenuItem,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import NamespaceTable from './NamespaceTable';
import SelectedNamespace from './SelectedNamespace';
import { setCurrentSlice } from '../../../../actions/namespaceActions';
import SliceTable from './SliceTable';
import { Prompt } from 'react-router-dom';

const NextButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '50px',
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

export default function InitialStep({ setBreadcrumb }) {
  const [selectedNamespace, setSelectedNamespace] = useState([]);
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(false);
  const [searchBy, setSearchBy] = useState('namespace');
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    currentSlice['spec']['namespaceIsolationProfile']['allowedNamespaces'] &&
      setSelectedNamespace(
        currentSlice['spec']['namespaceIsolationProfile']['allowedNamespaces']
      );
  }, []);
  // propsData = currentSlice?.metadata?.name;
  console.log('(currentSlice?.metadata?.name', currentSlice?.metadata?.name);
  useEffect(() => {
    setBreadcrumb(currentSlice?.metadata?.name);
    const currentSliceData = currentSlice?.metadata?.name;
    localStorage.setItem('currentSlice', currentSliceData);

    return () => {
      localStorage.removeItem('currentSlice');
    };
  }, [currentSlice?.metadata?.name]);

  const addToSlice = () => {
    let updatedSlice = { ...currentSlice };
    if (selectedNamespace && selectedNamespace.length > 0) {
      updatedSlice['spec']['namespaceIsolationProfile']['allowedNamespaces'] =
        selectedNamespace;
    } else {
      delete updatedSlice['spec']['namespaceIsolationProfile'][
        'allowedNamespaces'
      ];
    }

    // ISOLATION TOGGLE STATE PERSIST
    updatedSlice['spec']['namespaceIsolationProfile']['isolationEnabled'] =
      switchFlag;

    dispatch(setCurrentSlice(updatedSlice));
    //dispatch(updateSnackbarSuccess(true, "Namespace added successfully"));

    history.push({
      pathname: '/manage-namespaces/isolate-namespaces/add/2',
      state: {
        legit: true,
      },
    });
  };

  const searchNamespace = (event) => {
    setSearchText(event.target.value);
    setSearch(false);
  };

  // Disabled button status for Add Allow Namespace Button
  const [disableAddBtn, setDisableAddBtn] = useState(true);
  useEffect(() => {
    if (
      (currentSlice['spec']['namespaceIsolationProfile']['isolationEnabled'] &&
        selectedNamespace?.length > 0) ||
      (!currentSlice['spec']['namespaceIsolationProfile']['isolationEnabled'] &&
        selectedNamespace?.length === 0)
    ) {
      setDisableAddBtn(false);
    } else {
      return setDisableAddBtn(true);
    }
  }, [currentSlice, selectedNamespace]);

  // Experimental
  const [isFormChanged, setIsFormChanged] = useState(false);
  const updateFormChangeStat = () => {
    isFormChanged === false && setIsFormChanged(true);
  };

  const [switchFlag, setSwitchFlag] = useState(
    currentSlice?.spec?.namespaceIsolationProfile?.isolationEnabled
  );

  useEffect(() => {
    let updatedSlice = { ...currentSlice };
    if (selectedNamespace && selectedNamespace.length > 0) {
      updatedSlice['spec']['namespaceIsolationProfile']['allowedNamespaces'] =
        selectedNamespace;
    } else {
      delete updatedSlice['spec']['namespaceIsolationProfile'][
        'allowedNamespaces'
      ];
    }

    // ISOLATION TOGGLE STATE PERSIST
    updatedSlice['spec']['namespaceIsolationProfile']['isolationEnabled'] =
      switchFlag;

    dispatch(setCurrentSlice(updatedSlice));
  }, [switchFlag, selectedNamespace]);
  const updateToggleSwitchState = (flag) => {
    // Update prompt flag warning
    updateFormChangeStat();

    // Update switch state
    setSwitchFlag(flag);

    // Clear out the selected namespaces if isolation is toggled off
    if (!flag) setSelectedNamespace([]);
  };

  return (
    <>
      {/* Prompt Dialogue */}
      <Prompt
        message={(location, action) => {
          if (isFormChanged) {
            if (
              location.pathname ===
                '/manage-namespaces/isolate-namespaces/add/2' &&
              action === 'PUSH'
            ) {
              return true;
            } else {
              return 'You have unsaved changes. Do you want to discard them?';
            }
          }
          return true;
        }}
      />

      <Grid
        className="grid-item-main step-grid-item2 isolate-slice-table-s1"
        item
        xs={12}
      >
        <SliceTable
          currentSlice={currentSlice}
          switchFlag={switchFlag}
          setSwitchFlag={(flag) => updateToggleSwitchState(flag)}
        />
      </Grid>

      {/* Namespace Table */}
      <Grid className="grid-item-main step-grid-item2" item xs={12}>
        <div className="step-content">
          <div
            style={{ padding: '26px 40px 20px 40px' }}
            className="step-content-header"
          >
            <Typography
              className="step-title-header"
              gutterBottom
              component="div"
            >
              Allowed Namespaces
            </Typography>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  height: '40px',
                  overflow: 'hidden',
                  border: '1px solid #B6B8D6',
                  marginRight: '24px',
                  borderRadius: '9px',
                }}
              >
                <Paper
                  component="div"
                  sx={{
                    backgroundColor: '#E6E8F3',
                    borderWidth: 0,
                    borderColor: 'transparent',
                  }}
                  style={{ borderWidth: 0 }}
                >
                  <Select
                    style={{
                      height: '120%',
                      borderWidth: 0,
                      border: 'none',
                      marginTop: '-4px',
                      marginLeft: '-2px',
                    }}
                    value={searchBy}
                    onChange={(e) => setSearchBy(e.target.value)}
                    renderValue={(value) =>
                      value.charAt(0).toUpperCase() + value.slice(1)
                    }
                  >
                    <MenuItem value="namespace" style={{ borderWidth: 0 }}>
                      Search by Namespace
                    </MenuItem>
                    <MenuItem value="cluster" style={{ borderWidth: 0 }}>
                      Search by Cluster name
                    </MenuItem>
                  </Select>
                </Paper>
                <Paper
                  component="form"
                  elevation={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 250,
                    borderRadius: '0 5px 5px 0',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    onChange={searchNamespace}
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                  <IconButton
                    className="search-icon"
                    onClick={() => setSearch(true)}
                    type="submit"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <NextButton
                size="large"
                onClick={addToSlice}
                variant="contained"
                sx={{ height: '40px', padding: '10px 34px' }}
                disabled={
                  !(
                    (currentSlice['spec']['namespaceIsolationProfile'][
                      'isolationEnabled'
                    ] &&
                      selectedNamespace?.length > 0) ||
                    (!currentSlice['spec']['namespaceIsolationProfile'][
                      'isolationEnabled'
                    ] &&
                      selectedNamespace?.length === 0)
                  )
                }
              >
                Add Allowed Namespaces
              </NextButton>
            </div>
          </div>
          <div style={{ display: 'flex' }} className="step-main-content">
            <NamespaceTable
              selectedNamespace={selectedNamespace}
              searchText={searchText}
              setSelectedNamespace={(selectedArr) =>
                setSelectedNamespace(selectedArr)
              }
              search={search}
              searchBy={searchBy}
              updateFormChangeStat={updateFormChangeStat}
              isolationFlag={switchFlag}
            />
            <SelectedNamespace
              selectedNamespace={selectedNamespace}
              setSelectedNamespace={(selectedArr) =>
                setSelectedNamespace(selectedArr)
              }
            />
          </div>
        </div>
      </Grid>
    </>
  );
}
