import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Typography, Select } from '@mui/material';
import { ReactComponent as ArrowDown } from '../../assets/icons/Caret.svg';
import { useRef } from 'react';

export default function MetricsDropdown({
  styleWrapper = {},
  label,
  width,
  options,
  activeOption,
  compareOptionKey,
  handleUpdateActiveOption,
  placeholder = 'Select Cluster',
  clearSelected = false,
  renderValue = (val) => val,
}) {
  const typographyRef = useRef(null)

  return (
    <div style={{ cursor: 'pointer', ...styleWrapper }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: '1px solid #b6b8d6',
          borderRadius: '4px',
          background: '#ffffff',
          width: width ? width : 'auto',
          minWidth: width || 'none',
          maxWidth: width || 'none',
          height: '36px',
        }}
      >
        <Typography
          ref={typographyRef}
          style={{
            background: '#E6E8F3',
            height: '100%',
            padding: '0 0.5rem',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: 500,
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px',
          }}
        >
          {label}
        </Typography>

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Select
            value={
              activeOption && activeOption[compareOptionKey]
                ? activeOption[compareOptionKey]
                : placeholder
            }
            onChange={(event, newValue) => {
              handleUpdateActiveOption(
                options.find(
                  (opt) => opt[compareOptionKey] === event.target.value
                )
              );
            }}
            renderValue={(selected) => {
              if (selected === placeholder) {
                return <b>{placeholder}</b>
              }
              return renderValue(selected)
            }}
            id={`${label}-list-autocomplete`}
            sx={{
              fontSize: '12px',
              lineHeight: '15px',
              width: '100%',
              height: '100%',
              fontWeight: 500,
              maxWidth: width ? (typographyRef ? +width.split('px')[0] - typographyRef?.current?.clientWidth : width) : 'none',
              "& .MuiSelect-select": { padding: '10px 16px' },
              "& .MuiOutlinedInput-notchedOutline": { border: 0 }
            }}
            IconComponent={(props) => (
              <ArrowDown
                style={{
                  position: 'absolute',
                  top: '9px',
                  right: '8px',
                  bottom: '9px',
                }}
                {...props}
              />
            )}
          >
            {options &&
              options.map((el) => {
                return (
                  <MenuItem value={el[compareOptionKey]}>
                    {el[compareOptionKey]}
                  </MenuItem>
                );
              })}
          </Select>
        </div>
      </div>
    </div>
  );
}
