import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCurrentSliceRoleList } from '../../../actions/RbacActions';
import { keyMap } from '../../../utils/keyMap';
import ViewRoleModel from '../Steps/AssignToNamespace/ViewRoleModel';
// import { ReactComponent as Error } from './Error.svg';
import ErrorIcon from '@mui/icons-material/Error';
import './index.css';

const Row = (props) => {
  const { slice, checked, step } = props;
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const [clusterDetails, setClusterDetails] = React.useState({});
  const [roleList, setRoleList] = React.useState('');
  const [openViewModal, setOpenViewModal] = React.useState(false);

  const handleRadioChange = () => {
    const bindings = slice.metadata?.bindings;

    let tempRoles = [];
    if (bindings) {
      bindings.forEach((binding) => {
        const { roleRef } = binding;
        if (roleRef.kind !== keyMap.SliceRoleTemplate) {
          tempRoles.push(binding);
        }
      });
    }
    dispatch(setCurrentSliceRoleList({ ...slice, importk8Roles: tempRoles }));
  };

  const handleViewAll = () => {
    if (step === 0) {
      handleRadioChange();
    }
    setOpenViewModal(true);
  };

  useEffect(() => {
    let role = [];
    slice?.metadata?.bindings?.map((binding) => {
      if (binding['roleRef']?.name) {
        role.push(binding['roleRef']?.name);
      }
    });
    setRoleList(role.join(', '));

    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((clusterName, key) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == clusterName
        );
        list[clusterName] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion,
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });

      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  const getErrorMessage = () => {
    if (slice.metadata?.status?.roleRefConditions) {
      return (
        <List
          sx={{
            '& ul': { padding: 0 },
            listStyleType: 'disc',
          }}
          subheader={<li />}
        >
          {slice.metadata.status.roleRefConditions?.map((error, idx) => (
            <ListItem key={idx}>
              <li>{error?.message}</li>
            </ListItem>
          ))}
        </List>
      );
    }
    return null;
  };
  // console.log(window.location.hash);
  localStorage.setItem('currentSlice', slice?.metadata?.name);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell className="rbac-padding-cell-body" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ padding: 0 }}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ fontSize: '2.4rem' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ fontSize: '2.4rem' }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          scope="row"
          style={{
            width: '29%',
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '24px 0px 24px 24px',
          }}
        >
          <span>
            {step === 0 ? (
              <>
                <Radio
                  checked={checked}
                  onClick={handleRadioChange}
                  name="slice-row"
                  inputProps={{ 'aria-label': 'selected slice row' }}
                  sx={{
                    color: '#12153E',
                    padding: '0px',
                    '&.Mui-checked': {
                      color: '#12153E',
                    },
                  }}
                />
                <span style={{ marginLeft: '5px' }}>
                  {slice?.metadata?.name}
                </span>
              </>
            ) : (
              <span>{slice?.metadata?.name}</span>
            )}

            {slice?.metadata?.status?.roleRefConditions && (
              <Tooltip title={getErrorMessage()}>
                <span style={{ top: '4px', left: '6px', position: 'relative' }}>
                  <ErrorIcon fontSize="small" color="error" />
                </span>
              </Tooltip>
            )}
          </span>
        </TableCell>
        <TableCell
          style={{
            width: '29%',
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '24px 0px 24px 24px',
          }}
        >
          {slice.spec?.sliceType}
        </TableCell>
        <TableCell
          style={{
            width: '20%',
            // textAlign: "center",
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            // textAlign: "center",
            color: '#12153E',
            padding: '24px 0px 24px 24px',
          }}
        >
          {slice?.metadata?.annotations?.desc}
        </TableCell>
        <TableCell
          className="rbac-padding-cell-body rbac-table-cell"
          scope="row"
          style={{
            width: '16%',
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '24px 0px 24px 24px',
            width: step === 0 ? '46%' : 'auto',
          }}
        >
          {roleList ||
          window.location.hash.includes('#/manage-rbacs/add/3') ||
          window.location.hash.includes('#/manage-rbacs/add/4') ? (
            <Link
              underline="hover"
              style={{ color: '#2868F3', cursor: 'pointer' }}
              onClick={handleViewAll}
            >
              View all
            </Link>
          ) : (
            <Typography
              style={{
                fontFamily: 'Bai Jamjuree',
                fontSize: '16px',
                fontWeight: '500',
                color: '#81839A',
                fontStyle: 'italic',
              }}
            >
              No Roles Assigned
            </Typography>
          )}
          {/* {Location.pathname.includes()} */}
        </TableCell>
      </TableRow>
      {/* Collapsable Table */}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: open ? 10 : 0,
            paddingTop: open ? 10 : 0,
            transition: '0.3s ease-in-out',
          }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex', justifyContent: 'center' }}>
              <Table
                size="small"
                style={{ width: '75%' }}
                aria-label="purchases"
              >
                <TableHead
                  className="table-head"
                  sx={{ borderColor: 'transparent' }}
                >
                  <TableRow
                    style={{ border: '1px solid #D9DFEE', fontWeight: '600' }}
                  >
                    <TableCell className="ft-12 txt-bold">CLUSTERS</TableCell>
                    <TableCell className="ft-12 txt-bold">
                      DEPLOYMENT LOCATION
                    </TableCell>
                    <TableCell className="ft-12 txt-bold">CLOUD TYPE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slice.spec?.clusters &&
                    slice.spec?.clusters.map((cluster, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className="ft-16 txt-bold"
                            style={{ borderLeft: '1px solid #D9DFEE' }}
                          >
                            {cluster}
                          </TableCell>
                          <TableCell className="ft-16 txt-bold">
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster]['location']}
                          </TableCell>
                          <TableCell
                            className="ft-16 txt-bold"
                            style={{ borderRight: '1px solid #D9DFEE' }}
                          >
                            {clusterDetails[cluster] &&
                              clusterDetails[cluster][
                                'cloudType'
                              ]?.toUpperCase()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ViewRoleModel open={openViewModal} setOpenViewModal={setOpenViewModal} />
    </React.Fragment>
  );
};

export default Row;
