import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'underscore';
import {
  setAffinityApiAction,
  setAffinityProfiles,
  setCurrentSliceAffinity,
} from '../../../actions/NodeAffinity.actions';
import {
  selectAffinityProfiles,
  selectCurrentSliceAffinity,
} from '../../../selectors/NodeAffinity.selector';
import DeleteModal from '../Generic/DeleteModal';
import Fallback from '../Generic/Fallback';
import { nodeAffinityApiActions } from '../utils';
import './index.css';

// [ ] increase modals width
// [ ] search namespaces step 2
// [ ] search selected nsmespace in step 2 right side
// [ ] search selected nsmespace in step 2 right side

export default function SelectedNode() {
  const [clearAllDialog, setClearAllDialog] = React.useState(false);
  const [clearNsLabel, setClearNsLabel] = React.useState(null);
  const [clearClusterLabels, setClearClusterLabels] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const affinity_profiles = useSelector((state) =>
    selectAffinityProfiles(state)
  );
  const currentSliceAffinity = useSelector(selectCurrentSliceAffinity);

  const handleClearAll = () => {
    dispatch(setAffinityApiAction(nodeAffinityApiActions.delete));
    dispatch(setAffinityProfiles([]));
    dispatch(
      setCurrentSliceAffinity({
        ...(currentSliceAffinity ? currentSliceAffinity : {}),
        spec: {
          ...(currentSliceAffinity?.spec ? currentSliceAffinity?.spec : {}),
          nodeAffinityProfiles: [],
        },
      })
    );
    history.push('/assign-nodes/3');
  };

  const openDialog = () => {
    setClearAllDialog(true);
  };

  const closeDialog = () => {
    setClearAllDialog(false);
  };

  const removeSelectedClusterNode = (cluster, label) => {
    if (clearNsLabel) {
      const { cluster, label } = clearNsLabel;
      dispatch(
        setAffinityProfiles(
          affinity_profiles.map((profile) => {
            if (profile.cluster === cluster) {
              return {
                ...profile,
                nodeAffinityRules: profile.nodeAffinityRules.map((rule) => {
                  // remove from all namespaces
                  // if (rule.namespace === namespace) {
                  return {
                    ...rule,
                    nodeSelectorLabels: rule.nodeSelectorLabels.filter(
                      (selector_label) => !_.isEqual(selector_label, label)
                    ),
                    // };
                  };
                  return rule;
                }),
              };
            }
            return profile;
          })
        )
      );
      setClearNsLabel(null);
    }
  };

  const clearLabels = () => {
    const cluster = clearClusterLabels;
    dispatch(
      setAffinityProfiles(
        affinity_profiles.map((profile) => {
          if (profile.cluster === cluster) {
            return {
              ...profile,
              nodeAffinityRules: profile.nodeAffinityRules.map((rule) => ({
                ...rule,
                nodeSelectorLabels: [],
              })),
            };
          }
          return profile;
        })
      )
    );
    setClearClusterLabels(null);
  };

  const shouldFallBack = !affinity_profiles.some((profile) =>
    profile.nodeAffinityRules.some(
      (rule) => rule.nodeSelectorLabels?.length > 0
    )
  );

  return (
    <>
      <Box className="selected-namespace-container">
        <Box
          sx={{
            height: '76px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="selected-namespace-header"
        >
          <Box
            item
            sx={{
              p: 0,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '15px',
              color: '#12153E',
            }}
          >
            SELECTED LABELS
          </Box>
          <Grid sx={{ padding: 0 }} item md={3}>
            <Button
              onClick={openDialog}
              sx={{
                padding: 0,
              }}
            >
              Clear all
            </Button>
          </Grid>
        </Box>

        {shouldFallBack ? (
          <Fallback text="No Node Labels assigned" />
        ) : (
          <Stack
            sx={{
              overflowY: 'auto',
              maxHeight: 512,
            }}
          >
            {affinity_profiles.map((profile) => {
              const isSliceLevel = profile.cluster === '*';
              const shouldLoopOver = profile.nodeAffinityRules?.some(
                (rule) =>
                  rule.namespace === '*' && rule.nodeSelectorLabels?.length > 0
              );

              return shouldLoopOver ? (
                <Box mt={2} p={1}>
                  <Box
                    display={'flex'}
                    sx={{
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      <span>{isSliceLevel ? '' : profile.cluster}</span>
                    </Box>
                    {!isSliceLevel ? (
                      <Box>
                        <Button
                          onClick={() => setClearClusterLabels(profile.cluster)}
                        >
                          Clear
                        </Button>
                      </Box>
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {profile.nodeAffinityRules
                      .filter((rule) => rule.namespace === '*')
                      .map((rule) => {
                        return rule.nodeSelectorLabels?.map((label, l_idx) => {
                          return (
                            <div
                              key={l_idx}
                              className="selected-namespace-item"
                            >
                              <label>{`${label.key}:${label.values[0]}`}</label>
                              <CloseIcon
                                onClick={() =>
                                  setClearNsLabel({
                                    cluster: profile.cluster,
                                    label: label,
                                  })
                                }
                                color="primary"
                                fontSize="small"
                                sx={{
                                  fontSize: '13px',
                                  cursor: 'pointer',
                                  margin: '0 5px',
                                }}
                              />
                            </div>
                          );
                        });
                      })}
                  </Box>
                </Box>
              ) : null;
            })}
          </Stack>
        )}
      </Box>

      {clearAllDialog && (
        <DeleteModal
          open={true}
          handleClose={closeDialog}
          onAccept={handleClearAll}
          title="Do you want to clear all the labels from the slice?"
          subtitle="If you clear all the labels, node assignment will be deleted."
        />
      )}

      {clearNsLabel && (
        <DeleteModal
          open={true}
          handleClose={() => setClearNsLabel(null)}
          onAccept={removeSelectedClusterNode}
          title="Do you want to delete the selected label?"
          // subtitle="If you delete the label, the label will be deleted from all the namespaces in the cluster."
        />
      )}
      {clearClusterLabels && (
        <DeleteModal
          open={true}
          handleClose={() => setClearClusterLabels(null)}
          onAccept={clearLabels}
          title="Do you want to clear all the labels for the selected cluster?"
          subtitle="If you clear all the labels, all the labels from all the namespaces in the selected cluster will be cleared."
        />
      )}
    </>
  );
}
