import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";

export const RegularInputLimit = ({
  handleChange,
  handleBlur,
  value,
  startText,
  dataTestId,
  name,
  error,
}) => {
  return (
    <Tooltip
      classes={{
        popper: "custom-error-tooltip",
        arrow: "custom-error-tooltip",
      }}
      arrow
      open={!!error}
      title={
        <>
          <InfoIcon
            sx={{
              color: "#fff",
              marginRight: "4px",
              fontSize: "12px",
            }}
          />
          {error}
        </>
      }
    >
      <OutlinedInput
        error={!!error}
        type="number"
        sx={{
          padding: "4px 4px 4px 12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2868F3",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px",
          },
        }}
        variant="outlined"
        name={name}
        inputProps={{
          "data-testid": dataTestId,
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        startAdornment={
          <InputAdornment sx={{ marginRight: 0 }} position="start">
            <Box
              sx={{
                fontSize: "12px",
              }}
            >
              {startText}
            </Box>
          </InputAdornment>
        }
        value={value}
      />
    </Tooltip>
  );
};

export const DivisorInputLimit = ({
  handleChange,
  handleBlur,
  value,
  startText,
  dataTestId,
  name,
  error,
  label,
  gap = "8px",
}) => {
  return (
    <Tooltip
      classes={{
        popper: "custom-error-tooltip",
        arrow: "custom-error-tooltip",
      }}
      arrow
      open={!!error}
      title={
        <>
          <InfoIcon
            sx={{
              color: "#fff",
              marginRight: "4px",
              fontSize: "12px",
            }}
          />
          {error}
        </>
      }
    >
      <OutlinedInput
        error={!!error}
        type="number"
        sx={{
          mt: 1,
          px: 1,
          py: 0,
          padding: "4px 4px 4px 12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2868F3",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px",
          },
          width: "200px",
        }}
        variant="outlined"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          "data-testid": dataTestId,
        }}
        startAdornment={
          <InputAdornment sx={{ marginRight: 0 }} position="start">
            <Box fontSize={"12px"} marginRight={gap}>
              {label}
            </Box>
            <Box fontSize={"12px"}>{startText}</Box>
          </InputAdornment>
        }
        value={value}
      />
    </Tooltip>
  );
};

export const DividendInputLimit = ({
  handleChange,
  handleBlur,
  value,
  endText,
  dataTestId,
  name,
  error,
  label,
  gap = "8px",
}) => {
  return (
    <Tooltip
      classes={{
        popper: "custom-error-tooltip",
        arrow: "custom-error-tooltip",
      }}
      arrow
      open={!!error}
      title={
        <>
          <InfoIcon
            sx={{
              color: "#fff",
              marginRight: "4px",
              fontSize: "12px",
            }}
          />
          {error}
        </>
      }
    >
      <OutlinedInput
        error={!!error}
        type="number"
        sx={{
          mt: 1,
          px: 1,
          py: 0,
          padding: "4px 4px 4px 12px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2868F3",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px",
          },
          width: "200px",
        }}
        variant="outlined"
        className="container-input"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          "data-testid": dataTestId,
        }}
        startAdornment={
          <InputAdornment sx={{ marginRight: 0 }} position="start">
            <Box fontSize={"12px"} marginRight={gap}>
              {label}
            </Box>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            position="end"
            sx={{
              mr: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              / {endText}
            </Typography>
          </InputAdornment>
        }
        value={value}
      />
    </Tooltip>
  );
};
