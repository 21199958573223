import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FilterButton } from "../Commons/IconButtons";
import CollapseTable from "./collapsibleTable";
import TableFilters from "./filters";
import PodTable from "./PodTable";
import { connect } from "react-redux";
import { Grid, Paper } from "@mui/material";
import { FieldContainer, FieldContent, FieldHeading } from "../Body/BodyStyles";
import { ScrollBarStyle } from "../../AppStyles";
import {
  getAllClustersForSlice,
  getAllServiceExportsForSlice,
  getAllServiceImportsForSlice,
} from "../../actions/SliceActions";

const Slices = styled.div`
  margin: 0px 10px;
  /* padding: 20px; */
  /* box-shadow: 1px 1px 4px rgba(71, 56, 128, 0.5); */
  width: 25%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  /* border-right: solid silver 1px; */
  padding: 0rem 1rem;
  ${ScrollBarStyle}
`;

const SliceData = styled(Paper)`
  margin: 0 0 20px 0;
  padding: 5px;
  cursor: pointer;
  background: ${({ active }) =>
    active
      ? "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)"
      : "none"};
  color: ${({ active }) => (active ? "#ffffff" : "black")};
`;

const SliceName = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

const SliceInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  /* width: 100%; */
`;

const ClusterDataWrapper = styled(Paper)`
  padding: 0.5rem;
  /* border: 1px solid; */
`;

const ClusterData = styled.div``;

const NoCluster = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Clusters = styled.div`
  width: 75%;
  margin: 0px 10px;
  /* padding: 5px; */
  box-shadow: 1px 1px 4px rgba(71, 56, 128, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  row-gap: 1rem;
`;

const FilterButtonWrapper = styled.div`
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
`;

const ClearFilter = styled.span`
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
`;
const TableTitle = styled.div`
  font-weight: bold;
  /* color: #a9a9a9; */
`;

const SliceTable = (props) => {
  const [clusterData, setClusterData] = useState([]);

  const [filters, setFilters] = useState({});

  const [showFilter, setShowFilter] = useState(false);

  const [serviceFilters, setServiceFilters] = useState({});

  const [showServiceFilter, setShowServiceFilter] = useState(false);

  const [endpointFilters, setEndpointFilters] = useState({});

  const [showEndpointFilter, setShowEndpointFilter] = useState(false);

  const [portFilters, setPortFilters] = useState({});

  const [showPortFilter, setShowPortFilter] = useState(false);

  const [serviceAPIData, setServiceAPIData] = useState([]);

  const [serviceDiscIndex, setServiceDiscIndex] = useState(0);

  const [serviceData, setServiceData] = useState({
    services: [],
    endpoints: [],
    ports: [],
  });

  useEffect(() => {
    if (props?.sliceList?.[0]?.metadata?.name)
      changeIndex(0, props?.sliceList?.[0]?.metadata?.name);
  }, [props.sliceList]);

  const changeIndex = async (index, id) => {
    // async function getServiceData() {
    //   const { services } = await props.getSliceServices(
    //     props.sliceList[index].sliceName
    //   );
    //   setServiceAPIData(services);
    // }
    let response = await props.getAllClustersForSlice(
      props?.sliceList?.[index]?.metadata.name
    );
    await props.getAllServiceExportsForSlice(
      props?.sliceList?.[index]?.metadata.name
    );
    await props.getAllServiceImportsForSlice(
      props?.sliceList?.[index]?.metadata.name
    );
    // getServiceData();
    setClusterData(Object.values(response?.items || []));
    // async function callGetData() {
    //   await getData(data[index]);
    // }
    // callGetData();
    const { setIndex } = props;
    setIndex(index, id);
  };

  const setServiceDiscData = () => {
    const { services, endpoints, ports } = serviceData;
    const serData = {
      services: [],
      endpoints: [],
      ports: [],
    };
    serviceAPIData.forEach((ele, index) => {
      serData.services = [...serData.services, ele];
      if (index === serviceDiscIndex) {
        serData.endpoints = [...serData.endpoints, ...ele.endpoints];
        serData.ports = [...serData.ports, ...ele.ports];
      }
    });
    setServiceData({
      services,
      ports,
      endpoints,
      ...serData,
    });
  };

  useEffect(() => {
    setServiceDiscData(serviceAPIData);
  }, [serviceDiscIndex, serviceAPIData]);

  useEffect(() => {
    setServiceFiltersFunc();
  }, [serviceData]);

  const setServiceFiltersFunc = () => {
    const serviceFilters = getUnique(serviceData.services, {
      namespace: "Name_Space",
      loadBalancingPolicy: "Load_Balancing_Policy",
    });
    setServiceFilters(serviceFilters);
    const endpointFilters = getUnique(serviceData.endpoints, {
      clusterId: "Cluster_Id",
    });
    setEndpointFilters(endpointFilters);
    const portFilters = getUnique(serviceData.ports, {
      protocol: "Protocol",
    });
    setPortFilters(portFilters);
  };

  const getUnique = (data, filterObj) => {
    const result = {};
    Object.keys(filterObj).forEach((ele) => {
      const filterType = [...new Set(data.map((eleData) => eleData[ele]))];
      result[filterObj[ele]] = { selected: [], values: filterType };
    });
    return result;
  };

  const setSelectedFilter = (type, filter) => {
    const selectedFilterData = showFilter
      ? filters
      : showServiceFilter
      ? serviceFilters
      : showEndpointFilter
      ? endpointFilters
      : showPortFilter
      ? portFilters
      : {};
    const data = [...selectedFilterData[type].selected];
    const index = data.indexOf(filter);
    if (index > -1) data.splice(index, 1);
    else data.push(filter);
    const filterData = {
      ...selectedFilterData,
      [type]: {
        ...selectedFilterData[type],
        selected: data,
      },
    };
    const res = showFilter
      ? setFilters(filterData)
      : showServiceFilter
      ? setServiceFilters(filterData)
      : showEndpointFilter
      ? setEndpointFilters(filterData)
      : showPortFilter
      ? setPortFilters(filterData)
      : null;
  };

  const data = props.sliceList;

  const formatText = (filter) => {
    let fil = filter.split("_");
    fil.forEach((ele, index) => {
      if (index === 0) fil[index] = ele.toLowerCase();
      else fil[index] = ele.charAt(0)?.toUpperCase() + ele.slice(1);
    });
    fil = fil.join("");
    if (fil === "nameSpace") fil = "namespace";
    return fil;
  };

  const getFilteredData = (data, filterObj) => {
    return data.filter((ele) => {
      const data = Object.keys(filterObj).map((filter) => {
        const fil = formatText(filter);
        if (filterObj[filter].selected.length === 0) return true;
        if (filterObj[filter].selected.includes(ele[fil])) return true;
        return false;
      });
      if (data.every((ele) => ele === true)) return ele;
    });
  };

  const getFilterApplied = (filData) => {
    return Object.keys(filData)
      .map((filter) => {
        if (filData[filter].selected.length > 0) return true;
        return false;
      })
      .some((ele) => ele === true);
  };

  const clusters = getFilteredData(clusterData, filters);
  const filterApplied = getFilterApplied(filters);

  const clearFilters = (clearfilterData, setData) => {
    const data = Object.keys(clearfilterData).map((filter) => ({
      [filter]: { selected: [], values: clearfilterData[filter].values },
    }));
    let filtersData = {};
    data.forEach((ele) => {
      filtersData = { ...filtersData, ...ele };
    });
    setData(filtersData);
  };

  const services = getFilteredData(serviceData.services, serviceFilters);
  const serviceFilterApplied = getFilterApplied(serviceFilters);

  let showMainFilter;
  if (showFilter || showServiceFilter || showPortFilter || showEndpointFilter) {
    showMainFilter = true;
  }

  let mainFilterData = showFilter
    ? filters
    : showServiceFilter
    ? serviceFilters
    : showPortFilter
    ? portFilters
    : showEndpointFilter
    ? endpointFilters
    : {};

  const setAllFilters = () => {
    setShowServiceFilter(false);
    setShowEndpointFilter(false);
    setShowPortFilter(false);
    setShowFilter(false);
  };

  const nameSpace = clusters;

  function compare(a, b) {
    if (a.clusterName < b.clusterName) {
      return -1;
    }
    if (a.clusterName > b.clusterName) {
      return 1;
    }
    return 0;
  }
  if (nameSpace.length > 0) {
    nameSpace.sort(compare);
  }

  return (
    <>
      {showMainFilter && (
        <TableFilters
          showMainFilter={showMainFilter}
          setAllFilters={setAllFilters}
          mainFilterData={mainFilterData}
          setSelectedFilter={setSelectedFilter}
        />
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: "row",
          height: "calc(100% - 64px)",
          overflow: "auto",
          padding: "10px 0px",
        }}
      >
        <Slices>
          {data &&
            data?.map?.((slice, index) => (
              <SliceData
                active={props.indexSelected.id === slice.metadata.name}
                onMouseDown={() => changeIndex(index, slice.metadata.name)}
                elevation={4}
                data-cy={`av-serviceDiscovery-${index + 1}`}
                key={`av-serviceDiscovery-${index + 1}`}
              >
                <SliceName>{slice.metadata.name || "No Name"}</SliceName>
                <SliceInfo>
                  <FieldContainer>
                    <FieldHeading
                      color={
                        props.indexSelected.id === slice.sliceId
                          ? "#d6e3f7"
                          : "grey"
                      }
                    >
                      Type
                    </FieldHeading>
                    <FieldContent>{slice.spec.sliceType}</FieldContent>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading
                      color={
                        props.indexSelected.id === slice.sliceId
                          ? "#d6e3f7"
                          : "grey"
                      }
                    >
                      Slice GW Type
                    </FieldHeading>
                    <FieldContent>
                      {slice.spec.sliceGatewayProvider.sliceGatewayType}
                    </FieldContent>
                  </FieldContainer>
                  <FieldContainer>
                    <FieldHeading
                      color={
                        props.indexSelected.id === slice.sliceId
                          ? "#d6e3f7"
                          : "grey"
                      }
                    >
                      Slice QOS Profile
                    </FieldHeading>
                    <FieldContent>Custom</FieldContent>
                  </FieldContainer>
                </SliceInfo>
              </SliceData>
            ))}
        </Slices>
        <Grid
          container
          direction="column"
          height="100%"
          flexWrap="nowrap"
          overflow="auto"
          item
          xs
          component={Paper}
          p={2}
          gap={2}
        >
          {clusters.length > 0 ? (
            <>
              <ClusterDataWrapper>
                <FilterButtonWrapper>
                  <TableTitle>Slice PODs</TableTitle>
                </FilterButtonWrapper>
                <ClusterData>
                  <PodTable
                    slice={data[props.indexSelected.index]}
                    clusters={clusters}
                  />
                </ClusterData>
              </ClusterDataWrapper>
              <ClusterDataWrapper>
                <FilterButtonWrapper>
                  <TableTitle>Service Imports & Exports</TableTitle>
                </FilterButtonWrapper>
                <ClusterData>
                  <CollapseTable />
                </ClusterData>
              </ClusterDataWrapper>
            </>
          ) : (
            <NoCluster>No attached clusters</NoCluster>
          )}
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = ({
  slices: {
    sliceGateways: { gateways },
    sliceClusters,
  },
}) => ({ gateways, clusters: sliceClusters });

const mapDispatchToProps = {
  getAllClustersForSlice,
  getAllServiceExportsForSlice,
  getAllServiceImportsForSlice,
};
export default connect(mapStateToProps, mapDispatchToProps)(SliceTable);
