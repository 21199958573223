import { Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
const NavBarItemContainer = styled.div`
  background-color: ${(props) =>
    props.selected ? '#2868F3 !important' : 'transparent'};
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 12px;
  height: 3.5rem;
  align-items: center;
`;
const WhiteBlob = styled.div`
  width: 8px;
  height: 62.5%;
  background: ${(props) =>
    props.selected ? 'white !important' : 'transparent'};
  margin-left: -3px;
  margin-right: ${(props) => (props.isNamespace ? '10px' : '0')};
  border-radius: 10px;
`;
export function NavBarItem({
  selected,
  isNamespace = false,
  isSlice = false,
  Icon,
  label,
  onClick = () => { },
  open = true,
}) {
  return (
    <NavBarItemContainer selected={selected} onClick={onClick} open={open}>
      {<WhiteBlob isNamespace={isNamespace} selected={selected} />}
      <Icon
        width="23px"
        style={{
          color: selected ? '#FFF' : '#D9DAE6',
          fill: selected ? '#FFF' : '#D9DAE6',
          transform: isSlice ? 'rotate(180deg)' : null,
        }}
      />
      {open && (
        <Typography fontSize="14px" color={selected ? '#FFF' : '#D9DAE6'}>
          {label}
        </Typography>
      )}
    </NavBarItemContainer>
  );
}
const SubItemContainer = styled(NavBarItemContainer)`
  padding: 1rem;
  height: 0.8rem;
  width: unset;
  padding-left: 25%;
  color: ${(props) =>
    props.selected ? '#fff' : '#D9DAE6'};

`;
export const NavBarSubItem = ({
  selected,
  label,
  Icon,
  onClick,
  open = true,
}) => {
  return (
    <SubItemContainer selected={selected} onClick={onClick} open={open}>
      {Icon}
      {open && (
        <Typography fontSize="14px" color={selected ? '#ffffff' : '#D9DAE6'}>
          {label}
        </Typography>
      )}
    </SubItemContainer>
  );
};
