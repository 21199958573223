import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Chip,
  CircularProgress,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { deleteSliceCustomObject } from '../../actions/ConnectClusterActions';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#784af4',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props) {
  const { status } = props;
  switch (status) {
    case 'STARTED':
      return <CircularProgress size={24} />;
    case 'SUCCESS':
      return <CheckCircleOutlineIcon color="success" />;
    case 'FAILED':
      return <CancelIcon color="error" />;
    default:
      return (
        <CheckCircleOutlineIcon
          sx={{
            color: '#d1d5db',
          }}
        />
      );
  }
}

const SliceDeletionConfirmModal = ({ sliceName, handleClose, onOk }) => {
  const [confirmed, setConfirmed] = useState(false);
  const [okBtnDisabled, setOkBtnDisabled] = useState(true);

  const dispatch = useDispatch();

  const [steps, setSteps] = useState([]);

  const slideDeletionChip = () => {
    const step = steps[steps.length - 1];
    if (step?.status === 'SUCCESS') {
      return <Chip label="Success" color="success" size="small" />;
    }
    if (step?.status === 'FAILED') {
      return <Chip label="Failed" color="error" size="small" />;
    }
  };

  const confirmDelete = () => {
    setConfirmed(true);
    const hostname = window.location.hostname;
    const port = window.location.port;
    let socket_url = `wss://${hostname}/delete-slice`;
    if (port) {
      socket_url = `wss://${hostname}:${port}/delete-slice`;
    }

    const socket = io(socket_url, {
      secure: true,
      transports: ['websocket', 'polling'],
    });

    socket.on('connect', () => {
      dispatch(deleteSliceCustomObject(sliceName, socket.id));
    });

    socket.on('steps', function(data) {
      if (data?.data) {
        setSteps(data.data);
      }
    });

    socket.on('message', (data) => {
      const { status, entity } = data;
      if (entity.includes('STEP')) {
        setSteps((prev) =>
          prev.map((step) => {
            if (step.key === entity) {
              return {
                ...step,
                status: status,
              };
            }
            return step;
          })
        );
      }
    });

    socket.on('disconnect', () => {
      setSteps((prev) => {
        return prev.map((step) => ({
          ...step,
          status: step.status === 'SUCCESS' ? step.status : 'FAILED',
        }));
      });
      setOkBtnDisabled(false);
      socket.disconnect();
    });

    socket.on('connect_error', () => {
      setSteps((prev) => {
        return prev.map((step) => ({
          ...step,
          status: step.status ?? 'FAILED',
        }));
      });
      setOkBtnDisabled(false);
      socket.disconnect();
    });
  };

  const onClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs" disableEscapeKeyDown>
      {confirmed ? (
        <>
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <Typography variant="h5">Slice Deletion</Typography>
              {slideDeletionChip()}
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stepper connector={<QontoConnector />} orientation="vertical">
              {steps.map(({ label, status }) => {
                return (
                  <Step key={label}
                    completed={!!status}
                  >
                    <StepLabel
                      StepIconComponent={(props) => (
                        <QontoStepIcon {...props} status={status} />
                      )}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </DialogContent>
          <DialogActions
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onOk}
              className="btn"
              sx={{
                width: 40,
              }}
              disabled={okBtnDisabled}
              variant="contained"
            >
              Ok
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle className="modal-title" id="alert-dialog-title">
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className="modal-desc"
              id="alert-dialog-description"
            >
              Are you sure you want to delete this slice?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel-btn"
              onClick={confirmDelete}
              color="error"
            >
              Delete
            </Button>
            <Button
              className="submit-btn"
              onClick={handleClose}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SliceDeletionConfirmModal;
