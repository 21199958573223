import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { Tab } from "@mui/material";
import styled from "styled-components";
import { connect } from "react-redux";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const thCellStyle = {
  color: "black",
  fontWeight: "600",
  background: "#DEE8FF",
};

const TableTitle = styled.div`
  font-weight: bold;
  color: #a9a9a9;
  margin: 10px 15px;
  font-size: 16px;
`;

const getBox = (typo, tableHeaders, tableBodyKeys, tableBody) => {
  return (
    <Box margin={1}>
      <TableTitle>{typo}</TableTitle>
      <Table size="small">
        <TableHead>
          <TableRow>
            {tableHeaders.map((ele) => (
              <TableCell style={thCellStyle} align="left">
                {ele}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody?.map((tableBodyRow) => (
            <TableRow key={typo}>
              {tableBodyKeys.map((ele) => (
                <TableCell align="left">{tableBodyRow[ele]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.spec.serviceName}
        </TableCell>
        <TableCell align="left">{row.spec.serviceNamespace}</TableCell>
        {/* <TableCell align="left"></TableCell> */}
        <TableCell align="left">
          {moment(row.metadata.creationTimestamp).fromNow()}
        </TableCell>
        {/* <TableCell align="left">
          {moment(row.updatedAtTime).fromNow()}
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {getBox(
              "Endpoints",
              ["POD Name", "Cluster ID", "IP", "URL"],
              ["podName", "cluster", "nsmIp", "dnsName"],
              row.spec.serviceDiscoveryEndpoints
            )}
            {getBox(
              "Ports",
              ["Name", "Port", "Protocol"],
              ["name", "port", "protocol"],
              row.spec.serviceDiscoveryPorts
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function CollapsibleTable({ data }) {
  const rows = data;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={thCellStyle} align="left">
              Service Name
            </TableCell>
            <TableCell style={thCellStyle} align="left">
              Name Space
            </TableCell>
            {/* <TableCell style={thCellStyle} align="left">
              Load Balancing Policy
            </TableCell> */}
            <TableCell style={thCellStyle} align="left">
              Created At
            </TableCell>
            {/* <TableCell style={thCellStyle} align="left">
              Updated At
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.serviceName} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const mapStateToProps = (state) => ({
  data: [
    ...(Array.isArray(state.slices.serviceImports?.items)
      ? state.slices.serviceImports?.items
      : []),
    ...(Array.isArray(state.slices.serviceExports?.items)
      ? state.slices.serviceExports?.items
      : []),
  ],
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleTable);
