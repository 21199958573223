import * as Yup from 'yup';

export const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

export const purifyRQValues = (values) => {
  if (!values) return;

  const purified_values = {};
  if (values.cpu && values.cpu !== '' && values.cpu !== 'm') {
    purified_values.cpu = values.cpu;
  }
  if (values.memory && values.memory !== '' && values.memory !== 'Mi') {
    purified_values.memory = values.memory;
  }

  if (
    values.ephemeralStorage &&
    values.ephemeralStorage !== '' &&
    values.ephemeralStorage !== 'Mi'
  ) {
    purified_values.ephemeralStorage = values.ephemeralStorage;
  }

  if (values.podCount && values.podCount !== '') {
    purified_values.podCount = values.podCount;
  }

  return purified_values;

  // if (!isEmpty(purified_values)) {
  //   return purified_values;
  // }
};

export const nullifyEmptyString = (value, originalValue) => {
  return String(originalValue).trim() === '' ? null : value;
};

export const schema = Yup.object().shape(
  {
    /* slice_container_cpu_request, slice_container_memory_request, slice_container_storage_request
     * is required to validate container_request and are just used to refer, these filds doesnot need any validation
     * they are validated on step 1
     */
    slice_container_cpu_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .label('Slice container CPU request'),
    slice_container_memory_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .label('Slice container memory request'),
    slice_container_storage_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .label('Slice container storage request'),
    pod_count: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .label('Pod Count'),
    cpu_limit: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_cpu_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container CPU limit is entered'
          )
          .required(
            'This value is required when container CPU limit is entered'
          ),
      })
      .label('Cpu Limit'),
    container_cpu_limit: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('cpu_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError('This value is required when CPU limit is entered')
          .max(
            Yup.ref('cpu_limit'),
            'This value should be less than or equal to CPU limit'
          )
          .required('This value is required when CPU limit is entered'),
      })
      .label('Container Cpu Limit'),
    cpu_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_cpu_request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container CPU limit is entered'
          )
          .required(
            'This value is required when container CPU limit is entered'
          ),
      })
      .when('cpu_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('cpu_limit'),
            'This value should be less than or equal to CPU limit'
          )
          .min(
            Yup.ref('container_cpu_limit'),
            'This value should be greater than or equal to container CPU limit'
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Cpu Request'),
    container_cpu_request: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('cpu_request', {
        is: (value) => !!value,
        then: Yup.number()
          .nullable()
          .transform(nullifyEmptyString)
          .max(
            Yup.ref('cpu_request'),
            'This value should be less than or equal to CPU request'
          )
          .test(
            'is-required',
            () =>
              'This value is required when there is no default provided at the Slice',
            (value, context) => {
              if (context?.parent?.slice_container_cpu_request) {
                // if slice container memory request has value this field will be optional
                return true;
              }
              return !!value;
            }
          ),
        // .required(
        //   'container CPU limit is required when CPU limit is entered'
        // ),
      })
      .when('container_cpu_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('cpu_request'),
            'This value should be less than or equal to CPU request'
          )
          .max(
            Yup.ref('container_cpu_limit'),
            'This value should be less than or equal to container CPU limit'
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Container Cpu Request'),
    memory_limit: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_memory_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container memory limit is entered'
          )
          .required(
            'This value is required when container memory limit is entered'
          ),
      })
      .label('Memory Limit'),
    container_memory_limit: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('memory_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError('This value is required when memory limit is entered')
          .max(
            Yup.ref('memory_limit'),
            'This value should be less than or equal to memory limit'
          )
          .required('This value is required when memory limit is entered'),
      })
      .label('Container Memory Limit'),
    memory_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_memory_request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container memory request is entered'
          )
          .required(
            'This value is required when container memory request is entered'
          ),
      })
      .when('memory_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('memory_limit'),
            'This value should be less than or equal to memory limit'
          )
          .min(
            Yup.ref('container_memory_limit'),
            'This value should be greater than or equal to container memory limit'
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Memory Request'),
    container_memory_request: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('memory_request', {
        is: (value) => !!value,
        then: Yup.number()
          .nullable()
          .transform(nullifyEmptyString)
          .max(
            Yup.ref('memory_request'),
            'This value should be less than or equal to memory request'
          )
          .test(
            'is-required',
            () =>
              'This value is required when there is no default provided at the Slice',
            (value, context) => {
              if (context?.parent?.slice_container_memory_request) {
                // if slice container memory request has value this field will be optional
                return true;
              }
              return !!value;
            }
          ),
        // .required(
        //   'container memory limit is required when memory limit is entered'
        // ),
      })
      .when('container_memory_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('memory_request'),
            'This value should be less than or equal to container memory request'
          )
          .max(
            Yup.ref('container_memory_limit'),
            'This value should be less than or equal to container memory limit '
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Container Memory Request'),

    storage_limit: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_storage_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container storage limit is entered'
          )
          .required(
            'This value is required when container storage limit is entered'
          ),
      })
      .label('Storage Request'),
    container_storage_limit: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('storage_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError('This value is required when storage limit is entered')
          .max(
            Yup.ref('storage_limit'),
            'This value should be less than or equal to storage limit'
          )
          .required('This value is required when storage limit is entered'),
      })
      .label('Container Storage Request'),
    storage_request: Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('container_storage_request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when container storage request is entered'
          )
          .required(
            'This value is required when container storage request is entered'
          ),
      })
      .when('storage_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('storage_limit'),
            'This value should be less than or equal to storage limit'
          )
          .min(
            Yup.ref('container_storage_limit'),
            'This value should be greater than or equal to container storage limit'
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Storage Request'),
    container_storage_request: Yup.number()
      .min(0)
      .nullable()
      .transform(nullifyEmptyString)
      .when('storage_request', {
        is: (value) => !!value,
        then: Yup.number()
          .nullable()
          .transform(nullifyEmptyString)
          .max(
            Yup.ref('storage_request'),
            'This value should be less than or equal to storage request'
          )
          .test(
            'is-required',
            () =>
              `This value is required when there is no default provided at the Slice`,
            (value, context) => {
              if (context?.parent?.slice_container_storage_request) {
                return true;
              }
              return !!value;
            }
          ),
      })
      .when('container_storage_limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('storage_request'),
            'This value should be less than or equal to storage request'
          )
          .max(
            Yup.ref('container_storage_limit'),
            'This value should be less than or equal to container storage limit'
          )
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('Container Storage Request'),
  },
  [
    ['cpu_limit', 'container_cpu_limit'],
    ['cpu_limit', 'cpu_request'],
    ['cpu_request', 'container_cpu_request', 'slice_container_cpu_request'],
    ['cpu_request', 'container_cpu_limit'],
    ['container_cpu_limit', 'container_cpu_request'],
    ['memory_limit', 'memory_request'],
    ['memory_limit', 'container_memory_limit'],
    [
      'memory_request',
      'container_memory_request',
      'slice_container_memory_request',
    ],
    ['memory_request', 'container_memory_limit'],
    ['container_memory_limit', 'container_memory_request'],
    ['storage_limit', 'storage_request'],
    ['storage_limit', 'container_storage_limit'],
    [
      'storage_request',
      'container_storage_request',
      'slice_container_cpu_request',
    ],
    ['storage_request', 'container_storage_limit'],
    ['container_storage_limit', 'container_storage_request'],
  ]
);
