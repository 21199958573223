import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNodeLabels } from '../../../selectors/NodeAffinity.selector';
import { stringifyLabel } from './utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': {
    maxWidth: '100%',
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 3, display: 'flex', justifyContent: 'space-between' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon htmlColor="black" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Dialogs({ parentModalOpen, onClose, currentLabel }) {
  const [selectedNode, setSelectedNode] = useState();
  const node_labels = useSelector((state) => selectNodeLabels(state));

  const handleClose = () => {
    onClose?.();
    setSelectedNode('');
  };

  const associatedNode = node_labels
    .reduce((details, label) => [...details, ...label.nodeDetails], [])
    .filter((node) => {
      const [key, value] = Object.entries(currentLabel)[0];
      return node.labels[key] === value;
    });

  const clusterNodes = node_labels.map((cluster) => {
    return {
      ...cluster,
      nodeDetails: cluster.nodeDetails.filter((node) => {
        const [key, value] = Object.entries(currentLabel)[0];
        return node.labels[key] === value;
      }),
    };
  });

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={parentModalOpen}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '620px',
            position: 'relative',
          }}
        >
          <BootstrapDialogTitle
            style={{ fontWeight: '600' }}
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Label details
          </BootstrapDialogTitle>
          <DialogContent
            style={{  paddingBottom: 0  }}
          >
            <div>
              <div style={{ maxWidth: '100%' }}>
                <Typography sx={{ fontSize: '14px' }} gutterBottom>
                  Label name
                </Typography>
                <Typography
                  style={{
                    fontWeight: '600',
                    border: '1px solid lightgrey',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '15px',
                    fontSize: '16px',
                  }}
                  gutterBottom
                  className="truncate"
                >
                  {stringifyLabel(currentLabel)}
                </Typography>
                <Typography sx={{ fontSize: '14px' }} gutterBottom>
                  Associated nodes
                </Typography>
                <div
                  style={{
                    border: '1px solid lightgrey',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '15px',
                  }}
                >
                  {associatedNode?.length > 0 &&
                    clusterNodes.map((cluster) =>
                      cluster.nodeDetails.map((node, idx) => {
                        const isselected = selectedNode?.name === node.name;
                        return (
                          <Box
                            key={idx}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              px: 2,
                              py: 1,
                              justifyContent: 'space-between',
                              backgroundColor: isselected ? '#00048d1f' : '',
                            }}
                          >
                            <Stack>
                              <Box
                                style={{
                                  fontWeight: '600',
                                  fontSize: '16px',
                                }}
                                className="truncate"
                              >
                                {node?.name}
                              </Box>
                              <Box className="truncate">
                                {cluster.clusterName}
                              </Box>
                            </Stack>
                            <Button
                              style={{
                                color: '#2868F3',
                                textTransform: 'none',
                                fontWeight: '600',
                              }}
                              onClick={() => {
                                setSelectedNode(node);
                              }}
                            >
                              View
                            </Button>
                          </Box>
                        );
                      })
                    )}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{paddingTop: '8px', paddingBottom: '16px', padding: '0 24px 16px 24px'}}>
            <Button
              variant="contained"
              sx={{
                background: '#2868F3',
                width: '100%',
                textTransform: 'none',
                height: "50px",
                fontSize: '16px',
                fontWeight: 600
              }}
              autoFocus
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
        {selectedNode && (
          <Box
            style={{
              maxWidth: '50%',
              width: '50%',
            }}
          >
            <div
              style={{
                display: selectedNode ? 'flex' : 'none',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  py: 2.5,
                }}
              >
                <Typography
                  sx={{ fontWeight: '600', fontSize: 20 }}
                  variant="h2"
                >
                  Description
                </Typography>
                <Button
                  style={{
                    color: '#2868F3',
                    textTransform: 'none',
                    fontWeight: '600',
                  }}
                  onClick={() => {
                    setSelectedNode(null);
                  }}
                >
                  Hide
                </Button>
              </Box>
              <div
                style={{
                  background: '#F3F3F3',
                  border: '1px solid #F3F3F3',
                  borderRadius: '2px',
                  padding: '10px',
                }}
              >
                <Stack
                  sx={{
                    margin: 1,
                  }}
                >
                  {selectedNode.allocatable ? (
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          color: 'blue',
                          fontWeight: 500,
                        }}
                      >
                        allocatable:
                      </Box>
                      <Box
                        sx={{
                          px: 2,
                        }}
                      >
                        {Object.entries(selectedNode.allocatable).map(
                          ([key, value], node_idx) => (
                            <Box key={node_idx}>
                              <Box
                                component="span"
                                sx={{
                                  color: 'blue',
                                  fontWeight: 500,
                                }}
                              >
                                {' '}
                                {key}
                              </Box>
                              : {`"${value}"`}
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  ) : null}
                  {selectedNode.capacity ? (
                    <Box>
                      <Box
                        component="span"
                        sx={{
                          color: 'blue',
                          fontWeight: 500,
                        }}
                      >
                        capacity:
                      </Box>
                      <Box
                        sx={{
                          px: 2,
                        }}
                      >
                        {Object.entries(selectedNode.capacity).map(
                          ([key, value]) => (
                            <Box>
                              <Box
                                component="span"
                                sx={{
                                  color: 'blue',
                                  fontWeight: 500,
                                }}
                              >
                                {key}
                              </Box>
                              : {`"${value}"`}
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  ) : null}
                </Stack>
              </div>
            </div>
          </Box>
        )}
      </Box>
    </BootstrapDialog>
  );
}
