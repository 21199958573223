import { Mail, ReportProblem } from '@mui/icons-material';
import { Box, Button, Link, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideInvalidModal } from '../../../actions/licensing.action';
import { license_docs } from '../../../constants/license';
import { selectShowInvalidModal } from '../../../selectors/licensing.selector';
import { createLicenseSupportLink } from '../../../utils/license';

export const InvalidModal = ({ open, handleClose }) => {
  const href = createLicenseSupportLink();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 'none',
      }}
    >
      <Box
        data-testid="invalid-modal"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white !important',
          width: '600px',
          borderTop: '3px solid #E15E5E',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FEF9F9',
            bgOpacity: '0.6',
            pl: '38px',
            py: '38px',
            pr: '60px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <ReportProblem
              sx={{
                fill: '#E15E5E',
                fontSize: 32,
                height: '32px',
                width: '32px',
              }}
            />
            <Box
              sx={{
                color: '#E15E5E',
              }}
            >
              <Box
                data-testid="modal-title"
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                License is Invalid
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pt: '28px',
            pb: '38px',
            px: 5,
            borderTop: '1px solid #D9DAE6',
          }}
        >
          <Box
            sx={{
              color: '#828490',
            }}
          >
            You will not be able to perform any more actions.
          </Box>
          <Box
            sx={{
              mt: 1.25,
              color: '#828490',
            }}
          >
            Please contact Avesha support <b> support@avesha.io</b> to resolve
            this issue your license
          </Box>
          <Box
            sx={{
              mt: '12px',
              color: '#828490',
            }}
          >
            Learn more about licensing{' '}
            <Link
              href={license_docs}
              sx={{
                color: '#2868F3 !important',
                '&:hover': {
                  color: '#2868F3 !important',
                },
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </Link>
          </Box>
          <Box
            sx={{
              pt: 3,
              display: 'flex',
              gap: '14px',
              justifyContent: 'center',
            }}
          >
            <Button
              data-testid="cancel-button"
              sx={{
                backgroundColor: '#fff !important',
                border: '1px solid #B6B8D6',
                textTransform: 'capitalize',
                px: '24px',
                py: '10px',
                fontWeight: '600',
                color: 'black',
              }}
              type="button"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Link
              href={href}
              target="_blank"
              underline="none"
              sx={{
                color: '#fff',
                backgroundColor: '#DEE8FF',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: '#DEE8FF',
                  textDecoration: 'none',
                },
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#DEE8FF',
                  border: '0px',
                  textTransform: 'capitalize',
                  px: '24px',
                  py: '13px',
                  fontWeight: '600',
                  display: 'flex',
                  gap: '8px',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#DEE8FF',
                    textDecoration: 'none',
                  },
                }}
                type="button"
              >
                <Mail
                  style={{
                    height: '24px',
                    fill: '#2868F3',
                  }}
                />
                <Box
                  sx={{
                    color: '#2868F3',
                  }}
                  component="span"
                >
                  Support
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

// everything is inline beacuse we need to do this in v2 as well
export const InvalidLicenseModal = () => {
  const dispatch = useDispatch();
  const showInvalidModal = useSelector(selectShowInvalidModal);

  const handleClose = () => {
    dispatch(hideInvalidModal());
  };

  return <InvalidModal open={showInvalidModal} handleClose={handleClose} />;
};

export default InvalidLicenseModal;
