import * as React from 'react';
import { io } from 'socket.io-client';
import {
  BodyHeader,
  BodyRoot,
  Divider,
  FieldContainer,
  FieldContent,
  FieldHeading,
  GatewayContainer,
  IconContainer,
  SubHeading,
} from '../Body/BodyStyles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Panel, PanelList, Tab, TabList, Tabs } from 'react-tabtab';
import { simpleSwitch } from 'react-tabtab/lib/helpers/move';
import * as customStyle from 'react-tabtab/lib/themes/bootstrap';
import { deleteSliceCustomObject } from '../../actions/ConnectClusterActions';
import {
  clearEnforcementPoints,
  clearEnforcementSlice,
  getEnforcementByCanonical,
  getEnforcementPoints,
} from '../../actions/enforcementActions';
import { getPrometheusUrls } from '../../actions/metricsUrlActions';
import {
  clearGatewaysForSlice,
  getAllClustersForSlice,
  getAllGatewaysForSlice,
  getAllSlices,
  updateSlice,
} from '../../actions/SliceActions';
import {
  attachSlicePolicy,
  detachSlicePolicy,
  getAllPoliciesForSlice,
} from '../../actions/SlicePolicyActions';
import { keyMap } from '../../utils/keyMap';
import { FormField } from '../Body/FormFields';
import GatewayList from '../Body/GatewayList';
import AddSlicePolicyDialog from '../SlicePolicy/AddSlicePolicyDialog';
import AddEnforcementPointDialog from './AddEnforcementPoint';
import CustomerOverlay from './CustomerOverlay/CustomerOverlay';
import EnforcementPointMetricConfiguration from './EnforcementPointMetrics/EnforcementPointMetricConfiguration';
import PodTable from './PodTable';
import YamlEditor from './SliceConfig/yamlEditor';
import SliceDeletionConfirmModal from './SliceDeletionConfirmationModal';
import { PodContainer } from './SliceStyles';
import TelemetryForm from './TelemetryForm';
import TrainingEventDetails from './TrainingEvents/TrainingEventDetails';
import TrainingEventGraph from './TrainingEvents/TrainingEventGraphs';

const selectOptions = keyMap;

class SliceBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openConfirm: false,
      tabs: [],
      heading: '',
      tabIndex: 0,
      tabList: [],
      slice: {
        sliceGatewayProvider: {},
      },
      qosProfileOptions: {},
      openSlicePolicyDialog: false,
      openEnforcementDialog: false,
      showTrainingEventDialog: false,
      sliceIndex: null,
      selectValue: '',
      selectStatus: false,
      showTelemetryForm: false,
      sliceClusterId: null,
      showTrainingEventGraph: false,
      trainingEventParams: {},
      selectedCluster: '',
      showMetricConfiguration: false,
      selectedEnforcementPoint: null,
      showCustomerOverlay: false,
      openSliceDialog: false,
      iframe: '',
      confirmationModalText: ['Are you sure you want to delete?'],
    };
  }

  getQosProfileOptions = () => {
    let options = {};
    Object.keys(this.props.qos_profiles).forEach((profile) => {
      options[profile] = profile;
    });
    return options;
  };

  getIframe = () => {
    if (this.state?.iframe)
      return `<iframe height="265" style="width: 100%;" scrolling="no" title="fx." src="//${this.state.iframe}" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>`;
    else
      return `
     
      <div
      style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
      "
    >
      Prometheus not available
    </div>`;
  };

  setEnforcementDialog = async (status, canonicalName) => {
    if (canonicalName) {
      await this.props.getEnforcementByCanonical(
        this.state.slice?.sliceName,
        canonicalName
      );
    }

    this.setState({ openEnforcementDialog: status });
  };

  openPrometheusTab = async (clusterName) => {
    const iframe = this.props.clusterUrls
      ? this.props.clusterUrls[clusterName]
      : '';

    this.setState({ tabIndex: this.state.tabList.length - 1, iframe: iframe });
  };

  getSliceBodyMetadata = async () => {
    const query = new URLSearchParams(this.props.location.search);
    let sliceId = query.get('id');

    if (sliceId === null && this.props.sliceList.length > 0) {
      this.props.history.push(
        'slice?id=' + this.props.sliceList?.[0].metadata?.uid
      );
    } else if (this.props.sliceList.length > 0) {
      const sliceIndex = this.props.sliceList.findIndex(
        (slice) => slice.metadata?.uid === sliceId
      );
      if (sliceIndex !== -1) {
        let slice = this.props.sliceList[sliceIndex];
        if (slice?.metadata?.name) {
          // this.props.getAllPoliciesForSlice(slice?.metadata?.name);
          // this.props.getEnforcementPoints(slice?.metadata?.name);
          await this.props.getAllClustersForSlice(slice?.metadata?.name);
          await this.props.getAllGatewaysForSlice(slice?.metadata?.name);
          // this.props.getPrometheusUrls(slice?.metadata?.name);
        }

        this.setState({ slice, sliceIndex }, this.setTabs);
      } else {
        this.props.history.replace('slices');
      }
    }

    this.setState({ qosProfileOptions: this.getQosProfileOptions() });
  };

  componentDidMount() {
    this.getSliceBodyMetadata();
  }

  componentWillUnmount() {
    this.props.clearGatewaysForSlice();
    this.props.clearEnforcementPoints();
  }
  refreshData = () => {
    this.props.getAllSlices();
    if (this.state.slice?.metadata?.name) {
      this.props.getAllPoliciesForSlice(this.state.slice?.metadata?.name);
      this.props.getEnforcementPoints(this.state.slice?.metadata?.name);
      this.props.getAllGatewaysForSlice(this.state.slice?.metadata?.name);
      this.props.getAllClustersForSlice(this.state.slice?.metadata?.name);
    }
  };
  showMetricConfiguration = (selectedEnforcementPoint) => {
    this.setState({ showMetricConfiguration: true, selectedEnforcementPoint });
  };
  setTabs = () => {
    let cluster_tab = {
      name: 'Gateways',
      body: (
        <div>
          <GatewayList
            openPrometheusTab={this.openPrometheusTab}
            slice={this.state.slice}
            cluster={this.state.selectedCluster}
            addToTab={this.handleAddGatewayTab}
          />
        </div>
      ),
      closable: false,
    };

    this.setState({
      tabList: [cluster_tab, ...this.getTabList()],
    });
  };

  getTabList = () => {
    // let chart = {
    //   name: "Topology",
    //   body: (
    //     <div
    //       style={{
    //         backgroundColor: "aliceblue",
    //         height: "60vh",
    //         display: "flex",
    //       }}
    //     >
    //       <CustomNodeFlow />
    //     </div>
    //   ),
    //   closable: false,
    // };
    let sliceConfig = {
      name: 'Slice Configuration',
      body: <YamlEditor slice={this.state.slice} />,
    };
    // let slicePolicyList = {
    //   name: "Slice Policies",
    //   body: (
    //     <SlicePolicyList
    //       sliceName={this.state.slice?.sliceName}
    //       attachedSlicePolicy={this.state.slice?.slicePolicyId}
    //       attachSlicePolicy={(slicePolicyId) =>
    //         this.props.attachSlicePolicy(
    //           slicePolicyId,
    //           this.state.slice?.sliceName
    //         )
    //       }
    //       openDialog={() => this.setState({ openSlicePolicyDialog: true })}
    //       detachSlicePolicy={() => {
    //         this.props.detachSlicePolicy(this.state.slice?.sliceName, {
    //           detachSlicePolicy: true,
    //         });
    //       }}
    //     />
    //   ),
    //   closable: false,
    // };

    // let enforcementPoints = {
    //   name: "Enforcement Points",
    //   body: (
    //     <EnforcementPoints
    //       showCustomerOverlay={(enforcementPoint) =>
    //         this.setState({
    //           showCustomerOverlay: true,
    //           selectedEnforcementPoint: enforcementPoint,
    //         })
    //       }
    //       openDialog={(name) => this.setEnforcementDialog(true, name)}
    //       showTrainingEvents={(enforcementCanonicalName, name) =>
    //         this.handleAddTrainingEventTab(enforcementCanonicalName, name)
    //       }
    //       showMetricConfiguration={(enforcementPoint) => {
    //         this.showMetricConfiguration(enforcementPoint);
    //       }}
    //       toggleEnforcementPoint={(canonicalName, value) =>
    //         this.props.toggleEnforcementPoint(
    //           this.state?.slice?.sliceName,
    //           canonicalName,
    //           value
    //         )
    //       }
    //     />
    //   ),
    // };

    let serviceDiscovery = {
      name: 'Service Discovery',
      body: (
        <PodContainer>
          <PodTable
            slice={this.state.slice}
            clusters={this.props.clusters?.items || []}
          />
        </PodContainer>
      ),
    };

    // let prometheus = {
    //   name: "Prometheus",
    //   body: <IframeBox iframe={this.getIframe()} />,
    // };
    // let namespaces = {
    //   name: "Namespaces",
    //   body: <Namespace slice={this.state.slice} />,
    // };

    return [
      // chart,
      // slicePolicyList,
      // enforcementPoints,
      serviceDiscovery,
      // prometheus,
      // namespaces,
      sliceConfig,
    ];
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sliceList !== prevProps.sliceList) {
    }
    const query = new URLSearchParams(this.props.location.search);
    let sliceId = query.get('id');

    if (sliceId === null && this.props.sliceList.length > 0) {
      this.props.history.replace('slices');
    }

    if (
      (sliceId && sliceId != this.state.slice?.metadata?.uid) ||
      this.props.sliceList[this.state.sliceIndex] != this.state.slice
    ) {
      const sliceIndex = this.props.sliceList.findIndex(
        (slice) => slice.metadata?.uid === sliceId
      );
      if (sliceIndex !== -1) {
        const slice = this.props.sliceList[sliceIndex];
        if (slice?.metadata?.name) {
          // this.props.getAllPoliciesForSlice(slice?.sliceName);
        }

        this.setState({ slice, sliceIndex });
      } else {
        this.props.history.replace('slices');
      }
    }
    if (
      sliceId != this.state.slice?.metadata?.uid ||
      this.state.slice != prevState.slice ||
      // this.state.slice?.slicePolicyId !== prevState.slice?.slicePolicyId ||
      this.state.selectedCluster !== prevState.selectedCluster ||
      this.state.iframe !== prevState.iframe
    ) {
      this.props.sliceList.forEach((slice) => {
        if (slice.metadata?.uid === sliceId) {
          this.setState({ slice });
          this.setTabs();
        }
        return;
      });
    }

    // let slice = this.props.sliceList[this.state.sliceIndex];
    // if (slice?.slicePolicyId != this.state.slice?.slicePolicyId) {
    //   this.setState(
    //     { slice: this.props.sliceList[this.state.sliceIndex] },
    //     () => {
    //       // if (this.state.slice?.sliceName)
    //       // this.props.getAllPoliciesForSlice(this.state.slice?.sliceName);
    //     }
    //   );
    // }
  }

  setSelectStatus = () => {
    this.setState((prevState) => {
      return { selectStatus: !prevState.selectStatus };
    });
  };

  changeSelect = (e) => {
    this.setState({
      selectValue: e.target.value,
    });
  };

  handleAddGatewayTab = (tab) => {
    const keys = Object.keys(tab.data.spec);

    //converts keys into labels
    const camelToText = (camel) => {
      let text = camel[0]?.toUpperCase();
      for (var i = 1; i < camel.length; i++) {
        if (camel.charCodeAt(i) >= 65 && camel.charCodeAt(i) <= 90) {
          text += ' ' + camel[i];
        } else {
          text += camel[i];
        }
      }
      return text;
    };

    //to format the keys that need special formatting or to remove the unwanted ones
    const formatter = {
      health: null,
      sliceGatewayS3Url: null,
      sliceId: null,
      sliceName: null,
      assignedAtTime: null,
      prometheusButton: null,
      createdAtTime: (date) => moment(date).format('YYYY-MM-DD [:] h:mm:ss A'),
      sliceGatewayRegisteredAtTime: (date) =>
        moment(date).format('YYYY-MM-DD [:] h:mm:ss A'),
      updatedAtTime: (date) => moment(date).format('YYYY-MM-DD [:] h:mm:ss A'),
      sliceGatewayStatus: (inp) =>
        selectOptions['sliceGatewayStatus'][inp] || inp,
      sliceGatewayRegistration: (inp) =>
        selectOptions['sliceGatewayRegistration'][inp] || inp,
      sliceGatewayType: (inp) => selectOptions['sliceGatewayType'][inp] || inp,
      sliceGatewayHostType: (inp) =>
        selectOptions['sliceGatewayHostType'][inp] || inp,
    };

    //body of gateway and app details
    const tabBody = (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <SubHeading>Gateway Details</SubHeading>
          {/* <Button
            variant="outlined"
            style={{ height: "2.5rem" }}
            onClick={() =>
              this.setState({
                showTelemetryForm: true,
                sliceClusterId: tab?.data?.sliceClusterId,
              })
            }
          >
            Add Telemetry
          </Button> */}
        </div>
        <GatewayContainer>
          {keys.map((item) => {
            let subText = tab.data.spec[item];
            if (item in formatter) {
              if (formatter[item] == null) return null;
              else subText = formatter[item](subText);
            }
            // AM-27 Hide fields with empty values
            if (subText === '' || typeof subText == 'object') {
              return null;
            }
            return (
              <FieldContainer>
                <FieldHeading>{camelToText(item)}</FieldHeading>
                <FieldContent>{subText}</FieldContent>
              </FieldContainer>
            );
          })}
          <FieldContainer>
            <FieldHeading>Secret Name</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.gatewayCredentials?.secretName}
            </FieldContent>
          </FieldContainer>
        </GatewayContainer>
        <Divider />

        <SubHeading>Local Gateway Config</SubHeading>
        <GatewayContainer>
          <FieldContainer>
            <FieldHeading>Cluster Name</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.localGatewayConfig?.clusterName}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Gateway Name</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.localGatewayConfig?.gatewayName}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Gateway Subnet</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.localGatewayConfig?.gatewaySubnet}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Node IP</FieldHeading>
            <FieldContent>
              {/* {tab?.data?.spec?.localGatewayConfig?. */}
              {(Array.isArray(tab?.data?.spec?.localGatewayConfig?.nodeIps)
                ? tab?.data?.spec?.localGatewayConfig?.nodeIps[0]
                : undefined) ?? ''}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>VPN IP</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.localGatewayConfig?.vpnIp}
            </FieldContent>
          </FieldContainer>
        </GatewayContainer>

        <Divider />

        <SubHeading>Remote Gateway Config</SubHeading>
        <GatewayContainer>
          <FieldContainer>
            <FieldHeading>Cluster Name</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.remoteGatewayConfig?.clusterName}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Gateway Name</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.remoteGatewayConfig?.gatewayName}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Gateway Subnet</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.remoteGatewayConfig?.gatewaySubnet}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>Node IP</FieldHeading>
            <FieldContent>
              {/* {tab?.data?.spec?.remoteGatewayConfig?.nodeIp} */}
              {(Array.isArray(tab?.data?.spec?.remoteGatewayConfig?.nodeIps)
                ? tab?.data?.spec?.remoteGatewayConfig?.nodeIps[0]
                : undefined) ?? ''}
            </FieldContent>
          </FieldContainer>
          <FieldContainer>
            <FieldHeading>VPN IP</FieldHeading>
            <FieldContent>
              {tab?.data?.spec?.remoteGatewayConfig?.vpnIp}
            </FieldContent>
          </FieldContainer>
        </GatewayContainer>
      </div>
    );

    const tabDetails = {
      name: tab?.data?.metadata?.name,
      body: tabBody,
      closable: true,
    };

    const isTabActive = this.state.tabList.find(
      (value) => value.name === tabDetails.name
    );

    if (!isTabActive) {
      this.setState(
        {
          tabList: [...this.state.tabList, tabDetails],
        },
        () => {
          this.setState({ tabIndex: this.state.tabList.length - 1 });
        }
      );
    } else {
      this.state.tabList.forEach((value, index) => {
        if (value.name === tabDetails.name) {
          this.setState({ tabIndex: index });
        }
      });
    }
  };

  handleAddTrainingEventTab = (enforcementCanonicalName, name) => {
    this.setState({
      showTrainingEventGraph: true,
      trainingEventParams: { enforcementCanonicalName, name },
    });
  };

  handleCloseTab = (id) => {
    let { tabList } = this.state;
    tabList.splice(id.index, 1);
    tabList.unshift([]);
    this.setState({ tabList: tabList });
  };

  handleTabChange = (index) => {
    this.setState({ tabIndex: index });
  };

  handleTabSequenceChange = ({ oldIndex, newIndex }) => {
    const { tabs } = this.state;
    const updateTabs = simpleSwitch(tabs, oldIndex, newIndex);

    this.setState({ tabs: updateTabs, tabIndex: newIndex });
  };

  deleteSlice = async () => {
    // get socket url from env
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on('connect', () => {
      this.props.deleteSliceCustomObject(
        this.state.slice?.metadata?.name,
        socket.id
      );
    });

    socket.on('message', (data) => {
      this.setState({
        ...this.state,
        confirmationModalText: [
          ...this.state.confirmationModalText,
          `${data.status}: ${data.entity}`,
        ],
      });
    });

    socket.on('disconnect', () => {
      this.props.history.push('slices');
    });
  };

  editSlice = () => {
    this.setState({ openSliceDialog: true });
  };

  handleSliceDialogSubmit = async (sliceDetails) => {
    this.props.updateSlice(this.state.slice?.sliceName, sliceDetails);
  };

  updateSliceData = async () => {
    const { slice } = await this.props.updateSlice(
      this.state.slice?.sliceName,
      this.state.selectValue
    );
    await this.props.getAllSlices();

    this.setState((prevState) => ({
      selectStatus: false,
      slice: {
        ...prevState.slice,
        ...slice,
      },
    }));
  };

  handleOk = () => {
    this.props.history.replace('slices');
  };

  render() {
    const { tabIndex, openConfirm, slice } = this.state;

    let Actions = () => (
      <div
        style={{
          display: 'flex',
          marginTop: '0.9rem',
          justifyContent: 'flex-end',
        }}
      >
        {/* <IconContainer>
          <EditIcon
            onClick={this.editSlice}
            style={{
              cursor: "pointer",
              fontSize: "20px",
              color: "#ffffff",
              border: "solid grey 1px",
              borderRadius: "10px",
              padding: "5px",
              marginRight: "10px",
            }}
          />
        </IconContainer> */}
        <IconContainer>
          <RefreshIcon
            onClick={this.refreshData}
            style={{
              fontSize: '20px',
              color: '#ffffff',
              border: 'solid grey 1px',
              borderRadius: '10px',
              padding: '5px',
              marginRight: '10px',
            }}
          />
        </IconContainer>
        <IconContainer>
          <DeleteIcon
            onClick={() => this.setState({ openConfirm: true })}
            style={{
              fontSize: '20px',
              color: '#ffffff',
              border: 'solid white 1px',
              borderRadius: '10px',
              padding: '5px',
            }}
          />
        </IconContainer>
        {/* <AddSliceDialog
          open={this.state.openSliceDialog}
          edit={true}
          slice={{}}
          handleClose={() => {}}
          handleSubmit={() => {}}
        /> */}
      </div>
    );
    let TabsHeaderContent = this.state.tabList.map((tab) => {
      return (
        <Tab key={tab.name} closable={tab.closable}>
          {tab.name}
        </Tab>
      );
    });

    let TabsBodyContent = this.state.tabList.map((tab) => {
      return <Panel key={tab.name}>{tab.body}</Panel>;
    });

    const BodyTabs = (
      <Tabs
        showModalButton={false}
        customStyle={customStyle}
        activeIndex={tabIndex}
        onTabChange={this.handleTabChange}
        onTabSequenceChange={this.handleTabSequenceChange}
        onTabEdit={this.handleCloseTab}
      >
        <TabList>{TabsHeaderContent}</TabList>
        <PanelList>{TabsBodyContent}</PanelList>
      </Tabs>
    );

    let BodyContent = null;

    if (this.props.sliceList.length > 0) {
      BodyContent = (
        <>
          <BodyHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                overflow: 'auto',
                justifyContent: 'flex-start',
                alignSelf: 'center',
                flexWrap: 'wrap',
                height: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '35%',
                  alignSelf: 'center',
                  flex: 1,
                  height: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    onClick={() => this.props.history.goBack()}
                    variant="outlined"
                    style={{
                      marginRight: 'auto',
                      borderColor: '#ffffff',
                      color: '#ffffff',
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Back
                  </Button>
                  <Actions />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="Slice Name"
                    content={this.state.slice?.metadata?.name}
                  />
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="Slice Canonical Name"
                    content={this.state.slice?.metadata?.name}
                  />
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="Slice Subnet"
                    content={this.state.slice?.spec?.sliceSubnet}
                  />
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="Slice Type"
                    content={this.state.slice?.spec?.sliceType}
                  />
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="Slice GW type"
                    content={
                      this.state.slice?.spec?.sliceGatewayProvider
                        ?.sliceGatewayType
                    }
                  />
                  <FormField
                    style={{ minWidth: '12%' }}
                    heading="IPAM Type"
                    content={this.state.slice?.spec?.sliceIpamType}
                  />
                  {/* <FormField
                    style={{ minWidth: "12%" }}
                    heading="Slice QOS Profile"
                    content={this.state.slice?.sliceQosProfile}
                  /> */}
                </div>
              </div>
            </div>
          </BodyHeader>
          {BodyTabs}
        </>
      );
    } else {
      BodyContent = (
        <div style={{ textAlign: 'center', margin: '50px', color: 'gray' }}>
          <h3>No Slices Present. Click on &#43; icon to create a slice</h3>
        </div>
      );
    }
    const { trainingEventParams, selectedEnforcementPoint } = this.state;
    return (
      <BodyRoot>
        <AddSlicePolicyDialog
          attachedSlice={this.state?.slice?.slicePolicyId}
          sliceName={this.state?.slice?.sliceName}
          open={this.state.openSlicePolicyDialog}
          handleClose={() => this.setState({ openSlicePolicyDialog: false })}
        />
        <AddEnforcementPointDialog
          clearEnforcementSlice={this.props.clearEnforcementSlice}
          attachedSlice={this.state?.slice?.slicePolicyId}
          sliceName={this.state?.slice?.sliceName}
          open={this.state.openEnforcementDialog}
          handleClose={() => this.setState({ openEnforcementDialog: false })}
        />
        <EnforcementPointMetricConfiguration
          sliceName={this.state?.slice?.sliceName}
          selectedEnforcementPoint={selectedEnforcementPoint}
          open={this.state.showMetricConfiguration}
          handleClose={() =>
            this.setState({
              showMetricConfiguration: false,
              selectedEnforcementPoint: null,
            })
          }
        />
        <CustomerOverlay
          sliceName={this.state?.slice?.sliceName}
          selectedEnforcementPoint={selectedEnforcementPoint}
          open={this.state.showCustomerOverlay}
          handleClose={() =>
            this.setState({
              showCustomerOverlay: false,
              selectedEnforcementPoint: null,
            })
          }
        />
        <TrainingEventDetails
          open={this.state.showTrainingEventDialog}
          handleClose={() => this.setState({ showTrainingEventDialog: false })}
        />
        <TrainingEventGraph
          open={this.state.showTrainingEventGraph}
          enforcementCanonicalName={
            trainingEventParams?.enforcementCanonicalName
          }
          sliceName={this.state.slice?.sliceName}
          handleClose={() =>
            this.setState({
              showTrainingEventGraph: false,
              trainingEventParams: {},
            })
          }
        />
        <TelemetryForm
          sliceName={this.state.slice?.sliceName}
          clusterId={this.state.sliceClusterId}
          open={this.state.showTelemetryForm}
          cancelListener={() =>
            this.setState({ showTelemetryForm: false, sliceClusterId: null })
          }
        />
        {openConfirm ? (
          <SliceDeletionConfirmModal
            sliceName={this.state.slice?.metadata?.name}
            handleClose={() => this.setState({ openConfirm: false })}
            onOk={this.handleOk}
          />
        ) : null}
        {/*
        <Confirmation
          open={openConfirm}
          title="Confirm Delete"
          message={this.state.confirmationModalText.map((message) => (
            <p>{message}</p>
          ))}
          onConfirm={this.deleteSlice}
          onCancel={() => this.setState({ openConfirm: false })}
        />
        */}
        {BodyContent}
      </BodyRoot>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sliceList: store.ConnectCluster.sliceList,
    qos_profiles: store.qosProfiles.qos_profiles,
    clusters: store.slices.sliceClusters,
    // ...store.PrometheusReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    clearGatewaysForSlice,
    getAllGatewaysForSlice,
    updateSlice,
    attachSlicePolicy,
    getAllPoliciesForSlice,
    deleteSliceCustomObject,
    getAllSlices,
    getEnforcementPoints,
    getEnforcementByCanonical,
    clearEnforcementSlice,
    clearEnforcementPoints,
    detachSlicePolicy,
    getPrometheusUrls,
    getAllClustersForSlice,
  })(SliceBody)
);
