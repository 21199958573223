import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import AWSCloud from '../../../assets/icons/cloud_types/CloudType_AWS.svg';
import Azure from '../../../assets/icons/cloud_types/CloudType_Azure.svg';
import GCPCloud from '../../../assets/icons/cloud_types/CloudType_GCP.svg';
import Oracle from '../../../assets/icons/cloud_types/CloudType_Oracle.svg';
import Datacenter from '../../../assets/icons/cloud_types/CloudType_Datacenter.svg';
import Edge from '../../../assets/icons/cloud_types/CloudType_Edge.svg';
import Rancher from '../../../assets/icons/cloud_types/CloudType_Rancher.svg';
import OpenShift from '../../../assets/icons/cloud_types/CloudType_OpenShift.svg';
import KindCloud from '../../../assets/icons/cloud_types/CloudType_Kind.svg';
import OtherCloud from '../../../assets/icons/cloud_types/CloudType_Other.svg';
import Akamai from '../../../assets/images/Akamai.svg';

const getIcon = (type) => {
  switch (type.toUpperCase()) {
    case 'GCP':
      return GCPCloud;
    case 'AWS':
      return AWSCloud;
    case 'AZURE':
      return Azure;
    case 'ORACLE':
      return Oracle;
    case 'DATACENTER':
      return Datacenter;
    case 'EDGE':
      return Edge;
    case 'RANCHER':
      return Rancher;
    case 'OPENSHIFT':
      return OpenShift;
    case 'KIND':
      return KindCloud;
    case 'OTHER':
      return OtherCloud;
    case 'AKAMAI':
      return Akamai;
    case 'LINODE':
      return Akamai;
    default:
      return OtherCloud;
  }
};
export default memo(({ data, isConnectable }) => {
  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <div
          style={{
            width: '100%',
            position: 'absolute',
            marginTop: '-27px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlignLast: 'center',
          }}
        >
          <span
            style={{
              width: '100px',
              fontFamily: 'Bai Jamjuree',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '15px',
              marginTop: '-15px',
              /* identical to box height */

              letterSpacing: '0.05em',

              color: '#5A607C',
            }}
          >
            {data.clusterName}
          </span>
          <span
            style={{
              width: '100px',
              fontFamily: 'Bai Jamjuree',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '11px',
              lineHeight: '12px',
              /* Typography 2 */

              color: '#81839A',
            }}
          >
            {data.location}
          </span>
        </div>
        <div
          style={{
            border: '1px solid #81839A',
            borderRadius: '6px',
            background: '#FFFFFF',
            padding: 5,
          }}
        >
          <img
            height="25px"
            width="25px"
            src={getIcon(data.type)}
            alt={data.type}
          />
        </div>
      </div>

      <Handle
        type="source"
        position="bottom"
        className="nodrag"
        id="b"
        isConnectable={false}
        draggable={false}
        style={{
          background: 'transparent',
          border: '0px',
          bottom: '50%',
          top: 'auto',
        }}
      />
    </>
  );
});
