import React from "react";
import "./index.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function SliceTable() {
  const currentSlice = useSelector(
    (state) =>state.RbacReducer.currentSliceRoleList
  );
  return (
    
    <div style={{ 
        display: "flex",
         flexDirection: "column", 
         width: "100%" }}>
            
      <Typography
        style={{
          paddingLeft: "24px",
          paddingTop:"15px",
          fontFamily: "Bai Jamjuree",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "30px",
          color: "#12153E",
        }}
        className="step-title-header"
        gutterBottom
        component="div"
      >
        Slice
      </Typography>
      <TableContainer className="table-container">
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                style={{
                  padding: "14.5px 0px 14.5px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                SLICE NAME
              </TableCell>
              <TableCell
                style={{
                  padding: "14.5px 0px 14.5px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                TYPE
              </TableCell>
              <TableCell
                style={{
                  padding: "14.5px 0px 14.5px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                DESCRIPTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{borderStyle: 'hidden'}}>
              <TableCell
                style={{
                  padding: "24px 0px 24px 24px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#12153E",
                }}
              >
                {currentSlice?.metadata?.name}
              </TableCell>
              <TableCell
                style={{
                  padding: "24px 0px 24px 24px",
                  // boxShadow: 'inset 1px -1px 0px #D9DFEE',
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#12153E",
                }}
              >
                Application
              </TableCell>
              <TableCell
                style={{
                  padding: "24px 0px 24px 24px",
                  // boxShadow: 'inset 1px -1px 0px #D9DFEE',
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#12153E",
                }}
              >
                {currentSlice?.metadata?.annotations?.desc}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
