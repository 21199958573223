import { Checkbox, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { RowContainer } from './RowContainer';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutline from '@mui/icons-material/ErrorOutlineOutlined';
import WatchLater from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import componentName from '../../../../constants/componentName.json';

export const DataRow = ({
  clusterData,
  handleClusterSelect,
  selected,
  isMaxClusterLimitReached,
  currentSlice,
  disableOffboard,
}) => {
  const sliceList = useSelector((state) => state.ConnectCluster.sliceList);
  const status = clusterData?.status?.cniSubnet?.length > 0 ? true : false;
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  // const offboardStatus = true; // TO DO
  const disableCheckBoxIfMaxReachedAndNotSelected =
    isMaxClusterLimitReached && selected == false;

  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, [currentSlice]);

  useEffect(() => {
    let identifier = currentSlice?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
  }, []);

  let identifier = currentSlice?.kubesliceEvents || [];

  const filteredEvents = identifier.filter((data) => {
    return data.type === 'InProgress' || data.type === 'Error';
  });
  // const tooltipobjectData = [];
  const tooltipobjectData = filteredEvents.map((data) => {
    const components = data.components;
    const reason = data.reason;
    const identifier = data.identifier;
    if (components !== undefined) {
      return { components, reason, identifier };
    } else {
      const action = data.action;
      return { action, identifier };
    }
  });
  const identiferCluster = tooltipobjectData
    .filter((data) => data.identifier === clusterData.metadata.name)
    .map((data) => data.identifier)
    .join('');
  // const tooltipStatus = (
  //   <div>
  //     {tooltipobjectData.map((data) => (
  //       <div>
  //         {data.reason && <p>Reason: {data.reason}</p>}
  //         {data.components && <p>Components:</p>}
  //         <ul>
  //           {data.components &&
  //             data?.components?.map((component) => (
  //               <li>{componentName[component.split('/')[0]]}</li>
  //             ))}
  //         </ul>
  //         {data.action && <p>Actions: {data.action}</p>}
  //       </div>
  //     ))}
  //   </div>
  // );

  // let statusData = null;
  // if (filteredEvents.length > 0) {
  //   if (filteredEvents.some((data) => data.type === 'InProgress')) {
  //     statusData = 'InProgress';
  //   } else {
  //     statusData = 'Error';
  //   }
  // }
  // const renderIcon = () => {
  //   switch (statusData) {
  //     case 'InProgress':
  //       return (
  //         <span
  //           style={{
  //             top: '2px',
  //             position: 'relative',
  //             left: '5px',
  //           }}
  //         >
  //           <Tooltip title={tooltipStatus} arrow>
  //             <WatchLater fontSize="small" color="warning" />
  //           </Tooltip>
  //         </span>
  //       );
  //     case 'Error':
  //       return (
  //         <span
  //           style={{
  //             top: '2px',
  //             position: 'relative',
  //             left: '5px',
  //           }}
  //         >
  //           <Tooltip title={tooltipStatus} arrow>
  //             <ErrorIcon fontSize="small" color="error" />
  //           </Tooltip>
  //         </span>
  //       );
  //     default:
  //       return null;
  //   }
  // };
  const tooltipData = currentSlice?.kubesliceEvents || [];

  const tooltipStatus = tooltipData
    .map((data) => {
      const components = data.components;
      const reason = data.reason;
      const identifier = data.identifier;
      const type = data.type;
      if (components !== undefined) {
        return { components, reason, identifier, type };
      } else {
        const action = data.action;
        return { action, identifier, type };
      }
    })
    .filter((data) => data.identifier === clusterData.metadata.name);

  const inProgressEvents = tooltipStatus.filter(
    (data) => data.type === 'InProgress'
  );
  const errorEvents = tooltipStatus.filter((data) => data.type === 'Error');

  const inProgressTooltip = (
    <div>
      {inProgressEvents.map((data) => (
        <div>{data.action && <p>Actions: {data.action}</p>}</div>
      ))}
    </div>
  );

  const errorTooltip = (
    <div>
      {errorEvents.map((data) => (
        <div>
          {data.reason && <p>Reason: {data.reason}</p>}
          {data.components && <p>Components:</p>}
          <ul>
            {data.components &&
              data?.components?.map((component) => (
                <li>{componentName[component.split('/')[0]]}</li>
              ))}
          </ul>
          {data.action && <p>Actions: {data.action}</p>}
        </div>
      ))}
    </div>
  );

  const renderIcon = () => {
    if (inProgressEvents.length > 0) {
      return (
        <span style={{ top: '2px', position: 'relative', left: '5px' }}>
          <Tooltip title={inProgressTooltip} arrow>
            <WatchLater fontSize="small" color="warning" />
          </Tooltip>
        </span>
      );
    } else if (errorEvents.length > 0) {
      return (
        <span style={{ top: '2px', position: 'relative', left: '5px' }}>
          <Tooltip title={errorTooltip} arrow>
            <ErrorIcon fontSize="small" color="error" />
          </Tooltip>
        </span>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <RowContainer>
        <Grid item xs={0.5}>
          <Checkbox
            disabled={
              !status ||
              disableCheckBoxIfMaxReachedAndNotSelected ||
              // currentSlice?.kubesliceEvents?.[0]?.type === 'InProgress'
              DisableOffboardCluster
              // ||
              // offboardStatus TO DO
            }
            checked={selected}
            onClick={(event) => {
              handleClusterSelect(event.target.checked);
            }}
          />
        </Grid>
        <Grid item xs>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              color={status ? 'black' : 'rgba(0, 0, 0, 0.26)'}
              fontSize={16}
              fontWeight={600}
            >
              {clusterData?.metadata?.name}
            </Typography>
            {clusterData?.metadata?.name === identiferCluster && (
              <div>{renderIcon()}</div>
            )}
          </div>
        </Grid>
        <Grid item xs>
          <Typography
            color={status ? 'black' : 'rgba(0, 0, 0, 0.26)'}
            fontSize={16}
            fontWeight={600}
          >
            {/* {clusterData?.spec?.nodeIP} */}
            {(Array.isArray(clusterData?.spec?.nodeIPs)
              ? clusterData?.spec?.nodeIPs[0]
              : undefined) ?? ''}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            color={status ? 'black' : 'rgba(0, 0, 0, 0.26)'}
            fontSize={16}
            fontWeight={600}
          >
            {clusterData?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            color={status ? 'black' : 'rgba(0, 0, 0, 0.26)'}
            fontSize={16}
            fontWeight={600}
          >
            {status ? 'Registered' : 'Inprocess'}
          </Typography>
        </Grid>
      </RowContainer>
      <Divider sx={{ width: '95%', alignSelf: 'center' }} color="#DEE8FF" />
    </>
  );
};
