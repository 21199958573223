import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import './index.css';
import NodeTable from './NodeTable';
import SelectedNode from './SelectedNode';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import Dialogs from '../AssignToNamespace/Dialogs';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#2868F3',
    },
    // secondary: {
    //   // This is green.A700 as hex.
    //   main: "#11cb5f",
    // },
  },
});

export default function InitialStep() {
  const [selectedNode, setSelectedNode] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState('namespace');
  const [openModal, setParentModalOpen] = useState(false);
  const [namespace, setCurrentNamespace] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegisteredClusters());
  }, [selectedNode]);

  const searchLabels = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="step-content">
      <div style={{ padding: '24px' }} className="step-content-header">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              height: '40px',
              overflow: 'hidden',
              border: '1px solid #B6B8D6',
              marginRight: '24px',
              borderRadius: '9px',
            }}
          >
            <FormControl
              className="filter-dropdown"
              sx={{ backgroundColor: '#E6E8F3', top: '0px', height: '44px' }}
            >
              <InputLabel id="search-by-label"></InputLabel>
              <Select
                labelId="search-by-label"
                id="search-by"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                label="Search By"
                style={{ height: '40px' }}
                renderValue={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              >
                <MenuItem value="namespace">Search by Namespace</MenuItem>
                <MenuItem value="cluster">Search by Cluster</MenuItem>
              </Select>
            </FormControl>
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 250,
                borderRadius: '0 5px 5px 0',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={searchLabels}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                className="search-icon"
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <ThemeProvider theme={theme}>
          <Button
            variant="contained"
            position="right"
            disabled={!namespace}
            sx={{
              justifyContent: 'flex-end',
            }}
            onClick={() => {
              setParentModalOpen(true);
              // setCurrentNode('item');
            }}
            data-testid="AssignNodes"
          >
            Assign Nodes Labels
          </Button>
        </ThemeProvider>
      </div>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <NodeTable
          selectedNamespace={namespace}
          search={searchText}
          selectNamespace={(namespace) => setCurrentNamespace(namespace)}
          searchBy={searchBy}
        />
        <SelectedNode
          selectedNode={selectedNode}
          setSelectedNode={(selectedArr) => setCurrentNamespace(selectedArr)}
        />
        {openModal && namespace ? (
          <Dialogs
            open={openModal}
            onClose={() => setParentModalOpen(false)}
            selectedNamespace={namespace}
          />
        ) : null}
      </Box>
    </div>
  );
}
