import { SdkBackend } from '../backend/MeshBackend';
import { updateSnackbar } from './SnackbarActions';
import {
  CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST,
  CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST,
  CLEAR_CURRENT_SLICE_ROLES_LIST,
  CLEAR_SELECTED_ROLE,
  SELECT_IMPORTK8ROLE_LIST,
  SELECT_ROLE_LIST,
  SET_CURRENT_SLICE_ROLES_LIST,
  SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST,
  SET_ROLES,
  SET_SELECTED_ROLE,
  SET_SLICE_ROLES_LIST,
} from './types';

const sdkBackend = new SdkBackend();

export let sliceRoleLists = [];
export const getSliceRoleList = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getSliceCustomObjectList(
      getState(),
      dispatch
    );

    if (response) {
      sliceRoleLists = await sdkBackend.getAllSliceRoles(getState(), dispatch);

      if (sliceRoleLists) {
        for (const sliceRole of sliceRoleLists.data) {
          let workerRoleStatus = await sdkBackend.getWorkerRoleBindingStatus(
            getState(),
            dispatch,
            sliceRole?.name
          );
          if (workerRoleStatus) {
            workerRoleStatus?.data?.forEach((entry) => {
              if (
                entry?.status?.roleRefCondition &&
                entry?.status?.roleRefCondition?.condition !== 'SUCCESS'
              ) {
                if (!sliceRole?.status?.roleRefConditions) {
                  sliceRole.status.roleRefConditions = [];
                }

                // for errors from worker, we're not getting cluster name in the error message
                // so we append the cluster name error message
                const refCondition = entry?.status?.roleRefCondition;

                if (!refCondition.cluster) {
                  refCondition.cluster = entry.clusterName;
                }

                if (!refCondition.message.startsWith('Cluster')) {
                  refCondition.message = `Cluster: ${entry.clusterName} ${refCondition.message}`;
                  refCondition.message = refCondition.message.replace(
                    /Error:/,
                    ''
                  );
                }

                sliceRole.status.roleRefConditions.push(refCondition);
              }
            });
          }
        }
        for (const sliceRole of sliceRoleLists.data) {
          response.items.forEach((sliceList) => {
            if (sliceRole?.name === sliceList?.metadata?.name) {
              sliceList.metadata.bindings = sliceRole.bindings;
              sliceList.metadata.status = sliceRole.status;
            }
          });
        }
      }

      if (response) {
        response.items.forEach((sliceList) => {
          sliceList.metadata.k8simport = [
            {
              applyTo: [
                {
                  namespace: '',
                  subjects: [
                    {
                      kind: '',
                      name: '',
                    },
                  ],
                },
              ],
              roleRef: {
                apiVersion: '',
                kind: '',
                name: '',
              },
            },
          ];
        });
      }
    }
    dispatch({ type: SET_SLICE_ROLES_LIST, payload: response?.items ?? [] });
  };
};

export const registerSliceRoleBinding = (roleBindingDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.registerSliceRoleBinding(
      getState(),
      dispatch,
      roleBindingDetail
    );
    if (response) {
      setTimeout(() => dispatch(getSliceRoleList()), 1000);
      return response;
    }
    dispatch(updateSnackbar(true, 'Error updating slice role binding'));
  };
};

export const getRoles = () => {
  return async (dispatch, getState) => {
    let { data } = await sdkBackend.getRoles(getState(), dispatch);
    dispatch({
      type: SET_ROLES,
      payload: data ?? [],
    });
  };
};

export const addRole = (roleDetails) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.registerRole(
      getState(),
      dispatch,
      roleDetails
    );
    setTimeout(() => dispatch(getRoles()), 1000);
    if (response) return response;
    else {
      dispatch(updateSnackbar(true, 'Could not add the role template'));
    }
  };
};

export const updateRole = (roleDetails) => {
  // same route handles both register and update
  return async (dispatch, getState) => {
    let response = await sdkBackend.updateRole(
      getState(),
      dispatch,
      roleDetails
    );
    //else
    if (response) {
      setTimeout(() => dispatch(getRoles()), 1000);
      return response;
    }
    dispatch(updateSnackbar(true, 'Error updating role'));
  };
};

export const deleteRole = (roleName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.deleteSliceRoleBinding(
      getState(),
      dispatch,
      roleName
    );
    setTimeout(() => dispatch(getRoles()), 1000);
    if (response) return response;
  };
};

// export const deleteRole = (roleName) => {
//   return async (dispatch, getState) => {
//     let response = await sdkBackend.deleteRoleBinding(
//       getState(),
//       dispatch,
//       roleName
//     );
//     setTimeout(() => dispatch(getRoles()), 1000);
//     if (response) return response;
//   };
// };

export const deleteRoleTemplate = (roleTemplateName) => {
  return async (dispatch, getState) => {
    await sdkBackend.deleteRoleTemplate(getState(), dispatch, {
      roleTemplateName,
    });
    // refetching roles after deletion
    dispatch(getRoles());
  };
};

// export const deleteRoleBinding = (name) => {
//   return async (dispatch, getState) => {
//     await sdkBackend.deleteRoleBinding(getState(), dispatch, {
//       name,
//     });
//     // refetching roles after deletion
//     dispatch(getRoles());
//   };
// };

export const deleteRoleBinding = (roleName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.deleteRoleBinding(
      getState(),
      dispatch,
      roleName
    );
    setTimeout(() => dispatch(getRoles()), 1000);
    if (response) return response;
  };
};

export const clearCurrentSliceRoleList = () => {
  return {
    type: CLEAR_CURRENT_SLICE_ROLES_LIST,
  };
};

export const clearCurrentSelectSliceRoleList = () => {
  return {
    type: CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST,
  };
};
export const clearCurrentImportK8SelectSliceRoleList = () => {
  return {
    type: CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST,
  };
};

export const setCurrentSliceRoleList = (payload) => {
  return {
    type: SET_CURRENT_SLICE_ROLES_LIST,
    payload,
  };
};
export const setRoleList = (payload) => {
  return {
    type: SELECT_ROLE_LIST,
    payload,
  };
};
export const setK8RoleList = (payload) => {
  return {
    type: SELECT_IMPORTK8ROLE_LIST,
    payload,
  };
};

export const setOriginalSliceRoleList = (payload) => {
  return {
    type: SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST,
    payload,
  };
};
export const setSelectedRole = (payload) => {
  return {
    type: SET_SELECTED_ROLE,
    payload,
  };
};
export const clearSelectedRole = () => {
  return {
    type: CLEAR_SELECTED_ROLE,
  };
};
