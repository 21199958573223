import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearCurrentSlice } from "../../../actions/namespaceActions";
import { getSliceObject } from "../../../actions/ConnectClusterActions";
import StepsTableRow from "./StepsTableRow";

const FinalButton = styled(Button)({
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  height: "50px",
  padding: "10px 24px",
  lineHeight: 1.5,
  backgroundColor: "#2868f3",
  borderColor: "#0063cc",
  fontFamily: ["Bai Jamjuree"].join(","),
  "&:hover": {
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    boxShadow: "none",
  },
});

const Finalize = ({ currentSlice, clearCurrentSlice, getSliceObject }) => {
  const history = useHistory();
  const [slice, setSlice] = React.useState({});
  const onDone = () => {
    clearCurrentSlice();
    history.push("/manage-namespaces/isolate-namespaces");
  };

  React.useEffect(() => {
    // Checking route path
    if (location?.state?.legit) {
      if (currentSlice && currentSlice?.metadata?.name) {
        getSliceObject(currentSlice?.metadata?.name)
          .then((res) => {
            setSlice(res);
          })
          .catch((err) => console.log(err));
      } else {
        history.push("/manage-namespaces/isolate-namespaces");
      }
    }
  }, []);

  const location = useLocation();

  if (location?.state?.legit) {
    return (
      <>
        {slice?.metadata?.name && (
          <Grid sx={{ marginTop: "24px" }} item>
            <Paper
              elevation={0}
              sx={{ margin: "20px 0", border: "1px solid #B6B8D6" }}
            >
              <Grid
                sx={{ padding: "30px 25px" }}
                container
                direction="row"
                alignItems={"center"}
              >
                <Grid item xs={10}>
                  <Typography
                    sx={{fontSize: "24px", fontWeight: 600}}
                    component="div"
                  >
                    Slices
                  </Typography>
                </Grid>
                <Grid textAlign={"end"} item xs={2}>
                  <FinalButton onClick={onDone}>Done</FinalButton>
                </Grid>
              </Grid>

              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: "Bai Jamjuree",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "15px",
                          padding: "14px 24px",
                        }}
                      >
                        SLICE NAME
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Bai Jamjuree",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "15px",
                          padding: "14px 24px",
                        }}
                      >
                        ALLOWED NAMESPACES
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Bai Jamjuree",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "15px",
                          padding: "14px 24px",
                        }}
                      >
                        DESCRIPTION
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Bai Jamjuree",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "15px",
                          padding: "14px 24px",
                        }}
                      >
                        ISOLATE NAMESPACES
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StepsTableRow
                      key={slice?.metadata?.name}
                      slice={slice}
                      radioDisable={true}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        )}
      </>
    );
  } else {
    return (
      <Typography>Please complete the actions over Step 2 first</Typography>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    currentSlice: state.NamespaceOnboard.currentSlice,
  };
};
export default connect(mapStateToProps, {
  clearCurrentSlice,
  getSliceObject,
})(Finalize);
