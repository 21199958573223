export const selectShowLicensingBanner = () => {
  const values = document.cookie;
  if (values) {
    const shouldShowBanner = values.split(';').find((cookie) => {
      return cookie.includes('showLicenseBanner=true');
    });
    return !!shouldShowBanner;
  }

  return false;
};

export const selectShowExpiredModal = () => {
  const values = document.cookie;
  if (values) {
    const shouldShowBanner = values.split(';').find((cookie) => {
      return cookie.includes('showLicenseExpiryModal=true');
    });
    return !!shouldShowBanner;
  }

  return false;
};

export const selectShowInvalidModal = () => {
  const values = document.cookie;
  if (values) {
    return !!values.split(';').find((cookie) => {
      return cookie.includes('showInvalidLicenseModal=true');
    });
  }

  return false;
};

export const selectLicenseDetails = (state) => {
  return state.licensing.licenseDetails;
};

export const selectLicenseStatus = (state) => {
  return state.licensing.licenseStatus;
};

export const selectLicenseRemainingDays = (state) => {
  return state.licensing.licenseDetails;
};
