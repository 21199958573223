import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAllGatewaysForSlice } from "../../actions/SliceActions";
import { DataGrid } from "@mui/x-data-grid";

const datagridSx = {
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#DEE8FF",
  }
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderHeader: (params) => {
      return (
        <b>
          Name
        </b>
      );
    }
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
    renderHeader: (params) => {
      return (
        <b>
          Type
        </b>
      );
    }
  },
  // {
  //   field: "health",
  //   headerName: "Health",
  //   flex: 1,
  //   renderHeader: (params) => {
  //     return (
  //       <b>
  //         Health
  //       </b>
  //     );
  //   }
  // },
  {
    field: "clusterInfo", headerName: "Cluster", flex: 1,
    renderHeader: (params) => {
      return (
        <b>
          Cluster
        </b>
      );
    }
  },
  {
    field: "namespace",
    headerName: "NameSpace",
    flex: 1,
    renderHeader: (params) => {
      return (
        <b>
          NameSpace
        </b>
      );
    }
  },
];
class PodTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gatewayData: null,
    };
  }
  componentDidMount() {
    this.getData();
    this.getGateWays();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.slice?.metadata?.name !== prevProps.slice?.metadata?.name) {
      this.getData();
    }
    // if (prevProps.gateways?.length != this.props.gateways?.length) {
    //   this.getGateWays();
    //   console.log('Getting Gateways !');
    // }
  }
  getData = async () => {
    let sliceId = this.getSliceId();
    let { sliceList } = this.props;
    const sliceName = sliceList?.find?.(
      (slice) => slice?.metadata?.name == sliceId
    )?.metadata?.name;
    if (sliceName != -1) {
      await this.props.getAllGatewaysForSlice(sliceName);
      
      // Generating gateways report only after resolving getAllGatewaysForSlice() function
      this.getGateWays();
    }
  };
  getGateWays = () => {
    const result = this.props.gateways;
    const { clusters } = this.props;
    if (!result?.length) {
      this.setState({ gatewayData: [] });
      return;
    }

    let gatewayData = result.map((gateway) => ({
      name: gateway.metadata.name,
      health: "Not Available",
      clusterInfo: gateway.spec.localGatewayConfig.clusterName,
      type: "Gateway",
      namespace: gateway.metadata.namespace,
    }));
    let appPodsMap = [];
    clusters?.forEach?.((cluster) => {
      const pods =
        cluster?.status?.connectedAppPods?.map?.((pod) => ({
          name: pod?.podName,
          // health: "Not Available",
          clusterInfo: cluster.metadata.labels["worker-cluster"],
          type: "Application",
          namespace: pod?.podNamespace,
        })) || [];
      appPodsMap = [...appPodsMap, ...pods];
    });

    this.setState({ gatewayData: [...gatewayData, ...appPodsMap] });
  };

  getSliceId = () => {
    return this.props.slice?.metadata?.name;
  };
  render() {
    const { gatewayData } = this.state;
    const data =
      gatewayData?.map((data, index) => ({ ...data, id: index.toString() })) ||
      [];

    return (
      <DataGrid
        style={{ height: "60vh" }}
        sx={datagridSx}
        rows={data}
        columns={columns}
        pageSize={10}
      />
    );
  }
}

const mapStateToProps = ({
  slices: { sliceGateways },
  ConnectCluster: { sliceList },
}) => ({
  sliceList,
  gateways: sliceGateways.items,
});

const mapDispatchToProps = { getAllGatewaysForSlice };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PodTable)
);

