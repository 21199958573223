import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import gcp from '../../assets/images/gcp.png';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import gcpLogo from '../../assets/images/gcpLogo.svg';

const useStyles = makeStyles((theme) => ({
  mainCloudContainer: {
    width: '300px',
    maxWidth: '400px',
    height: '45px',
    fontFamily: 'Bai Jamjuree',
  },
  chipCloudContainer: {
    transitionDuration: '1s',

    '&:hover': {
      transition: 'all .3s ease-in-out',

      transition: '0.2s',
      transitionDuration: '3s',

      '& $chipInnerCloudContainer': {
        display: 'flex',
        width: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
      },

      '& $chipCloudIndex': {
        background: 'white',
        color: 'black',
        height: '20px',
        marginTop: '5px',
        width: '20px',
        marginLeft: '10px',
      },
      '& $chipCloudLabel': {
        display: 'block',
        marginLeft: '0.5rem',
        marginRight: '0.1rem',
      },
      '& $chipCloudBigLogo': {
        display: 'block',
        zIndex: '2',
        height: '50px',
      },
      '& $chipCloudSmallLogo': {
        display: 'none',
      },
      '& $arrow': {
        marginTop: '20px',
        content: ' ',
        height: '0',
        position: ' absolute',
        width: '0',
        left: '160px',
        border: '10px solid transparent',
        marginLeft: '12px',
        borderWidth: '10px',
        borderStyle: 'solid',
        borderColor: '#3A66EA transparent transparent',
        bottom: '1px',
        marginBottom: '0px',
        borderBottomWidth: '8px',
      },
    },
  },
  chipInnerCloudContainer: () => ({
    // transition: "all 0.2s ease-in-out",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2px',
    position: 'relative',
    justifyContent: 'center',
  }),
  arrow: () => ({
    display: 'block',
  }),
  chipCloudBigLogo: () => ({}),
  chipCloudSmallLogo: () => ({
    display: 'block',
    height: '15px',
    marginLeft: '4px',
  }),
  chipCloudLabel: () => ({}),
  chipCloudIndex: () => ({
    background: 'transparent',
    color: 'white',
  }),
  chipCloudIndexContainer: () => ({
    fontSize: '12px',
    fontWeight: 600,
    color: '#FFFFFF',
    marginRight: '8px',
  }),
}));

export function CommonCloud(props) {
  const classes = useStyles();

  return (
    <Box
      sx={{
        height: '24px',
        width: '40px',
        borderRadius: '16px',
        backgroundColor: '#3A66EA',
        display: 'inline-flex',
      }}
    >
      <Box className={classes.chipInnerCloudContainer}>
        <Box className={classes.chipCloudSmallLogo}>
          <Avatar
            sx={
              props.isSVG
                ? {
                    height: '24px',
                    width: '24px',
                    position: 'absolute',
                    top: '-3px',
                    left: '-3px',
                  }
                : { height: '14px', width: '14px' }
            }
            alt="Natacha"
            src={props.img}
          />
        </Box>
        <Box className={classes.arrow}></Box>
        <Box
          className={classes.chipCloudIndexContainer}
          sx={props.isSVG ? { marginLeft: '12px' } : undefined}
        >
          {props.index}
        </Box>
      </Box>
    </Box>
  );
}

export const CloudTooltipCommon = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainCloudContainer}>
      <Box className={classes.chipInnerCloudContainer}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '2px',
          }}
        >
          <Box className={classes.chipCloudBigLogo}>
            <Box
              component="img"
              sx={{ height: '41px', width: '58px', position: 'relative' }}
              alt="Natacha"
              src={props.logo}
            />
            <Avatar
              sx={
                props.isAzure
                  ? {
                      position: 'absolute',
                      width: '46px',
                      height: '46px',
                      top: '-5px',
                      left: '7px',
                    }
                  : {
                      top: '10px',
                      left: '19px',
                      width: '26px',
                      height: '26px',
                      position: 'absolute',
                    }
              }
              alt="Natacha"
              src={props.img}
            />
          </Box>
          <Box className={classes.chipCloudLabel}>{props.data}</Box>
          <Box
            className={classes.chipCloudIndexContainer}
            sx={{
              background: '#FFFFFF',
              borderRadius: '25px',
              color: 'black',
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              marginBottom: '1px',
            }}
          >
            {props.index}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              background: '#3A66EA',
              borderRadius: '40px',
              left: '10px',
              right: '4px',
              top: '6px',
              bottom: '7px',
              zIndex: '-1',
            }}
          />
          <Box
            sx={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '5.5px 5px 0 5px',
              borderColor: '#3A66EA transparent transparent transparent',
              position: 'absolute',
              left: 'auto',
              right: 'auto',
              bottom: '3px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const CloudTooltip = (props) => {
  return <CloudTooltipCommon {...props} isSVG logo={gcpLogo} img={gcp} />;
};

export const Cloud = (props) => {
  return <CommonCloud {...props} img={gcp} />;
};

export default Cloud;
