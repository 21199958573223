import MUIDialog from "@mui/material/Dialog";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideUserDetails } from "../../actions/profileActions";
import styled from "styled-components";
import MUIAvatar from "@mui/material/Avatar";
import { FormField } from "./FormFields";
import { CopyToClipboard } from "../Commons/formUtils";
import jwt from "jwt-decode";
import { API_KEY, API_BASE_URL } from "../../backend/api-config";

const StyledFormField = styled(FormField)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: max-content;
  width: -moz-available;  
  width: -webkit-fill-available;
  max-width: unset;
`;
const InnerContainer = styled.div`
  padding: 16px;
  /* min-width: 20rem; */
  min-height: 30vh;
  display: flex;
  width: 25rem;
  flex-direction: column;
  overflow: visible;
`;
const FieldList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
`;

class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: jwt(this.props.token),
    };
  }

  getInitials = () => {
    if (!this.props.operatorName) return "AV";
    let initials = "";
    let words = this.props.operatorName.trim().split(" ");
    words.forEach((word) => (initials += word[0]?.toUpperCase()));
    return initials;
  };
  render() {
    const apiUrl = API_BASE_URL.slice(0, API_BASE_URL.length - 1);
    const { user } = this.state;
    const { operatorName, customerName } = this.props;
    return (
      <MUIDialog
        style={{ zIndex: 3000, overflow: "visible" }}
        open={this.props.open}
        onClose={this.props.handleClose}
      >
        <InnerContainer>
          <MUIAvatar
            style={{
              backgroundColor: "#f1f1f1",
              color: "black",
              alignSelf: "center",
              height: "5rem",
              width: "5rem",
            }}
          >
            {this.getInitials()}
          </MUIAvatar>
          <FieldList>
            <StyledFormField
              color="black"
              heading="Name"
              content={operatorName}
            />
            <StyledFormField
              color="black"
              heading="Customer Name"
              content={customerName}
            />
            <StyledFormField
              color="black"
              heading="Email"
              content={user?.email}
            />
            <StyledFormField
              color="black"
              heading="Refresh Token"
              content={
                <CopyToClipboard>{this.props.refreshToken}</CopyToClipboard>
              }
            />
            <StyledFormField
              color="black"
              heading="API Key"
              content={<CopyToClipboard>{API_KEY}</CopyToClipboard>}
            />
            <StyledFormField
              color="black"
              heading="API Host"
              content={<CopyToClipboard>{apiUrl}</CopyToClipboard>}
            />
          </FieldList>
        </InnerContainer>
      </MUIDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.SessionReducer.session_token,
  refreshToken: state.SessionReducer.refreshToken,
  operatorName: state.UserReducer.operatorName,
  customerName: state.UserReducer.customerName,
});

const mapDispatchToProps = {
  hideUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
