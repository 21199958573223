import React, { PureComponent } from 'react';
import { ReactComponent as Logo } from '../../assets/images/LogoWithName.svg';

import { connect } from 'react-redux';
import { ReactComponent as MiniLogo } from '../../assets/images/Logo.svg';
import { updateMenu } from './../../actions/MenuActions';
import { getAllQOSProfiles } from './../../actions/QOSProfileActions';
import { createSlice, getAllSlices } from './../../actions/SliceActions';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import { Box, Button } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { logoutUser } from '../../actions/logoutAction';
import { clearCurrentSlice } from '../../actions/namespaceActions';
import { closePopUp, openPopUp } from '../../actions/PopUpActions';
import { ReactComponent as KeyWhite } from '../../assets/icons/key-white.svg';
import { ReactComponent as ReplicateIcon } from '../../assets/icons/ReplicateIcon.svg';
import { ReactComponent as Logout } from '../../assets/icons/Logout.svg';
import { ReactComponent as ConnectIcon } from '../../assets/images/connect-cluster.svg';
import { ReactComponent as WorkspaceIcon } from '../../assets/images/workspace.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/users.svg';
import { ReactComponent as GpuIcon } from '../../assets/images/gpu.svg';
import { ReactComponent as CostManagement } from '../../assets/images/cost-management.svg';
import { ReactComponent as Connect } from '../../assets/navbarIcons/Connect.svg';
import { ReactComponent as Dashboard } from '../../assets/navbarIcons/Dashboard.svg';
import { ReactComponent as Isolate } from '../../assets/navbarIcons/IsolateNS.svg';
import { ReactComponent as LicenseKey } from '../../assets/navbarIcons/LicenseKey.svg';

import { ReactComponent as Nodes } from '../../assets/navbarIcons/Nodes.svg';
import { ReactComponent as Rbac } from '../../assets/navbarIcons/Rbac.svg';
import { ReactComponent as Settings } from '../../assets/navbarIcons/Settings.svg';
import { ReactComponent as ResourceIcon } from '../../assets/navbarIcons/Vector.svg';
import {
  closedDrawerWidth,
  openDrawerWidth,
} from '../../constants/styleConstants';
import { ABOUT_US, EXTEND_LICENSE, LICENSING } from '../Body/Popups/types';
import AveshaBanner from './AveshaBanner';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from './MenuItemsStyle';
import { NavBarItem, NavBarSubItem } from './MenuItemUtils';

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? openDrawerWidth : closedDrawerWidth,
  overflow: 'visible',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  backgroundColor: '#12153E',
  '& div': {
    backgroundColor: '#12153E',
  },
  '& ul': {
    backgroundColor: '#12153E',
  },
  '& li': {
    backgroundColor: '#12153E',
  },
  '.MuiDrawer-paper': open
    ? {
        width: openDrawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'visible',
      }
    : {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'visible',
        width: closedDrawerWidth,
      },
  transition: open
    ? theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
}));

class MenuItems extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchPhrase: '',
      openSliceDialog: false,
      role: 'Admin',
      selectedItem: '/dashboard',
      expanded: 'connect',
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    const route = String(pathname);
    this.routeCheck(route);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    if (pathname !== prevProps.location.pathname) {
      const route = String(pathname);
      this.routeCheck(route);
    }
  }

  routeCheck = (route) => {
    switch (route) {
      case '/slice':
        this.setState({ selectedSlice: true, selectedItem: '/slices' });
        break;
      default:
        this.setState({ selectedItem: route });
    }
  };

  handleRedirect = (redirect_uri) => {
    this.props.history.push(`/${redirect_uri}`);
  };

  handleRedirectToNewUi = (redirect_uri) => {
    window.location.href = `/${redirect_uri}`;
  };
  render() {
    const { open } = this.props;
    const { selectedItem, expanded } = this.state;

    const licenseAction = 'Upgrade';

    const settingItems = [
      {
        name: 'About Us',
        selected: selectedItem === '/about-us',
        redirect: null,
        popUp: true,
        popUpType: ABOUT_US,
        icon: (
          <InfoIcon
            style={{
              color: selectedItem === '/about-us' ? '#ffffff' : '#D9DAE6',
            }}
          />
        ),
      },
      {
        name: 'License',
        selected: selectedItem === '/license', // this doesnot do anything
        redirect: null,
        popUp: true,
        popUpType: LICENSING,
        icon: (
          <Box
            component={'span'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20px',
              width: '20px',
              backgroundColor:
                selectedItem === '/license' ? '#ffffff' : '#D9DAE6',
              borderRadius: ' 50%',
            }}
          >
            <KeyWhite
              style={{
                color: selectedItem === '/license' ? '#ffffff' : '#D9DAE6',
                height: '8px',
                width: '8px',
                fill: 'black',
              }}
            />{' '}
          </Box>
        ),
      },
    ];

    const displayItems = ({
      selected,
      name,
      redirect,
      icon,
      popUp,
      popUpType,
    }) =>
      popUp === true ? (
        <NavBarSubItem
          key={name}
          selected={selected}
          label={name}
          onClick={() => this.props.openPopUp({ type: popUpType })}
          Icon={icon}
          open={open}
        />
      ) : (
        <NavBarSubItem
          key={name}
          selected={selected}
          label={name}
          onClick={() => this.handleRedirect(redirect)}
          Icon={icon}
          open={open}
        />
      );

    return (
      <CustomDrawer open={open} variant="permanent">
        <div
          style={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <List className="sidebar-scrollbar">
            <ListItem style={{ marginBottom: '1.5rem' }}>
              <AveshaBanner
                open={open}
                handleDrawerToggle={this.props.handleDrawerToggle}
                sx={{ paddingTop: '12px' }}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={Dashboard}
                label="Dashboard"
                selected={selectedItem === '/dashboard'}
                onClick={() => this.handleRedirect('dashboard')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={ConnectIcon}
                label="Clusters"
                selected={selectedItem.includes('/cluster')}
                onClick={() => this.handleRedirectToNewUi('cluster')}
                open={open}
              />
            </ListItem>
            {/* <ListItem>
              <NavBarItem
                Icon={ConnectIcon}
                label="Connect Cluster"
                selected={selectedItem.includes('/connect-cluster')}
                onClick={() => this.handleRedirect('connect-cluster')}
                open={open}
              />///
            </ListItem> */}
            <ListItem>
              <NavBarItem
                Icon={LocalPizzaIcon}
                label="Slices"
                isSlice
                selected={selectedItem === '/slices'}
                onClick={() => this.handleRedirectToNewUi('slices')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={GpuIcon}
                label="GPU Requests"
                selected={selectedItem === '/gpu-request'}
                onClick={() => this.handleRedirectToNewUi('gpu-request')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={WorkspaceIcon}
                label="AI Workloads"
                selected={selectedItem === '/workspace'}
                onClick={() => this.handleRedirectToNewUi('workspace')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={UsersIcon}
                label="Users"
                selected={selectedItem === '/users'}
                onClick={() => this.handleRedirectToNewUi('users')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <Accordion
                expanded={expanded == 'Namespaces'}
                onChange={() =>
                  this.setState({
                    expanded: expanded == 'Namespaces' ? null : 'Namespaces',
                  })
                }
              >
                <AccordionSummary
                  sx={{
                    display: 'flex',
                    marginLeft: '-8px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    backgroundColor: 'transparent !important',
                    '.MuiAccordionSummary-expandIconWrapper': {
                      marginRight: '-202px !important',
                      marginTop: '2px',
                      backgroundColor: 'transparent !important',
                      color: '#757d8a!important',
                      position: 'absolute',
                    },
                  }}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          selectedItem === '/manage-namespaces'
                            ? '#ffffff'
                            : '#ffffff',

                        marginRight: 0,
                        height: '64.2px',
                      }}
                    />
                  }
                >
                  <NavBarItem
                    isNamespace
                    Icon={Connect}
                    label="Namespaces"
                    open={open}
                    selected={
                      selectedItem === '/manage-namespaces' ||
                      selectedItem.includes('/manage-namespaces/add')
                    }
                    onClick={() => this.handleRedirect('manage-namespaces')}
                  ></NavBarItem>
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: '0px' }}>
                    <NavBarSubItem
                      Icon={
                        <Isolate
                          style={{
                            color: selectedItem.includes(
                              '/manage-namespaces/isolate-namespaces'
                            )
                              ? '#ffffff'
                              : '#D9DAE6',
                          }}
                        />
                      }
                      label="Isolation"
                      selected={selectedItem.includes(
                        '/manage-namespaces/isolate-namespaces'
                      )}
                      onClick={() =>
                        this.handleRedirect(
                          'manage-namespaces/isolate-namespaces'
                        )
                      }
                      open={open}
                    />
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={Nodes}
                label="Node Assignment"
                selected={
                  selectedItem === '/assign-nodes' ||
                  selectedItem.includes('/assign-nodes/')
                }
                onClick={() => this.handleRedirect('assign-nodes')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={ReplicateIcon}
                label="Replicate"
                selected={
                  selectedItem === '/Replicate' ||
                  selectedItem.includes('/Replicate/')
                }
                onClick={() => this.handleRedirectToNewUi('replicate')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={ResourceIcon}
                label="Resource Quotas"
                selected={
                  selectedItem === '/manage-resource' ||
                  selectedItem.includes('/manage-resources/add')
                }
                onClick={() => this.handleRedirect('manage-resource')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <NavBarItem
                Icon={CostManagement}
                label="Cost Management"
                selected={selectedItem.includes('/cost-management')}
                onClick={() => this.handleRedirectToNewUi('cost-management')}
                open={open}
              />
            </ListItem>
            <ListItem>
              <Accordion
                expanded={expanded == 'RBAC'}
                onChange={() =>
                  this.setState({
                    expanded: expanded == 'RBAC' ? null : 'RBAC',
                  })
                }
              >
                <AccordionSummary
                  sx={{
                    display: 'flex',
                    marginLeft: '-8px',
                    marginRight: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    backgroundColor: 'transparent !important',
                    '.MuiAccordionSummary-expandIconWrapper': {
                      marginRight: '-202px !important',
                      marginTop: '2px',
                      backgroundColor: 'transparent !important',
                      color: '#757d8a!important',
                      position: 'absolute',
                    },
                  }}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          selectedItem === '/manage-rbac' ||
                          selectedItem.includes('/manage-rbacs/add/')
                            ? '#ffffff'
                            : '#ffffff',

                        marginRight: 0,
                        height: '64.2px',
                      }}
                    />
                  }
                >
                  <NavBarItem
                    isNamespace
                    Icon={Rbac}
                    label="RBAC"
                    open={open}
                    selected={
                      selectedItem === '/manage-rbac' ||
                      selectedItem.includes('/manage-rbacs/add/')
                    }
                    onClick={() => this.handleRedirect('manage-rbac')}
                  ></NavBarItem>
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: '0px' }}>
                    <NavBarSubItem
                      Icon={
                        <Isolate
                          style={{
                            color: selectedItem.includes('/manage-roles')
                              ? '#FFF'
                              : '#D9DAE6',
                            fill: selectedItem.includes('/manage-roles')
                              ? '#FFF'
                              : '#D9DAE6',
                          }}
                        />
                      }
                      label="Roles"
                      selected={selectedItem.includes('/manage-roles')}
                      onClick={() => this.handleRedirect('manage-roles')}
                      open={open}
                    />
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <Accordion
                expanded={expanded == 'settings'}
                onChange={() =>
                  this.setState({
                    expanded: expanded == 'settings' ? null : 'settings',
                  })
                }
              >
                <AccordionSummary
                  sx={{
                    display: 'flex',
                    '.MuiAccordionSummary-expandIconWrapper': {
                      marginRight: '5px !important',
                      color: 'white !important',
                    },
                  }}
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: [''].includes(selectedItem) ? '#ffffff' : '#FFF',
                        marginRight: 0,
                      }}
                    />
                  }
                >
                  <NavBarItem Icon={Settings} label="Settings" open={open} />
                </AccordionSummary>
                <AccordionDetails>
                  <List style={{ padding: '0px' }}>
                    {settingItems.map(displayItems)}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem
              sx={{
                bottom: '1rem',
                display: 'flex',
                flexDirection: 'column !important',
              }}
            >
              <NavBarItem
                Icon={Logout}
                label="Logout"
                onClick={() => {
                  this.props.logoutUser();
                  this.props.clearCurrentSlice();
                  this.handleRedirect('');
                }}
                open={open}
              />
            </ListItem>
          </List>
          <List>
            <ListItem
              sx={{
                bottom: '1rem',
                display: 'flex',
                flexDirection: 'column !important',
              }}
            >
              <Button
                type="button"
                onClick={() => this.props.openPopUp({ type: EXTEND_LICENSE })}
                data-testid="sidebar-extend-license-btn"
                sx={{
                  flex: '1 1 0%',
                  mx: '16px',
                  py: 1.25,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  borderRadius: '4px',
                  textTransform: 'capitalize',
                  backgroundColor: '#DEE8FF',
                  fontWeight: 600,
                  color: '#2868F3',

                  ':hover': {
                    backgroundColor: '#DEE8FF',
                    color: '#2868F3',
                  },
                }}
              >
                <LicenseKey style={{ height: '24', width: '24' }} />
                {open && <Box component="span">{licenseAction} License</Box>}
              </Button>
            </ListItem>
            <ListItem
              sx={{
                bottom: '1rem',
                display: 'flex',
                flexDirection: 'column !important',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  py: 2,
                }}
              >
                {open ? <Logo style={{ width: '80px' }} /> : <MiniLogo />}
              </Box>
            </ListItem>
          </List>
        </div>
      </CustomDrawer>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sliceList: store.slices.sliceList,
    popUp: store.popup.popup,
    licenseDetails: store.licensing.licenseDetails,
    licenseStatus: store.licensing.licenseStatus,
  };
};
const mapDispatchToProps = {
  updateMenu,
  getAllSlices,
  createSlice,
  getAllQOSProfiles,
  openPopUp,
  closePopUp,
  logoutUser,
  clearCurrentSlice,
};
export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(MenuItems)
);
