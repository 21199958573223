import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getSliceCustomObjectList } from '../../actions/ConnectClusterActions';
import {
  clearSystemEvent,
  getAllSliceNamespaces,
  getClusterCapacityDetail,
  getClusterDetail,
  getSystemEvent,
} from '../../actions/dashboardActions';
import { ReactComponent as NotificationsIcon } from '../../assets/navbarIcons/notifications.svg';
import { BodyRoot } from '../Body/BodyStyles';
import CurrentProject from '../CurrentProject';
import VCpu from '../VCpu';
import TabPanelDashboard from './TabPanelDashboard';
import TopbarSpinner from './TopbarSpinner';
import { SliceUserNames } from '../../constants/slice-user-names';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    top: '1px',
    marginLeft: '2%',
    marginRight: '2%',
    padding: '0% 2% 2% 2%',
  },
  welcomeContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '3%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardContainer: {
    margin: '5px',
    width: '200px',
    borderRadius: '14px',
  },
  cardContent: {
    display: 'flex',
  },
  fab: {
    margin: '0 5px',
    marginTop: '0px',
    marginLeft: '0px',
    transform: 'scale(0.8)',
    textTransform: 'none',
  },
  buttonAction: {
    textAlign: 'left',
    fontWeight: 500,
  },
  btnClass: {
    borderRadius: '12px',
    color: '#05CD99',
    backgroundColor: '#dfefeb',
    width: '80px',
    '&:hover': {
      backgroundColor: '#c3e2da',
    },
  },
  btnInactive: {
    borderRadius: '12px',
    color: 'red',
    backgroundColor: '#f9dbdb',
    width: '80px',
    '&:hover': {
      backgroundColor: '#f9dbdb',
    },
  },
}));
const severity = [
  'SEVERITY_INFO',
  'SEVERITY_WARNING',
  'SEVERITY_ERROR',
  'SEVERITY_CRITICAL',
];
function Dashboard(props) {
  const classes = useStyles();
  const {
    getClusterDetail,
    getClusterCapacityDetail,
    getAllSliceNamespaces,
    slices,
    getAllSlices,
    getSystemEvent,
    clearSystemEvent,
    getSliceCustomObjectList,
  } = props;
  // useEffect;
  const userDetail = useSelector((state) => state.UserReducer);
  const [value, setValue] = useState(0);

  let isAdmin = false;
  let sliceName = ''
  if (localStorage.getItem('isAdmin') !== null) {
     isAdmin = JSON.parse(localStorage.getItem('isAdmin') || '');
     sliceName = localStorage.getItem('sliceName');
  }

  useEffect(() => {
    getClusterDetail();
    //getClusterCapacityDetail();
    getAllSliceNamespaces();
    //clearSystemEvent(); //clear all system event from state before calling the getsystemevent
    //calling all system events of slice
    // severity.forEach((severe) => {
    //   getSystemEvent({
    //     entityType: "ENTITY_TYPE_SLICES",
    //     severity: severe,
    //   });
    // });
  }, []);
  useEffect(() => {
    <CurrentProject />;
  }, []);

  const storedData = localStorage?.getItem('currentProjectData');
  const currentProjectData = JSON?.parse(storedData);

  const handleRedirectToNewUi = () => {
    window.location.href = `/events`;
  };
  return (
    <BodyRoot
      style={{ backgroundColor: '#f4f7fe', fontFamily: 'Bai Jamjuree' }}
    >
      <Grid item className="header">
        <Paper
          sx={{
            padding: '8px 40px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '30px',
                color: '#12153E',
              }}
            >{`Hello ${
              typeof userDetail.operatorName != 'undefined'
                ? userDetail.operatorName
                : ''
            }`}</Typography> */}

            <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px', // Adjust the font size as per your requirement
                lineHeight: '20px',
                color: '#6E708E',
                paddingTop: '4px',
              }}
              variant="subtitle1"
            >
              {currentProjectData?.projectNamespace ?? ''}
              <span style={{ fontWeight: 600 }}> / Dashboard</span>
            </Typography>
          </div>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <TopbarSpinner />
            <VCpu />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {isAdmin
                ? 'Admin'
                : `${
                    SliceUserNames[sliceName]
                      ? SliceUserNames[sliceName]
                      : ''
                  }`}
              <NotificationsIcon
                style={{ cursor: 'pointer' }}
                onClick={handleRedirectToNewUi}
              />
            </div>
          </Box>
        </Paper>
      </Grid>
      <div className={classes.root}>
        {/* <div style={{ display: "flex", paddingTop: "20px" }}> */}
        {/* <div id="greetings" className={classes.welcomeContent}> */}

        {/* </div> */}
        {/* <Notification /> */}
        {/* </div> */}
        {/* <ClusterMetadata /> */}
        <Box
          id="topology"
          sx={{
            border: '1px solid #B6B8D6',
            height: 'fit-content',
            borderRadius: '10px',
            marginTop: '16px',
            backgroundColor: '#fff',
          }}
        >
          <TabPanelDashboard
            value={value}
            onChange={(_value) => {
              setValue(_value);
            }}
          />
        </Box>
        {value === 0 && (
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: '2rem' }}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={6}>
              {/* <ClusterDetails /> */}
            </Grid>
            <Grid item xs={6}>
              {/* <ClusterCapacity /> */}
            </Grid>
          </Grid>
        )}
      </div>
    </BodyRoot>
  );
}

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
  };
};

export default connect(mapStateToProps, {
  getClusterDetail,
  getClusterCapacityDetail,
  getAllSliceNamespaces,
  getSystemEvent,
  clearSystemEvent,
  getSliceCustomObjectList,
})(Dashboard);
