import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertModal = ({ handleClose, open = false }) => {

    return (
        <Dialog
            sx={{
                maxWidth: '528px',
                margin:'0px auto',
                minHeight:'252px'
            }}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className='modal-title' id="alert-dialog-title">
            The namespace quotas exceed the slice quotas. Please ensure they are within the slice quota.
            </DialogTitle>
            <DialogActions>
                <Button className='cancel-btn' onClick={() => handleClose(true)} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertModal;