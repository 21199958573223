import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';

const useConfirm = () => {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // You could replace the Dialog with your library's version
  const ConfirmationDialog = ({
    title,
    message,
    confirmBtnText = 'Yes',
    cancelBtnText = 'Cancel',
  }) => (
    <Dialog open={promise !== null} fullWidth>
      <DialogTitle className='modal-title' id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent className='modal-desc' id='alert-dialog-description'>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='cancel-btn' onClick={handleConfirm}>
          {confirmBtnText}
        </Button>
        <Button className='submit-btn' onClick={handleCancel}>
          {cancelBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return [ConfirmationDialog, confirm];
};

export default useConfirm;
