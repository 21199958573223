const checkNameError = (value) => {
  let msg = '';
  if (!value) {
    msg = 'Slice Name is Required';
  } else if (value[0]?.toUpperCase() === value[0]) {
    msg = 'Capital letters not allowed';
  } else if (/\s/.test(value)) {
    msg = 'Spaces are not allowed';
  }
  return msg;
};

const checkmaxClustersError = (value) => {
  let msg = '';
  if (value > 32) {
    msg = 'Max cluster value is 32';
  } else if (/\D/.test(value)) {
    msg = 'Enter numbers';
  } else if (value == 1) {
    msg = 'minimum cluster value should be 2';
  }
  return msg;
};

const checkSubnetError = (value) => {
  let msg = '';

  if (!value) {
    msg = 'Slice Subnet is Required';
  } else if (
    !/((\b|\.)(0|1|2(?!5(?=6|7|8|9)|6|7|8|9))?\d{1,2}){4}(-((\b|\.)(0|1|2(?!5(?=6|7|8|9)|6|7|8|9))?\d{1,2}){4}|\/((0|1|2|3(?=1|2))\d|\d))\b/g.test(
      value
    )
  ) {
    msg = 'Incorrect Subnet Format';
  }
  return msg;
};

const errorMessages = {
  sliceType: 'Slice Type Required',
  sliceGWType: 'Slice Gateway Type Required',
  sliceIpamType: 'Slice IPAM Type Required',
  sliceQosProfile: 'Slice Qos Profile Required',
  sliceCertAuthType: 'Slice Qos Cert Auth Type Required',
  sliceQosPriority: 'Slice Qos Profile Priority Required',
  sliceQosType: 'Slice Qos Type Required',
  sliceQosTcType: 'Slice Qos Tc Type Required',
  bandwidthCeilingKbps: 'Slice Qos Bandwidth Ceiling Required',
  bandwidthGuaranteedKbps: {
    required: 'Slice Qos Bandwidth Guaranteed Required',
    greaterThan: 'Guaranteed Value can not be greater than Ceiling value',
  },
  dscpClass: 'Slice Qos Dscp Class Required',
};

export {
  checkNameError,
  checkSubnetError,
  checkmaxClustersError,
  errorMessages,
};
