import { AccessTimeFilled } from '@mui/icons-material';
import { Box, Button, Link, Modal } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReactComponent as KeyWhite } from '../../../assets/icons/key-white.svg';
import { license_docs, license_status } from '../../../constants/license';
import {
  selectLicenseDetails,
  selectLicenseStatus,
} from '../../../selectors/licensing.selector';
import { formatDate } from '../../../utils/date-time';
import { createUpgradeLicenseLink, isEnterpriseLicense } from '../../../utils/license';
import LicenseId from '../LicenseId/LicenseId';
import { InvalidModal } from './InvalidModal';

// everything is inline beacuse we need to do this in v2 as well
const ExtendLicenseModal = ({ open, handleClose }) => {
  const licenseDetails = useSelector(selectLicenseDetails);
  const licenseStatus = useSelector(selectLicenseStatus);

  if (licenseStatus === license_status.invalid || !licenseDetails) {
    return <InvalidModal open={open} handleClose={handleClose} />;
  }

  const {
    'license-expiration': expirationDate,
    'license-expiration': licenseExpiration,
  } = licenseDetails;

  const daysLeft = moment(licenseExpiration).diff(moment(), 'days');

  const expiring = daysLeft <= 20;

  const severe = daysLeft <= 15;
  const expired = licenseStatus === 'expired';

  let color = '#2868F3';

  if (severe) {
    color = '#E15E5E';
  } else if (expiring) {
    color = '#A5673F';
  }

  const href = createUpgradeLicenseLink(licenseDetails, "extend_modal");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 'none',
      }}
    >
      <Box
        data-testid="extend-license-modal"
        data-status={expiring ? (severe ? 'severe' : 'expiring') : 'valid'}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white !important',
          width: '600px',
          borderTop: `3px solid ${color}`,
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: '#F6F9FF',
            bgOpacity: '0.6',
            pl: '38px',
            py: '38px',
            pr: '60px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <AccessTimeFilled
              sx={{
                fill: color,
                fontSize: 28,
                height: '34px',
                width: '34px',
              }}
              data-color={color}
            />
            <Box
              sx={{
                color: color,
              }}
            >
              <Box
                data-testid="modal-title"
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                {licenseDetails['license-type']}{' '}
                {expired ? 'Expired' : 'Expires'} on{' '}
                {formatDate(expirationDate)}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pt: '28px',
            pb: '38px',
            px: '38px',
            borderTop: '1px solid #D9DAE6',
            color: '#5A607C',
          }}
        >
          {isEnterpriseLicense(licenseDetails) ? (
            <>
              <Box
                sx={{
                  color: '#828490',
                }}
              >
                To upgrade vCPU bundle or extend your license, click on the
                Upgrade License button.
              </Box>
              <Box
                sx={{
                  mt: 1.25,
                  color: '#828490',
                }}
              >
                Please contact Avesha support <b> support@avesha.io</b> for any
                request/queries regarding your license.
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  color: '#828490',
                }}
              >
                To upgrade to enterprise license click the Upgrade License
                button
              </Box>
              <Box
                sx={{
                  mt: 1.25,
                  color: '#828490',
                }}
              >
                Please contact Avesha support <b> support@avesha.io</b> for any
                request/queries regarding your license.
              </Box>
            </>
          )}
          <Box
            sx={{
              mt: '35px',
            }}
          >
            <LicenseId />
          </Box>
          <Box
            sx={{
              mt: '12px',
              color: '#828490',
            }}
          >
            Learn more about licensing{' '}
            <Link
              href={license_docs}
              sx={{
                color: '#2868F3 !important',
                '&:hover': {
                  color: '#2868F3 !important',
                },
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </Link>
          </Box>
          <Box
            sx={{
              pt: '35px',
              display: 'flex',
              gap: '14px',
              justifyContent: 'end',
            }}
          >
            <Button
              sx={{
                backgroundColor: '#fff !important',
                border: '1px solid #B6B8D6',
                textTransform: 'capitalize',
                px: '24px',
                py: '16px',
                fontWeight: '600',
                color: 'black',
              }}
              type="button"
              data-testid="cancel-button"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Link
              href={href}
              target="_blank"
              underline="none"
              sx={{
                color: '#fff',
                backgroundColor: 'transparent',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: 'transparent',
                  textDecoration: 'none',
                },
              }}
              data-testid="upgrade-license-button"
            >
              <Button
                sx={{
                  backgroundColor: '#DEE8FF',
                  border: '0px',
                  textTransform: 'capitalize',
                  px: '24px',
                  py: '16px',
                  fontWeight: '600',
                  display: 'flex',
                  gap: '8px',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#DEE8FF',
                    textDecoration: 'none',
                  },
                }}
                type="button"
              >
                <KeyWhite
                  style={{
                    height: '24px',
                    fill: '#2868F3',
                  }}
                />
                <Box
                  sx={{
                    color: '#2868F3',
                  }}
                  component="span"
                >
                  Upgrade License
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExtendLicenseModal;
