import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { connect } from "react-redux";
import {
  getNodeCapacityDetail,
  getNamespaceCapacityDetail,
} from "../../../actions/dashboardActions";
import TreeMapChart from "./TreeMapChart";
import styled from "styled-components";
import MetricsDropdown from "../MetricsDropdown";
import { ALL_SLICES_KEY, CLUSTER_COMPARE_KEY } from "../../../constants/enums";

const Square = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
const LabelText = styled.div`
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const NodesView = (props) => {
  // state and variable declarations
  const [data, setData] = useState([]);
  const [clusterName, setClusterName] = useState("");
  const {
    clusterDetail,
    getNodeCapacityDetail,
    getNamespaceCapacityDetail,
    slice,
  } = props;

  //useEffect

  const clusterInfo = useMemo(
    () =>
      slice?.sliceName === ALL_SLICES_KEY
        ? clusterDetail
        : clusterDetail.filter((cluster) =>
            cluster.slices.find(
              (currSlice) => currSlice.name === slice.sliceName
            )
          ),
    [clusterDetail,slice]
  );

  useEffect(() => {
    const setTreeMapData = async (clusterName) => {
      if (clusterName.length > 0) {
        const treeMapData = await generateTreeMapData(clusterName);
        setData(treeMapData);
      }
    };
    if (clusterDetail?.length > 0) {
      setClusterName(clusterDetail.clusterName); //pass cluster Name
      setTreeMapData(clusterDetail.clusterName); //same here
    }
  }, [slice?.sliceName, clusterInfo]);

  //generate tree map data

  const generateTreeMapData = async (clusterName) => {
    //let selectedCluster = clusterCapacity.find(o => o.clusterId === clusterId);
    try {
      const nodeCapacity = await getNodeCapacityDetail(clusterName);

      let treeMapData = [];
      if (nodeCapacity?.nodes?.length) {
        for (let node of nodeCapacity.nodes) {
          const nameSpaceCapacity = node.namespaces;
          let children = [];
          if (nameSpaceCapacity.length) {
            for (let namespace of nameSpaceCapacity) {
              children.push({
                name: namespace.NamespaceName,
                path: `${clusterName}/${node.NodeName}/${namespace.NmespaceName}`,
                value: namespace.CpuUtilization,

                itemStyle: {
                  color:
                    node.NodeType === "NODE_TYPE_AVESHA_MANAGED"
                      ? "#a7cbff"
                      : "#f5d29e",
                },
              });
            }
          }
          treeMapData.push({
            name: node.NodeName,
            path: `${clusterName}/${node.NodeName}`,
            value: node.CpuUtilization,
            itemStyle: {
              color:
                node.NodeType === "NODE_TYPE_AVESHA_MANAGED"
                  ? "#a7cbff"
                  : "#f5d29e",
            },
            children,
          });
        }
      }
      return [treeMapData, clusterName];
    } catch (err) {
      //have to add message here
    }
  };

  // on cluster select
  const onClusterSelect = async (cluster) => {
    // cluster.preventDefault();
    setClusterName(cluster.clusterName);
    const treeMapData = await generateTreeMapData(cluster.clusterName);
    setData(treeMapData);
  };


  return (
    <>
      {
        // clusterCapacity?.length>0
        true ? (
          <div>
            <Grid container>
              {/* <Box mr={20}> */} <Grid container justifyContent="flex-end">
                <MetricsDropdown
                  label={"Cluster"}
                  width={"220px"}
                  activeOption={(option) => option.clusterName || ""}
                  compareOptionKey={CLUSTER_COMPARE_KEY}
                  value={clusterName}
                  handleUpdateActiveOption={onClusterSelect}
                  options={clusterInfo}
                  placeholder={clusterName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={9}

                  style={{
                    color: "#12153E",
                    fontSize: "16px",
                    fontWeight: "normal",
                    lineHeight: "20px",
                    display: "flex",
                    alignItems: "left",
                    marginTop: "16px",
                    marginLeft: "20px",
                  }}
                  >
                  Node utilisation
              </Grid>
             
              {/* <Grid item xs={12} sm={6} md={3}>
                {clusterDetail?.length > 0 && (
                  <FormControl style={{ minWidth: "175px", left: 100 }}>
                    <InputLabel id="cluster-select-label">Cluster</InputLabel>
                    <Select
                      labelId="cluster-select"
                      id="cluster-select"
                      value={clusterName}
                      label="Cluster"
                      autoWidth
                      onChange={onClusterSelect}
                    >
                      {clusterDetail?.length > 0 &&
                        clusterDetail.map((cluster) => (
                          <MenuItem
                            key={cluster.clusterName}
                            value={cluster.clusterName}
                          >
                            {cluster.clusterName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid> */}
              
              {/* </Box> */}
            </Grid>
            {data && data.length > 0 && data[0].length > 0 ? (
              <div>
                <div style={{ height: "100%", width: "100%" }}>
                  <TreeMapChart data={data} />
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <Square color="#a7cbff" />
                    <LabelText>Kubeslice Managed Nodes</LabelText>
                    <Square color="#f5d29e" style={{ marginLeft: "15px" }} />
                    <LabelText>Non-Kubeslice Managed Nodes</LabelText>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                No node data available
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            No cluster detail available
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
    slice: state.dashboard.activeSelectedSlice,
  };
};

export default connect(mapStateToProps, {
  getNodeCapacityDetail,
  getNamespaceCapacityDetail,
})(NodesView);
