import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { BodyRoot } from '../../Body/BodyStyles';
import ClusterSelect from './ClusterSelect';
import Finalize from './Finalize';
import SliceDetails from './SliceDetails';

const Step = ({ stepNumber, title, subtext, selected, onSelect }) => (
  <Grid
    height="100%"
    item
    xs
    backgroundColor={selected ? '#2868F3' : '#ffffff'}
    container
    borderRadius="14px"
    p={2}
    onClick={onSelect}
    style={{ cursor: 'pointer' }}
  >
    <Grid
      height="100%"
      item
      xs
      container
      direction="column"
      rowGap={1}
      flexWrap="nowrap"
    >
      <Grid
        item
        xs={4}
        container
        alignItems="center"
        columnGap={1}
        flexWrap="nowrap"
        width="100%"
      >
        <Typography
          backgroundColor={selected ? '#ffffff' : 'black'}
          borderRadius="100%"
          sx={{
            aspectRatio: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          fontSize="16px"
          height="20px"
          width="20px"
          color={selected ? '#2868F3' : '#ffffff'}
        >
          {stepNumber}
        </Typography>
        <Typography
          color={selected ? '#ffffff' : 'black'}
          fontSize="16px"
          fontWeight="600"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item container xs={8}>
        <Typography color={selected ? '#ffffff' : 'black'} fontSize="14px">
          {subtext}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
const StepLayout = () => {
  const { stepNo } = useParams();
  const history = useHistory();
  const [selected, setSelected] = React.useState(+stepNo);

  useEffect(() => {
    setSelected(+stepNo);
  }, [stepNo]);

  const steps = [
    {
      stepNumber: 1,
      title: 'Enter Slice Details',
      subtext: 'Create a new slice or modify an existing slice',
    },
    {
      stepNumber: 2,
      title: 'Choose Clusters to Connect',
      subtext: 'Choose the clusters that are to be connected with a slice.',
    },
    {
      stepNumber: 3,
      title: 'Finalize',
      subtext: 'See the status of the Slice',
    },
  ];

  return (
    <BodyRoot>
      <Prompt
        message={(location) => {
          if (
            !location.pathname.includes('connect-cluster/slice') &&
            stepNo == 2
          ) {
            return 'You have unsaved changes. Do you want to discard them?';
          }
          return true;
        }}
      />

      <Grid
        container
        item
        xs
        height="100%"
        width="100%"
        p="44px"
        flexDirection="column"
        backgroundColor="#f3f7fe"
        rowGap={4}
        flexWrap="nowrap"
        overflow="auto"
      >
        <Grid
          item
          width="100%"
          height="fit-content"
          container
          bgcolor="#ffffff"
          borderRadius="14px"
        >
          {steps.map(({ stepNumber, title, subtext }) => (
            <Step
              key={stepNumber}
              stepNumber={stepNumber}
              title={title}
              subtext={subtext}
              selected={selected === stepNumber}
              onSelect={() => {
                history.push(`/connect-cluster/slice/${stepNumber}`);
              }}
            />
          ))}
        </Grid>
        {selected === 1 && (
          <Grid item xs container overflow="auto">
            <SliceDetails />
          </Grid>
        )}
        {selected === 2 && (
          <Grid item xs container>
            <ClusterSelect />
          </Grid>
        )}
        {selected === 3 && (
          <Grid item xs container>
            <Finalize />
          </Grid>
        )}
      </Grid>
    </BodyRoot>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(StepLayout);
