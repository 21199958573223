import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
} from '@mui/material';
import ClusterTabRow from './ClusterTabRow';
import { connect } from 'react-redux';
import {
  getKubernetesDashboard,
  getClusterDetail,
} from '../../../actions/dashboardActions';
import { startSpinner, stopSpinner } from '../../../actions/SpinnerActions';
import moment from 'moment';
import {
  CLUSTER_COMPARE_KEY,
  SLICE_COMPARE_KEY,
} from '../../../constants/enums';

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: "12px",
  padding: "15px 0px 15px 24px",
  backgroundColor: "#DEE8FF",
  alignItems: "center",
  fontWeight: 600, 
  lineHeight: "15px",

}));

const utilityBtnStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.75rem 0.75rem',
  cursor: 'pointer',
};

const IFrame = ({ uri, resized = false, handleOnLoad }) => {
  return (
    <iframe
      width="100%"
      style={{
        ...(!resized ? { minHeight: 'inherit' } : { height: '100%' }),
        border: 'none',
      }}
      src={uri}
      title="Kubernetes Dashboard"
      onLoad={() => handleOnLoad()}
    ></iframe>
  );
};

const IFrameHeader = ({ handleOpen, handleClose, float }) => {
  return (
    <div
      style={{
        ...(float && {
          position: 'absolute',
          right: '2rem',
          top: '3rem',
          opacity: 0.7,
          padding: '5px 15px',
          background: '#F6F6F6',
        }),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '50px',
        background: float ? '#F6F6F6' : '#FFFFFF',
      }}
    >
      <div style={utilityBtnStyle} onClick={() => handleOpen()}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5C5.26522 2 5.51957 1.89464 5.70711 1.70711C5.89464 1.51957 6 1.26522 6 1C6 0.734784 5.89464 0.48043 5.70711 0.292893C5.51957 0.105357 5.26522 0 5 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6C1.26522 6 1.51957 5.89464 1.70711 5.70711C1.89464 5.51957 2 5.26522 2 5V3ZM2 15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H5C5.26522 16 5.51957 16.1054 5.70711 16.2929C5.89464 16.4804 6 16.7348 6 17C6 17.2652 5.89464 17.5196 5.70711 17.7071C5.51957 17.8946 5.26522 18 5 18H3C2.20435 18 1.44129 17.6839 0.87868 17.1213C0.316071 16.5587 0 15.7956 0 15V13C0 12.7348 0.105357 12.4804 0.292893 12.2929C0.48043 12.1054 0.734784 12 1 12C1.26522 12 1.51957 12.1054 1.70711 12.2929C1.89464 12.4804 2 12.7348 2 13V15ZM15 2C15.2652 2 15.5196 2.10536 15.7071 2.29289C15.8946 2.48043 16 2.73478 16 3V5C16 5.26522 16.1054 5.51957 16.2929 5.70711C16.4804 5.89464 16.7348 6 17 6C17.2652 6 17.5196 5.89464 17.7071 5.70711C17.8946 5.51957 18 5.26522 18 5V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0H13C12.7348 0 12.4804 0.105357 12.2929 0.292893C12.1054 0.48043 12 0.734784 12 1C12 1.26522 12.1054 1.51957 12.2929 1.70711C12.4804 1.89464 12.7348 2 13 2H15ZM16 15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H13C12.7348 16 12.4804 16.1054 12.2929 16.2929C12.1054 16.4804 12 16.7348 12 17C12 17.2652 12.1054 17.5196 12.2929 17.7071C12.4804 17.8946 12.7348 18 13 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V13C18 12.7348 17.8946 12.4804 17.7071 12.2929C17.5196 12.1054 17.2652 12 17 12C16.7348 12 16.4804 12.1054 16.2929 12.2929C16.1054 12.4804 16 12.7348 16 13V15Z"
            fill="black"
          />
        </svg>
      </div>
      <div style={utilityBtnStyle} onClick={() => handleClose()}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.40994 7.00019L12.7099 2.71019C12.8982 2.52188 13.004 2.26649 13.004 2.00019C13.004 1.73388 12.8982 1.47849 12.7099 1.29019C12.5216 1.10188 12.2662 0.996094 11.9999 0.996094C11.7336 0.996094 11.4782 1.10188 11.2899 1.29019L6.99994 5.59019L2.70994 1.29019C2.52164 1.10188 2.26624 0.996094 1.99994 0.996094C1.73364 0.996094 1.47824 1.10188 1.28994 1.29019C1.10164 1.47849 0.995847 1.73388 0.995847 2.00019C0.995847 2.26649 1.10164 2.52188 1.28994 2.71019L5.58994 7.00019L1.28994 11.2902C1.19621 11.3831 1.12182 11.4937 1.07105 11.6156C1.02028 11.7375 0.994141 11.8682 0.994141 12.0002C0.994141 12.1322 1.02028 12.2629 1.07105 12.3848C1.12182 12.5066 1.19621 12.6172 1.28994 12.7102C1.3829 12.8039 1.4935 12.8783 1.61536 12.9291C1.73722 12.9798 1.86793 13.006 1.99994 13.006C2.13195 13.006 2.26266 12.9798 2.38452 12.9291C2.50638 12.8783 2.61698 12.8039 2.70994 12.7102L6.99994 8.41019L11.2899 12.7102C11.3829 12.8039 11.4935 12.8783 11.6154 12.9291C11.7372 12.9798 11.8679 13.006 11.9999 13.006C12.132 13.006 12.2627 12.9798 12.3845 12.9291C12.5064 12.8783 12.617 12.8039 12.7099 12.7102C12.8037 12.6172 12.8781 12.5066 12.9288 12.3848C12.9796 12.2629 13.0057 12.1322 13.0057 12.0002C13.0057 11.8682 12.9796 11.7375 12.9288 11.6156C12.8781 11.4937 12.8037 11.3831 12.7099 11.2902L8.40994 7.00019Z"
            fill="#12153E"
          />
        </svg>
      </div>
    </div>
  );
};

class ClusterTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKubeDash: false,
      open: false,
      allClusterResponse: null,
      clusterDetails: null,
    };
  }

  handleOpen = () => {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  };

  handleClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: false, activeKubeDash: null });
    }
  };

  redirectK8Dash = async (clusterName) => {
    try {
      const { redirectUrl } = await this.props.getKubernetesDashboard(
        clusterName
      );
      if (redirectUrl) {
        this.setState({
          activeKubeDash: redirectUrl,
        });
        this.props.startSpinner();
      }
    } catch (error) {
      console.log('Req Error: ', error);
    }
  };

  onLoadComplete = () => {
    this.props.stopSpinner();
  };

  fetchClusterCapacityDetails = async () => {
    try {
      const end = moment().format('X');
      const start = moment().subtract(3, 'hour').format('X');

      const clusterResponse = await this.props.getClusterDetail(start, end);
      this.setState({
        ...this.state,
        allClusterResponse: clusterResponse,
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  fitlerClusterMetrics() {
    const clusters = this.props.clusterDetail
      .filter((el) =>
        el.slices.some(
          (slice) =>
            slice.name === this.props.activeSelectedSlice[SLICE_COMPARE_KEY]
        )
      )
      .map((el) => el[CLUSTER_COMPARE_KEY]);
    const filteredClusterDetails = this.state.allClusterResponse?.filter((el) =>
      clusters.includes(el[CLUSTER_COMPARE_KEY])
    );
    this.setState({
      ...this.state,
      clusterDetails: filteredClusterDetails,
    });
  }

  componentDidMount() {
    // Fetching all cluster details
    this.fetchClusterCapacityDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.allClusterResponse !== this.state.allClusterResponse ||
      prevProps.activeSelectedSlice !== this.props.activeSelectedSlice
    ) {
      this.fitlerClusterMetrics();
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.activeKubeDash ? (
          <TableContainer
            sx={{ height: '100%', borderBottom: 'solid #DEE8FF 5px' }}
          >
            <Table
              stickyHeader
              aria-label="Cluster Table"
              size="small"
              sx={{ fontSize: '12px' }}
              style={{ tableLayout: 'fixed' }}
            >
              <TableHead sx={{ backgroundColor: '#DEE8FF' }}>
                <TableRow>
                  <StyledTableCell>CLUSTER NAME</StyledTableCell>
                  <StyledTableCell>CLOUD NAME</StyledTableCell>
                  <StyledTableCell>CAPACITY</StyledTableCell>
                  <StyledTableCell>KUBERNETES DASHBOARD</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.clusterDetails &&
                  this.state.clusterDetails.length > 0 &&
                  this.state.clusterDetails.map((row, index) => {
                    return (
                      <ClusterTabRow
                        key={index}
                        row={row}
                        handleK8Nav={(clusterName) =>
                          this.redirectK8Dash(clusterName)
                        }
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              border: 'none',
              ...(this.state.open
                ? {
                    position: 'fixed',
                    width: '100%',
                    top: 0,
                    left: 0,
                    height: '100%',
                    zIndex: 90,
                  }
                : {
                    minHeight: 'inherit',
                    width: '100%',
                  }),
            }}
          >
            <IFrameHeader
              handleOpen={this.handleOpen}
              handleClose={this.handleClose}
              float={this.state.open}
            />
            <IFrame
              uri={this.state.activeKubeDash}
              resized={this.state.open}
              handleOnLoad={() => this.onLoadComplete()}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
    activeSelectedSlice: state.dashboard.activeSelectedSlice,
  };
};

export default connect(mapStateToProps, {
  getKubernetesDashboard,
  getClusterDetail,
  startSpinner,
  stopSpinner,
})(ClusterTab);
