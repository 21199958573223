import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import MetricForm from "./MetricForm";
import styled from "styled-components";
import { connect } from "react-redux";
import { updateEnforcementPointMetric } from "../../../actions/enforcementActions";
const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ForwardArrow = styled(IconButton)`
  position: absolute;
  right: 1rem;
`;
const BackwardArrow = styled(IconButton)`
  position: absolute;
  left: 1rem;
`;
class EnforcementPointMetricConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      totalSteps: 0,
      data: {},
      services: [],
    };
  }

  componentDidMount() {
    this.parseData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps?.selectedEnforcementPoint && this.props.selectedEnforcementPoint) this.parseData();
  }
  parseData = () => {
    let data = {};
    let services = [];
    if (!this.props?.selectedEnforcementPoint?.enforcementPointMetrics?.length) return;
    const {
      selectedEnforcementPoint: { enforcementPointMetrics },
    } = this.props;
    for (let i in enforcementPointMetrics) {
      services.push(enforcementPointMetrics[i]?.serviceName);
      data[enforcementPointMetrics[i]?.serviceName] = enforcementPointMetrics[i]?.metricValues;
    }
    this.setState({ data, services, totalSteps: services?.length });
  };
  handleSave = () => {
    const { data, currentStep, services } = this.state;
    const currentService = services[currentStep];
    let _data = {
      serviceName: currentService,
      metricValues: data[currentService],
    };
    this.props.updateEnforcementPointMetric(this.props.sliceName, this.props.selectedEnforcementPoint.canonicalName, _data);
    this.nextStep();
  };
  nextStep = () => {
    const { currentStep, totalSteps } = this.state;
    if (currentStep == totalSteps - 1) {
      this.setState({ currentStep: 0 });
      this.props.handleClose();
    } else this.setState({ currentStep: this.state.currentStep + 1 });
  };
  prevStep = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep ? this.state.currentStep - 1 : currentStep });
  };
  handleMetricChange = (newMetrics) => {
    const { data, currentStep, services } = this.state;
    const currentService = services[currentStep];
    let _data = { ...data };
    _data[currentService] = newMetrics;
    this.setState({ data: _data });
  };
  render() {
    const { open, handleClose } = this.props;
    const { handleSave, handleMetricChange } = this;
    const { currentStep, totalSteps, services, data } = this.state;
    const isFirstStep = currentStep == 0;
    const isLastStep = currentStep == totalSteps - 1;
    const currentService = services[currentStep];
    return (
      <div>
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="lg">
          <StyledTitle>
            {!isFirstStep && (
              <BackwardArrow onClick={this.prevStep}>
                <ArrowBackIcon />
              </BackwardArrow>
            )}
            <h3>Enforcement Point Metrics</h3>
            {!isLastStep && (
              <ForwardArrow onClick={this.nextStep}>
                <ArrowForwardIcon />
              </ForwardArrow>
            )}
          </StyledTitle>
          <DialogContent dividers style={{ height: "50vh", width: "50vw" }}>
            <MetricForm metrics={data[currentService]} service={currentService} onChange={handleMetricChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save {!isLastStep && "and Proceed"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(null, { updateEnforcementPointMetric })(EnforcementPointMetricConfiguration);
