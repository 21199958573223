import moment from 'moment';
import PieChart from './PieChart';
import _ from 'lodash';

const NoNetwork = ({ sliceData = [], clusterList = [], sliceName = '' }) => {
  console.log(sliceData, clusterList);

  const clusterArrData = [];
  const sliceWorkerData =
    sliceData?.items?.map((sliceItem) => {
      return sliceItem?.metadata?.labels['worker-cluster'];
    }) || [];

  clusterList?.items?.forEach((cluster) => {
    if (_.has(cluster, 'status')) {
      if (sliceWorkerData.includes(cluster?.metadata?.name)) {
        clusterArrData.push({
          data: [],
          lastUpdated: cluster?.status?.clusterHealth?.lastUpdated,
          clusterName: cluster?.metadata?.name,
          cloudName:
            cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'LINODE' ||
            cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'Akamai'
              : cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider,
        });
      }
    }
  });


  return (
    <div data-testid='nonetwork' style={{ display: 'flex' }}>
      <div
        style={{
          width: '30%',
          display: 'inline-block',
          marginLeft: '20px',
        }}
      >
        <p
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: '#12153E',
            textAlign: 'initial',
            marginBottom: '40px',
          }}
        >
          Slice Health
        </p>
        <PieChart
          errorStates={[]}
          data={[]}
          clusterArrData={[]}
          width={'360px'}
          sliceName={sliceName}
          height={'320px'}
        />
      </div>
      <div style={{ width: '64%', display: 'inline-block', marginLeft: 70 }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: '#12153E',
            textAlign: 'initial',
          }}
        >
          Clusters Health
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {clusterArrData.map((item) => {
            const currentTime = moment().utc().format();
            const lastUpdatedTime = item.lastUpdated;
            const diffMinutes = moment(currentTime).diff(
              lastUpdatedTime,
              'minutes'
            );
            return (
              <div
                data-testid={item.clusterName}
                style={{
                  display: 'flex',
                  padding: '16px 24px',
                  marginBottom: 10,
                  flexDirection: 'row',
                  border: '1px solid #ACB9FF',
                  borderRadius: 4,
                  alignItems: diffMinutes > 3 ? 'center' : '',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 40,
                    alignItems: 'flex-start',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#12153E',
                    }}
                  >
                    {item.clusterName}
                  </p>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      color: '#81839A',
                    }}
                  >
                    Cloud Name: {item.cloudName}
                  </p>
                </div>
                {diffMinutes > 3 ? (
                  <div style={{ fontWeight: 'bold' }}>
                    Cluster health status not available
                  </div>
                ) : (
                  <>
                    <PieChart
                      isCluster
                      customClass="charts-row"
                      data={item.data}
                      width={'150px'}
                      height={'100px'}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NoNetwork;
