import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const ConfirmClearModal = ({ handleClose, title, open = false, content }) => {
  return (
    <Dialog
      sx={{
        maxWidth: '528px',
        margin: '0px auto',
        minHeight: '252px',
      }}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle className='modal-title' id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className='modal-desc' id='alert-dialog-description'>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className='submit-btn'
          onClick={() => handleClose(true)}
          autoFocus
        >
          Yes
        </Button>
        <Button className='cancel-btn' onClick={() => handleClose(false)}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmClearModal;
