import * as React from 'react';
import OpenshiftOnCloud from '../../assets/images/cloud-open-shift.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import openshift from '../../assets/images/openshift.svg';

function OpenshiftCloud(props) {
  return <CommonCloud {...props} img={openshift} />;
}

export const OpenshiftTooltip = (props) => {
  return (
    <CloudTooltipCommon {...props} logo={OpenshiftOnCloud} img={openshift} />
  );
};

export default OpenshiftCloud;
