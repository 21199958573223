import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import React from "react";
import colors from "./colors.json";
const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    white: {
      main: "#FFFFFF",
    },
    slate: colors.slate
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
        put: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Bai Jamjuree !important",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        content: {
          textAlign: "left",
        },
        title: {
          fontFamily: "Bai Jamjuree !important",
        },
        subheader: {
          fontFamily: "Bai Jamjuree !important",
        },
      }
    },
  },
});

export const MuiTheme = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
