import {
  ACTION_LOGOUT_INITIATED,
  CLEAR_LEGEND_DATA,
  CLEAR_SYSTEM_EVENT,
  SET_ACTIVE_CLOUD_OPTION,
  SET_ACTIVE_SLICE_OPTION,
  SET_CLUSTER_CAPACITY_DETAIL,
  SET_CLUSTER_DETAIL,
  SET_CLUSTER_METADATA,
  SET_DASHBOARD_CLUSTER_DATA,
  SET_ENTITY_COUNT,
  SET_LEGEND_DATA,
  SET_RQ_TRENDS_DATA,
  SET_SLICE_DETAIL,
  SET_SYSTEM_EVENT,
} from "../actions/types";

import CloudOptions from "../constants/AvailabilityZone.json";
import { CLOUD_COMPARE_KEY } from "../constants/enums";

const generateCloudOptions = () =>
  Object.keys(CloudOptions).map((el, index) => {
    return { id: index, [CLOUD_COMPARE_KEY]: el };
  });

const initialState = {
  compareClusterData: {},
  data: {},
  filteredData: {},
  isValueSet: false,
  clusterDetail: [],
  clusterMetaData: {},
  clusterCapacityDetail: {},
  slices: [],
  systemEvents: [],
  activeSelectedSlice: null,
  cloudNames:
    Object.keys(generateCloudOptions()).length > 0
      ? generateCloudOptions()
      : [],
  activeSelectedCloud: null,
  rqTrendsData: [],
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_CLUSTER_DATA:
      return {
        ...state,
        compareClusterData: action.payload,
      };
    case SET_LEGEND_DATA:
      return {
        ...state,
        data: action.payload.data,
        filteredData: action.payload.filteredData,
        isValueSet: true,
      };
    case CLEAR_LEGEND_DATA:
      return {
        ...initialState,
      };

    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };

    case SET_CLUSTER_DETAIL:
      return {
        ...state,
        clusterDetail: action.payload,
      };

    case SET_CLUSTER_METADATA:
      return {
        ...state,
        clusterMetaData: action.payload,
      };
    case SET_ENTITY_COUNT:
      return {
        ...state,
        clusterMetaData: {
          ...state.clusterMetaData,
          entityCount: action.payload,
        },
      };
    case SET_CLUSTER_CAPACITY_DETAIL:
      return {
        ...state,
        clusterCapacityDetail: action.payload.clusterCapacity,
      };
    case SET_SLICE_DETAIL:
      return {
        ...state,
        slices: action.payload,
      };
    case SET_SYSTEM_EVENT:
      return {
        ...state,
        systemEvents: [...state.systemEvents, ...action.payload.events],
      };
    case CLEAR_SYSTEM_EVENT:
      return {
        ...state,
        systemEvents: [],
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    case SET_ACTIVE_SLICE_OPTION:
      return { ...state, activeSelectedSlice: action.payload };
    case SET_ACTIVE_CLOUD_OPTION:
      return { ...state, activeSelectedCloud: action.payload };
    case SET_RQ_TRENDS_DATA:
      return {
        ...state,
        rqTrendsData: action.payload,
      };
    default:
      return state;
  }
}
