import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';

import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { setAffinityProfiles } from '../../../actions/NodeAffinity.actions';
import {
  selectAffinityProfiles,
  selectNodeLabels,
} from '../../../selectors/NodeAffinity.selector';
import './index.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': {
    // width: "100%",
    maxWidth: '100%',
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Dialogs({ open, onClose, selectedNamespace }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose?.();
  };

  const affinity_profiels = useSelector((state) =>
    selectAffinityProfiles(state)
  );

  const all_node_labels = useSelector((state) => selectNodeLabels(state));

  const currentClusterAffinityProfile =
    useSelector((state) =>
      selectAffinityProfiles(state, selectedNamespace.cluster)
    ) ?? [];

  const [searchText, setSearchText] = useState('');
  const [selectedNode, setSelectedNode] = useState();

  const [selectedRules, setSelectedRules] = useState(() => {
    if (currentClusterAffinityProfile[0]) {
      return (
        // [0] because it returns an array with 0the element as profile
        currentClusterAffinityProfile[0].nodeAffinityRules
          ?.filter((rule) => rule.namespace === selectedNamespace.namespace)
          .reduce((all, current) => {
            return [...all, ...(current.nodeSelectorLabels ?? [])];
          }, []) ?? []
      );
    }
    return [];
  });

  const [searchBy, setSearchBy] = useState('label');
  const [selectedLabel, setSelectedLabel] = useState();

  const hasNode = (rule, cluster) => {
    return node_labels.some((list) => {
      if (list.clusterName === cluster) {
        return list.nodeDetails.some(
          (node) => node.labels[rule.key] === rule.values[0]
        );
      }
      return false;
    });
  };

  const node_labels = useMemo(() => {
    return all_node_labels.filter((node_label) => {
      return node_label.clusterName === selectedNamespace.cluster;
    });
  }, [all_node_labels, selectedNamespace.cluster]);

  const labels = useMemo(() => {
    let memoized_labels = [];
    const labelAdded = (label) => {
      return memoized_labels.find((m_label) => _.isEqual(m_label, label));
    };
    affinity_profiels.forEach((profile) => {
      if (profile.cluster === '*') {
        profile.nodeAffinityRules.forEach((rule) => {
          if (rule.namespace === '*') {
            rule.nodeSelectorLabels.forEach((label) => {
              if (
                !labelAdded(label) &&
                hasNode(label, selectedNamespace.cluster)
              ) {
                memoized_labels.push(label);
              }
            });
          }
        });
      } else if (profile.cluster === selectedNamespace.cluster) {
        profile.nodeAffinityRules.forEach((rule) => {
          if (rule.namespace === '*') {
            rule.nodeSelectorLabels?.forEach((label) => {
              if (
                !labelAdded(label) &&
                hasNode(label, selectedNamespace.cluster)
              ) {
                memoized_labels.push(label);
              }
            });
          } else {
            rule.nodeSelectorLabels?.forEach((label) => {
              if (!labelAdded(label)) {
                memoized_labels.push(label);
              }
            });
          }
        });
      }
    });
    return memoized_labels;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affinity_profiels]);

  const searchLabels = (event) => {
    setSearchText(event.target.value);
  };

  const selectRow = (event, rule) => {
    // setIsChanged(true);
    const selected = event.target.checked;
    if (selected) {
      setSelectedRules((prev) => [...prev, rule]);
    } else {
      setSelectedRules((prev) =>
        prev.filter((s_rule) => !_.isEqual(s_rule, rule))
      );
    }
  };

  const selectAll = (event) => {
    const selected = event.target.checked;
    if (selected) {
      setSelectedRules(labels);
    } else {
      setSelectedRules([]);
    }
  };

  const handleDone = () => {
    const clusterExists = affinity_profiels.find(
      (profile) => profile.cluster === selectedNamespace.cluster
    );

    if (!clusterExists) {
      dispatch(
        setAffinityProfiles([
          ...affinity_profiels,
          {
            cluster: selectedNamespace.cluster,
            nodeAffinityRules: [
              {
                namespace: selectedNamespace.namespace,
                nodeSelectorLabels: selectedRules,
              },
            ],
          },
        ])
      );
    } else {
      dispatch(
        setAffinityProfiles(
          affinity_profiels.map((profile) => {
            if (profile.cluster === selectedNamespace.cluster) {
              const nameSpaceExists = profile.nodeAffinityRules.some(
                (rule) => rule.namespace === selectedNamespace.namespace
              );

              if (!nameSpaceExists) {
                return {
                  ...profile,
                  nodeAffinityRules: [
                    ...profile.nodeAffinityRules.map((rule) => {
                      if (rule.namespace === selectedNamespace.namespace) {
                        return {
                          ...rule,
                          nodeSelectorLabels: selectedRules,
                        };
                      }
                      return rule;
                    }),
                    {
                      namespace: selectedNamespace.namespace,
                      nodeSelectorLabels: selectedRules,
                    },
                  ],
                };
              }
              return {
                ...profile,
                nodeAffinityRules: profile.nodeAffinityRules.map((rule) => {
                  if (rule.namespace === selectedNamespace.namespace) {
                    return {
                      ...rule,
                      nodeSelectorLabels: selectedRules,
                    };
                  }
                  return rule;
                }),
              };
            }
            return profile;
          })
        )
      );
    }
    handleClose?.();
  };

  const isAllSelected = () => {
    if (!labels.length) return false;
    return labels.length === selectedRules.length;
  };

  /*
   * lable: collection of nodes across cluster
   * all the selected labesl in cluster -1 should be visible,
   * all the labeles in slice level, that has nods in cluser -1
   *
   *
   * for view all, show the node list with cluster name similar to step1,
   * in step 1 add cluster name
   *
   * in step 1 modal, highlight the node selected
   * */
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            width: '700px',
            position: 'relative',
          }}
        >
          <BootstrapDialogTitle
            style={{ fontWeight: '600' }}
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Stack>
              <span>Assign Nodes Labels</span>
              <Box
                sx={{
                  fontSize: '14px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '400',
                  }}
                  component="span"
                >
                  Selected namespace:
                </Box>
                <Box component="span">{` ${selectedNamespace.namespace}`}</Box>
              </Box>
              <Box
                sx={{
                  fontSize: '14px',
                }}
              >
                <Box
                  sx={{
                    fontWeight: '400',
                  }}
                  component="span"
                >
                  Cluster:
                </Box>
                <Box component="span">{` ${selectedNamespace.cluster}`}</Box>
              </Box>
            </Stack>
          </BootstrapDialogTitle>
          <Box
            sx={{
              display: 'flex',
              height: '40px',
              overflow: 'hidden',
              border: '1px solid #B6B8D6',
              marginRight: '24px',
              borderRadius: '9px',
              mx: 2,
            }}
          >
            <FormControl
              className="filter-dropdown"
              sx={{ backgroundColor: '#E6E8F3', top: '0px', height: '44px' }}
            >
              <InputLabel id="search-by-label"></InputLabel>
              <Select
                labelId="search-by-label"
                id="search-by"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                label="Search By"
                style={{ height: '40px' }}
              >
                <MenuItem value="label">Search by label</MenuItem>
              </Select>
            </FormControl>
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 250,
                flex: '1 1 0%',
                borderRadius: '0 5px 5px 0',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={searchLabels}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                sx={{
                  ml: 'auto',
                  p: '10px',
                  borderRadius: '0%',
                  backgroundColor: '#E6E8F3',
                }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
          <DialogContent
            style={{
              padding: '0px',
            }}
          >
            <div
              className={
                // description !== '' ? 'with-description' : 'without-description'
                selectedLabel !== ''
                  ? 'without-description'
                  : 'without-description'
              }
            >
              <div
                style={{
                  height: '45vh',
                  overflow: 'auto',
                  borderBottom: '1px solid lightgrey',
                  // padding: "0",
                }}
              >
                <TableContainer className="table-container">
                  <Table
                    sx={{ minWidth: 300, borderRight: '1px solid lightgrey' }}
                    aria-labelledby="Namespace Table"
                    size="small"
                    data-testid="assignDialog"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell
                          style={{
                            padding: '0px 20px',
                            width: '10%',
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isAllSelected()}
                            onChange={selectAll}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            padding: '15px 20px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#12153E',
                            width: '60%',
                          }}
                        >
                          LABEL NAMES
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#12153E',
                          }}
                          align="left"
                        >
                          DESCRIPTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        {labels?.length > 0 ? (
                          labels
                            ?.filter((label) => {
                              // this is operator rule
                              const stringified_labels = `${label.key}: ${label.values[0]}`;
                              return stringified_labels.includes(searchText);
                            })
                            .map((label, l_idx) => {
                              const isItemSelected = selectedRules.some(
                                (s_rule) => _.isEqual(s_rule, label)
                              );

                              return (
                                <TableRow
                                  key={l_idx}
                                  selected={isItemSelected}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  hover
                                >
                                  <TableCell
                                    style={{
                                      padding: '0px 20px',
                                      fontFamily: 'Bai Jamjuree',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      lineHeight: '24px',
                                      color: '#12153E',
                                    }}
                                  >
                                    <Checkbox
                                      inputProps={{
                                        'data-testid': `${label.key}: ${label.values[0]}`,
                                      }}
                                      color="primary"
                                      onChange={(event) =>
                                        selectRow(event, label)
                                      }
                                      checked={isItemSelected}
                                    />
                                  </TableCell>
                                  <TableCell
                                    data-testid="AssignnodeLabelss2"
                                    style={{
                                      padding: '12px 0px 12px 20px',
                                      /*lineHeight: '20px',*/
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        color: '#12153E',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {`${label.key}: ${label.values[0]}`}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: '12px 0px 12px 20px',
                                      /*lineHeight: '20px',*/
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: '#5A607C',
                                        lineHeight: '17.5px',
                                      }}
                                      component="div"
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'baseline',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <Button
                                          style={{
                                            color: '#2868F3',
                                            textTransform: 'none',
                                            fontWeight: '600',
                                          }}
                                          onClick={() => {
                                            setSelectedLabel(label);
                                          }}
                                        >
                                          View
                                        </Button>
                                      </div>
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              No Namespace to display
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="nodeDialogDone"
              variant="contained"
              style={{
                background: '#2868F3',
                width: '100%',
                textTransform: 'none',
              }}
              autoFocus
              onClick={handleDone}
            >
              Done
            </Button>
          </DialogActions>
        </Box>
        {selectedLabel && (
          <Box
            style={{
              maxWidth: '50%',
              width: '50%',
            }}
          >
            <div
              style={{
                display: selectedLabel !== '' ? 'flex' : 'none',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '25px 10px ',
                }}
              >
                <Typography className="dialog-title">Nodes</Typography>
                <Button
                  style={{
                    color: '#2868F3',
                    textTransform: 'none',
                    fontWeight: '600',
                  }}
                  onClick={() => {
                    setSelectedLabel(null);
                  }}
                >
                  Hide
                </Button>
              </DialogTitle>
              <Box
                sx={{
                  border: '1px solid #F3F3F3',
                  borderRadius: '2px',
                  padding: '0 10px',
                  maxHeight: '440px',
                  overflowY: 'auto',
                }}
              >
                <TableContainer className="table-container">
                  <Table
                    sx={{
                      minWidth: 300,
                    }}
                    size="small"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell
                          style={{
                            padding: '15px 20px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#12153E',
                            width: '10%',
                          }}
                        />
                        <TableCell
                          style={{
                            padding: '15px 20px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '15px',
                            color: '#12153E',
                            width: '60%',
                          }}
                        >
                          Node Name
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        {node_labels.map((cluster) => {
                          return cluster.nodeDetails.map((node, idx) => {
                            const { key, values } = selectedLabel;
                            if (node.labels[key] === values[0]) {
                              const isNodeSelected =
                                selectedNode && selectedNode === node.name;
                              return (
                                <>
                                  <TableRow key={idx}>
                                    <TableCell
                                      style={{
                                        padding: '0px 20px',
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#12153E',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => {
                                          if (
                                            !selectedNode ||
                                            selectedNode !== node.name
                                          ) {
                                            setSelectedNode(node.name);
                                          } else {
                                            setSelectedNode(null);
                                          }
                                        }}
                                      >
                                        {isNodeSelected ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '12px 0px 12px 20px',
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 600,
                                          fontSize: '16px',
                                          color: '#12153E',
                                          lineHeight: '20px',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {node.name}
                                        <Typography
                                          style={{
                                            fontFamily: 'Bai Jamjuree',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            color: '#5A607C',
                                            lineHeight: '17.5px',
                                          }}
                                        >
                                          {`${cluster.clusterName ?? ''}`}
                                        </Typography>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {isNodeSelected ? (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          transition: '0.3s ease-in-out',
                                        }}
                                        colSpan={2}
                                      >
                                        <Collapse
                                          in={!!selectedNode}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <div
                                            style={{
                                              background: '#F3F3F3',
                                              border: '1px solid #F3F3F3',
                                              borderRadius: '2px',
                                              padding: '10px',
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                margin: 1,
                                                paddingLeft: 13,
                                              }}
                                            >
                                              {node.allocatable ? (
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      color: 'blue',
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    allocatable:
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      px: 2,
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      node.allocatable
                                                    ).map(([key, value]) => (
                                                      <Box>
                                                        <Box
                                                          component="span"
                                                          sx={{
                                                            color: 'blue',
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {' '}
                                                          {key}
                                                        </Box>
                                                        : {`"${value}"`}
                                                      </Box>
                                                    ))}
                                                  </Box>
                                                </Box>
                                              ) : null}
                                              {node.capacity ? (
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      color: 'blue',
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    capacity:
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      px: 2,
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      node.capacity
                                                    ).map(([key, value]) => (
                                                      <Box>
                                                        <Box
                                                          component="span"
                                                          sx={{
                                                            color: 'blue',
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {' '}
                                                          {key}
                                                        </Box>
                                                        : {`"${value}"`}
                                                      </Box>
                                                    ))}
                                                  </Box>
                                                </Box>
                                              ) : null}
                                            </Stack>
                                          </div>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  ) : null}
                                </>
                              );
                            }
                            return null;
                          });
                        })}
                      </TableBody>
                    }
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </Box>
        )}
      </Box>
    </BootstrapDialog>
  );
}
