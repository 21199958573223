import React from 'react'

function Tri() {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0L22.3923 18H1.6077L12 0Z" fill="#C87349"/>
</svg>

    </div>
  )
}

export default Tri