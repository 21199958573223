import { Grid, Typography, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { RowContainer } from "./Tables/RowContainer";
import { styled } from "@mui/material/styles";
import { getSliceObject, clearCurrentSlice } from "../../../actions/ConnectClusterActions";
import { connect } from "react-redux";
const FinalButton = styled(Button)({
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  height: "50px",
  width: "150px",
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#2868f3",
  borderColor: "#0063cc",
  fontFamily: [
    "Bai Jamjuree"
  ].join(","),
  "&:hover": {
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    boxShadow: "none"
  }
});
const DataRow = ({ sliceValue }) => {
  return (
    <>
      <RowContainer>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.metadata?.name}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.spec?.sliceType}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.spec?.sliceGatewayProvider?.sliceGatewayType}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.spec?.sliceGatewayProvider?.sliceCaType}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.spec?.qosProfileDetails?.queueType}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography fontSize={12} fontWeight={600}>
          {sliceValue?.metadata?.annotations?.desc}
          </Typography>
        </Grid>
        <Grid item xs>
        {sliceValue?.spec?.clusters && sliceValue?.spec?.clusters.map((cluster)=>{
          return (
            <Typography key={cluster} fontSize={12} fontWeight={600}>
              {cluster}
            </Typography>
          )
        })}
         
        </Grid>
      </RowContainer>
    </>
  );
};
function Finalize({ currentSlice,  getSliceObject, clearCurrentSlice }) {
  const [sliceValue,setSliceValue] = React.useState({});
  const history = useHistory();

  React.useEffect(()=>{
      if(currentSlice && currentSlice?.sliceName){
        getSliceObject(currentSlice?.sliceName)
        .then((res)=>{
          if(!res)//undefined case
            throw new Error(`${currentSlice?.sliceName} not created`)
          setSliceValue(res);
        })
        .catch((err)=>{
         
        })
      }
  },[]);
  
  const onDone = ()=>{
    clearCurrentSlice();
    history.push("/connect-cluster")
  }
  return (
    <Grid
      container
      item
      xs
      gap={3}
      flexWrap="nowrap"
      overflow="auto"
      height="100%"
      direction="column"
    >
      <Grid
        item
        xs={6}
        container
        direction="column"
        flexWrap="nowrap"
        bgcolor="#ffffff"
        boxShadow="0px 5px 25px rgba(40, 104, 243, 0.04)"
        borderRadius={4}
        position="relative"
        minHeight={{ sm: "75vh", md: "50vh" }}
      >
        <Grid item p={4} py={2}>
          <Typography fontSize={20} fontWeight={700}>
            Slice
          </Typography>
        </Grid>
        <RowContainer bgcolor="#DEE8FF">
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Slice
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Slice Type
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Gateway Type
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              CA Type
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Qos Type
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Description
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography fontSize={12} fontWeight={600}>
              Clusters Connected
            </Typography>
          </Grid>
        </RowContainer>
        <Grid
          item
          xs
          container
          direction="column"
          flexWrap="nowrap"
          overflow="auto"
        >
          <DataRow sliceValue={sliceValue} />
        </Grid>
        <Grid
          item
          width="150px"
          height="50px"
          my={3}
          alignSelf="center"
          bgcolor="#2868F3"
          borderRadius={1}
          alignItems="center"
          justifyContent="center"
          container     
        >
          <FinalButton onClick={onDone}>
            Done
          </FinalButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    currentSlice: state.ConnectCluster.currentSlice,
  };
};
export default connect(mapStateToProps, {
  getSliceObject,
  clearCurrentSlice
})(Finalize);
