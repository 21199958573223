import { AccessTimeFilled } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Button, LinearProgress, Link } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideBanner } from '../../actions/licensing.action';
import { selectLicenseDetails } from '../../selectors/licensing.selector';
import { formatDate } from '../../utils/date-time';
import { createUpgradeLicenseLink, isEnterpriseLicense, plularize } from '../../utils/license';

// 2023-04-07T11:24:03.602Z

// used material UI because we have to do the same thing in v1
const ValidBanner = () => {
  const licenseDetails = useSelector(selectLicenseDetails);
  const [progress, setProgress] = useState(100);

  const license_type = licenseDetails['license-type'] ?? '';

  const dispatch = useDispatch();

  const onClickClose = () => {
    dispatch(hideBanner());
  };

  const { 'license-expiration': licenseExpiration } = licenseDetails || {};

  const expirationDate = formatDate(licenseExpiration);

  const daysLeft = moment(licenseExpiration).diff(moment(), 'days');
  const expiring = daysLeft <= 15;

  const isExpiringToday =
    daysLeft === 0 && moment().isSame(licenseExpiration, 'date');

  useEffect(() => {
    if (daysLeft > 20) return;

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress <= 0) {
          dispatch(hideBanner());
          clearTimeout(timer);
          return 0;
        }
        return oldProgress - 1;
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTitle = () => {
    if (daysLeft === 0 && !isExpiringToday) {
      return (
        <>
          Your
          <b> {license_type} </b>
          is expiring Tomorrow on {expirationDate}.
        </>
      );
    }

    if (isExpiringToday) {
      return (
        <>
          Your
          <b> {license_type} </b>
          is expiring Today on {expirationDate}.
        </>
      );
    }

    return (
      <>
        Your
        <b> {license_type} </b>
        is expiring after
        <b> {daysLeft}</b> {plularize(daysLeft, 'day')} on {expirationDate}.
      </>
    );
  };

  if (daysLeft > 20) return null;

  const href = createUpgradeLicenseLink(licenseDetails);
  const isEnterprise = isEnterpriseLicense(licenseDetails);

  return (
    <Box
      data-testid="valid-license-banner"
      // used for testing
      data-licensestatus={expiring ? 'expiring' : 'valid'}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        data-progress={progress}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: expiring
              ? '#E15E5E !important'
              : '#A5673F !important',
          },
        }}
      />
      <Box
        sx={{
          backgroundColor: '#FEFCF9',
          borderLeft: `4px solid ${expiring ? '#E15E5E' : '#A5673F'}`,
          py: 2.5,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 4px 8px rgba(31, 61, 153, 0.16)',
          mb: '2px',
          zIndex: 100, // probably not needed
          position: 'sticky',
          top: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <AccessTimeFilled
            sx={{
              color: expiring ? '#E15E5E' : '#A5673F',
              fontSize: 28,
            }}
          />
          <Box
            sx={{
              color: expiring ? '#E15E5E' : '#A5673F',
              fontSize: '18px',
              fontWeight: 500,
            }}>
            <Box data-testid="license-banner-heading">{renderTitle()}</Box>
            <Box
              component="p"
              sx={{
                fontSize: '14px',
                mb: 0,
                mt: '5px',
                fontWeight: 500,
                color: '#828490',
              }}
            >
              {expiring
                ? 'You will not be able to perform actions on any of the items after the expiration date. '
                : ' Please contact Avesha Support to extend your license. '}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Link
            href={href}
            target="_blank"
            underline="none"
            sx={{
              color: '#fff',
              flex: '1 1 0%',
              width: '100%',
              backgroundColor: 'transparent',
              '&:hover': {
                color: '#fff',
                backgroundColor: 'transparent',
                textDecoration: 'none',
              },
            }}
            data-testid="license-cta"
          >
            <Button
              type="button"
              data-testid="extend-btn"
              sx={{
                backgroundColor: expiring ? '#E15E5E' : '#A5673F',
                fontWeight: 600,
                px: 2.5,
                py: 1.25,
                color: '#fff',
                textTransform: 'capitalize',
                ':hover': {
                  backgroundColor: expiring ? '#E15E5E' : '#A5673F',
                },
              }}
            >
              {isEnterprise ? 'Extend' : 'Upgrade'} License
            </Button>
          </Link>
          <Button
            type="button"
            sx={{ px: 0, py: 0, w: 3.5, minWidth: 3.5 }}
            data-testid="close-btn"
            onClick={onClickClose}
          >
            <Close
              sx={{
                color: '#12153E',
                fontSize: 28,
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ValidBanner;
