import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { classNames } from '../../utils/functions';

export const StepsIndicator = () => {
  const { stepNo } = useParams();

  return (
    <Box
      sx={{
        paddingTop: 3,
        paddingX: 5,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '25%',
        }}
        className={classNames(
          'iso-grid-item',
          stepNo === '1' ? `grid-item-selected` : `grid-item`
        )}
      >
        <Typography className="step-title" gutterBottom component="div">
          Step 1 - Assign to Slice
        </Typography>
        <Typography className="step-subtitle" gutterBottom component="div">
          Assign the node labels to clusters across the slice
        </Typography>
      </Box>
      <Box
        sx={{
          width: '25%',
        }}
        className={classNames(
          'iso-grid-item',
          stepNo === '2' ? `grid-item-selected` : `grid-item`
        )}
      >
        <Typography className="step-title" gutterBottom component="div">
          Step 2 - Assign to Namespaces
        </Typography>
        <Typography className="step-subtitle" gutterBottom component="div">
          Assign the node labels to namespaces
        </Typography>
      </Box>
      <Box
        className={classNames(
          'iso-grid-item',
          stepNo === '3' ? `grid-item-selected` : `grid-item`
        )}
        sx={{
          width: '25%',
        }}
      >
        <Typography className="step-title" gutterBottom component="div">
          Step 3 - Apply YAML
        </Typography>
        <Typography className="step-subtitle" gutterBottom component="div">
          Apply the YAML to save the Node Assignment Rules
        </Typography>
      </Box>
      <Box
        className={classNames(
          'iso-grid-item',
          stepNo === '4' ? `grid-item-selected` : `grid-item`
        )}
        sx={{
          width: '25%',
        }}
      >
        <Typography className="step-title" gutterBottom component="div">
          Step 4 - Finalize
        </Typography>
        <Typography className="step-subtitle" gutterBottom component="div">
          Display the final status
        </Typography>
      </Box>
      <Box></Box>
    </Box>
  );
};
