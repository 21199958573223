import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { tooltipClasses } from '@mui/material/Tooltip';
import {
  deleteSliceCustomObject,
  setResourceVersion,
  updateSliceCustomObject,
  getSliceObject,
  clearCurrentSlice,
} from '../../../actions/ConnectClusterActions';
import { connect } from 'react-redux';
import {
  createSliceCustomObject,
  setCurrentSlice,
} from '../../../actions/ConnectClusterActions';
import { Prompt, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import {
  checkNameError,
  checkSubnetError,
  errorMessages,
  checkmaxClustersError,
} from './SliceValidations';

const Header = styled(Typography)({
  fontFamily: 'Bai Jamjuree',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '0.05em',
  color: '#03051E',
  opacity: '0.6',
});
const Content = styled('div')({
  fontFamily: 'Bai Jamjuree',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.05em',
  color: '#12153E',
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SliceContentField = ({ header, content }) => {
  return (
    <div>
      <Header>{header}</Header>
      <Content>{content}</Content>
    </div>
  );
};

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '52px',
  width: '309px',
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  fontFamily: ['Bai Jamjuree'].join(','),
});

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#12153E',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#12153E',
    padding: '12px',
  },
}));

function SliceDetails({
  updateSliceCustomObject,
  deleteSliceCustomObject,
  setCurrentSlice,
  currentSlice,
  editSlice,
  resourceVersion,
  setResourceVersion,
  getSliceObject,
}) {
  const [sliceName, setSliceName] = useState('');
  const [sliceDisplayName, setSliceDisplayName] = useState('');
  const [maxClusters, setmaxClusters] = useState('');

  const [sliceType, setSliceType] = useState('Application');
  const [sliceGWType, setSliceGWType] = useState('OpenVPN');
  const [sliceSubnet, setSliceSubnet] = useState('');
  const [sliceIpamType, setSliceIpamType] = useState('Local');
  const [sliceCertAuthType, setSliceCertAuthType] = useState('Local');
  const [sliceQosProfile, setSliceQosProfile] = useState('Custom');
  const [sliceQosType, setSliceQosType] = useState('HTB');
  const [dscpClass, setDscpClass] = useState('');
  const [bandwidthCeilingKbps, setBandwidthCeilingKbps] = useState('');
  const [bandwidthGuaranteedKbps, setBandwidthGuaranteedKbps] = useState('');
  const [sliceQosTcType, setSliceQosTcType] = useState('BANDWIDTH_CONTROL');
  const [sliceQosPriority, setSliceQosPriority] = useState('');
  const [sliceDesc, setSliceDesc] = useState('');
  const [edited, setEdited] = useState(false);
  const [save, setSave] = useState(true);
  const [maxClustersErr, setmaxClustersErr] = useState('');
  const [sliceNameErr, setSliceNameErr] = useState('');
  const [sliceSubnetErr, setSliceSubnetErr] = useState('');
  const [sliceTypeErr, setSliceTypeErr] = useState('');
  const [sliceGWTypeErr, setSliceGWTypeErr] = useState('');
  const [sliceIpamTypeErr, setSliceIpamTypeErr] = useState('');
  const [sliceQosProfileErr, setSliceQosProfileErr] = useState('');
  const [sliceQosPriorityErr, setSliceQosPriorityErr] = useState('');
  const [sliceQosTypeErr, setSliceQosTypeErr] = useState('');
  const [bandwidthCeilingKbpsErr, setBandwidthCeilingKbpsErr] = useState('');
  const [bandwidthGuaranteedKbpsErr, setBandwidthGuaranteedKbpsErr] =
    useState('');
  const [dscpClassErr, setDscpClassErr] = useState('');
  const [sliceCertAuthTypeErr, setSliceCertAuthTypeErr] = useState('');
  const [sliceQosTcTypeErr, setSliceQosTcTypeErr] = useState('');
  const [deleteClusterDialog, setDeleteClusterDialog] = useState(false);
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);

  const history = useHistory();

  React.useEffect(() => {
    if (currentSlice?.sliceName && Object.keys(currentSlice).length > 0) {
      setSliceName(currentSlice.sliceName);
      setSliceDisplayName(currentSlice.sliceDisplayName);
      setmaxClusters(currentSlice.maxClusters);
      setSliceType(currentSlice.sliceType);
      setSliceGWType(currentSlice.sliceGWType);
      setSliceCertAuthType(currentSlice.sliceCertAuthType);
      setSliceQosType(currentSlice.sliceQosType);
      setSliceDesc(currentSlice.sliceDesc);
      setSliceQosProfile(currentSlice.sliceQosProfile);
      setDscpClass(currentSlice.dscpClass);
      setSliceQosTcType(currentSlice.sliceQosTcType);
      setBandwidthCeilingKbps(currentSlice.bandwidthCeilingKbps);
      setBandwidthGuaranteedKbps(currentSlice.bandwidthGuaranteedKbps);
      setSliceQosPriority(currentSlice.sliceQosPriority);
      setSliceSubnet(currentSlice.sliceSubnet);
      setSliceIpamType(currentSlice.sliceIpamType);
    }
    if (editSlice) {
      setSave(false);
    }
  }, [currentSlice, editSlice]);

  const checkFormValidation = () => {
    const nameErrMsg = checkNameError(sliceName);
    const maxClustersErrMsg = checkmaxClustersError(maxClusters);
    const sliceSubnetErrMsg = checkSubnetError(sliceSubnet);
    const sliceTypeErrMsg = sliceType ? '' : errorMessages.sliceType;
    const sliceGWTypeErrMsg = sliceGWType ? '' : errorMessages.sliceGWType;
    const sliceIpamTypeErrMsg = sliceIpamType
      ? ''
      : errorMessages.sliceIpamType;
    const sliceQosProfileErrMsg = sliceQosProfile
      ? ''
      : errorMessages.sliceQosProfile;
    const sliceCertAuthTypeErrMsg = sliceCertAuthType
      ? ''
      : errorMessages.sliceCertAuthType;
    let sliceQOSProfileDependancy = '';

    setSliceNameErr(nameErrMsg);
    setmaxClustersErr(maxClustersErrMsg);
    setSliceSubnetErr(sliceSubnetErrMsg);
    setSliceTypeErr(sliceTypeErrMsg);
    setSliceGWTypeErr(sliceGWTypeErrMsg);
    setSliceIpamTypeErr(sliceIpamTypeErrMsg);
    setSliceQosProfileErr(sliceQosProfileErrMsg);
    setSliceCertAuthTypeErr(sliceCertAuthTypeErrMsg);

    if (sliceQosProfile === 'Custom') {
      const sliceQosPriorityErrMsg = sliceQosPriority
        ? ''
        : errorMessages.sliceQosPriority;
      const sliceQosTypeErrMsg = sliceQosType ? '' : errorMessages.sliceQosType;
      const sliceQosTcTypeErrMsg = sliceQosTcType
        ? ''
        : errorMessages.sliceQosTcType;
      const dscpClassErrMsg = dscpClass ? '' : errorMessages.dscpClass;
      const bandwidthCeilingKbpsErrMsg = bandwidthCeilingKbps
        ? ''
        : errorMessages.bandwidthCeilingKbps;
      const bandwidthGuaranteedKbpsErrMsg = bandwidthGuaranteedKbps
        ? +bandwidthGuaranteedKbps > +bandwidthCeilingKbps
          ? errorMessages.bandwidthGuaranteedKbps.greaterThan
          : ''
        : errorMessages.bandwidthGuaranteedKbps.required;

      setSliceQosPriorityErr(sliceQosPriorityErrMsg);
      setSliceQosTypeErr(sliceQosTypeErrMsg);
      setSliceQosTcTypeErr(sliceQosTcTypeErrMsg);
      setDscpClassErr(dscpClassErrMsg);

      setBandwidthCeilingKbpsErr(bandwidthCeilingKbpsErrMsg);
      setBandwidthGuaranteedKbpsErr(bandwidthGuaranteedKbpsErrMsg);

      sliceQOSProfileDependancy =
        sliceQosPriorityErrMsg ||
        sliceQosTypeErrMsg ||
        sliceQosTcTypeErrMsg ||
        bandwidthCeilingKbpsErrMsg ||
        bandwidthGuaranteedKbpsErrMsg ||
        dscpClassErrMsg;
    }

    return (
      !nameErrMsg &&
      !sliceSubnetErrMsg &&
      !sliceTypeErrMsg &&
      !sliceGWTypeErrMsg &&
      !sliceIpamTypeErrMsg &&
      !sliceQosProfileErrMsg &&
      !sliceQOSProfileDependancy &&
      !sliceCertAuthTypeErrMsg &&
      !maxClustersErrMsg
    );
  };
  const handleCancel = () => {
    if (editSlice) {
      setSave(false);
    } else {
      history.goBack();
    }
  };
  const handleSave = async () => {
    const formValid = checkFormValidation();
    if (formValid) {
      if (editSlice) {
        const currentSliceObj = await getSliceObject(currentSlice.sliceName);
        updateSliceCustomObject({
          sliceName,
          sliceDisplayName,
          maxClusters,
          sliceType,
          sliceGWType,
          sliceCertAuthType,
          sliceQosProfile,
          sliceQosType,
          sliceDesc,
          sliceQosPriority,
          sliceQosTcType,
          bandwidthGuaranteedKbps,
          bandwidthCeilingKbps,
          dscpClass,
          clusters: currentSlice?.clusters,
          resourceVersion: currentSliceObj?.metadata?.resourceVersion,
          applicationNamespaces:
            currentSliceObj?.spec?.namespaceIsolationProfile
              ?.applicationNamespaces,
          sliceSubnet,
          sliceIpamType,
        }).then((res) => {
          getSliceObject(sliceName).then((response) => {
            setResourceVersion(response?.metadata?.resourceVersion); // set latest resource version
          });

          setCurrentSlice({
            sliceName,
            sliceDisplayName,
            maxClusters,
            sliceType,
            sliceGWType,
            sliceCertAuthType,
            sliceQosProfile,
            sliceQosType,
            sliceDesc,
            sliceQosPriority,
            sliceQosTcType,
            bandwidthGuaranteedKbps,
            bandwidthCeilingKbps,
            dscpClass,
            clusters: currentSlice?.clusters,
            resourceVersion: currentSliceObj?.metadata?.resourceVersion,
            applicationNamespaces:
              currentSliceObj?.spec?.namespaceIsolationProfile
                ?.applicationNamespaces,
            sliceSubnet,
            sliceIpamType,
          });
          setSave(false);
          setEdited(false);
        });
      } else {
        setCurrentSlice({
          sliceName,
          sliceDisplayName,
          maxClusters,
          sliceType,
          sliceGWType,
          sliceCertAuthType,
          sliceQosProfile,
          sliceQosType,
          sliceDesc,
          sliceQosPriority,
          sliceQosTcType,
          bandwidthGuaranteedKbps,
          bandwidthCeilingKbps,
          dscpClass,
          sliceSubnet,
          sliceIpamType,
        });
        setSave(false);
        setEdited(false);
      }
    }
  };

  const onDelete = () => {
    if (sliceName) {
      if (editSlice) {
        deleteSliceCustomObject(sliceName).then(() => {
          history.push('/connect-cluster');
        });
      } else {
        clearCurrentSlice();
        history.push('/connect-cluster');
      }
    }
  };

  const openDialog = () => {
    setDeleteClusterDialog(true);
  };

  const closeDialog = () => {
    setDeleteClusterDialog(false);
  };

  useEffect(() => {
    getSliceObject(currentSlice.sliceName).then((response) => {
      if (response) {
        let identifier = response.status?.kubesliceEvents || [];
        const filteredEvents = identifier.filter((data) => {
          return data.type === 'InProgress';
        });
        const disableButton = filteredEvents.length > 0;
        setDisableOffboardCluster(disableButton);
      }
    });
  }, []);

  const enabledData = (
    <>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? 'filled' : 'outlined'}
            required
            disabled={editSlice}
            label="Name of Slice (Global Namespace)"
            fullWidth
            error={sliceNameErr}
            name="sliceName"
            value={sliceName}
            helperText={sliceNameErr}
            onChange={(e) => {
              setEdited(true);
              setSliceName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Slice Display Name"
            fullWidth
            value={sliceDisplayName}
            onChange={(e) => {
              setEdited(true);
              setSliceDisplayName(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? 'filled' : 'outlined'}
            label="Slice Type"
            error={sliceTypeErr}
            helperText={sliceTypeErr}
            fullWidth
            required
            disabled
            select
            value={sliceType}
            onChange={(e) => {
              setEdited(true);
              setSliceType(e.target.value);
            }}
          >
            <MenuItem value={'Application'}>Application</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Slice Description"
            fullWidth
            value={sliceDesc}
            onChange={(e) => {
              setEdited(true);
              setSliceDesc(e.target.value);
            }}
          />
        </Grid>

        {/* <Grid item xs={6}>
          <TextField
            variant={editSlice ? "filled" : "outlined"}
            label="Slice Gateway Type"
            error={sliceGWTypeErr}
            required
            disabled={editSlice}
            helperText={sliceGWTypeErr}
            fullWidth
            select
            value={sliceGWType}
            onChange={(e) => {
              setEdited(true);
              setSliceGWType(e.target.value);
            }}
          >
            <MenuItem value={"OpenVPN"}>OpenVPN</MenuItem>
          </TextField>
        </Grid> */}
      </Grid>
      {/* <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? "filled" : "outlined"}
            label="Slice Subnet"
            error={sliceSubnetErr}
            helperText={sliceSubnetErr}
            fullWidth
            required
            disabled={editSlice}
            value={sliceSubnet}
            onChange={(e) => {
              setEdited(true);
              setSliceSubnet(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? "filled" : "outlined"}
            label="Slice IPAM Type"
            error={sliceIpamTypeErr}
            helperText={sliceIpamTypeErr}
            fullWidth
            required
            disabled={editSlice}
            select
            value={sliceIpamType}
            onChange={(e) => {
              setEdited(true);
              setSliceIpamType(e.target.value);
            }}
          >
            <MenuItem value={"Local"}>Local</MenuItem>
          </TextField>
        </Grid>
      </Grid> */}
      {/* <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? "filled" : "outlined"}
            label="Slice Certificate Authority Type"
            fullWidth
            select
            required
            disabled={editSlice}
            error={sliceCertAuthTypeErr}
            helperText={sliceCertAuthTypeErr}
            value={sliceCertAuthType}
            onChange={(e) => {
              setEdited(true);
              setSliceCertAuthType(e.target.value);
            }}
          >
            <MenuItem value={"Local"}>Local</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            required
            label="Slice Qos Profile"
            error={sliceQosProfileErr}
            helperText={sliceQosProfileErr}
            fullWidth
            select
            value={sliceQosProfile}
            onChange={(e) => {
              setEdited(true);
              setSliceQosProfile(e.target.value);
            }}
          >
            <MenuItem value="Custom">-- Custom --</MenuItem>
          </TextField>
        </Grid>
      </Grid> */}
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            variant={editSlice ? 'filled' : 'outlined'}
            label="Slice Subnet"
            error={sliceSubnetErr}
            helperText={sliceSubnetErr}
            fullWidth
            required
            disabled={editSlice}
            value={sliceSubnet}
            onChange={(e) => {
              setEdited(true);
              setSliceSubnet(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            // variant="outlined"
            label="Maximum Clusters"
            fullWidth
            variant={editSlice ? 'filled' : 'outlined'}
            disabled={editSlice}
            error={maxClustersErr}
            helperText={maxClustersErr}
            value={maxClusters}
            onChange={(e) => {
              setEdited(true);
              setmaxClusters(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <BootstrapTooltip title="This indicates the maximum number of clusters that can be connected to the slice and can lie between 2 through 32, If you do not enter a value, a default of 16 clusters will be saved. Note that this number cannot be changed once saved.">
                  <InputAdornment position="end">
                    <InfoIcon fontSize="small" sx={{ color: 'black' }} />
                  </InputAdornment>
                </BootstrapTooltip>
              ),
            }}
          />
        </Grid>
      </Grid>
      {sliceQosProfile === 'Custom' && (
        <>
          <Grid px={10} py={2} container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                label="Slice Qos Profile"
                error={sliceQosProfileErr}
                helperText={sliceQosProfileErr}
                fullWidth
                select
                value={sliceQosProfile}
                onChange={(e) => {
                  setEdited(true);
                  setSliceQosProfile(e.target.value);
                }}
              >
                <MenuItem value="Custom">-- Custom --</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Slice Qos Profile Priority"
                fullWidth
                required
                error={sliceQosPriorityErr}
                helperText={sliceQosPriorityErr}
                value={sliceQosPriority}
                onChange={(e) => {
                  setEdited(true);
                  setSliceQosPriority(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid px={10} py={2} container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Slice Qos Bandwidth Ceiling Kbps"
                fullWidth
                required
                error={bandwidthCeilingKbpsErr}
                helperText={bandwidthCeilingKbpsErr}
                value={bandwidthCeilingKbps}
                onChange={(e) => {
                  setEdited(true);
                  setBandwidthCeilingKbps(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Slice Qos Bandwidth Guaranteed Kbps"
                fullWidth
                required
                error={bandwidthGuaranteedKbpsErr}
                helperText={bandwidthGuaranteedKbpsErr}
                value={bandwidthGuaranteedKbps}
                onChange={(e) => {
                  setEdited(true);
                  setBandwidthGuaranteedKbps(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid px={10} py={2} container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Slice Qos Dscp Class"
                fullWidth
                select
                required
                error={dscpClassErr}
                helperText={dscpClassErr}
                value={dscpClass}
                onChange={(e) => {
                  setEdited(true);
                  setDscpClass(e.target.value);
                }}
              >
                <MenuItem value="Default">Default</MenuItem>
                <MenuItem value="AF11">AF11</MenuItem>
                <MenuItem value="AF12">AF12</MenuItem>
                <MenuItem value="AF13">AF13</MenuItem>
                <MenuItem value="AF21">AF21</MenuItem>
                <MenuItem value="AF22">AF22</MenuItem>
                <MenuItem value="AF23">AF23</MenuItem>
                <MenuItem value="AF31">AF31</MenuItem>
                <MenuItem value="AF32">AF32</MenuItem>
                <MenuItem value="AF33">AF33</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Slice Qos Profile TC Type"
                fullWidth
                select
                required
                error={sliceQosTcTypeErr}
                helperText={sliceQosTcTypeErr}
                value={sliceQosTcType}
                onChange={(e) => {
                  setEdited(true);
                  setSliceQosTcType(e.target.value);
                }}
              >
                <MenuItem value="BANDWIDTH_CONTROL">BANDWIDTH_CONTROL</MenuItem>
              </TextField>
            </Grid> */}
          </Grid>
        </>
      )}

      <Grid
        item
        width="100%"
        minHeight="50px"
        alignSelf="center"
        borderRadius={1}
        sx={{ marginTop: '50px' }}
        alignItems="center"
        justifyContent="center"
        container
      >
        <FinalButton onClick={handleSave} variant="contained">
          Save
        </FinalButton>
        <FinalButton
          sx={{ backgroundColor: '#828490' }}
          onClick={handleCancel}
          variant="contained"
        >
          Cancel
        </FinalButton>
      </Grid>
    </>
  );
  const disabledData = (
    <>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <SliceContentField
            header={'Name of Slice (Global Namespace)'}
            content={sliceName}
          />
        </Grid>
        <Grid item xs={6}>
          <SliceContentField
            header={'Slice Display Name'}
            content={sliceDisplayName}
          />
        </Grid>
      </Grid>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <SliceContentField header={'Slice Type'} content={sliceType} />
        </Grid>
        <Grid item xs={6}>
          <SliceContentField header={'Slice Description'} content={sliceDesc} />
        </Grid>
      </Grid>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <SliceContentField header={'Slice Subnet'} content={sliceSubnet} />
        </Grid>
        <Grid item xs={6}>
          <SliceContentField
            header={'Maximum Clusters'}
            content={maxClusters}
          />
        </Grid>
      </Grid>
      <Grid px={10} py={2} container spacing={3}>
        <Grid item xs={6}>
          <SliceContentField
            header={'Slice Qos Profile'}
            content={sliceQosProfile}
          />
        </Grid>
        {sliceQosProfile === 'Custom' && (
          <Grid item xs={6}>
            <SliceContentField
              header={'Slice Qos Profile Priority'}
              content={sliceQosPriority}
            />
          </Grid>
        )}
      </Grid>

      {sliceQosProfile === 'Custom' && (
        <>
          <Grid px={10} py={2} container spacing={3}>
            <Grid item xs={6}>
              <SliceContentField
                header={'Slice Qos Bandwidth Ceiling Kbps'}
                content={bandwidthCeilingKbps}
              />
            </Grid>
            <Grid item xs={6}>
              <SliceContentField
                header={'Slice Qos Bandwidth Guaranteed Kbps'}
                content={bandwidthGuaranteedKbps}
              />
            </Grid>
          </Grid>
          <Grid px={10} py={2} container spacing={3}>
            <Grid item xs={6}>
              <SliceContentField
                header={'Slice Qos Dscp Class'}
                content={dscpClass}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      <Prompt
        when={edited}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Paper
        sx={{
          width: '100%',
          borderRadius: '14px',
          overflow: 'auto',
          flexWrap: 'nowrap',
          boxShadow: '0px 5px 25px rgba(40, 104, 243, 0.04)',
          px: '50px',
          py: '20px',
        }}
        bgcolor="#ffffff"
      >
        <Grid px={10} container spacing={3}>
          <Grid item xs={8}>
            <Typography color="primary" fontSize={24} fontWeight={500}>
              <LocalPizzaIcon sx={{ margin: '-5px 5px' }} color="primary" />
              Enter Slice Details
            </Typography>
          </Grid>
          <Grid item xs={4} container flexWrap="nowrap">
            {!save && (
              <>
                <Button
                  disabled={DisableOffboardCluster}
                  onClick={() => setSave(true)}
                  variant="contained"
                  sx={{
                    mr: 1,
                    backgroundColor: DisableOffboardCluster
                      ? '#CCCCCC'
                      : '#2868F3',
                    width: '73px',
                  }}
                >
                  Edit
                </Button>
                {/* <Button onClick={openDialog} variant="outlined" sx={{ mr: 1 }}>
                  Delete
                </Button> */}
              </>
            )}
          </Grid>
        </Grid>
        {save ? enabledData : disabledData}
      </Paper>
      <CustomDialog
        open={deleteClusterDialog}
        onClose={closeDialog}
        style={{ padding: '25px' }}
        aria-labelledby="Delete Slice Dialog"
        aria-describedby="This Dialog is for delete slice confirmation"
      >
        <DialogTitle
          style={{ fontWeight: 'bold' }}
          id="delete-slice-dialog-title"
        >
          {`Do you want to delete the ${sliceName} slice?`}
        </DialogTitle>
        <DialogActions>
          <>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="outlined"
              onClick={onDelete}
            >
              Yes
            </Button>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="contained"
              onClick={closeDialog}
            >
              No
            </Button>
          </>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentSlice: state.ConnectCluster.currentSlice,
    resourceVersion: state.ConnectCluster.resourceVersion,
    editSlice: state.ConnectCluster.editSlice,
  };
};

export default connect(mapStateToProps, {
  createSliceCustomObject,
  setCurrentSlice,
  updateSliceCustomObject,
  deleteSliceCustomObject,
  setResourceVersion,
  getSliceObject,
})(SliceDetails);
