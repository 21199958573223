import { RollerShades } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Fade, Grid, IconButton, Tooltip } from '@mui/material';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import React, { useState } from 'react';
import AceEditor from 'react-ace';
import { useSelector } from 'react-redux';
import MinimizeIcon from "@mui/icons-material/Minimize";
import MaximizeIcon from "@mui/icons-material/Maximize";
import YAML from 'yaml';
import {
  selectCurrentSlice,
  selectCurrentSliceAffinity,
} from '../../../selectors/NodeAffinity.selector';

function CodePreview(props) {
  const currentSlice = useSelector(selectCurrentSlice);

  const currentSliceAffinity = useSelector(selectCurrentSliceAffinity);
  const [isCopied, setIsCopied] = useState(false);
  const [yamlEditorText, setYamlEditorText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onExpandClick = () => {
    setExpanded((oldState) => {
      props.setCurrHeight(oldState ? "0vh" : "40vh");  
      if(oldState){
        setOpenDialog(false);
      }
      return !oldState});
  }


  React.useEffect(() => {
    if (currentSlice) {
      const preview_slice = {
        ...currentSliceAffinity,
        spec: {
          ...currentSliceAffinity.spec,
          nodeAffinityProfiles:
            currentSliceAffinity.spec.nodeAffinityProfiles
           .map(profile => ({
              ...profile, 
              nodeAffinityRules: profile.nodeAffinityRules?.filter(rule => {
                return rule.nodeSelectorLabels?.length > 0
              }) ?? []
            })).filter(profile => {
              // if all nodeSelectorLabels is empty, skip the cluster itself
              return profile.nodeAffinityRules?.some(rule => rule.nodeSelectorLabels?.length > 0)
            }).map((profile) => {
              const { nodeAffinityRules } = profile;
              const sorted_rules = nodeAffinityRules.sort((prev, next) => {
                if (prev.namespace === '*') return 1;
                return -1;
              });
              return {
                ...profile,
                nodeAffinityRules: sorted_rules,
              };
            }),
        },
      };
      const updatedSlice = JSON.parse(JSON.stringify(preview_slice));
      const doc = new YAML.Document();
      doc.contents = updatedSlice;
      setYamlEditorText(doc.toString());
    }
  }, []);

  const copyToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(yamlEditorText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } else {
      let textArea = document.createElement('textarea');
      textArea.value = yamlEditorText;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      });
    }
  };

  const closeEditor = () => {
    props.setClose(false);
    props.setCurrHeight('0vh');
    setOpenDialog(false);
  };

  const onClickHandler = () => {
    props.toggleOpen?.();
    if (!props.isOpen) {
      props.setCurrHeight('calc(100% - 64px)');
      setOpenDialog(true);
    } else {
      props.setCurrHeight('0vh');
      setOpenDialog(false);
    }
  };

  return (
    <Grid
      item
      xs
      container
      backgroundColor="#ffffff"
      borderRadius="14px"
      flexDirection="column"
      direction="column"
      justifyContent="space-between"
      sx={{
        height: '100%',
      }}
    >
      {props?.close && (
        <Grid
          item
          sx={{
            backgroundColor: '#272822',
            padding: '24px',
            height: '100%',
          }}
        >
          <span
            style={{
              margin: '30px 0 8px',
              fontSize: '20px',
              fontWeight: '700',
              color: 'white',
            }}
            data-testid="codePreview"
          >
            Code Preview
            <IconButton
              onClick={closeEditor}
              sx={{ float: 'right' }}
              aria-label="close code"
              component="span"
            >
              <CancelIcon color="white" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={onClickHandler}
              sx={{ float: 'right' }}
              aria-label="copy yaml code"
              component="span"
            >
              {props.currHeight === '30vh' ? (
                <OpenInFullIcon color="white" fontSize="small" />
              ) : (
                <CloseFullscreenIcon color="white" fontSize="small" />
              )}
            </IconButton>
            <IconButton
                sx={{ float: "right" }}
                aria-label="minimize yaml code"
                onClick={onExpandClick}
                component="span"
                disabled={props.isOpen}
              >
                {expanded ? (
                  <MinimizeIcon color="white" fontSize="small" />
                ) : (
                  <MaximizeIcon color="white" fontSize="small" />
                )}
              </IconButton>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={isCopied}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
            >
              <IconButton
                onClick={copyToClipboard}
                sx={{ float: 'right' }}
                aria-label="copy yaml code"
                component="span"
              >
                <ContentCopyIcon color="white" fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <AceEditor
            mode="sass"
            theme="monokai"
            name="Slice YAML"
            width="100%"
            height={props.open ? 'calc(100% - 12px)' : props.currHeight}
            onChange={(value) => setYamlEditorText(value)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={yamlEditorText}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </Grid>
      )}
      {/* <AceEditorDialog
        editorValue={yamlEditorText}
        setEditorValue={(value) => setYamlEditorText(value)}
        openDialog={openDialog}
        closeDialog={() => {
          setOpenDialog(false);
          props.setCurrHeight("7vh");
        }}
      /> */}
    </Grid>
  );
}

export default CodePreview;
