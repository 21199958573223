import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Finalize from './Finalize';
import IsolateGenerateYaml from './GenerateYaml';
import CodePreview from './GenerateYaml/CodePreview';
import './index.css';
import '../../IsolateNamespaces/Steps/index.css';
import InitialStep from './InitialStep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Steps(props) {
  const [close, setClose] = useState(true);
  const { stepNo } = useParams();
  const history = useHistory();
  const [currHeight, setCurrHeight] = useState('30vh');
  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  const sliceDataNAme = localStorage.getItem('currentSlice');
  const [Breadcrumb, setBreadcrumb] = useState('');

  return (
    <>
      <Grid style={{ height: '100vh', overflow: 'scroll' }}>
        <Grid item className="header">
          <Paper className="paper-header">
            <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px', // Adjust the font size as per your requirement
                lineHeight: '20px',
                color: '#6E708E',
                paddingTop: '4px',
              }}
              variant="subtitle1"
            >
              {currentProjectData?.projectNamespace}
              <span> / Isolation / </span>
              <span style={{ fontWeight: 600 }}> {Breadcrumb}</span>
            </Typography>
          </Paper>
        </Grid>
        <Grid item container direction="column" className="step-main-container">
          <Grid item className="grid-item-main" xs={12}>
            <Grid container className="step-container">
              <Grid
                item
                onClick={() =>
                  history.push({
                    pathname: '/manage-namespaces/isolate-namespaces/add/1',
                    state: {
                      legit: true,
                    },
                  })
                }
                sx={{ padding: '18px 22px', border: '1px solid lightgrey' }}
                className={`grid-item-base ${
                  stepNo === '1' ? `grid-item-current` : `grid-item-completed`
                }`}
                xs={4}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className="step-title"
                  gutterBottom
                  component="div"
                >
                  {stepNo !== '1' && (
                    <CheckCircleIcon width={'24px'} height={'24px'} />
                  )}
                  {`Step 1 - Add Namespaces`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Choose namespaces to be put on the Slice from each cluster`}
                </Typography>
              </Grid>
              <Grid
                sx={{ padding: '18px 22px', border: '1px solid lightgrey' }}
                item
                onClick={() =>
                  history.push({
                    pathname: '/manage-namespaces/isolate-namespaces/add/2',
                    state: {
                      legit: true,
                    },
                  })
                }
                className={`grid-item-base ${
                  stepNo === '2'
                    ? `grid-item-current`
                    : stepNo === '3'
                    ? `grid-item-completed`
                    : ''
                }`}
                xs={4}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className="step-title"
                  gutterBottom
                  component="div"
                >
                  {stepNo === '3' && (
                    <CheckCircleIcon width={'24px'} height={'24px'} />
                  )}
                  {`Step 2 - Generate YAML and Apply`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Generate Slice YAML with all namespaces for each cluster`}
                </Typography>
              </Grid>
              <Grid
                sx={{ padding: '18px 22px', border: '1px solid lightgrey' }}
                item
                onClick={() =>
                  history.push({
                    pathname: '/manage-namespaces/isolate-namespaces/add/3',
                    state: {
                      legit: true,
                    },
                  })
                }
                className={`grid-item-base ${
                  stepNo === '3' ? `grid-item-current` : ``
                }`}
                xs={4}
              >
                <Typography className="step-title" gutterBottom component="div">
                  {`Step 3 - Finalize`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Display Final state`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {stepNo === '1' && <InitialStep setBreadcrumb={setBreadcrumb} />}
          {stepNo === '2' && <IsolateGenerateYaml setClose={setClose} />}
          {stepNo === '3' && <Finalize />}
        </Grid>
        {stepNo === '2' && (
          <Grid
            item
            style={{
              position: 'fixed',
              bottom: '50px',
              width: 'calc(100% - 220px)',
              height: currHeight,
            }}
          >
            <CodePreview
              setClose={setClose}
              close={close}
              setCurrHeight={setCurrHeight}
              currHeight={currHeight}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Steps;
