import React, { Component } from 'react';
import AboutUs from '../AboutUs';
import { connect } from 'react-redux';
import { openPopUp, closePopUp } from '../../../actions/PopUpActions';
import UserDetails from './../UserDetails';
import { ABOUT_US, EXTEND_LICENSE, LICENSING, PROFILE } from './types';
import LicenceDetails from '../../Licensing/Details/Details';
import ExtendLicenseModal from '../../Licensing/Modals/ExtendModal';

class PopUp extends Component {
  render() {
    const { popup, closePopUp } = this.props;
    switch (popup) {
      case ABOUT_US:
        return (
          <AboutUs open={popup === ABOUT_US} closeContactModal={closePopUp} />
        );
      case PROFILE:
        return (
          <UserDetails open={popup === PROFILE} handleClose={closePopUp} />
        );
      case LICENSING:
        return (
          <LicenceDetails open={popup === LICENSING} handleClose={closePopUp} />
        );
      case EXTEND_LICENSE:
        return (
          <ExtendLicenseModal
            open={popup === EXTEND_LICENSE}
            handleClose={closePopUp}
          />
        );

      default:
        return <></>;
    }
  }
}
const mapStateToProps = ({ popup: { popup } }) => ({ popup });
export default connect(mapStateToProps, { openPopUp, closePopUp })(PopUp);
