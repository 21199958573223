import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase } from '@mui/material';

const SearchInput = ({ onChange, value }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '40px',
          overflow: 'hidden',
          border: '1px solid #B6B8D6',
          borderRadius: '4px',
          width: '308px',
          padding: '1px 0px 0px 16px;',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          onChange={onChange}
          inputProps={{ 'aria-label': 'search google maps' }}
          value={value}
        />
        <IconButton className="search-icon" type="button">
          <SearchIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default SearchInput;
