import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  getCompareClusterStats,
  setActiveSelectedSlice,
} from '../../../actions/dashboardActions';
import { ReactComponent as Checked } from '../../../assets/icons/Checked2.svg';
import { ReactComponent as UnChecked } from '../../../assets/icons/Unchecked.svg';
import {
  CLUSTER_COMPARE_KEY,
  SLICE_COMPARE_KEY,
} from '../../../constants/enums';
import MetricsDropdown from '../MetricsDropdown';
import TopologicalView from '../TopologicalView';

const centerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const timeMetricsBtnStyle = {
  width: '28px',
  height: '23px',
  borderRadius: '4px',
  marginRight: '10px',
  cursor: 'pointer',
  ...centerStyle,
};

const timeMetricsBtnLabel = {
  fontSize: '12px',
  lineHeight: '16px',
};

var keyPod = [];
var podName = [];

const colors = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#5474f6',
  '#91cf75',
  '#facff8',
  '#ee66a6',
  '#73c0be',
  '#3bac72',
  '#fc8d52',
  '#9a6024',
  '#ea7c8c',
];

let clusterToColor = {};

let currentIndex = 0;

const getEchartSeries = (dataResponse, areaEnabled) => {
  if (dataResponse === null || Object.keys(dataResponse).length === 0) {
    return [];
  } else {
    let keys = Object.keys(dataResponse);

    let vals = [];

    for (let key in keys) {
      if (dataResponse[keys[key]].length > 0) {
        const thisCluster = keys[key];
        if (!clusterToColor[thisCluster]) {
          console.log({ clusterToColor, currentIndex });
          clusterToColor[thisCluster] = colors[currentIndex];
          currentIndex =
            colors.length - 1 >= currentIndex ? currentIndex + 1 : 0;
        }
        dataResponse[keys[key]].map((datax, index) => {
          // console.log([...dataResponse[keys[key]]]);
          vals.push({
            name: `${keys[key]}/${datax.podName}`,
            // smooth: true,
            type: 'line',
            // stack: 'Total',
            showSymbol: false,
            color: clusterToColor[thisCluster],
            data: [...dataResponse[keys[key]][index].latency].map((el) => {
              return el.value;
            }),
            areaStyle: areaEnabled ? {} : null,
          });
          keyPod.push(datax);
          const datamap = vals.map((e) => e.podName);
          podName.push(datamap);
        });
      }
    }
    return vals;
  }
};

const getEchartAxisLabel = (dataResponse) => {
  if (dataResponse === null || Object.keys(dataResponse).length === 0) {
    return [];
  } else {
    let keys = Object.keys(dataResponse);
    let time = [...dataResponse[keys[0]][0].latency].map((el) => el.time);
    return time;
  }
};

const getEchartTitleVisibilityStat = (dataResponse) => {
  if (dataResponse && Object.keys(dataResponse).length > 0) {
    return false;
  } else {
    return true;
  }
};

const getEchartTitleText = (dataResponse, activeAnchor) => {
  if (activeAnchor && dataResponse && Object.keys(dataResponse).length === 0) {
    return 'There are no graphs to show. No data available';
  } else {
    return 'There are no graphs to show. Select Cluster';
  }
};

const eChartOption = (dataResponse, activeAnchor, areaEnabled) => {
  // console.log(dataResponse);
  return {
    title: {
      show: getEchartTitleVisibilityStat(dataResponse),
      textStyle: {
        color: '#81839A',
        fontWeight: 'normal',
        fontFamily: 'Bai Jamjuree',
        fontSize: 20,
        lineHeight: 25,
      },
      backgroundColor: '#ffffff',
      text: getEchartTitleText(dataResponse, activeAnchor),
      left: 'center',
      top: '40%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          formatter: function (params) {
            if (params.axisDimension === 'y') {
              return Number(params.value).toFixed(2) + 'ms';
            } else {
              return moment(params.value).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              );
            }
          },
        },
      },
      alwaysShowContent: false,
      formatter: function (params) {
        let formattedData = [];
        for (let i = 0; i < params.length; i++) {
          formattedData.push(`
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-height: 1.7rem">
                                <div style="margin-right: 1.7rem;">
                                    <span style="display: inline-block; margin-right: 4px; border-radius: 2px; width: 10px; height: 10px; background-color: ${
                                      params[i].color
                                    }"></span>
                                    ${params[i].seriesName}: 
                                </div>
                                <p style="margin-left: auto; font-weight: bold; color: '#fff',">
                                    ${Number(params[i].value).toFixed(2)}ms
                                <p>
                            </div>
                        `);
        }
        return `
                <div style="display: flex; flex-direction: column">
                    ${formattedData.join('')}
                </div>
        `;
      },
      padding: [10, 20, 10, 20],
    },
    grid: {
      left: '8%',
      right: '2%',
      top: '40px',
      containLabel: true,
    },
    legend: {
      z: -1,
      top: -100,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getEchartAxisLabel(dataResponse),
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          opacity: 0.3,
          width: 1,
        },
      },
      axisLabel: {
        show: true,
        margin: 16,
        formatter: function (value, index) {
          return moment(value).format('hh:mm a');
        },
      },
      // name: "Time (UTC)",
      nameLocation: 'middle',
      nameTextStyle: {
        color: 'rgba(18, 21, 62, 1)',
        fontStyle: 'normal',
        fontWeight: 'bolder',
        lineHeight: 14,
        padding: [25, 0, 0, 0],
        fontSize: 12,
        fontFamily: 'Bai Jamjuree',
        align: 'center',
        verticalAlign: 'top',
      },
    },
    yAxis: {
      type: 'value',
      // max: 400,
      position: 'left',
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          opacity: 0.3,
        },
      },
      axisLabel: {
        show: true,
        margin: 16,
        formatter: function (value, index) {
          return `${value}`;
        },
      },
      name: 'Latency(ms)',
      nameLocation: 'middle',
      nameTextStyle: {
        color: 'rgba(18, 21, 62, 1)',
        fontStyle: 'normal',
        fontWeight: 'bolder',
        lineHeight: 14,
        padding: [0, 0, 40, 0],
        fontSize: 12,
        fontFamily: 'Bai Jamjuree',
        align: 'center',
        verticalAlign: 'bottom',
      },
    },
    series: getEchartSeries(dataResponse, areaEnabled),
    dataZoom: [
      {
        show: true,
      },
    ],
  };
};

class MetricsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      isAreaEnabled: false,
      activeTimeMetricsIndex: 0,
      activeChartTypeMetics: { id: '0', label: 'Line' },
      activeAnchorClusterMetrics: null,
      metricsDataResponse: null,
      chartRendered: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  echartRef = React.createRef();

  handleChange() {
    this.setState({ checked: !this.state.checked });
  }
  defineMetricsBtnWrapperStyle = (index) =>
    this.state.activeTimeMetricsIndex === index
      ? { ...timeMetricsBtnStyle, background: '#E9EbF4' }
      : { ...timeMetricsBtnStyle };

  updateActiveTimeMetrics = async (index) => {
    this.setState({ activeTimeMetricsIndex: index });
  };

  updateActiveAnchorCluster = (el) => {
    this.setState({ activeAnchorClusterMetrics: el });
  };

  setChartTypeActiveMetrics = (el) => {
    this.setState({
      isAreaEnabled: el.id === '0' ? false : true,
      activeChartTypeMetics: el,
    });
  };

  startPollingStats = async () => {
    let end = moment().format('X');
    let start;

    switch (this.state.activeTimeMetricsIndex) {
      case 0:
        // 1 Hour mark!
        start = moment().subtract(1, 'hour').format('X');
        break;
      case 1:
        // 3 Hour mark !
        start = moment().subtract(3, 'hour').format('X');
        break;
      case 2:
        // 5 Hour mark!
        start = moment().subtract(5, 'hour').format('X');
        break;
      case 3:
        // 1 Day mark!
        start = moment().subtract(1, 'day').format('X');
        break;
      case 4:
        // 3 Day mark!
        start = moment().subtract(3, 'day').format('X');
        break;
      case 5:
        // 1 Week mark!
        start = moment().subtract(1, 'week').format('X');
        break;
      default:
        return;
    }
    const response = await this.props.getCompareClusterStats(
      this.state.activeAnchorClusterMetrics[CLUSTER_COMPARE_KEY],
      this.props.activeSelectedSlice?.sliceName === 'All Slice'
        ? null
        : this.props.activeSelectedSlice.sliceName,
      start,
      end
    );
    this.setState({
      metricsDataResponse: response,
      chartRendered: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const newAnchorState =
      prevState.activeAnchorClusterMetrics ===
      this.state.activeAnchorClusterMetrics;
    const newSliceState =
      prevProps.activeSelectedSlice === this.props.activeSelectedSlice;
    const newTimeMetricsState =
      prevState.activeTimeMetricsIndex === this.state.activeTimeMetricsIndex;

    // CHECKING WHETHER WE REPLACED THE EXISTING ACTIVE SELECTED SLICE
    if (!newSliceState) {
      // RESETTING ANCHORED CLUSTER AND METRICS DATA RESPONSE EVERYTIME USER SELECTS NEW ANCHORED SLICE
      this.setState({
        ...this.state,
        activeAnchorClusterMetrics: null,
        metricsDataResponse: null,
        chartRendered: false,
      });
    } else {
      // Checking whether we have selected an active cluster and valid slice and time metrics
      if (
        (this.state.activeAnchorClusterMetrics !== null && !newAnchorState) ||
        (this.state.activeAnchorClusterMetrics !== null && !newSliceState) ||
        (this.state.activeAnchorClusterMetrics !== null && !newTimeMetricsState)
      ) {
        if (
          this.props.activeSelectedSlice &&
          this.state.activeAnchorClusterMetrics
        ) {
          // Pool data as per new parameter
          this.startPollingStats();
        }
      }
    }
  }

  render() {
    const myEcharts = this.echartRef.current;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 'inherit',
        }}
      >
        <Paper
          variant="outlined"
          sx={{ borderRadius: '4px', border: '1px solid #B6B8D6' }}
          style={{ marginTop: '1rem', minHeight: 'inherit', padding: '1rem' }}
        >
          <Typography
            fontSize="1rem"
            lineHeight="1.25rem"
            fontWeight="600"
            textAlign="left"
            paddingTop={'0.5rem'}
          >
            {this.state.checked ? 'Topological View' : 'Chart view'}
          </Typography>

          {this.state.checked ? (
            <TopologicalView />
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <MetricsDropdown
                  label={'Anchor Cluster'}
                  width={'280px'}
                  activeOption={this.state.activeAnchorClusterMetrics}
                  compareOptionKey={CLUSTER_COMPARE_KEY}
                  handleUpdateActiveOption={(el) =>
                    this.updateActiveAnchorCluster(el)
                  }
                  options={this.props.clusterData.filter((el) =>
                    el.slices.some(
                      (slice) =>
                        slice.name ===
                        this.props.activeSelectedSlice[SLICE_COMPARE_KEY]
                    )
                  )}
                  placeholder={'Choose a Cluster'}
                />

                {/* Parameter Metrics */}
                <div
                  style={{
                    ...centerStyle,
                    flexDirection: 'row',
                  }}
                >
                  {/* Time metrics */}
                  <div
                    style={{
                      ...centerStyle,
                      flexDirection: 'row',
                      marginRight: '10px',
                    }}
                  >
                    <div
                      style={this.defineMetricsBtnWrapperStyle(0)}
                      onClick={() => this.updateActiveTimeMetrics(0)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1hr</Typography>
                    </div>
                    <div
                      style={this.defineMetricsBtnWrapperStyle(1)}
                      onClick={() => this.updateActiveTimeMetrics(1)}
                    >
                      <Typography style={timeMetricsBtnLabel}>3hr</Typography>
                    </div>
                    <div
                      style={this.defineMetricsBtnWrapperStyle(2)}
                      onClick={() => this.updateActiveTimeMetrics(2)}
                    >
                      <Typography style={timeMetricsBtnLabel}>5hr</Typography>
                    </div>
                    <div
                      style={this.defineMetricsBtnWrapperStyle(3)}
                      onClick={() => this.updateActiveTimeMetrics(3)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1d</Typography>
                    </div>
                    <div
                      style={this.defineMetricsBtnWrapperStyle(4)}
                      onClick={() => this.updateActiveTimeMetrics(4)}
                    >
                      <Typography style={timeMetricsBtnLabel}>3d</Typography>
                    </div>
                    <div
                      style={this.defineMetricsBtnWrapperStyle(5)}
                      onClick={() => this.updateActiveTimeMetrics(5)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1w</Typography>
                    </div>
                  </div>

                  {/* Chart Type */}
                  <MetricsDropdown
                    label={'Type '}
                    width={'155px'}
                    activeOption={this.state.activeChartTypeMetics}
                    compareOptionKey={'label'}
                    handleUpdateActiveOption={(el) =>
                      this.setChartTypeActiveMetrics(el)
                    }
                    options={[
                      {
                        id: '0',
                        label: 'Line Chart',
                        shortLabel: 'Line',
                      },
                      {
                        id: '1',
                        label: 'Area Chart',
                        shortLabel: 'Area',
                      },
                    ]}
                    placeholder={'Chart'}
                    renderValue={(val) => val.split(' ')[0]}
                  />
                </div>
              </div>
              <ReactECharts
                ref={(e) => {
                  this.echartRef.current = e;
                }}
                option={eChartOption(
                  this.state.metricsDataResponse,
                  this.state.activeAnchorClusterMetrics,
                  this.state.isAreaEnabled
                )}
                style={{ width: '100%', minHeight: '500px' }}
                notMerge={true}
                onEvents={{
                  finished: () => {
                    if (
                      !this.state.chartRendered &&
                      this.state.metricsDataResponse &&
                      myEcharts?.getEchartsInstance().getModel().getSeries()
                    ) {
                      this.setState((oldState) => ({
                        ...oldState,
                        chartRendered: true,
                      }));
                    }
                  },
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '10%',
                  marginTop: '20px',
                  flexWrap: 'wrap',
                  '& > *': {
                    flex: '0 0 25%', // Each legend takes up 25% of the container width, allowing 4 legends per line
                    boxSizing: 'border-box',
                    padding: '5px', // Add padding for spacing between legends
                  },
                }}
              >
                {this.state.chartRendered &&
                  myEcharts
                    ?.getEchartsInstance()
                    .getModel()
                    .getSeries()
                    .map((serie) => (
                      <LegendCheckBox
                        onClick={() => {
                          myEcharts.getEchartsInstance().dispatchAction({
                            type: 'legendToggleSelect',
                            name: serie.name,
                          });
                        }}
                        color={serie.option.color}
                      >
                        {serie.name}
                      </LegendCheckBox>
                    ))}
              </Box>
            </>
          )}
        </Paper>
      </div>
    );
  }
}

const LegendCheckBox = ({ onClick, children, color }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <FormGroup>
      <FormControlLabel
        onChange={() => {
          setToggle(!toggle);
          onClick();
        }}
        control={
          <Checkbox
            sx={{
              color: color,
              '&.Mui-checked': {
                color: color,
              },
            }}
            defaultChecked
            icon={<UnChecked fill={color} />}
            checkedIcon={<Checked fill={color} />}
          />
        }
        label={children}
      />
    </FormGroup>
  );
};

const mapStateToProps = (state) => {
  return {
    clusterData: state.dashboard.clusterDetail,
    sliceData: state.dashboard.slices,
    activeSelectedSlice: state.dashboard.activeSelectedSlice,
  };
};

export default connect(mapStateToProps, {
  getCompareClusterStats,
  setActiveSelectedSlice,
})(MetricsTab);
