import { Paper, Typography } from '@mui/material';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getRqtrendsData
} from "../../../actions/dashboardActions";
import { getAllClustersForSlice } from "../../../actions/SliceActions";
import { CLUSTER_COMPARE_KEY, SLICE_COMPARE_KEY } from "../../../constants/enums";
import MetricsDropdown from "../MetricsDropdown";
import Charts from './Charts';
import './index.css';

const centerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  
  const timeMetricsBtnStyle = {
    width: "28px",
    height: "23px",
    borderRadius: "4px",
    marginRight: "10px",
    cursor: "pointer",
    ...centerStyle,
  };
  const timeMetricsBtnLabel = {
    fontSize: "12px",
    lineHeight: "16px",
  };
  
const ResourceQuota = () => {
  const clusterData = useSelector((state)=>state.dashboard.clusterDetail);
  const activeSelectedSlice= useSelector((state)=>state.dashboard.activeSelectedSlice);
  const responseData= useSelector((state)=>state.dashboard.rqTrendsData);
  const clusterList = useSelector((state) => state.slices.sliceClusters);
    const [activeTimeMetrics,setActiveTimeMetrics] = useState(0);
    const [activeChartTypeMetics,setActiveChartTypeMetics]= useState({ id: "0", label: "Line" })
    const [activeCluster,setActiveCluster] =useState();
    const [activeNamespace,setActiveNamespace] =useState();
    const [namespaceList,setNamespaceList] = useState([]);
const dispatch = useDispatch();
    
 const   defineMetricsBtnWrapperStyle = (index) =>
    activeTimeMetrics === index
      ? { ...timeMetricsBtnStyle, background: "#E9EbF4" }
      : { ...timeMetricsBtnStyle };

      useEffect(() => {
        setActiveCluster('');
        setActiveNamespace('');
        setNamespaceList([]);
        dispatch(getAllClustersForSlice(activeSelectedSlice?.sliceName));
      }, [activeSelectedSlice]);

useEffect(()=>{

fetchData();
},[activeTimeMetrics,activeNamespace,activeCluster,activeSelectedSlice])

useEffect(()=>{
  setActiveNamespace('');
if (activeCluster)
{
  let newArr=[];
  clusterList?.items?.map((item)=>{
    if(item?.metadata?.labels?.['worker-cluster'] === activeCluster?.clusterName)
    {
      item?.spec?.namespaceIsolationProfile?.applicationNamespaces?.map((x,index)=>{
          let obj={
            id: index,
            name:x
          }
          newArr.push(obj);
      });
    }
  })
  setNamespaceList(newArr);
}
else
{
  setNamespaceList([]);
}
},[activeCluster])


const fetchData = async () => {
  let end = moment().format("X");
  let start;

  switch (activeTimeMetrics) {
    case 0:
      // 1 Hour mark!
      start = moment().subtract(1, "hour").format("X");
      break;
    case 1:
      // 3 Hour mark !
      start = moment().subtract(3, "hour").format("X");
      break;
    case 2:
      // 5 Hour mark!
      start = moment().subtract(5, "hour").format("X");
      break;
    case 3:
      // 1 Day mark!
      start = moment().subtract(1, "day").format("X");
      break;
    case 4:
      // 3 Day mark!
      start = moment().subtract(3, "day").format("X");
      break;
    case 5:
      // 1 Week mark!
      start = moment().subtract(1, "week").format("X");
      break;
    default:
      return;
  }

  if (activeSelectedSlice?.sliceName)
  {
  dispatch(getRqtrendsData(activeSelectedSlice?.sliceName,activeCluster?.clusterName,activeNamespace?.name,start,end));
  }
};

  return (
    <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "inherit",
        }}
      >
        <Paper
          variant="outlined"
          square
          style={{ minHeight: "inherit", padding: "0 16px",paddingBottom:'16px',borderRadius:'4px',borderColor:'#B6B8D6'}}
        >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div style={{display:'flex',columnGap:'16px',width:'100%'}}>
                <MetricsDropdown
                  label={"Cluster "}
                  width={"200px"}
                  activeOption={activeCluster}
                  compareOptionKey={CLUSTER_COMPARE_KEY}
                  handleUpdateActiveOption={(el) =>
                    setActiveCluster(el)
                  }
                  options={clusterData.filter((el) =>
                    el.slices.some(
                      (slice) =>
                        slice.name ===
                        activeSelectedSlice[SLICE_COMPARE_KEY]
                    )
                  )}
                  placeholder={"All Clusters"}
                  clearSelected={true}
                />
                <MetricsDropdown
                  label={"Namespace "}
                  width={"230px"}
                  activeOption={activeNamespace}
                  compareOptionKey={'name'}
                  handleUpdateActiveOption={(el) =>
                    setActiveNamespace(el)
                  }
                  options={namespaceList}
                  placeholder={"All Namespaces"}
                  clearSelected={true}
                />
                </div>

                {/* Parameter Metrics */}
                <div
                  style={{
                    ...centerStyle,
                    flexDirection: "row",
                  }}
                >
                  {/* Time metrics */}
                  <div
                    style={{
                      ...centerStyle,
                      flexDirection: "row",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={defineMetricsBtnWrapperStyle(0)}
                      onClick={() => setActiveTimeMetrics(0)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1hr</Typography>
                    </div>
                    <div
                      style={defineMetricsBtnWrapperStyle(1)}
                      onClick={() => setActiveTimeMetrics(1)}
                    >
                      <Typography style={timeMetricsBtnLabel}>3hr</Typography>
                    </div>
                    <div
                      style={defineMetricsBtnWrapperStyle(2)}
                      onClick={() => setActiveTimeMetrics(2)}
                    >
                      <Typography style={timeMetricsBtnLabel}>5hr</Typography>
                    </div>
                    <div
                      style={defineMetricsBtnWrapperStyle(3)}
                      onClick={() => setActiveTimeMetrics(3)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1d</Typography>
                    </div>
                    <div
                      style={defineMetricsBtnWrapperStyle(4)}
                      onClick={() => setActiveTimeMetrics(4)}
                    >
                      <Typography style={timeMetricsBtnLabel}>3d</Typography>
                    </div>
                    <div
                      style={defineMetricsBtnWrapperStyle(5)}
                      onClick={() => setActiveTimeMetrics(5)}
                    >
                      <Typography style={timeMetricsBtnLabel}>1w</Typography>
                    </div>
                  </div>

                  {/* Chart Type */}
                  <MetricsDropdown
                    label={"Type "}
                    width={"155px"}
                    activeOption={activeChartTypeMetics}
                    compareOptionKey={"label"}
                    handleUpdateActiveOption={(el) =>
                        setActiveChartTypeMetics(el)
                    }
                    options={[
                      {
                        id: "0",
                        label: "Line",
                      },
                      {
                        id: "1",
                        label: "Area",
                      },
                    ]}
                    placeholder={"Chart"}
                  />
                </div>
              </div>
              {/*<ReactECharts
                option={eChartOption(this.state.metricsDataResponse, this.state.activeAnchorClusterMetrics, this.state.isAreaEnabled)}
                style={{ width: "100%", minHeight: "62vh" }}
                notMerge={true}
                />*/}
                <Charts activeChartTypeMetics={activeChartTypeMetics} data={responseData} activeTimeMetrics={activeTimeMetrics}/>
            </Paper>
    </div>
  )
}

export default ResourceQuota;