import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Tooltip,
  Fade,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-github";
import YAML from "yaml";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { connect } from "react-redux";

function CodePreview(props) {
  const [isCopied, setIsCopied] = useState(false);
  const [yamlEditorText, setYamlEditorText] = useState("");

  React.useEffect(() => {
    if (props.currentSlice) {
      //Replace addition comma in application namespace to display in yaml
      const updatedSlice = JSON.parse(JSON.stringify(props.currentSlice));
      let updatedApplicationNamespaces = [];
      if(updatedSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces?.length > 0) {
        updatedApplicationNamespaces = [...updatedSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces];
      }
      
      if (updatedApplicationNamespaces?.length > 0) {
        updatedApplicationNamespaces.forEach((item, index) => {
          let foundIndex = item['clusters'].indexOf("'*'");

          if (foundIndex >= 0) {
            updatedApplicationNamespaces[index]['clusters'][foundIndex] = updatedApplicationNamespaces[index]['clusters'][foundIndex].replace(/[']+/g, '');
          }
        });
        updatedSlice.spec.namespaceIsolationProfile.applicationNamespaces = [...updatedApplicationNamespaces];
      }

      const doc = new YAML.Document();
      doc.contents = updatedSlice;
      setYamlEditorText(doc.toString());
    }
  }, []);

  const copyToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(yamlEditorText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = yamlEditorText;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      });
    }
  };

  const closeEditor = () => {
    props.setClose(false);
    props.setCurrHeight('30vh');
  };
  const onClickHandler = () => {
    if (props.currHeight === '30vh') {
      props.setCurrHeight('95vh')
    }
    else {
      props.setCurrHeight('30vh')
    }
  }


  return (
    <Grid
      item
      xs
      container
      backgroundColor="#ffffff"
      borderRadius="14px"
      flexDirection="column"
      direction="column"
      justifyContent="space-between"
    >
      {props?.close && (
        <Grid
          item
          sx={{
            backgroundColor: "#272822",
            padding: "24px",
          }}
        >
          <span
            style={{
              margin: "30px 0 8px",
              fontSize: "20px",
              fontWeight: "700",
              color: "white",
            }}
          >
            Code Preview
            <IconButton
              onClick={closeEditor}
              sx={{ float: "right" }}
              aria-label="close editor"
              component="span"
            >
              <CancelIcon color="white" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={onClickHandler}
              sx={{ float: "right" }}
              aria-label="expand collapse"
              component="span"
            >
              {props.currHeight === '30vh' ? <OpenInFullIcon color="white" fontSize="small" /> : <CloseFullscreenIcon color="white" fontSize="small" />}
            </IconButton>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={isCopied}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
            >
              <IconButton
                onClick={copyToClipboard}
                sx={{ float: "right" }}
                aria-label="copy yaml code"
                component="span"
              >
                <ContentCopyIcon color="white" fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <AceEditor
            mode="sass"
            theme="monokai"
            name="Slice YAML"
            height={props.currHeight}
            width="100%"
            onChange={(value) => setYamlEditorText(value)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={yamlEditorText}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    currentSlice: state.NamespaceOnboard.currentSlice,
  };
};
export default connect(mapStateToProps, {

})(CodePreview);

