import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ChevronRightCustom from '@/assets/icons/ChevronRightCustom.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PendingIcon from '@mui/icons-material/Pending';
import { useDispatch, useSelector } from 'react-redux';
import { getSliceCustomObjectList ,getListMigrationPairs,getMigrationJobList } from '../../actions/ConnectClusterActions';
import CustomDrawer from '../newUiSidebar/slice-create-loading';

const CustomTooltip = ({
  content,
  onCreateLoading,
  setsocketData,
  parentcssProps,
}) => {
  const handleClick = (sliceName, socketId, identifier, clusterName) => {
    onCreateLoading(true);
    setsocketData({
      sliceName: sliceName,
      socketId: socketId,
      identifier: identifier,
      clusterName: clusterName,
    });

    if(identifier==="SLICE_REPLICATION"){
      window.location.href = `/replicate`;
    }else{
      window.location.href = `/slices`;
    }

  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        width: '320px',
        padding: '4px 11px',
        right: '-160px',
        transform: 'translateX(-50%)',
        top: parentcssProps?.tooltip ? parentcssProps?.tooltip?.top : '50px',
        zIndex: 1,
        backgroundColor: 'white',
        border: '1px solid gray',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <div>
        {content.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              order: index === 0 ? 1 : 0,
              marginBottom: '8px',
            }}
          >
            <span
              style={{
                color: '#ACB9FF',
                display: 'flex',
                alignItems: 'center',
                marginRight: '2px',
              }}
            >
              <PendingIcon
                fontSize="large"
                style={{ color: '#ACB9FF', marginRight: '4px' }}
                className="mr-2"
              />
              <div style={{ color: '#5A607C' }}>
                {item?.identifier === 'SLICE_DELETE'
                  ? 'Deleting Slice'
                  : item?.identifier === 'CLUSTER_OFFBOARDING'
                    ? 'Detaching Cluster'
                    : item?.identifier === 'SLICE_PROPAGATION'
                      ? 'Propagating Slice '
                      : item?.identifier === 'NAMESPACE_OFFBOARDING'
                        ? 'Offboarding Namespace'
                        : item?.identifier}
                <div>{`Slice: ${item?.sliceName}`}</div>
              </div>
            </span>

            <KeyboardArrowRightIcon
              fontSize="large"
              style={{ color: '#A1A4D3' }}
              onClick={() =>
                handleClick(
                  item?.sliceName,
                  item?.socketId,
                  item?.identifier,
                  item?.clusterName
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TopbarSpinner = ({ cssProps }: { cssProps: any }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [onCreateLoading, setOnCreateLoading] = useState(false);
  const [socketData, setsocketData] = useState({});
  const dispatch = useDispatch();

  const SliceData = useSelector((state) => state.ConnectCluster.sliceList);
  const getAllReplicatedList =useSelector((state) =>state.ConnectCluster.pair )
  const getmigrationJob =useSelector((state) => state.ConnectCluster.job)

  const [replicationFullData, setreplicationFullData] = useState([])

  let SpinnerData = [];

  useEffect(() => {
    if (getAllReplicatedList && getAllReplicatedList.data) {
      const newData = getAllReplicatedList?.data?.flatMap((obj) => {
        if (obj.metadata?.annotations) {
          const applicationSlices = obj?.metadata?.annotations["kubeslice.io/application-slices"];
          if (applicationSlices?.includes(",")) {
            return applicationSlices?.split(",").map((slice) => slice?.trim())
              .map((sliceName) => ({
                ...obj,
                metadata: {
                  ...obj?.metadata,
                  annotations: { "kubeslice.io/application-slices": sliceName },
                },
                jobfilter: getmigrationJob?.data?.filter((data) => {
                  return data.spec.migrationSliceName === obj?.metadata?.labels?.["migration-slice-name"]
                    && data.metadata.labels["application-slice-name"] === sliceName;
                })
              }));
          } else {
            return [
              {
                ...obj,
                jobfilter: getmigrationJob?.data?.filter((data) => {
                  return data.spec.migrationSliceName === obj?.metadata?.labels?.["migration-slice-name"]
                    && data?.metadata?.labels["application-slice-name"] === applicationSlices;
                })
              }
            ]; 
          }
        } else {
          return []; // Return an empty array if no metadata
        }
      });
      setreplicationFullData(newData);
    }
  }, [getAllReplicatedList, getmigrationJob]);


  replicationFullData.map((rep) => {
    rep?.jobfilter?.forEach((job) => {
      if (job?.status?.backup?.phase === "InProgress" || job?.status?.restore?.phase === "InProgress" || job?.status?.backup?.phase === undefined && job?.status?.restore?.phase === undefined
        || (job?.status?.backup?.phase === "Completed" && !job?.status?.restore?.phase)) {
        SpinnerData.push({
          sliceName: rep?.metadata?.annotations?.["kubeslice.io/application-slices"],
          identifier: "SLICE_REPLICATION",
          socketId: job?.metadata?.name,
          clusterName: rep?.spec,
        });
      }
    });
  });

  useEffect(() => {
    dispatch(getSliceCustomObjectList());
    dispatch(getListMigrationPairs())
    dispatch(getMigrationJobList())
  }, []);


  let timeoutId;
  const openTooltip = () => {
    clearTimeout(timeoutId); 
    setTooltipOpen(true);
  };

  const closeTooltip = () => {
    timeoutId = setTimeout(() => {
      setTooltipOpen(false);
    }, 500);
  };



  const onCloseCreateLoadingModal = () => {
    setOnCreateLoading(false);
    setsocketData({});
  };


  if (SliceData && SliceData.length > 0) {
    SliceData.forEach((objectData) => {
      if (objectData.status && objectData.status.kubesliceEvents) {
        objectData.status.kubesliceEvents.forEach((event) => {
          if (event.type === 'InProgress') {
            SpinnerData.push({
              sliceName: objectData.metadata.name,
              identifier: event.action,
              socketId: objectData.metadata.name,
              clusterName:
                event.action === 'CLUSTER_OFFBOARDING' ? event.identifier : '',
            });
          }
        });
      }
    });
  }

  localStorage.setItem('RedirectionTooltip', JSON.stringify(socketData));
  return (
    <div onMouseEnter={openTooltip} onMouseLeave={closeTooltip} style={{cursor:'pointer'}}>
      <div
        style={{
          right: cssProps?.spinner ? cssProps?.spinner?.right : '85px',
          top: cssProps?.spinner ? cssProps?.spinner?.top : '19px',
        }}
      >
        <div className="cursor-pointer relative">
          {SpinnerData && SpinnerData.length > 0 && (
            <>
              {' '}
              <CircularProgress size="1.8rem" style={{ color: '#2868F3' }} />
            </>
          )}
        </div>
      </div>
      {tooltipOpen && (
        <CustomTooltip
          content={SpinnerData}
          onCreateLoading={setOnCreateLoading}
          parentcssProps={cssProps}
          onCloseCreateLoadingModal={() => onCloseCreateLoadingModal()}
          setsocketData={setsocketData}
        />
      )}
      {socketData && <CustomDrawer open={false} />}
    </div>
  );
};

export default TopbarSpinner;
