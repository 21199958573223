import moment from 'moment';
import { SdkBackend } from '../backend/MeshBackend';
import { license_status } from '../constants/license';
import {
  HIDE_EXPIRATION_BANNER,
  HIDE_LICENSING_BANNER,
  SET_LICENSE_DETAILS,
  SET_LICENSE_STATUS,
  SHOW_EXPIRATION_BANNER,
} from './types';

export const hideBanner = () => {
  document.cookie =
    'showLicenseBanner=false;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return {
    type: HIDE_LICENSING_BANNER,
  };
};

export const hideExpiredBanner = () => {
  document.cookie =
    'showLicenseExpiryModal=false;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return {
    type: HIDE_EXPIRATION_BANNER,
  };
};

export const hideInvalidModal = () => {
  document.cookie =
    'showInvalidLicenseModal=false;Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  return {
    type: HIDE_EXPIRATION_BANNER,
  };
};

const sdkBackend = new SdkBackend();

export const getLicensingDetails = () => {
  return async (dispatch, getState) => {
    try {
      // fetch slice list
      let response = await sdkBackend.getLicenseDetails(getState(), dispatch);
      if (response) {
        dispatch({
          type: SET_LICENSE_DETAILS,
          payload: response.details,
        });

        const { 'license-expiration': licenseExpiration } =
          response.details || {};

        const expired = moment(licenseExpiration).isBefore();

        if (expired) {
          dispatch({
            type: SHOW_EXPIRATION_BANNER,
            payload: license_status.expired,
          });
          dispatch({
            type: SET_LICENSE_STATUS,
            payload: license_status.expired,
          });
        } else {
          dispatch({
            type: SET_LICENSE_STATUS,
            payload: license_status.valid,
          });
        }
      }
      // else {
      //   dispatch({
      //     type: SET_LICENSE_DETAILS,
      //     payload: null,
      //   });
      //   // dispatch({
      //   //   type: SET_LICENSE_STATUS,
      //   //   payload: license_status.invalid,
      //   // });
      // }
    } catch (error) {
      // dispatch({
      //   type: SET_LICENSE_DETAILS,
      //   payload: null,
      // });
      const possible_status_codes = [422, 404, 500];

      if (possible_status_codes.includes(error.status)) {
        dispatch({
          type: SET_LICENSE_STATUS,
          payload: license_status.invalid,
        });
      }
    }
  };
};
