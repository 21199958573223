import * as React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import edgeLogo from '../../assets/images/edgeLogo.svg';

const useStyles = makeStyles((theme) => ({
  mainCloudContainer: {
    width: '300px',
    maxWidth: '400px',
    height: '45x',
    fontFamily: 'Bai Jamjuree',
  },
  chipCloudContainer: {
    '&:hover': {
      WebkitBorderRadius: '10px',

      transitionDuration: '1s',

      '& $chipCloudContainer': {
        borderRadius: '10px',
      },

      '& $chipInnerCloudContainer': {
        display: 'flex',

        width: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
      },

      '& $chipCloudIndex': {
        background: 'white',
        color: 'black',
        height: '20px',
        marginTop: '5px',
        width: '20px',
        marginLeft: '10px',
      },
      '& $chipCloudLabel': {
        display: 'block',
        marginLeft: '0.5rem',
        marginRight: '0.1rem',
      },
      '& $chipCloudBigLogo': {
        display: 'block',
        zIndex: '2',
        height: '50px',
      },
      '& $chipCloudSmallLogo': {
        display: 'none',
      },
      '& $arrow': {
        marginTop: '20px',
        content: ' ',
        height: '0',
        position: ' absolute',
        width: '0',
        left: '160px',
        border: '10px solid transparent',
        marginLeft: '15px',
        borderWidth: '10px',
        borderStyle: 'solid',
        borderColor: '#C43AAA transparent transparent',
        bottom: '1px',
        marginBottom: '0px',
        borderBottomWidth: '8px',
      },
    },
  },

  chipInnerCloudContainer: () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2px',
    position: 'relative',
    justifyContent: 'center',
  }),
  arrow: () => ({
    display: 'block',
  }),
  chipCloudBigLogo: () => ({}),
  chipCloudSmallLogo: () => ({
    display: 'block',
    height: '30px',
    marginLeft: '-4px',
  }),
  chipCloudLabel: () => ({}),
  chipCloudIndex: () => ({
    background: 'transparent',
    color: 'white',
  }),

  chipCloudIndexContainer: () => ({
    height: '20px',
    width: '20px',
    background: '#C43AAA',
    fontSize: '12px',
    fontWeight: 600,
    color: '#FFFFFF',
    lineHeight: '20px',
  }),
}));

function Block1(props) {
  const classes = useStyles();

  return (
    <Box
      sx={{
        height: '24px',
        width: '40px',
        display: 'inline-flex',
        justifyContent: 'center',
      }}
    >
      <Box className={classes.chipInnerCloudContainer}>
        <Box className={classes.chipCloudIndexContainer}>{props.index}</Box>
      </Box>
    </Box>
  );
}

export const BlockTooltip = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainCloudContainer}>
      <Box className={classes.chipInnerCloudContainer}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '2px',
          }}
        >
          <Box className={classes.chipCloudBigLogo}>
            <Box
              component="img"
              sx={{
                height: '41px',
                width: '27px',
                position: 'relative',
                bottom: '5px',
              }}
              alt="Natacha"
              src={edgeLogo}
            />
          </Box>
          <Box className={classes.chipCloudLabel}>{props.data}</Box>
          <Box
            className={classes.chipCloudIndexContainer}
            sx={{
              background: '#FFFFFF',
              borderRadius: '25px',
              color: 'black',
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              marginBottom: '1px',
              marginRight: '8px',
            }}
          >
            {props.index}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              background: '#C43AAA',
              borderRadius: '40px',
              left: '-2px',
              right: '4px',
              top: '6px',
              bottom: '7px',
              zIndex: '-1',
            }}
          />
          <Box
            sx={{
              width: 0,
              height: 0,
              borderStyle: 'solid',
              borderWidth: '5.5px 5px 0 5px',
              borderColor: '#C43AAA transparent transparent transparent',
              position: 'absolute',
              left: 'auto',
              right: 'auto',
              bottom: '3px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Block1;
