import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Tooltip,
  styled,
} from '@mui/material';
import Fallback from './Fallback';
import './index.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSliceObjectData } from '../../../../actions/ConnectClusterActions';
import { useDispatch } from 'react-redux';
import React, { useContext } from 'react';
import MyContext from './context';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function SelectedNamespace({
  selectedNamespace,
  setSelectedNamespace,
  DisableOffboardCluster,
  // SelectionSate,
  // setSelectionSate,
}) {
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const [clearAllDialog, setClearAllDialog] = React.useState(false);
  const [DisableOffboardClusterS, setDisableOffboardClusterS] = useState(false);
  const [defaultNameSpaceObj, setdefaultNameSpaceObj] = React.useState([]);
  const dispatch = useDispatch();
  const { setSelectionSate } = useContext(MyContext);
  const { SelectionSate } = useContext(MyContext);
  const [deletedItems, setdeletedItems] = useState([]);

  const clearAll = () => {
    setSelectionSate({ selectionStatecontext: false, clearAllcontext: true });
    setSelectedNamespace([]);
    setClearAllDialog(false);
  };

  const openDialog = () => {
    setClearAllDialog(true);
  };

  const closeDialog = () => {
    setClearAllDialog(false);
  };

  useEffect(() => {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardClusterS(disableButton);
  }, [currentSlice]);
  useEffect(() => {
    if (SelectionSate.openModalCancel) {
      dispatch(getSliceObjectData(currentSlice?.metadata?.name)).then(
        (response) => {
          setSelectedNamespace([
            ...response?.spec?.namespaceIsolationProfile?.applicationNamespaces,
          ]);
        }
      );
    }
  }, [SelectionSate.openModalCancel]);

  const removeSelectedNamespace = (namespace, cluster) => {
    if (!selectedNamespace || !Array.isArray(selectedNamespace)) {
      return;
    }
    let deletedItems = [];
    const results = [...selectedNamespace];

    results.filter(Boolean).forEach((selected, index) => {
      if (selected?.namespace === namespace) {
        let clusterIndex = selected.clusters.indexOf(cluster);
        if (clusterIndex >= 0) {
          deletedItems.push({
            namespace: selected.namespace,
            clusters: [selected.clusters[clusterIndex]],
          });
          results[index]['clusters'].splice(clusterIndex, 1);
        }
      }
    });
    setSelectionSate({
      selectionStatecontext: true,
      clearAllcontext: false,
      openModal: true,
      deletedItems: deletedItems,
      openModalCancel: false,
    });
    // console.log(SelectionSate);
    setSelectedNamespace(results);
    setdeletedItems(deletedItems);
    return deletedItems;
  };
  return (
    <Box sx={{ flex: 5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div className="selected-namespace-header">
          <Grid sx={{ padding: '0px', alignItems: 'center' }} container>
            <Grid
              p={0}
              item
              style={{
                padding: '15px 0px 15px 24px',
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#12153E',
              }}
              md={9}
            >
              SELECTED NAMESPACES
            </Grid>
            <Grid item md={3} alignContent={'end'}>
              <Button
                disabled={DisableOffboardCluster || DisableOffboardClusterS}
                sx={{ textTransform: 'none', color: '#2868F3', paddingLeft: '24px', paddingBottom: '15px', paddingTop: '15px', lineHeight: '16px' }}
                onClick={openDialog}
              >
                Clear All
              </Button>
            </Grid>
          </Grid>
        </div>
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', padding: '8px 10px' }}
          className="selected-namespace-content"
        >
          {selectedNamespace?.length > 0 ? (
            selectedNamespace?.map((selected, key) => {
              return selected?.clusters?.map((cluster, index) => {
                return (
                  <Box
                    disabled={DisableOffboardCluster || DisableOffboardClusterS}
                    key={`${selected.namespace}-${index}`}
                    className="selected-namespace-item"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: '1px solid #2868F3',
                      borderRadius: '2px',
                      margin: '10px 0 10px 10px',
                    }}
                  >
                    {cluster === `*` || cluster === `'*'` ? (
                      <Tooltip title="Namespace Sameness" arrow placement="top">
                        <Box
                          sx={{ padding: '2px 0px 2px 12px' }}
                          style={{ color: 'green' }}
                        >
                          {selected.namespace}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip title={cluster.toString()} arrow placement="top">
                        <Box sx={{ padding: '2px 0px 2px 12px' }}>
                          {selected.namespace}
                        </Box>
                      </Tooltip>
                    )}
                    <CloseIcon
                      disabled={
                        DisableOffboardCluster || DisableOffboardClusterS
                      }
                      onClick={(event) => {
                        if (DisableOffboardCluster || DisableOffboardClusterS) {
                          return null;
                        } else {
                          removeSelectedNamespace(selected.namespace, cluster);
                        }
                      }}
                      width="8px"
                      height="8px"
                      fontSize="small"
                      sx={{
                        fontSize: '13px',
                        cursor: 'pointer',
                        margin: '7px 16px',
                      }}
                      htmlColor={'#2868F3'}
                    />
                  </Box>
                );
              });
            })
          ) : (
            <Fallback />
          )}
        </Box>
      </Box>

      <CustomDialog
        open={clearAllDialog}
        onClose={closeDialog}
        style={{ padding: '25px' }}
        aria-labelledby="Delete Cluster Dialog"
        aria-describedby="This Dialog is for delete cluster confirmation"
      >
        <DialogTitle
          style={{ fontWeight: 'bold' }}
          id="delete-cluster-dialog-title"
        >
          Do you want to delete selected namespaces?
        </DialogTitle>
        <DialogActions>
          <>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="outlined"
              onClick={clearAll}
            >
              Yes
            </Button>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="contained"
              onClick={closeDialog}
            >
              No
            </Button>
          </>
        </DialogActions>
      </CustomDialog>
    </Box>
  );
}
