export const ADD_SESSION = 'ADD_SESSION';
export const ACTION_LOGOUT_INITIATED = 'ACTION_LOGOUT_INITIATED';
export const UPDATE_MENU = 'UPDATE_MENU';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_SLICES = 'SET SLICES';
export const SET_SLICE_GATEWAYS = 'SET_SLICE_GATEWAYS';
export const SET_RESOURCE_QUOTA_LIST = 'SET_RESOURCE_QUOTA_LIST';
export const SET_CURRENT_RESOURCE_QUOTA = 'SET_CURRENT_RESOURCE_QUOTA';
export const CLEAR_CURRENT_RESOURCE_QUOTA = 'CLEAR_CURRENT_RESOURCE_QUOTA';
export const SET_ORIGINAL_CURRENT_RESOURCE_QUOTA =
  'SET_ORIGINAL_CURRENT_RESOURCE_QUOTA';
export const CLEAR_SLICE_GATEWAYS = 'CLEAR_SLICE_GATEWAYS';
export const SET_QOS_PROFILES = 'SET_QOS_PROFILES';
export const SET_DASHBOARD_CLUSTER_DATA = 'SET_DASHBOARD_CLUSTER_DATA';
export const START_SPINNER = 'START_SPINNER';
export const STOP_SPINNER = 'STOP_SPINNER';
export const SHOW_ERROR = 'SHOW_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const UPDATE_SNACKBAR_ERROR = 'UPDATE_SNACKBAR_ERROR';
export const UPDATE_SNACKBAR_SUCCESS = 'UPDATE_SNACKBAR_SUCCESS';
export const SET_USERS = 'SET_USERS';
export const SET_ROLES = 'SET_ROLES';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SHOW_USER_DETAILS = 'SHOW_USER_DETAILS';
export const HIDE_USER_DETAILS = 'HIDE_USER_DETAILS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_QOS_FILTER = 'UPDATE_QOS_FILTER';
export const CLEAR_SELECTED_SLICE_POLICY = 'CLEAR_SELECTED_SLICE_POLICY';
export const SET_SELECTED_SLICE_POLICY = 'SET_SELECTED_SLICE_POLICY';
export const SET_SLICE_POLICIES = 'SET_SLICE_POLICIES';
export const SET_ENFORCEMENT_POINT_SLICE = 'SET_ENFORCEMENT_POINT_SLICE';
export const SET_ENFORCEMENT_POINTS = 'SET_ENFORCEMENT_POINTS';
export const SET_TRAINING_EVENTS = 'SET_TRAINING_EVENTS';
export const ADD_TRAINING_EVENTS = 'ADD_TRAINING_EVENTS';
export const CLEAR_SELECTED_TRAINING_EVENT = 'CLEAR_SELECTED_TRAINING_EVENT';
export const SET_SELECTED_TRAINING_EVENT = 'SET_SELECTED_TRAINING_EVENT';
export const SET_LOADING = 'SET_LOADING';
export const SET_LEGEND_DATA = 'SET_LEGEND_DATA';
export const CLEAR_LEGEND_DATA = 'CLEAR_LEGEND_DATA';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_KIALI_URL = 'SET_KIALI_URL';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SET_CLUSTER_DETAIL = 'SET_CLUSTER_DETAIL';
export const SET_CLUSTER_METADATA = 'SET_CLUSTER_METADATA';
export const SET_CLUSTER_CAPACITY_DETAIL = 'SET_CLUSTER_CAPACITY_DETAIL';
export const SET_SLICE_DETAIL = 'SET_SLICE_DETAIL';
export const SET_SYSTEM_EVENT = 'SET_SYSTEM_EVENT';
export const CLEAR_SYSTEM_EVENT = 'CLEAR_SYSTEM_EVENT';
export const SET_NAMESPACE_LIST = 'SET_NAMESPACE_LIST';
export const SET_CLUSTER_NAME = 'SET_CLUSTER_NAME';
export const SET_STEPPER_PARAMETER = 'SET_STEPPER_PARAMETER';
export const SET_STEPPER_TAB = 'SET_STEPPER_TAB';
export const SET_CLUSTERS = 'SET_CLUSTERS';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_CURRENT_SLICE = 'SET_CURRENT_SLICE';
export const SET_SLICE = 'SET_SLICE';
export const SET_REGISTER_CLUSTER_LIST = 'SET_REGISTER_CLUSTER_LIST';
export const SET_SLICE_LIST = 'SET_SLICE_LIST';
export const DELETE_SLICE = 'DELETE_SLICE';
export const SET_MIGRATION_JOB = 'SET_MIGRATION_JOB';
export const SET_MIGRATION_PAIR = 'SET_MIGRATION_PAIR';
export const CLEAR_CURRENT_SLICE = 'CLEAR_CURRENT_SLICE';
export const EDIT_SLICE = 'EDIT_SLICE';
export const SET_SLICE_RESOURCE_VERSION = 'SET_SLICE_RESOURCE_VERSION';
export const SET_SLICE_CLUSTERS = 'SET_SLICE_CLUSTERS';
export const SET_CLUSTER_GATEWAYS = 'SET_CLUSTER_GATEWAYS';
export const SET_ENTITY_COUNT = 'SET_ENTITY_COUNT';
export const SET_SLICE_SERVICE_EXPORTS = 'SET_SLICE_SERVICE_EXPORTS';
export const SET_SLICE_SERVICE_IMPORTS = 'SET_SLICE_SERVICE_IMPORTS';
export const SET_ACTIVE_SLICE_OPTION = 'SET_ACTIVE_SLICE_OPTION';
export const SET_ACTIVE_CLOUD_OPTION = 'SET_ACTIVE_CLOUD_OPTION';
export const SET_ISOLATE_CLEAR = 'SET_ISOLATE_CLEAR';
export const SET_SLICE_ROLES_LIST = 'SET_SLICE_ROLES_LIST';
export const SET_CURRENT_SLICE_ROLES_LIST = 'SET_CURRENT_SLICE_ROLES_LIST';
export const SELECT_ROLE_LIST = 'SELECT_ROLE_LIST';
export const CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST =
  'CLEAR_CURRENT_SELECT_SLICE_ROLES_LIST';
export const CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST =
  'CLEAR_CURRENT_IMPORTK8SELECT_SLICE_ROLES_LIST';

export const SELECT_IMPORTK8ROLE_LIST = 'SELECT_IMPORTK8ROLE_LIST';
export const SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST =
  'SET_ORIGINAL_CURRENT_SLICE_ROLES_LIST';
export const SET_RQ_TRENDS_DATA = 'SET_RQ_TRENDS_DATA';
export const CLEAR_CURRENT_SLICE_ROLES_LIST = 'CLEAR_CURRENT_SLICE_ROLES_LIST';
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
export const CLEAR_SELECTED_ROLE = 'CLEAR_SELECTED_ROLE';
export const DELETE_RBAC_ROLES = 'DELETE_RBAC_ROLES';
export const SET_NODE_AFFINITY_SLICE_LIST = 'SET_NODE_AFFINITY_SLICE_LIST';
export const SET_NODE_AFFINITY_CURRENT_SLICE =
  'SET_NODE_AFFINITY_CURRENT_SLICE';
export const SET_NODE_AFFINITY_LIST = 'SET_NODE_AFFINITY_LIST';
export const SET_NODE_LABELS_LIST = 'SET_NODE_LABELS_LIST';
export const SET_AFFINITY_PROFILES = 'SET_AFFINITY_PROFILES';
export const SET_AFFINITY_API_ACTION = 'SET_AFFINITY_API_ACTION';
export const SET_CURRENT_SLICE_AFFINITY = 'SET_CURRENT_SLICE_AFFINITY';
export const SET_UPDATED_CLUSTER_QUOTA = 'SET_UPDATED_CLUSTER_QUOTA';
export const SET_DELETE_RQ = 'SET_DELETE_RQ';
export const OFFBOARD_NAMESPACE = 'OFFBOARD_NAMESPACE';

/**
 * Licensing
 */
export const HIDE_LICENSING_BANNER = 'HIDE_LICENSING_BANNER';
export const SET_LICENSE_DETAILS = 'SET_LICENSE_DETAILS';
export const SHOW_EXPIRATION_BANNER = 'SHOW_EXPIRATION_BANNER';
export const HIDE_EXPIRATION_BANNER = 'HIDE_EXPIRATION_BANNER';
export const SET_LICENSE_STATUS = 'SET_LICENSE_STATUS';
