import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Grid, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentSliceRoleList } from '../../../../actions/RbacActions';
import { Prompt, useHistory } from 'react-router-dom';
import './index.css';
import RoleTab from './RoleTab';
import { tr } from 'date-fns/locale';

export default function AssignToNamespace() {
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const [isNextEnabled, setIsNextDisabled] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    var newArr = [];
    if (
      currentSlice?.importk8Roles &&
      (!currentSlice?.metadata?.importedData ||
        currentSlice?.metadata?.importedData?.length == 0)
    ) {
      currentSlice.importk8Roles?.map((row) => {
        row?.applyTo?.map((data) => {
          data?.subjects?.map((x) => {
            if (newArr.length == 0) {
              let obj = {
                roleName: [row?.roleRef?.name],
                apiKind: row?.roleRef?.kind,
                apiversion: row?.roleRef?.apiVersion,
                name: x?.name,
                kind: x?.kind,
                namespaces: data?.namespace
                  ? [{ namespace: data?.namespace, cluster: data?.cluster }]
                  : [],
              };
              newArr.push(obj);
            } else {
              let index = newArr.findIndex((item) => {
                return item?.name === x?.name && item?.kind === x?.kind;
              });
              if (index >= 0) {
                if (
                  data?.namespace &&
                  !newArr[index]?.namespaces.some(function (element) {
                    return (
                      JSON.stringify(element) ===
                      JSON.stringify({
                        namespace: data?.namespace,
                        cluster: data?.cluster,
                      })
                    );
                  })
                  //!newArr[index]?.namespaces.includes({namespace:data?.namespace,cluster:data?.cluster})
                ) {
                  newArr[index]?.namespaces.push({
                    namespace: data?.namespace,
                    cluster: data?.cluster,
                  });
                }
                if (!newArr[index]?.roleName?.includes(row?.roleRef?.name)) {
                  newArr[index]?.roleName.push(row?.roleRef?.name);
                }
              } else {
                let obj = {
                  roleName: [row?.roleRef?.name],
                  apiKind: row?.roleRef?.kind,
                  apiversion: row?.roleRef?.apiVersion,
                  name: x?.name,
                  kind: x?.kind,
                  namespaces: data?.namespace
                    ? [{ namespace: data?.namespace, cluster: data?.cluster }]
                    : [],
                };
                newArr.push(obj);
              }
            }
          });
        });
      });
      if (currentSlice?.metadata?.status?.roleRefConditions) {
        currentSlice?.metadata?.status?.roleRefConditions?.map((error) => {
          let index = newArr.findIndex((item) => {
            return (
              item?.roleName?.includes(error?.roleRef?.name) &&
              item?.apiKind === error?.roleRef?.kind &&
              item?.apiversion === error?.roleRef?.apiVersion
            );
          });
          if (index >= 0) {
            if (newArr[index]?.errObj) {
              newArr[index]?.errObj?.message.push(error?.message);
            } else {
              let errObj = {
                error: true,
                message: [error?.message],
              };
              let updatedObj = { ...newArr[index], errObj };
              newArr[index] = updatedObj;
            }
          }
        });
      }
      let updatedCurrentSlice = { ...currentSlice };
      updatedCurrentSlice.metadata.importedData = newArr;
      dispatch(setCurrentSliceRoleList(updatedCurrentSlice));
    }
  }, []);

  useEffect(() => {
    let flag = true;
    if (currentSlice?.metadata?.data) {
      currentSlice?.metadata?.data.map((item) => {
        if (item.namespaces.length === 0) flag = false;
      });
    }
    if (currentSlice?.metadata?.importedData) {
      currentSlice?.metadata?.importedData.map((item) => {
        if (item.namespaces.length === 0) flag = false;
      });
    }

    if (flag === false) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [currentSlice]);

  const history = useHistory();

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (location.pathname.includes('manage-rbacs')) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      <Grid item className="rbac-step2-container">
        <Paper elevation={3}>
          <div className="step2-content-header">
            <RoleTab />
          </div>
        </Paper>
      </Grid>

      <div className="bottom-btn-container">
        <div>
          <Button
            onClick={() => history.push('/manage-rbacs/add/1')}
            style={{ width: '100px', marginRight: '10px', top: '8%' }}
            className="btn"
            variant="contained"
          >
            <ArrowBackIosIcon style={{ fontSize: '12px' }} /> Previous{' '}
          </Button>
          <Button
            disabled={isNextEnabled}
            onClick={() => history.push('/manage-rbacs/add/3')}
            style={{ width: '100px', marginRight: '10px', top: '8%' }}
            className="btn"
            variant="contained"
          >
            Next <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
          </Button>
        </div>
      </div>
    </>
  );
}
