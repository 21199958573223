import React, { Component } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Container } from "./styles";
export default class Group extends Component {
  render() {
    const { groupBy, groupOrder, changeGroupBy, changeGroupOrder } = this.props;
    return (
      <Container>
        <h5 style={{ marginTop: "16px", marginBottom: "8px" }}>Group Order</h5>
        <RadioGroup
          name="groupOrder"
          value={groupOrder}
          onChange={(e) => changeGroupOrder(e.target.value)}
        >
          <FormControlLabel
            value="ascending"
            control={<Radio style={{ padding: 5 }} />}
            label="Ascending"
          />
          <FormControlLabel
            value="desceneding"
            control={<Radio style={{ padding: 5 }} />}
            label="Desceneding"
          />
        </RadioGroup>
        <h5 style={{ marginTop: "16px", marginBottom: "8px" }}>Group By</h5>
        <RadioGroup
          name="groupBy"
          value={groupBy}
          onChange={(e) => changeGroupBy(e.target.value)}
        >
          <FormControlLabel
            value="aplhabetically"
            control={<Radio style={{ padding: 5 }}/>}
            label="Aplhabetically"
          />
          <FormControlLabel
            value="category"
            control={<Radio style={{ padding: 5 }}/>}
            label="Category"
          />
          <FormControlLabel
            value="category2"
            control={<Radio style={{ padding: 5 }}/>}
            label="Category"
          />
          <FormControlLabel
            value="category3"
            control={<Radio style={{ padding: 5 }}/>}
            label="Category"
          />
          <FormControlLabel
            value="category4"
            control={<Radio style={{ padding: 5 }}/>}
            label="Category"
          />
        </RadioGroup>
      </Container>
    );
  }
}
