export const canRemove = (slice, internalKey) => {
  const { clusterQuota } = slice.spec.resourceQuota.spec;

  const any_dependent = clusterQuota?.some(({ namespaceQuota }) => {
    return namespaceQuota.some(({ resources }) => {
      const per_container =
        resources['defaultRequestPerContainer']?.[internalKey];
      const internal = resources['request'][internalKey];

      if (internal && !per_container) {
        return true;
      }
      return false;
    });
  });
  return !any_dependent;
};

export const defaultRQValues = {
  ephemeralStorage: '',
  cpu: '',
  memory: '',
};

export const emptyRqValues = {
  defaultLimitPerContainer: defaultRQValues,
  defaultRequestPerContainer: defaultRQValues,
  limit: {
    ...defaultRQValues,
    podCount: '',
  },
  request: defaultRQValues,
};
