import { START_SPINNER, STOP_SPINNER } from "../actions/types";

const initialState = {
  spinner: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case START_SPINNER:
      return {
        ...state,
        spinner: state.spinner + 1,
      };
    case STOP_SPINNER:
      return {
        ...state,
        spinner: state.spinner - 1 >= 0 ? state.spinner - 1 : 0,
      };
    default:
      return state;
  }
}
