import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.css';
const ViewNamespacesModel = ({ open, setOpenViewModal }) => {
  const [search, setSearch] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [list, setList] = React.useState([]);
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [rowCount, setRowCount] = React.useState(0);
  const selectedRole = useSelector((state) => state.RbacReducer.selectedRole);

  useEffect(() => {
    let itemArr = [];
    const { clusters: slice_clusters, namespaceIsolationProfile } =
      currentSlice.spec;
    if (currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces) {
      currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces.map(
        (data) => {
          data.clusters.map((cluster) => {
            let objclusters = {};

            slice_clusters?.map((cluster) => {
              const namespaces =
                namespaceIsolationProfile?.applicationNamespaces
                  .filter((namespace) => {
                    return (
                      namespace.clusters.includes(cluster) ||
                      namespace.clusters.includes('*')
                    );
                  })
                  .map((namespace) => namespace.namespace);
              objclusters[cluster] = namespaces;
              return cluster;
            });
            // console.log(objclusters);

            const result = Object.entries(objclusters).reduce(
              (acc, [worker, items]) => {
                items.forEach((item) => {
                  if (item === data.namespace) {
                    acc[item] = [...(acc[item] || []), worker];
                  }
                });
                return acc;
              },
              {}
            );

            const selected = selectedRole?.namespaces?.some((value) => {
              return (
                value?.namespace === data?.namespace &&
                value?.cluster === cluster
              );
            });
            if (selected) {
              let obj = {
                namespace: data.namespace,
                cluster: cluster,
              };
              itemArr.push(obj);
            }
          });
        }
      );
      itemArr?.sort((a, b) => (a.cluster == '*' ? -1 : 1));

      if (search && searchText !== '') {
        itemArr = selectedRole.namespaces.filter((item) => {
          return item?.namespace
            ?.toUpperCase()
            .includes(searchText.toUpperCase());
        });
      }
      setRowCount(itemArr.length);
      itemArr = itemArr.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setList(itemArr);
    }
  }, [selectedRole, search, searchText, rowsPerPage, page]);

  const [filteredNamespaces, setFilteredNamespaces] = useState(
    selectedRole.namespaces
  );
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchTerm !== '') {
      const filtered = selectedRole.namespaces.filter((item) => {
        return item.namespace.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredNamespaces(filtered);
    } else {
      setFilteredNamespaces(selectedRole.namespaces);
    }
  }, [searchTerm]);

  // filteredNamespaces = selectedRole.namespaces.filter((item) => {
  //   return item.namespace.toLowerCase().includes(searchTerm.toLowerCase());
  // });

  const searchNamespace = (event) => {
    setSearchText(event.target.value);
    setSearch(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      sx={{ '& .MuiDialog-paper': { minWidth: '60%', minHeight: '90%' } }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <div>
          <Typography className="dialog-title">
            Total NameSpaces : {selectedRole.namespaces?.length}
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenViewModal(false);
                setSearchText('');
              }}
              sx={{
                float: 'right',
                padding: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Typography className="dialog-subtitle">
            Role:{' '}
            <b className="model-prop">{selectedRole?.roleName?.join(', ')} </b>|{' '}
            {selectedRole?.kind == 'User' ? 'User:' : 'User Group:'}{' '}
            <b className="model-prop">{selectedRole?.name} </b>
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0, maxHeight: '80%' }}>
        <div
          style={{
            display: 'flex',
            height: '40px',
            overflow: 'hidden',
            border: '1px solid #B6B8D6',
            margin: 'auto',
            borderRadius: '4px',
            marginTop: '16px',
            width: '92%',
          }}
        >
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              borderRadius: '0 5px 5px 0',
            }}
          >
            <IconButton
              className="search-icon"
              onClick={() => setSearch(true)}
              sx={{ p: '10px' }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Namespaces"
              onChange={handleSearch}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  setSearch(true);
                }
              }}
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Paper>
        </div>
        <div>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div
              style={{
                height: '56vh',
                overflow: 'auto',
                borderBottom: '1px solid lightgrey',
              }}
            >
              <TableContainer className="table-container">
                <Table
                  sx={{ minWidth: 300, borderRight: '1px solid lightgrey' }}
                  aria-labelledby="Namespace Table"
                  size="small"
                >
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell
                        style={{
                          padding: '15px 20px',
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '15px',
                          color: '#12153E',
                          width: '10%',
                        }}
                      >
                        NO
                      </TableCell>
                      <TableCell
                        style={{
                          padding: '15px 20px',
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '15px',
                          color: '#12153E',
                          width: '60%',
                        }}
                      >
                        NAMESPACES
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '12px',
                          lineHeight: '15px',
                          color: '#12153E',
                        }}
                        align="right"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {selectedRole?.namespaces?.length > 0 ? (
                        <>
                          {searchTerm !== ''
                            ? filteredNamespaces.map((item, index) => (
                                <TableRow
                                  key={`${item.cluster}-${index}`}
                                  hover
                                >
                                  <TableCell
                                    style={{
                                      padding: '0px 20px',
                                      fontFamily: 'Bai Jamjuree',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      lineHeight: '24px',
                                      color: '#12153E',
                                    }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: '12px 0px 12px 20px' }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        color: '#12153E',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {item.namespace}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: '#5A607C',
                                        lineHeight: '17.5px',
                                      }}
                                    >
                                      {item.cluster}
                                    </Typography>
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              ))
                            : selectedRole.namespaces.map((item, index) => (
                                <TableRow
                                  key={`${item.cluster}-${index}`}
                                  hover
                                >
                                  <TableCell
                                    style={{
                                      padding: '0px 20px',
                                      fontFamily: 'Bai Jamjuree',
                                      fontStyle: 'normal',
                                      fontWeight: 600,
                                      fontSize: '16px',
                                      lineHeight: '24px',
                                      color: '#12153E',
                                    }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: '12px 0px 12px 20px' }}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        color: '#12153E',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {item.namespace}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: '#5A607C',
                                        lineHeight: '17.5px',
                                      }}
                                    >
                                      {item.cluster}
                                    </Typography>
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              ))}
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            No Namespace to display
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rowCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewNamespacesModel;
