import { useSelector } from 'react-redux';
import { license_status } from '../../../constants/license';
import { selectLicenseStatus } from '../../../selectors/licensing.selector';
import LicenseExpiredModal from './ExpiredModal';
import InvalidLicenseModal from './InvalidModal';

// everything is inline beacuse we need to do this in v2 as well
const LicensingModals = () => {
  const status = useSelector(selectLicenseStatus);

  if (status === license_status.expired) {
    return <LicenseExpiredModal />;
  } else if (status === license_status.invalid) {
    return <InvalidLicenseModal />;
  }

  return null;
};

export default LicensingModals;
