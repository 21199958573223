import * as React from 'react';
import oracle from '../../assets/images/oracle.png';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import oracleLogo from '../../assets/images/oracleLogo.svg';

function Oracle(props) {
  return <CommonCloud {...props} img={oracle} />;
}

export const OracleTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={oracleLogo} img={oracle} />;
};

export default Oracle;
