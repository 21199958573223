import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearCurrentResourceQuota } from "../../../actions/ResourceQuotaActions";
import Row from "../Row";
import ManageResourceTable from "./InitialStep/ManageResourceTable";

const FinalButton = styled(Button)({
  color: "#fff",
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  height: "50px",
  width: "100px",
  padding: 0,
  lineHeight: 1.5,
  backgroundColor: "#2868f3",
  borderColor: "#0063cc",
  fontFamily: ["Bai Jamjuree"].join(","),
  "&:hover": {
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    boxShadow: "none",
  },
});

const Finalize = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentResourceQuota = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );

  const onDone = () => {
    dispatch(clearCurrentResourceQuota());
    history.push("/manage-resource");
  };

  React.useEffect(() => {
    if (!currentResourceQuota?.metadata?.name) {
      history.push("/manage-resource");
    }
  }, []);

  return (
    <>
      {
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: "20px 0 0 0",
              position: "relative",
              border: "1px solid #B6B8D6",
            }}
          >
            <Grid
              sx={{ padding: "0 10px" }}
              container
              spacing={2}
              direction="row"
            >
              <Grid item xs={10}>
                <Typography
                  className="step-title-header"
                  gutterBottom
                  component="div"
                >
                  Manage Resources
                </Typography>
              </Grid>
            </Grid>
            <TableContainer
              className="table-container"
              data-testid="finializeRQ"
            >
              <Table aria-label="collapsible table">
                <TableHead className="table-head">
                  <TableRow data-testid="finializeRQTable">
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                    >
                      SLICE NAME
                    </TableCell>
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                    >
                      NO.OF PODS
                    </TableCell>
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                    >
                      CPU (m)
                    </TableCell>
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                    >
                      MEMORY (Mi)
                    </TableCell>
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                    >
                      EPHEMERAL STORAGE
                    </TableCell>
                    <TableCell
                      className="txt-bold ft-12 regular-table"
                      sx={{ padding: "15px 25px" }}
                      data-testid="yamalDownload"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Row
                    isFinalize
                    stepNo={4}
                    key={currentResourceQuota?.metadata?.name}
                    slice={currentResourceQuota}
                    radioDisable={true}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <ManageResourceTable
            isFinalize
            currentResourceQuota={currentResourceQuota}
          />
          <Box
            sx={{
              marginLeft: "-19px",
              marginBottom: "-12px",
              position: "fixed",
              bottom: "12px",
              left: "240px",
              right: "0",
              zIndex: 10,
              padding: "12px 40px",
              background: "white",
              display: "flex",
              justifyContent: "flex-end",
              boxSizing: "border-box",
              gap: 2,
            }}
          >
            <Button
              onClick={() => history.push("/manage-resources/add/3")}
              sx={{
                borderColor: "black",
                color: "black",
                width: "100px",
                textTransform: "none",
                fontWeight: 600,
              }}
              variant="outlined"
              data-testid="PreviousButtonS4"
              textTransform
            >
              <ArrowBackIosIcon style={{ fontSize: "12px" }} /> Previous
            </Button>
            <Button
              onClick={onDone}
              style={{ width: "100px", marginRight: "10px" }}
              className="btn"
              variant="contained"
              data-testid="DoneS4"
            >
              Done
            </Button>
          </Box>
        </Grid>
      }
    </>
  );
};

export default Finalize;
