import { ArrowBack } from '@mui/icons-material';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getNodeLabels } from '../../actions/NodeAffinity.actions';
import { selectCurrentSlice } from '../../selectors/NodeAffinity.selector';
import { ApplyYaml } from './ApplyYaml';
import { AssignToNamespace } from './AssignToNamespace/AssignToNamespace';
import AssignToSlice from './AssignToSlice';
import { Finalize } from './Finalize';
import { StepsIndicator } from './StepsIndicator';

export const AssignNodes = () => {
  const { stepNo } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSlice = useSelector((state) => selectCurrentSlice(state));

  useEffect(() => {
    if (!currentSlice?.metadata?.name) {
      history.push('/assign-nodes');
    }
    // dispatch(getNodeAssignments(currentSlice.metadata.name));
    dispatch(getNodeLabels(currentSlice.metadata.name));
  }, [dispatch, history, currentSlice]);

  const renderContent = () => {
    switch (stepNo) {
      case '4':
        return <Finalize />;
      case '3':
        return <ApplyYaml />;
      case '2':
        return <AssignToNamespace />;
      default:
        return <AssignToSlice />;
    }
  };

  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  const sliceDataNAme = localStorage.getItem('currentSlice');

  return (
    <Stack
      sx={{
        height: '100vh',
        position: 'relative',
      }}
    >
      <Grid item className="header">
        <Paper className="paper-header">
          <Typography
            sx={{
              fontFamily: 'Bai Jamjuree',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px', // Adjust the font size as per your requirement
              lineHeight: '20px',
              color: '#6E708E',
              paddingTop: '4px',
            }}
            variant="subtitle1"
          >
            {currentProjectData?.projectNamespace}
            <span> / Node Assignment / </span>{' '}
            <span style={{ fontWeight: 600 }}>{sliceDataNAme}</span>
          </Typography>
        </Paper>
      </Grid>

      <div
        sx={{
          paddingTop: 3,
          paddingX: 5,
          display: 'flex',
          paddingBottom: 2,
          marginBottom: '2px',
          // flexGrow: 1,
        }}
        styles={{ width: '100%' }}
      >
        <StepsIndicator />
      </div>
      <div
        className="nextbox"
        style={{ clear: 'both', marginTop: '2rem' }}
      ></div>
      {renderContent()}
    </Stack>
  );
};
