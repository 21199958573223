import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  Link,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Radio,
  Dialog,
  DialogContent,
  DialogTitle,
  InputBase,
  Typography,
  Paper,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./index.css";
import { setCurrentSlice } from "../../../actions/namespaceActions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export default function StepsTableRow(props) {
  const { slice, radioDisable = false } = props;
  const [open, setOpen] = useState(false);
  const [namespaceModal, setNamespaceModal] = useState(false);
  const dispatch = useDispatch();
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const [clusterDetails, setClusterDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);

  const allowedNamespaceList =
    slice?.spec?.namespaceIsolationProfile?.allowedNamespaces;

  const totalAllowedNamespaceCount =
    allowedNamespaceList &&
    allowedNamespaceList.reduce((total, item) => {
      return total + item.clusters.length;
    }, 0);

  const isChecked = useMemo(() => {
    return currentSlice?.metadata?.name === slice?.metadata?.name;
  }, [slice, currentSlice]);

  useEffect(() => {
    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((clusterName, key) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == clusterName
        );
        list[clusterName] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider,
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion,
        };
      });

      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component="th"
          scope="row"
          sx={{
            //styleName: Body/16 - Body - Semi-bold;
            fontFamily: "Bai Jamjuree",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#12153E",
            width: "30%",
            padding: "23px 24px",
          }}
        >
          {/* {!radioDisable &&
                        <Radio
                            checked={isChecked}
                            onChange={() => {
                                dispatch(setCurrentSlice(slice))
                            }}
                            name="slice-row"
                            inputProps={{ 'aria-label': 'selected slice row' }}
                        />
                    } */}
          {slice.metadata?.name}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          sx={{
            width: "20%",
            padding: "23px 24px",
            lineHeight: "20px"
          }}
        >
          {allowedNamespaceList?.length > 0 ? (
            <Link
              underline="hover"
              onClick={() => setNamespaceModal(true)}
              href="javascript:void(0)"
              style={{
                fontFamily: "Bai Jamjuree",
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#2868F3",
              }}
            >
              View all
            </Link>
          ) : (
            "No external Namespaces"
          )}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          sx={{
            fontFamily: "Bai Jamjuree",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#12153E",
            borderRight: 0,
            padding: "23px 24px",
          }}
        >
          {slice.metadata?.annotations?.desc}
        </TableCell>

        <TableCell component="th" scope="row" sx={{ padding: "24px 24px" }}>
          {slice && slice?.spec?.namespaceIsolationProfile?.isolationEnabled ? (
            <Typography
              sx={{
                //styleName: Body/16 - Body - Semi-bold;
                fontFamily: "Bai Jamjuree",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#12153E",
              }}
            >
              Isolated with Network policy
            </Typography>
          ) : (
            <Typography
              sx={{
                fontStyle: "italic",
                fontFamily: "Bai Jamjuree",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#81839A",
              }}
            >
              Isolation not applied
            </Typography>
          )}
        </TableCell>
      </TableRow>

      <Dialog
        open={namespaceModal}
        onClose={() => setNamespaceModal(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            minHeight: "80%",
            maxHeight: "80%",
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{ padding: "24px 16px 16px 16px" }}
          id="scroll-dialog-title"
        >
          <Typography className="dialogue-title">
            {`Total Allowed Namespaces: ${totalAllowedNamespaceCount}`}
            <IconButton
              aria-label="close"
              onClick={() => setNamespaceModal(false)}
              sx={{
                float: "right",
                padding: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>
          <Typography className="dialogue-subtitle">
            {`Slice name: ${slice.metadata?.name}`}
          </Typography>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <IconButton
              className="search-icon"
              onClick={() => setSearch(true)}
              type="submit"
              sx={{ p: "4px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearch(false);
              }}
              inputProps={{ "aria-label": "search google maps" }}
            />
          </Paper>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <Table size="small" aria-label="purchases">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{ paddingLeft: "24px", paddingTop: "15px", paddingBottom: "15px", paddingRight: "0px", lineHeight: '15px', width: "15%" }}
                  className="txt-bold"
                >
                  N.O
                </TableCell>
                <TableCell sx={{ paddingLeft: "24px", paddingTop: "15px", paddingBottom: "15px", paddingRight: "0px", lineHeight: '15px', width: "40%" }} className="txt-bold">
                  NAMESPACES
                </TableCell>
                <TableCell sx={{ paddingLeft: "24px", paddingTop: "15px", paddingBottom: "15px", paddingRight: "0px", lineHeight: '15px', }} className="txt-bold">CLUSTERS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allowedNamespaceList &&
                allowedNamespaceList.map((item, namespaceIndex) => {
                  if (
                    (search &&
                      searchText !== "" &&
                      item.namespace === searchText) ||
                    !search
                  ) {
                    return item.clusters.map((cluster, index) => {
                      return (
                        <TableRow key={`${cluster}-${namespaceIndex}-${index}`}>
                          <TableCell
                            sx={{ padding: "16px 24px" }}
                            className="txt-bold"
                          >
                            {namespaceIndex + index + 1}.
                          </TableCell>
                          <TableCell className="txt-bold">
                            {item["namespace"]}
                          </TableCell>
                          <TableCell className="txt-bold">{cluster}</TableCell>
                        </TableRow>
                      );
                    });
                  }
                })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
