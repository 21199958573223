import { SdkBackend } from "../backend/MeshBackend";
import { getMeshBackend } from "../backend/MeshBackendProxy";
import {
  CLEAR_SLICE_GATEWAYS,
  SET_CLUSTER_GATEWAYS,
  SET_SLICE_CLUSTERS,
  SET_SLICE_GATEWAYS,
  SET_SLICE_SERVICE_EXPORTS,
  SET_SLICE_SERVICE_IMPORTS,
} from "./types";
const meshBackend = getMeshBackend();
const sdkBackend = new SdkBackend();

export const getAllSlices = () => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getAllSlices(getState(), dispatch);
    // if (response) dispatch({ type: SET_SLICES, payload: response });
  };
};
export const getCompareClusterStats = (slice, cluster1, cluster2) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getCompareClusterStats(
      getState(),
      dispatch,
      cluster1,
      cluster2,
      slice
    );
    return response;
  };
};

export const getAllClustersForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllClustersForSlice(
      getState(),
      dispatch,
      sliceName
    );
    if (response) {
      dispatch({ type: SET_SLICE_CLUSTERS, payload: response });
      return response;
    }
  };
};
export const getAllGatewaysForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllGatewaysForSlice(
      getState(),
      dispatch,
      sliceName
    );
    if (response) dispatch({ type: SET_SLICE_GATEWAYS, payload: response });
  };
};

export const getAllServiceExportsForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllServiceExportsForSlice(
      getState(),
      dispatch,
      sliceName
    );
    if (response)
      dispatch({ type: SET_SLICE_SERVICE_EXPORTS, payload: response });
  };
};
export const getAllServiceImportsForSlice = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllServiceImportsForSlice(
      getState(),
      dispatch,
      sliceName
    );
    if (response)
      dispatch({ type: SET_SLICE_SERVICE_IMPORTS, payload: response });
  };
};
export const getAllGatewaysForCluster = (clusterId, sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getAllGatewaysForCluster(
      getState(),
      dispatch,
      { clusterId, sliceName }
    );
    if (response)
      dispatch({
        type: SET_CLUSTER_GATEWAYS,
        payload: { [clusterId]: response },
      });
  };
};
export const clearGatewaysForSlice = () => ({
  type: CLEAR_SLICE_GATEWAYS,
});

export const createSlice = (sliceDetails) => {
  return async (dispatch, getState) => {
    //   await meshBackend.postSlice(getState(), dispatch, sliceDetails);
  };
};
export const deleteSlice = (sliceName) => {
  return async (dispatch, getState) => {
    //   await meshBackend.deleteSlice(getState(), dispatch, sliceName);
    dispatch(getAllSlices());
  };
};

export const addClusters = (sliceName) => {
  return async (dispatch, getState) => {
    //   await meshBackend.attachCluster(getState(), dispatch, sliceName);
  };
};

export const getSliceGateway = (sliceId, clusterId, gatewayId) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getSliceGateway(
    //   getState(),
    //   dispatch,
    //   sliceId,
    //   clusterId,
    //   gatewayId
    // );
    // return response;
  };
};

export const getSliceServices = (sliceName) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.getSliceServices(
    //   getState(),
    //   dispatch,
    //   sliceName
    // );
    // return response;
  };
};

export const updateSlice = (sliceName, sliceData) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.updateSlice(
    //   getState(),
    //   dispatch,
    //   sliceName,
    //   sliceData
    // );
    dispatch(getAllSlices());
    // return response;
  };
};
