import React, { useEffect, useState } from 'react';
import './index.css';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { setCurrentSlice } from '../../../../actions/namespaceActions';

export const IOSSwitch = styled(({ checked, handleChange, ...props }) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    inputProps={{ 'aria-label': 'controlled' }}
    checked={checked}
    onChange={(e) => handleChange(e.target.checked)}
  />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#14974C',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function SliceTable(props) {
  localStorage.setItem('currentSlice', props.currentSlice?.metadata?.name);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography
        style={{
          padding: '16px 24px 0px 16px',
          fontFamily: 'Bai Jamjuree',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '30px',
          color: '#12153E',
          paddingBottom: 0,
        }}
        className="step-title-header"
        gutterBottom
        component="div"
      >
        Isolate Namespaces
      </Typography>
      <TableContainer sx={{ marginTop: '24px' }} className="table-container">
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow style={{ height: '45px' }}>
              <TableCell
                style={{
                  padding: '15px 0px 15px 24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                SELECTED SLICE
              </TableCell>
              <TableCell
                style={{
                  padding: '15px 0px 15px 24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                ISOLATE NAMESPACES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  // boxShadow: 'inset 1px -1px 0px #D9DFEE',
                  padding: '24px 0px 24px 24px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                {props.currentSlice?.metadata?.name}
              </TableCell>

              <TableCell
                style={{
                  padding: '22px 0px 22px 24px',
                  // boxShadow: 'inset 1px -1px 0px #D9DFEE',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#12153E',
                }}
              >
                <FormGroup
                  sx={{ '& .MuiFormControlLabel-root': { marginX: '0px' } }}
                >
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontWeight: 600,
                        marginLeft: '19px',
                      },
                    }}
                    control={
                      <IOSSwitch
                        sx={{ m: 1, padding: '0px', margin: '0px' }}
                        checked={props.switchFlag}
                        handleChange={(flag) => props.setSwitchFlag(flag)}
                      />
                    }
                    label={
                      props.switchFlag
                        ? 'Isolated with Network Policy'
                        : 'Isolate with Network Policy'
                    }
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
