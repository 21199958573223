import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  IconButton,
  Link,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSlice } from '../../actions/NodeAffinity.actions';
import ViewNamespacesModel from './ViewNamespacesModel';
import { getRegisteredClusters } from '../../actions/RegisterClusterActions';
const SliceRow = (props) => {
  const { slice, selected, selectSlice } = props;
  const [open, setOpen] = useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const dispatch = useDispatch();

  const [clusterDetails, setClusterDetails] = useState({});
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);

  useEffect(() => {
    dispatch(getRegisteredClusters());
  }, []);
  const node_affinity_list = useSelector(
    (state) => state.NodeAffinity.node_affinity_list
  );

  const hasNodeAffinity = node_affinity_list.some(
    (affinity) => affinity.metadata.name === slice.metadata.name
  );

  const handleChange = () => {
    selectSlice(slice);
    dispatch(setCurrentSlice(slice));
  };
  useEffect(() => {
    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((item) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == item
        );
        list[item] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion?.toLowerCase(),
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });

      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  // localStorage.setItem('currentSlice', slice.metadata.name);
  return (
    <>
      {openViewModal && (
        <ViewNamespacesModel
          open={openViewModal}
          setOpenViewModal={setOpenViewModal}
          sliceName={slice.metadata.name}
        />
      )}
      <TableRow data-testid="slice-row">
        <TableCell
          sx={{
            width: 40,
            paddingY: '14px',
          }}
        >
          <IconButton
            sx={{
              padding: '0px',
            }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            width: '25%',
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '22px 0px 22px 24px',
          }}
        >
          <Radio
            checked={selected}
            onChange={handleChange}
            name="slice-row"
            inputProps={{ 'aria-label': 'selected slice-row' }}
            sx={{ padding: '0px 18px 0px 0px' }}
          />
          {slice.metadata?.name}
          {props.enabled && (
            <span
              style={{ height: '0px', width: '8px', marginLeft: '8px' }}
              className="success-dot"
            />
          )}
        </TableCell>
        <TableCell sx={{ padding: '24px 0px 24px 24px' }}>
          <Typography
            sx={{
              lineHeight: '20px',
              fontWeight: 600,
              color: '#12153E',
            }}
          >
            {slice.spec?.sliceType}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: '24px 0px 24px 24px', lineHeight: '20px' }}>
          <Typography
            sx={{
              lineHeight: '20px',
              fontWeight: 500,
              color: '#5A607C',
            }}
          >
            {hasNodeAffinity ? (
              <Link
                underline="hover"
                style={{
                  color: '#2868F3',
                  cursor: 'pointer',
                  lineHeight: '20px',
                }}
                onClick={() => {
                  setOpenViewModal(true);
                }}
              >
                View all
              </Link>
            ) : (
              'No Labels Assigned'
            )}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: '24px 0px 24px 24px', lineHeight: '20px' }}>
          {slice?.metadata?.annotations?.desc != '' ? (
            <Typography
              sx={{
                lineHeight: '20px',
                fontWeight: 600,
                color: '#12153E',
              }}
            >
              {slice?.metadata?.annotations?.desc}
            </Typography>
          ) : (
            <Typography
              sx={{
                lineHeight: '20px',
                fontWeight: 500,
                color: '#5A607C',
              }}
            ></Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: open ? 10 : 0,
            paddingTop: open ? 10 : 0,
            transition: '0.3s ease-in-out',
          }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex', justifyContent: 'center' }}>
              <Table
                size="small"
                style={{ width: '100%' }}
                aria-label="purchases"
              >
                <TableHead
                  className="table-head"
                  sx={{ borderColor: 'transparent' }}
                >
                  <TableRow
                    style={{ border: '1px solid #D9DFEE', fontWeight: '600' }}
                  >
                    <TableCell
                      sx={{
                        fontSize: '12px',
                        fontWeight: '600',
                        padding: '15px 0px 15px 24px',
                        lineHeight: '15px',
                      }}
                    >
                      CLUSTERS
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '12px',
                        fontWeight: '600',
                        padding: '15px 0px 15px 24px',
                        lineHeight: '15px',
                      }}
                    >
                      DEPLOYMENT LOCATION
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '12px',
                        fontWeight: '600',
                        padding: '15px 0px 15px 24px',
                        lineHeight: '15px',
                      }}
                    >
                      CLOUD TYPE
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(clusterDetails)?.map((key, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            borderLeft: '1px solid #D9DFEE',
                            fontSize: '16px',
                            fontWeight: '600',
                            padding: '13px 0px 13px 24px',
                            lineHeight: '20px',
                          }}
                        >
                          {key}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            padding: '13px 0px 13px 24px',
                            lineHeight: '20px',
                          }}
                        >
                          {clusterDetails[key]['location']}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: '1px solid #D9DFEE',
                            fontSize: '16px',
                            fontWeight: '600',
                            padding: '13px 0px 13px 24px',
                            lineHeight: '20px',
                          }}
                        >
                          {clusterDetails[key]['cloudType']}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default SliceRow;
