const new_line = '%0D%0A';

export const createLicenseSupportLink = () => {
  return (
    `mailto:support@avesha.io?subject=I'm facing issues with my License&body=Hi Kubeslice Support Team,` +
    new_line +
    new_line +
    new_line +
    `I am facing issues with my license and I need your help resolving this issue.` +
    new_line +
    new_line +
    `Here are some additional information about the error` +
    new_line +
    new_line +
    `       -----------------------------------` +
    new_line +
    new_line +
    `          (You can include any additional information or feedback here, below this line ) ` +
    new_line +
    new_line +
    new_line +
    'Thank you.'
  );
};

export const plularize = (count, singular, suffix = 's') => {
  return count === 1 ? singular : singular + suffix;
};

export const isEnterpriseLicense = (license) =>
  license['license-kind'] === 'kubeslice-enterprise-vcpu-license';
// Object.prototype.hasOwnProperty.call(license, 'license-kind') &&
// license['license-kind'] === 'kubeslice-enterprise-vcpu-license';

export const createTrialUpgradeLink = (license) => {
  const href = `mailto:support@avesha.io?subject=Request to Upgrade from Trial to Enterprise License&body=Dear Avesha Support Team, 
${new_line} ${new_line} 
I have been using the trial version of Avesha’s KubeSlice and I am now interested in upgrading to an Enterprise license. Could you please guide me through the process of upgrading and provide any additional information or prerequisites that I need to be aware of? 
${new_line}
${new_line}
Here is my License information:
${new_line}
License Id: ${license['license-id']}
${new_line}
Issue Date: ${license['license-created']}
${new_line}
Expiry Date: ${license['license-expiration']}
${new_line}
${new_line}

Thank you for your assistance.
${new_line}
Warm regards,
${new_line}
[Your Name]
${new_line}
[Your Company Name]
${new_line}
[Your Contact Information]
                `;
  return href;
};

const createEntSidebarUpgradeLink = (license) => {
  const href = `mailto:support@avesha.io?subject=Request to Upgrade to Next vCPU Bundle&body=Dear Avesha Support Team,
${new_line}
I am subscribed to Avesha’s Enterprise license for KubeSlice and am keen on upgrading to the next vCPU bundle.
${new_line}
${new_line}
Could you please guide me through the process of this upgrade and let me know of any additional requirements or prerequisites that I need to be aware of?
${new_line}
${new_line}
Here is my License information:
${new_line}
License Id: ${license['license-id']}
${new_line}
Issue Date: ${license['license-created']}
${new_line}
Expiry Date: ${license['license-expiration']}
${new_line}
${new_line}

Thank you for your assistance.
${new_line}
${license['customer-name']}
${new_line}
[Your Company Name]
${new_line}
[Your Contact Information]
`;
  return href;
};

export const createUpgradeLicenseLink = (
  licenseDetails,
  position = 'banner'
) => {
  if (!licenseDetails) {
    return createLicenseSupportLink();
  }

  if (!isEnterpriseLicense(licenseDetails)) {
    return createTrialUpgradeLink(licenseDetails);
  }

  if (position === 'extend_modal') {
    return createEntSidebarUpgradeLink(licenseDetails);
  }

  const href = `mailto:support@avesha.io?subject=Request to Extend Enterprise License&body=Dear Avesha Support Team,
${new_line}
I am subscribed to Avesha’s Enterprise license for KubeSlice, and as my current license is nearing expiration, I would like the license period to be extended by — months.
${new_line}
${new_line}

Could you please guide me through the process of extending the Enterprise license, and let me know of any additional requirements or prerequisites that I need to be aware of?
${new_line}
${new_line}

Here is my License information:
${new_line}
License Id: ${licenseDetails['license-id']}
${new_line}
Issue Date: ${licenseDetails['license-created']}
${new_line}
Expiry Date: ${licenseDetails['license-expiration']}
${new_line}
${new_line}

Thank you for your assistance.
${new_line}
${licenseDetails['customer-name']}
${new_line}
[Your Company Name]
${new_line}
[Your Contact Information
`;

  return href;
};
