import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './index.css';
import '../../LandingPage/index.css';
import Fallback from './Fallback';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from '@mui/material';
import { Dialog, DialogActions, DialogTitle, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function SelectedNamespace({
  selectedNamespace,
  setSelectedNamespace,
}) {
  const [clearAllDialog, setClearAllDialog] = React.useState(false);
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);

  const clearAll = () => {
    setSelectedNamespace([]);
    setClearAllDialog(false);
  };

  const openDialog = () => {
    setClearAllDialog(true);
  };

  const closeDialog = () => {
    setClearAllDialog(false);
  };

  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const removeSelectedNamespace = (item) => {
    const results = [...selectedNamespace];
    const foundIndex =
      results?.length > 0
        ? results.findIndex((selected) => {
            return selected.namespace === item.namespace;
          })
        : -1;

    if (results[foundIndex]['clusters']?.length > 1) {
      const clusterIndex = results[foundIndex]['clusters'].indexOf(
        item.cluster
      );
      results[foundIndex]['clusters'].splice(clusterIndex, 1);
    } else {
      results.splice(foundIndex, 1);
    }
    setSelectedNamespace(results);
  };

  // useEffect(() => {
  //   if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);

  return (
    <React.Fragment>
      <div
        className="selected-namespace-container"
        style={{ marginTop: 0, flex: 5 }}
      >
        <div
          className="selected-namespace-header"
          style={{ height: '45px', boxSizing: 'border-box' }}
        >
          <div
            style={{
              padding: '0px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid
              p={0}
              style={{
                // padding: '21px 0px 7px 0px',
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '15px',
                color: '#12153E',
              }}
            >
              SELECTED ALLOWED NAMESPACES
            </Grid>
            <div>
              <Button
                disabled={
                  selectedNamespace?.length === 0 || DisableOffboardCluster
                }
                onClick={openDialog}
                sx={{ textTransform: 'none', color: '#2868F3' }}
              >
                Clear All
              </Button>
            </div>
          </div>
        </div>

        <div
          className={`selected-namespace-content ${
            selectedNamespace?.length === 0
              ? 'flex-1'
              : 'selected-isolate-namespace-container'
          }`}
        >
          {selectedNamespace?.length > 0 ? (
            selectedNamespace.map((item, index) => (
              <div
                key={`${item.namespace}- ${index}`}
                className="selected-isolated-namespace-item"
              >
                {item.namespace}
                <CloseIcon
                  onClick={(event) => {
                    if (DisableOffboardCluster) {
                      return null;
                    } else {
                      removeSelectedNamespace(item);
                    }
                  }}
                  htmlColor="#2868F3"
                  fontSize="small"
                  sx={{
                    fontSize: '13px',
                    cursor: 'pointer',
                    marginLeft: '16px',
                  }}
                />
              </div>
            ))
          ) : (
            <Fallback />
          )}
        </div>
      </div>

      <CustomDialog
        open={clearAllDialog}
        onClose={closeDialog}
        style={{ padding: '25px' }}
        aria-labelledby="Delete Cluster Dialog"
        aria-describedby="This Dialog is for delete cluster confirmation"
      >
        <DialogTitle
          style={{ fontWeight: 'bold' }}
          id="delete-cluster-dialog-title"
        >
          Do you want to delete selected allowed namespaces?
        </DialogTitle>
        <DialogActions>
          <>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="outlined"
              onClick={clearAll}
            >
              Yes
            </Button>
            <Button
              style={{
                textTransform: 'none',
                padding: '5px 40px',
                fontWeight: '700',
              }}
              variant="contained"
              onClick={closeDialog}
            >
              No
            </Button>
          </>
        </DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
}
