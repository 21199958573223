import CancelIcon from "@mui/icons-material/Cancel";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Fade, Grid,
  IconButton,
  Tooltip
} from "@mui/material";
import "ace-builds/src-noconflict/mode-sass";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import React, { useState } from "react";
import AceEditor from "react-ace";
import { useSelector } from "react-redux";
import YAML from "yaml";

function CodePreview(props) {
  const [isCopied, setIsCopied] = useState(false);
  const [yamlEditorText, setYamlEditorText] = useState("");
  const currentSlice = useSelector((state) => state.RbacReducer.currentSliceRoleList);

  React.useEffect(() => {
    if (currentSlice) {
      const sliceRoleBindingObj = {
        apiVersion: "controller.kubeslice.io/v1alpha1",
        kind: "SliceRoleBinding",
        metadata: {
          name: currentSlice?.metadata?.name
        },
        bindings: currentSlice?.updatedBinding
      };
      const doc = new YAML.Document();
      doc.contents = sliceRoleBindingObj;
      setYamlEditorText(doc.toString());
    }
    props.setClose(true);
  }, [currentSlice]);

  const copyToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(yamlEditorText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = yamlEditorText;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      });
    }
  };

  const closeEditor = () => {
    props.setClose(false);
    props.setCurrHeight('15vh');
  };

  const onClickHandler = () => {
    if (props.currHeight === '15vh') {
      props.setCurrHeight('80vh')
    }
    else {
      props.setCurrHeight('15vh')
    }
  }

  return (
    <Grid
      item
      xs
      container
      backgroundColor="#ffffff"
      borderRadius="14px"
      flexDirection="column"
      direction="column"
      justifyContent="space-between"
    >
      {props?.close && (
        <Grid
          item
          sx={{
            backgroundColor: "#272822",
            padding: "24px",
          }}
        >
          <span
            style={{
              margin: "30px 0 8px",
              fontSize: "20px",
              fontWeight: "700",
              color: "white",
            }}
          >
            Code Preview
            <IconButton
              onClick={closeEditor}
              sx={{ float: "right" }}
              aria-label="close yaml editor"
              component="span"
            >
              <CancelIcon color="white" fontSize="small" />
            </IconButton>
            <IconButton
              onClick={onClickHandler}
              sx={{ float: "right" }}
              aria-label="expand-collapse"
              component="span"
            >
              {props.currHeight === '15vh' ? <OpenInFullIcon color="white" fontSize="small" /> : <CloseFullscreenIcon color="white" fontSize="small" />}
            </IconButton>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              open={isCopied}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Copied to clipboard"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 2000 }}
            >
              <IconButton
                onClick={copyToClipboard}
                sx={{ float: "right" }}
                aria-label="copy yaml code"
                component="span"
              >
                <ContentCopyIcon color="white" fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
          <AceEditor
            mode="sass"
            theme="monokai"
            name="Slice YAML"
            height={props.currHeight}
            width="100%"
            onChange={(value) => setYamlEditorText(value)}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={yamlEditorText}
            setOptions={{
              readOnly: true,
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </Grid>
      )
      }
    </Grid>
  );
}

export default CodePreview;

