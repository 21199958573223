import * as React from 'react';
import RancherOnCloud from '../../assets/images/cloud-rancher.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import Rancher from '../../assets/images/Rancher.svg';

function RancherCloud(props) {
  return <CommonCloud {...props} img={Rancher} />;
}

export const RancherTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={RancherOnCloud} img={Rancher} />;
};

export default RancherCloud;
