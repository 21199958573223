import styled from "styled-components";
import { AppBar, IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import React from "react";

export const Header = styled(AppBar)`
  height: 3rem;
  top: 0;
  left: 0;
  padding: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  box-shadow: 0 0 5 0 lightgrey !important;
  z-index:3 !important;
  // background-image: linear-gradient(to right, #000046, #002f73, #005a9c);
  background-image: linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%);
`;

export const StyledToolBar = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  margin: 0rem 1rem !important;
  flex-direction: row;
  justify-content: space-between;
  color: white;
`;

export const NavContainer = styled.div`
  //width: 15%;
`;

export const MenuContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
  max-height: calc(100vh);
  min-height: calc(100vh);
  position: fixed;
  /* top: 3rem; */
  left: 0;
  width: auto;
  overflow: auto;
  margin-bottom: 0.5rem;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
export const MenuItem = styled(Accordion)`
  align-self: center;
  background-color: white !important;
  box-shadow: none !important;
  &.Mui-expanded {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const MenuOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  width: 100%;
  height: 1.5rem;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 2px 4px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  box-shadow: 1px 1px 3px 0px lightgrey;
  &:hover {
    border-color: grey;
  }
`;

export const SearchInput = styled.input`
  height: 100%;
  width: 80%;
  border: none;
  background-color: inherit;
  outline: none !important;
  align-self: center;
`;

export const AddSliceButton = styled(({ ...otherProps }) => <IconButton {...otherProps} variant="outlined" color="secondary" size="large" />)`
  && {
    font-size: 0.8rem;
    align-self: flex-end;
    padding: 0.05rem;
    margin: 0rem 1rem;
    z-index: 3;
  }
`;