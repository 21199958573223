import { ContentCopy } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Collapse, Stack, TableCell, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import React, { useState } from 'react';
import AceEditor from 'react-ace';
import EditRole from './EditRoles';

const RoleDetail = (props) => {
  const { role, open, onClose } = props;
  const stringifiedRules = JSON.stringify(role.rules ?? '', null, 4);
  const [showEditModal, setShowEditModal] = useState(false);

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openEditModal = () => {
    setShowEditModal(true);
  };


  const handleCopy = () => {
    navigator.clipboard.writeText(stringifiedRules);
  };

  return (
    <>
    <TableRow>
      <TableCell
        sx={{
          padding: 0,
        }}
        colSpan={4}
      >
        <Collapse in={open} timeout='auto' unmountOnExit>
          <Stack>
            <Box
              sx={{
                paddingY: '12px',
                paddingX: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 5,
                }}
              >
                <Typography fontWeight={600}>Role Name: {role.name}</Typography>
                <Typography fontWeight={600}>
                  Description: {role.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <Button
                  sx={{
                    color: '#12153E',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    border: '1px solid #B6B8D6',
                    borderRadius: '4px',
                  }}
                  varient='primary'
                  onClick={handleCopy}
                >
                  <ContentCopy fontSize='small' />
                  <Typography variant='body2' textTransform='capitalize'>
                    Copy
                  </Typography>
                </Button>
                <Button
                  sx={{
                    color: '#12153E',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    border: '1px solid #B6B8D6',
                    borderRadius: '4px',
                  }}
                  varient='primary'
                  onClick={openEditModal}
                >
                  <ModeEditOutlineOutlinedIcon fontSize='small' />
                  <Typography variant='body2' textTransform='capitalize'>
                    Edit
                  </Typography>
                </Button>
                <Button
                  sx={{
                    color: '#12153E',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  varient='primary'
                  onClick={onClose}
                >
                  <CloseIcon fontSize='small' />
                </Button>
              </Box>
            </Box>
            <Box>
              <AceEditor
                mode='json'
                theme='monokai'
                readOnly
                name='Rule'
                height='400px'
                width='100%'
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={stringifiedRules}
                setOptions={{
                  showLineNumbers: true,
                  tabSize: 4,
                }}
              />
            </Box>
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
      {showEditModal && <EditRole role={role} closeModal={closeEditModal} />}
      </>
  );
};

export default RoleDetail;
