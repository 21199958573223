import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../actions/RbacActions';
import NoRecord from '../../assets/images/no-slice-namespace.png';
import { ReactComponent as NotificationsIcon } from '../../assets/navbarIcons/notifications.svg';

import AddRole from './AddRole';
import './index.css';
import Row from './Row';
import TopbarSpinner from '../Dashboard/TopbarSpinner';
import GeneralLayout from '../Commons/GeneralLayout';

const ManageRoles = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [roleToShow, setRoleToShow] = React.useState(null);

  const [searchText, setSearchText] = React.useState('');

  const dispatch = useDispatch();
  const [addRoleDialog, setAddRoleDialog] = React.useState(false);
  const roles = useSelector((state) => state.RbacReducer.roles);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const handleRowsCountChange = (event) => {
    const { target } = event;
    setRowsPerPage(target.value);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleSearchInput = (event) => {
    const {
      target: { value },
    } = event;

    setSearchText(value);
  };

  const handleRedirectToNewUi = () => {
    window.location.href = `/events`;
  };

  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  return (
    <GeneralLayout description={'RBAC'}>
      <Paper elevation={3} className="rbac-table-paper">
        <div className="rbac-table-header">
          <Paper
            component="form"
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 40,
              borderRadius: '0 5px 5px 0',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                margin: '24.5px 0px 24.5px 16px',
              }}
            >
              Roles
            </Typography>
            <div
              style={{
                display: 'flex',
                height: '40px',
                overflow: 'hidden',
                border: '1px solid #B6B8D6',
                marginLeft: '24px',
                borderRadius: '4px',
                width: '308px',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search google maps' }}
                value={searchText}
                onChange={handleSearchInput}
              />
              <IconButton className="search-icon">
                <SearchIcon />
              </IconButton>
            </div>
          </Paper>
          <Button
            //disabled={selectedRole.length === 0}
            sx={{ marginRight: '20px' }}
            className="btn"
            variant="contained"
            width="116px"
            height="40px"
            onClick={() => setAddRoleDialog(true)}
          >
            Add Role
          </Button>
        </div>
        <TableContainer
          className="table-container-roles"
          sx={{
            flex: '1 1 0%',
            overflowY: 'auto',
          }}
        >
          <Table>
            <TableHead className="table-head">
              <TableRow>
                <TableCell />
                <TableCell
                  // className="txt-bold ft-12 padding-cell-header "
                  // style={{ paddingRight: "0px" }}
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  ROLE NAME
                </TableCell>
                <TableCell
                  // className="txt-bold ft-12 padding-cell-header "
                  // style={{ paddingLeft: "0px" }}
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  DESCRIPTION
                </TableCell>
                <TableCell className="txt-bold ft-12 padding-cell-header "></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.length ? (
                roles
                  .filter((role) => role.name.includes(searchText))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => (
                    <Row
                      key={idx}
                      role={row}
                      roleToShow={roleToShow}
                      setRoleToShow={setRoleToShow}
                    />
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ paddingY: 10, borderBottom: 0 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img src={NoRecord} alt="no roles" />
                      <Typography variant="h5" sx={{ marginTop: 3 }}>
                        No Role Bindings
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            width: '100%',
            background: 'white',
          }}
        >
          <div className="pagination">
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={roles.length}
              onRowsPerPageChange={handleRowsCountChange}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        </Box>
        {addRoleDialog && (
          <AddRole
            addRoleDialog={addRoleDialog}
            setAddRoleDialog={setAddRoleDialog}
          />
        )}
      </Paper>
    </GeneralLayout>
  );
};

export default ManageRoles;
