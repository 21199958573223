import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { connect } from "react-redux";
import { FormCheck, FormField, FormSelect } from "../Commons/formUtils";
import { FormContainer } from "../RoleManager/FormStyles";
import EnforcementRule from "./EnforcementRule";
import EnforcementRuleList from "./EnforcementRuleList";
import JsonEditor from "./JsonEditor";
import styled from "styled-components";
import { clearSelectedSlicePolicy, createSlicePolicy, editSlicePolicy } from "../../actions/SlicePolicyActions";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import MuiPopper from "@mui/material/Popper";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import {styled as MuiStyled} from "@mui/material/styles";

const Title = MuiStyled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}));


const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const Popper = styled(MuiPopper)`
  z-index: 5000;
`;

// const useStyles = makeStyles((theme) => ({
//   // appBar: {
//   //   position: "relative",
//   // },
//   // title: {
//   //   marginLeft: theme.spacing(2),
//   //   flex: 1,
//   // },
//   // root: {
//   //   width: "100%",
//   //   "& > * + *": {
//   //     marginTop: theme.spacing(2),
//   //   },
//   // },
// }));

const SaveButton = styled(Button)`
  :disabled {
    background-color: rgba(47, 167, 103, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddSlicePolicyDialog(props) {
  //creating css classes from jss
  //const classes = useStyles();

  const [sliceName, setSliceName] = useState("");
  const [policyName, setPolicyName] = useState("");
  const [policyEnabled, setPolicyEnabled] = useState(false);
  const [sliceTelemetryEnable, setSliceTelemetryEnable] = useState(false);
  const [telemetryAccessPoint, setTelemetryAccessPoint] = useState([]);
  const [enforcementEnable, setEnforcementEnable] = useState(false);
  const [enforcementPointProfile, setEnforcementPointProfile] = useState([]);
  const [algorithmConfig, setAlgorithmConfig] = useState(null);
  const [showEnfRuleForm, setShowEnfRuleForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [algorithmConfigType, setAlgorithmConfigType] = useState("");
  const [enforcementKindOptions, setEnforcementKindOptions] = useState([]);
  const handleAddEnfRule = (enfRule) => {
    setEnforcementPointProfile([...enforcementPointProfile, enfRule]);
    setShowEnfRuleForm(false);
  };

  const handleDeleteEnforcementRule = (index) => {
    let newRules = [...enforcementPointProfile.slice(0, index), ...enforcementPointProfile.slice(index + 1)];
    setEnforcementPointProfile(newRules);
  };

  useEffect(() => {
    const options = filterEnforcementKindOptions();
    setEnforcementKindOptions(options);
  }, [enforcementPointProfile?.length, algorithmConfigType]);

  const filterEnforcementKindOptions = () => {
    let options = [];
    let visited = enforcementPointProfile.map(({ kind }) => kind);
    if (algorithmConfigType == "TCP_LB_POLICY") {
      options = [{ value: "KIND_TCP_ISTIO_VIRTUAL_SERVICE", label: "TCPIstioVirtualService" }].filter(({ value }) => !visited.includes(value));
    } else if (algorithmConfigType == "HTTP_LB_POLICY") {
      options = [
        { value: "KIND_HTTP_ISTIO_EGRESS_VIRTUAL_SERVICE", label: "HTTPIstioEgressVirtualService" },
        { value: "KIND_HTTP_ISTIO_VIRTUAL_SERVICE", label: "HTTPIstioVirtualService" },
      ].filter(({ value }) => !visited.includes(value));
    } else {
      options = [];
    }
    return options;
  };
  const clearData = () => {
    props.clearSelectedSlicePolicy();
    setPolicyName("");
    setPolicyEnabled(false);
    setSliceTelemetryEnable(false);
    setTelemetryAccessPoint([]);
    setEnforcementEnable(false);
    setEnforcementPointProfile([]);
    setAlgorithmConfig(null);
    setShowEnfRuleForm(false);
  };

  const validateData = () => {
    if (!policyName) {
      setErrorMsg("Policy Name is required!");
      return false;
    }
    if (!telemetryAccessPoint?.length) {
      setErrorMsg("Telemetry Access Point is required!");
      return false;
    }
    if (!algorithmConfigType) {
      setErrorMsg("Policy Profile is required!");
      return false;
    }
    if (enforcementPointProfile?.length > 1) {
      setErrorMsg("Only one Enforcement Point Profile allowed per Slice Policy!");
      return false;
    }
    return true;
  };
  const handleSave = () => {
    if (!validateData()) return;
    let slicePolicy = {
      slicePolicy: {
        slicePolicyName: policyName,
        slicePolicyEnabled: policyEnabled,
        sliceTelemetryEnabled: sliceTelemetryEnable,
        telemetryAccessPoints: telemetryAccessPoint,
        enableEnforcement: enforcementEnable,
        enforcementPointConfig: enforcementPointProfile,
        algorithmConfig,
        algorithmConfigType,
      },
    };
    if (props?.selectedSlicePolicy) {
      props.editSlicePolicy(slicePolicy, props.sliceName, props.selectedSlicePolicy.slicePolicyId);
    } else {
      props.createSlicePolicy(slicePolicy, props.sliceName);
    }
    props.handleClose();
  };

  useEffect(() => {
    if (props.selectedSlicePolicy) {
      handleSelectedPolicyData();
    }
  }, [props.selectedSlicePolicy]);

  const handleSelectedPolicyData = () => {
    const { selectedSlicePolicy } = props;
    if (selectedSlicePolicy) {
      setPolicyName(selectedSlicePolicy.slicePolicyName);
      setPolicyEnabled(selectedSlicePolicy.slicePolicyEnabled);
      setSliceTelemetryEnable(selectedSlicePolicy.sliceTelemetryEnabled);
      setTelemetryAccessPoint(selectedSlicePolicy.telemetryAccessPoints);
      setEnforcementEnable(selectedSlicePolicy.enableEnforcement);
      setEnforcementPointProfile(selectedSlicePolicy.enforcementPointConfig);
      setAlgorithmConfig(selectedSlicePolicy.algorithmConfig);
      setAlgorithmConfigType(selectedSlicePolicy.algorithmConfigType);
    }
  };

  const policyTitle = props.selectedSlicePolicy ? "Edit Slice Policy" : "New Slice Policy";

  const handleSnackClose = () => {
    setErrorMsg("");
  };
  return (
    <div>
      <Dialog
        style={{ zIndex: 4000 }}
        fullScreen
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          //className={classes.appBar}
          style={{
            backgroundImage: "linear-gradient(90deg,  rgba(18,21,62,1) 0%, rgba(40,104,242,1) 100%)",
            position: "relative",
          }}
        >
          <Toolbar style={{ color: "#ffffff" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                clearData();
                props.handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Title variant="h6" 
            //className={classes.title}
            >
              {policyTitle}
            </Title>

            <SaveButton variant="contained" autoFocus color="secondary" onClick={handleSave}>
              save
            </SaveButton>
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              borderRight: "solid black 1px",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <Snackbar
                style={{ zIndex: 100000 }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorMsg !== ""}
                autoHideDuration={3000}
                onClose={handleSnackClose}
              >
                <div>
                <Alert onClose={handleSnackClose} severity="error">
                  {errorMsg}
                </Alert>
                </div>
              </Snackbar>
              <h3>{policyTitle}</h3>
              <h4>General Details</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormField label="Slice Name" value={props.sliceName} disabled />
                <FormField label="Policy Name" value={policyName} onChange={(e) => setPolicyName(e.nativeEvent.target.value)} />
                <div style={{ paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                  <FormCheck checked={policyEnabled} onChange={() => setPolicyEnabled(!policyEnabled)} label="Policy Enabled" />
                  <FormCheck checked={enforcementEnable} onChange={() => setEnforcementEnable(!enforcementEnable)} label="Enforcement Enabled" />
                  <FormCheck
                    checked={sliceTelemetryEnable}
                    onChange={() => setSliceTelemetryEnable(!sliceTelemetryEnable)}
                    label="Slice Telemetry Enabled"
                  />
                </div>
              </div>
            </FormContainer>
          </div>
          <Form>
            <Autocomplete
              multiple
              disabled={props.selectedSlicePolicy}
              options={["TELEMETRY_ACCESS_POINT_PROMETHEUS"]}
              // options={["TELEMETRY_ACCESS_POINT_PROMETHEUS", "TELEMETRY_ACCESS_POINT_DATADOG", "TELEMETRY_ACCESS_POINT_ELK"]}
              defaultValue={telemetryAccessPoint}
              filterSelectedOptions
              PopperComponent={Popper}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Telemetry Access Point" placeholder="" />}
              style={{ width: "40rem", zIndex: 5000 }}
              onChange={(e, v) => setTelemetryAccessPoint(v)}
              />
            <Autocomplete
              disabled={props.selectedSlicePolicy}
              options={["TCP_LB_POLICY", "HTTP_LB_POLICY"]}
              defaultValue={algorithmConfigType}
              filterSelectedOptions
              PopperComponent={Popper}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Policy Profile" placeholder="" />}
              style={{ width: "40rem", zIndex: 5000, marginTop: "1rem" }}
              onChange={(e, v) => {
                setAlgorithmConfigType(v);
                setEnforcementPointProfile([])
              }}
            />

            {showEnfRuleForm && (
              <EnforcementRule options={enforcementKindOptions} onSave={handleAddEnfRule} onCancel={() => setShowEnfRuleForm(false)} />
            )}
            {/* <JsonEditor placeholder={algorithmConfig} label="Algorithm Config" onChange={(e) => setAlgorithmConfig(e.jsObject)} /> */}

            <EnforcementRuleList onAdd={() => setShowEnfRuleForm(true)} list={enforcementPointProfile} onDelete={handleDeleteEnforcementRule} />
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({ SlicePolicyReducer: { selectedSlicePolicy } }) => ({
  selectedSlicePolicy,
});
export default connect(mapStateToProps, {
  editSlicePolicy,
  createSlicePolicy,
  clearSelectedSlicePolicy,
})(AddSlicePolicyDialog);
