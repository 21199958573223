import {
  CLEAR_CURRENT_SLICE,
  EDIT_SLICE,
  OFFBOARD_NAMESPACE,
  SET_CURRENT_SLICE,
} from '../actions/types';

const initialState = {
  currentSlice: {},
};

export default function NamespaceOnboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_SLICE:
      return {
        ...state,
        currentSlice: action.payload,
      };
    case CLEAR_CURRENT_SLICE:
      return {
        ...state,
        currentSlice: {},
      };
    case EDIT_SLICE:
      return {
        ...state,
        editSlice: action.payload,
      };
    case OFFBOARD_NAMESPACE:
      return {
        ...state,
        editSlice: action.payload,
        OffboardNamespaces: action.payload,
      };
    default:
      return state;
  }
}
