import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AssignToNamespace from './AssignToNamespace';
import Finalize from './Finalize';
import GenerateYaml from './GenerateYaml';
import CodePreview from './GenerateYaml/CodePreview';
import './index.css';
import InitialStep from './InitialStep';
import SliceTable from './InitialStep/SliceTable';

function Steps() {
  const { stepNo } = useParams();
  const [currHeight, setCurrHeight] = useState('15vh');
  const [close, setClose] = useState(true);
  const ref = useRef();
  const [Breadcrumb, setBreadcrumb] = useState('');

  useEffect(() => {
    ref.current.scrollTo(0, 0);
  });

  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  const sliceDataNAme = localStorage.getItem('currentSlice');

  return (
    <>
      <Box
        container
        sx={{
          position: 'relative',
        }}
        direction="column"
      >
        <Grid item className="header">
          <Paper className="paper-header">
            <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px', // Adjust the font size as per your requirement
                lineHeight: '20px',
                color: '#6E708E',
                paddingTop: '4px',
              }}
              variant="subtitle1"
            >
              {currentProjectData?.projectNamespace}
              <span> / RBAC / </span>
              <span style={{ fontWeight: 600 }}>{Breadcrumb}</span>
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            flex: '1 1 0%',
            padding: '0px',
            overflowY: 'scroll',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            container
            direction="column"
            className="step-main-custom-container"
          >
            <Grid item className="grid-item-main-rbac" xs={12}>
              <Grid container className="step-container">
                <Grid
                  item
                  className={[
                    'iso-grid-item',
                    stepNo == 1 ? `grid-item-selected` : `grid-item`,
                  ]}
                  xs={3}
                >
                  <Typography
                    className="step-title"
                    gutterBottom
                    component="div"
                  >
                    {`Step 1 - Assign roles to Slices`}
                  </Typography>
                  <Typography
                    className="step-subtitle"
                    gutterBottom
                    component="div"
                  >
                    {`Assign roles to slices`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={[
                    'iso-grid-item',
                    stepNo == 2 ? `grid-item-selected` : `grid-item`,
                  ]}
                  xs={3}
                >
                  <Typography
                    className="step-title"
                    gutterBottom
                    component="div"
                  >
                    {`Step 2 -Assign to namespaces`}
                  </Typography>
                  <Typography
                    className="step-subtitle"
                    gutterBottom
                    component="div"
                  >
                    {`Assign roles to namespaces`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={[
                    'iso-grid-item',
                    stepNo == 3 ? `grid-item-selected` : `grid-item`,
                  ]}
                  xs={3}
                >
                  <Typography
                    className="step-title"
                    gutterBottom
                    component="div"
                  >
                    {`Step 3 - Apply YAML`}
                  </Typography>
                  <Typography
                    className="step-subtitle"
                    gutterBottom
                    component="div"
                  >
                    {`Apply YAML`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={[
                    'iso-grid-item',
                    stepNo == 4 ? `grid-item-selected` : `grid-item`,
                  ]}
                  xs={3}
                >
                  <Typography
                    className="step-title"
                    gutterBottom
                    component="div"
                  >
                    {`Step 4 - Finalize`}
                  </Typography>
                  <Typography
                    className="step-subtitle"
                    gutterBottom
                    component="div"
                  >
                    {`Display the final status`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="scroll-container"
              ref={ref}
              sx={{
                flex: '1 1 0%',
                marginBottom: '60px',
                overflow: 'scroll',
              }}
            >
              {stepNo == 1 && (
                <>
                  <Grid className="grid-item-main step-grid-item" item xs={12}>
                    <SliceTable />
                  </Grid>
                  <Grid className="grid-item-main step-grid-item" item xs={12}>
                    <InitialStep step={stepNo} setBreadcrumb={setBreadcrumb} />
                  </Grid>
                </>
              )}
              {stepNo == 2 && <AssignToNamespace step={stepNo} />}
              {stepNo == 3 && <GenerateYaml />}
              {stepNo == 4 && <Finalize />}
            </Grid>
            {stepNo == 3 && (
              <Grid
                item
                style={{
                  position: 'fixed',
                  bottom: '-20px',
                  width: 'calc(100% - 220px)',
                  height: { currHeight },
                  right: '.10px',
                }}
              >
                <CodePreview
                  setClose={setClose}
                  close={close}
                  setCurrHeight={setCurrHeight}
                  currHeight={currHeight}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Steps;
