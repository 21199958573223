import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentSlice } from '../../../../actions/namespaceActions';
import NamespaceTable from './NamespaceTable';
import SelectedNamespace from './SelectedNamespace';
import './index.css';
import MyContext from './context';

export default function InitialStep() {
  const [selectedNamespace, setSelectedNamespace] = useState([]);
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState(false);
  const [searchBy, setSearchBy] = useState('namespace');
  const [SelectionSate, setSelectionSate] = useState({
    selectionStatecontext: false,
    clearAllcontext: false,
  });

  // const [myData, setMyData] = useState('');

  // console.log(SelectionSate, 'SelectionSate');
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setSelectedNamespace(
      currentSlice['spec']['namespaceIsolationProfile']['applicationNamespaces']
    );
  }, [currentSlice]);

  const addToSlice = () => {
    let updatedSlice = { ...currentSlice };
    updatedSlice['spec']['namespaceIsolationProfile']['applicationNamespaces'] =
      selectedNamespace;
    dispatch(setCurrentSlice(updatedSlice));
    history.push('/manage-namespaces/add/2');
  };

  const searchNamespace = (event) => {
    setSearchText(event.target.value);
    setSearch(false);
  };

  useEffect(() => {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, [currentSlice]);

  return (
    <Box className="step-grid-item">
      <div style={{ padding: '24px' }} className="step-content-header">
        <Typography className="step-title-header" gutterBottom component="div">
          Add Namespaces
        </Typography>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              height: '40px',
              overflow: 'hidden',
              border: '1px solid #B6B8D6',
              marginRight: '24px',
              borderRadius: '9px',
            }}
          >
            <FormControl
              className="filter-dropdown"
              sx={{ backgroundColor: '#E6E8F3', top: '0px', height: '44px' }}
            >
              <InputLabel id="search-by-label"></InputLabel>
              <Select
                labelId="search-by-label"
                id="search-by"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                label="Search By"
                style={{ height: '40px' }}
                renderValue={(value) =>
                  value.charAt(0).toUpperCase() + value.slice(1)
                }
              >
                <MenuItem value="namespace">Search by Namespace</MenuItem>
                <MenuItem value="cluster">Search by Cluster name</MenuItem>
              </Select>
            </FormControl>
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 250,
                borderRadius: '0 5px 5px 0',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                onChange={searchNamespace}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearch(true);
                  }
                }}
                inputProps={{ 'aria-label': 'search google maps' }}
              />
              <IconButton
                className="search-icon"
                onClick={() => setSearch(true)}
                sx={{ p: '10px' }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <Button
            disabled={
              currentSlice['spec']['namespaceIsolationProfile'][
                'applicationNamespaces'
              ]?.length > 0 || selectedNamespace?.length > 0
                ? false
                : true
            }
            className="btn-style"
            onClick={addToSlice}
            variant="contained"
          >
            Add to slice
          </Button>
        </div>
      </div>
      <Paper sx={{ display: 'flex' }}>
        <MyContext.Provider value={{ SelectionSate, setSelectionSate }}>
          <NamespaceTable
            selectedNamespace={selectedNamespace}
            searchText={searchText}
            setSelectedNamespace={(selectedArr) =>
              setSelectedNamespace(selectedArr)
            }
            search={search}
            searchBy={searchBy}
          />
          <SelectedNamespace
            selectedNamespace={selectedNamespace}
            setSelectedNamespace={(selectedArr) =>
              setSelectedNamespace(selectedArr)
            }
            DisableOffboardCluster={DisableOffboardCluster}
          />
        </MyContext.Provider>
      </Paper>
    </Box>
  );
}
