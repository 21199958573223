import styled from 'styled-components';
import { Edit as MuiEdit } from "@mui/icons-material";
import { Divider } from '../../Body/BodyStyles';
import MuiButton from "@mui/material/Button";

export const SectionContainer = styled.div`
  min-height: 7rem;
  margin-top: 1rem;
`;
export const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ActionContainer = styled.div`
  width: 15rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export const SectionHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${(props) => (props.attached ? "#004285" : "black")};
`;
export const AttachButton = styled(MuiButton)`
  width: 13.5rem;
`;

export const SectionDivider = styled(Divider)`
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const ActionBar = styled.div`
  display: flex;
  padding: 0.5rem;
  width: inherit;
  justify-content: space-between;
  align-items: center;
`;