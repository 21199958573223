
const type = "selectorNode"
export const generateNodeCoordinates = (clusters)=>{

    const size = clusters.length;
    if(size === 0)
        return [];
    const radius = 50 * clusters.length;
    const centerX = radius;
    const centerY = radius;
    let id = 0;
    const offset = (Math.PI * 2)/size;
    const result = []
    while(id<size){
        const x =centerX+radius * Math.sin(offset*id);
        const y =centerY+radius * Math.cos(offset*id);
        const data = { 
            clusterName:clusters[id].clusterName,
            location: clusters[id].location.region,
            type:clusters[id].location.cloudProvider,
        };
        result.push({
            id:clusters[id].clusterName,
            type,
            data,
            position:{x,y} 
        });
        id++;
    }
    return result;
   
}

export const generateEdges = (nodes, latency)=>{
    let edges = []
    for(var i=0;i<nodes.length-1;i++){
        for(var j=i+1;j<nodes.length;j++){            
            const node1LatencyMap = latency[nodes[i]?.id]?.latencyMap?.find(lm => lm?.remote_cluster_id == nodes[j]?.id)?.latency;
            const node1Latency = node1LatencyMap === undefined ? "NA" : (Number(node1LatencyMap).toFixed(2) + "ms");
            const node2LatencyMap = latency[nodes[j]?.id]?.latencyMap?.find(lm => lm?.remote_cluster_id == nodes[i]?.id)?.latency;
            const node2Latency =  node2LatencyMap === undefined ? "NA" : (Number(node2LatencyMap).toFixed(2) + "ms");
            edges.push(
            { 
                id: `${nodes[i].id}-${nodes[j].id}`,
                source: nodes[i].id, target: nodes[j].id,
                type: 'straight',
                label  :`${node1Latency} , ${node2Latency}`,
            }
            )
        }
    }
    return edges;
}
