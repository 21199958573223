import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Row from '../LandingPage/Row';
import { CustomTableCell } from './GenerateYaml';

const Finalize = () => {
  const history = useHistory();
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const onDone = () => {
    history.push('/manage-rbac');
  };

  React.useEffect(() => {
    if (!currentSlice?.metadata?.name) {
      history.push('/manage-rbac');
    }
  }, []);

  return (
    <>
      {
        <Grid item>
          <Paper
            style={{ minHeight: 'auto', marginBottom: '20px' }}
            elevation={5}
            className="table-paper"
          >
            <Grid
              style={{ padding: '0 10px' }}
              container
              spacing={2}
              direction="row"
            >
              <Grid item xs={10}>
                <Typography
                  className="step-title-header"
                  gutterBottom
                  component="div"
                >
                  Slice
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className="table-container">
              <Table aria-label="collapsible table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell />
                    <CustomTableCell>SLICE NAME</CustomTableCell>
                    <CustomTableCell>TYPE</CustomTableCell>
                    <CustomTableCell>DESCRIPTION</CustomTableCell>
                    <CustomTableCell>
                      ROLES
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Row
                    isFinalize
                    step={4}
                    slice={currentSlice}
                    radioDisable={true}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper
            style={{ minHeight: 'auto' }}
            mt={2}
            elevation={5}
            className="table-paper"
          >
            <Grid
              style={{ padding: '0 10px' }}
              container
              spacing={2}
              direction="row"
            >
              <Grid item xs={10}>
                <Typography
                  className="step-title-header"
                  gutterBottom
                  component="div"
                >
                  Namespaces
                </Typography>
              </Grid>
            </Grid>
            <TableContainer className="table-container">
              <Table aria-label="collapsible table">
                <TableHead className="table-head">
                  <TableRow>
                    <CustomTableCell
                      style={{
                        fontWeight: '600',
                      }}
                    >
                      ROLES
                    </CustomTableCell>
                    <CustomTableCell
                      style={{
                        fontWeight: '600',
                      }}
                    >
                      NAMESPACES
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentSlice?.metadata?.data?.length > 0
                    ? currentSlice.metadata.data.map((row, key) => (
                        <TableRow>
                          <TableCell
                            scope="row"
                            style={{
                              width: '40%',
                            }}
                          >
                            <label
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontSize: '16px',
                                fontWeight: '600',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#12153E',
                                display: 'block',
                              }}
                            >
                              {row?.roleName.join(', ')}
                            </label>
                            <label>
                              {row.kind === 'User' ? 'User' : 'User Group'} :{' '}
                              {row.name}
                            </label>
                          </TableCell>
                          <TableCell scope="row">
                            <Grid container spacing={0}>
                              {row?.namespaces?.length > 0 &&
                                row.namespaces.map((item, index) => (
                                  <div
                                    style={{
                                      flexBasis: 'calc(33.33% - 20px)',
                                      margin: '10px',
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        color: '#12153E',
                                        display: 'block',
                                        borderRight:
                                          index % 3 === 2
                                            ? 'none'
                                            : '0.6px solid #B6B8D6',
                                      }}
                                    >
                                      {item?.namespace}
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          color: '#5A607C',
                                          lineHeight: '25.5px;',
                                        }}
                                      >
                                        {`Cluster - ${item?.cluster}`}
                                      </Typography>
                                    </label>
                                  </div>
                                ))}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}

                  {currentSlice?.metadata?.importedData.length > 0
                    ? currentSlice.metadata.importedData.map((row, key) => (
                        <TableRow
                        // style={{
                        //   display: 'flex',
                        //   flexWrap: 'wrap',
                        //   columnGap: '22px',
                        //   padding: '20px',
                        // }}
                        // style={{
                        //   display: 'flex',
                        //   flexWrap: "wrap",
                        //   columnGap: '22px',
                        //   /* padding-bottom: 3%; */
                        //   padding: '20PX,',
                        // }}
                        >
                          <TableCell scope="row">
                            <label
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontSize: '16px',
                                fontWeight: '600',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#12153E',
                                display: 'block',
                              }}
                            >
                              {row?.roleName.join(', ')}
                            </label>
                            <label>
                              {row.kind === 'User' ? 'User' : 'User Group'} :{' '}
                              {row.name}
                            </label>
                          </TableCell>

                          <TableCell scope="row">
                            <Grid container spacing={0}>
                              {row?.namespaces?.length > 0 &&
                                row.namespaces.map((item, index) => (
                                  <div
                                    style={{
                                      flexBasis: 'calc(33.33% - 20px)',
                                      margin: '10px',
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontFamily: 'Bai Jamjuree',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        color: '#12153E',
                                        display: 'block',
                                        borderRight:
                                          index % 3 === 2
                                            ? 'none'
                                            : '0.6px solid #B6B8D6',
                                      }}
                                    >
                                      {item?.namespace}
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          color: '#5A607C',
                                          lineHeight: '25.5px;',
                                        }}
                                      >
                                        {`Cluster - ${item?.cluster}`}
                                      </Typography>
                                    </label>
                                  </div>
                                ))}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="bottom-btn-container">
            <div>
              <Button
                onClick={() => history.push('/manage-rbacs/add/3')}
                style={{ width: '100px', marginRight: '10px', top: '8%' }}
                className="btn"
                variant="contained"
              >
                <ArrowBackIosIcon style={{ fontSize: '12px' }} /> Previous{' '}
              </Button>
              <Button
                onClick={onDone}
                style={{ width: '100px', marginRight: '10px', top: '8%' }}
                className="btn"
                variant="contained"
              >
                {' '}
                Done{' '}
              </Button>
            </div>
          </div>
        </Grid>
      }
    </>
  );
};

export default Finalize;
