import MUIDialog from '@mui/material/Dialog';
import React from 'react';
import styled from 'styled-components';
import { FormField } from './FormFields';

const StyledFormField = styled(FormField)`
  flex-direction: row;
  justify-content: center;
  min-width: 33rem;
  width: auto;
  margin-bottom: -1%;
`;
const InnerContainer = styled.div`
  padding: 16px;
  display: flex;
  overflow: visible;
`;
const FieldList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-self: center;
  align-items: center;
`;

const AboutUs = (props) => {
  const companyDetails = (
    <div style={{ fontWeight: '500' }}>
      Avesha's breakthrough patented products KubeSlice and Smart Scaler make it
      easy and cost-effective to manage and scale large-scale applications and
      infrastructure in the enterprise world.
      <br />
      <br />
      <p>
        For more info click on{' '}
        <a
          style={{
            color: '#3b82f6',
            textDecoration: 'none',
          }}
          href="https://www.avesha.io"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          www.avesha.io
        </a>
      </p>
    </div>
  );

  return (
    <MUIDialog
      PaperProps={{
        style: { borderTop: '4px solid rgb(40, 104, 243)' },
      }}
      style={{ zIndex: 3000, overflow: 'visible' }}
      open={props.open}
      onClose={props.closeContactModal}
    >
      <InnerContainer>
        <FieldList>
          <StyledFormField
            color="black"
            heading="Company Details"
            content={companyDetails}
          />
          {/* <StyledFormField color="black" heading="Version" content={'0.8.1'} /> */}
        </FieldList>
      </InnerContainer>
    </MUIDialog>
  );
};

export default AboutUs;
