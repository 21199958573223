import React, { Component } from "react";
import { FormField, FormSelect } from "../Commons/formUtils";
import Button from "@mui/material/Button";
import { ButtonContainer, FormContainer, Title } from "../Body/FormStyles";
import { connect } from "react-redux";

import PopUp from "../Commons/popUtils";
import { createTelemetry } from "../../actions/telemetryActions";
import JsonEditor from "../SlicePolicy/JsonEditor";
const initialValue = {
  siteName: "us-east-1",
  metricProviderConfiguration: {
    prometheus: {
      host: "10.1.75.26",
      port: "30009",
    },
    datadog: {
      endpoint: "10.1.75.26",
      apiKey: "xxxaaa",
    },
    elk: {
      host: "10.1.75.26",
      port: "30009",
    },
  },
};
// const initialValue = {
//   siteName: "",
//   prometheus: {
//     host: "",
//     port: "",
//   },
//   datadog: {
//     endpoint: "",
//     apiKey: "",
//   },
//   elk: {
//     host: "",
//     port: "",
//   },
// };
class TelemetryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { ...initialValue },
    };
  }

  submitForm = () => {
    const { sliceName, clusterId } = this.props;
    const { value } = this.state;

    this.props.createTelemetry(sliceName, clusterId, { telemetry: value });
    this.setState({ value: { ...initialValue } });
    this.props.cancelListener();
  };

  componentDidMount() {}
  setValue = (key, val) => {
    this.setState({ value: { ...this.state.value, [key]: val } });
  };
  render() {
    const { cancelListener, open } = this.props;
    const { value } = this.state;
    const { siteName, metricProviderConfiguration } = value;
    return (
      <PopUp open={open}>
        <FormContainer>
          <Title>New Telemetry</Title>
          <FormField label="Site Name" value={siteName} onChange={(e) => this.setValue("siteName", e.target.value)} />
          <JsonEditor
            height="20rem"
            width="30rem"
            placeholder={metricProviderConfiguration}
            label="Metric Provider Configuration"
            onChange={(e) => this.setValue("metricProviderConfiguration", e.jsObject)}
          />
          {/* <div>Prometheus</div>
          <FormField label="Host" value={prometheus.host} onChange={(e) => this.setValue("prometheus", "host", e.target.value)} />
          <FormField label="Port" value={prometheus.port} onChange={(e) => this.setValue("prometheus", "port", e.target.value)} />
          <div>Datadog</div>
          <FormField label="Endpoint" value={datadog.endPoint} onChange={(e) => this.setValue("datadog", "endPoint", e.target.value)} />
          <FormField label="Api Key" value={datadog.apiKey} onChange={(e) => this.setValue("datadog", "apiKey", e.target.value)} />
          <div>Datadog</div>
          <FormField label="Host" value={elk.host} onChange={(e) => this.setValue("elk", "host", e.target.value)} />
          <FormField label="Port" value={elk.port} onChange={(e) => this.setValue("elk", "port", e.target.value)} /> */}
          <ButtonContainer>
            <Button variant="contained" color="primary" onClick={this.submitForm}>
              Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={cancelListener}>
              Cancel
            </Button>
          </ButtonContainer>
        </FormContainer>
      </PopUp>
    );
  }
}

export default connect(null, { createTelemetry })(TelemetryForm);
