// import React, { useState } from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Check } from '@mui/icons-material';
import './ButtonDep.css';

const MapOptionButton = ({ onClick, isActive, label }) => {
  return (
    <Button
      sx={{ fontSize: '12px', fontWeight: 600, height: '26px', px: "10px" }}
      className={`DepButton ${isActive ? 'activeButton' : 'inactiveButton'}`}
      variant={isActive ? 'contained' : 'outlined'}
      onClick={onClick}
    >
      {isActive && (
        <Check sx={{ marginRight: '4px', height: '14px', width: '14px' }} />
      )}
      {label}
    </Button>
  );
};

export function LocationButton(props) {
  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#2868F3',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#81839A',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={1} direction="row">
        <MapOptionButton
          isActive={props.location === 'all'}
          label={'World'}
          onClick={props.allClick}
        />
        <MapOptionButton
          isActive={props.location === 'na'}
          label={'North America'}
          onClick={props.northClick}
        />
        <MapOptionButton
          isActive={props.location === 'europe'}
          label={'Europe'}
          onClick={props.europeClick}
        />
      </Stack>
    </ThemeProvider>
  );
}

LocationButton.propTypes = {
  allClick: PropTypes.func.isRequired,
  northClick: PropTypes.func.isRequired,
  europeClick: PropTypes.func.isRequired,
};

export default LocationButton;
