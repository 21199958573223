import { AccessTimeFilled } from '@mui/icons-material';
import { Box, Button, Link, Modal } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { hideExpiredBanner } from '../../../actions/licensing.action';
import { ReactComponent as KeyWhite } from '../../../assets/icons/key-white.svg';
import { license_docs } from '../../../constants/license';
import {
  selectLicenseDetails,
  selectShowExpiredModal,
} from '../../../selectors/licensing.selector';
import { formatDate } from '../../../utils/date-time';
import { createUpgradeLicenseLink, isEnterpriseLicense } from '../../../utils/license';
import LicenseId from '../LicenseId/LicenseId';

// everything is inline beacuse we need to do this in v2 as well
const LicenseExpiredModal = () => {
  const licenseDetails = useSelector(selectLicenseDetails);
  const dispatch = useDispatch();
  const showLicenseExpired = useSelector(selectShowExpiredModal);
  const { 'license-expiration': licenseExpiration } = licenseDetails || {};
  const expirationDate = formatDate(licenseExpiration);

  const daysLeft = moment(licenseExpiration).diff(moment(), 'days');
  const expired = daysLeft <= 0;

  const handleClose = () => {
    dispatch(hideExpiredBanner());
  };

  const href = createUpgradeLicenseLink(licenseDetails);
  const isEnterprise = isEnterpriseLicense(licenseDetails);

  const open = showLicenseExpired && expired;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 'none',
      }}
    >
      <Box
        data-testid="expired-modal"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white !important',
          width: '600px',
          borderTop: '3px solid #E15E5E',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: '#FEF9F9',
            bgOpacity: '0.6',
            pl: '38px',
            py: '38px',
            pr: '60px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '12px',
            }}
          >
            <AccessTimeFilled
              sx={{
                fill: '#E15E5E',
                fontSize: 28,
                height: '34px',
                width: '34px',
              }}
            />
            <Box
              sx={{
                color: '#E15E5E',
              }}
            >
              <Box
                data-testid="modal-title"
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                Your License Expired on ({expirationDate})
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pt: '28px',
            pb: '40px',
            px: 5,
            borderTop: '1px solid #D9DAE6',
          }}
        >
          <Box
            sx={{
              color: '#828490',
            }}
          >
            You will not be able to perform any more actions. To continue
            further, please extend your license.
          </Box>
          <Box
            sx={{
              mt: 1.25,
              color: '#828490',
            }}
          >
            Please contact Avesha support <b> support@avesha.io</b> to extend
            your license with your License ID.
          </Box>
          <Box
            sx={{
              mt: '35px',
            }}
          >
            <LicenseId />
          </Box>
          <Box
            sx={{
              mt: '12px',
              color: '#828490',
            }}
          >
            Learn more about licensing{' '}
            <Link
              href={license_docs}
              sx={{
                color: '#2868F3 !important',
                '&:hover': {
                  color: '#2868F3 !important',
                },
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </Link>
          </Box>

          <Box
            sx={{
              pt: '35px',
              display: 'flex',
              gap: '14px',
              justifyContent: 'end',
            }}
          >
            <Button
              sx={{
                backgroundColor: '#fff !important',
                border: '1px solid #B6B8D6',
                textTransform: 'capitalize',
                px: '24px',
                py: '16px',
                fontWeight: '600',
                color: 'black',
              }}
              type="button"
              data-testid="cancel-button"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Link
              href={href}
              target="_blank"
              underline="none"
              sx={{
                color: '#fff',
                backgroundColor: 'transparent',
                '&:hover': {
                  color: '#fff',
                  backgroundColor: 'transparent',
                  textDecoration: 'none',
                },
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#DEE8FF',
                  border: '0px',
                  textTransform: 'capitalize',
                  px: '24px',
                  py: '16px',
                  fontWeight: '600',
                  display: 'flex',
                  gap: '8px',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#DEE8FF',
                    textDecoration: 'none',
                  },
                }}
                type="button"
              >
                <KeyWhite
                  style={{
                    height: '24px',
                    fill: '#2868F3',
                  }}
                />
                <Box
                  sx={{
                    color: '#2868F3',
                  }}
                  component="span"
                >
                  {isEnterprise ? 'Extend' : 'Upgrade'} License
                </Box>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LicenseExpiredModal;
