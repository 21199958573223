import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import {
  registerSliceRoleBinding,
  setCurrentSliceRoleList,
} from '../../../../actions/RbacActions';
import Row from '../../LandingPage/Row';
import Checked from './../../../../assets/images/Check.svg';
import LinearProgressWithLabel from './ProgressBar';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '300px',
    height: '120px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '50px',
  width: '100%',
  padding: '6px 12px',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

export const CustomTableCell = styled(TableCell)`
  padding: 15px 0px 15px 24px;
  font-family: Bai Jamjuree;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`

const GenerateYaml = () => {
  const [applyYamlDialog, setApplyYamlDialog] = React.useState(false);
  const [loaded, setLoaded] = React.useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  useEffect(() => {
    if (currentSlice) {
      const sliceRoleBindingObject = [];
      ///for importedroles
      currentSlice?.metadata?.importedData?.map((roleItem) => {
        roleItem.roleName.map((name) => {
          if (roleItem?.namespaces?.length > 0) {
            let roleRefObj = {
              apiVersion: roleItem.apiversion,
              kind: roleItem.apiKind,
              name: name,
            };
            let applyTo = [];
            roleItem.namespaces.map((data) => {
              applyTo.push({
                namespace: data.namespace,
                cluster: data.cluster,
                subjects: [
                  {
                    kind: roleItem.kind,
                    name: roleItem.name,
                  },
                ],
              });
            });
            let index = sliceRoleBindingObject.findIndex((item) => {
              return (
                item.roleRef.name === name &&
                item.roleRef.kind === roleItem.apiKind &&
                item.roleRef.apiVersion === roleItem.apiversion
              );
            });
            if (index >= 0) {
              applyTo.map((data) => {
                let idx = sliceRoleBindingObject[index].applyTo.findIndex(
                  (item) => {
                    return (
                      item?.namespace === data?.namespace &&
                      item?.cluster === data?.cluster
                    );
                  }
                );
                if (idx >= 0) {
                  sliceRoleBindingObject[index].applyTo[idx].subjects = [
                    ...sliceRoleBindingObject[index].applyTo[idx].subjects,
                    ...data.subjects,
                  ];
                } else {
                  sliceRoleBindingObject[index].applyTo.push(applyTo[0]);
                }
              });
            } else {
              sliceRoleBindingObject.push({
                applyTo: applyTo,
                roleRef: roleRefObj,
              });
            }
          }
        });
      });

      ///for choose roles
      currentSlice?.metadata?.data?.map((roleItem) => {
        roleItem.roleName.map((name) => {
          if (roleItem?.namespaces?.length > 0) {
            let roleRefObj = {
              apiVersion: roleItem.apiversion,
              kind: roleItem.apiKind,
              name: name,
            };
            let applyTo = [];

            roleItem.namespaces.map((data) => {
              applyTo.push({
                namespace: data.namespace,
                cluster: data.cluster,
                subjects: [
                  {
                    kind: roleItem.kind,
                    name: roleItem.name,
                  },
                ],
              });
            });
            let index = sliceRoleBindingObject.findIndex((item) => {
              return (
                item.roleRef.name === name &&
                item.roleRef.kind === roleItem.apiKind &&
                item.roleRef.apiVersion === roleItem.apiversion
              );
            });

            if (index >= 0) {
              applyTo.map((data) => {
                let idx = sliceRoleBindingObject[index].applyTo.findIndex(
                  (item) => {
                    return (
                      item?.namespace === data?.namespace &&
                      item?.cluster === data?.cluster
                    );
                  }
                );
                if (idx >= 0) {
                  sliceRoleBindingObject[index].applyTo[idx].subjects = [
                    ...sliceRoleBindingObject[index].applyTo[idx].subjects,
                    ...data.subjects,
                  ];
                } else {
                  sliceRoleBindingObject[index].applyTo.push(applyTo[0]);
                }
              });
            } else {
              sliceRoleBindingObject.push({
                applyTo: applyTo,
                roleRef: roleRefObj,
              });
            }
          }
        });
      });
      let updatedSlice = { ...currentSlice };
      updatedSlice.updatedBinding = sliceRoleBindingObject;
      dispatch(setCurrentSliceRoleList(updatedSlice));
    }
  }, []);

  const generatePayload = () => {
    const payload = {
      sliceName: currentSlice?.metadata?.name,
      sliceRoleBinding: currentSlice?.updatedBinding,
    };

    dispatch(registerSliceRoleBinding(payload)).then((res) => {
      history.push('/manage-rbacs/add/4');
    });
    setApplyYamlDialog(false);
  };

  const openDialog = () => {
    setApplyYamlDialog(true);
    generatePayload(currentSlice);
  };

  const closeDialog = () => {
    setApplyYamlDialog(false);
  };

  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  // useEffect(() => {
  //   if (currentSlice?.status?.kubesliceEvents?.[0]?.type === 'InProgress') {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);
  return (
    <>
      {currentSlice?.metadata?.name && (
        <>
          <Prompt
            message={(location, action) => {
              if (location.pathname.includes('manage-rbacs')) {
                return true;
              } else {
                return 'You have unsaved changes. Do you want to discard them?';
              }
            }}
          />
          <Grid item>
            <Paper elevation={5} style={{ overflow: 'auto' }}>
              <Grid
                style={{ padding: '14px 20px' }}
                container
                spacing={1}
                direction="row"
              >
                <Grid item xs={10}>
                  <Typography
                    className="step-title-header"
                    gutterBottom
                    component="div"
                  >
                    Slice
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <FinalButton
                    disabled={DisableOffboardCluster}
                    onClick={openDialog}
                    style={{
                      backgroundColor: DisableOffboardCluster
                        ? '#CCCCCC'
                        : undefined,
                    }}
                  >
                    Apply YAML
                  </FinalButton>
                </Grid>
              </Grid>
              <TableContainer className="table-container">
                <Table aria-label="collapsible table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell />
                      <CustomTableCell>SLICE NAME</CustomTableCell>
                      <CustomTableCell>TYPE</CustomTableCell>
                      <CustomTableCell>DESCRIPTION</CustomTableCell>
                      <CustomTableCell>
                        ROLES
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Row step={3} slice={currentSlice} radioDisable={true} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <CustomDialog
            open={applyYamlDialog}
            onClose={closeDialog}
            style={{ padding: '5px' }}
            aria-labelledby="This dialog is for applying yam"
            aria-describedby="This Dialog is for applying yaml"
          >
            <DialogTitle
              style={{ fontWeight: 'bold' }}
              id="delete-cluster-dialog-title"
            >
              {!loaded ? (
                <>
                  <Box p={1} sx={{ width: '100%' }}>
                    <Typography
                      style={{
                        fontFamily: 'Bai Jamjuree',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '20px',
                        lineHeight: '18px',
                        /* identical to box height, or 90% */
                        color: '#2A2747',
                      }}
                    >
                      Applying YAML
                    </Typography>
                  </Box>

                  <Box p={1} sx={{ width: '100%' }}>
                    <LinearProgressWithLabel value={100} />
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img alt="discover img" src={Checked} width="25px" />{' '}
                  </Box>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      style={{
                        fontFamily: 'Bai Jamjuree',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '14px',
                        lineHeight: '18px',
                        /* identical to box height, or 90% */
                        color: '#2A2747',
                      }}
                    >
                      YAML applied succesfully
                    </Typography>
                  </Box>
                </>
              )}
            </DialogTitle>
          </CustomDialog>
          <div className="bottom-btn-container">
            <div>
              <Button
                onClick={() => history.push('/manage-rbacs/add/2')}
                style={{ width: '100px', marginRight: '10px', top: '8%' }}
                className="btn"
                variant="contained"
              >
                <ArrowBackIosIcon style={{ fontSize: '12px' }} /> Previous{' '}
              </Button>
              <Button
                disabled={true}
                onClick={() => history.push('/manage-rbacs/add/4')}
                style={{ width: '100px', marginRight: '10px', top: '8%' }}
                className="btn"
                variant="contained"
              >
                Next <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GenerateYaml;
