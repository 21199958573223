import { ACTION_LOGOUT_INITIATED, START_SPINNER, STOP_SPINNER } from "./types";
import { PURGE } from 'redux-persist';
import { SdkBackend } from "../backend/MeshBackend";
const sdkBackend = new SdkBackend();

export const logoutUser = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: START_SPINNER });
      let response = await sdkBackend.logoutUser(
        getState(),
        dispatch,
      );
      if (response.status === true) {
        dispatch({
          type: ACTION_LOGOUT_INITIATED,
          payload: { session_token: "" },
        });
        dispatch({
          type: PURGE,
          key: "root",   
          result: () => null
        });
        localStorage.clear();
        sessionStorage.clear();
        localStorage.removeItem('persist:root')
        window.location.href = "/login"
      }
      dispatch({ type: STOP_SPINNER });
    } catch (error) {
      dispatch({ type: STOP_SPINNER });
    }
  };
};
