import {
    Grid,
  } from "@mui/material";
  import React from "react";

  export const RowContainer = ({ children, ...props }) => (
    <Grid
      height="45px"
      item
      container
      justifyContent="space-evenly"
      alignItems="center"
      px={5}
      gap={2}
      {...props}
    >
      {children}
    </Grid>
  );