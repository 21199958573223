import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNodeLabels } from '../../actions/NodeAffinity.actions';
import './index.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': {
    // width: '60%',
    maxWidth: '100%',
  },
}));

const ViewNamespacesModel = ({ open, setOpenViewModal, sliceName }) => {
  const [searchText, setSearchText] = React.useState('');
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [labels, setLabels] = useState([]);

  const node_labels = useSelector((state) => state.NodeAffinity.node_labels);
  const selectedNode_labels = useSelector(
    (state) => state.NodeAffinity.affinity_profiles
  );

  const [selectedNode, setSelectedNode] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNodeLabels(sliceName));
  }, [dispatch, sliceName]);

  useEffect(() => {
    let selected_node = [];
    const local_labels = [];
    selectedNode_labels?.forEach((profile) => {
      profile.nodeAffinityRules.forEach((item) => {
        item.nodeSelectorLabels?.forEach((node) => {
          const label = { [node.key]: node.values[0] };
          selected_node = [
            ...selected_node.filter((l_label) => {
              return !_.isEqual(l_label, label);
            }),
            label,
          ];
        });
      });
    });
    node_labels.forEach((cluster) => {
      cluster.nodeDetails.forEach((node) => {
        Object.entries(node.labels).forEach(([key, value]) => {
          const label = { [key]: value };
          const exists = local_labels.find((_label) => _label[key] === value);

          if (!exists) {
            local_labels.push(label);
          }
        });
      });
    });
    setLabels(selected_node);
  }, [selectedNode_labels]);

  const searchNodes = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const selected_node = [];

  // [selectedNode_labels[0].nodeAffinityRules[0].nodeSelectorLabels].forEach(
  //   (node) => {
  //     Object.entries(node).forEach(([key, value]) => {
  //       // console.log(value);
  //       const label = { [value.key]: value.values[0] };
  //       selected_node.push(label);
  //       // console.log("label", label);
  //     });
  //   }
  // );
  //  cluster.nodeDetails.forEach((node) => {
  //         Object.entries(node.labels).forEach(([key, value]) => {
  //           const label = { [key]: value };
  //           const exists = local_labels.find((_label) => _label[key] === value);
  //           if (!exists) {
  //             local_labels.push(label);
  //           }
  //         });

  return (
    <>
      <BootstrapDialog
        open={open}
        onClose={() => {}}
        sx={{
          '& .MuiDialog-paper': {
            // minWidth:  "80%",
            minWidth: '200px',
            // minHeight: 'auto',
            position: 'relative',
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              minWidth: '200px',
              width: '50vw',
              position: 'relative',
              height: 'fit-content',
            }}
          >
            <DialogTitle
              style={{ fontWeight: '600' }}
              id="customized-dialog-title"
            >
              <Stack>
                <span>
                  Assigned Node labels{' '}
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenViewModal(false);
                      setSearchText('');
                    }}
                    sx={{
                      float: 'right',
                      padding: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
                <Box
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: '400',
                    }}
                    component="span"
                  >
                    Slice Name:
                  </Box>
                  <Box component="span">{` ${sliceName}`}</Box>
                </Box>
                <Box
                  sx={{
                    fontSize: '14px',
                  }}
                ></Box>
              </Stack>
              {/* <div>
                <Typography className="dialog-title">
                  Total labels : {labels.length}
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenViewModal(false);
                      setSearchText('');
                    }}
                    sx={{
                      float: 'right',
                      padding: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>
                <Box
                  sx={{
                    fontWeight: '400',
                  }}
                  component="span"
                >
                  Slice Name: <Box component="span">{sliceName}</Box>
                </Box>
              </div> */}
            </DialogTitle>

            <DialogContent
              dividers
              sx={{ padding: 0, maxHeight: '80%' }}
              style={{
                paddingTop: '0%',
                padding: 0,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '40px',
                  overflow: 'hidden',
                  border: '1px solid #B6B8D6',
                  margin: 'auto',
                  borderRadius: '4px',
                  marginTop: '16px',
                  width: '92%',
                }}
              >
                <Paper
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '0 5px 5px 0',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Labels"
                    onChange={searchNodes}
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                  <IconButton
                    className="search-icon"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      height: '48vh',
                      width: '100%',
                      overflow: 'auto',
                      borderBottom: '1px solid lightgrey',
                    }}
                  >
                    <TableContainer className="table-container">
                      <Table
                        sx={{
                          minWidth: 300,
                          borderRight: '1px solid lightgrey',
                        }}
                        aria-labelledby="Namespace Table"
                        size="small"
                      >
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell
                              style={{
                                padding: '15px 20px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '10%',
                              }}
                            >
                              NO
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '15px 20px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '60%',
                              }}
                            >
                              LABEL NAME
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '30%',
                                paddingRight: '1%',
                              }}
                              align="left"
                            >
                              DESCRIPTION
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '30%',
                              }}
                              align="left"
                            >
                              {/* DESCRIPTION */}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {labels?.length > 0 ? (
                            labels
                              .filter((label) => {
                                const name = Object.keys(label)[0];
                                return name.includes(searchText); // TOdO search not working
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((label, index) => {
                                const [key, value] = Object.entries(label)[0];
                                const isselected =
                                  JSON.stringify(selectedLabel) ===
                                  JSON.stringify({ [key]: value });
                                return (
                                  <TableRow
                                    key={`${key}-${value}`}
                                    // hover
                                    // key={index}
                                    sx={{
                                      backgroundColor: isselected
                                        ? '#00048d1f'
                                        : '',
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        padding: '0px 20px',
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#12153E',
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '12px 0px 12px 20px',
                                        /*lineHeight: '20px',*/
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 600,
                                          fontSize: '16px',
                                          color: '#12153E',
                                          lineHeight: '20px',
                                        }}
                                      >
                                        {`${key}:${value}`}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '12px 0px 12px 20px',
                                        /*lineHeight: '20px',*/
                                      }}
                                    >
                                      <Button
                                        style={{
                                          color: '#2868F3',
                                          textTransform: 'none',
                                          fontWeight: '600',
                                        }}
                                        onClick={() => {
                                          setSelectedLabel(label);
                                        }}
                                      >
                                        View
                                      </Button>
                                    </TableCell>
                                    <TableCell />
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={3}>
                                No Nodes to display
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={labels.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </DialogContent>
          </Box>
          {/* <h1>hello</h1> */}
          {selectedLabel && (
            <Box
              style={{
                maxWidth: '50%',
                width: '50%',
              }}
            >
              <div
                style={{
                  display: selectedLabel !== '' ? 'flex' : 'none',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <DialogTitle
                  id="scroll-dialog-title"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '25px 10px ',
                  }}
                >
                  <Typography className="dialog-title">Nodes</Typography>
                  <Button
                    style={{
                      color: '#2868F3',
                      textTransform: 'none',
                      fontWeight: '600',
                    }}
                    onClick={() => {
                      setSelectedLabel(null);
                    }}
                  >
                    Hide
                  </Button>
                </DialogTitle>
                <Box
                  sx={{
                    border: '1px solid #F3F3F3',
                    borderRadius: '2px',
                    padding: '0 10px',
                    display: 'flex',
                    maxHeight: '60vh'
                  }}
                >
                  <TableContainer className="table-container">
                    <Table
                      sx={{
                        minWidth: 300,
                      }}
                      size="small"
                    >
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell
                            style={{
                              padding: '15px 20px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '12px',
                              lineHeight: '15px',
                              color: '#12153E',
                              width: '0%',
                            }}
                          />
                          <TableCell
                            style={{
                              padding: '15px 20px',
                              fontFamily: 'Bai Jamjuree',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: '15px',
                              color: '#12153E',
                              width: '60%',
                            }}
                          >
                            Node Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {node_labels.map((cluster) => {
                          return cluster.nodeDetails.map((node, idx) => {
                            const [key, value] =
                              Object.entries(selectedLabel)[0];
                            if (node.labels[key] === value) {
                              const isNodeSelected =
                                selectedNode && selectedNode === node.name;
                              return (
                                <>
                                  <TableRow key={idx}>
                                    <TableCell
                                      style={{
                                        padding: '0px 20px',
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#12153E',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => {
                                          if (
                                            !selectedNode ||
                                            selectedNode !== node.name
                                          ) {
                                            setSelectedNode(node.name);
                                          } else {
                                            setSelectedNode(null);
                                          }
                                        }}
                                      >
                                        {isNodeSelected ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '12px 0px 12px 20px',
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 600,
                                          fontSize: '16px',
                                          color: '#12153E',
                                          lineHeight: '20px',
                                          textOverflow: 'ellipsis',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {node.name}
                                        <Typography
                                          style={{
                                            fontFamily: 'Bai Jamjuree',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            color: '#5A607C',
                                            lineHeight: '17.5px',
                                          }}
                                        >
                                          {`${cluster.clusterName ?? ''}`}
                                        </Typography>
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                  {isNodeSelected ? (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          transition: '0.3s ease-in-out',
                                        }}
                                        colSpan={2}
                                      >
                                        <Collapse
                                          in={selectedNode}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <div
                                            style={{
                                              background: '#F3F3F3',
                                              border: '1px solid #F3F3F3',
                                              borderRadius: '2px',
                                              padding: '10px',
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                paddingLeft: 8,
                                              }}
                                            >
                                              {node.allocatable ? (
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      color: 'blue',
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    allocatable:
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      px: 2,
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      node.allocatable
                                                    ).map(([key, value]) => (
                                                      <Box>
                                                        <Box
                                                          component="span"
                                                          sx={{
                                                            color: 'blue',
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {' '}
                                                          {key}
                                                        </Box>
                                                        : {`"${value}"`}
                                                      </Box>
                                                    ))}
                                                  </Box>
                                                </Box>
                                              ) : null}
                                              {node.capacity ? (
                                                <Box>
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      color: 'blue',
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    capacity:
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      px: 2,
                                                    }}
                                                  >
                                                    {Object.entries(
                                                      node.capacity
                                                    ).map(([key, value]) => (
                                                      <Box>
                                                        <Box
                                                          component="span"
                                                          sx={{
                                                            color: 'blue',
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {' '}
                                                          {key}
                                                        </Box>
                                                        : {`"${value}"`}
                                                      </Box>
                                                    ))}
                                                  </Box>
                                                </Box>
                                              ) : null}
                                            </Stack>
                                          </div>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  ) : null}
                                </>
                              );
                            }
                            return null;
                          });
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            </Box>
          )}
        </Box>
      </BootstrapDialog>
    </>
  );
};

export default ViewNamespacesModel;
