import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'underscore';
import { selectAffinityProfiles } from '../../../selectors/NodeAffinity.selector';
import ViewNamespacesModel from '../Generic/ViewNamespacesModel';
import './index.css';

export default function SliceTable() {
  const currentSlice = useSelector((state) => state.NodeAffinity.currentSlice);
  const [openModal, setOpenViewModal] = useState(false);

  const node_affinity_list = useSelector(
    (state) => state.NodeAffinity.node_affinity_list
  );
  const hasNodeAffinity = node_affinity_list.some(
    (affinity) => affinity.metadata.name === currentSlice.metadata.name
  );
  useEffect(() => {
    const currentSliceData = currentSlice?.metadata?.name;
    localStorage.setItem('currentSlice', currentSliceData);

    return () => {
      localStorage.removeItem('currentSlice');
    };
  }, [currentSlice?.metadata?.name]);

  // localStorage.setItem('currentSlice', currentSlice.metadata.name);
  const selectedNode_labels = useSelector(selectAffinityProfiles);
  const node_labels = useSelector((state) => state.NodeAffinity.node_labels);

  const labels = useMemo(() => {
    let selected_node = [];
    const local_labels = [];
    selectedNode_labels?.forEach((profile) => {
      profile.nodeAffinityRules.forEach((item) => {
        item.nodeSelectorLabels?.forEach((node) => {
          const label = { [node.key]: node.values[0] };
          selected_node = [
            ...selected_node.filter((l_label) => {
              return !_.isEqual(l_label, label);
            }),
            label,
          ];
        });
      });
    });
    node_labels.forEach((cluster) => {
      cluster.nodeDetails.forEach((node) => {
        Object.entries(node.labels).forEach(([key, value]) => {
          const label = { [key]: value };
          const exists = local_labels.find((_label) => _label[key] === value);

          if (!exists) {
            local_labels.push(label);
          }
        });
      });
    });
    return selected_node;
  }, [node_labels, selectedNode_labels]);

  return (
    <>
      {openModal && (
        <ViewNamespacesModel
          open={openModal}
          setOpenViewModal={setOpenViewModal}
          sliceName={currentSlice.metadata.name}
          labels={labels}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography
          style={{
            paddingLeft: '24px',
            paddingTop: '15px',
            fontFamily: 'Bai Jamjuree',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '30px',
            color: '#12153E',
          }}
          className="step-title-header"
          gutterBottom
          component="div"
        >
          Slice
        </Typography>
        <TableContainer className="table-container">
          <Table
            sx={{ minWidth: 300 }}
            aria-labelledby="Namespace Table"
            size="small"
          >
            <TableHead className="table-head">
              <TableRow style={{ height: '45px' }}>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  SLICE NAME
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  TYPES
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  NODES LABELS
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: '24px',
                    fontFamily: 'Bai Jamjuree',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '15px',
                    color: '#12153E',
                  }}
                >
                  DESCRIPTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ borderStyle: 'hidden' }}>
                <TableCell
                  style={{
                    padding: '24px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#12153E',
                  }}
                >
                  {currentSlice?.metadata?.name}
                </TableCell>
                <TableCell
                  style={{
                    padding: '24px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#12153E',
                  }}
                >
                  Application
                </TableCell>
                <TableCell
                  style={{
                    padding: '24px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#12153E',
                  }}
                >
                  {hasNodeAffinity ? (
                    <Link
                      underline="hover"
                      style={{ color: '#2868F3', cursor: 'pointer' }}
                      onClick={() => {
                        setOpenViewModal(true);
                      }}
                    >
                      View all
                    </Link>
                  ) : (
                    'No Labels Assigned'
                  )}
                </TableCell>
                <TableCell
                  style={{
                    padding: '24px',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#12153E',
                  }}
                >
                  {currentSlice?.metadata?.annotations?.desc}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
