import {
  SET_SLICE_LIST,
  SET_CURRENT_SLICE,
  DELETE_SLICE,
  CLEAR_CURRENT_SLICE,
  EDIT_SLICE,
  SET_SLICE_RESOURCE_VERSION,
  ACTION_LOGOUT_INITIATED,
  SET_MIGRATION_PAIR,
  SET_MIGRATION_JOB,
} from '../actions/types';

const currentSliceInitialState = {
  sliceName: '',
  sliceDisplayName: '',
  sliceType: '',
  sliceGWType: '',
  sliceCertAuthType: '',
  sliceQosType: '',
  sliceDesc: '',
  clusters: [],
  maxClusters: '',
};
const initialState = {
  sliceList: [],
  editSlice: false,
  resourceVersion: '',
  currentSlice: currentSliceInitialState,
  clusterList: [],
  pair:[],
  job:[]
};

export default function ConnectClusterReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SLICE_LIST:
      return {
        ...state,
        sliceList: action.payload,
      };
    case SET_MIGRATION_PAIR:
        return {
          ...state,
          pair: action.payload,
        };
    case SET_MIGRATION_JOB:
          return {
            ...state,
            job: action.payload,
          };
    case SET_CURRENT_SLICE:
      return {
        ...state,
        currentSlice: action.payload,
        kubesliceEvents: action.payload,
      };
    case DELETE_SLICE:
      const slices = state.sliceList.filter(
        (slice) => slice?.metadata?.name !== action.payload
      );
      return {
        ...state,
        sliceList: slices,
      };
    case CLEAR_CURRENT_SLICE:
      return {
        ...state,
        currentSlice: currentSliceInitialState,
      };
    case SET_SLICE_RESOURCE_VERSION:
      return {
        ...state,
        resourceVersion: action.payload,
      };
    case EDIT_SLICE:
      return {
        ...state,
        editSlice: action.payload,
      };
    case ACTION_LOGOUT_INITIATED:
      return { ...initialState };
    default:
      return state;
  }
}
