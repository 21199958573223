import {
  CLEAR_CURRENT_RESOURCE_QUOTA,
  SET_CURRENT_RESOURCE_QUOTA,
  SET_DELETE_RQ,
  SET_ORIGINAL_CURRENT_RESOURCE_QUOTA,
  SET_RESOURCE_QUOTA_LIST,
  SET_UPDATED_CLUSTER_QUOTA,
} from '../actions/types';

const initialState = {
  resourceQuotaList: [],
  currentResourceQuota: {},
  originalResourceQuota: {},
  edited_cluster_quota: null,
  should_delete_rq: false,
};

export default function RQReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_QUOTA_LIST:
      return {
        ...state,
        resourceQuotaList: action.payload.items,
      };
    case SET_CURRENT_RESOURCE_QUOTA:
      return {
        ...state,
        currentResourceQuota: action.payload,
      };
    case SET_ORIGINAL_CURRENT_RESOURCE_QUOTA:
      return {
        ...state,
        originalResourceQuota: action.payload,
      };
    case CLEAR_CURRENT_RESOURCE_QUOTA:
      return {
        ...state,
        currentResourceQuota: {},
      };
    case SET_UPDATED_CLUSTER_QUOTA:
      return {
        ...state,
        edited_cluster_quota: action.payload,
      };
    case SET_DELETE_RQ:
      return {
        ...state,
        should_delete_rq: action.payload,
      };
    default:
      return state;
  }
}
