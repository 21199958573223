import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import { ReactComponent as KubeSlice } from "../../../../assets/images/Kubeslice.svg";

export default class Fallback extends Component {
  render() {
    return (
      <Grid
        item
        xs
        container
        justifyContent="center"
        alignItems="center"
        width="60%"
        style={{paddingTop: '10%' }}
        flexWrap="nowrap"
        flexDirection="column"
        alignSelf="center"
      >
    {/* //   <div
    //   style={{
    //     position: "relative",
    //     // left: "50%",
    //     textAlign:'center',
    //     top: "50%",
    //     transform: "translate(-50%, -50%)",
    //   }}
    // > */}
        <KubeSlice />
        <Typography fontSize="24px" fontWeight="400">
          You have not selected any Namespaces.
        </Typography>
         {/* </div> */}
      </Grid>
    );
  }
}
