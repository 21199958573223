import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import {
  clearCurrentSelectSliceRoleList,
  setCurrentSliceRoleList,
} from "../../../../actions/RbacActions";
import { ReactComponent as Error } from "../../LandingPage/Error.svg";
import AlertDialog from "./AlertDialog";
import "./index.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultInputObject = {
  user_or_group: "",
  name: "",
  namespace: [],
  roles: [],
  name_error: false,
  roles_error: false,
  user_or_group_error: false,
  touched: false,
};
export default function SelectRoles({ setIsSelectRolesValid }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [Delindex, setDelIndex] = useState();
  const [delMessage, setDelMessage] = useState();
  const currentSlice = useSelector(
    (state) => state.RbacReducer.currentSliceRoleList
  );
  const k8Status = useSelector(
    (state) =>
      state?.RbacReducer?.currentSliceRoleList?.metadata?.status
        ?.roleRefConditions
  );
  const currentRoles = useSelector((state) => state.RbacReducer.roles);

  const dispatch = useDispatch();

  useEffect(() => {
    let updatedslice = { ...currentSlice };
    updatedslice.metadata.data = [];
    dispatch(setCurrentSliceRoleList(updatedslice));
    setIsSelectRolesValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const [isFormChanged, setIsFormChanged] = useState(false);
  const [roles, setRoles] = useState(() => {
    if (currentSlice.metadata?.bindings?.length === 0) {
      return [defaultInputObject];
    }
    const bindings = currentSlice.metadata?.bindings;
    let tempRoles = [];
    if (!bindings || bindings.length === 0) {
      return [defaultInputObject];
    }
    bindings.forEach((binding) => {
      const { roleRef, applyTo } = binding;
      applyTo.forEach((item) => {
        let namespace = item.namespace;
        let cluster=item.cluster;
        item.subjects?.forEach((subject) => {
          if (currentRoles.some((role) => role.name === roleRef.name)) {
            if (
              tempRoles.some(
                (role) =>
                  role.user_or_group === subject.kind &&
                  role.name === subject.name
              )
            ) {
              tempRoles = tempRoles.map((role) => {
                if (
                  role.user_or_group === subject.kind &&
                  role.name === subject.name
                ) {
                  const differentNamespace =
                    //!role.namespace.includes({namespace:namespace,cluster:cluster});
                    !role.namespace.some(function(element) {
                      return JSON.stringify(element) === JSON.stringify({namespace:namespace,cluster:cluster});
                    })

                  const new_role = {
                    ...role,
                    ...(differentNamespace
                      ? { namespace: [...role.namespace, {namespace:namespace,cluster:cluster}] }
                      : { namespace: [...role.namespace] }),
                    roles: [
                      ...role.roles.filter(
                        (role) => role.name !== roleRef.name
                      ),
                      currentRoles.filter(
                        (role) => role.name === roleRef.name
                      )[0],
                    ],
                  };
                  return new_role;
                }
                return role;
              });
            } else {
              tempRoles.push({
                ...defaultInputObject,
                user_or_group: subject.kind,
                name: subject.name,
                namespace: [{namespace:item?.namespace,cluster:item?.cluster}] ?? [],
                roles: currentRoles.filter(
                  (role) => role.name === roleRef.name
                ),
              });
            }
          }
        });
      });
    });
    if (tempRoles.length === 0) {
      return [defaultInputObject];
    }
    
    tempRoles = tempRoles.map(role => {
      const backendErrors = k8Status?.filter(status => {
        return  role?.roles.some(r => r?.name === status?.roleRef?.name )
      })
      return {
        ...role,
        backendErrors
      }
    })

    return tempRoles;
  });

  const addBinding = () => {
    setRoles(() => [...roles, defaultInputObject]);
  };

  useEffect(() => {
    dispatch(clearCurrentSelectSliceRoleList());
  }, [dispatch]);

  const [openModel, setOpenModel] = React.useState(false);

  const handleModalClose = (del, i) => {
    if (del) {
      if (i === 0 && !(i > 0) && roles.length <= 1) {
        removeTempRoles(i);
        setOpenDeleteModal(false);
        setRoles([defaultInputObject]);
        setOpenDeleteModal(false);
      } else removeTempRoles(i);
      setOpenDeleteModal(false);
    } else {
      setOpenDeleteModal(false);
      setOpenModel();
    }

    // if (del) {
    //   removeTempRoles(i);
    //   setOpenDeleteModal(false);
    // } else {
    //   setOpenDeleteModal(false);
    //   setOpenModel();
    // }
  };

  const removeTempRoles = (index) => {
    const rows = roles.filter((_, idx) => idx !== index);
    setRoles(rows);

    // setRoles(() => {
    //   return roles.filter((role, idx) => idx !== index);
    // });
  };

  const validate = ({ name, user_or_group, roles }) => {
    let error = {
      name_error: false,
      roles_error: false,
      user_or_group_error: false,
    };

    if (!name) {
      error.name_error = true && "Enter name";
    }

    if (!user_or_group) {
      error.user_or_group_error = true && "Enter User/Group";
    }

    if (!roles.length) {
      error.roles_error = true && "Enter Roles";
    }

    return error;
  };

  // added default values  to validate when remove button is clicked
  const validationsForNextBtn = (new_rules = roles) => {
    let isInvalid = new_rules.some((role) => {
      if (role.name_error || role.roles_error || role.user_or_group_error) {
        return true;
      }
      return false;
    });

    if (isInvalid) {
      setIsSelectRolesValid(false);
    } else {
      setIsSelectRolesValid(true);
    }
  };

  const handleChange = (event, i) => {
    const { name, value } = event.target;

    const new_roles = roles.map((role, idx) => {
      if (idx === i) {
        let errors = validate({ ...role, [name]: value });

        isFormChanged === false && setIsFormChanged(true);
        return {
          ...role,
          [name]: value,
          touched: true,
          ...errors,
        };
      }
      return role;
    });

    setRoles(() => new_roles);
    validationsForNextBtn(new_roles);
  };

  const handleRolesChange = (value, i) => {
    const new_roles = roles.map((role, idx) => {
      if (idx === i) {
        let errors = validate({ ...role, roles: value });
        isFormChanged === false && setIsFormChanged(true);
        return {
          ...role,
          roles: value,
          roles_touched: true,
          ...errors,
        };
      }
      return role;
    });

    setRoles(() => new_roles);
    validationsForNextBtn(new_roles);
  };

  useEffect(() => {
    const roles_by_role_templates = {};
    roles.forEach((role) => {
      role.roles.forEach(({ name }) => {
        roles_by_role_templates[name] = [
          ...(roles_by_role_templates[name]
            ? roles_by_role_templates[name]
            : []),
          role,
        ];
      });
    });
    const bindings = [];

    for (const role_template in roles_by_role_templates) {
      const roles = roles_by_role_templates[role_template];
      const tempRole = {
        applyTo: [],
        roleRef: {
          apiVersion: "controller.kubeslice.io/v1alpha1",
          kind: "SliceRoleTemplate",
          name: role_template,
        },
      };
      roles.forEach((role) => {
        if (role.namespace.length === 0) {
          tempRole.applyTo.push({
            namespace: "",
            cluster:"",
            subjects: [
              {
                kind: role.user_or_group,
                name: role.name,
              },
            ],
          });
        } else {
          role.namespace.forEach((data, idx) => {
            tempRole.applyTo.push({
              namespace :data?.namespace,
              cluster: data?.cluster,
              subjects: [
                {
                  kind: role.user_or_group,
                  name: role.name,
                },
              ],
            });
          });
        }
      });
      bindings.push(tempRole);
    }
    const tempSlice = {
      ...currentSlice,
      metadata: {
        ...currentSlice.metadata,
        bindings,
      },
    };

    dispatch(setCurrentSliceRoleList(tempSlice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const delRole = (idx, message) => {
    if (idx === 0 && !(idx > 0) && roles.length <= 1 && message === "") {
      setRoles([defaultInputObject]);
    }
    if (message === "" && idx >= 0 && roles.length > 1) {
      removeTempRoles(idx);
      // removeRoles(i);
    } else if (idx >= 0 && message !== "") {
      setDelIndex(idx);
      setDelMessage(message);
      setOpenDeleteModal(true);
    }

    // setDelIndex(idx);
    // setDelMessage(message);
    // setOpenDeleteModal(true);
  };

  // const roles_to_map_over = roles.length > 0 ? roles : [defaultInputObject];
  const getErrorMessage = (item) => {
    if (item.backendErrors?.length) {
      return (
        <List
          sx={{
            '& ul': { padding: 0 },
            listStyleType: 'disc',
          }}
          subheader={<li />}
        >
          {item.backendErrors?.map((error, idx) => (
            <ListItem key={idx}>{error.message}</ListItem>
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <TableContainer className="table-container">
        <Prompt
          message={(location, action) => {
            if (isFormChanged) {
              if (
                location.pathname.includes("manage-rbacs") &&
                location.pathname.includes("/manage-rbac")
              ) {
                return true;
              } else {
                return "You have unsaved changes. Do you want to discard them?";
              }
            } else if (
              location.pathname.includes("manage-rbacs") ||
              location.pathname.includes("/manage-rbac")
            ) {
              return true;
            } else {
              return "You have unsaved changes. Do you want to discard them?";
            }
          }}
        />
        <Table
          sx={{ minWidth: 300 }}
          aria-labelledby="Namespace Table"
          size="small"
        >
          <TableHead className="table-head">
            <TableRow style={{ height: "45px" }}>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                  minWidth: '20%',
                }}
              >
                USER / GROUP
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                NAME
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              >
                ROLES
              </TableCell>
              <TableCell
                style={{
                  padding: "15px 0px 15px 24px",
                  fontFamily: "Bai Jamjuree",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#12153E",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {roles.map((role, idx) => {
              return (
                <TableRow style={{ borderStyle: "hidden" }} key={idx}>
                  <AlertDialog
                    open={openDeleteModal}
                    handleClose={(a) => handleModalClose(a, Delindex)}
                    groupName={delMessage}
                  ></AlertDialog>
                  <TableCell
                    style={{
                      padding: '24px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#12153E',
                      minWidth: '20%',
                      display: 'flex',
                      border: 'none',
                      alignItems: 'center',
                    }}
                  >
                    { role.backendErrors?.length > 0 ? (
                      <Typography paddingRight={'8px'}>
                        <Tooltip title={getErrorMessage(role)}>
                          <Error></Error>
                        </Tooltip>
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          width: '24px',
                          height: '8px',
                          display: 'inline-block',
                        }}
                      ></Box>
                    )}

                    <Box sx={{ minWidth: 120, flex: '1 1 0%' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select User/Group
                        </InputLabel>
                        <Select
                          error={role.user_or_group_error}
                          helperText={role.user_or_group_error}
                          fullWidth="true"
                          name="user_or_group"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={role.user_or_group}
                          label="Select User/Group"
                          onChange={(e) => handleChange(e, idx)}
                        >
                          <MenuItem value="User">User</MenuItem>
                          <MenuItem value="Group">Group</MenuItem>
                        </Select>
                        {/* 
                        <FormHelperText>Error</FormHelperText> */}
                      </FormControl>
                    </Box>
                    {role.user_or_group_error && role?.user_or_group === "" && (
                      <Typography color={"red"} fontSize={"12px"}>
                        Enter User/Group
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box sx={{ minWidth: 120 }}>
                      <TextField
                        error={role.name_error}
                        helperText={role.name_error}
                        id="outlined-basic"
                        label="Enter name"
                        name="name"
                        fullWidth={true}
                        variant="outlined"
                        value={role.name}
                        onChange={(evnt) => handleChange(evnt, idx)}
                      />
                    </Box>
                    {/* {role?.name === "" && (
                      <Typography color={"red"} fontSize={"12px"}>
                        {" "}
                        Enter name{" "}
                      </Typography>
                    )} */}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "0",
                      padding: "24px",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          //  helperText={rolesErr}
                          // name="roles"
                          // value={listItem.roleRef.name}
                          multiple
                          defaultValue={role.roles}
                          // value={state.selectedOptions}
                          limitTags={2}
                          id="checkboxes-tags-demo"
                          name="roles"
                          ListboxProps={currentRoles}
                          options={currentRoles}
                          disableCloseOnSelect={true}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option.name === value.name
                          }
                          value={role.roles}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                name={`roles_${idx}`}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}
                          onChange={(_, value) => {
                            handleRolesChange(value, idx);
                          }}
                          style={{ width: 530 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={role.roles_error}
                              helperText={role.roles_error}
                              label="Select roles"
                              // placeholder="Select roles"
                            />
                          )}
                        />
                      </FormControl>
                      {/* {role.roles.length === 0 && (
                        <Typography color={"red"} fontSize={"12px"}>
                          {" "}
                          Enter roles{" "}
                        </Typography>
                      )} */}
                      {/* {listItem?.roles_error && listItem.touched && (
                          <InputLabel> Invalid Role </InputLabel>
                        )} */}
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "0",
                      padding: "24px",
                      // boxShadow: 'inset 1px -1px 0px #D9DFEE',
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#12153E",
                    }}
                  >
                    <div style={{display:'flex'}}>
                    {
                      // roles.length - 1 === idx &&
                      role.roles.length !== 0 &&
                        role?.name !== "" &&
                        role?.user_or_group !== "" &&
                        roles.length - 1 === idx && (
                          <IconButton
                            aria-label="add"
                            size="small"
                            color="primary"
                            onClick={addBinding}
                          >
                            <AddCircleIcon className="AddCircleIcon" />
                          </IconButton>
                        )
                    }
                    {roles.length > 0 && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => delRole(idx, role.name)}
                        // onClick={() => setOpenDeleteModal(idx, role.name)}

                        // onClick={(role) => setOpenDeleteModal(true, idx, role)}
                      >
                        <CancelIcon
                        // onClick={() => removeTempRoles(idx, role)}
                        />
                      </IconButton>
                    )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
