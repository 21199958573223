import React from "react";
import Add from "@mui/icons-material/Add";
import styled from "styled-components";
import Close from "@mui/icons-material/Close";
const Header = styled.div`
  height: 3rem;
  width: 40rem;
  display: flex;
  background-color: #e3e3e3;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px silver;
  border-radius: 0.2rem;
  padding-left: 1rem;
  justify-content: space-between;
  margin-top: 1rem;
`;
const RuleBox = styled.div`
  width: 40rem;
  padding: 1rem;
  background-color: #e3e3e3;
  box-sizing: border-box;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 5px silver;
  border-radius: 0.2rem;
  color: #6a6a6a;
  margin-bottom: 1rem;
`;
const ListContainer = styled.div`
  box-sizing: border-box;
  max-height: 15rem;
  overflow-y: auto;
  /* padding: 1rem; */
  margin-top: 1rem;
  overflow-x: hidden;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: grey;
  }
`;
export default function EnforcementRuleList({ list, onAdd, onDelete }) {
  return (
    <div>
      <Header>
        <div>Enforcement Point Profiles</div>
        <Add onClick={onAdd} />
      </Header>
      <ListContainer>
        {list?.map((rule, index) => (
          <RuleBox>
            <div>{rule.kind}</div>
            <Close onClick={() => onDelete(index)} />
          </RuleBox>
        ))}
      </ListContainer>
    </div>
  );
}
