import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  clearCurrentSlice,
  getSliceList,
} from '../../actions/NodeAffinity.actions';
import NoSlice from '../../assets/images/no-slice-namespace.png';
import {
  selectCurrentSlice,
  selectHasAffinity,
} from '../../selectors/NodeAffinity.selector';
import GeneralLayout from '../Commons/GeneralLayout';
import SearchInput from '../Commons/SearchInput';
import SliceRow from './SliceRow';
import styles from './style.module.css';

const TableCellCustom = styled(TableCell)`
  padding-top: '15px';
  padding-bottom: '15px';
  padding-left: '24px';
  line-height: '15px';
  font-size: '12px';
  font-weight: '600';
`;

const NodeAffinitySliceList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [selectedSlice, setSelectedSlice] = useState(null);
  const [search, setSearch] = useState('');

  const slices = useSelector((state) => state.NodeAffinity.slice_list);
  const currentSlice = useSelector((state) => selectCurrentSlice(state));
  const hasNodeAffinity = useSelector((state) => selectHasAffinity(state));

  const assignNode = () => {
    history.push(`${location.pathname}/1`);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    dispatch(clearCurrentSlice());
    dispatch(getSliceList());
  }, [dispatch]);
  const handleRedirectToNewUi = (redirect_uri) => {
    window.location.href = `/${redirect_uri}`;
  };

  console.log(selectedSlice)
  return (
    <GeneralLayout description={'Node Assignment'}>
      <Paper className={'custom-table_container'}>
        <div className={styles.table_heading__container}>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                margin: '24.5px 0px 24.5px 16px',
              }}
            >
              Slices
            </Typography>
            <SearchInput onChange={handleSearch} value={search} />
          </Box>
          <button
            className={styles.assign_nodes__btn}
            disabled={!currentSlice}
            onClick={assignNode}
            data-testid="btn-assign-node"
          >
            {hasNodeAffinity ? 'Edit Node Labels' : 'Assign Node Labels'}
          </button>
        </div>
        <TableContainer className="table-container">
          <Table aria-label="collapsible table">
            <TableHead className="table-head">
              <TableRow>
                <TableCellCustom />
                <TableCellCustom>SLICE NAME</TableCellCustom>
                <TableCellCustom>TYPE</TableCellCustom>
                <TableCellCustom>NODE LABELS</TableCellCustom>
                <TableCellCustom>DESCRIPTION</TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              {slices?.length > 0 ? (
                slices
                  .filter((slice) => slice.metadata.name.includes(search))
                  .map((slice, idx) => (
                    <SliceRow
                      key={idx}
                      slice={slice}
                      selectSlice={(slice) => setSelectedSlice(slice)}
                      selected={selectedSlice === slice}
                    />
                  ))
              ) : (
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell colSpan={6}>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={NoSlice}
                        width="150"
                        height="150"
                        alt="No slice for namespace"
                      />
                    </div>
                    {/* <Typography style={{ textAlign: 'center' }}>
                      Create Slices under Slices and onboard namespaces
                    </Typography> */}
                    <Typography
                      className="no-slice-txt-isolated"
                      variant="subtitle2"
                      component="div"
                      style={{ textAlign: 'center' }}
                    >
                      Create Slices and onboard Namespaces before defining Node
                      Affinity
                    </Typography>
                    <Box
                      sx={{
                        marginTop: '24px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        style={{ width: 'auto' }}
                        onClick={() => handleRedirectToNewUi('slices')}
                        className="btn"
                        variant="contained"
                      >
                        Go to Slices
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </GeneralLayout>
  );
};

export default NodeAffinitySliceList;
