import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'underscore';
import { setCurrentSliceAffinity } from '../../../actions/NodeAffinity.actions';
import {
  selectAffinityProfiles,
  selectCurrentSlice,
  selectNodeLabels,
  selectSliceClusters,
} from '../../../selectors/NodeAffinity.selector';
import { FooterBtns } from '../FooterBtns';
import InitialStep from './InitialStep';
import SliceTable from './SliceTable';

export default function AssignToSlice() {
  const history = useHistory();
  const affinity_profiles = useSelector(selectAffinityProfiles);
  const currentSlice = useSelector(selectCurrentSlice);
  const node_labels = useSelector(selectNodeLabels);
  const dispatch = useDispatch();
  const clusters = useSelector(selectSliceClusters);

  const clusterHasLabel = (clusterName, rule) => {
    const cluster = node_labels.find(
      (cluster) => cluster.clusterName === clusterName
    );
    const { nodeDetails } = cluster; // nodeDetails will always exist
    const label_key = rule.key;
    const label_value = rule.values[0];

    return nodeDetails.some((node) => {
      return node.labels[label_key] === label_value;
    });
  };

  const handleNext = () => {
    // any changes you do to this function, make the appropriate changes to step 2 next as well
    // This is done in two places to preserve the state even if user skips step two
    const asterisk_cluster = affinity_profiles.find(
      (profile) => profile.cluster === '*'
    );

    if (asterisk_cluster) {
      const asterisk_namespace = asterisk_cluster.nodeAffinityRules.find(
        (rule) => rule.namespace === '*'
      );
      let cluster_affinities = affinity_profiles.filter(
        (profile) => profile.cluster !== '*'
      );

      if (cluster_affinities.length !== clusters.length) {
        clusters.forEach((cluster) => {
          if (
            !cluster_affinities.find(
              (a_cluster) => a_cluster.cluster === cluster
            )
          ) {
            cluster_affinities.push({
              cluster,
              nodeAffinityRules: [
                {
                  namespace: '*',
                  nodeSelectorLabels: [],
                },
              ],
            });
          }
        });
      }

      dispatch(
        setCurrentSliceAffinity({
          ...currentSlice,
          spec: {
            ...(currentSlice.spec ?? {}),
            nodeAffinityProfiles: cluster_affinities.map((profile) => {
              return {
                ...profile,
                nodeAffinityRules: profile.nodeAffinityRules.map((rule) => {
                  if (rule.namespace === '*' && asterisk_namespace) {
                    let unique_labels = [...(rule.nodeSelectorLabels ?? [])];
                    asterisk_namespace.nodeSelectorLabels?.forEach((label) => {
                      // first condition: only add if the label exists in cluster
                      // did this in multiple steps for readibility
                      if (clusterHasLabel(profile.cluster, label)) {
                        // second condition: only add if not duplicate
                        if (!unique_labels.find((l) => _.isEqual(l, label))) {
                          unique_labels.push(label);
                        }
                      }
                    });

                    return {
                      ...rule,
                      // TODO: append only if nodes exists in this cluster
                      nodeSelectorLabels: unique_labels,
                    };
                  }
                  return rule;
                }),
              };
            }),
          },
        })
      );
    }

    history.push(`/assign-nodes/2`);
  };

  const isNextDisabled = !affinity_profiles.some((profile) => {
    return profile.nodeAffinityRules?.some(
      (rule) => rule.nodeSelectorLabels?.length > 0
    );
  });

  return (
    <>
      <Box sx={{ margin: '0 40px', overflow: 'auto', flex: '1 1 0%' }}>
        <Grid className="grid-item-main step-grid-item" item xs={12}>
          <SliceTable />
        </Grid>
        <Box className="grid-item-main step-grid-item" item xs={12}>
          <InitialStep />
        </Box>
      </Box>
      <FooterBtns
        data-testid="Step1Next"
        handleNext={handleNext}
        nextDisabled={isNextDisabled}
      />
    </>
  );
}
