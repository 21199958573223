import { FormControlLabel, Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  setActiveSelectedCloud,
  setActiveSelectedSlice,
} from '../../actions/dashboardActions';
import { SLICE_COMPARE_KEY } from '../../constants/enums';
import DeploymentMap from '../DeployMap/DeploymentMap';
import { IOSSwitch } from '../IsolateNamespaces/Steps/InitialStep/SliceTable';
import ClusterTab from './ClusterTab';
import Health from './Health';
import MetricsDropdown from './MetricsDropdown';
import MetricsTab from './MetricsTab';
import NodesView from './NodesView';
import ResourceQuota from './ResourceQuota';
import ServiceDependency from './ServiceDependency';
import TopologicalView from './TopologicalView';

const serviceStyle = {
  position: 'relative',
  textAlign: 'center',
  minHeight: '52vh',
  width: '100%',
};
const StyledTabs = styled((props) => (
  <Tabs
    variant="scrollable"
    scrollButtons="auto"
    aria-label="scrollable auto tabs example"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    sx={{ minHeight: '20px' }}
  />
))({
  gap: '24px',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    borderRadius: '3px',
    backgroundColor: '#2868F3',
  },
  '& .MuiTabs-flexContainer': {
    gap: '24px',
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '16px',
  minWidth: '0px',
  paddingLeft: '0',
  paddingRight: '0',
  paddingBottom: '8px',
  paddingTop: '0px',
  minHeight: '20px',
  color: '#81839A',
  '&.Mui-selected': {
    color: '#2868F3',
    fontWeight: '600',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box pt={3} pr={3} pb={3} pl={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const mapStateToProps = (state) => {
  return {
    sliceData: state.dashboard.slices,
    activeSelectedSlice: state.dashboard.activeSelectedSlice,
    cloudData: state.dashboard.cloudNames,
    activeSelectedCloud: state.dashboard.activeSelectedCloud,
  };
};

export default connect(mapStateToProps, {
  setActiveSelectedSlice,
  setActiveSelectedCloud,
})(function TabPanelDashboard({ value, onChange, ...storeProps }) {
  const [logicalView, setlogicalView] = useState(false);
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={serviceStyle}>
      <AppBar
        position="static"
        color="inherit"
        elevation={0}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingRight: '24px',
          paddingLeft: '24px',
          display: 'flex',
          paddingBottom: '10px',
          paddingTop: '16px',
          alignItems: 'center',
          borderRadius: '10px',
        }}
      >
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Deployment Map"
            {...a11yProps(0)}
          />
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Metrics"
            {...a11yProps(1)}
          />
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Clusters"
            {...a11yProps(2)}
          />
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Health"
            {...a11yProps(3)}
          />
          {/* temporary changed index */}
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Resource Quotas"
            {...a11yProps(4)} //temp
          />
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Nodes"
            {...a11yProps(5)} //temp
          />
          <StyledTab
            style={{ textTransform: 'none' }}
            label="Services"
            {...a11yProps(6)} //temp
          />
        </StyledTabs>
        <div style={{ marginLeft: '16px' }}>
          <MetricsDropdown
            styleWrapper={{ marginBottom: '6px' }}
            label={'Slice '}
            width={'200px'}
            activeOption={storeProps.activeSelectedSlice}
            compareOptionKey={SLICE_COMPARE_KEY}
            handleUpdateActiveOption={(el) =>
              storeProps.setActiveSelectedSlice(el)
            }
            options={storeProps.sliceData}
            placeholder={'Select Slice'}
          /></div>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div style={serviceStyle}>
          <DeploymentMap />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container direction="column" alignItems="flex-end">
          <FormControlLabel
            labelPlacement="start"
            control={
              <IOSSwitch
                checked={logicalView}
                handleChange={() =>
                  setlogicalView((oldLogicalView) => !oldLogicalView)
                }
              />
            }
            label={`Topology view`}
            sx={{
              marginRight: 0,
              marginBottom: '20px',
              '& .MuiFormControlLabel-label': {
                marginRight: '12px',
              },
            }}
          />
          <Grid item xs style={{ width: '100%' }}>
            {!logicalView ? (
              <div style={serviceStyle}>
                <ReactFlowProvider>
                  <div style={serviceStyle}>
                    <MetricsTab storeProps={storeProps} />
                  </div>
                </ReactFlowProvider>
              </div>
            ) : (
              <TopologicalView />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={serviceStyle}>
          <ClusterTab />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div style={serviceStyle}>
          <Health storeProps={storeProps} />
        </div>
      </TabPanel>
      {/* temporary changed index */}
      <TabPanel value={value} index={4}>
        <div style={serviceStyle}>
          <ResourceQuota storeProps={storeProps} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div style={serviceStyle}>
          <NodesView />
        </div>
      </TabPanel>

      <TabPanel value={value} index={6}>
        <div style={serviceStyle}>
          <ServiceDependency />
        </div>
      </TabPanel>
    </div>
  );
});
