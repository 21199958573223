import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.css';
//import { useSelector } from "react-redux";
import ViewNamespacesModel from './NameSpaceModal';

import { selectAffinityProfiles } from '../../../selectors/NodeAffinity.selector';

export default function NamespaceTable() {
  const currentSlice = useSelector((state) => state.NodeAffinity.currentSlice);
  // console.log(currentSlice);
  const namespaceSelected = useSelector(
    (state) => state.NodeAffinity.affinity_profiles
  );
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [Name, setName] = React.useState('');
  const [NameSpaceLabel, setNameSpaceLabel] = React.useState([]);

  const affinity_profiles = useSelector(selectAffinityProfiles);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getNodeLabels(currentSlice.metadata.name))
  }, [dispatch, currentSlice.metadata.name, affinity_profiles]);

  const setNameSpaceLabelName = (item, id) => {
    const demmyNameSace = [];
    item.map((label) => {
      console.log(`${label.key}: ${label.values}`);
      demmyNameSace.push(`${label.key}: ${label.values}`);
    });
    setNameSpaceLabel(demmyNameSace);
  };
  useEffect(() => {}, [namespaceSelected]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {openViewModal && (
        <ViewNamespacesModel
          open={openViewModal}
          setOpenViewModal={setOpenViewModal}
          sliceName={currentSlice.metadata.name}
          nameSpaceName={Name}
          NameSpaceLabel={NameSpaceLabel}
        />
      )}
      <Typography
        style={{
          paddingLeft: '24px',
          paddingTop: '15px',
          fontFamily: 'Bai Jamjuree',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '30px',
          color: '#12153E',
        }}
        className="step-title-header"
        gutterBottom
        component="div"
        data-testid="namespacesHeader"
      >
        Namespaces
      </Typography>
      <TableContainer className="table-container">
        <Table sx={{ minWidth: 300 }} data-testid="namespaceTables4">
          <TableHead className="table-head">
            <TableRow style={{ height: '45px' }}>
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="namespaces"
              >
                NAMESPACES
              </TableCell>
              <TableCell
                style={{
                  paddingLeft: '24px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
                data-testid="namespacesNodes"
              >
                NODE LABELS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/*             
            {usenamespacesName.map((name) => {
              console.log(name, 'name'); */}
            {affinity_profiles?.length > 0
              ? affinity_profiles.map((profile, p_idx) => {
                  return profile.nodeAffinityRules
                    .filter((rule) => rule.namespace !== '*')
                    .map((rule, idx) => {
                      const shouldLoopOver =
                        rule.nodeSelectorLabels?.length > 0;
                      return (
                        shouldLoopOver && (
                          <TableRow style={{ borderStyle: 'hidden' }}>
                            <TableCell
                              style={{
                                padding: '24px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#12153E',
                              }}
                            >
                              {rule.namespace}
                              <Typography
                                style={{
                                  fontFamily: 'Bai Jamjuree',
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '14px',
                                  color: '#5A607C',
                                  lineHeight: '17.5px',
                                }}
                              >
                                {`${profile.cluster ?? ''}`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '24px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#12153E',
                              }}
                            >
                              {currentSlice ? (
                                <Link
                                  data-testid={`${profile.cluster}-${rule.namespace}`}
                                  underline="hover"
                                  style={{
                                    color: '#2868F3',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setOpenViewModal(true);
                                    setName(rule.namespace);
                                    setNameSpaceLabelName(
                                      rule.nodeSelectorLabels,
                                      idx
                                    );
                                  }}
                                >
                                  View all
                                </Link>
                              ) : (
                                'No Nodes Assigned'
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    });
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
