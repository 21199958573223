import { useSelector } from 'react-redux';
import { license_status } from '../../constants/license';
import {
  selectLicenseStatus,
  selectShowLicensingBanner,
} from '../../selectors/licensing.selector';
import InvalidLicenseBanner from '../Licensing/InvalidBanner/InvalidBanner';
import ExpiredBanner from './ExpiredBanner';
import ValidBanner from './Valid.banner';

// 2023-04-07T11:24:03.602Z

// used material UI because we have to do the same thing in v1
const LicensingBanner = () => {
  const showBanner = useSelector(selectShowLicensingBanner);
  const licenseStatus = useSelector(selectLicenseStatus);

  // const daysLeft = moment().diff(licenseExpiration, 'days');

  const expired = licenseStatus === license_status.expired;

  if (licenseStatus === license_status.invalid) {
    return <InvalidLicenseBanner />;
  }

  if (expired) {
    return <ExpiredBanner />;
  }

  if (licenseStatus === license_status.valid && showBanner)
    return <ValidBanner />;

  return null;
};

export default LicensingBanner;
