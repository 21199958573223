import { Typography } from "@mui/material";
import React, { Component } from "react";
import { ReactComponent as KubeSlice } from "../../../../assets/images/Kubeslice.svg";

export default class Fallback extends Component {
  render() {
    return (
      <div className="step2-fallback">
      <div style={{textAlign:'center'}}>
        <KubeSlice />
        <Typography fontSize="24px" fontWeight="400">
        Add Roles in Step 1 to assign Namespaces to them
        </Typography>
        </div>
      </div>
    );
  }
}
