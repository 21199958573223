import { Box, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as NotificationsIcon } from '../../assets/navbarIcons/notifications.svg';
import TopbarSpinner from '../Dashboard/TopbarSpinner';
import './GeneralLayout.css';
import VCpu from '../VCpu/VCpu';
import { SliceUserNames } from '../../constants/slice-user-names';

export default function GeneralLayout({ title, description, children }) {
  const handleRedirectToNewUi = () => {
    window.location.href = `/events`;
  };
  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);

  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') || '');
  const sliceName = localStorage.getItem('sliceName') || ''

  return (
    <Grid
      style={{ position: 'relative' }}
      container
      direction="column"
      className="layout-container"
    >
      <Grid item className="header">
        <Paper
          sx={{
            padding: '8px 40px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                className="header-primary-layout"
                gutterBottom
                component="div"
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '16px', // Adjust the font size as per your requirement
                  lineHeight: '20px',
                  color: '#6E708E',
                  paddingTop: '0',
                }}
                variant="subtitle1"
              >
                {currentProjectData?.projectNamespace}
                <span style={{ fontWeight: 600 }}> / {description}</span>
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <TopbarSpinner
                cssProps={{
                  spinner: { top: '24px', right: '77px' },
                  tooltip: { left: '82%', top: '56px' },
                }}
              />
              <VCpu />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {isAdmin
                  ? 'Admin'
                  : `${
                      SliceUserNames[sliceName]
                        ? SliceUserNames[sliceName]
                        : ''
                    }`}
                <NotificationsIcon
                  style={{
                    cursor: 'pointer',
                    // position: 'absolute',
                    // right: '38px',
                    // top: '21px',
                  }}
                  onClick={handleRedirectToNewUi}
                />
              </div>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item className="table-container-grid-custom">
        {children}
      </Grid>
    </Grid>
  );
}
