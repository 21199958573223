import moment from 'moment';

export const formatDate = (date, format = 'MMM DD, YYYY') => {
  const m = moment(date);

  if (m.isValid()) {
    return m.format(format);
  }
  return '';
};
