import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteRoleTemplate } from "../../actions/RbacActions";
import useConfirm from "../ConfirmationModal/ConfirmationModal";
import AddRole from "./AddRole";
import "./index.css";
import RoleDetail from "./RoleDetail";

const Row = (props) => {
  const { role, roleToShow, setRoleToShow } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const [showAddModal, setShowAddModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const [Dialog, confirmDelete] = useConfirm();
  const showDetails = roleToShow === role;

  const toggleRoleToShow = () => {
    if (roleToShow) {
      setRoleToShow(showDetails ? null : role);
    } else {
      setRoleToShow(role);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    confirmDelete()
      .then((yes) => {
        if (yes) {
          dispatch(deleteRoleTemplate(role.name));
        }
      })
      .finally(() => {
        setAnchorEl(null);
      });
  };

  const actions = [
    {
      label: "Use as Template",
      action: () => setShowAddModal(true),
    },
    {
      label: "Delete",
      action: handleDelete,
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        }}
        data-testid="rule-row"
      >
        <TableCell className="roles-padding-cell-body" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleRoleToShow}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          className="padding-cell-body"
          component="th"
          scope="row"
          sx={{
            // display: "flex",
            alignItems: "center",
            borderBottom: 0,
          }}
        >
          <Typography className="rbac-table-cell"> {role.name}</Typography>
        </TableCell>
        <TableCell
          className="padding-cell-body"
          component="th"
          scope="row"
          sx={{
            width: "50%",
            borderBottom: 0,
          }}
        >
          <Typography className="rbac-table-cell">
            {" "}
            {role.description}
          </Typography>
        </TableCell>
        <TableCell
          className="padding-cell-body"
          component="th"
          scope="row"
          sx={{
            width: "6%",
            borderBottom: 0,
          }}
        >
          <Button
            id="action-menu"
            aria-controls={open ? "action-meenu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{
              padding: 0,
              minWidth: "24px",
              width: "24px",
              display: "flex",
              margin: 0,
            }}
          >
            <MoreVertIcon />
          </Button>
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            aria-labelledby="action-menu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {actions.map((action, idx) => (
              <MenuItem
                onClick={action.action}
                variant="soft"
                color="danger"
                key={idx}
              >
                <ListItemText>{action.label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
          <Dialog title="Are you sure you want to delete this role?" />
        </TableCell>
      </TableRow>
      <RoleDetail
        role={role}
        open={showDetails}
        onClose={() => {
          setRoleToShow(null);
        }}
      />
      <AddRole
        addRoleDialog={showAddModal}
        setAddRoleDialog={setShowAddModal}
        rules={role.rules}
      />
    </React.Fragment>
  );
};

export default Row;
