import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  getSliceCustomObjectList,
  getSliceObjectData,
} from '../../../../actions/ConnectClusterActions';
import { setCurrentSlice } from '../../../../actions/namespaceActions';
import { getSliceObject } from '../../../../actions/namespaceActions';
import { ReactComponent as Checked } from '../../../../assets/icons/Checked.svg';

import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import AlertDialog from './AlertDialog';
import './index.css';
import MyContext from './context';

import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { removeNamespacesToSlice } from '../../../../actions/namespaceActions';
export const CustomPaginator = ({
  rowsPerPageOptions,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const firstPosition = page * rowsPerPage + 1;
  const lastPosition = firstPosition + rowsPerPage - 1;
  const lastPage = Math.ceil(count / rowsPerPage);
  const paginatorOptionsLength = lastPage - 1 >= 3 ? 3 : lastPage;
  const paginatorOptions = Array(paginatorOptionsLength)
    .fill(undefined)
    .map((_, index) => {
      const pivot =
        page === 0
          ? 0
          : page === lastPage - 1
            ? -(paginatorOptionsLength - 1)
            : -1;
      return page + 1 + (index + pivot);
    });

  const onNextPage = () => onPageChange(undefined, page + 1);
  const onPrevPage = () => onPageChange(undefined, page - 1);
  const onLastPage = () => onPageChange(undefined, lastPage - 1);
  const onFirstPage = () => onPageChange(undefined, 0);
  return (
    <TableFooter>
      <TableRow
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid lightgrey',
        }}
      >
        <Typography
          sx={{ padding: '24px 16px', fontSize: '12px' }}
          component={'span'}
        >
          {`Showing ${firstPosition}-${lastPosition} of ${count} entries`}
        </Typography>
        <div>
          <IconButton
            sx={{
              fontSize: '14px',
              padding: 0,
              letterSpacing: '-3px',
              marginRight: '8px',
            }}
            onClick={onFirstPage}
            disabled={page === 0}
          >{`<<`}</IconButton>
          <IconButton
            sx={{ fontSize: '14px', padding: 0, marginRight: '8px' }}
            onClick={onPrevPage}
            disabled={page === 0}
          >{`<`}</IconButton>
          {paginatorOptions.map((pageItem) => {
            return (
              <Button
                sx={{ minWidth: 0, fontSize: '14px', marginRight: '12px' }}
                onClick={(e) => {
                  onPageChange(e, pageItem - 1);
                }}
              >
                {pageItem}
              </Button>
            );
          })}
          <IconButton
            sx={{ fontSize: '14px', padding: 0, marginRight: '8px' }}
            onClick={onNextPage}
            disabled={page >= lastPage - 1}
          >{`>`}</IconButton>

          <IconButton
            onClick={onLastPage}
            sx={{ fontSize: '14px', padding: 0, letterSpacing: '-3px' }}
            disabled={page >= lastPage - 1}
          >{`>>`}</IconButton>
        </div>
        <Box sx={{ fontSize: '12px', marginRight: '24px' }}>
          View{' '}
          <Select
            value={rowsPerPage}
            defaultValue={rowsPerPage[0]}
            onChange={(e) => {
              onRowsPerPageChange(e);
            }}
            sx={{
              backgroundColor: '#E6E8F3',
              fontSize: '12px',
              marginLeft: '6px',
              marginRight: '8px',
              '& .MuiSelect-select': {
                paddingLeft: '8px',
                paddingRight: '24px !important',
                textAlign: 'right',
                textAlignLast: 'right',
                paddingTop: '4px',
                paddingBottom: '4px',
              },
              '& .MuiSelect-icon': {
                width: '16px',
                height: '16px',
                marginTop: '4px',
              },
            }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
          rows per page
        </Box>
      </TableRow>
    </TableFooter>
  );
};

export default function NamespaceTable({
  selectedNamespace,
  setSelectedNamespace,
  searchText,
  search,
  searchBy,
  // setSelectionSate,
  // SelectionSate,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = useState(0);
  const [list, setList] = useState([]);
  const sliceList = useSelector((state) => state.ConnectCluster.sliceList);
  const clusterList = useSelector(
    (state) => state.RegisterCluster.clusterList?.items
  );
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const takenNamespace = useMemo(() => {
    if (sliceList?.length > 0) {
      let listSliced = [...sliceList];
      listSliced = listSliced.filter(
        (item) => item?.metadata?.name !== currentSlice?.metadata?.name
      );
      if (listSliced?.length > 0) {
        let usedNamespaces = [];
        listSliced.forEach((slice) => {
          slice?.spec?.namespaceIsolationProfile?.applicationNamespaces?.forEach(
            (namespace) => {
              namespace?.clusters?.forEach((cluster) => {
                usedNamespaces.push({
                  namespace: namespace.namespace,
                  cluster: cluster,
                });
              });
            }
          );
        });
        return usedNamespaces;
      }
    }
    return [];
  }, [currentSlice?.metadata?.name, sliceList]);
  const [allList, setAllList] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const defaultNameSpaceObj = React.useMemo(
    () =>
      currentSlice?.spec?.namespaceIsolationProfile?.applicationNamespaces ||
      [],
    [currentSlice.spec.namespaceIsolationProfile.applicationNamespaces]
  );
  const [namespaceName, setnamespaceName] = React.useState('');
  const [NameSpaceObject, setNameSpaceObject] = React.useState([]);
  const [SliceName, setSliceName] = React.useState('');
  const [OffboardSliceData] = React.useState([]);
  const { SelectionSate } = useContext(MyContext);
  const { setSelectionSate } = useContext(MyContext);
  const [OriginalList, setOriginalList] = useState([]);
  const dispatch = useDispatch();

  const formatList = useCallback((listProp) => {
    const results = [];
    listProp?.forEach((item) => {
      const foundIndex =
        results?.length > 0
          ? results.findIndex((resultItem) => {
            return resultItem.namespace === item.namespace;
          })
          : -1;

      if (results?.length > 0 && foundIndex >= 0) {
        results[foundIndex]['clusters'].push(item?.cluster);
      } else {
        results.push({
          namespace: item.namespace,
          sameness: item.sameness,
          clusters: [item.cluster],
        });
      }
    });
    return results;
  }, []);

  const handleModalClose = (del = false) => {
    if (del === false) {
      setSelectionSate(() => ({
        selectionStatecontext: false,
        clearAllcontext: false,
        openModal: false,
        // deletedItems: SelectionSate.deletedItems,
        openModalCancel: true,
        defobj: defaultNameSpaceObj,
        // revert: false,
      }));
      setSelectedNamespace([...defaultNameSpaceObj]);
    } else {
      setSelectedNamespace([...defaultNameSpaceObj]);

      setSelectionSate(() => ({
        selectionStatecontext: false,
        clearAllcontext: false,
        openModal: false,
        // deletedItems: SelectionSate.deletedItems,
        openModalCancel: true,
        defobj: defaultNameSpaceObj,
        // revert: false,
      }));
      // setSelectedNamespace(defaultNameSpaceObj);
    }
    setOpenAlertModal(() => false);
  };

  const confirmClose = (del = false) => {
    if (del === false) {
      setSelectionSate(false);
      let query = {
        namespaceName,
        SliceName,
        NameSpaceObject,
      };
      // setConfirmed(true);
      const hostname = window.location.hostname;
      const port = window.location.port;
      let socket_url = `wss://${hostname}/offboard-namespace`;
      if (port) {
        socket_url = `wss://${hostname}:${port}/offboard-namespace`;
      }
      // socket_url = `ws://localhost:3000/offboard-namespace`;

      const socket = io(socket_url, {
        secure: true,
        transports: ['websocket', 'polling'],
      });

      socket.on('connect', () => {
        // const socket = io(process.env.REACT_APP_SOCKET_URL);
        dispatch(removeNamespacesToSlice(query, socket.id));
        let updatedSlice = { ...currentSlice };
        updatedSlice['spec']['namespaceIsolationProfile'][
          'applicationNamespaces'
        ] = selectedNamespace;
        dispatch(getSliceObject(SliceName, updatedSlice));
      });
      setOpenAlertModal(false);
    } else {
      setOpenAlertModal(false);
      setOpenAlertModal(false);
    }
  };
  const openModal = () => {
    setOpenAlertModal(true);
  };

  const isFirstRender = React.useRef(true);
  useEffect(() => {
    // if (defaultNameSpaceObj.length > 0) {
    if (SelectionSate.clearAllcontext !== true) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      const missingObjects = SelectionSate.deletedItems;

      if (missingObjects?.length > 0) {
        const namespaceExists = OriginalList.some((obj) => {
          return (
            obj.namespace === missingObjects[0].namespace &&
            JSON.stringify(obj.clusters) ===
            JSON.stringify(missingObjects[0].clusters)
          );
        });

        if (namespaceExists) {
          const nameSpaceObject = missingObjects[0].clusters;
          setSliceName(currentSlice?.metadata?.name);
          setnamespaceName(missingObjects[0]?.namespace);
          setNameSpaceObject(nameSpaceObject);
          return openModal();
        } else {
          return null;
        }
      }
    }
    // }
  }, [SelectionSate, OriginalList, list, currentSlice?.metadata?.name]);

  function destructureClusters(obj) {
    const newObj = obj?.clusters
      ? obj?.clusters.map((cluster) => ({
        namespace: obj?.namespace,
        clusters: [cluster],
      }))
      : [];
    return newObj;
  }
  useEffect(() => {
    dispatch(getSliceObjectData(currentSlice?.metadata?.name)).then(
      (response) => {
        const originalArray =
          response?.spec?.namespaceIsolationProfile?.applicationNamespaces ??
          [];
        const destructuredArray = originalArray.flatMap((obj) =>
          destructureClusters(obj)
        );
        setOriginalList(destructuredArray);
      }
    );
  }, [dispatch, currentSlice, defaultNameSpaceObj]);

  useEffect(() => {
    let itemArr = [];
    if (clusterList) {
      clusterList.forEach((cluster) => {
        if (currentSlice?.spec?.clusters.includes(cluster?.metadata?.name)) {
          cluster?.status?.namespaces &&
            cluster?.status?.namespaces.forEach((namespace) => {
              const itemObj = {
                namespace: namespace?.name,
                cluster: cluster?.metadata?.name,
                sameness: false,
              };

              itemArr.push(itemObj);
            });
        }
      });

      if (takenNamespace?.length > 0) {
        itemArr = itemArr.filter((item) => {
          return !takenNamespace.find((taken) => {
            return (
              item.namespace === taken.namespace &&
              (item.cluster === taken.cluster || taken.cluster === '*')
            );
          });
        });
      }

      itemArr?.forEach((item, index) => {
        let foundIndex = selectedNamespace?.findIndex((selected, key) => {
          return (
            item.namespace === selected.namespace &&
            selected['clusters'][0] === '*'
          );
        });
        if (foundIndex >= 0) {
          itemArr[index]['sameness'] = true;
        }
      });

      if (search && searchText !== '') {
        itemArr = itemArr.filter((item) => {
          return searchBy === 'namespace'
            ? item.namespace === searchText
            : item.cluster === searchText;
        });
      }

      setAllList(itemArr);
      itemArr = itemArr.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      const formattedArr = formatList(itemArr);
      setList(formattedArr);
    }
  }, [
    clusterList,
    currentSlice,
    search,
    searchText,
    searchBy,
    page,
    rowsPerPage,
    takenNamespace,
    selectedNamespace,
    formatList,
    defaultNameSpaceObj,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const samenessChange = (event, namespace) => {
    const isItemSelected = event.target.checked;
    // To handle removable of already sameness item when sameness condition changes to unchecked
    const deletedNamespace = [];
    // Create new list for updating table
    const results = [];
    list.forEach((row) => {
      // if namespace is same then if namespace selected then push into list array with sameness condition
      if (row.namespace === namespace) {
        if (isItemSelected) {
          results.push({ ...row, sameness: true, clusters: ['*'] });
        } else {
          results.push({
            ...row,
            sameness: true,
            clusters: [...currentSlice?.spec?.clusters],
          });
          //
          deletedNamespace.push({
            namespace: namespace,
            clusters: ['*'],
          });
        }
      } else {
        // or if other namespace just append
        results.push({ ...row });
      }
    });
    // Variable to check if namespace is already selected
    let nameSpaceFound = false;
    // If namespace was checked then update cluster to *
    const newSelectedNamespace = [];
    (selectedNamespace || []).forEach((row) => {
      if (row.namespace === namespace) {
        nameSpaceFound = true;
        if (isItemSelected) {
          newSelectedNamespace.push({ ...row, clusters: ['*'] });
        } else {
          newSelectedNamespace.push({
            ...row,
            clusters: [...currentSlice?.spec?.clusters],
          });
        }
      } else {
        newSelectedNamespace.push({ ...row });
      }
    });
    // If namespace wasnt checked
    if (nameSpaceFound === false) {
      newSelectedNamespace.push({
        namespace: namespace,
        clusters: isItemSelected ? ['*'] : [...currentSlice?.spec?.clusters],
      });
    }

    //This will update right side selected namespace in ui
    setSelectedNamespace(() => newSelectedNamespace);
    //This will update left side table in ui
    setList(() => results);
    setSelectionSate({
      selectionStatecontext: true,
      clearAllcontext: false,
      openModal: true,
      deletedItems: deletedNamespace,
      openModalCancel: false,
      defobj: defaultNameSpaceObj,
    });
    setIsFormChanged((value) => !value);
  };

  const selectRow = (event, namespace, cluster) => {
    const isChecked = event.target.checked;
    let selectedNamespaces = Array.isArray(selectedNamespace)
      ? [...selectedNamespace]
      : [];
    const foundIndex = selectedNamespaces.findIndex(
      (selected) => selected.namespace === namespace
    );

    if (isChecked) {
      if (foundIndex >= 0) {
        if (Array.isArray(cluster)) {
          selectedNamespaces[foundIndex].clusters = ['*'];
        } else {
          selectedNamespaces[foundIndex].clusters.push(cluster);
        }
      } else {
        selectedNamespaces.push({
          namespace: namespace,
          clusters: Array.isArray(cluster) ? ['*'] : [cluster],
        });
      }
    } else {
      if (foundIndex >= 0) {
        let deletedItems = [];
        if (
          selectedNamespaces[foundIndex].clusters?.length >= 0 &&
          !Array.isArray(cluster)
        ) {
          const clusterIndex =
            selectedNamespaces[foundIndex].clusters.indexOf(cluster);
          selectedNamespaces[foundIndex].clusters.splice(clusterIndex, 1);
          deletedItems.push({
            namespace: namespace,
            clusters: [cluster],
          });
        }
        if (selectedNamespaces[foundIndex].clusters.length === 0) {
          selectedNamespaces.splice(foundIndex, 1);
          deletedItems.push({
            namespace: namespace,
            clusters: ['*'],
          });
        }
        if (deletedItems.length > 0) {
          setSelectionSate({
            selectionStatecontext: true,
            clearAllcontext: false,
            openModal: true,
            deletedItems: deletedItems,
            openModalCancel: false,
            defobj: defaultNameSpaceObj,
          });
        } else {
          const seam = selectedNamespaces.splice(foundIndex, 1);

          seam.map((data) => {
            return deletedItems.push({
              namespace: data.namespace,
              clusters: data.clusters,
            });
          });
          setSelectionSate({
            selectionStatecontext: true,
            clearAllcontext: false,
            openModal: true,
            deletedItems: deletedItems,
            openModalCancel: false,
            defobj: defaultNameSpaceObj,
          });
        }
      }
    }

    setIsFormChanged((value) => !value);
    setSelectedNamespace(selectedNamespaces);
  };

  const selectAllRows = () => {
    const allChecked = list?.every((item) =>
      selectedNamespace?.some(
        (namespace) => namespace.namespace === item.namespace
      )
    );
    if (allChecked) {
      const filteredList = selectedNamespace.filter(
        (namespace) =>
          !list?.some((item) => item.namespace === namespace.namespace)
      );
      setSelectedNamespace(filteredList);
    } else {
      setSelectedNamespace([...defaultNameSpaceObj, ...list]);
    }
  };
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentSlice.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, [currentSlice, dispatch]); //DEV IN PROCESS
  useEffect(() => {
    dispatch(getSliceCustomObjectList());
  }, [OffboardSliceData, dispatch]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     dispatch(getSliceObjectData(currentSlice?.metadata?.name));
  //   }, 10000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 6,
      }}
    >
      <AlertDialog
        open={openAlertModal}
        handleClose={handleModalClose}
        clusterName={namespaceName}
        sliceName={currentSlice.sliceName}
        confirmClose={confirmClose}
      // setIsChanged={setIsChanged}
      />
      <Prompt
        message={(location, action) => {
          if (isFormChanged) {
            if (
              location.pathname === '/manage-namespaces/add/2' &&
              action === 'PUSH'
            ) {
              return true;
            } else {
              return 'You have unsaved changes. Do you want to discard them?';
            }
          }
          return true;
        }}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 300, borderRight: '1px solid lightgrey' }}
          aria-labelledby="Namespace Table"
          disabled={DisableOffboardCluster}
          size="small"
        >
          <TableHead className="table-head">
            <TableRow>
              <TableCell
                sx={{ textAlign: 'center', width: '15%' }}
                style={{
                  padding: '0px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                <Checkbox
                  disabled={DisableOffboardCluster}
                  color="primary"
                  onChange={() => selectAllRows()}
                  checked={list?.every((item) =>
                    selectedNamespace?.some(
                      (namespace) => namespace.namespace === item.namespace
                    )
                  )}
                  checkedIcon={<Checked/>}
                />
              </TableCell>
              <TableCell
                sx={{ width: '40%' }}
                style={{
                  paddingLeft: '24px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                NAMESPACE
              </TableCell>
              <TableCell
                sx={{ maxWidth: '35%' }}
                style={{
                  paddingLeft: '24px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              >
                CLUSTER NAME
              </TableCell>
              <TableCell
                style={{
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  fontFamily: 'Bai Jamjuree',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#12153E',
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length > 0 ? (
              list.map((item, index) => {
                if (item.sameness) {
                  const isItemSelected =
                    selectedNamespace?.length > 0
                      ? selectedNamespace?.some((value) => {
                        return value['namespace'] === item['namespace'];
                      })
                      : false;
                  return (
                    <TableRow
                      disabled={DisableOffboardCluster}
                      key={`${index}`}
                      selected={isItemSelected}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      hover
                    >
                      <TableCell
                        sx={{ textAlign: 'left' }}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#12153E',
                        }}
                      >
                        <Checkbox
                          disabled={DisableOffboardCluster}
                          color="primary"
                          onChange={(event) =>
                            selectRow(
                              event,
                              item['namespace'],
                              item['clusters']
                            )
                          }
                          checked={isItemSelected}
                          checkedIcon={<Checked/>}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#12153E',
                        }}
                      >
                        {item['namespace']}
                      </TableCell>
                      <TableCell
                        sx={{ maxWidth: '35%' }}
                        style={{
                          paddingLeft: '24px',
                          paddingTop: '15px',
                          paddingBottom: '15px',
                          fontFamily: 'Bai Jamjuree',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#12153E',
                        }}
                      >
                        <Chip
                          label={'Namespace sameness'}
                          sx={{ backgroundColor: '#E4F8F3', color: '#14974C' }}
                        ></Chip>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'end',
                          padding: 0
                        }}
                        disabled={DisableOffboardCluster}
                      >
                        <PopupState
                          disabled={DisableOffboardCluster}
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <div disabled={DisableOffboardCluster}>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreVertIcon />
                              </IconButton>
                              {DisableOffboardCluster ? (
                                <></>
                              ) : (
                                <Popover
                                  {...bindPopover(popupState)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                >
                                  <Typography sx={{ p: 1 }}>
                                    <Switch
                                      checked={item['sameness']}
                                      onChange={(event) =>
                                        samenessChange(event, item['namespace'])
                                      }
                                      size="small"
                                    />
                                    Namespace sameness
                                  </Typography>
                                </Popover>
                              )}
                            </div>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return item?.clusters?.map((cluster) => {
                    const isItemSelected =
                      selectedNamespace?.length > 0 &&
                      selectedNamespace?.some((value) => {
                        return (
                          value['clusters'].includes(cluster) &&
                          value['namespace'] === item['namespace']
                        );
                      });
                    return (
                      <TableRow
                        disabled={DisableOffboardCluster}
                        key={`${cluster}-${index}`}
                        selected={isItemSelected}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        hover
                      >
                        <TableCell
                          sx={{ textAlign: 'center' }}
                          style={{
                            paddingLeft: '15px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#12153E',
                          }}
                        >
                          <Checkbox
                            disabled={DisableOffboardCluster}
                            color="primary"
                            onChange={(event) =>
                              selectRow(event, item['namespace'], cluster)
                            }
                            checked={isItemSelected}
                            checkedIcon={<Checked/>}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            paddingLeft: '24px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#12153E',
                          }}
                        >
                          {item['namespace']}
                        </TableCell>
                        <TableCell
                          sx={{ maxWidth: '35%' }}
                          style={{
                            paddingLeft: '24px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            fontFamily: 'Bai Jamjuree',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#12153E',
                          }}
                        >
                          {cluster}
                        </TableCell>
                        <TableCell
                          style={{
                            fontFamily: 'Bai Jamjuree',
                            paddingLeft: '24px',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#12153E',
                          }}
                          sx={{ padding: 0, textAlign: 'end' }}
                          disabled={DisableOffboardCluster}
                        >
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-popover"
                            disabled={DisableOffboardCluster}
                          >
                            {(popupState) => (
                              <div disabled={DisableOffboardCluster}>
                                <IconButton {...bindTrigger(popupState)}>
                                  <MoreVertIcon />
                                </IconButton>
                                {DisableOffboardCluster ? (
                                  <></>
                                ) : (
                                  <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 1 }}>
                                      <Switch
                                        checked={item['sameness']}
                                        onChange={(event) =>
                                          samenessChange(
                                            event,
                                            item['namespace']
                                          )
                                        }
                                        size="small"
                                      />
                                      Namespace sameness
                                    </Typography>
                                  </Popover>
                                )}
                              </div>
                            )}
                          </PopupState>
                        </TableCell>
                      </TableRow>
                    );
                  });
                }
              })
            ) : (
              <TableRow>
                <TableCell style={{
                  paddingLeft: '24px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                }} colSpan={3}>No Namespace to display</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPaginator
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
