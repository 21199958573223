import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': {
    // width: '60%',
    maxWidth: '100%',
  },
}));

const ViewNamespacesModel = ({
  open,
  setOpenViewModal,
  sliceName,
  nameSpaceName,
  NameSpaceLabel: rawLabels,
}) => {
  const [searchText, setSearchText] = React.useState('');
  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [labels, setLabels] = useState([]);

  const node_labels = useSelector((state) => state.NodeAffinity.node_labels);

  const [selectedNode, setSelectedNode] = React.useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getNodeLabels(sliceName));
  // }, [dispatch, sliceName]);

  const searchNodes = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const NameSpaceLabel = rawLabels.filter((label) => {
    return label.includes(searchText);
  });

  return (
    <div>
      <BootstrapDialog
        open={open}
        onClose={() => {}}
        sx={{
          '& .MuiDialog- paper': {
            // minWidth:  "80%",
            maxWidth: '1 00%',
            minHeight: 'auto',
            position: 'relative',
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              maxWidth: '100%',
              width: '600px',
              position: 'relative',
              height: 'fit-content',
            }}
          >
            <DialogTitle
              style={{ fontWeight: '600' }}
              id="customized-dialog-title"
            >
              <Stack>
                <span>
                  Total labels :
                  <Box component="span">{` ${NameSpaceLabel.length}`}</Box>
                  <IconButton
                    data-testid="closeIcons4"
                    aria-label="close"
                    onClick={() => {
                      setOpenViewModal(false);
                      setSearchText('');
                    }}
                    sx={{
                      float: 'right',
                      padding: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
                <Box
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: '400',
                    }}
                    component="span"
                  >
                    Namespace Name:
                  </Box>
                  <Box component="span">{` ${nameSpaceName}`}</Box>
                </Box>
                <Box
                  sx={{
                    fontSize: '14px',
                  }}
                ></Box>
              </Stack>
            </DialogTitle>
            {/* <DialogTitle id="scroll-dialog-title">
              <div>
                <Typography className="dialog-title">
                  Total labels : {NameSpaceLabel.length}
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenViewModal(false);
                      setSearchText('');
                    }}
                    sx={{
                      float: 'right',
                      padding: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>
                <Typography className="dialog-subtitle">
                  Namespace Name: {nameSpaceName}
                </Typography>
              </div>
            </DialogTitle> */}

            <DialogContent
              dividers
              sx={{ maxHeight: '80%' }}
              style={{ padding: '0' }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '40px',
                  overflow: 'hidden',
                  border: '1px solid #B6B8D6',
                  margin: 'auto',
                  borderRadius: '4px',
                  marginTop: '16px',
                  width: '92%',
                }}
              >
                <Paper
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '0 5px 5px 0',
                  }}
                >
                  <IconButton
                    className="search-icon"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Labels"
                    onChange={searchNodes}
                    inputProps={{ 'aria-label': 'search google maps' }}
                  />
                </Paper>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      height: '48vh',
                      width: '100%',
                      overflow: 'auto',
                      borderBottom: '1px solid lightgrey',
                    }}
                  >
                    <TableContainer className="table-container">
                      <Table
                        sx={{
                          minWidth: 300,
                          borderRight: '1px solid lightgrey',
                        }}
                        aria-labelledby="Namespace Table"
                        size="small"
                      >
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell
                              style={{
                                padding: '15px 20px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '0%',
                              }}
                            >
                              NO
                            </TableCell>
                            <TableCell
                              style={{
                                padding: '15px 20px',
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '60%',
                              }}
                            >
                              LABEL NAME
                            </TableCell>
                            {/* <TableCell
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '30%',
                                paddingRight: '1%',
                              }}
                              align="left"
                            >
                              DESCRIPTION
                            </TableCell> */}
                            <TableCell
                              style={{
                                fontFamily: 'Bai Jamjuree',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '15px',
                                color: '#12153E',
                                width: '30%',
                              }}
                              align="left"
                            >
                              {/* DESCRIPTION */}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {
                          <TableBody>
                            {NameSpaceLabel ? (
                              NameSpaceLabel.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              ).map((label, index) => {
                                const [key, value] = Object.entries(label)[0];
                                return (
                                  <TableRow key={`${key}-${value}`} hover>
                                    <TableCell
                                      style={{
                                        padding: '0px 20px',
                                        fontFamily: 'Bai Jamjuree',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        color: '#12153E',
                                      }}
                                    >
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: '12px 0px 12px 20px',
                                        /*lineHeight: '20px',*/
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontFamily: 'Bai Jamjuree',
                                          fontStyle: 'normal',
                                          fontWeight: 600,
                                          fontSize: '16px',
                                          color: '#12153E',
                                          lineHeight: '20px',
                                        }}
                                      >
                                        {label}
                                      </Typography>
                                    </TableCell>

                                    <TableCell />
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell colSpan={3}>
                                  No NameSpace Labels to display
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        }
                      </Table>
                    </TableContainer>
                  </div>
                  <TablePagination
                    data-testid="NameSpaceDataPagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={NameSpaceLabel?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </DialogContent>
          </Box>
          {/* <h1>hello</h1> */}
        </Box>
      </BootstrapDialog>
    </div>
  );
};

export default ViewNamespacesModel;
