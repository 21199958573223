import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSSE } from '../../hooks/use-sse';
import { selectLicenseDetails } from '../../selectors/licensing.selector';

const VCpu = () => {
  const licenseDetails = useSelector(selectLicenseDetails);

  const { data: used } = useSSE({
    url: '/api/v1/license/getCurrentVcpuCount',
  });

  const total = licenseDetails?.['vcpu-count'];

  const usedPercentage = total ? parseFloat(used || '0') / parseInt(total) : 0;

  const redirectToVcpuPage = () => {
    window.location.href = `/vcpu`;
  };

  const getVcpuColor = (index) => {
    if (usedPercentage < 0.5) {
      return 1 - usedPercentage >= (index + 1) / 4 ? '' : '#4caf50'; // green
    } else if (usedPercentage < 0.75) {
      return 1 - usedPercentage >= (index + 1) / 4 ? '' : '#ed8936'; // orange
    }
    return 1 - usedPercentage >= (index + 1) / 4 ? '' : '#f44336'; // red
  };
  const parsedUsage = parseFloat(used ?? '0').toFixed(1);

  return (
    <Box
      sx={{
        border: '1px solid #ACB9FF',
        borderRadius: '4px',
        padding: '10px 11px',
        // backgroundColor: '#2868f35d',
        backgroundColor: '#ECF2FF',
        display: 'flex',
        gap: '8px',
        cursor: 'pointer',
      }}
      onClick={redirectToVcpuPage}
      data-testid="vcpu-usage"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '10px',
          borderRadius: '2px',
          overflow: 'hidden',
          border: '1px solid #E0E1ED',
        }}
      >
        {Array.from(Array(4).keys()).map((_, index) => (
          <Box
            key={index}
            sx={{
              height: '5px',
              flex: '1',
              backgroundColor: getVcpuColor(index),
              borderTopLeftRadius: index === 0 ? '2px' : 0,
              borderTopRightRadius: index === 0 ? '2px' : 0,
              borderBottomLeftRadius: index === 3 ? '2px' : 0, // 3 because last
              borderBottomRightRadius: index === 3 ? '2px' : 0,
              borderBottom: index !== 3 ? '1px solid #E0E1ED' : 0,
            }}
            data-colored={1 - usedPercentage >= (index + 1) / 4 ? false : true}
          />
        ))}
      </Box>
      <Box>
        <Box
          component={'p'}
          sx={{
            fontSize: '10px',
            color: '#6C728C',
            fontWeight: 500,
            m: 0,
          }}
        >
          vCPU
        </Box>
        <Box
          component={'p'}
          sx={{
            fontSize: '14px',
            color: '#6E738C',
            fontWeight: 500,
            m: 0,
            lineHeight: '10px',
          }}
        >
          <Box
            sx={{
              color: '#12153E',
            }}
            component={'span'}
            data-testid="vcpu-used"
          >
            {parsedUsage ?? 'NA'}
          </Box>{' '}
          of{' '}
          <Box component={'span'} data-testid="vcpu-total">
            {total}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const EntVcpuUsage = () => {
  const licenseDetails = useSelector(selectLicenseDetails);

  // TODO: validate in trial license
  if (
    !licenseDetails ||
    licenseDetails['license-kind'] !== 'kubeslice-enterprise-vcpu-license' ||
    !licenseDetails['vcpu-count']
  ) {
    return null;
  }

  return <VCpu />;
};

export default EntVcpuUsage;
