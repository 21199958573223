import {
  SET_AFFINITY_API_ACTION,
  SET_AFFINITY_PROFILES,
  SET_CURRENT_SLICE_AFFINITY,
  SET_NODE_AFFINITY_CURRENT_SLICE,
  SET_NODE_AFFINITY_LIST,
  SET_NODE_AFFINITY_SLICE_LIST,
  SET_NODE_LABELS_LIST,
} from '../actions/types';

const initialState = {
  currentSlice: null,
  slice_list: [],
  node_affinity_list: [],
  node_labels: [],
  affinity_profiles: [],
  api_action: null, // null is default, which means create
  currentSliceAffinity: null,
};

export default function NodeAffinityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NODE_AFFINITY_CURRENT_SLICE:
      return { ...state, currentSlice: action.payload };
    case SET_NODE_AFFINITY_SLICE_LIST:
      return { ...state, slice_list: action.payload };
    case SET_NODE_AFFINITY_LIST:
      return { ...state, node_affinity_list: action.payload };
    case SET_NODE_LABELS_LIST:
      return { ...state, node_labels: action.payload };
    case SET_AFFINITY_PROFILES:
      return { ...state, affinity_profiles: action.payload };
    case SET_AFFINITY_API_ACTION:
      return { ...state, api_action: action.payload };
    case SET_CURRENT_SLICE_AFFINITY:
      return { ...state, currentSliceAffinity: action.payload };
    default:
      return state;
  }
}
