import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import {
  addCustomResourceQuotaObject,
  deleteCustomResourceQuotaObject,
  updateCustomResourceQuotaObject,
} from '../../../../actions/ResourceQuotaActions';
import Row from '../../Row';
import ManageResourceTable from '../InitialStep/ManageResourceTable';
import {
  isObjectEmpty,
  purifyRQValues,
} from '../InitialStep/ManageResourceTable/utils';
import Checked from './../../../../assets/images/Check.svg';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '300px',
    height: '120px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '50px',
  width: '100%',
  padding: '6px 12px',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

const GenerateYaml = ({ setClose }) => {
  const [applyYamlDialog, setApplyYamlDialog] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentResourceQuota = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );
  // const currentSlice = useSelector(
  //   (state) => state.NamespaceOnboard.currentSlice
  // );
  const should_delete_rq = useSelector(
    (state) => state.resourceQuota.should_delete_rq
  );

  const sliceList = useSelector(
    (state) => state.resourceQuota.resourceQuotaList
  );

  const updateResourceQuota = async () => {
    if (should_delete_rq) {
      dispatch(
        deleteCustomResourceQuotaObject(currentResourceQuota.metadata.name)
      );

      history.push('/manage-resources/add/4');
    } else {
      let result;
      try {
        const updatedSliceQuota = { ...currentResourceQuota };
        const originalSliceQuota =
          updatedSliceQuota?.spec?.resourceQuota?.spec?.sliceQuota;

        const sliceQuota = {
          resources: {
            limit: purifyRQValues(originalSliceQuota.resources.limit),
            request: purifyRQValues(originalSliceQuota.resources.request),
            defaultRequestPerContainer: purifyRQValues(
              originalSliceQuota.resources.defaultRequestPerContainer
            ),
          },
        };

        let clusterQuota =
          currentResourceQuota?.spec?.resourceQuota?.spec?.clusterQuota;

        clusterQuota = clusterQuota?.map((cluster) => {
          delete cluster.resources;
          let namesapceArr = [
            ...cluster.namespaceQuota.map((item) => {
              // directly returning map without spreading causes object to mutate in reducer
              const { resources } = item;
              const defaultLimitPerContainer = purifyRQValues(
                resources.defaultLimitPerContainer
              );
              const defaultRequestPerContainer = purifyRQValues(
                resources.defaultRequestPerContainer
              );
              const limit = purifyRQValues(resources.limit);
              const request = purifyRQValues(resources.request);

              return {
                ...item,
                resources: {
                  // only append keys when it's not empty
                  ...(isObjectEmpty(defaultRequestPerContainer)
                    ? null
                    : { defaultRequestPerContainer }),
                  defaultLimitPerContainer,
                  limit,
                  request,
                },
              };
            }),
          ];

          return {
            ...cluster,
            namespaceQuota: namesapceArr,
          };
          // cluster.namespaceQuota = namesapceArr;
          // return cluster;
        });

        const payload = {
          sliceName: currentResourceQuota?.metadata?.name,
          sliceQuota: sliceQuota,
          clusterQuota: clusterQuota,
        };

        if (currentResourceQuota?.metadata?.resourceVersion !== '') {
          // the resource version is updated after applied and refetched in actions.
          // but the currentResourceQuota.resourceVersion doesnot get refetched,
          // hence we try get the the resourceVersion from list
          const slice = sliceList?.filter((slice) => {
            return slice.metadata.name === currentResourceQuota.metadata.name;
          })[0];

          payload.resourceVersion =
            slice?.metadata.resourceVersion ??
            currentResourceQuota?.metadata?.resourceVersion;

          result = await dispatch(updateCustomResourceQuotaObject(payload));
        } else {
          result = await dispatch(addCustomResourceQuotaObject(payload));
        }
        setApplyYamlDialog(false);
        result && history.push('/manage-resources/add/4');
      } catch (e) {
        console.log('Error', e);
      }
    }
  };

  const onClickHandler = () => {
    setClose(true);
  };

  const [isYamlApplied, setIsYamlApplied] = useState(false);

  const openDialog = () => {
    setApplyYamlDialog(true);
    setIsYamlApplied(true);
    updateResourceQuota();
  };

  const closeDialog = () => {
    setApplyYamlDialog(false);
  };
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  // useEffect(() => {
  //   if (
  //     currentResourceQuota?.status?.kubesliceEvents?.[0]?.type === 'InProgress'
  //   ) {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // });
  useEffect(() => {
    // getSliceObject(currentSlice.sliceName).then((response) => {
    //   if (response) {
    let identifier = currentResourceQuota?.status?.kubesliceEvents || [];
    const filteredEvents = identifier.filter((data) => {
      return data.type === 'InProgress';
    });
    const disableButton = filteredEvents.length > 0;
    setDisableOffboardCluster(disableButton);
    // }
    // });
  }, []);

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (location.pathname.includes('manage-resources/add')) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      {currentResourceQuota?.metadata?.name && (
        <>
          <Grid item>
            <Box sx={{ overflow: 'auto', background: 'transparent' }}>
              <Box sx={{ background: 'white', border: '1px solid #B6B8D6' }}>
                <Box
                  sx={{
                    padding: '21px 17px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={10}>
                    <Typography
                      className="step-title-header"
                      gutterBottom
                      component="div"
                    >
                      Manage Resources
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      disabled={DisableOffboardCluster}
                      onClick={openDialog}
                      style={{
                        width: '130px',
                        backgroundColor: DisableOffboardCluster
                          ? '#CCCCCC'
                          : undefined,
                      }}
                      className="btn"
                      variant="contained"
                      data-testid="applyyamalbutton"
                    >
                      Apply YAML
                    </Button>
                  </Grid>
                </Box>

                <TableContainer data-testid="ManageResourceTable">
                  <Table aria-label="collapsible table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell
                          sx={{ padding: '15px 25px' }}
                          className="txt-bold ft-12 regular-table"
                        >
                          SLICE NAME
                        </TableCell>
                        <TableCell
                          sx={{ padding: '15px 25px' }}
                          className="txt-bold ft-12 regular-table"
                        >
                          NO.OF PODS
                        </TableCell>
                        <TableCell
                          sx={{ padding: '15px 25px' }}
                          className="txt-bold ft-12 regular-table"
                        >
                          <Box>CPU (m)</Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: '15px 25px' }}
                          className="txt-bold ft-12 regular-table"
                        >
                          <Box>MEMORY (Mi)</Box>
                        </TableCell>
                        <TableCell
                          sx={{ padding: '15px 25px' }}
                          className="txt-bold ft-12 regular-table"
                        >
                          <Box>EPHEMERAL STORAGE (Mi)</Box>
                        </TableCell>
                        <TableCell
                          className="txt-bold ft-12 regular-table"
                          data-testid="yamalDownload"
                        >
                          YAML
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      onClick={onClickHandler}
                      data-testid="manageResourceTableValues"
                    >
                      <Row
                        isFinalize
                        stepNo={3}
                        onClick={onClickHandler}
                        key={currentResourceQuota?.metadata?.name}
                        slice={currentResourceQuota}
                        radioDisable={true}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <ManageResourceTable
                isFinalize
                currentResourceQuota={currentResourceQuota}
              />
            </Box>
            <Box
              sx={{
                position: 'fixed',
                bottom: '0',
                right: '0',
                left: '220px',
                padding: '12px 40px',
                background: 'white',
                display: 'flex',
                justifyContent: 'flex-end',
                boxSizing: 'border-box',
                gap: 2,
              }}
            >
              <Button
                onClick={() => history.push('/manage-resources/add/2')}
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  width: '100px',
                  textTransform: 'none',
                  fontWeight: 600,
                }}
                variant="outlined"
                data-testid="RQPreviousS3"
              >
                <ArrowBackIosIcon style={{ fontSize: '12px' }} /> Previous
              </Button>
              <Button
                disabled={!isYamlApplied}
                onClick={() => history.push('/manage-resources/add/4')}
                style={{ width: '100px' }}
                className="btn"
                variant="contained"
                data-testid="RQNextS3"
              >
                Next <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
              </Button>
            </Box>
          </Grid>

          <CustomDialog
            open={applyYamlDialog}
            onClose={closeDialog}
            style={{ padding: '5px' }}
            aria-labelledby="This dialog is for applying yam"
            aria-describedby="This Dialog is for applying yaml"
          >
            <DialogTitle
              style={{ fontWeight: 'bold' }}
              id="delete-cluster-dialog-title"
            >
              {
                <>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <img alt="discover img" src={Checked} width="25px" />{' '}
                  </Box>
                  <Box
                    p={1}
                    sx={{ width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      style={{
                        fontFamily: 'Bai Jamjuree',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#2A2747',
                      }}
                    >
                      YAML applied succesfully
                    </Typography>
                  </Box>
                </>
              }
            </DialogTitle>
          </CustomDialog>
        </>
      )}
    </>
  );
};

export default GenerateYaml;
