import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import SessionReducer from './SessionReducer';
import MenuReducer from './MenuReducer';
import SliceReducer from './SliceReducer';
import SpinnerReducer from './SpinnerReducer';
import QOSProfileReducer from './QOSProfileReducer';
import DashboardReducer from './DashboardReducer';
import ErrorReducer from './ErrorReducer';
import ErrorSnackReducer from './ErrorSnackReducer';
import RolesReducer from './RolesReducer';
import ProfileReducer from './ProfileReducer';
import SliceFilterReducer from './SliceFilterReducer';
import QOSProfileFilterReducer from './QOSProfileFilterReducer';
import EnforcementPointsReducer from './EnforcementPointsReducer';
import SlicePolicyReducer from './SlicePolicyReducer';
import trainingEventsReducer from './trainingEventsReducer';
import EnvironmentReducer from './EnvironmentReducer';
import UserReducer from './UserReducer';
import PrometheusReducer from './PrometheusReducer';
import popupReducer from './popupReducer';
import HeirarchicalNamespaceReducer from './HeirarchicalNamespaceReducer';
import AppOnboardingReducer from './AppOnboardingReducer';
import RegisterClusterReducer from './RegisterClusterReducer';
import ConnectClusterReducer from './ConnectClusterReducer';
import NamespaceOnboardReducer from './NamespaceOnboardReducer';
import ResourceQuotaReducer from './ResourceQuotaReducer';
import NodeAffinityReducer from './NodeAffinityReducer';
import RbacReducer from './RbacReducer';
import LicensingReducer from './licensing.reducer';

const config = {
  key: 'root',
  storage,
  blacklist: [
    'SliceFilterReducer',
    'SpinnerReducer',
    'ErrorReducer',
    'ErrorSnackReducer',
  ],
};
export default persistCombineReducers(config, {
  RegisterCluster: RegisterClusterReducer,
  ConnectCluster: ConnectClusterReducer,
  NamespaceOnboard: NamespaceOnboardReducer,
  popup: popupReducer,
  EnvironmentReducer,
  trainingEventsReducer,
  SlicePolicyReducer,
  ProfileReducer,
  SessionReducer,
  MenuReducer,
  slices: SliceReducer,
  SpinnerReducer,
  resourceQuota: ResourceQuotaReducer,
  qosProfiles: QOSProfileReducer,
  dashboard: DashboardReducer,
  ErrorReducer,
  ErrorSnackReducer,
  roles: RolesReducer,
  SliceFilterReducer,
  QOSProfileFilterReducer,
  enforcementPoints: EnforcementPointsReducer,
  UserReducer,
  PrometheusReducer,
  NodeAffinity: NodeAffinityReducer,
  hnc: HeirarchicalNamespaceReducer,
  AppOnboardingReducer,
  RbacReducer: RbacReducer,
  licensing: LicensingReducer,
});
