import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRegisteredClusters } from '../../actions/RegisterClusterActions';
import {
  clearCurrentResourceQuota,
  getResourceQuotaList,
} from '../../actions/ResourceQuotaActions';
import NoSlice from '../../assets/images/no-slice-namespace.png';
import SearchBar from '../AppOnboarding/AddDialog/SearchBar';
import GeneralLayout from '../Commons/GeneralLayout';
import './index.css';
import Row from './Row';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common?.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common?.black,
    padding: '16px',
  },
}));

const SortCellHeader = ({ title, units }) => {
  return (
    <>
      <div>
        {title} {units}
      </div>
    </>
  );
};

const ManageResource = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearCurrentResourceQuota());
    dispatch(getResourceQuotaList());
    dispatch(getRegisteredClusters());
  }, [dispatch]);

  const resourceQuotaList = useSelector(
    (state) => state.resourceQuota.resourceQuotaList
  );
  const currentSlice = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );

  useEffect(() => {
    const rowsFiltered = resourceQuotaList.filter(
      (slice) => slice?.spec?.namespaceIsolationProfile?.applicationNamespaces
    );

    setRows(rowsFiltered);
  }, [resourceQuotaList]);

  const haveRows = rows && rows.length > 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRedirectToNewUi = (redirect_uri) => {
    window.location.href = `/${redirect_uri}`;
  };

  return (
    <GeneralLayout description={'Resource Quotas'}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          border: '1px solid #B6B8D6',
          minHeight: '700px',
          position: 'relative',
        }}
      >
        <div>
          <div className="table-header">
            <SearchBar
              placeholder="Search Slice name"
              source="manage_resources"
              currentData={resourceQuotaList}
              setRowsData={setRows}
              rows={rows}
            />
            <Button
              style={{ width: '184px' }}
              data-testid="btnEditResourceQuota"
              disabled={Object.keys(currentSlice).length === 0}
              onClick={() => history.push('/manage-resources/add/1')}
              style={{
                fontFamily: 'Bai Jamjuree',
                background:
                  Object.keys(currentSlice).length === 0 ? '' : '#2868F3',
                textTransform: 'none',
              }}
              // className="btn"
              variant="contained"
            >
              {_.has(currentSlice, 'isNew') && currentSlice.isNew
                ? 'Add Resource Quota'
                : 'Edit Resource Quota'}
            </Button>
          </div>
          <TableContainer
            sx={{ display: 'flex', flex: haveRows ? 0 : 1 }}
            className="table-container"
          >
            <Table aria-label="collapsible table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell
                    sx={{
                      padding: '15px 0 15px 24px',
                      lineHeight: '15px',
                      fontSize: '12px',
                      fotnWeight: '600',
                      maxWidth: '48px',
                    }}
                  />
                  <TableCell
                    className="txt-bold ft-12"
                    sx={{
                      padding: '11.5px 4px 11.5px 24px',
                      fontSize: '12px',
                      fotnWeight: '600',
                      maxWidth: '170px',
                      minWidth: '170px',
                    }}
                    data-testid="SliceNameManageResource"
                  >
                    <div className="cell-flex">
                      <div style={{ marginRight: '8px' }}>SLICE NAME</div>
                      <BootstrapTooltip
                        placement="top-start"
                        title={
                          <>
                            <span className="success-dot" />
                            Enforced
                          </>
                        }
                      >
                        <span className="info-container">
                          <span
                            className="success-dot"
                            style={{
                              height: '8px',
                              width: '8px',
                              marginRight: '2px',
                            }}
                          />
                          <InfoIcon
                            sx={{
                              color: '#81839A',
                              fontSize: '10px',
                              height: '10px',
                              width: '10px',
                            }}
                          />
                        </span>
                      </BootstrapTooltip>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '15px 4px 15px 24px',
                      lineHeight: '15px',
                      fontSize: '12px',
                      fotnWeight: '600',
                      minWidth: '18%',
                    }}
                    data-testid="PodsManageResource"
                  >
                    NO.OF PODS
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '15px 0 15px 24px',
                      lineHeight: '15px',
                      fontSize: '12px',
                      fotnWeight: '600',
                    }}
                    data-testid="CpuManageResource"
                  >
                    <SortCellHeader title={'CPU '} units={'(m)'} />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '15px 0 15px 24px',
                      lineHeight: '15px',
                      fontSize: '12px',
                      fotnWeight: '600',
                    }}
                    data-testid="MemoryManageResource"
                  >
                    <SortCellHeader title={'Memory '} units={'(Mi)'} />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '15px 0 15px 24px',
                      lineHeight: '15px',
                      fontSize: '12px',
                      fotnWeight: '600',
                    }}
                    data-testid="EStorageManageResource"
                  >
                    <SortCellHeader
                      title={'EPHEMERAL STORAGE '}
                      units={'(m)'}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-testid="TableManageResource">
                {haveRows ? (
                  rows.map((slice) => {
                    let enabled = false;

                    if (slice?.spec?.resourceQuota?.spec?.clusterQuota) {
                      slice?.spec?.resourceQuota?.spec?.clusterQuota.forEach(
                        (cluster) => {
                          if (cluster.namespaceQuota) {
                            cluster.namespaceQuota.forEach((namespace) => {
                              if (!enabled) {
                                enabled = namespace.enforceQuota;
                              }
                            });
                          }
                        }
                      );
                    }

                    return (
                      <Row
                        enabled={enabled}
                        key={slice.metadata?.name}
                        slice={slice}
                      />
                    );
                  })
                ) : (
                  <TableRow style={{ textAlign: 'center' }}>
                    <TableCell colSpan={6}>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          src={NoSlice}
                          width="150"
                          height="150"
                          alt="No slice for namespace"
                        />
                      </div>
                      {/* <Typography style={{ textAlign: 'center' }}>
                        {`Create Slices under Slices and`}
                      </Typography> */}
                      <Typography
                        className="no-slice-txt-isolated"
                        variant="subtitle2"
                        component="div"
                        style={{ textAlign: 'center' }}
                      >
                        {`Create Slices and onboard Namespaces before defining Resource Quotas `}
                      </Typography>
                      <Box
                        sx={{
                          marginTop: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          style={{ width: 'auto' }}
                          onClick={() => handleRedirectToNewUi('slices')}
                          className="btn"
                          variant="contained"
                        >
                          Go to Slices
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {haveRows && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </GeneralLayout>
  );
};

export default ManageResource;
