import React from 'react';
import { getMarkerEnd } from 'react-flow-renderer';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) {
  const edgePath = `M${sourceX},${sourceY} ${targetX},${targetY}`
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <rect width="17.61328125" x={(targetX+sourceX)/2} y={(targetY+sourceY)/2} height="20.5" class="react-flow__edge-textbg" rx="2" ry="2"></rect>
      <text class="react-flow__edge-text" x={(targetX+sourceX)/2} y={(targetY+sourceY)/2}dy=".3em">
          abc
      </text>
    </>
  );
}