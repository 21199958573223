/* eslint-disable eqeqeq */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Finalize from './Finalize';
import GenerateYaml from './GenerateYaml';
import CodePreview from './GenerateYaml/CodePreview';
import './index.css';
import InitialStep from './InitialStep';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../../Namespaces/Steps/index.css';

export const StepsHeader = ({ currentStep, stepInfo }) => {
  return (
    <Grid sx={{ flexBasis: 'auto', marginBottom: '24px' }} item xs={12}>
      <Grid container className="step-container">
        {stepInfo.map((thisStep, index) => {
          return (
            <Grid
              item
              onClick={thisStep.callback}
              className={`grid-item-base ${currentStep === index + 1
                  ? `grid-item-current`
                  : currentStep > index + 1
                  ? `grid-item-completed`
                  : ''
              }`}
              xs={12 / stepInfo.length}
            >
              <Typography
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                className="step-title"
                gutterBottom
                component="div"
              >
                {currentStep > index + 1 && (
                  <CheckCircleIcon width={'24px'} height={'24px'} />
                )}
                {thisStep.title}
              </Typography>
              <Typography
                className="step-subtitle"
                gutterBottom
                component="div"
              >
                {thisStep.subtitle}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

function Steps() {
  const [close, setClose] = useState(true);

  const { stepNo } = useParams();
  const [currHeight, setCurrHeight] = useState('60px');
  const currentResourceQuota = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );
  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  const sliceDataNAme = localStorage.getItem('currentSlice');
  const [Breadcrumb, setBreadcrumb] = useState('');

  return (
    <>
      <Box
        container
        sx={{
          position: 'relative',
        }}
        direction="column"
      >
        <Grid item className="header">
          <Paper className="paper-header">
            <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px', // Adjust the font size as per your requirement
                lineHeight: '20px',
                color: '#6E708E',
                paddingTop: '4px',
              }}
              variant="subtitle1"
            >
              {currentProjectData?.projectNamespace}
              <span> / Resource Quotas / </span>
              <span style={{ fontWeight: 600 }}>
                {' '}
                {Breadcrumb !== '' && Breadcrumb}
              </span>
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            flex: '1 1 0%',
            padding: '20px',
            paddingTop: '16px',
            overflowY: 'scroll',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <StepsHeader
            currentStep={parseInt(stepNo)}
            stepInfo={[
              {
                title: `Step 1 - Set slice quotas`,
                subtitle: `Set resource quotas at the Slice level across all clusters`,
              },
              {
                title: `Step 2 - Enforce quotas`,
                subtitle: `Select which namespaces have quotas enforced`,
              },
              {
                title: `Step 3 - Generate YAML and Apply`,
                subtitle: `Apply Slice YAML to set these quotas in Kubernetes`,
              },
              {
                title: `Step 4 - Finalize`,
                subtitle: `Display the final status`,
              },
            ]}
          />
          <Grid
            className="scroll-container"
            sx={{
              flex: '1 1 0%',
              marginBottom: stepNo === '3' ? '120px' : '60px',
            }}
          >
            {stepNo == 1 && (
              <InitialStep step={stepNo} setBreadcrumb={setBreadcrumb} />
            )}
            {stepNo == 2 && (
              <InitialStep step={stepNo} setBreadcrumb={setBreadcrumb} />
            )}
            {stepNo == 3 && <GenerateYaml setClose={setClose} />}
            {stepNo == 4 && <Finalize />}
          </Grid>
          {stepNo == 3 && (
            <Grid
              item
              sx={{
                position: 'absolute',
                bottom: '60px',
                width: '100%',
                height: currHeight,
                right: '0',
                overflow: 'hidden',
                boxSizing: 'border-box',
              }}
            >
              <CodePreview
                currentResourceQuota={currentResourceQuota}
                setClose={setClose}
                close={close}
                setCurrHeight={setCurrHeight}
                currHeight={currHeight}
                withMinimize
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default Steps;
