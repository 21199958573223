import * as Yup from 'yup';
import { nullifyEmptyString } from '../InitialStep/ManageResourceTable/utils';
export const schema = Yup.object().shape(
  {
    can_remove_default_cpu_request: Yup.boolean(),
    can_remove_default_memory_request: Yup.boolean(),
    can_remove_default_storage_request: Yup.boolean(),
    'Pods Count': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(1)
      .label('This value'),
    'CPU Limit': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .label('This value'),
    'CPU Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('Container CPU Request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when default CPU request is entered'
          )
          .required(
            'This value is required when default CPU request is entered'
          ),
      })
      .when('CPU Limit', {
        is: (value) => !!value,
        then: Yup.number()
          .max(Yup.ref('CPU Limit'), 'This value should be less than CPU limit')
          .nullable()
          .transform(nullifyEmptyString),
      })
      .label('CPU Request'),
    'Container CPU Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('CPU Request', {
        is: (value) => !!value,
        then: Yup.number()
          // when CPU request is there, this value is always required
          .typeError('This value is required when Cpu request is entered')
          .required('This value is required when Cpu request is entered')
          .max(
            Yup.ref('CPU Request'),
            'This value should be less than or equal to CPU request'
          ),
      })
      .when('can_remove_default_cpu_request', {
        is: (value) => value === false,
        then: Yup.number()
          .typeError(
            'This value is required as it used as a default for some namespaces.'
          )
          .required(
            'This value is required as it used as a default for some namespaces.'
          ),
      })

      .label('This value'),
    'Memory Limit': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .label('This value'),
    'Memory Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('Container Memory Request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when default memory request is entered'
          )
          .required(
            'This value is required when default memory request is entered'
          ),
      })
      .when('Memory Limit', {
        is: (value) => !!value,
        then: Yup.number()
          .nullable()
          .transform(nullifyEmptyString)
          .max(
            Yup.ref('Memory Limit'),
            'This value should be less than or equal to Memory Limit'
          ),
      })
      .label('This value'),
    'Container Memory Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('Memory Request', {
        is: (value) => !!value,
        then: Yup.number()
          // when memory request is there, this value is always required
          .max(
            Yup.ref('Memory Request'),
            'This value should be less than or equal to Memory Request'
          )
          .typeError('This value is required when Memory request is entered')
          .required('This value is required when Memory request is entered'),
      })
      .when('can_remove_default_memory_request', {
        is: (value) => value === false,
        then: Yup.number()
          .typeError(
            'This value is required as it used as a default for some namespaces.'
          )
          .required(
            'This value is required as it used as a default for some namespaces.'
          ),
      })
      .label('This value'),
    'Ephemeral Storage Limit': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .label('This value'),
    'Ephemeral Storage Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .min(0)
      .when('Container Storage Request', {
        is: (value) => !!value,
        then: Yup.number()
          .typeError(
            'This value is required when Container Storage request is entered'
          )
          .required(
            'This value is required when Container Storage request is entered'
          ),
      })
      .when('Ephemeral Storage Limit', {
        is: (value) => !!value,
        then: Yup.number()
          .nullable()
          .transform(nullifyEmptyString)
          .max(Yup.ref('Ephemeral Storage Limit')),
      })
      .label('This value'),
    'Container Storage Request': Yup.number()
      .nullable()
      .transform(nullifyEmptyString)
      .when('Ephemeral Storage Request', {
        is: (value) => !!value,
        then: Yup.number()
          .max(
            Yup.ref('Ephemeral Storage Request'),
            'This value should be less than or equal to Storage Request'
          )
          .typeError('This value is required when Storage request is entered')
          .required('This value is required when Storage request is entered'),
      })
      .min(0)
      .when('can_remove_default_storage_request', {
        is: (value) => value === false,
        then: Yup.number()
          .typeError(
            'This value is required as it used as a default for some namespaces.'
          )
          .required(
            'This value is required as it used as a default for some namespaces.'
          ),
      })
      .label('This value'),
  },
  [
    ['CPU Request', 'Container CPU Request'],
    ['can_remove_default_cpu_request', 'Container CPU Request'],
    ['CPU Limit', 'CPU Request'],
    ['Memory Limit', 'Memory Request'],
    ['Ephemeral Storage Limit', 'Ephemeral Storage Request'],
    ['Memory Request', 'Container Memory Request'],
    ['can_remove_default_memory_request', 'Container Memory Request'],
    ['Ephemeral Storage Request', 'Container Storage Request'],
    ['can_remove_default_storage_request', 'Container Storage Request'],
  ]
);
