import React, { useRef } from 'react';
import ReactEcharts from "echarts-for-react";
import './Health.css';
import moment from "moment";
import { PIE_COLORS } from '.';
import _ from 'lodash';

const PieChart = ({ data = [], sliceName = '', errorStates = [], clusterArrData = [], isCluster = false, height = '300px', width = '360px', customClass = '' }) => {

    const colorPalette = [];
    const statusArr = [];

    const colors = data.map((item) => {
        colorPalette.push(PIE_COLORS[item.componentHealthStatus])
        statusArr.push(item.componentHealthStatus);
        return PIE_COLORS[item.componentHealthStatus]
    })

    const getToolTipData = (errors) => {
        return errors.map((errorItem) => {
            return `<p>${errorItem.component}</p>`
        })
    }

    const option = {
        series: [
            {
                name: 'Access From',
                type: 'pie',
                selectedMode: 'center',
                radius: [0, '30%'],
                label: {
                    position: 'center',
                    fontSize: isCluster ? '8px' : '18px',
                    color: statusArr.includes('Error') ? '#DD4B4B' : '#14974C'

                },
                color: statusArr.includes('Error') ? '#ECA0A5' : '#7DD6D8',
                data: [
                    { value: 1, name: statusArr.includes('Error') ? 'Warning' : 'Normal' },
                ],
                tooltip: {
                    trigger: 'none'
                },
            },
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                color: colors,
                itemStyle: {
                    borderRadius: 2,
                    borderColor: '#E9FAF6',
                    borderWidth: 5
                },
                label: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true,
                    }
                },
                labelLine: {
                    show: false
                },
                data: data,
                tooltip: {
                    trigger: isCluster ? 'none' : 'item'
                },
            }
        ],
        graph: {
            color: colorPalette
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params) {
                const errorData = errorStates.filter((errorItem) => {
                    if (errorItem.component === params.data.component) {
                        return {
                            ...params.data,
                            name: errorItem.name
                        }
                    }
                })

                let updatedErrorData = []
                clusterArrData.map((item) => {
                    const currentTime = moment().utc().format();;
                    const lastUpdatedTime = item.lastUpdated;
                    const diffMinutes = moment(currentTime).diff(lastUpdatedTime, 'minutes');
                    const clusterName = `${sliceName}-${item.clusterName}`
                    errorData.map((errorItem) => {
                        if (clusterName == errorItem.name && diffMinutes > 3) {
                          const itemExists = updatedErrorData.find(
                            (item) => item.name === errorItem.name
                          );
                          if (itemExists) {
                            updatedErrorData = updatedErrorData.map((item) => {
                              if (item.name === errorItem.name) {
                                return {
                                  ...errorItem,
                                  componentHealthStatus: 'Warning',
                                };
                              }
                              return item;
                            });
                          } else {
                            updatedErrorData = [
                              ...updatedErrorData,
                              {
                                ...errorItem,
                                componentHealthStatus: 'Warning',
                              },
                            ];
                          }
                        } else {
                          const itemExists = updatedErrorData.find(
                            (item) => item.name === errorItem.name
                          );
                          if (!itemExists) {
                            updatedErrorData.push(errorItem);
                          }
                        }
                    })
                });

                const uniqueErrorWithData = _.uniqWith(updatedErrorData, { componentHealthStatus: 'Normal' })

                return `<div style='display:${isCluster}?'none':'block';width:230px'>
                <p style='font-weight:700;font-size:16px;text-align:left'>
                ${params.data.component}
                </p>
                <hr>
                ${uniqueErrorWithData.map((errorItem) => {
                    return `<div style='font-weight: 500;
                    font-size: 14px;color: #5A607C;display:flex;align-items:center'>
                    <span style='display:block;height:16px;width:16px;margin-right:8px;
                    background-color:${errorItem.componentHealthStatus == 'Error' ? '#ECA0A5' : errorItem.componentHealthStatus == 'Warning' ? '#FFA500' : '#7DD6D8'}'>
                    </span>
                    <span style='text-decoration: ${errorItem.componentHealthStatus == 'Warning' ? 'line-through' : 'auto'}'>${errorItem.name}</span>
                    </div>`
                })}
                </div>`
            }
        },
    };

    const chartsRef = useRef();
    return (
        <div className={customClass} style={{ height: height, width: width }}>
            <ReactEcharts ref={chartsRef} option={option} />
        </div>
    )
}

export default PieChart;