import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedRole } from "../../../../actions/RbacActions";
import AlertDialog from "./AlertDialog";
import AssignNamespacesModel from "./AssignNamespacesModel";
import ChooseRoles from "./ChooseRoles";
import ImportRoles from "./ImportRoles";
import "./index.css";
import ViewNamespacesModel from "./ViewNamespacesModel";

const tabStyle = {
  default_tab: {
    color: "#81839A",
  },
  active_tab: {
    color: "#12153E",
    fontWeight: 600,
  },
};

const RoleTab = ({ modalBox, setRoleCount }) => {
  const [searchBy, setSearchBy] = React.useState("roles");
  const [search, setSearch] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [openModel, setOpenModel] = React.useState(false);
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  const selectedRole = useSelector((state) => state.RbacReducer.selectedRole);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveIndex(newValue);
    dispatch(clearSelectedRole());
  };
  const handleModalClose = (del = false) => {
    if (del == false) {
      setOpenAlertModal(false);
    } else {
      setOpenAlertModal(false);
      setOpenModel(false);
    }
  };
  const searchNamespace = (event) => {
    setSearchText(event.target.value);
    setSearch(true);
  };
  const openModal = () => {
    setOpenModel(true);
  };

  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };
  useEffect(() => {
    dispatch(clearSelectedRole());
  }, [dispatch]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box>
        <AssignNamespacesModel
          open={openModel}
          setOpenModel={setOpenModel}
          setOpenAlertModal={setOpenAlertModal}
          value={value}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
        />
        <ViewNamespacesModel
          open={openViewModal}
          setOpenViewModal={setOpenViewModal}
        />
        <AlertDialog
          open={openAlertModal}
          handleClose={handleModalClose}
          setIsChanged={setIsChanged}
        />
        <div className="tab-component">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"
              TabIndicatorProps={{
                style: { backgroundColor: "#2868F3", fontWeight: "600" },
              }}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Tab
                className="tab-content"
                label="Roles"
                {...a11yProps(0)}
                style={getStyle(activeIndex == 0)}
                sx={{ "&.MuiTab-root":{minWidth:'0px'}, padding: '24px 0px 12px 0px', margin: '0px 12px 0px 12px'}}
              />
              <Tab
                className="tab-content"
                label="Imported K8s Roles"
                {...a11yProps(1)}
                style={getStyle(activeIndex == 1)}
                sx={{padding: '24px 0px 12px 0px', margin: '0px 12px 0px 12px'}}
              />
            </Tabs>
          </Box>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                height: "40px",
                overflow: "hidden",
                border: "1px solid #B6B8D6",
                marginRight: "24px",
                borderRadius: "4px",
              }}
            >
              <FormControl
                className="filter-dropdown"
                sx={{ backgroundColor: "#E6E8F3", top: "0px", height: "44px" }}
              >
                <InputLabel id="search-by-label"></InputLabel>
                <Select
                  labelId="search-by-label"
                  id="search-by"
                  value={searchBy}
                  onChange={(e) => setSearchBy(e.target.value)}
                  label="Search By"
                  style={{ height: "40px" }}
                >
                  <MenuItem value="roles">Roles</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="userGroup">User Group</MenuItem>
                </Select>
              </FormControl>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 250,
                  borderRadius: "0 5px 5px 0",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  onChange={searchNamespace}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      setSearch(true);
                    }
                  }}
                  inputProps={{ "aria-label": "search google maps" }}
                />
                <IconButton
                  className="search-icon"
                  onClick={() => setSearch(true)}
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            {modalBox !== true && (
              <Button
                disabled={selectedRole?.length <= 0}
                className="btn-style-step2"
                variant="contained"
                onClick={openModal}
              >
                Assign Namespace
              </Button>
            )}
          </div>
        </div>

        <TabPanel value={value} index={0} className="rbac-tab">
          <ChooseRoles
            modalBox={modalBox}
            setRoleCount={setRoleCount}
            search={search}
            searchBy={searchBy}
            searchText={searchText}
            setOpenViewModal={setOpenViewModal}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ImportRoles
            modalBox={modalBox}
            search={search}
            searchBy={searchBy}
            searchText={searchText}
            setOpenViewModal={setOpenViewModal}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default RoleTab;
