//import { SdkBackend } from "../backend/MeshBackend";
import {
  CLEAR_CURRENT_SLICE,
  OFFBOARD_NAMESPACE,
  SET_CURRENT_SLICE,
} from './types';
//const sdkBackend = new SdkBackend();
import { SdkBackend } from '../backend/MeshBackend';

const sdkBackend = new SdkBackend();

export const setCurrentSlice = (payload) => {
  return {
    type: SET_CURRENT_SLICE,
    payload,
  };
};

export const clearCurrentSlice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CURRENT_SLICE,
      payload: {},
    });
  };
};

export const OffboardNamespaces = (namespace) => {
  return (dispatch) => {
    dispatch({
      type: OFFBOARD_NAMESPACE,
      payload: { namespace },
    });
  };
};

export const updateNamespaceIsolationProfile = (sliceName, value) => {
  return async (dispatch, getState) => {
    // let response = await meshBackend.updateSliceNamespaceIsolationProfile(
    //   getState(),
    //   dispatch,
    //   sliceName,
    //   value
    // );
    // dispatch(getAllSlices());
    // return response;
  };
};

export const removeNamespacesToSlice = (query, socketId) => {
  return async (dispatch, getState) => {
    await sdkBackend.removeNamespacesToSlice(
      getState(),
      dispatch,
      query,
      socketId
    );
  };
};
export const getSliceObject = (sliceName, updatedSlice) => {
  return async (dispatch, getState) => {
    try {
      let response = await sdkBackend.getSliceObject(
        getState(),
        dispatch,
        sliceName
      );
      if (response) {
        let identifier = response.status?.kubesliceEvents || [];
        const filteredEvents = identifier.filter((data) => {
          return data.type === 'InProgress';
        });
        updatedSlice['status']['kubesliceEvents'] = filteredEvents;
        setTimeout(() => dispatch(setCurrentSlice(updatedSlice)), 1000);
        return response;
      }
    } catch (error) {}
  };
};

export const getSliceObjectData = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getSliceObject(
      getState(),
      dispatch,
      sliceName
    );
    if (response) return response;
  };
};

export const fetchSliceObject = (sliceName) => {
  return async (dispatch, getState) => {
    const endpoint = `/slice/slice-name/${sliceName}`;
    try {
      const response = await this.getApiCall(
        getState(),
        dispatch,
        endpoint,
        null,
        'Unable to get slice'
      );
      dispatch({
        type: 'slice/fetchSliceObject/success',
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: 'slice/fetchSliceObject/failure',
        payload: error.message,
      });
    }
  };
};
