import { Close } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactComponent as Shield } from '../../../assets/icons/shield.svg';
import { license_status } from '../../../constants/license';
import {
  selectLicenseDetails,
  selectLicenseStatus,
} from '../../../selectors/licensing.selector';
import { formatDate } from '../../../utils/date-time';
import LicenseId from '../LicenseId/LicenseId';
import { InvalidModal } from '../Modals/InvalidModal';
import { isEnterpriseLicense } from '../../../utils/license';

// everything is inline beacuse we need to do this in v2 as well
const LicenceDetails = ({ open, handleClose }) => {
  const licenseDetails = useSelector(selectLicenseDetails);
  const licenseStatus = useSelector(selectLicenseStatus);

  if (!licenseDetails || licenseStatus === license_status.invalid) {
    return <InvalidModal open={open} handleClose={handleClose} />;
  }

  const {
    'license-type': licenseType,
    'license-expiration': expiratioDate,
    'license-created': creationDate,
    'license-updated': updatedDate,
    'license-kind': licenseKind,
  } = licenseDetails;

  const expired = licenseStatus === license_status.expired;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: 'none',
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white !important',
          width: '544px',
          px: 3,
          py: '34px',
          borderTop: '3px solid #C47616',
          '&:focus': {
            outline: 'none',
          },
        }}>
        <Box
          sx={{
            position: 'relative',
          }}>
          <Button
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '0',
              right: '0',
              minWidth: '24px',
            }}>
            <Close
              sx={{
                height: '24px',
                width: '24px',
                color: '#12153E',
              }}
            />
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3,
            }}>
            <Shield
              style={{
                height: '84px',
              }}
            />
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              my: 0,
              pt: '12px',
              pb: 0,
              fontWeight: '600',
              color: '#2868f3',
            }}
            component="p">
            {licenseType}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              py: 3,
            }}>
            <LicenseId />
          </Box>
          <Box>
            <Box
              sx={{
                borderBottom: '1px solid #E0E1ED',
              }}>
              <Box
                component="p"
                sx={{
                  fontWeight: '500',
                }}>
                License Information
              </Box>
            </Box>
            <Box
              sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}>
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Box
                  sx={{
                    width: '35%',
                    color: '#5A607C',
                  }}>
                  License Type:{' '}
                </Box>
                <Box
                  sx={{
                    fontWeight: '600',
                    width: '65%%',
                    textTransform: 'uppercase',
                    color: '#2868f3',
                  }}
                  component="span">
                  {licenseKind}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Box
                  sx={{
                    width: '35%',
                    color: '#5A607C',
                  }}>
                  License Policy:{' '}
                </Box>
                <Box
                  sx={{
                    fontWeight: '500',
                    width: '65%',
                  }}
                  component="span">
                  {licenseType}
                </Box>
              </Box>
              {isEnterpriseLicense(licenseDetails) && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                    }}>
                    <Box
                      sx={{
                        width: '35%',
                        color: '#5A607C',
                      }}>
                      Purchased vCPU :{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: '500',
                        width: '65%',
                      }}
                      component="span">
                      {licenseDetails['vcpu-count']}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                    }}>
                    <Box
                      sx={{
                        width: '35%',
                        color: '#5A607C',
                      }}>
                      Overage Percentage:{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: '500',
                        width: '65%',
                      }}
                      component="span">
                      {licenseDetails['overage']}%
                    </Box>
                  </Box>
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Box
                  sx={{
                    width: '35%',
                    color: '#5A607C',
                  }}>
                  Issued On:{' '}
                </Box>
                <Box
                  sx={{
                    fontWeight: '500',
                    width: '65%',
                  }}
                  component="span">
                  {formatDate(creationDate)}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Box
                  sx={{
                    width: '35%',
                    color: '#5A607C',
                  }}>
                  {expired ? 'Expired On:' : 'Expiring On:'}
                </Box>
                <Box
                  sx={{
                    fontWeight: '500',
                    width: '65%',
                  }}
                  component="span">
                  {' '}
                  {formatDate(expiratioDate)}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                }}>
                <Box
                  sx={{
                    width: '35%',
                    color: '#5A607C',
                  }}>
                  Updated At:{' '}
                </Box>
                <Box
                  sx={{
                    fontWeight: '500',
                    width: '65%',
                  }}
                  component="span">
                  {' '}
                  {formatDate(updatedDate)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default LicenceDetails;
