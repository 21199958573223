import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Finalize from './Finalize';
import GenerateYaml from './GenerateYaml';
import CodePreview from './GenerateYaml/CodePreview';
import './index.css';
import InitialStep from './InitialStep';
import SliceTable from './InitialStep/SliceTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Steps(props) {
  const [close, setClose] = useState(true);
  const { stepNo } = useParams();
  const history = useHistory();
  const [currHeight, setCurrHeight] = useState('30vh');
  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);
  const sliceDataNAme = localStorage.getItem('currentSlice');

  return (
    <>
      <Grid style={{ height: '100vh', overflow: 'scroll' }}>
        {/* <Grid item className="header">
          <Paper
            sx={{ display: "flex", alignItems: "center" }}
            className="paper-header"
          >
            <Link to="/manage-namespaces" data-testid="back-btn">
              <ArrowBackIcon className="BackArrow" />
            </Link>
            <Typography
              sx={{ fontWeight: 600, fontSize: "24px", marginLeft: "12px" }}
              gutterBottom
              component="div"
            >
        {currentProjectData?.projectNamespace}
            </Typography>
            <span>/ Namespaces /{sliceDataNAme}</span>
          </Paper>
        </Grid> */}
        <Grid item className="header">
          <Paper className="paper-header">
            <Typography
              sx={{
                fontFamily: 'Bai Jamjuree',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px', // Adjust the font size as per your requirement
                lineHeight: '20px',
                color: '#6E708E',
                paddingTop: '4px',
              }}
              variant="subtitle1"
            >
              {currentProjectData?.projectNamespace}
              <span> / Namespaces / </span>
              <span style={{ fontWeight: 600 }}>{sliceDataNAme}</span>
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className="step-main-container-add-namespace"
        >
          <Grid sx={{ flexBasis: 'auto', marginBottom: '24px' }} item xs={12}>
            <Grid container className="step-container">
              <Grid
                item
                onClick={() => history.push('/manage-namespaces/add/1')}
                className={`grid-item-base ${
                  stepNo === '1' ? `grid-item-current` : `grid-item-completed`
                }`}
                xs={4}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className="step-title"
                  gutterBottom
                  component="div"
                >
                  {stepNo !== '1' && (
                    <CheckCircleIcon width={'24px'} height={'24px'} />
                  )}

                  {`Step 1 - Add Namespaces`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Choose namespaces to be put on the Slice from each cluster`}
                </Typography>
              </Grid>
              <Grid
                item
                onClick={() => history.push('/manage-namespaces/add/2')}
                className={`grid-item-base ${
                  stepNo === '2'
                    ? `grid-item-current`
                    : stepNo === '3'
                    ? `grid-item-completed`
                    : ''
                }`}
                xs={4}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className="step-title"
                  gutterBottom
                  component="div"
                >
                  {stepNo === '3' && (
                    <CheckCircleIcon width={'24px'} height={'24px'} />
                  )}
                  {`Step 2 -Generate YAML and Apply`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Generate Slice YAML with all namespaces for each cluster`}
                </Typography>
              </Grid>
              <Grid
                item
                onClick={() => history.push('/manage-namespaces/add/3')}
                className={`grid-item-base ${
                  stepNo === '3' ? `grid-item-current` : ``
                }`}
                xs={4}
              >
                <Typography
                  sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  className="step-title"
                  gutterBottom
                  component="div"
                >
                  {`Step 3 - Finalize`}
                </Typography>
                <Typography
                  className="step-subtitle"
                  gutterBottom
                  component="div"
                >
                  {`Display Final state`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {stepNo === '1' && (
            <>
              <Grid
                sx={{ flexBasis: 'auto', marginBottom: '24px' }}
                className="step-grid-item"
                item
                xs={12}
              >
                <SliceTable />
              </Grid>
              <Grid item xs={12}>
                <InitialStep />
              </Grid>
            </>
          )}
          {stepNo === '2' && <GenerateYaml setClose={setClose} />}
          {stepNo === '3' && <Finalize />}
        </Grid>
        {stepNo === '2' && (
          <Grid
            item
            style={{
              position: 'fixed',
              bottom: '67px',
              width: 'calc(100% - 220px)',
              height: currHeight,
            }}
          >
            <CodePreview
              setClose={setClose}
              close={close}
              setCurrHeight={setCurrHeight}
              currHeight={currHeight}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Steps;
