export const search_labels_by = {
  label: 'label',
  cluster: 'cluster',
};

export const stringifyLabel = (lable) => {
  const [key, value] = Object.entries(lable)[0];
  return `${key ?? ''}: ${value ?? ''}`;
};

export const labelToSelectorLabel = (label) => {
  const [key, value] = Object.entries(label)[0];
  return {
    key,
    operator: 'In',
    values: [value],
  };
};
