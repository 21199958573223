import * as React from 'react';
import azure from '../../assets/images/azure.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import azureLogo from '../../assets/images/azureLogo.svg';

export const AzureTooltip = (props) => {
  return <CloudTooltipCommon {...props} isAzure logo={azureLogo} img={azure} />;
};

function Cloud(props) {
  return <CommonCloud {...props} img={azure} isSVG />;
}

export default Cloud;
