import { Paper } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import { getAllClustersForSlice } from '../../../actions/SliceActions';
import PieChart from './PieChart';
import NoNetwork from './NoNetwork.jsx';

export const PIE_COLORS = {
  Normal: '#7DD6D8',
  Error: '#ECA0A5',
  Warning: '#fcca03',
};

const Health = ({ storeProps }) => {
  const dispatch = useDispatch();
  const sliceName = storeProps?.activeSelectedSlice?.sliceName;
  const MINUTE_MS = 150000;

  useEffect(() => {
    if (sliceName != undefined) {
      dispatch(getAllClustersForSlice(sliceName));
      dispatch(getRegisteredClusters());
    }
  }, [sliceName]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliceName != undefined) {
        dispatch(getAllClustersForSlice(sliceName));
        dispatch(getRegisteredClusters());
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [sliceName]);

  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);

  const sliceData = useSelector((state) => state.slices.sliceClusters);

  const [isNoNetwork, setIsNoNetwork] = useState(false);


  useEffect(()=>{
    if(sliceData){
      if(sliceData?.items?.[0]?.spec?.overlayNetworkDeploymentMode === 'no-network') {
        setIsNoNetwork(true)
      }else{
        setIsNoNetwork(false);
      }
    }
  }, [sliceData])

 
  const clusterArrData = [];

  const sliceWorkerData =
    sliceData?.items?.map((sliceItem) => {
      return sliceItem?.metadata?.labels['worker-cluster'];
    }) || [];

  clusterList?.items?.forEach((cluster) => {
    if (_.has(cluster, 'status')) {
      if (sliceWorkerData.includes(cluster?.metadata?.name)) {
        const sliceClusterData =
        cluster?.status?.clusterHealth?.componentStatuses &&
          cluster?.status?.clusterHealth?.componentStatuses.map(
            (componentItem) => {
              return {
                ...componentItem,
                value: 1,
              };
            }
          ) || [];
        clusterArrData.push({
          data: sliceClusterData,
          lastUpdated: cluster?.status?.clusterHealth?.lastUpdated,
          clusterName: cluster?.metadata?.name,
          cloudName:
            cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'LINODE' ||
            cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'Akamai'
              : cluster?.spec?.clusterProperty?.geoLocation?.cloudProvider,
        });
      }
    }
  });
  
  if(isNoNetwork){
    return <NoNetwork sliceName={sliceName} sliceData={sliceData} clusterList={clusterList} />
  }

  const slicePieDataArr = [];

  sliceData?.items?.forEach((slice) => {
    clusterArrData.map((clusterItem) => {
      const currentTime = moment().utc().format();
      const lastUpdatedTime = clusterItem.lastUpdated;
      const diffMinutes = moment(currentTime).diff(lastUpdatedTime, 'minutes');
      if (_.has(slice, 'status')) {
        slice.status?.sliceHealth?.componentStatuses?.forEach((component) => {
          if (
            slice.metadata.labels['worker-cluster'] ===
              clusterItem.clusterName &&
            diffMinutes > 3
          ) {
            slicePieDataArr.push({
              ...component,
              componentHealthStatus: 'Error',
              value: 1,
            });
          } else {
            slicePieDataArr.push({
              ...component,
              value: 1,
            });
          }
        });
      }
    });
  });

  const errorStates = [];

  const sliceNames = sliceData?.items?.map((slice) => {
    slice.status?.sliceHealth?.componentStatuses?.forEach((component) => {
      errorStates.push({
        name: slice.metadata.name,
        ...component,
      });
    });
    return slice.metadata.name;
  });


  const errorUniq = [];
  slicePieDataArr.filter((item) => {
    if (item.componentHealthStatus == 'Error') {
      errorUniq.push(item.component);
    }
  });

  const uniqArr = _.uniqBy(slicePieDataArr, 'component').map((item) => {
    return {
      ...item,
      names: sliceNames,
    };
  });

  const updatedUniqArr = uniqArr.map((item) => {
    if (errorUniq.includes(item.component)) {
      return {
        ...item,
        componentHealthStatus: 'Error',
      };
    }
    return item;
  });

  return (
    <div>
      <Paper
        variant="outlined"
        square
        style={{
          display: 'flex',
          minHeight: 'inherit',
          padding: '16px',
          paddingBottom: '16px',
          borderRadius: '4px',
          borderColor: '#B6B8D6',
        }}
      >
        {updatedUniqArr.length == 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              textAlign: 'center',
              height: '50%',
              width: '90%',
              margin: '150px',
            }}
          >
            No Health status available
          </div>
        ) : (
          <>
            <div
              style={{
                width: '30%',
                display: 'inline-block',
                marginLeft: '20px',
              }}
            >
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#12153E',
                  textAlign: 'initial',
                  marginBottom: '40px',
                }}
              >
                Slice Health
              </p>
              <PieChart
                errorStates={errorStates}
                data={updatedUniqArr}
                clusterArrData={clusterArrData}
                width={'360px'}
                sliceName={sliceName}
                height={'320px'}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexFlow: 'wrap',
                  padding: '20px',
                }}
                data-testid="slice-components"
              >
                {updatedUniqArr.map((slice, i) => {
                  return (
                    <div>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: 2,
                        }}
                      >
                        {' '}
                        <span
                          style={{
                            width: 16,
                            height: 16,
                            display: 'inline-block',
                            marginRight: 10,
                            background: PIE_COLORS[slice.componentHealthStatus],
                          }}
                        />
                        <span
                          style={{
                            width: '130px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textAlign: 'initial',
                            textOverflow: 'ellipsis',
                          }}
                          data-testid={slice.component}
                          data-healthstatus={slice.componentHealthStatus}
                        >
                          {slice.component}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{ width: '64%', display: 'inline-block', marginLeft: 70 }}
            >
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: '#12153E',
                  textAlign: 'initial',
                }}
              >
                Clusters Health
              </p>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {clusterArrData.map((item) => {
                  const currentTime = moment().utc().format();
                  const lastUpdatedTime = item.lastUpdated;
                  const diffMinutes = moment(currentTime).diff(
                    lastUpdatedTime,
                    'minutes'
                  );
                  return (
                    <div
                      data-testid={item.clusterName}
                      style={{
                        display: 'flex',
                        padding: '16px 24px',
                        marginBottom: 10,
                        flexDirection: 'row',
                        border: '1px solid #ACB9FF',
                        borderRadius: 4,
                        alignItems: diffMinutes > 3 ? 'center' : '',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginRight: 40,
                          alignItems: 'flex-start',
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: '#12153E',
                          }}
                        >
                          {item.clusterName}
                        </p>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: 12,
                            color: '#81839A',
                          }}
                        >
                          Cloud Name: {item.cloudName}
                        </p>
                      </div>
                      {diffMinutes > 3 ? (
                        <div style={{ fontWeight: 'bold' }}>
                          Cluster health status not available
                        </div>
                      ) : (
                        <>
                          <PieChart
                            isCluster
                            customClass="charts-row"
                            data={item.data}
                            width={'150px'}
                            height={'100px'}
                          />
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 40,
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flexFlow: 'wrap',
                              width: '350px',
                            }}
                          >
                            {item.data.map((slice, i) => {
                              return (
                                <div>
                                  <p
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      margin: 0,
                                    }}
                                  >
                                    {' '}
                                    <span
                                      style={{
                                        width: 16,
                                        height: 16,
                                        display: 'inline-block',
                                        marginRight: 10,
                                        background:
                                          PIE_COLORS[
                                            slice.componentHealthStatus
                                          ],
                                      }}
                                    />
                                    <span
                                      style={{
                                        width: '130px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textAlign: 'initial',
                                      }}
                                      data-testid={slice.component}
                                      data-healthstatus={
                                        slice.componentHealthStatus
                                      }
                                    >
                                      {slice.component}
                                    </span>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
};

export default Health;
