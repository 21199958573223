import { useEffect, useRef, useState } from 'react';

export const useSSE = ({ url, onMessage, onError }) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const eventSourceRef = useRef();

  useEffect(() => {
    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource(url);

      const eventSource = eventSourceRef.current;

      eventSource.onmessage = (e) => {
        setData(e.data);
        onMessage?.(e.data);
      };
      eventSource.onerror = (e) => {
        setError(e);
        onError?.(e);
      };
    }
    return () => {
      eventSourceRef.current?.close();
      eventSourceRef.current = undefined;
    };
  }, [onError, onMessage, url]);

  return {
    data,
    error,
  };
};
