import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { FooterBtns } from '../FooterBtns';
import NamespaceTable from '../Generic/NamespaceTable';
import SliceTable from '../Generic/SliceTable';

export const Finalize = () => {
  const history = useHistory();

  const handleNext = () => {
    history.push(`/assign-nodes`);
  };
  const handlePrev = () => {
    history.push(`/assign-nodes/3`);
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (
            location.pathname.includes('/assign-nodes/4') ||
            location.pathname.includes('/assign-nodes')
          ) {
            return true;
          } else {
            return 'You have unsaved changes. Do you want to discard them?';
          }
        }}
      />
      <Box sx={{ margin: '10px 40px', overflow: 'auto', flex: '1 1 0%' }}>
        <Grid className="grid-item-main step-grid-item" item xs={12}>
          <SliceTable stepNo={4} />
        </Grid>
        <Grid className="grid-item-main step-grid-item" item xs={12}>
          <NamespaceTable stepNo={4} />
        </Grid>
      </Box>
      <FooterBtns
        handleNext={handleNext}
        nextText="Done"
        handlePrev={handlePrev}
      />
    </>
  );
};
