import { ReportProblem } from '@mui/icons-material';
import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';
import { createLicenseSupportLink } from '../../../utils/license';

// 2023-04-07T11:24:03.602Z

// used material UI because we have to do the same thing in v1
const InvalidLicenseBanner = () => {
  const href = createLicenseSupportLink();

  return (
    <Box
      data-testid="invalid-banner"
      sx={{
        backgroundColor: '#FEFCF9',
        borderLeft: '4px solid #E15E5E',
        py: 2.5,
        px: 3,
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 8px rgba(31, 61, 153, 0.16)',
        alignItems: 'center',
        mb: '2px',
        zIndex: 100, // probably not needed
        position: 'sticky',
        top: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        <ReportProblem
          sx={{
            color: '#E15E5E',
            fontSize: 28,
          }}
        />
        <Box
          sx={{
            color: '#E15E5E',
            fontWeight: 500,
          }}
        >
          <Box data-testid="license-banner-heading">
            Your
            <b> License </b>
            is
            <b> Invalid.</b>
          </Box>

          <Box
            component="p"
            sx={{
              fontSize: '14px',
              mb: 0,
              mt: '8px !important',
              color: '#828490',
              fontWeight: 500,
              borderRadius: '4px',
            }}
          >
            You will not be able to perform actions on any of the items.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Link
          href={href}
          target="_blank"
          underline="none"
          sx={{
            color: '#fff',
            backgroundColor: 'transparent',
            '&:hover': {
              color: '#fff',
              backgroundColor: 'transparent',
              textDecoration: 'none',
            },
          }}
        >
          <Button
            type="button"
            sx={{
              backgroundColor: '#E15E5E',
              px: 2.5,
              py: 1.25,
              color: '#fff',
              borderRadius: '4px',
              fontWeight: 600,
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: '#E15E5E',
              },
            }}
          >
            Contact Support
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default InvalidLicenseBanner;
