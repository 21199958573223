import React, { Component } from 'react';
import { TableRow, TableCell, styled, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

const option = (vals) => {
  const mappedTime = vals.map((el) => moment(el.time).format('hh:mm a'));
  const mappedValue = vals.map((el) => el.value.toFixed(2));

  return {
    title: {
      // text: 'Cluster Details'
    },
    toolbox: {
      feature: {
        // saveAsImage: {},
        // dataZoom: {},
        // restore: {}
      },
    },
    grid: {
      show: true,
      backgroundColor: '#EFF4FF',
      borderColor: 'transparent',
      left: '1%',
      right: '1%',
      bottom: '1%',
      top: '1%',
      containLabel: true,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      alwaysShowContent: false,
      formatter: function (params) {
        let formattedData = [];
        // console.log('Formatter Params: ', params);
        for (let i = 0; i < params.length; i++) {
          formattedData.push(`
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-height: 1.7rem">
                                <div style="margin-right: 1.2rem;">
                                    <span style="display: inline-block; border-radius: 2px; width: 10px; height: 10px; background-color: ${params[i].color}"></span>
                                    ${params[i].axisValueLabel}
                                </div>
                                <p style="margin-left: auto; font-weight: bold; color: '#fff',">
                                    ${params[i].value} %
                                <p>
                            </div>
                        `);
        }
        return `
                        <div style="display: flex; flex-direction: column">
                            ${formattedData.join('')}
                        </div>
                    `;
      },
      padding: [10, 20, 10, 20],
    },
    legend: {
      //   data:['Cluster Data']
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: mappedTime,
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      show: false,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      max: 100,
    },
    series: [
      {
        type: 'line',
        showSymbol: false,
        itemStyle: {
          color: '#2868F3',
        },
        lineStyle: {
          color: '#2868F3',
          width: 1,
        },
        areaStyle: {
          color: '#2868F3',
        },
        data: mappedValue,
      },
    ],
  };
};

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    maxHeight: '4.25rem',
    padding: '1.5rem 0px 1.5rem 1.5rem',
  },
}));

class ClusterTabRow extends Component {
  state = {};
  render() {
    // const data = this.props.row;
    return (
      <TableRow>
        <CustomTableCell>
          <Typography fontSize="14px" fontWeight="600">
            {this.props.row.clusterName}
          </Typography>
        </CustomTableCell>
        <CustomTableCell>
          <Typography fontSize="14px" fontWeight="600">
            {this.props.row.location.cloudProvider.toUpperCase() === 'LINODE' ||
            this.props.row.location.cloudProvider === 'linode'
              ? 'AKAMAI'
              : this.props.row.location.cloudProvider.toUpperCase()}
          </Typography>
        </CustomTableCell>
        <CustomTableCell>
          <ReactECharts
            option={option(this.props.row.usedCapacity)}
            style={{ width: '8.875rem', height: '1.875rem' }}
          />
        </CustomTableCell>
        <CustomTableCell
          onClick={() => this.props.handleK8Nav(this.props.row.clusterName)}
        >
          <Typography
            fontSize="14px"
            fontWeight="600"
            color={'#2868F3'}
            style={{
              cursor: 'pointer',
            }}
          >
            View Dashboard
          </Typography>
        </CustomTableCell>
      </TableRow>
    );
  }
}

export default ClusterTabRow;
