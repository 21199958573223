import { SdkBackend } from '../backend/MeshBackend';
import {
  CLEAR_CURRENT_SLICE,
  EDIT_SLICE,
  SET_CURRENT_SLICE,
  SET_SLICE_LIST,
  SET_SLICE_RESOURCE_VERSION,
  SET_MIGRATION_PAIR,
  SET_MIGRATION_JOB
} from './types';
const sdkBackend = new SdkBackend();

export const createSliceCustomObject = (sliceDetail) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.createSliceCustomObject(
      getState(),
      dispatch,
      sliceDetail
    );

    if (response) {
      dispatch(getSliceCustomObjectList());
      return response;
    }
  };
};

export const updateSliceCustomObject = (sliceDetail) => {
  return async (dispatch, getState) => {
    const response = await sdkBackend.updateSliceCustomObject(
      getState(),
      dispatch,
      sliceDetail
    );
    if (response) return response;
  };
};

export const deleteSliceCustomObject = (sliceName, socketId) => {
  return async (dispatch, getState) => {
    await sdkBackend.deleteSliceCustomObject(
      getState(),
      dispatch,
      sliceName,
      socketId
    );
    // dispatch({ type: DELETE_SLICE, payload: sliceName });
  };
};

export default function removeClusterFromSlice(query, socketId) {
  return async (dispatch, getState) => {
    await sdkBackend.removeClusterFromSlice(
      getState(),
      dispatch,
      query,
      socketId
    );
  };
}

export const getSliceCustomObjectList = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getSliceCustomObjectList(
      getState(),
      dispatch
    );
    if (response) dispatch({ type: SET_SLICE_LIST, payload: response.items });
  };
};


export const getListMigrationPairs = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getListMigrationPairs(
      getState(),
      dispatch
    );
    if (response){
    } dispatch({ type: SET_MIGRATION_PAIR, payload: response});

  };
};
export const getMigrationJobList = () => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getMigrationJobList(
      getState(),
      dispatch
    );
    if (response){
      dispatch({ type: SET_MIGRATION_JOB, payload: response });

    }
  };
};





export const getSliceObject = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getSliceObject(
      getState(),
      dispatch,
      sliceName
    );
    if (response) return response;
  };
};




export const getSliceObjectData = (sliceName) => {
  return async (dispatch, getState) => {
    let response = await sdkBackend.getSliceObject(
      getState(),
      dispatch,
      sliceName
    );
    if (response) return response;
  };
};
export const setCurrentSlice = (payload) => {
  return {
    type: SET_CURRENT_SLICE,
    payload,
  };
};

export const clearCurrentSlice = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CURRENT_SLICE,
      payload: {},
    });
  };
};

export const editSlice = (value) => {
  //need to change to a better name
  return (dispatch) => {
    dispatch({
      type: EDIT_SLICE,
      payload: value,
    });
  };
};

export const setResourceVersion = (version) => {
  return (dispatch) => {
    dispatch({
      type: SET_SLICE_RESOURCE_VERSION,
      payload: version,
    });
  };
};
