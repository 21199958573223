import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSliceCustomObjectList } from '../../../actions/ConnectClusterActions';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import { clearCurrentSlice } from '../../../actions/namespaceActions';
import NoSlice from '../../../assets/images/no-slice-namespace.png';
import GeneralLayout from '../../Commons/GeneralLayout';
import Row from './Row';
import './index.css';

export default function NamespaceList() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getSliceCustomObjectList());
    dispatch(getRegisteredClusters());
    dispatch(clearCurrentSlice());
  }, []);

  const sliceList = useSelector((state) => state.ConnectCluster.sliceList);
  useSelector((state) => state.ConnectCluster.sliceList);
  const currentSlice = useSelector(
    (state) => state.NamespaceOnboard.currentSlice
  );
  const resourceQuotaList = useSelector(
    (state) => state.resourceQuota.resourceQuotaList
  );
  const handleRedirectToNewUi = (redirect_uri) => {
    window.location.href = `/${redirect_uri}`;
  };
  const storedData = localStorage.getItem('currentProjectData');
  const currentProjectData = JSON.parse(storedData);

  return (
    <GeneralLayout
      // title={'Manage Namespaces'}
      description={
        // 'This allows you to put namespaces on a Slice and enables services in those namespaces to communicate with each other while isolating from other namespaces'
        `${'Namespaces'}`
      }
    >
      <Paper
        elevation={0}
        sx={{ border: '1px solid #B6B8D6' }}
        className={`table-paper-namespace ${sliceList.length === 0 ? 'table-paper-namespace-empty' : ''
          }`}
      >
        <div className="table-header">
          <Typography className="table-header-txt" gutterBottom component="div">
            {`Slice`}
          </Typography>
          {sliceList.length > 0 && (
            <Button
              disabled={
                Object.keys(currentSlice).length === 0 ||
                !currentSlice?.spec?.clusters
              }
              onClick={() => history.push('/manage-namespaces/add/1')}
              className="add-namespace-btn"
              variant="contained"
            >
              Add Namespaces
            </Button>
          )}
        </div>
        <TableContainer
          className={`table-container ${sliceList.length === 0 ? 'table-container-empty-namespace' : ''
            }`}
        >
          <Table aria-label="collapsible table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell
                  sx={{
                    width: '60px',
                    padding: '15px 24px',
                    fontSize: '12px',
                    lineHeight: '15px',
                  }}
                />
                <TableCell
                  sx={{
                    padding: '15px 24px',
                    width: '35%',
                    fontSize: '12px',
                    lineHeight: '15px',
                  }}
                  className="txt-bold"
                >
                  SLICE NAME
                </TableCell>
                <TableCell
                  sx={{
                    padding: '15px 24px',
                    width: '35%',
                    fontSize: '12px',
                    lineHeight: '15px',
                  }}
                  className="txt-bold"
                >
                  NAMESPACES
                </TableCell>
                <TableCell
                  sx={{
                    padding: '15px 24px',
                    width: '35%',
                    fontSize: '12px',
                    lineHeight: '15px',
                  }}
                  className="txt-bold"
                >
                  DESCRIPTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliceList?.length > 0 ? (
                sliceList.map((slice) => (
                  <Row key={slice.metadata?.name} slice={slice} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={NoSlice}
                        width="200"
                        height="200"
                        alt="No slice for namespace"
                      />
                    </div>
                    <div className="no-slice-container">
                      <Typography
                        className="no-slice-txt-dif"
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {' '}
                        <Typography style={{ textAlign: 'center' }}>
                          Create Slices under Connect Clusters and onboard
                          namespaces
                        </Typography>
                      </Typography>
                      {/* <Typography style={{ textAlign: 'center' }}>
                      under Manage Namespaces to get started with Assigning Node
                      Labels
                    </Typography>
                        {`To onboard namespaces you need to create a Slice. Go to Connect Clusters and create a Slice.`}
                      </Typography> */}
                      <Button
                        onClick={() => handleRedirectToNewUi('slices')}
                        variant="contained"
                        style={{
                          background: '#2868F3',
                          fontFamily: 'Bai Jamjuree',
                        }}
                      >
                        {'Go to Slices'}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </GeneralLayout>
  );
}
