import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import errorIcon from '../../../assets/icons/errorIcon.svg';
// import { createTheme } from '@mui/material/styles'
import * as React from 'react';
import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import removeClusterFromSlice from '../../../actions/ConnectClusterActions';
import getSliceObject from '../../../actions/ConnectClusterActions';
import { io } from 'socket.io-client';

import { useDispatch } from 'react-redux';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    borderTop: `4px solid #E15E5E`,
  },
  '& .MuiDialog-root': {
    maxWidth: '610px', // Change the value as neede
  },
}));

const theme = createTheme({
  palette: {
    customRed: {
      main: '#E15E5E',
    },
    cutomwhite: {
      main: '#FFFFFF',
    },
  },
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const AlertDialog = ({
  handleClose,
  open = false,
  clusterName,
  sliceName,
  confirmClose,
}) => {
  // let input = '';
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const dispatch = useDispatch();
  const handleTextChange = (event) => {
    // console.log(event.target.value);
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const handelSubmit = () => {
    if (inputValue !== 'DETACH') {
      setInputError(true);
    } else {
      let query = {
        sliceName,
        clusterName,
      };
      setConfirmed(true);
      // const hostname = window.location.hostname;
      // const port = window.location.port;
      // let socket_url = `wss://${hostname}/offboard-cluster`;
      // if (port) {
      //   socket_url = `wss://${hostname}:${port}/offboard-cluster`;
      // }
      // socket_url = `ws://localhost:3000/offboard-cluster`;

      // const socket = io(socket_url, {
      //   secure: true,
      //   transports: ['websocket', 'polling'],
      // });

      // socket.on('connect', () => {
      //   // const socket = io(process.env.REACT_APP_SOCKET_URL);
      //   console.log('connected');
      //   dispatch(removeClusterFromSlice(query, socket.id));
      // });

      setInputValue('');
      confirmClose();
      setInputError(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={{
          onClick: null,
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // onClose={handleClose}
        >
          <div style={{ display: 'flex', marginLeft: '2.5%' }}>
            <div>
              <img alt="ErrorIconOffboard" src={errorIcon}></img>
            </div>
            {/* <errorIcon /> */}
            <div
              style={{
                fontStyle: 'Bai Jamjuree',
                fontWeight: '600',
                color: '#E15E5E',
              }}
            >
              Detach Cluster ({clusterName})?
            </div>
          </div>
        </BootstrapDialogTitle>
        <DialogContent
          style={{
            width: '572px',
            background: '#EBECF7',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            // alignItems: 'flex-start',
            // flexDirection: "column",
            // justifyContent: "center",
            // align-items: flex-start,
            padding: '14px',
            gap: '10px',
          }}
        >
          <div
            style={{
              marginLeft: '3%',
              marginRight: '5%',
            }}
          >
            <div
              style={{
                marginTop: '4%',

                fontStyle: 'Bai Jamjuree',
                fontWeight: '500',
                color: '#5A607C',
              }}
            >
              <div>
                This cluster and all the related namespaces will be removed from
                the slice.
              </div>
              This could impact other applications on the slice.
            </div>
            <div
              style={{
                marginTop: '4%',
                marginBottom: '4%',
                fontStyle: 'Bai Jamjuree',
                fontWeight: '500',
                color: '#5A607C',
              }}
            >
              Type <strong>DETACH</strong> to confirm
            </div>
            <div
              style={{
                background: 'white',
                width: '539px',
              }}
            >
              <TextField
                fullWidth={true}
                value={inputValue}
                error={inputError}
                onChange={handleTextChange}
                // helperText={inputError ? 'Please enter OFFBOARD' : ''}
              />
              {inputError && (
                <p
                  style={{
                    fontStyle: 'Bai Jamjuree',
                    fontWeight: '500',
                    background: '#EBECF7',
                    color: 'red',
                    margin: 0,
                  }}
                >
                  Incorrect confirmation text
                </p>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            background: '#EBECF7',
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              style={{
                marginRight: '-0.5%',
                marginBottom: '4%',
                width: '54%',
              }}
            >
              <Button
                style={{
                  width: '96px',
                  height: '50px',
                  marginRight: '5%',
                }}
                variant="contained"
                color="cutomwhite"
                autoFocus
                onClick={() => {
                  setInputValue('');
                  setInputError(false);
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: '177px',
                  height: '50px',
                  color: 'white',
                }}
                variant="contained"
                color="customRed"
                autoFocus
                onClick={handelSubmit}
              >
                Detach Cluster
              </Button>
            </div>
          </ThemeProvider>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AlertDialog;
