import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import { connect } from "react-redux";

// import List from "./List";
import { usePrevious } from "../Commons/hooksUtils";
import { FormCheck, FormField, FormSelect } from "../Commons/formUtils";
import { FormContainer } from "../RoleManager/FormStyles";
import JsonEditor from "../SlicePolicy/JsonEditor";
import styled from "styled-components";
import {
  clearSelectedSlicePolicy,
  createSlicePolicy,
} from "../../actions/SlicePolicyActions";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import MuiPopper from "@mui/material/Popper";
import {styled as MuiStyled} from "@mui/material/styles";

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;
const Popper = styled(MuiPopper)`
  z-index: 5000;
`;

const Title = MuiStyled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  flex: 1,
}));

//const useStyles = makeStyles((theme) => ({
  // appBar: {
  //   position: "relative",
  // },
  // title: {
  //   marginLeft: theme.spacing(2),
  //   flex: 1,
  // },
  // root: {
  //   width: "100%",
  //   "& > * + *": {
  //     marginTop: theme.spacing(2),
  //   },
  // },
//}));
const SaveButton = styled(Button)`
  :disabled {
    background-color: rgba(47, 167, 103, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
`;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddEnforcementPointDialog(props) {
  //creating css classes from jss
  //const classes = useStyles();

  const [sliceName, setSliceName] = useState("");
  const [kind, setKind] = useState("");
  const [description, setDescription] = useState("");
  const [canonicalName, setCanonicalName] = useState("");
  const [algorithmConfig, setAlgorithmConfig] = useState(null);

  const clearData = () => {
    props.clearEnforcementSlice();
    setKind("");
    setDescription("");
    setCanonicalName("");
    setAlgorithmConfig(null);
  };

  const handleSave = () => {
    let enforcementPoint = {
      enforcementPoint: {
        kind,
        description,
        canonicalName,
        spec: algorithmConfig,
      },
    };
    // props.createEnforcement(enforcementPoint, props.sliceName);
    props.handleClose();
  };

  useEffect(() => {
    if (props.selectedEnforcement) {
      
      handleSelectedEnforcement();
    }
  }, [props.selectedEnforcement]);

  const handleSelectedEnforcement = () => {
    
    const { selectedEnforcement } = props;
    if (selectedEnforcement) {
      setKind(selectedEnforcement.kind);
      setDescription(selectedEnforcement.description);
      setCanonicalName(selectedEnforcement.canonicalName);
      setAlgorithmConfig(JSON.parse(selectedEnforcement.spec));
    }
  };

  const isSelected = props.selectedEnforcement;

  return (
    <div>
      <Dialog
        style={{ zIndex: 4000 }}
        fullScreen
        open={props.open}
        onClose={() => {
          clearData();
          props.handleClose();
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          //className={classes.appBar}
          style={{
            backgroundImage:
              "linear-gradient(to right, #000046, #002f73, #005a9c)",
              position: "relative",
          }}
        >
          <Toolbar style={{ color: "#ffffff" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                clearData();
                props.handleClose();
                // props.clearSelectedOperator();
                // clearData();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Title variant="h6" 
            //className={classes.title}
            >
              {!isSelected && "New"} Enforcement Point
            </Title>
            {!isSelected && (
              <SaveButton
                disabled={props.selectedSlicePolicy}
                variant="contained"
                autoFocus
                color="secondary"
                onClick={handleSave}
              >
                save
              </SaveButton>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              borderRight: "solid black 1px",
              minHeight: "90vh",
            }}
          >
            <FormContainer style={{ alignItems: "flex-start" }}>
              <h3>{!isSelected && "New"} Enforcement Point</h3>
              {/* <div className="TextContainer">
                A operator defines the permissions that you can assign to a user, group, or role. You can create and edit a operator in the
                visual editor
              </div>  */}
              <h4>General Details</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormField
                  label="Slice Name"
                  value={props.sliceName}
                  disabled
                />
                <FormField
                  disabled={isSelected}
                  label="Enforcement Kind"
                  value={kind}
                  onChange={(e) => setKind(e.nativeEvent.target.value)}
                />
                <FormField
                  disabled={isSelected}
                  label="Enforcement Canonical Name"
                  value={canonicalName}
                  onChange={(e) => setCanonicalName(e.nativeEvent.target.value)}
                />
                <FormField
                  disabled={isSelected}
                  label="Enforcement Description"
                  value={description}
                  onChange={(e) => setDescription(e.nativeEvent.target.value)}
                />
              </div>
            </FormContainer>
          </div>
          <Form>
            <JsonEditor
              height="30rem"
              viewOnly={isSelected}
              placeholder={algorithmConfig ? { algorithmConfig } : null}
              label="Spec"
              onChange={(e) => setAlgorithmConfig(e.jsObject)}
            />
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = ({
  enforcementPoints: { enforcementSlice: selectedEnforcement },
}) => ({
  selectedEnforcement,
});

export default connect(mapStateToProps, {
  createSlicePolicy,
  clearSelectedSlicePolicy,
})(AddEnforcementPointDialog);
