import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YAML from 'yaml';
import { setCurrentResourceQuota } from '../../actions/ResourceQuotaActions';
import { handleConversion, kiToMi } from '../../utils/functions';
import './index.css';
import { defaultRQValues } from './Steps/AddResource/utils';
import { ValueWithUsage } from './Steps/ValueWithUsage';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadIcon.svg';

export default function Row(props) {
  const { slice, radioDisable = false, stepNo } = props;
  const [open, setOpen] = useState(false);
  const [clusterDetails, setClusterDetails] = useState({});
  const dispatch = useDispatch();
  const clusterList = useSelector((state) => state.RegisterCluster.clusterList);
  const currentResourceQuota = useSelector(
    (state) => state.resourceQuota.currentResourceQuota
  );
  const {
    slice: {
      spec: {
        resourceQuota: {
          spec: {
            clusterQuota,
            sliceQuota: {
              resources: {
                limit: { podCount, ephemeralStorage, cpu, memory } = {},
                userdata,
                request: {
                  ephemeralStorage: ephemeralRequestStorage,
                  cpu: cpuRequest,
                  memory: memoryRequest,
                } = {},
              } = {},
            } = {},
          } = {},
        } = {},
      },
    },
  } = props;

  const isChecked = useMemo(() => {
    return currentResourceQuota?.metadata?.name === slice?.metadata?.name;
  }, [slice, currentResourceQuota, clusterQuota]);

  useEffect(() => {
    if (slice?.spec?.clusters && clusterList.items) {
      const list = {};
      slice?.spec?.clusters.forEach((item) => {
        const clusterDetail = clusterList.items.find(
          (cluster) => cluster?.metadata?.name == item
        );
        list[item] = {
          location:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudRegion?.toLowerCase(),
          cloudType:
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase() ===
              'LINODE' ||
            clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider ===
              'linode'
              ? 'AKAMAI'
              : clusterDetail?.spec?.clusterProperty?.geoLocation?.cloudProvider?.toUpperCase(),
        };
      });
      setClusterDetails(list);
    }
  }, [slice, clusterList]);

  const secretDownload = () => {
    if (currentResourceQuota) {
      let updatedResourceQuota = JSON.parse(
        JSON.stringify(currentResourceQuota)
      );
      updatedResourceQuota?.spec?.resourceQuota?.spec?.clusterQuota?.forEach(
        (cluster) => {
          if (cluster['resources']) {
            if (cluster['resources']['userdata']) {
              delete cluster['resources']['userdata'];
            }
          }
          return cluster?.namespaceQuota?.forEach((item) => {
            if (item['resources']['userdata']) {
              return delete item['resources']['userdata'];
            }
            return item;
          });
        }
      );

      updatedResourceQuota?.spec?.resourceQuota?.spec?.clusterQuota?.forEach(
        (cluster) => {
          if (_.has('resources', cluster)) {
            if (cluster['resources']['userdata']) {
              return delete cluster['resources']['userdata'];
            }
          }
          return cluster;
        }
      );
      delete updatedResourceQuota?.spec?.resourceQuota.spec.sliceQuota.resources
        .userdata;
      const doc = new YAML.Document();
      doc.contents = updatedResourceQuota;
      const element = document.createElement('a');
      const file = new Blob([doc.toString()], { type: 'yaml' });
      element.href = URL.createObjectURL(file);
      element.download = `resource-quota.yaml`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const handleAdditionOfSliceData = (slice) => {
    if (!_.has(slice.spec, 'resourceQuota')) {
      const clusterQuota = slice.spec.clusters.map((cluster) => {
        let namespaceArr =
          slice.spec.namespaceIsolationProfile.applicationNamespaces
            .map((namespace) => {
              if (
                namespace.clusters.includes('*') ||
                namespace.clusters.includes(cluster)
              ) {
                return {
                  enforceQuota: false,
                  namespace: namespace.namespace,
                  resources: {
                    userdata: {
                      podCount: '',
                      cpu: '',
                      ephemeralStorage: '',
                      memory: '',
                    },
                    limit: {
                      podCount: '',
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    request: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    defaultRequestPerContainer: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    defaultLimitPerContainer: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                  },
                };
              }
              return null;
            })
            .filter(Boolean);

        return {
          clusterName: cluster,
          namespaceQuota: namespaceArr,
          resources: {
            userdata: {
              podCount: 0,
              cpu: 0,
              ephemeralStorage: 0,
              memory: 0,
            },
            limit: {
              podCount: '',
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
            request: {
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
          },
        };
      });
      slice = {
        ...slice,
        isNew: true,
        metadata: { ...slice.metadata },
        spec: {
          ...slice.spec,
          resourceQuota: {
            spec: {
              clusterQuota: clusterQuota,
              sliceQuota: {
                resources: {
                  userdata: {
                    podCount: '',
                    cpu: '',
                    ephemeralStorage: '',
                    memory: '',
                  },
                  limit: {
                    podCount: '',
                    ephemeralStorage: '',
                    cpu: '',
                    memory: '',
                  },
                  request: {
                    ephemeralStorage: '',
                    cpu: '',
                    memory: '',
                  },
                },
              },
            },
          },
        },
      };
      dispatch(setCurrentResourceQuota(slice));
    } else {
      const clusterQuota = slice.spec.clusters.map((cluster) => {
        let namespaceArr =
          slice.spec.namespaceIsolationProfile.applicationNamespaces
            .map((namespace) => {
              if (
                namespace.clusters.includes('*') ||
                namespace.clusters.includes(cluster)
              ) {
                return {
                  enforceQuota: false,
                  namespace: namespace.namespace,
                  resources: {
                    userdata: {
                      podCount: '',
                      cpu: '',
                      ephemeralStorage: '',
                      memory: '',
                    },
                    limit: {
                      podCount: '',
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    request: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    defaultRequestPerContainer: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                    defaultLimitPerContainer: {
                      ephemeralStorage: '',
                      cpu: '',
                      memory: '',
                    },
                  },
                };
              }
              return null;
            })
            .filter(Boolean); // filters out null values

        return {
          clusterName: cluster,
          namespaceQuota: namespaceArr,
          resources: {
            userdata: {
              podCount: '',
              cpu: '',
              ephemeralStorage: '',
              memory: '',
            },
            limit: {
              podCount: '',
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
            request: {
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
          },
        };
      });

      const upClusterQuota = clusterQuota.map((cluster, i) => {
        slice?.spec?.resourceQuota?.spec?.clusterQuota?.map((sliceCluster) => {
          if (sliceCluster.clusterName === cluster.clusterName) {
            cluster.namespaceQuota.map((namespace, j) => {
              sliceCluster?.namespaceQuota?.map((sliceNamespace) => {
                if (namespace.namespace === sliceNamespace.namespace) {
                  const upSliceNamespace = {
                    ...sliceNamespace,
                  };
                  if (!_.has(upSliceNamespace, 'userdata')) {
                    const upSliceNamespace = {
                      ...sliceNamespace,
                      userdata: {
                        podCount: 0,
                        cpu: 0,
                        ephemeralStorage: 0,
                        memory: 0,
                      },
                    };
                  }
                  cluster.namespaceQuota[j] = upSliceNamespace;
                }
              });
            });
          }
        });
        return cluster;
      });

      const updatedSlice = { ...slice };
      updatedSlice.spec.resourceQuota.spec.clusterQuota = upClusterQuota;
      if (!updatedSlice.spec.resourceQuota.spec.sliceQuota) {
        updatedSlice.spec.resourceQuota.spec.sliceQuota = {
          resources: {
            userdata: {
              podCount: '',
              cpu: '',
              ephemeralStorage: '',
              memory: '',
            },
            limit: {
              podCount: '',
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
            request: {
              ephemeralStorage: '',
              cpu: '',
              memory: '',
            },
          },
        };
      }
      if (!updatedSlice.spec.resourceQuota.spec.sliceQuota.resources.limit) {
        updatedSlice.spec.resourceQuota.spec.sliceQuota.resources.limit = {
          ...defaultRQValues,
          podCount: '',
        };
      }
      if (!updatedSlice.spec.resourceQuota.spec.sliceQuota.resources.request) {
        updatedSlice.spec.resourceQuota.spec.sliceQuota.resources.request =
          defaultRQValues;
      }
      if (
        !updatedSlice.spec.resourceQuota.spec.sliceQuota.resources
          .defaultRequestPerContainer
      ) {
        updatedSlice.spec.resourceQuota.spec.sliceQuota.resources.defaultRequestPerContainer =
          defaultRQValues;
      }
      dispatch(setCurrentResourceQuota(updatedSlice));
    }
  };

  const converted_cpu = handleConversion('cpu', cpu);
  const converted_cpu_request = handleConversion('cpu', cpuRequest);
  const converted_memory_limit = handleConversion('memory', memory);
  const memory_limit_usage = kiToMi(userdata.memory);
  const converted_memory_request = handleConversion('memory', memoryRequest);
  const memory_request_usage = kiToMi(userdata.memoryRequest);
  const converted_storage_limit = handleConversion('memory', ephemeralStorage);
  const storage_limit_usage = kiToMi(userdata.ephemeralStorage);
  const converted_storage_request = handleConversion(
    'memory',
    ephemeralRequestStorage
  );
  const storage_request_usage = kiToMi(userdata.ephemeralStorageRequest);
  // localStorage.setItem('currentSlice',  slice.metadata?.name);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {!props.isFinalize && (
          <TableCell className="resource-quota-table-cell">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              style={{ padding: '0px' }}
            >
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{ width: '40px', height: '40px', padding: '0px' }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{ width: '40px', height: '40px', padding: '0px' }}
                />
              )}
            </IconButton>
          </TableCell>
        )}
        <TableCell
          component="th"
          scope="row"
          sx={{
            minWidth: '128px',
            fontFamily: 'Bai Jamjuree',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#12153E',
            padding: '20px 0px 20px 24px',
          }}
        >
          {!radioDisable && (
            <Radio
              sx={{ padding: '0px 18px 0px 0px' }}
              checked={isChecked}
              onChange={() => {
                handleAdditionOfSliceData(slice);
              }}
              name="slice-row"
              inputProps={{ 'aria-label': 'selected slice row' }}
            />
          )}
          {slice.metadata?.name}
          {props.enabled && (
            <span
              style={{ height: '8px', width: '8px', marginLeft: '8px' }}
              className="success-dot"
            />
          )}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            width: '10%',
            padding: '12px 25px',
          }}
        >
          <ValueWithUsage value={podCount} usage={userdata?.podCount} />
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          sx={{
            minWidth: '98px',
            width: '20%',
            padding: '12px 0px 12px 25px',
          }}
        >
          <>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Limit -
              </Box>
              <ValueWithUsage
                value={converted_cpu}
                usage={userdata.cpu}
                sx={{ lineHeight: '20px' }}
              />
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                fontSize: '16px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Request -
              </Box>
              <ValueWithUsage
                sx={{ lineHeight: '20px' }}
                value={converted_cpu_request}
                usage={userdata.cpuRequest}
              />
            </Typography>
          </>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            minWidth: '98px',
            width: '20%',
            padding: '12px 0px 12px 25px',
          }}
        >
          <>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Limit -
              </Box>
              <ValueWithUsage
                sx={{ lineHeight: '20px' }}
                value={converted_memory_limit}
                usage={memory_limit_usage}
              />
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Request -
              </Box>
              <ValueWithUsage
                sx={{ lineHeight: '20px' }}
                value={converted_memory_request}
                usage={memory_request_usage}
              />
            </Typography>
          </>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            padding: '12px 0px 12px 25px',
            minWidth: '98px',
            width: '20%',
          }}
        >
          <>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Limit -
              </Box>
              <ValueWithUsage
                sx={{ lineHeight: '20px' }}
                value={converted_storage_limit}
                usage={storage_limit_usage}
              />
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                lineHeight: '20px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#5A607C',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
              component="div"
            >
              <Box component={'span'} sx={{ mr: '4px' }}>
                Request -
              </Box>
              <ValueWithUsage
                sx={{ lineHeight: '20px' }}
                value={converted_storage_request}
                usage={storage_request_usage}
              />
            </Typography>
          </>
        </TableCell>
        {[3, 4].includes(stepNo) && (
          <TableCell
            component="th"
            scope="row"
            sx={{
              fontFamily: 'Bai Jamjuree',
              fontWeight: '600',
              lineHeight: '20px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: '#12153E',
              padding: '12px 25px',
              width: '40px',
            }}
          >
            <IconButton onClick={secretDownload} sx={{ padding: 0 }}>
              <DownloadIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {/* Collapsable Table */}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: open ? 10 : 0,
            paddingTop: open ? 10 : 0,
            transition: '0.3s ease-in-out',
          }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: 'flex', justifyContent: 'center' }}>
              <Table size="small" aria-label="purchases">
                <TableHead
                  className="table-head"
                  sx={{ borderColor: 'transparent' }}
                >
                  <TableRow
                    style={{ border: '1px solid #D9DFEE', fontWeight: '600' }}
                  >
                    <TableCell className="sub-table-cell">CLUSTERS</TableCell>
                    <TableCell className="sub-table-cell">
                      DEPLOYMENT LOCATION
                    </TableCell>
                    <TableCell className="sub-table-cell">CLOUD TYPE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(clusterDetails)?.map((key, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            borderLeft: '1px solid #D9DFEE',
                            fontSize: '16px',
                            lineHeight: '20px',
                            padding: '13px 4px 13px 24px',
                          }}
                        >
                          {key}
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: '16px',
                            lineHeight: '20px',
                            padding: '13px 4px 13px 24px',
                          }}
                        >
                          {clusterDetails[key]['location']}
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: '1px solid #D9DFEE',
                            fontSize: '16px',
                            lineHeight: '20px',
                            padding: '13px 4px 13px 24px',
                          }}
                        >
                          {clusterDetails[key]['cloudType']}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
