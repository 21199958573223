import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { ALL_SLICES_KEY } from '../../../src/constants/enums';
import {
  getCompareClusterStats,
  setActiveSelectedSlice,
} from '../../actions/dashboardActions';
import zones from '../../constants/AvailabilityZone.json';
import AWS, { AWSTooltip } from './AWS';
import Azure, { AzureTooltip } from './Azure';
import Block, { BlockTooltip } from './Block1';
import './ButtonDep.css';
import Cloud, { CloudTooltip } from './Cloud1';
import LocationButton from './LocationButton';
import Oracle, { OracleTooltip } from './Oracle';
import AkamaiCloud, { AkamaiTooltip } from './Akamai';
import OpenshiftCloud, { OpenshiftTooltip } from './Openshift';
import RancherCloud, { RancherTooltip } from './Rancher';
import KindCloud, { KindTooltip } from './Kind';
import OtherCloud, { OtherTooltip } from './Other';
import ServLeg from './ServLeg';
import Tri from './Tri';
import Triangle1, { TriangleTooltip } from './Triangle1';
import { __extends } from 'tslib';

am4core.useTheme(am4themes_animated);

/**
 * Button is capable of drawing a simple rectangular button with
 * optionally rounded corners and an icon in it.
 */

var Button = /** @class */ (function (_super) {
  __extends(Button, _super);
  /**
   * Constructor
   */
  function Button() {
    var _this = _super.call(this) || this;
    _this.className = 'Button';
    _this.tooltipY = 1;
    // Set defaults
    _this.iconPosition = 'left';
    _this.layout = 'horizontal';
    _this.setStateOnChildren = true;
    _this.setPropertyValue('horizontalCenter', 'middle');
    _this.setPropertyValue('verticalCenter', 'top');
    var interfaceColors = new am4core.InterfaceColorSet();
    // Create background
    var background = _this.background;
    background.fillOpacity = 0;
    background.strokeOpacity = 0;
    // Create the label element
    _this.label = new am4core.Label();
    _this.label.fill = interfaceColors.getFor('secondaryButtonText');
    _this.label.shouldClone = false;
    // Create default states
    var hoverState = background.states.create('hover');
    hoverState.properties.fillOpacity = 0;
    hoverState.properties.fill = interfaceColors.getFor('secondaryButtonHover');
    var downState = background.states.create('down');
    downState.transitionDuration = 100;
    downState.properties.fill = interfaceColors.getFor('secondaryButtonDown');
    downState.properties.fillOpacity = 0;
    // Set up accessibility
    // A button should be always focusable
    _this.role = 'button';
    _this.focusable = true;
    // Apply theme
    _this.applyTheme();
    return _this;
  }
  Object.defineProperty(Button.prototype, 'icon', {
    /**
     * @return Icon Sprite
     */
    get: function () {
      return this._icon;
    },
    /**
     * A [[Sprite]] to be used as an icon on button.
     *
     * @param icon Icon Sprite
     */
    set: function (icon) {
      var currentIcon = this._icon;
      if (currentIcon) {
        //this._icon.dispose();
        //this.removeDispose(currentIcon);
        currentIcon.parent = undefined;
      }
      if (icon) {
        this._icon = icon;
        icon.parent = this;
        icon.interactionsEnabled = false;
        icon.shouldClone = false;
        this.iconPosition = this.iconPosition;
        this._disposers.push(icon);
      }
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Button.prototype, 'iconPosition', {
    /**
     * @return Icon position
     */
    get: function () {
      return this.getPropertyValue('iconPosition');
    },
    /**
     * Icon position: "left" or "right".
     *
     * @default "left"
     * @param position  Icon position
     */
    set: function (position) {
      this.setPropertyValue('iconPosition', position);
      if (this.icon) {
        if (position == 'left') {
          this.icon.toBack();
        } else {
          this.icon.toFront();
        }
      }
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Button.prototype, 'label', {
    /**
     * @return Label element
     */
    get: function () {
      return this._label;
    },
    /**
     * [[Label]] element to be used for text.
     *
     * @param label element
     */
    set: function (label) {
      if (this._label) {
        //this._label.dispose();
        this.removeDispose(this._label);
      }
      this._label = label;
      if (label) {
        label.parent = this;
        label.interactionsEnabled = false;
        this._disposers.push(this._label);
      }
    },
    enumerable: true,
    configurable: true,
  });
  /**
   * Creates a background element for the button.
   *
   * @ignore Exclude from docs
   * @return Background element
   */
  Button.prototype.createBackground = function () {
    return new am4core.RoundedRectangle();
  };
  /**
   * Copies properties and other attributes.
   *
   * @param source  Source
   */
  Button.prototype.copyFrom = function (source) {
    _super.prototype.copyFrom.call(this, source);
    if (source.label) {
      this.label.copyFrom(source.label);
    }
    if (source.icon) {
      this.icon = source.icon.clone();
    }
  };
  return Button;
})(am4core.Container);

export class DeploymentMap extends Component {
  constructor() {
    super();
    this.state = {
      location: 'na',
    };
  }
  chart = null;
  // Below function is to reduce array to their own cloud provider so they can be consumed by am chart
  getCloudProviders() {
    const clusterDetails =
      this.props.activeSelectedSlice?.sliceName === ALL_SLICES_KEY
        ? this.props.clusterDetail
        : this.props.clusterDetail.filter((cluster) =>
            cluster.slices.find(
              (slice) =>
                slice.name === this.props.activeSelectedSlice?.sliceName
            )
          );

    return clusterDetails.reduce(
      (allValue, currentValue) => {
        const cloudProvider = currentValue?.location?.cloudProvider
          ? currentValue?.location?.cloudProvider?.toUpperCase()
          : null;
        const cloudRegion = currentValue?.location?.region
          ? currentValue?.location?.region?.toLowerCase()
          : null;
        if (
          cloudProvider &&
          cloudRegion &&
          zones[cloudProvider] &&
          zones[cloudProvider][cloudRegion]?.latitude &&
          zones[cloudProvider][cloudRegion]?.longitude
        ) {
          currentValue.location.latitude =
            +zones[cloudProvider][cloudRegion]?.latitude;
          currentValue.location.longitude =
            +zones[cloudProvider][cloudRegion]?.longitude;
        } else {
          currentValue.location.latitude = +currentValue.location.latitude;
          currentValue.location.longitude = +currentValue.location.longitude;
        }
        const providerDetails = allValue[cloudProvider] || [];
        currentValue.location.count = String(
          providerDetails.length + 1
        ).padStart(2, '0');

        providerDetails.push(currentValue.location);
        allValue[cloudProvider] = providerDetails;
        return allValue;
      },
      {
        GCP: [],
        AWS: [],
        AZURE: [],
        ORACLE: [],
        OPENSHIFT: [],
        DATACENTER: [],
        EDGE: [],
        RANCHER: [],
        KIND: [],
        AKAMAI: [],
        OTHER: [],
        LINODE: [],
      }
    );
  }

  getChart() {
    let chart = am4core.create('chartdiv', am4maps.MapChart);
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.chartContainer.wheelable = false;
    chart.seriesContainer.events.disableType('doublehit');
    chart.chartContainer.background.events.disableType('doublehit');
    chart.paddingRight = 20;
    chart.draggable = false;
    chart.resizable = false;
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ['AQ'];
    polygonSeries.useGeodata = true;

    polygonSeries.events.on('datavalidated', function () {
      chart.zoomToGeoPoint({ latitude: 38, longitude: -97 }, 5, true);
    });

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.polygon.fillOpacity = 0.6;
    polygonTemplate.draggable = false;
    polygonTemplate.fill = am4core.color('#EBECF6');
    polygonTemplate.stroke = am4core.color('#BABABA');
    polygonTemplate.strokeWidth = 0.25;
    let hs = polygonTemplate.states.create('always');
    hs.properties.fill = chart.colors.getIndex(0);
    return chart;
  }

  addCloudProvider(chart, buttonHtml, data = [], tooltipHTML) {
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
    imageSeries.mapImages.template.propertyFields.latitude = 'latitude';
    imageSeries.mapImages.template.propertyFields.id = 'id';
    imageSeries.mapImages.template.tooltipText = '{region}';
    imageSeries.mapImages.template.propertyFields.zIndex = 'index';
    imageSeries.data = data;

    let imageSeriesTemplate = imageSeries.mapImages.template;
    let button = imageSeriesTemplate.createChild(Button);
    button.radius = 4;
    button.fill = am4core.color('#B27799');
    button.stroke = am4core.color('#FFFFFF');
    button.strokeWidth = 2;
    button.label.html = buttonHtml;
    button.nonScaling = true;

    imageSeriesTemplate.tooltip = new am4core.Tooltip();
    imageSeriesTemplate.tooltip.getFillFromObject = false;
    imageSeriesTemplate.tooltip.label.interactionsEnabled = true;
    imageSeriesTemplate.tooltip.background.fill = am4core.color('#ffffff');
    imageSeriesTemplate.tooltipHTML = tooltipHTML;
    imageSeriesTemplate.tooltip.label.padding(-1, -1, -1, -1);
    imageSeriesTemplate.tooltip.background.fill = '';
    imageSeriesTemplate.tooltip.background.cornerRadius = '';
  }

  buildPinsOnChart() {
    const cloudProviders = this.getCloudProviders();
    const chart = this.getChart();
    this.addCloudProvider(
      chart,
      renderToString(<Cloud data={'{region}'} index={'{count}'} />),
      cloudProviders['GCP'],
      renderToString(<CloudTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<AWS data={'{region}'} index={'{count}'} />),
      cloudProviders['AWS'],
      renderToString(<AWSTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<Oracle data={'{region}'} index={'{count}'} />),
      cloudProviders['ORACLE'],
      renderToString(<OracleTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<Azure data={'{region}'} index={'{count}'} />),
      cloudProviders['AZURE'],
      renderToString(<AzureTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<Triangle1 data={'Custom'} index={'{count}'} />),
      cloudProviders['DATACENTER'],
      renderToString(<TriangleTooltip data={'Custom'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<Block data={'Custom'} index={'{count}'} />),
      cloudProviders['EDGE'],
      renderToString(<BlockTooltip data={'Custom'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<AkamaiCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['AKAMAI'],
      renderToString(<AkamaiTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<AkamaiCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['LINODE'],
      renderToString(<AkamaiTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<OpenshiftCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['OPENSHIFT'],
      renderToString(<OpenshiftTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<RancherCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['RANCHER'],
      renderToString(<RancherTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<KindCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['KIND'],
      renderToString(<KindTooltip data={'{region}'} index={'{count}'} />)
    );
    this.addCloudProvider(
      chart,
      renderToString(<OtherCloud data={'{region}'} index={'{count}'} />),
      cloudProviders['OTHER'],
      renderToString(<OtherTooltip data={'{region}'} index={'{count}'} />)
    );

    return chart;
  }

  buildChart() {
    const chart = this.buildPinsOnChart();

    this.chart = chart;
    chart.homeZoomLevel = 5;
    chart.homeGeoPoint = {
      latitude: 38,
      longitude: -97,
    };
  }

  allbuildChart() {
    this.buildPinsOnChart();
  }

  europebuildChart() {
    const chart = this.buildPinsOnChart();

    this.chart = chart;
    chart.homeZoomLevel = 5;
    chart.homeGeoPoint = {
      latitude: 55,
      longitude: 23,
    };
  }

  NorthClick = () => {
    this.buildChart();
    this.setState({ location: 'na' });
    this.chart.zoomToGeoPoint({ latitude: 38, longitude: -97 }, 5, true);
    this.chart.maxZoomLevel = 5;
    this.chart.minZoomLevel = 5;
  };

  AllClick = () => {
    this.allbuildChart();
    this.setState({ location: 'all' });
    this.chart.zoomToGeoPoint(
      { latitude: 25.25856880397297, longitude: -0.27119587692465036 },
      1,
      true
    );
    this.chart.maxZoomLevel = 1.0012439500159054;
    this.chart.minZoomLevel = 1.0012439500159054;
  };

  Europeclick = () => {
    this.europebuildChart();
    this.setState({ location: 'europe' });
    this.chart.zoomToGeoPoint({ latitude: 55, longitude: 23 }, 5, true);
    this.chart.maxZoomLevel = 5;
    this.chart.minZoomLevel = 5;
  };

  componentDidMount() {
    this.buildChart();
    this.allbuildChart();
    this.europebuildChart();

    this.NorthClick();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.paddingRight !== this.props.paddingRight) {
      this.chart.paddingRight = this.props.paddingRight;
    }
    if (oldProps?.clusterDetail?.length != this.props.clusterDetail.length) {
      this.resetChart();
    }
    if (
      oldProps.activeSelectedSlice?.sliceName !==
      this.props.activeSelectedSlice?.sliceName
    ) {
      this.resetChart();
    }
  }

  resetChart() {
    if (this.chart) {
      this.chart.dispose();
    }
    this.buildChart();
    this.allbuildChart();
    this.europebuildChart();

    this.NorthClick();
    this.setState({ location: 'na' });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="flex-end"
          marginTop={'20px'}
        >
          <LocationButton
            location={this.state.location}
            northClick={() => this.NorthClick()}
            allClick={() => this.AllClick()}
            europeClick={() => this.Europeclick()}
          />
        </Grid>

        <div
          className="chartdiv"
          style={{ width: '100%', height: '600px' }}
        ></div>
        <div className="sticker">
          <ServLeg />
          <div className="tri">
            <Tri />
          </div>
        </div>
        <div className="cir"></div>
        <div className="rec"></div>
      </div>
    );
  }
}

DeploymentMap.propTypes = {
  clusterDetail: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    clusterDetail: state.dashboard.clusterDetail,
    sliceData: state.dashboard.slices,
    activeSelectedSlice: state.dashboard.activeSelectedSlice,
  };
};

export default connect(mapStateToProps, {
  getCompareClusterStats,
  setActiveSelectedSlice,
})(DeploymentMap);
