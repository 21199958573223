import { Box, Typography } from '@mui/material';
import { classNames } from '../../../utils/functions';

/*
 * | usage | limit |       result      |
 * |   1   |   0   |       usage       |
 * |   0   |   1   |   "NA / limit "   |
 * |   1   |   1   |  "usage / limit " |
 * |   0   |   0   |       "NA"        |
 */
export const ValueWithUsage = ({
  usage,
  value,
  valueError,
  isContainer,
  ...props
}) => {
  if (!value && !usage) {
    return (
      <Typography
        sx={{
          color: '#5A607C',
          fontSize: '16px',
          fontWeight: 500,
        }}
        component="div"
        {...props}
      >
        {isContainer ? '' : 'NA'}
        {isContainer ? (
          <Box
            sx={{
              ml: 2,
            }}
            component="span"
          >
            /container
          </Box>
        ) : null}
      </Typography>
    );
  }

  const isUsageGreater = value ? (usage > value ? true : false) : false;

  const renderValue = () => {
    // {value ? ` / ${value} ` : isContainer ? ' ' : ' / NA'}
    // return {value ? ` / ${value} ` : isContainer ? ' ' : ' / NA'}
    if (isContainer) {
      return value ? ` ${value}` : ' ';
    }
    return value ? (
      <span className="text-black">
        <span className="text-gray">{' / '}</span>
        {`${value}`}
      </span>
    ) : (
      ' '
    );
  };

  return (
    <Typography
      sx={{
        color: '#5A607C',
        fontSize: '16px',
        fontWeight: 600,
      }}
      component="div"
      {...props}
    >
      <span
        className={classNames(
          value ? (isUsageGreater ? 'text-red' : 'text-green') : ''
        )}
      >
        {usage ? `${usage} ` : isContainer ? '' : 'NA'}
      </span>
      <span className={classNames(valueError ? 'text-red' : '')}>
        {renderValue()}
        {/*  if not value show blank for container else show NA */}
      </span>
      {isContainer ? (
        <Box
          sx={{
            ml: 2,
          }}
          component="span"
        >
          /container
        </Box>
      ) : null}
    </Typography>
  );
};
