import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AceEditor from "react-ace";
import Slide from "@mui/material/Slide";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    height: "70vh",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function AceEditorDialog(props) {
  const { openDialog, closeDialog, editorValue, setEditorValue } = props;
  return (
    <BootstrapDialog
      onClose={closeDialog}
      aria-labelledby="ace-editor-dialog"
      open={openDialog}
      fullWidth={true}
      maxWidth="md"
      TransitionComponent={Transition}
    >
      <BootstrapDialogTitle id="ace-editor-dialog" onClose={closeDialog}>
        Code Preview
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <AceEditor
          placeholder="Placeholder Text"
          mode="sass"
          theme="monokai"
          name="Cluster Yaml Editor"
          height="100%"
          width="100%"
          onChange={(value) => setEditorValue(value)}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={editorValue}
          setOptions={{
            readOnly:true,
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 4,
          }}
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
