import {
  HIDE_EXPIRATION_BANNER,
  HIDE_LICENSING_BANNER,
  SET_LICENSE_DETAILS,
  SET_LICENSE_STATUS,
  SHOW_EXPIRATION_BANNER,
} from '../actions/types';

const initialState = {
  showBanner: true,
  showExpirationModal: true,
  licenseDetails: null,
  licenseStatus: null, // 'valid', 'expired', 'invalid'
};

const LicensingReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_LICENSING_BANNER: {
      return {
        ...state,
        showBanner: false,
      };
    }
    case SHOW_EXPIRATION_BANNER: {
      return {
        ...state,
        showExpirationModal: true,
      };
    }
    case HIDE_EXPIRATION_BANNER: {
      return {
        ...state,
        showExpirationModal: false,
      };
    }
    case SET_LICENSE_DETAILS: {
      return {
        ...state,
        licenseDetails: action.payload,
      };
    }

    case SET_LICENSE_STATUS: {
      return {
        ...state,
        licenseStatus: action.payload,
      };
    }

    default:
      return state;
  }
};

export default LicensingReducer;
