import {
  ADD_SESSION,
  CHANGE_PASSWORD,
  SHOW_ERROR,
  START_SPINNER,
  STOP_SPINNER,
  REMOVE_ERROR,
  SET_LOADING,
  CHANGE_PASSWORD_ERROR,
  ACTION_LOGOUT_INITIATED,
} from './types';
import Amplify, { Auth } from 'aws-amplify';
import { getMeshBackend } from '../backend/MeshBackendProxy';
import { getOperatorDetails } from './UserActions';
import { SdkBackend } from '../backend/MeshBackend';
import { removeError } from './SnackbarActions';
import history from '../utils/history';
const backend = getMeshBackend();
const sdkBackend = new SdkBackend();

export const getEnvVariables = () => {
  return async (dispatch, getState) => {
    let response = await backend.getEnvVariables(getState(), dispatch);
    const {
      cognitoIdentityPoolId,
      awsRegion,
      cognitoUserPoolId,
      cognitoClientId,
    } = response;
    Amplify.configure({
      Auth: {
        identityPoolId: cognitoIdentityPoolId,
        region: awsRegion,
        identityPoolRegion: awsRegion,
        userPoolId: cognitoUserPoolId,
        userPoolWebClientId: cognitoClientId,
      },
    });
    dispatch({
      type: SET_LOADING,
      payload: false,
    });
  };
};

// You can get the current config object
const currentConfig = Auth.configure();

export const loginUser = (userCredentials) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ACTION_LOGOUT_INITIATED,
        payload: { session_token: '' },
      });
      dispatch({ type: START_SPINNER });
      let response = await sdkBackend.authenticateUser(
        getState(),
        dispatch,
        userCredentials
      );
      if (!response) throw new Error('Invalid Token');
      dispatch({
        type: ADD_SESSION,
        payload: {
          session_token: '',
          customerId: '',
          refreshToken: '',
        },
      });
      dispatch({ type: STOP_SPINNER });
      history.push(window.location.pathname);
    } catch (error) {
      dispatch({ type: STOP_SPINNER });
      dispatch({ type: SHOW_ERROR, payload: { message: error.message } });
    }
  };
};

export const changeUserPassword = (userCredentials) => {
  return (dispatch, getState) => {
    try {
      if (userCredentials.newPassword == userCredentials.confirmNewPassword) {
        Auth.signIn(userCredentials.username, userCredentials.password).then(
          (user) => {
            Auth.completeNewPassword(
              user, // the Cognito User Object
              userCredentials.newPassword // the new password
            )
              .then(async (user) => {
                // at this time the user is logged in if no MFA required
                const refreshToken = (await Auth.currentSession())
                  .getRefreshToken()
                  .getToken();
                dispatch({
                  type: ADD_SESSION,
                  payload: {
                    session_token: user.signInUserSession.idToken.jwtToken,
                    // customerId: user.attributes["custom:customerId"],
                    refreshToken,
                  },
                });
                dispatch(getOperatorDetails());
              })
              .catch((e) => {
                dispatch({ type: SHOW_ERROR, payload: { message: e.message } });
              });
          }
        );
      } else {
        dispatch({
          type: SHOW_ERROR,
          payload: { message: 'Passwords do not match' },
        });
      }
    } catch (error) {
      console.log('error signing in', error);
    }
  };
};

export const authLookupProjects = (creds) => {
  return async (dispatch, getState) => {
    const response = await sdkBackend.authenticationLookupProject(
      getState(),
      dispatch,
      creds
    );
    return response;
  };
};

export const authLookupOidc = (redirectUrl) => {
  return async (dispatch, getState) => {
    const response = await sdkBackend.authenticationLookupOidc(
      getState(),
      dispatch,
      redirectUrl
    );
    return response;
  };
};
