import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  createSliceCustomObject,
  editSlice,
  getSliceObject,
  setCurrentSlice,
  setResourceVersion,
  updateSliceCustomObject,
} from '../../../actions/ConnectClusterActions';
import { getRegisteredClusters } from '../../../actions/RegisterClusterActions';
import AlertDialog from './AlertDialog';
import { DataRow } from './Tables/DataRow';
import { RowContainer } from './Tables/RowContainer';
import SliceDataRow from './Tables/SliceDataRow';

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import removeClusterFromSlice from '../../../actions/ConnectClusterActions';

import { io } from 'socket.io-client';

const FinalButton = styled(Button)({
  color: '#fff',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  height: '50px',
  width: '200px',
  padding: '6px 12px',
  lineHeight: 1.5,
  backgroundColor: '#2868f3',
  borderColor: '#0063cc',
  fontFamily: ['Bai Jamjuree'].join(','),
  '&:hover': {
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    boxShadow: 'none',
  },
});

const ClusterSelect = ({
  clusterList,
  getRegisteredClusters,
  currentSlice,
  createSliceCustomObject,
  editSlice,
  resourceVersion,
  updateSliceCustomObject,
  setResourceVersion,
  getSliceObject,
  setCurrentSlice,
  setEditSlice,
}) => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [registerFilter, setRegisterFilter] = useState(false);
  const [connectedClusters, setConnectedClusters] = useState({});
  const [sortedList, setSortedList] = useState([]);
  const [edited, setEdited] = useState(false);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [duplicateCluster, setDuplicateCluster] = useState({});
  const [clusterName, setClusterName] = useState('');
  const [sliceName, setsliceName] = useState('');
  const [OffboardSliceData, setOffboardSliceData] = useState([]);
  const [DisableOffboardCluster, setDisableOffboardCluster] = useState(false);
  const [OffboardStarted, setOffboardStarted] = useState(false);

  const offBoardCluster = true;

  const dispatch = useDispatch();
  const openModal = () => {
    setOpenAlertModal(true);
  };
  const handleModalClose = (del = false) => {
    if (del === false) {
      setConnectedClusters(duplicateCluster);
      setOpenAlertModal(false);
    } else {
      setConnectedClusters(duplicateCluster);
      setOpenAlertModal(false);
      setOpenAlertModal(false);
    }
  };
  useEffect(() => {
    if (currentSlice?.kubesliceEvents?.[0]?.type === 'InProgress') {
      setDisableOffboardCluster(true);
    } else {
      setDisableOffboardCluster(false);
    }
  }, []);
  useEffect(() => {
    if (currentSlice?.kubesliceEvents?.[0]?.type === 'InProgress') {
      setDisableOffboardCluster(true);
    } else {
      setDisableOffboardCluster(false);
    }
  }, [currentSlice]);

  // useEffect(() => {
  //   getSliceObject(currentSlice.sliceName).then((response) => {
  //     if (response) {
  //       setOffboardSliceData(response);
  //     }
  //   });
  // }, []);

  // let editData = {
  //   sliceName: OffboardSliceData?.metadata?.name,
  //   maxClusters: OffboardSliceData?.spec?.maxClusters,
  //   sliceDisplayName: OffboardSliceData?.metadata?.annotations?.displayName,
  //   sliceType: OffboardSliceData?.spec?.sliceType,
  //   sliceGWType:
  //     OffboardSliceData?.spec?.sliceGatewayProvider?.sliceGatewayType,
  //   sliceCertAuthType:
  //     OffboardSliceData?.spec?.sliceGatewayProvider?.sliceCaType,
  //   sliceQosProfile: OffboardSliceData?.spec?.qosProfileDetails && 'Custom',
  //   sliceQosType: OffboardSliceData?.spec?.qosProfileDetails?.queueType,
  //   sliceQosTcType: OffboardSliceData?.spec?.qosProfileDetails?.tcType,
  //   sliceQosPriority: OffboardSliceData?.spec?.qosProfileDetails?.priority,
  //   bandwidthCeilingKbps:
  //     OffboardSliceData?.spec?.qosProfileDetails?.bandwidthCeilingKbps,
  //   bandwidthGuaranteedKbps:
  //     OffboardSliceData?.spec?.qosProfileDetails?.bandwidthGuaranteedKbps,
  //   dscpClass: OffboardSliceData?.spec?.qosProfileDetails?.dscpClass,
  //   sliceSubnet: OffboardSliceData?.spec?.sliceSubnet,
  //   sliceIpamType: OffboardSliceData?.spec?.sliceIpamType,
  //   sliceDesc: OffboardSliceData?.metadata?.annotations?.desc,
  //   clusters: OffboardSliceData?.spec?.clusters,
  //   isolationEnabled:
  //     OffboardSliceData?.spec?.namespaceIsolationProfile?.isolationEnabled,
  //   applicationNamespaces:
  //     OffboardSliceData?.spec?.namespaceIsolationProfile?.applicationNamespaces,
  //   allowedNamespaces:
  //     OffboardSliceData?.spec?.namespaceIsolationProfile?.allowedNamespaces,
  //   kubesliceEvents: Array.isArray(OffboardSliceData?.status?.kubesliceEvents),
  // };

  // console.log(editData);
  const confirmClose = (del = false) => {
    if (del === false) {
      let query = {
        sliceName,
        clusterName,
      };
      // setConfirmed(true);
      const hostname = window.location.hostname;
      const port = window.location.port;
      let socket_url = `wss://${hostname}/offboard-cluster`;
      if (port) {
        socket_url = `wss://${hostname}:${port}/offboard-cluster`;
      }
      // socket_url = `ws://localhost:3000/offboard-cluster`;

      const socket = io(socket_url, {
        secure: true,
        transports: ['websocket', 'polling'],
      });

      socket.on('connect', () => {
        // const socket = io(process.env.REACT_APP_SOCKET_URL);
        // console.log('connected');
        dispatch(removeClusterFromSlice(query, socket.id));

        setTimeout(() => {
          dispatch(
            getSliceObject(currentSlice.sliceName).then((response) => {
              if (response) {
                let identifier = response.status?.kubesliceEvents || [];
                const filteredEvents = identifier.filter((data) => {
                  return (
                    data.identifier === clusterName &&
                    data.action === 'CLUSTER_OFFBOARDING'
                  );
                });
                setOffboardSliceData(response);
                setOffboardStarted(true);
                setCurrentSlice({
                  ...currentSlice,
                  clusters: Object.keys(duplicateCluster),
                  kubesliceEvents: filteredEvents,
                });
              }
            })
          );
        }, 2000);
      });
      socket.on('message', (data) => {
        // const socket = io(process.env.REACT_APP_SOCKET_URL);
        if (data.status === 'FAILED') {
          // setStopSocket(true);
          socket.disconnect();
        }
      });

      setOpenAlertModal(false);
    } else {
      setOpenAlertModal(false);
      setOpenAlertModal(false);
    }
  };

  useEffect(() => {
    if (editSlice) {
      let missingElementsFound = false;

      let offBoardCluster = Object.keys(duplicateCluster)
        .map((key) => {
          if (!connectedClusters.hasOwnProperty(key)) {
            missingElementsFound = true;
            return key;
          }
        })
        .filter(Boolean);

      if (missingElementsFound) {
        openModal();
      }
      setClusterName(offBoardCluster.toString());
      setsliceName(currentSlice.sliceName);
    }
  }, [duplicateCluster, connectedClusters]);

  useEffect(() => {
    getRegisteredClusters();

    if (currentSlice && currentSlice?.clusters?.length > 0) {
      let _connectedClusters = {};
      currentSlice.clusters.forEach((cluster) => {
        _connectedClusters[cluster] = true;
      });
      setConnectedClusters(_connectedClusters);
      setDuplicateCluster(_connectedClusters);
    }
  }, []);

  useEffect(() => {
    const sortedClusterList = sortClusterList(clusterList);
    setSortedList(sortedClusterList);
  }, [clusterList]);

  const sortClusterList = (clusterList) => {
    const sorted =
      clusterList?.items?.length > 0
        ? clusterList?.items.sort(function (a, b) {
            const statusA = a?.status?.cniSubnet?.length > 0 ? true : false;
            const statusB = b?.status?.cniSubnet?.length > 0 ? true : false;
            if (statusA) return -1;
            else if (statusB) return 1;
            else return 0;
          })
        : [];
    return sorted;
  };

  const filterPopUpAction = (event) => {
    setOpenFilterPopUp((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClusterSelect = (cluster, checked) => {
    let _connectedClusters = { ...connectedClusters };

    if (
      cluster?.metadata?.name &&
      connectedClusters[cluster?.metadata?.name] &&
      !checked
    ) {
      delete _connectedClusters[cluster?.metadata?.name];
    } else {
      _connectedClusters[cluster?.metadata?.name] = true;
    }
    setConnectedClusters(_connectedClusters);
  };
  if (handleClusterSelect > currentSlice.maxClusters) {
  }

  const handleConnectCluster = async () => {
    if (editSlice) {
      setConnecting(true);
      let slice = {
        ...currentSlice,
        clusters: Object.keys(connectedClusters),
      };
      setCurrentSlice({
        ...currentSlice,
        clusters: Object.keys(connectedClusters),
      });
      updateSliceCustomObject(slice).then((res) => {
        setEdited(false);
        getSliceObject(currentSlice.sliceName).then((response) => {
          setResourceVersion(response?.metadata?.resourceVersion); // set latest resource version
          if (response) {
            setConnected(true);
          }
        });
        setConnecting(false);
      });
    } else {
      setConnecting(true);
      let slice = { ...currentSlice, clusters: Object.keys(connectedClusters) };
      setCurrentSlice({
        ...currentSlice,
        clusters: Object.keys(connectedClusters),
      });
      createSliceCustomObject(slice).then((res) => {
        setEdited(false);
        setConnecting(false);
        if (res) {
          setResourceVersion(res.metadata.resourceVersion);
          setConnected(true);
          setEditSlice(true);
        }
      });
    }
  };

  const isMaxClusterLimitReached = useMemo(() => {
    return parseInt(currentSlice?.maxClusters || 16) <=
      Object.keys(connectedClusters).length
      ? true
      : false;
  }, [currentSlice?.maxClusters, connectedClusters]);

  useEffect(() => {
    getSliceObject(currentSlice.sliceName).then((response) => {
      if (response) {
        let identifier = response.status?.kubesliceEvents || [];
        const filteredEvents = identifier.filter((data) => {
          return data.type === 'InProgress';
        });
        const disableButton = filteredEvents.length > 0;
        setDisableOffboardCluster(disableButton);
      }
    });
  }, []);

  // useEffect(() => {
  //   const kubesliceEvent =
  //     OffboardSliceData?.status?.kubesliceEvents &&
  //     OffboardSliceData?.status?.kubesliceEvents.find(
  //       (event) => event.type === 'InProgress'
  //     );
  //   if (kubesliceEvent) {
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // }, [OffboardSliceData, connectedClusters]);

  // useEffect(() => {
  //   console.log(OffboardSliceData);
  //   // let identifier = OffboardSliceData?.status?.filteredEvents?.[0]?.type;
  //   // console.log(identifier);
  //   // if (identifier === 'InProgress') {
  //   //   console.log(314);
  //   //   setDisableOffboardCluster(true);
  //   // } else {
  //   //   setDisableOffboardCluster(false);
  //   // }
  // }, [OffboardSliceData, DisableOffboardCluster]);
  // useEffect(() => {
  //   let identifier = OffboardSliceData?.status?.kubesliceEvents || [];

  //   const filteredEvents = identifier.filter((data) => {
  //     return data.identifier === clusterName;
  //   });
  //   let offboarddisabledata = filteredEvents?.[0]?.type;
  //   console.log(filteredEvents);
  //   console.log(filteredEvents?.[0]?.type);
  //   if (offboarddisabledata === 'InProgress') {
  //     console.log(314);
  //     setDisableOffboardCluster(true);
  //   } else {
  //     setDisableOffboardCluster(false);
  //   }
  // }, [OffboardSliceData]);

  if (currentSlice?.sliceName !== '') {
    return (
      <>
        <AlertDialog
          open={openAlertModal}
          handleClose={handleModalClose}
          clusterName={clusterName}
          sliceName={currentSlice.sliceName}
          confirmClose={confirmClose}
          currentSlice={currentSlice}
          // setIsChanged={setIsChanged}
        />
        <Prompt
          when={edited}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Grid
          container
          item
          xs
          gap={3}
          flexWrap="nowrap"
          overflow="auto"
          height="100%"
          direction="column"
        >
          <Grid
            item
            container
            direction="column"
            flexWrap="nowrap"
            bgcolor="#ffffff"
            boxShadow="0px 5px 25px rgba(40, 104, 243, 0.04)"
            borderRadius={4}
            position="relative"

            // minHeight={{ sm: "30%", md: "20%" }}
          >
            <Grid item p={4} py={2}>
              <Typography fontSize={20} fontWeight={700}>
                Slice
              </Typography>
            </Grid>
            <RowContainer bgcolor="#DEE8FF">
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Slice
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Slice Type
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Gateway Type
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  CA Type
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Qos Type
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Description
                </Typography>
              </Grid>
            </RowContainer>
            <Grid
              item
              height="45px"
              container
              direction="column"
              flexWrap="nowrap"
              overflow="auto"
            >
              <SliceDataRow value={currentSlice} />
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            flexWrap="nowrap"
            bgcolor="#ffffff"
            boxShadow="0px 5px 25px rgba(40, 104, 243, 0.04)"
            borderRadius={4}
            position="relative"
          >
            <Grid
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              item
              p={4}
              py={2}
            >
              <Typography
                sx={{ alignSelf: 'start' }}
                fontSize={20}
                fontWeight={700}
              >
                Clusters
              </Typography>
              <Button
                sx={{ alignSelf: 'start' }}
                onClick={filterPopUpAction}
                startIcon={<FilterAltIcon />}
              >
                Filters
              </Button>
              <Popper
                placement="right"
                open={openFilterPopUp}
                anchorEl={anchorEl}
                style={{ zIndex: '100' }}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={0}>
                    <Paper sx={{ padding: '10px 20px' }} elevation={10}>
                      <CloseIcon
                        onClick={filterPopUpAction}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          fontSize: '16px',
                          fontWeight: '700',
                        }}
                      />
                      <Typography fontSize="16px" fontWeight="700">
                        Filters
                      </Typography>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={DisableOffboardCluster}
                              size="small"
                              onChange={(e) =>
                                setRegisterFilter(e.target.checked)
                              }
                              checked={registerFilter}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              Show Only Registered Cluster
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Grid>
            <RowContainer bgcolor="#DEE8FF">
              <Grid item xs={0.5}></Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Cluster name
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Node IP
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Cloud
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontSize={12} fontWeight={600}>
                  Status
                </Typography>
              </Grid>
            </RowContainer>
            <Grid
              item
              xs
              container
              direction="column"
              flexWrap="nowrap"
              overflow="auto"
              minHeight="135px"
            >
              {sortedList?.length > 0 &&
                sortedList.map((row, index) => {
                  let selected = Object.keys(connectedClusters).includes(
                    row?.metadata?.name
                  );
                  const clusterRegistered = row?.status?.cniSubnet?.length > 0;
                  if (
                    !registerFilter ||
                    (registerFilter && clusterRegistered)
                  ) {
                    return (
                      <DataRow
                        key={index}
                        isMaxClusterLimitReached={isMaxClusterLimitReached}
                        clusterData={row}
                        selected={selected}
                        handleClusterSelect={(checked) => {
                          setEdited(true);
                          handleClusterSelect(row, checked);
                        }}
                        currentSlice={currentSlice}
                        disableOffboard={DisableOffboardCluster}
                      />
                    );
                  }
                })}
            </Grid>

            <Grid
              item
              width="fit-content"
              height="fit-content"
              minWidth="150px"
              minHeight="50px"
              p={1}
              my={3}
              alignSelf="center"
              borderRadius={1}
              alignItems="center"
              justifyContent="center"
              container
            >
              {connecting ? (
                <CircularProgress sx={{ color: '#ffffff' }} size="50px" />
              ) : (
                <FinalButton
                  //  disabled={Object.keys(connectedClusters).length == 0}
                  onClick={handleConnectCluster}
                  disabled={DisableOffboardCluster}
                  style={{
                    backgroundColor: DisableOffboardCluster
                      ? '#CCCCCC'
                      : undefined,
                  }}
                >
                  Connect Clusters
                </FinalButton>
              )}
            </Grid>
            {/* <Grid
              item
              width="fit-content"
              height="fit-content"
              minWidth="150px"
              minHeight="50px"
              p={1}
              my={3}
              alignSelf="center"
              borderRadius={1}
              alignItems="center"
              justifyContent="center"
              container
            >
              <FinalButton onClick={openModal}>dialouge</FinalButton>
            </Grid> */}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Typography variant="h6" component="div" gutterBottom>
      No slice to display
    </Typography>
  );
};
const mapStateToProps = (state) => {
  return {
    clusterList: state.RegisterCluster.clusterList,
    currentSlice: state.ConnectCluster.currentSlice,
    editSlice: state.ConnectCluster.editSlice,
    resourceVersion: state.ConnectCluster.resourceVersion,
  };
};
export default connect(mapStateToProps, {
  getRegisteredClusters,
  createSliceCustomObject,
  updateSliceCustomObject,
  setResourceVersion,
  getSliceObject,
  setCurrentSlice,
  setEditSlice: editSlice,
})(ClusterSelect);
