import * as React from 'react';
import OtherOnCloud from '../../assets/images/cloud-other.svg';
import { CloudTooltipCommon, CommonCloud } from './Cloud1';
import Other from '../../assets/images/Other.svg';

function OtherCloud(props) {
  return <CommonCloud {...props} img={Other} />;
}

export const OtherTooltip = (props) => {
  return <CloudTooltipCommon {...props} logo={OtherOnCloud} img={Other} />;
};

export default OtherCloud;
