import {
  SET_STEPPER_PARAMETER,
  SET_STEPPER_TAB,
  SET_CLUSTERS,
  SET_SERVICES,
  ACTION_LOGOUT_INITIATED,
  SET_CURRENT_SLICE,
  SET_SLICE,
} from "../actions/types";

const dummyClusters = [
  {
    clusterId: "azure-dev-asfasfg-asgfsag",
    clusterName: "azure-dev-asfasfg-asgfsag",
    network: "azure",
    status: 1,
    services: [
      {
        id: "azure-dev-asfasfg-asgfsag",
        serviceName: "azure-dev-asfasfg-asgfsag",
        network: "azure",
        status: 1,
        clusterId: "azure-dev-asfasfg-asgfsag",
        clusterName: "azure-dev-asfasfg-asgfsag",
      },
      {
        id: "azure-dev-safag-agasdh",
        serviceName: "azure-dev-adhgash-adhgah",
        network: "azure",
        status: 1,
        clusterId: "azure-dev-asfasfg-asgfsag",
        clusterName: "azure-dev-asfasfg-asgfsag",
      },
      {
        id: "azure-dev-adhgah-asdhdah",
        serviceName: "azure-dev-adshah-adhdah",
        network: "azure",
        status: 1,
        clusterId: "azure-dev-asfasfg-asgfsag",
        clusterName: "azure-dev-asfasfg-asgfsag",
      },
      {
        id: "azure-dev-adhadhh-adhahh",
        serviceName: "azure-dev-dahadhh-adhahh",
        network: "azure",
        status: 1,
        clusterId: "azure-dev-asfasfg-asgfsag",
        clusterName: "azure-dev-asfasfg-asgfsag",
      },
    ],
  },
  {
    clusterId: "gcp-dev-asfasfg-asgfsag",
    clusterName: "gcpdev-asfasfg-asgfsag",
    network: "google",
    status: 1,
    services: [
      {
        id: "gcp-dev-asfasfg-asgfsag",
        serviceName: "gcp-dev-asfasfg-asgfsag",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asfasfg-asgfsag",
        clusterName: "gcp-dev-asfasfg-asgfsag",
      },
      {
        id: "gcp-dev-safag-agasdh",
        serviceName: "gcp-dev-adhgash-adhgah",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asfasfg-asgfsag",
        clusterName: "gcp-dev-asfasfg-asgfsag",
      },
      {
        id: "gcp-dev-adhgah-asdhdah",
        serviceName: "gcp-dev-adshah-adhdah",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asfasfg-asgfsag",
        clusterName: "gcp-dev-asfasfg-asgfsag",
      },
      {
        id: "gcp-dev-adhadhh-adhahh",
        serviceName: "gcp-dev-dahadhh-adhahh",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asfasfg-asgfsag",
        clusterName: "gcp-dev-asfasfg-asgfsag",
      },
    ],
  },
  {
    clusterId: "gcp-dev-asgasgg-asgfsag",
    clusterName: "gcpdev-asdghsdhg-asgfsag",
    network: "google",
    status: 1,
    services: [
      {
        id: "gcp-dev-asdhgshg-asgfsag",
        serviceName: "gcp-dev-ashshsg-asgfsag",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asgasgg-asgfsag",
        clusterName: "gcp-dev-asgasgg-asgfsag",
      },
      {
        id: "gcp-dev-shshsagasdh",
        serviceName: "gcp-dev-afsdjsjsh-adhgah",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asgasgg-asgfsag",
        clusterName: "gcp-dev-asgasgg-asgfsag",
      },
      {
        id: "gcp-dev-sfdjsjasdhdah",
        serviceName: "gcp-dev-asfjhshjsh-adhdah",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asgasgg-asgfsag",
        clusterName: "gcp-dev-asgasgg-asgfsag",
      },
      {
        id: "gcp-dev-sfhsjhsf-adhahh",
        serviceName: "gcp-dev-fsdhjsfjsfh-adhahh",
        network: "gcp",
        status: 1,
        clusterId: "gcp-dev-asgasgg-asgfsag",
        clusterName: "gcp-dev-asgasgg-asgfsag",
      },
    ],
  },
  {
    clusterId: "aws-dev-asgasgg-asgfsag",
    clusterName: "aws-dev-asdghsdhg-asgfsag",
    network: "amazon",
    status: 1,
    services: [
      {
        id: "aws-dev-asdhgshg-asgfsag",
        serviceName: "aws-dev-ashshsg-asgfsag",
        network: "aws",
        status: 1,
        clusterId: "aws-dev-asgasgg-asgfsag",
        clusterName: "aws-dev-asgasgg-asgfsag",
      },
      {
        id: "aws-dev-shshsagasdh",
        serviceName: "aws-dev-afsdjsjsh-adhgah",
        network: "aws",
        status: 1,
        clusterId: "aws-dev-asgasgg-asgfsag",
        clusterName: "aws-dev-asgasgg-asgfsag",
      },
      {
        id: "aws-dev-sfdjsjasdhdah",
        serviceName: "aws-dev-asfjhshjsh-adhdah",
        network: "aws",
        status: 1,
        clusterId: "aws-dev-asgasgg-asgfsag",
        clusterName: "aws-dev-asgasgg-asgfsag",
      },
      {
        id: "aws-dev-sfhsjhsf-adhahh",
        serviceName: "aws-dev-fsdhjsfjsfh-adhahh",
        network: "aws",
        status: 1,
        clusterId: "aws-dev-asgasgg-asgfsag",
        clusterName: "aws-dev-asgasgg-asgfsag",
      },
    ],
  },
  {
    clusterId: "dc-dev-asgasgg-asgfsag",
    clusterName: "dc-ev-asdghsdhg-asgfsag",
    network: "data-center",
    status: 1,
    services: [
      {
        id: "dc-dev-asdhgshg-asgfsag",
        serviceName: "dc-dev-ashshsg-asgfsag",
        network: "data-center",
        status: 1,
        clusterId: "dc-dev-asgasgg-asgfsag",
        clusterName: "dc-dev-asgasgg-asgfsag",
      },
      {
        id: "dc-dev-shshsagasdh",
        serviceName: "dc-dev-afsdjsjsh-adhgah",
        network: "data-center",
        status: 1,
        clusterId: "dc-dev-asgasgg-asgfsag",
        clusterName: "dc-dev-asgasgg-asgfsag",
      },
      {
        id: "dc-dev-sfdjsjasdhdah",
        serviceName: "aws-dev-asfjhshjsh-adhdah",
        network: "data-center",
        status: 1,
        clusterId: "dc-dev-asgasgg-asgfsag",
        clusterName: "dc-dev-asgasgg-asgfsag",
      },
      {
        id: "dc-dev-sfhsjhsf-adhahh",
        serviceName: "aws-dev-fsdhjsfjsfh-adhahh",
        network: "data-center",
        status: 1,
        clusterId: "dc-dev-asgasgg-asgfsag",
        clusterName: "dc-dev-asgasgg-asgfsag",
      },
    ],
  },
];
const dummySliceDesign = {
  "slice-1": {
    sliceName: "slice1",
    namespaceCount: 20,
    clusters: {
      c1: {
        provider: "Azure",
        clusterName: "edge-outpost",
        network: "Azure",
        services: {
          sv1: {
            serviceName: "productpage-1",
          },
          sv2: {
            serviceName: "ratings_v3",
          },
          sv3: {
            serviceName: "ratings_v5",
          },
          sv4: {
            serviceName: "ratings_v6",
          },
        },
      },
      c2: {
        provider: "Azure",
        clusterName: "edge-outpost",
        network: "Azure",
        services: {
          sv5: {
            serviceName: "reviews_v3",
          },
          sv6: {
            serviceName: "reviews_v2",
          },
          sv7: {
            serviceName: "reviews_v1",
          },
        },
      },
    },
  },
};
const initialState = {
  clusters: dummyClusters, //putting dummy cluster for now
  stepper: {
    popUp: false,
    currentStep: 0,
    cluster: {
      tabPanel: "azure",
    },
  },
  slices: dummySliceDesign, //Object to persist the selected clusters and services
  currentSlice: "slice-1",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STEPPER_PARAMETER:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          popUp: action.payload.status,
          currentStep: action.payload.step,
        },
        currentSlice: action?.payload?.currentSlice || state.currentSlice,
      };
    case SET_STEPPER_TAB:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          cluster: {
            tabPanel: action.payload.name,
          },
        },
      };
    case SET_CURRENT_SLICE:
      return {
        ...state,
        currentSlice: action.payload.currentSlice || state.currentSlice,
      };
    case SET_SLICE:
      return {
        ...state,
        slices: {
          ...state.slices,
          [action.payload.slice.sliceName]: {
            namespaceCount: 0,
            ...action.payload.slice,
            clusters: {},
          },
        },
      };
    case SET_CLUSTERS:
      return {
        ...state,
        slices: {
          ...state.slices,
          [action.payload.currentSlice]: {
            ...state.slices[action.payload.currentSlice],
            clusters: action.payload.clusters,
          },
        },
      };
    case SET_SERVICES:
      return {
        ...state,
        slices: {
          ...state.slices,
          [action.payload.currentSlice]: {
            ...state.slices[action.payload.currentSlice],
            clusters: {
              ...state.slices[action.payload.currentSlice].clusters,
              [action.payload.clusterName]: {
                ...state.slices[action.payload.currentSlice].clusters[
                  action.payload.clusterName
                ],
                services: action.payload.serviceObject,
              },
            },
          },
        },
      };
    case ACTION_LOGOUT_INITIATED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
