import {
  updateSnackbar,
  updateSnackbarSuccess,
} from './../actions/SnackbarActions';
import history from './../utils/history';
import { API_HOST, API_KEY } from './api-config';
import * as ApiEndpoint from './ApiEndpoints';
import BaseBackend from './BaseBackend';
import * as HttpMethod from './HTTPMethodConstants';

const parmaterize = (params) => {
  let result = '?';
  for (let param in params) {
    if (params[param] || params[param] == 0)
      result += param + '=' + params[param] + '&';
  }
  result = result.slice(0, result.length - 1);
  return result;
};

const handleError = async (e, errMsg, dispatch, state = null) => {
  if (e.status === 401) {
    const sdkBackend = new SdkBackend();
    await sdkBackend.logoutUser(state, dispatch);
    history.push('/');
    return;
  }
  if (e.bodyUsed) return;
  let msg = '';
  if (e?.text) msg = await e.text();

  if (msg !== '') {
    msg = JSON.parse(msg);
    msg = msg?.message;
  } else {
    if (typeof e === 'string') {
      msg = e;
    } else {
      msg = errMsg;
    }
  }

  if (msg === 'Unauthorized') {
    msg = 'Session Timeout';
  }

  dispatch(updateSnackbar(true, msg));
  throw e;
};
export class SdkBackend extends BaseBackend {
  constructor() {
    super(window.location.pathname, '');
  }

  async getApiCall(
    state,
    dispatch,
    endpoint,
    data = null,
    errorMsg = 'Unable to fetch data!',
    showSpinner = true
  ) {
    let response = await this._fetch(
      HttpMethod.GET,
      'api/v1' + endpoint,
      data,
      state.SessionReducer?.session_token,
      dispatch,
      showSpinner
    ).catch((e) => {
      handleError(e, errorMsg, dispatch, state);
    });
    return response;
  }

  // this call is only used in license action to throw error to display invalid license
  // if the get api call is used, livalid license will flash on session timeout
  async getLicenseCall(
    state,
    dispatch,
    endpoint,
    data = null,
    errorMsg = 'Unable to fetch data!',
    showSpinner = true
  ) {
    let response = await this._fetch(
      HttpMethod.GET,
      'api/v1' + endpoint,
      data,
      state.SessionReducer?.session_token,
      dispatch,
      showSpinner
    ).catch((e) => {
      handleError(e, errorMsg, dispatch, state);
      throw e;
    });
    return response;
  }

  async postApiCall(
    state,
    dispatch,
    endpoint,
    data = null,
    errorMsg = 'Unable to post data!'
  ) {
    let response = await this._fetch(
      HttpMethod.POST,
      'api/v1' + endpoint,
      data,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      if (e.status === 401) {
        this.logoutUser(state, dispatch);
      } else {
        handleError(e, errorMsg, dispatch);
      }
    });
    return response;
  }

  async putApiCall(
    state,
    dispatch,
    endpoint,
    data = null,
    errorMsg = 'Unable to update data!'
  ) {
    let response = await this._fetch(
      HttpMethod.PUT,
      'api/v1' + endpoint,
      data,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(e, errorMsg, dispatch, state);
    });
    return response;
  }

  async deleteApiCall(
    state,
    dispatch,
    endpoint,
    data = null,
    errorMsg = 'Unable to delete data!'
  ) {
    let response = await this._fetch(
      HttpMethod.DELETE,
      'api/v1' + endpoint,
      data,
      state?.SessionReducer?.session_token,
      dispatch
    ).catch((e) => {
      if (e.status === 401) {
        this.logoutUser(state, dispatch);
      } else {
        handleError(e, errorMsg, dispatch);
      }
    });
    return response;
  }

  async authenticateUser(state, dispatch, userCredentials) {
    let Endpoint = '/authentication/';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      userCredentials,
      'Unable to authenticate user'
    );
    if (response) return response;
  }

  async logoutUser(state, dispatch, data) {
    let Endpoint = '/authentication/logout';
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to logout user'
    );
    if (response) return response;
  }

  async authenticationLookupProject(state, dispatch, creds) {
    const Endpoint = '/authentication/lookup-projects';
    const response = await this.postApiCall(state, dispatch, Endpoint, creds);

    if (response) return response;
  }

  async authenticationLookupOidc(state, dispatch, redirectUrl) {
    const Endpoint = `/authentication/oidc/login?redirect_url=${encodeURI(
      redirectUrl
    )}`;
    const response = await this.getApiCall(state, dispatch, Endpoint);

    if (response) return response;
  }
  async getRegisteredClusters(state, dispatch) {
    let Endpoint = '/cluster/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch clusters'
    );
    return response;
  }
  async getClusterSecret(state, dispatch, clusterName) {
    let Endpoint = '/cluster/secrets/' + clusterName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster secret'
    );
    return response;
  }
  async getAllClustersForSlice(state, dispatch, sliceName) {
    let Endpoint = '/cluster/list/' + sliceName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch clusters'
    );
    return response;
  }
  async getAllServiceImportsForSlice(state, dispatch, sliceName) {
    let Endpoint = '/slice/serviceImports/' + sliceName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch service imports'
    );
    return response;
  }
  async getAllServiceExportsForSlice(state, dispatch, sliceName) {
    let Endpoint = '/slice/serviceExports/' + sliceName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch Service Exports'
    );
    return response;
  }

  async getCompareClusterStats(state, dispatch, cluster1, cluster2, slice) {
    let Endpoint =
      '/cluster/compare' + parmaterize({ cluster1, cluster2, slice });
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster statistics',
      false
    );
    return response;
  }
  async getCurentProject(state, dispatch) {
    const Endpoint = `/events/listProjectNamespace`;
    const response = await this.getApiCall(state, dispatch, Endpoint);

    if (response) return response;
  }

  /// get RQ trends
  async getRqtrends(state, dispatch, slice, cluster, namespace, start, end) {
    let Endpoint1 =
      `/resource-quota/trends/cpu/${slice}` +
      (cluster ? `/${cluster}` : '') +
      (namespace ? `/${namespace}` : '') +
      `/?startDate=${start}&endDate=${end}`;
    const cpu = await this.getApiCall(
      state,
      dispatch,
      Endpoint1,
      null,
      'Unable to fetch cpu RQ Trends details',
      false
    );
    let Endpoint2 =
      `/resource-quota/trends/memory/${slice}` +
      (cluster ? `/${cluster}` : '') +
      (namespace ? `/${namespace}` : '') +
      `/?startDate=${start}&endDate=${end}`;
    const memory = await this.getApiCall(
      state,
      dispatch,
      Endpoint2,
      null,
      'Unable to fetch memory RQ Trends details',
      false
    );
    let Endpoint3 =
      `/resource-quota/trends/ephemeral-storage/${slice}` +
      (cluster ? `/${cluster}` : '') +
      (namespace ? `/${namespace}` : '') +
      `/?startDate=${start}&endDate=${end}`;
    const ephemeral = await this.getApiCall(
      state,
      dispatch,
      Endpoint3,
      null,
      'Unable to fetch ephemeral RQ Trends details',
      false
    );
    let Endpoint4 =
      `/resource-quota/trends/pod-count/${slice}` +
      (cluster ? `/${cluster}` : '') +
      (namespace ? `/${namespace}` : '') +
      `/?startDate=${start}&endDate=${end}`;
    const pod = await this.getApiCall(
      state,
      dispatch,
      Endpoint4,
      null,
      'Unable to fetch pod-count RQ Trends details',
      false
    );

    return { cpu: cpu, memory: memory, ephemeral: ephemeral, pod: pod };
  }

  // Slice role
  async getAllSliceRoles(state, dispatch) {
    let Endpoint = '/rbac/listRoleBinding';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch slice Roles'
    );
    return response;
  }
  // Get Worker Role Binding Status
  async getWorkerRoleBindingStatus(state, dispatch, sliceName) {
    let Endpoint = `/rbac/listWorkerRoleBindingStatus/${sliceName}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slice'
    );
    return response;
  }

  // Create Slice Role Binding
  async registerSliceRoleBinding(state, dispatch, roleBindingDetail) {
    let Endpoint = '/rbac/registerRoleBinding';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      roleBindingDetail,
      'Unable to register slice role binding'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(
          true,
          'Slice Role binding successfully registered'
        )
      );
      return response;
    } else {
      dispatch(
        updateSnackbar(true, 'Slice Role binding could not be registered')
      );
    }
  }

  //delete slice role binding
  async deleteSliceRoleBinding(state, dispatch, data) {
    let Endpoint = '/rbac/deleteRoleBinding';
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to delete slice role binding'
    );

    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Slice Role binding Deleted Successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Slice Role binding could not be Deleted'));
      return response;
    }
  }

  // Slice Resource Quota
  async getResourceQuotaList(state, dispatch) {
    let Endpoint = '/resource-quota/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch resource quota'
    );
    return response.slices;
  }

  //Edit Slice Resource Quota
  async updateCustomResourceQuotaObject(state, dispatch, resourceQuotaDetail) {
    let Endpoint = '/resource-quota';
    const response = await this.putApiCall(
      state,
      dispatch,
      Endpoint,
      resourceQuotaDetail,
      'Unable to edit resource quota object'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Resource Quota Updated Successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Resource Quota could not be updated'));
    }
  }

  // Create Slice Resource Quota
  async registerCustomResourceQuotaObject(
    state,
    dispatch,
    resourceQuotaDetail
  ) {
    let Endpoint = '/resource-quota/register';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      resourceQuotaDetail,
      'Unable to create resource quota object'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Resource Quota Successfully Created')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Resource Quota could not be created'));
    }
  }

  //Delete Slice Resource Quota
  async deleteCustomResourceQuotaObject(state, dispatch, sliceName) {
    let Endpoint = '/resource-quota';
    const data = { sliceName };
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to delete slice resource quota config'
    );

    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Slice Resource Quota Deleted Successfully')
      );
      return response;
    } else {
      dispatch(
        updateSnackbar(true, 'Slice Resource Quota Could Not Be Deleted')
      );
      return response;
    }
  }

  async getResourceQuotaPromCountsForSlice(state, dispatch, sliceName) {
    let Endpoint = '/resource-quota/list/prometheus-count/slice/' + sliceName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch gateways'
    );
    return response;
  }

  async getResourceQuotaPromCountsForNamespace(
    state,
    dispatch,
    sliceName,
    clusterName,
    namespace
  ) {
    let Endpoint = `/resource-quota/list/prometheus-count/namespace/${sliceName}/${clusterName}/${namespace}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch gateways'
    );
    return response;
  }

  async getAllGatewaysForSlice(state, dispatch, sliceName) {
    let Endpoint = '/gateways/slice/' + sliceName;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch gateways'
    );
    return response;
  }
  async getAllGatewaysForCluster(state, dispatch, query) {
    let Endpoint = `/gateways/cluster/${query.clusterId}/${query.sliceName}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch gateways'
    );
    return response;
  }

  async createCustomClusterObject(state, dispatch, clusterDetail) {
    let Endpoint = '/cluster/register';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      clusterDetail,
      'Unable to create cluster object'
    );
    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Cluster Created Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Cluster could not be created'));
    }
  }

  async updateCustomClusterObject(state, dispatch, clusterDetail) {
    let Endpoint = '/cluster';
    const response = await this.putApiCall(
      state,
      dispatch,
      Endpoint,
      clusterDetail,
      'Unable to create cluster object'
    );
    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Cluster Updated Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Cluster could not be updated'));
    }
  }

  async deleteCustomClusterObject(state, dispatch, clusterName) {
    let Endpoint = '/cluster';
    const data = { clusterName };
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to create slice config'
    );

    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Cluster Deleted Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Cluster could not be deleted'));
    }
  }

  async createSliceCustomObject(state, dispatch, sliceDetail) {
    let Endpoint = '/slice/register';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      sliceDetail,
      'Unable to create slice config'
    );
    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Slice Created Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable to Create Slice'));
    }
  }

  async updateSliceCustomObject(state, dispatch, sliceDetail) {
    let Endpoint = '/slice';
    const response = await this.putApiCall(
      state,
      dispatch,
      Endpoint,
      sliceDetail,
      'Unable to update slice details'
    );
    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Slice Updated Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable to update Slice'));
    }
  }

  async deleteSliceCustomObject(state, dispatch, sliceName, socketId) {
    let Endpoint = '/slice';
    const data = { sliceName, socketId };
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to create slice config'
    );
    if (response) {
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable to create slice config'));
    }
  }
  async removeClusterFromSlice(state, dispatch, query, socketId) {
    let Endpoint = `/slice/${query.sliceName}/offboard-cluster/${query.clusterName}`;
    const data = { socketId: socketId };
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to remove cluster from slice'
    );
    if (response) {
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable to remove cluster from slice'));
    }
  }

  async removeNamespacesToSlice(state, dispatch, query, socketId) {
    let Endpoint = `/slice/${query.SliceName}/offboard-namespace/${query.namespaceName}`;
    const data = {
      socketId: socketId,
      clusters: [query?.NameSpaceObject?.[0]],
    };
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable to remove cluster from slice'
    );
    if (response) {
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable to remove cluster from slice'));
    }
  }

  async getSliceCustomObjectList(state, dispatch) {
    let Endpoint = '/slice/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slices list'
    );
    console.log(response)
    localStorage.setItem('isAdmin', JSON.stringify(response.isAdmin));
    localStorage.setItem('sliceName', response?.items[0]?.metadata?.name)
    return response;
  }

  async getListMigrationPairs(state, dispatch) {
    let Endpoint = '/migrationSlice/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slices list'
    );
    return response;
  }
  async getMigrationJobList(state, dispatch) {
    let Endpoint = '/migrationJob/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slices list'
    );
    return response;
  }

  async getSliceDetail(state, dispatch) {
    let Endpoint = '/slice/details';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slices list'
    );
    return response;
  }

  async getAllSliceNamespaces(state, dispatch, sliceName) {
    let Endpoint = '/slice/details';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slices list'
    );
    return response;
  }

  async getSliceObject(state, dispatch, sliceName) {
    let Endpoint = `/slice/slice-name/${sliceName}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get slice'
    );
    return response;
  }

  //dashboard API

  async getAllServiceDependencyRequest(state, dispatch, params) {
    //remove the mili seconds part from timestamp
    if (params.endTime && params.startTime) {
      params.endTime = Math.ceil(params.endTime / 1000);
      params.startTime = Math.ceil(params.startTime / 1000);
    }
    let Endpoint =
      `${ApiEndpoint.CLUSTER}${ApiEndpoint.SERVICE_DEPENDENCY_GRAPH}` +
      parmaterize(params);
    let response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch service dependency details'
    );
    return response;
  }

  async getEntityCount(state, dispatch) {
    let Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.ENTITY_COUNT}`;
    let response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch entity count details'
    );
    return response;
  }

  async getNodeCapacityDetail(state, dispatch, clusterName) {
    let Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.NODE}/${clusterName}`;

    let response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch namespace capacity details'
    );
    return response;
  }

  async getClusterDetail(state, dispatch, start = null, end = null) {
    let Endpoint, response;
    // We're calling parameterize version of this api if we get start and end time frame for range of cluster used capacity data
    // Else we're using default api for single value for cluster used capacity
    if (start !== null && end !== null) {
      Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.DETAILS}${parmaterize({
        start,
        end,
      })}`;
    } else {
      Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.DETAILS}`;
    }

    response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster details'
    );
    return response;
  }

  async getKubernetesDashboard(state, dispatch, clusterName) {
    let Endpoint = `${ApiEndpoint.CLUSTER}/k8s-dashboard/redirect/${clusterName}`;
    let response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster details'
    );
    return response;
  }

  async getCompareAllClusterStats(
    state,
    dispatch,
    anchoredCluster,
    slice,
    start = null,
    end = null
  ) {
    let Endpoint =
      '/cluster/all-compare' +
      parmaterize({ anchoredCluster, slice, start, end });
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster statistics',
      false
    );
    return response;
  }

  async getTopologyViewMetricStats(state, dispatch, slice, timestamp) {
    let Endpoint =
      '/cluster/topology-metrics' + parmaterize({ slice, timestamp });
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch cluster statistics',
      false
    );
    return response;
  }

  // Manage Roles
  async getRoles(state, dispatch) {
    let Endpoint = '/rbac/listRole';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch roles'
    );
    return response;
  }

  async registerRole(state, dispatch, roleDetails) {
    let Endpoint = '/rbac/registerRole';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      roleDetails,
      'Unable to register role'
    );

    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Role Successfully Created'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Role could not be Created'));
    }
  }

  async updateRole(state, dispatch, roleDetails) {
    let Endpoint = '/rbac/registerRole';
    const response = await this.putApiCall(
      state,
      dispatch,
      Endpoint,
      roleDetails,
      'Unable to update role'
    );

    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Role Updated Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Role could not be updated'));
    }
  }

  async deleteRoleTemplate(state, dispatch, data) {
    let Endpoint = '/rbac/deleteRoleTemplate';
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable To Delete Role Template'
    );

    if (response) {
      dispatch(updateSnackbarSuccess(true, 'Role Deleted Successfully'));
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable To Delete Role Template'));
      return response;
    }
  }

  async deleteRoleBinding(state, dispatch, data) {
    let Endpoint = '/rbac/deleteRoleBinding';
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      data,
      'Unable To Delete Role Binding'
    );

    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Role Binding Deleted Successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Unable To Delete Role Binding'));
      return response;
    }
  }

  /*******
   ** NODE ASSIGNMENT **
   */
  // List nodeaffinity
  async getNodeAffinityList(state, dispatch) {
    let Endpoint = '/nodeAffinity/list';
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch node assignment list'
    );
    return response;
  }

  async getNodeLabels(sliceName, state, dispatch) {
    let Endpoint = `/nodeAffinity/listLabels/${sliceName}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch node labels list'
    );
    return response;
  }
  async createNodeAffinity(state, dispatch, nodeDetails) {
    let Endpoint = '/nodeAffinity/create';
    const response = await this.postApiCall(
      state,
      dispatch,
      Endpoint,
      nodeDetails,
      'Unable to configure Node assignment'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Node assignment created successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Cloud not create Node assignment'));
    }
  }

  async updateNodeAffinity(state, dispatch, nodeDetails) {
    let Endpoint = '/nodeAffinity/updateNodeAssignments';
    const response = await this.putApiCall(
      state,
      dispatch,
      Endpoint,
      nodeDetails,
      'Unable to update Node assignment'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Node assignment Update Successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Could not update node assignment'));
    }
  }

  async deleteNodeAffinity(state, dispatch, nodeDetails) {
    let Endpoint = '/nodeAffinity/deleteNodeAssignments';
    const response = await this.deleteApiCall(
      state,
      dispatch,
      Endpoint,
      nodeDetails,
      'Unable to delete node assignment'
    );
    if (response) {
      dispatch(
        updateSnackbarSuccess(true, 'Node assignment deleted Successfully')
      );
      return response;
    } else {
      dispatch(updateSnackbar(true, 'Could not delete node assignment'));
    }
  }

  async getNodeAssignments(sliceName, state, dispatch) {
    let Endpoint = `/nodeAffinity/get/${sliceName}`;
    const response = await this.getApiCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to fetch node assignments'
    );
    return response;
  }

  async getLicenseDetails(state, dispatch) {
    let Endpoint = `/license/details/`;
    const response = await this.getLicenseCall(
      state,
      dispatch,
      Endpoint,
      null,
      'Unable to get license deatils'
    );
    return response;
  }
}

export default class MeshBackend extends BaseBackend {
  constructor() {
    super(API_HOST, API_KEY);
  }

  async getEnvVariables(state, dispatch) {
    let Endpoint = '';
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      null,
      dispatch
    );
    return response;
  }

  async getSample(state, dispatch) {
    let Endpoint = ApiEndpoint.SAMPLE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.session.session_token,
      dispatch
    );
    return response;
  }

  getClusterMetaData(state, dispatch) {
    let eventCount = ApiEndpoint.EVENT_COUNT;
    let entityCount = ApiEndpoint.ENTITY_COUNT;
    let promiseEventCount = this._fetch(
      HttpMethod.GET,
      eventCount,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    let promiseEntityCount = this._fetch(
      HttpMethod.GET,
      entityCount,
      null,
      state.SessionReducer.session_token,
      dispatch
    );

    return Promise.allSettled([promiseEventCount, promiseEntityCount])
      .then((values) => {
        let response = {};
        response.eventCount =
          values[0].status === 'fulfilled' ? values[0].value : null;
        response.entityCount =
          values[1].status === 'fulfilled' ? values[1].value : null;
        return response;
      })
      .catch((e) => {
        handleError(e, 'Unable to fetch Cluster Metadata', dispatch, state);
      });
  }

  async getAllSlices(state, dispatch) {
    let Endpoint = ApiEndpoint.SLICE + '/?getSlicePolicy=true';
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => handleError(e, 'Unable to fetch Slices', dispatch, state));
    return response;
  }
  async getAllGatewaysForSlice(state, dispatch, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + '/' + sliceName + ApiEndpoint.SLICE_GATEWAYS;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) =>
      handleError(e, 'Unable to fetch Slice Gateways', dispatch, state)
    );
    return response;
  }

  async getOperatorDetails(state, dispatch) {
    let Endpoint = ApiEndpoint.PROFILE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) =>
      handleError(e, 'Unable to fetch Operator Details', dispatch, state)
    );
    return response;
  }

  async postSlice(state, dispatch, sliceDetails) {
    let Endpoint = ApiEndpoint.SLICE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        { slice: sliceDetails },
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, 'Slice Created Successfully'));
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to Create Slice!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to Create Slice!', dispatch, state);
      throw e;
    }
    return response;
  }

  async deleteSlice(state, dispatch, sliceName) {
    let Endpoint = ApiEndpoint.SLICE + '/' + sliceName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.DELETE,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, 'Slice Deleted Successfully'));
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to Delete Slice!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to delete Slice!', dispatch, state);
    }
    return response;
  }

  async deleteSlicePolicy(state, dispatch, sliceName, slicePolicyId) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      '/' +
      slicePolicyId;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.DELETE,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      );
    } catch (e) {
      handleError(e, 'Unable to delete Slice Policy!', dispatch, state);
    }
    return response;
  }

  async attachCluster(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/health`;
    let body = {
      clusterName: 'cluster-1',
      clusterId: '12nsadfvdsdf',
      siteName: 'us-east-1',
    };
    let response = await this._fetch(
      HttpMethod.POST,
      Endpoint,
      body,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getSliceGateway(
    state,
    dispatch,
    sliceName,
    clusterId,
    gatewayId,
    showSpinner = true
  ) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${ApiEndpoint.GATEWAY}/${clusterId}/${gatewayId}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch,
      showSpinner
    );
    return response;
  }

  async getSliceServices(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/svc-import`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async updateSliceNamespaceIsolationProfile(
    state,
    dispatch,
    sliceName,
    sliceData
  ) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}`;
    let response = null;
    try {
      let { sliceQosProfile, namespaceIsolationProfile } = sliceData;
      let body = {
        sliceQosProfile,
        namespaceIsolationProfile,
      };
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        body,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(
              true,
              'Namespace Isolation Profile Updated Successfully'
            )
          );
          response = res;
        })
        .catch((e) =>
          handleError(
            e,
            'Unable to update Namespace Isolation Profile!',
            dispatch,
            state
          )
        );
    } catch (e) {
      handleError(
        e,
        'Unable to update Namespace Isolation Profile!',
        dispatch,
        state
      );
    }
    return response;
  }
  async updateSlice(state, dispatch, sliceName, sliceData) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}`;
    let response = null;
    try {
      let { sliceQosProfile, namespaceIsolationProfile } = sliceData;
      let body = {
        sliceQosProfile,
        namespaceIsolationProfile,
      };
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        body,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(updateSnackbarSuccess(true, 'Slice Updated Successfully'));
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to update Slice!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to update Slice!', dispatch, state);
    }
    return response;
  }

  async getQOSProfiles(state, dispatch) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getCompareClusterStats(state, dispatch, cluster1, cluster2) {
    let Endpoint = ApiEndpoint.CLUSTER_COMPARE;
    let response = await this._fetch(
      HttpMethod.POST,
      Endpoint,
      {
        clusters: [cluster1, cluster2],
      },
      state.SessionReducer.session_token,
      dispatch,
      false
    );
    return response;
  }

  async postSlicePolicy(state, dispatch, slicePolicyDetails, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + '/' + sliceName + ApiEndpoint.SLICE_POLICY;
    try {
      await this._fetch(
        HttpMethod.POST,
        Endpoint,
        slicePolicyDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(
        updateSnackbarSuccess(true, 'Slice Policy Created Successfully')
      );
    } catch (e) {
      handleError(e, 'Unable to Create Slice Policy!', dispatch, state);
    }
  }
  async editSlicePolicy(
    state,
    dispatch,
    slicePolicyDetails,
    sliceName,
    slicePolicyId
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      '/' +
      slicePolicyId;
    try {
      await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        slicePolicyDetails,
        state.SessionReducer.session_token,
        dispatch
      );
      dispatch(
        updateSnackbarSuccess(true, 'Slice Policy Updated Successfully')
      );
    } catch (e) {
      handleError(e, 'Unable to Update Slice Policy!', dispatch, state);
    }
  }

  async postQosProfile(state, dispatch, qosProfileDetails) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        qosProfileDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'QOS Profile Created Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to Create QOS Profile!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to Create QOS Profile!', dispatch, state);
    }
    return response;
  }

  async attachSlicePolicy(state, dispatch, sliceName, slicePolicyId) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.SLICE_POLICY +
      '/' +
      slicePolicyId +
      '/attach';
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        null,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'Slice Policy attached Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to attach Slice Policy!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to attach Slice Policy!', dispatch, state);
    }
    return response;
  }

  async detachSlicePolicy(state, dispatch, sliceName, value) {
    let Endpoint = ApiEndpoint.SLICE + '/' + sliceName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        value,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'Slice Policy Detached Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to detach Slice Policy!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to detach Slice Policy!', dispatch, state);
    }
    return response;
  }

  async editQosProfile(state, dispatch, qosProfileDetails) {
    let Endpoint = ApiEndpoint.QOSPROFILE;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        qosProfileDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'QOS Profile Updated Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to Update QOS Profile!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to Edit QOS Profile!', dispatch, state);
    }
    return response;
  }

  async getAllPoliciesForSlice(state, dispatch, sliceName) {
    let Endpoint =
      ApiEndpoint.SLICE + '/' + sliceName + ApiEndpoint.LIMITED_SLICE_POLICY;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async updateCustomerOverlay(state, dispatch, sliceName, canonicalName, data) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      '/' +
      canonicalName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'Customer Overlay updated Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to update Customer Overlay!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to update Customer Overlay!', dispatch, state);
    }
    return response;
  }
  async toggleEnforcementPoint(
    state,
    dispatch,
    sliceName,
    canonicalName,
    data
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      '/' +
      canonicalName;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.PUT,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(
              true,
              `Enforcement Point ${
                data.enabled == 'true' ? 'Enabled' : 'Disabled'
              } Successfully`
            )
          );
          response = res;
        })
        .catch((e) =>
          handleError(
            e,
            `Unable to ${
              data.enabled == 'true' ? 'Enabled' : 'Disabled'
            } Enforcement Point`,
            dispatch
          )
        );
    } catch (e) {
      handleError(
        e,
        `Unable to ${
          data.enabled == 'true' ? 'Enabled' : 'Disabled'
        } Enforcement Point`,
        dispatch
      );
    }
    return response;
  }
  async updateEnforcementPointMetric(
    state,
    dispatch,
    sliceName,
    canonicalName,
    data
  ) {
    let Endpoint =
      ApiEndpoint.SLICE +
      '/' +
      sliceName +
      ApiEndpoint.ENFORCEMENT_POINTS +
      '/' +
      canonicalName +
      '/metrics';
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        data,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(
              true,
              'Enforcement Metrics updated Successfully'
            )
          );
          response = res;
        })
        .catch((e) =>
          handleError(
            e,
            'Unable to update Enforcement Metrics!',
            dispatch,
            state
          )
        );
    } catch (e) {
      handleError(e, 'Unable to update Enforcement Metrics!', dispatch, state);
    }
    return response;
  }
  async getEnforcementPoints(state, dispatch, sliceName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/enforcement-point`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getMetricsUrl(state, dispatch) {
    let Endpoint = ApiEndpoint.METRICS_URL;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getEnforcementByCanonical(state, dispatch, sliceName, canonicalName) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}/enforcement-point/${canonicalName}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async getTrainingEvents(state, dispatch, sliceName, canonicalName, params) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${
      ApiEndpoint.ENFORCEMENT_POINTS
    }/${canonicalName}/minified-events/${parmaterize(params)}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    );
    return response;
  }

  async postTelemetry(state, dispatch, sliceName, clusterId, telemetryDetails) {
    let Endpoint = `${ApiEndpoint.SLICE}/${sliceName}${ApiEndpoint.TELEMETRY}/${clusterId}`;
    let response = null;
    try {
      response = await this._fetch(
        HttpMethod.POST,
        Endpoint,
        telemetryDetails,
        state.SessionReducer.session_token,
        dispatch
      )
        .then((res) => {
          dispatch(
            updateSnackbarSuccess(true, 'Telemetry Created Successfully')
          );
          response = res;
        })
        .catch((e) =>
          handleError(e, 'Unable to Create Telemetry!', dispatch, state)
        );
    } catch (e) {
      handleError(e, 'Unable to Create Telemetry!', dispatch, state);
    }
    return response;
  }

  async getClusterDetail(state, dispatch) {
    let Endpoint = ApiEndpoint.CLUSTER;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(e, 'Unable to fetch cluster details', dispatch, state);
    });
    return response;
  }

  async getClusterCapacityDetail(state, dispatch) {
    let Endpoint = `${ApiEndpoint.CLUSTER}${ApiEndpoint.CAPACITY}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(
        e,
        'Unable to fetch cluster capacity details',
        dispatch,
        state
      );
    });
    return response;
  }

  async getNamespaceCapacityDetail(state, dispatch, clusterId, nodeName) {
    let Endpoint = `${ApiEndpoint.CLUSTER}/${clusterId}${ApiEndpoint.NODE}/${nodeName}${ApiEndpoint.NAMESPACE}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(
        e,
        'Unable to fetch namespace capacity details',
        dispatch,
        state
      );
    });
    return response;
  }

  async getAllServiceDependencyRequest(
    state,
    dispatch,
    clusterId,
    sliceName,
    params
  ) {
    //remove the mili seconds part from timestamp
    if (params.endTime && params.startTime) {
      params.endTime = Math.ceil(params.endTime / 1000);
      params.startTime = Math.ceil(params.startTime / 1000);
    }
    let Endpoint = `${ApiEndpoint.SERVICE_DEPENDENCY_GRAPH}${
      ApiEndpoint.SLICE
    }/${sliceName}${ApiEndpoint.CLUSTER}/${clusterId}${parmaterize(params)}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(
        e,
        'Unable to fetch namespace capacity details',
        dispatch,
        state
      );
    });
    return response;
  }

  async getSystemEvent(state, dispatch, params) {
    let Endpoint = `${ApiEndpoint.SYSTEM_EVENT}${parmaterize(params)}`;
    let response = await this._fetch(
      HttpMethod.GET,
      Endpoint,
      null,
      state.SessionReducer.session_token,
      dispatch
    ).catch((e) => {
      handleError(
        e,
        'Unable to fetch namespace capacity details',
        dispatch,
        state
      );
    });
    return response;
  }

  // async getProjectList(state, dispatch) {
  //   let Endpoint = 'api/v1/events/listProjectNamespace';
  //   const response = await this.getApiCall(
  //     state,
  //     dispatch,
  //     Endpoint,
  //     null,
  //     'Unable to fetch ProjectName'
  //   );
  //   return response;
  // }
}
