import { default as ArrowForwardIos } from '@mui/icons-material/ArrowForwardIos';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { Box } from '@mui/system';

export const FooterBtns = ({
  nextDisabled,
  prevDisabled,
  handleNext,
  onSkip,
  nextText = 'Next',
  prevText = 'Previous',
  handlePrev,
}) => {
  return (
    <Box
      sx={{
        padding: '12px 40px',
        background: 'white',
        display: 'flex',
        margin: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
      }}
      className="sagar"
    >
      <Box sx={{ display: 'flex', gap: 4 }}>
        {onSkip ? (
          <Button sx={{color:'#5A607C', fontSize:'16px', fontWeight: 600, textTransform: 'none'}} onClick={onSkip}>
            Skip step
            <ArrowForwardIos sx={{ marginLeft:'10px',fontSize: '16px', color: '#5A607C' }} />
          </Button>
        ) : null}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {handlePrev ? (
            <Button
              sx={{ width: '100px', marginRight: '10px', textTransform: 'none', color: '#12153E', fontWeight: 600, fontSize: '16px' }}
              variant="outlined"
              disabled={prevDisabled}
              onClick={handlePrev}
            >
              <ArrowBackIosIcon sx={{ fontSize: '16px', color: '#12153E' }} />
              {prevText}
            </Button>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', gap: 4 }}>
          {handleNext ? (
            <Button
              data-testid="NextButton"
              style={{ width: '100px' }}
              className="btn"
              variant="contained"
              disabled={nextDisabled}
              onClick={handleNext}
            >
              {nextText}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
