import { AccessTimeFilled } from '@mui/icons-material';
import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { selectLicenseDetails } from '../../selectors/licensing.selector';
import { formatDate } from '../../utils/date-time';
import { createUpgradeLicenseLink, isEnterpriseLicense } from '../../utils/license';

// 2023-04-07T11:24:03.602Z

// used material UI because we have to do the same thing in v1
const ExpiredBanner = () => {
  const licenseDetails = useSelector(selectLicenseDetails);

  const {
    'license-expiration': licenseExpiration,
    'license-type': license_type,
  } = licenseDetails || {};

  const expirationDate = formatDate(licenseExpiration);

  const href = createUpgradeLicenseLink(licenseDetails);
  const isEnterprise = isEnterpriseLicense(licenseDetails);

  return (
    <Box
      data-testid="expired-license-banner"
      sx={{
        backgroundColor: '#FEF9F9',
        borderLeft: '4px solid #E15E5E',
        py: 2.5,
        px: 3,
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 8px rgba(31, 61, 153, 0.16)',
        alignItems: 'center',
        mb: '2px',
        zIndex: 100, // probably not needed
        position: 'sticky',
        top: 0,
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}>
        <AccessTimeFilled
          sx={{
            color: '#E15E5E',
            fontSize: 28,
          }}
        />
        <Box
          sx={{
            color: '#E15E5E',
            fontSize: '18px',
            fontWeight: 500,
          }}>
          <Box data-testid="license-banner-heading">
            Your
            <b> {license_type} </b>
            expired on {expirationDate}
          </Box>
          <Box
            component="p"
            sx={{
              fontSize: 14,
              color: '#828490',
              mt: '5px',
              mb: 0,
              fontWeight: 500,
            }}>
            You will not be able to perform actions on any of the items after
            the expiration date.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}>
        <Link
          href={href}
          target="_blank"
          underline="none"
          sx={{
            color: '#fff',
            flex: '1 1 0%',
            width: '100%',
            backgroundColor: 'transparent',
            '&:hover': {
              color: '#fff',
              backgroundColor: 'transparent',
              textDecoration: 'none',
            },
          }}>
          <Button
            type="button"
            sx={{
              backgroundColor: '#E15E5E',
              px: 2.5,
              py: 1.25,
              color: '#fff',
              fontWeight: 600,
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: '#E15E5E',
              },
            }}>
            {isEnterprise ? 'Extend' : 'Upgrade'} License
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ExpiredBanner;
