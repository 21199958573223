import { SdkBackend } from '../backend/MeshBackend';
import { nodeAffinityApiActions } from '../Components/AssignNodes/utils';
import {
  SET_AFFINITY_API_ACTION,
  SET_AFFINITY_PROFILES,
  SET_CURRENT_SLICE_AFFINITY,
  SET_NODE_AFFINITY_CURRENT_SLICE,
  SET_NODE_AFFINITY_LIST,
  SET_NODE_AFFINITY_SLICE_LIST,
  SET_NODE_LABELS_LIST,
} from './types';
const sdkBackend = new SdkBackend();

export const clearCurrentSlice = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_NODE_AFFINITY_CURRENT_SLICE,
      payload: null,
    });
    dispatch({
      type: SET_AFFINITY_PROFILES,
      payload: [],
    });
  };
};

export const setCurrentSlice = (payload) => {
  return async (dispatch, getState) => {
    const state = getState();

    const hasNodeAffinity = state.NodeAffinity.node_affinity_list.some(
      (affinity) => affinity.metadata.name === payload.metadata.name
    );

    dispatch({
      type: SET_NODE_AFFINITY_CURRENT_SLICE,
      payload,
    });

    if (hasNodeAffinity) {
      dispatch({
        type: SET_AFFINITY_API_ACTION,
        payload: nodeAffinityApiActions.update,
      });
    } else {
      dispatch({
        type: SET_AFFINITY_API_ACTION,
        payload: nodeAffinityApiActions.create,
      });
    }
  };
};

export const getSliceList = () => {
  return async (dispatch, getState) => {
    // fetch slice list
    let response = await sdkBackend.getSliceCustomObjectList(
      getState(),
      dispatch
    );
    if (response) {
      dispatch({
        type: SET_NODE_AFFINITY_SLICE_LIST,
        payload:
          response.items?.filter(
            (slice) =>
              // only show those slices with namespapces
              slice?.spec?.namespaceIsolationProfile?.applicationNamespaces
          ) ?? [],
      });
    }

    let node_affinity_response = await sdkBackend.getNodeAffinityList(
      getState(),
      dispatch
    );
    if (node_affinity_response) {
      dispatch({
        type: SET_NODE_AFFINITY_LIST,
        payload: node_affinity_response.data ?? [],
      });
    }

    // fetch nodeaffinity
  };
};

export const getNodeLabels = (sliceName) => {
  return async (dispatch, getState) => {
    // fetch slice list
    let response = await sdkBackend.getNodeLabels(
      sliceName,
      getState(),
      dispatch
    );
    if (response) {
      dispatch({
        type: SET_NODE_LABELS_LIST,
        payload: response.data ?? [],
      });
    }
    dispatch(getNodeAssignments(sliceName));
    // fetch nodeaffinity
  };
};

export const createNodeAffinity = (nodeDetails) => {
  return async (dispatch, getState) => {
    // Create nodeaffinity
    let response = await sdkBackend.createNodeAffinity(
      getState(),
      dispatch,
      nodeDetails
    );

    if (response) {
      // dispatch(getNodeLabels());  // note: fix for step 3 and 4
      return response;
    }
  };
};

export const updateNodeAffinity = (nodeDetails) => {
  return async (dispatch, getState) => {
    // Create nodeaffinity
    let response = await sdkBackend.updateNodeAffinity(
      getState(),
      dispatch,
      nodeDetails
    );

    if (response) {
      // dispatch(getNodeLabels());
      return response;
    }
  };
};

export const deleteNodeAffinity = (sliceName) => {
  return async (dispatch, getState) => {
    // Create nodeaffinity
    let response = await sdkBackend.deleteNodeAffinity(getState(), dispatch, {
      sliceName,
    });

    // setting back to create
    dispatch({
      type: SET_AFFINITY_API_ACTION,
      payload: nodeAffinityApiActions.create,
    });

    if (response) {
      return response;
    }
  };
};

export const getNodeAssignments = (sliceName) => {
  return async (dispatch, getState) => {
    // fetch slice list
    let response = await sdkBackend.getNodeAssignments(
      sliceName,
      getState(),
      dispatch
    );
    if (response) {
      dispatch({
        type: SET_AFFINITY_PROFILES,
        payload: response.spec.nodeAffinityProfiles,
      });
      dispatch({
        type: SET_CURRENT_SLICE_AFFINITY,
        payload: response,
      });
    } else {
      dispatch({
        type: SET_AFFINITY_PROFILES,
        payload: [],
      });
    }
  };
};

export const setAffinityProfiles = (profiles) => {
  return {
    type: SET_AFFINITY_PROFILES,
    payload: profiles,
  };
};

export const setAffinityApiAction = (action) => {
  return {
    type: SET_AFFINITY_API_ACTION,
    payload: action,
  };
};

export const setCurrentSliceAffinity = (payload) => {
  return {
    type: SET_CURRENT_SLICE_AFFINITY,
    payload: payload,
  };
};
