import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogContent, DialogTitle, IconButton, Typography
} from '@mui/material';
import RoleTab from "./RoleTab";
import { useSelector } from "react-redux";

const ViewRoleModel = ({ open, setOpenViewModal }) => {
    const [roleCount, setRoleCount] = React.useState(0);
    const currentSlice = useSelector((state) => state.RbacReducer.currentSliceRoleList);

    return (
        <Dialog
            open={open}
            onClose={() => {

            }}
            sx={{ '& .MuiDialog-paper': { minWidth: '60%' } }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                <div>
                    <Typography className="dialog-title">
                        {`Total Roles : ${roleCount}`}
                        <IconButton
                            aria-label="close"
                            onClick={() => { setOpenViewModal(false) }}
                            sx={{
                                float: 'right',
                                padding: 0
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Typography>
                    <Typography className="dialog-subtitle">
                        Slice: <b className='model-prop'>{currentSlice?.metadata?.name} </b>
                        | Type: <b className='model-prop'>{`APPLICATION`} </b>
                        | Description: <b className='model-prop'>{currentSlice?.metadata?.annotations?.desc}</b>
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 3, maxHeight: '80%' }}>
                <RoleTab setRoleCount={setRoleCount} modalBox={true}/>
            </DialogContent>
        </Dialog>
    )
}

export default ViewRoleModel;