import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
//import FormControl from '@mui/material/FormControl';
//import FormHelperText from '@mui/material/FormHelperText';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import AceEditor from "react-ace";
import { updateRole } from "../../actions/RbacActions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditRole = ({ role, closeModal }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [rulesError, setRulesError] = useState("");
  const [rule, setRule] = useState(() =>
    JSON.stringify(role?.rules ?? "", null, 4)
  );
  const [description, setDescription] = useState(() => role?.description ?? "");

  const dispatch = useDispatch();

  const handleClose = () => {
    closeModal();
  };

  const handleEditorError = (value) => {
    setRulesError("");
    setRule(value);
  };

  const saveRole = async () => {
    try {
      const roleDetails = {
        ...role,
        description,
        rules: JSON.parse(rule),
      };
      dispatch(updateRole(roleDetails));
      handleClose();
    } catch (error) {
      setRulesError(error.message);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="edit-role-title"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <label style={{ fontWeight: "bold" }}>Edit Role</label>
          <IconButton
            aria-label="close"
            onClick={() => setConfirmDialog(true)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="role-name"
            label="Role Name"
            value={role.name}
            disabled
            fullWidth
            autoFocus
            margin="dense"
          />
          <TextField
            id="description"
            label="Description"
            value={description}
            fullWidth
            autoFocus
            margin="dense"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Typography
            sx={{ fontSize: "17px", color: "black" }}
            mt={2}
            gutterBottom
          >
            Rules:
          </Typography>
          <AceEditor
            mode="json"
            theme="monokai"
            name="Rule"
            height="400px"
            width="100%"
            onChange={handleEditorError}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={rule}
            setOptions={{
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </DialogContent>
        {rulesError && (
          <Typography
            sx={{
              paddingY: 1,
              paddingX: 2,
              color: "error.main",
            }}
          >
            {rulesError}
          </Typography>
        )}
        <DialogActions>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#2868F3", color: "white" }}
            fullWidth
            autoFocus
            onClick={saveRole}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="confirm"
        aria-describedby="confirm to close"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to leave without saving the changes?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>No</Button>
          <Button onClick={handleClose} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditRole;
